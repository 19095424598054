import React, { useCallback, useEffect, useState } from 'react'
import { Knob } from '../Knob';
import { Checkbox, Popover, Select, TextField } from '@shopify/polaris'
import { ColorResult, SketchPicker } from 'react-color';
import { View } from 'react-native';


export const KnobAdvancedText = React.memo<Knob.KnobComponentProps<Knob.KnobAdvancedTextDescriptor>>((
    { knob, value: initValue, onChange, innerKey }
) => {
    const { description, placeholder, disableAlpha, noValue, noColor, noTextAlign, noFontWeight, switchable } = knob;

    console.log('[KnobAdvancedText] init', initValue);
    const [value, setValue] = useState<Knob.AdvancedText>({
        value: initValue.value,
        textColor: initValue.textColor || undefined,
        showCaption: initValue.showCaption,
        fontWeight: initValue.fontWeight,
        textAlign: initValue.textAlign
    });
    const [popoverActive, setPopoverActive] = useState(false);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );

    const handleKnobChange = useCallback((key: keyof Knob.AdvancedText, value: any) => {
        setValue((prevValue) => {
            const newValue = { ...prevValue, ...{ [key]: value } };
            onChange(newValue, innerKey)
            return newValue;
        });
    }, [innerKey, onChange]);

    const onChangeCb = useCallback((val: ColorResult) => {
        if (disableAlpha) {
            handleKnobChange('textColor', val.hex);
        } else {
            if (val.rgb.a) {
                let alphaHex = Math.round(val.rgb.a * 255).toString(16);
                if (alphaHex.length < 2) alphaHex = '0' + alphaHex;
                handleKnobChange('textColor', val.hex + `${alphaHex}`);
            } else {
                handleKnobChange('textColor', val.hex + '00');
            }
        }
    }, [handleKnobChange]);

    const handleShowCaptionChange = useCallback((newChecked) => {
        handleKnobChange('showCaption', newChecked);
    }, [handleKnobChange]);

    useEffect(() => {
        setValue(initValue)
    }, [initValue]);


    const activator = (
        <button
            onClick={togglePopoverActive}
            disabled={value.showCaption !== undefined && !value.showCaption}
            style={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                width: '36px',
                outline: 'none',
                cursor: 'pointer'
            }}>
            <div style={{
                width: '100px',
                height: '16px',
                border: '1px solid #000',
                backgroundColor: value.textColor
                    ? value.textColor
                    : '#fff'
            }}
            />
        </button>
    );

    if (noValue) {
        return (
            <div style={{ position: 'relative' }}>
                <div style={{
                    marginBottom: '0.4rem'
                }}>
                    {description}
                </div>
                <div className='advanced-text-input'>
                    <View style={{ flexDirection: 'row' }}>
                        {!noColor ? <div style={{
                            marginRight: '0.4rem'
                        }}>
                            <Popover
                                active={popoverActive}
                                activator={activator}
                                onClose={togglePopoverActive}
                            >
                                <SketchPicker
                                    width={'200px'}
                                    color={
                                        value.textColor
                                            ? value.textColor
                                            : '#000'
                                    }
                                    onChange={onChangeCb}
                                    disableAlpha={disableAlpha}

                                />
                            </Popover>
                        </div> : undefined}
                        {!noTextAlign ? <div style={{
                            marginRight: '0.4rem'
                        }}>
                            <Select
                                label="Text align"
                                onChange={(v) => { handleKnobChange('textAlign', v) }}
                                disabled={!value.showCaption}
                                value={value.textAlign}
                                labelHidden
                                options={[
                                    {
                                        label: 'left',
                                        value: 'flex-start',
                                    },
                                    {
                                        label: 'center',
                                        value: 'center',
                                    },
                                    {
                                        label: 'right',
                                        value: 'flex-end',
                                    },
                                ]}
                            />
                        </div> : undefined}
                        <div>
                            {!noFontWeight ? <Select
                                label="Font weight"
                                onChange={(v) => { handleKnobChange('fontWeight', v) }}
                                disabled={!value.showCaption}
                                value={value.fontWeight}
                                labelInline
                                options={[
                                    {
                                        label: 'Light',
                                        value: '300',
                                    },
                                    {
                                        label: 'Normal',
                                        value: '400',
                                    },
                                    {
                                        label: 'Bold',
                                        value: '700',
                                    },
                                ]}
                            /> : undefined}
                        </div>
                    </View>
                    {switchable ? <Checkbox
                        label="Enabled"
                        checked={value.showCaption}
                        onChange={(v) => { handleKnobChange('showCaption', v) }}
                    /> : undefined}
                </div>
            </div>
        )
    }

    return (
        <div style={{ position: 'relative' }}>
            <div style={{
                marginBottom: '0.4rem'
            }}>
                {description}
            </div>
            <div className='advanced-text-input'>
                <TextField
                    label={description}
                    labelHidden
                    value={value.value}
                    onChange={(v) => { handleKnobChange('value', v) }}
                    placeholder={placeholder ?? ''}
                    disabled={value.showCaption !== undefined && !value.showCaption}
                    multiline={1}
                    connectedLeft={!noColor ? <div>
                        <Popover
                            active={popoverActive}
                            activator={activator}
                            onClose={togglePopoverActive}
                        >
                            <SketchPicker
                                width={'200px'}
                                color={
                                    value.textColor
                                        ? value.textColor
                                        : '#000'
                                }
                                onChange={onChangeCb}
                                disableAlpha={disableAlpha}
                            />
                        </Popover>
                    </div> : undefined}
                    connectedRight={!noTextAlign ? <Select
                        label="Text align"
                        onChange={(v) => { handleKnobChange('textAlign', v) }}
                        disabled={!value.showCaption}
                        value={value.textAlign}
                        labelHidden
                        options={[
                            {
                                label: 'left',
                                value: 'flex-start',
                            },
                            {
                                label: 'center',
                                value: 'center',
                            },
                            {
                                label: 'right',
                                value: 'flex-end',
                            },
                        ]}
                    /> : undefined}
                />
            </div>
            <div style={{
                marginTop: '0.4rem'
            }}>{!noFontWeight ? <Select
                label="Font weight"
                onChange={(v) => { handleKnobChange('fontWeight', v) }}
                disabled={!value.showCaption}
                value={value.fontWeight}
                labelInline
                options={[
                    {
                        label: 'Light',
                        value: '300',
                    },
                    {
                        label: 'Normal',
                        value: '400',
                    },
                    {
                        label: 'Bold',
                        value: '700',
                    },
                ]}
            /> : undefined}
                {switchable ? <Checkbox
                    label="Enabled"
                    checked={value.showCaption}
                    onChange={(v) => { handleKnobChange('showCaption', v) }}
                /> : undefined}
            </div>
        </div>
    );
});