import React, { useEffect } from "react";
import { useClient } from "../../../api/useClient";
import { AppEmulatorConfig, AppEmulatorPlatform } from "../types";
import { matchPath, useParams } from "react-router";
import { View } from "react-native";
import { IPhoneEmulatorContent } from "./IPhoneEmulatorContent";
import { ThemeScreen } from "../screens/ThemeScreen";
import { ShowPageContent } from "../../../configure/theme/preview/usePageEditorPreview";
import { AppBannerInputParams } from "../../../api/spacex.types";
import { SmartBannerScreen } from "../screens/SmartBannerScreen";
import { PageScreen } from "../screens/PageScreen";
import { IOSScreenContent } from "./IOSScreenContent";
import { IOSScreen } from "./IOSScreen";
import { IOSSearchBar } from "./IOSSearchBar";
import { ScalableEmulatorComponent } from "./ScalableEmulatorComponent";
import { IOSTitlebar } from "./IOSTitlebar";
import { EmulatorSuspense, Suspense } from "../../../components/Suspense";
import { EmulatorNewTab } from "./EmulatorNewTab";
import { BannerContext, useBannerConfig } from "../../../configure/listing/AppPromotionComponent";
import { TabContent } from "../../../configure/theme/preview/TabContent";
import { MenuScreen } from "../screens/MenuScreen";
import { useSmartBanner } from "../../../SmartBannerContext";

export enum AppEmulatorMode {
    Theme = 'theme',
    Page = 'page',
    SmartBanner = 'smart_banner',
    NewTab = 'new_tab',
    Tab = 'tab',
    Menu = "menu"
}

export const AppEmulator = React.memo((
    {
        appId,
        mode,
        selectedPlatform,
        showNavigation,
    }: {
        appId: string,
        mode: AppEmulatorMode,
        selectedPlatform: AppEmulatorPlatform,
        showNavigation: boolean,
    }
) => {
    const [smartBanner]  = useSmartBanner();
    console.log('[AppEmulator] smartBanner', smartBanner);
    let selectedTab = mode === AppEmulatorMode.Tab
        ? matchPath<{ key: string, id: string }>(location.pathname, `/apps/:id/menu/item/:key`)
        : undefined;
    console.log('selectedTab', selectedTab);

    const banner = useBannerConfig();
    const params = useParams<{ id: string, pageId?: string }>();
    const client = useClient();
    const appConfig = client.useAppEmulatorConfig({ id: appId }).app.draftConfig as AppEmulatorConfig;

    useEffect(() => {
        let canceled = false;
        let subscription: { destroy: () => void } = { destroy: () => {/* */ } };

        function startSubscription() {
            if (!canceled) {
                subscription = client.subscribeWatchAppEmulatorConfig({ appId }, (event) => {
                    if (event.type === 'stopped') {
                        setTimeout(() => {
                            startSubscription();
                        }, 1000);
                    }
                    console.log("EVENT", event);
                });
            }
        }
        startSubscription();

        return () => {
            canceled = true;
            subscription.destroy();
        }
    }, []);

    console.log('[AppEmulator]: mode', mode);
    switch (mode) {
        case AppEmulatorMode.Tab:
            return (
                <ScalableEmulatorComponent>
                    <IPhoneEmulatorContent
                        appId={appId}
                        config={appConfig}
                        showNavigation={showNavigation}
                        colored={true}
                        bannerScreen={false}
                    >
                        <IOSScreen>
                            <IOSTitlebar config={appConfig} />
                            <IOSSearchBar config={appConfig} />
                            <IOSScreenContent>
                                <TabContent />
                            </IOSScreenContent>
                        </IOSScreen>
                    </IPhoneEmulatorContent>
                </ScalableEmulatorComponent>
            );
        case AppEmulatorMode.Page:
            return (
                <ScalableEmulatorComponent>
                    <IPhoneEmulatorContent
                        appId={appId}
                        config={appConfig}
                        showNavigation={showNavigation}
                        colored={true}
                        bannerScreen={false}
                    >
                        <IOSScreen>
                            <IOSTitlebar config={appConfig} />
                            <IOSSearchBar config={appConfig} />
                            <IOSScreenContent>
                                {
                                    params.pageId
                                    && params.pageId !== "new"
                                    && <ShowPageContent pageId={params.pageId} />
                                }
                                {
                                    params.pageId
                                    && params.pageId === "new"
                                    && <PageScreen
                                        pageId={params.pageId}
                                        appId={appId}
                                        config={appConfig}
                                        selectedPlatform={selectedPlatform} />
                                }
                                {
                                    !params.pageId
                                    && <PageScreen
                                        appId={appId}
                                        config={appConfig}
                                        selectedPlatform={selectedPlatform}
                                    />
                                }
                            </IOSScreenContent>
                        </IOSScreen>
                    </IPhoneEmulatorContent>
                </ScalableEmulatorComponent>
            );
        case AppEmulatorMode.Menu:
            return (
                <ScalableEmulatorComponent>
                    <IPhoneEmulatorContent
                        appId={appId}
                        config={appConfig}
                        showNavigation={showNavigation}
                        colored={true}
                        bannerScreen={false}
                    >
                        <EmulatorSuspense>
                            <MenuScreen appId={appId} config={appConfig} />
                        </EmulatorSuspense>
                    </IPhoneEmulatorContent>
                </ScalableEmulatorComponent>
            );
        case AppEmulatorMode.Theme:
            return (
                <ScalableEmulatorComponent>
                    <IPhoneEmulatorContent
                        appId={appId}
                        config={appConfig}
                        showNavigation={showNavigation}
                        colored={true}
                        bannerScreen={false}
                    >
                        <EmulatorSuspense>
                            <ThemeScreen appId={appId} config={appConfig} />
                        </EmulatorSuspense>
                    </IPhoneEmulatorContent>
                </ScalableEmulatorComponent>
            );
        case AppEmulatorMode.SmartBanner:
            return (
                <ScalableEmulatorComponent>
                    <IPhoneEmulatorContent
                        appId={appId}
                        config={appConfig}
                        showNavigation={showNavigation}
                        colored={false}
                        bannerScreen={true}
                    >
                        <EmulatorSuspense>
                            <IOSScreen>
                                {
                                    smartBanner
                                    && <SmartBannerScreen
                                        smartBanner={smartBanner}
                                        appId={appId}
                                        selectedPlatform={selectedPlatform}
                                        config={appConfig} />
                                }
                            </IOSScreen>
                        </EmulatorSuspense>
                    </IPhoneEmulatorContent>
                </ScalableEmulatorComponent>
            );
        // TODO: add new tab content, icon, title emulation
        case AppEmulatorMode.NewTab:
            return (
                <ScalableEmulatorComponent>
                    <IPhoneEmulatorContent
                        appId={appId}
                        config={appConfig}
                        showNavigation={showNavigation}
                        colored={true}
                        bannerScreen={false}
                    >
                        <IOSScreen>
                            <IOSTitlebar config={appConfig} />
                            <IOSSearchBar config={appConfig} />
                            <IOSScreenContent>
                                <EmulatorNewTab config={appConfig} />
                            </IOSScreenContent>
                        </IOSScreen>
                    </IPhoneEmulatorContent>
                </ScalableEmulatorComponent>
            );
        default:
            return (<View />);
    }

});


