import { Modal, Stack, TextStyle, Thumbnail } from "@shopify/polaris";
import React from "react";
import { ListCommunityListings_listCommunityListings_edges_node } from "../../../api/spacex.types";

export const ReviewListingModal = React.memo((
    {
        listing,
        handleCancel,
        handleAccept
    }: {
        listing?: ListCommunityListings_listCommunityListings_edges_node,
        handleCancel: () => void,
        handleAccept: (item?: ListCommunityListings_listCommunityListings_edges_node) => void
    }
) => {

    return (
        <Modal
            open={listing !== undefined}
            onClose={handleCancel}
            title={`Request review`}
            primaryAction={{
                content: 'Accept',
                onAction: () => handleAccept(listing),
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: handleCancel,
                },
            ]}
        >
            <Modal.Section>
                <p>
                    <TextStyle>
                        {'If you accept this request this position will be visible on your Cartmate storefront ' +
                            ' and you will recieve points for every click on this position form your Cartmate storefront'}
                    </TextStyle>
                </p>
            </Modal.Section>
            <Modal.Section>
                <p>
                    Type:
                    <TextStyle variation={'strong'}>
                        {` ${listing?.adListing.type}`}
                    </TextStyle>
                </p>
                <Stack>
                    {listing && listing.content.image?.src && (
                        <Stack.Item>
                            <Thumbnail
                                alt={'content-thumbnail'}
                                size={'medium'}
                                source={listing?.content.image?.src}
                            />
                        </Stack.Item>
                    )}
                    <Stack.Item>
                        <Stack vertical>
                            <p>
                                <TextStyle variation={'strong'}>
                                    {listing?.content.title}
                                </TextStyle>
                            </p>
                            <p>
                                <TextStyle variation={'subdued'}>
                                    {listing?.content.description}
                                </TextStyle>
                            </p>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Modal.Section>
        </Modal >
    );
});