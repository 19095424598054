import React from "react";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Page, Layout, Card, Link } from "@shopify/polaris";
import { View } from "react-native";
import { useParams, useHistory } from "react-router";

const themeGif = require('../../../assets/gifs/theme.gif');
const menuGif = require('../../../assets/gifs/menu.gif');

export const Customize = React.memo(() => {
    const appBridge = useAppBridge();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    return (<>
        <Page
            title={'Make your mobile in-app storefront stand out'}
            breadcrumbs={[{
                content: 'About',
                onAction: () => { history.length > 2 ? history.goBack() : history.push({ pathname: '/about' }) }
            }]
            }>
            <Layout>
                <Layout.Section>
                    <div id={'section'}>
                        {/* <View style={{ marginVertical: 16 }}>
                            <DisplayText size={'medium'}>
                                {'Make your mobile in-app storefront stand out'}
                            </DisplayText>
                        </View> */}
                        <Card title={'Customize your mobile in-app storefront'} sectioned>
                            <p>
                                Set your branding in the <Link onClick={() => {
                                    const redirect = ShopifyRedirect.create(appBridge);
                                    redirect.dispatch(ShopifyRedirect.Action.APP, '/apps');
                                }}>Customize app branding</Link>
                                {' '}section.
                            </p>
                            <View style={{
                                justifyContent: 'flex-start', alignItems: 'center',
                                width: '100%', marginVertical: 16,
                                flexDirection: 'row'
                            }}>
                                <img src={themeGif} alt="theme" />
                            </View>
                            <p>
                                Also you can edit your mobile storefront navigation and add new items in the <Link onClick={() => {
                                    const redirect = ShopifyRedirect.create(appBridge);
                                    redirect.dispatch(ShopifyRedirect.Action.APP, `/apps/${id}/menu`);
                                }}>Customize app menu</Link>
                                {' '}tab.
                            </p>
                            <View style={{
                                justifyContent: 'flex-start', alignItems: 'center',
                                width: '100%', marginVertical: 16,
                                flexDirection: 'row'
                            }}>
                                <img src={menuGif} alt="menu" />
                            </View>
                        </Card>
                    </div>
                </Layout.Section>
            </Layout>
        </Page>
    </>
    )
});