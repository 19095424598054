import { Card, Form } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { Screen } from "../../api/spacex.types";
import { useClient } from "../../api/useClient";
import { KnobMultiCollectionPicker } from "../../configEditor/knobs/components/KnobMultiCollectionPicker";
import { useFirstActiveApp } from "../../utils/helpers";

type SettingsForm = {
    collections: string[],
};

export const CollectionsSettings = React.memo(() => {
    const client = useClient();
    const app = useFirstActiveApp();
    const screenSettings = client.useScreenSettings({ screen: Screen.COLLECTIONS }).screenSettings;
    const settings = JSON.parse(screenSettings.settings);
    console.log('Setttings', settings);
    const [state, setState] = useState<SettingsForm>({ collections: settings.collections || [] });
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleFormChange = useCallback((field: keyof SettingsForm, value: any) => {
        setState((prevValue) => {
            const newValue = { ...prevValue, ...{ [field]: value } };
            console.log('MCP newVal', newValue);
            if (prevValue[field] !== value) {
                console.log('IsDirty');
                setIsDirty(true);
            }
            return newValue;
        });
    }, []);

    const handleSubmitForm = useCallback(() => {
        setLoading(true);
        client.mutateUpdateScreenSettings({
            screen: Screen.COLLECTIONS,
            settings: JSON.stringify(state)
        })
            .then((result) => {
                setLoading(false);
                setIsDirty(false);
                console.log('Screen settings saved', result);
                client.refetchScreenSettings({ screen: Screen.COLLECTIONS });
            })
            .catch((e) => {
                setLoading(false);
                console.warn(e);
            })

    }, [client, state]);

    return (
        <Card
            title={"Collections screen settings"}
            primaryFooterAction={{
                content: "Save", disabled: !isDirty, onAction: handleSubmitForm
            }}
        >
            <Form onSubmit={handleSubmitForm}>
                <Card.Section>
                    <KnobMultiCollectionPicker
                        knob={{
                            type: 'multi_collection_picker',
                            description: "Select collections to display at the screen",
                            unique: true,
                        }}
                        innerKey={`${app.id}-multi-col-picker`}
                        value={state.collections}
                        onChange={(value, innerKey) => {
                            handleFormChange('collections', value);
                        }}
                    />
                </Card.Section>
            </Form>
        </Card>
    );
});