import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

function SvgComponent(props: SvgProps) {
    return (
        <Svg
            width={18}
            height={10}
            viewBox="0 0 18 10"
            fill="none"
            {...props}
        >
            <Path
                d="M1 1l8 8 8-8"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    )
}

const MemoSvgComponent = React.memo(SvgComponent)
export default MemoSvgComponent