import { Toast, BannerStatus, Card, Banner } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { AccountMonitoringStatus, SyncStatus } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { useAccount } from "../../../configure/utils/useAccount";
import { trackEvent, MixpanelEvent } from "../../../utils/mixpanel";

export const Synchronize = React.memo(() => {
    const client = useClient();
    const settings = client.useSettings();
    const [syncToastShown, setToastShown] = useState(false);
    const syncToast = syncToastShown ? (
        <Toast content="Synchronization queued" onDismiss={() => setToastShown(false)} />
    ) : null;
    const syncCatalog = useCallback(
        () => {
            trackEvent(MixpanelEvent.ForceSynchronization);
            client.mutateSyncCatalog().then((result) => {
                console.log('Result', result);
                setToastShown(true);
            }).catch(console.warn)
        },
        [],
    );


    const account = useAccount();
    const status = account.monitoringStatus?.status
    const syncState = client.useMyShop().syncState;
    console.log('account monitoring status ' + status);

    let syncStatus = '', bannerStatus: BannerStatus = 'info';
    switch (syncState.status) {
        case SyncStatus.IN_PROGRES:
            syncStatus = 'in progress'
            bannerStatus = 'info';
            break;
        case SyncStatus.PENDING:
            syncStatus = 'queued';
            bannerStatus = 'warning';
            break;
        case SyncStatus.READY:
            syncStatus = 'synchronized';
            bannerStatus = 'success';
            break;        
        default:
            syncStatus = 'unknown';
            break;
    }

    return (
        <Card
            primaryFooterAction={{
                content: 'Force Sync',
                onAction: syncCatalog
            }}
            footerActionAlignment={'left'}
            sectioned
        >
            <Banner status={bannerStatus}>
                <p>Your products and collections sync status: {syncStatus}</p>
            </Banner>
            {syncToast}
        </Card>
    )
});