import * as React from 'react';
import { View } from 'react-native';
import { createClient } from './api/createClient';
import { TokenContext } from './api/useApiToken';
import { ClientContext } from './api/useClient';
import { Suspense } from './components/Suspense';

export const AppProviders = React.memo((props: { token: string, children?: any }) => {
    const client = React.useMemo(() => createClient(props.token), []);
    console.log('loading AppProviders');
    return (
        <ClientContext.Provider value={client}>
            <TokenContext.Provider value={props.token}>
                <View style={{ width: '100vw', height: '100vh' }}>
                    <Suspense>
                        {props.children}
                    </Suspense>
                </View>
            </TokenContext.Provider>
        </ClientContext.Provider>
    )
});