import { Knob } from './knobs/Knob';
import {
    ContentPageBlockInput,
    ContentPageBlockType,
    ContentPageCreateInput,
    ContentPageType, Page_page,
} from '../api/spacex.types';
import * as t from 'io-ts';
import { getOrElse, toError } from 'fp-ts/es6/Either';
import { pipe } from 'fp-ts/function';
import { initRenderBlock } from './subRender/subRenderUtils';
import { IconSource } from '@shopify/polaris'


export namespace ViewConfig {
    export type NodeBlock = {
        id: string
        src: string
        originComponentKey: string
        knobs: Knob.SideEffect,
        title: string,
    }

    export type NodeBlockExtended = {
        descr: Knob.Map | null
        modernRender: string
    } & NodeBlock

    export type Page = {
        id: string
        name: string
        type: ContentPageType.BLOCK
        blocks: NodeBlock[],
        contentPageId: string
    }

    export const BlocksDataPage = new t.Type<NodeBlock[], string, string>(
        'PagesDataPage',
        (val): val is NodeBlock[] => false, //todo add impl,
        (input, context) => t.success(JSON.parse(input)),
        (input) => JSON.stringify(input.map(({ id, src, originComponentKey, knobs, title }) => ({
            id, src, originComponentKey, knobs, title
        }))),
    )

    export const PageToInputPage = new t.Type<Page, ContentPageCreateInput, ContentPageCreateInput>(
        'PageToInputPage',
        (val): val is Page => false, //todo add impl,
        (input, context) => t.failure(input, context),
        (input) => ({
            name: input.name,
            body: {
                designerData: BlocksDataPage.encode(input.blocks),
                type: ContentPageType.BLOCK,
                blocks: input.blocks.map(NodeBlockBlockInputCodec.encode),
            },
        }))

    export const NodeBlockBlockInputCodec = new t.Type<NodeBlock, ContentPageBlockInput, ContentPageBlockInput>(
        'PageStringCodec',
        (val): val is NodeBlock => false, //todo add impl
        (input, context) => t.failure(input, context),
        (input) => ({
            body: NodeBlockStringCodec.encode(input),
            key: input.id,
            type: ContentPageBlockType.REACT,
        }))

    const NodeBlockStringCodec = new t.Type<NodeBlock, string, string>(
        'NodeBlockStringCodec',
        (val): val is NodeBlock => false, //todo add impl
        (input, context) => t.failure(input, context),
        (input) => input.src)

    export const PageToSeverPage = new t.Type<Page, Page_page, Page_page>(
        'PageToSeverPage',
        (val): val is Page => false, //todo add impl,
        ({ id, name, draftData, contentPageId }, context) => {
            try {
                return t.success<Page>({
                    id,
                    name,
                    type: ContentPageType.BLOCK,
                    blocks: pipe(BlocksDataPage.decode(draftData), getOrElse(() => [] as NodeBlock[])),
                    contentPageId: contentPageId
                })
            } catch (e) {
                return t.failure(toError(e), context)
            }
        },
        (input) => ({
            __typename: 'PageEditor',
            id: input.id,
            name: input.name,
            publishedData: null,
            draftData: BlocksDataPage.encode(input.blocks),
            contentPageId: input.contentPageId
        }))

    export function getBlocks(page: Page_page): ViewConfig.NodeBlockExtended[] {
        const nodeBlocks = pipe(ViewConfig.PageToSeverPage.decode(page), getOrElse(() => (null as ViewConfig.Page | null)))?.blocks || [];

        return nodeBlocks.map(node => initRenderBlock(node))
    }

}
