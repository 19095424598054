import { Stack, TextField } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { Contacts_contacts_data_socials } from "../../../api/spacex.types";
import { CleanSpaceX } from "../../../components/types";
import { IconElement } from "../../../content/elements/IconElement";
import { capitalize } from "../../../utils/textUtils";

export enum SocialType {
    Instagram = 'instagram',
    Facebook = 'facebook',
}

export const SocialsInput = React.memo(({
    value,
    onChange
}: {
    value?: CleanSpaceX<Contacts_contacts_data_socials>[] | null,
    onChange: (data: CleanSpaceX<Contacts_contacts_data_socials>[] | null) => void
}) => {
    const initialInstagramId = value?.find((s) => s.socialName === SocialType.Instagram)?.socialId;
    const initialFacebookId = value?.find((s) => s.socialName === SocialType.Facebook)?.socialId;

    const [instagramId, setInstagramId] = useState(initialInstagramId);
    const [facebookId, setFacebookId] = useState(initialFacebookId);

    useEffect(() => {
        const socials = [
            { socialName: SocialType.Instagram, socialId: instagramId || '' },
            { socialName: SocialType.Facebook, socialId: facebookId || '' }
        ]
        console.log('socials', socials);
        if (instagramId !== initialInstagramId || facebookId !== initialFacebookId) {
            onChange(socials);
        }
    }, [instagramId, facebookId, onChange, initialInstagramId, initialFacebookId]);

    return (
        <Stack distribution={'fill'}>
            <Stack.Item>
                <TextField
                    id={'instagram-input'}
                    placeholder={''}
                    label={<View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                        <View style={{ marginRight: 4 }}>
                            <IconElement
                                collection={'ionicons'}
                                name={'logo-instagram'}
                                size={24}
                            />
                        </View>
                        <label>{capitalize(SocialType.Instagram)}</label>
                    </View>}
                    error={''}
                    onChange={(value) => setInstagramId(value.replace('@', ''))}
                    type={'text'}
                    inputMode={'text'}
                    value={`@${instagramId ? instagramId : ''}`}
                />
            </Stack.Item>
            <Stack.Item>
                <TextField
                    id={'facebook-input'}
                    placeholder={''}
                    label={<View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                        <View style={{ marginRight: 4 }}>
                            <IconElement
                                collection={'ionicons'}
                                name={'logo-facebook'}
                                size={24}
                            />
                        </View>
                        <label>{capitalize(SocialType.Facebook)}</label>
                    </View>}
                    error={''}
                    onChange={(value) => setFacebookId(value.replace('@', ''))}
                    type={'text'}
                    inputMode={'text'}
                    value={`@${facebookId ? facebookId : ''}`}
                />
            </Stack.Item>
        </Stack>
    );
});