import React, { useCallback, useEffect, useState } from 'react'
import { Knob } from '../Knob';
import { ColorResult, SketchPicker } from 'react-color';
import { FormLayout } from '@shopify/polaris'
import { View } from 'react-native';

const defaultValue = '#FFF'

const style = { display: 'block', marginBottom: 6 };

export const KnobColor = React.memo<Knob.KnobComponentProps<Knob.KnobColorDescriptor>>(
    ({ knob, value: initValue, onChange, innerKey }) => {
        const { description, disableAlpha } = knob
        const [value, setValue] = useState(initValue || defaultValue)

        const onChangeCb = useCallback((val: ColorResult) => {

            if (disableAlpha) {
                setValue(val.hex)
            } else {
                if (val.rgb.a) {
                    let alphaHex = Math.round(val.rgb.a * 255).toString(16)
                    if (alphaHex.length < 2) alphaHex = '0' + alphaHex;
                    setValue(val.hex + `${alphaHex}`)
                } else {
                    setValue(val.hex + '00')
                }
            }
        }, [])

        useEffect(() => {
            if (initValue !== value) {
                onChange(value, innerKey)
            }
        }, [innerKey, value, knob, initValue])

        return <div
            style={{
                marginBottom: 12
            }}>
            <FormLayout>
                <View>
                    <label style={style}>{description}</label>
                    <SketchPicker width={'340'} color={value} onChange={onChangeCb} disableAlpha={disableAlpha} />
                </View>
            </FormLayout>
        </div>
    })