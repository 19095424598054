import { IconView, TabDesigner } from '../../types';
import { Collections, ContentReferenceType, Pages_pages } from '../../../api/spacex.types';
import { useMemo } from 'react';
import { useClient } from '../../../api/useClient';
import {
    CartMajor,
    CollectionsMajor,
    FeaturedContentMajor,
    ImageWithTextMajor,
    RiskMinor,
} from '@shopify/polaris-icons';
import { MaterialCommunityIcons } from '../../Icon/IconPicker/iconsData';
import { Feather, Ionicons } from '../../IconPicker/collections';

type TabContent = Pick<TabDesigner, 'contentType' | 'contentKey'>

export const CONTENT_MAP: Record<ContentReferenceType, IconView> = {
    [ContentReferenceType.CART]: {
        collection: MaterialCommunityIcons.key,
        name: 'cart'
    },
    [ContentReferenceType.COLLECTION]: {
        collection: MaterialCommunityIcons.key,
        name: 'view-list'
    },
    [ContentReferenceType.CONTENT]: {
        collection: MaterialCommunityIcons.key,
        name: 'page-previous'
    },
    [ContentReferenceType.EMPTY]: {
        collection: MaterialCommunityIcons.key,
        name: 'block-helper'
    },
    [ContentReferenceType.FEED]: {
        collection: MaterialCommunityIcons.key,
        name: 'block-helper'
    },
    [ContentReferenceType.ALL_COLLECTIONS]: {
        collection: MaterialCommunityIcons.key,
        name: 'view-list'
    },
    [ContentReferenceType.COLLECTIONS]: {
        collection: MaterialCommunityIcons.key,
        name: 'view-list'
    },
    [ContentReferenceType.FAVORITES]: {
        collection: Ionicons.key,
        name: 'heart'
    },
    [ContentReferenceType.PRODUCTS_BY_COLLECTIONS]: {
        collection: MaterialCommunityIcons.key,
        name: 'format-list-text'
    },
    [ContentReferenceType.SHOP_ORDERS]: {
        collection: Feather.key,
        name: 'package'
    }
}

export type Item = ItemCategory | {
    type: 'selectable',
} & RenderProps

export type ItemCategory = {
    type: 'category',
    options: Item[]
} & RenderProps | {
    type: 'paginated-category',
    pageController: {
        options: Item[]
        totalItemCount: number
    }
} & RenderProps

export type RenderProps = ({
    viewType: 'img'
    imgSrc: string
} | {
    icon: IconView
    viewType: 'icon'
}) & {
    title: string
    hash: Hash
}

export type ItemTreeRoot = Item[]

const STATIC_ITEMS: Array<Item & { contentValue: TabContent }> = [
    {
        type: 'selectable',
        viewType: 'icon',
        title: 'Empty',
        hash: 'empty',
        icon: CONTENT_MAP[ContentReferenceType.EMPTY],
        contentValue: {
            contentKey: null,
            contentType: ContentReferenceType.EMPTY,
        },
    }, {
        type: 'selectable',
        viewType: 'icon',
        title: 'Cart',
        hash: 'cart',
        icon: CONTENT_MAP[ContentReferenceType.CART],
        contentValue: {
            contentKey: null,
            contentType: ContentReferenceType.CART,
        },
    },{
        type: 'selectable',
        viewType: 'icon',
        title: 'All collecitons',
        hash: 'all_collections',
        icon: CONTENT_MAP[ContentReferenceType.ALL_COLLECTIONS],
        contentValue: {
            contentKey: null,
            contentType: ContentReferenceType.ALL_COLLECTIONS,
        },
    },{
        type: 'selectable',
        viewType: 'icon',
        title: 'Favorites',
        hash: 'favorites',
        icon: CONTENT_MAP[ContentReferenceType.FAVORITES],
        contentValue: {
            contentKey: null,
            contentType: ContentReferenceType.FAVORITES,
        },
    },{
        type: 'selectable',
        viewType: 'icon',
        title: 'Prodcuts by Collections',
        hash: 'products_by_collections',
        icon: CONTENT_MAP[ContentReferenceType.PRODUCTS_BY_COLLECTIONS],
        contentValue: {
            contentKey: null,
            contentType: ContentReferenceType.PRODUCTS_BY_COLLECTIONS,
        },
    },{
        type: 'selectable',
        viewType: 'icon',
        title: 'Orders',
        hash: 'shop_orders',
        icon: CONTENT_MAP[ContentReferenceType.SHOP_ORDERS],
        contentValue: {
            contentKey: null,
            contentType: ContentReferenceType.SHOP_ORDERS,
        },
    },
]

type Hash = string
type ValueMap = Map<Hash, TabContent>

function fillTreeStaticItems(map: ValueMap, root: ItemTreeRoot) {
    STATIC_ITEMS.forEach(i => {
        if (i.type === 'selectable') {
            map.set(i.hash, i.contentValue)
        }

        root.push({...i})
    })

    return root
}

export function useContentTree(search: string = '') {
    const trimmedSearch = search.trim()
    const [tree, valueMap] = useLoadTree()
    // todo make products with pagination
    // const products = useClient().useProduct({query: trimmedSearch}).products
    // const [searchTree, setSearchTree] = useState<ItemTreeRoot>(tree)

    const filteredTree = useMemo(() => {
        if (!trimmedSearch) {
            return tree
        } else {
            return filterTree(trimmedSearch, tree)
        }
    }, [tree, trimmedSearch])

    return [valueMap, filteredTree, tree] as const
}

function useLoadTree() {
    const client = useClient()
    const valueMap = useMemo(() => new Map<Hash, TabContent>(), [])
    const staticContent = useMemo(() => fillTreeStaticItems(valueMap, []), [valueMap])
    const collections = client.useCollections().collections
    const pages = client.usePages().pages

    const tree = useMemo(() => buildTree({
        collections,
        pages,
        staticContent,
        valueMap,
    }), [collections, pages, staticContent, valueMap])

    return [tree, valueMap] as const
}

type buildTreeOptions = {
    collections: Collections['collections']
    pages: Pages_pages[]
    staticContent: ItemTreeRoot
    valueMap: ValueMap
}

function notNullablePredicate<T>(item: T | null | undefined): item is T {
    return item !== null && item !== undefined
}

function buildTree({collections, pages, staticContent, valueMap}: buildTreeOptions) {
    const collectionCategory: Item = {
        type: 'category',
        viewType: 'icon',
        title: 'Collection',
        hash: 'collection_category',
        icon: CONTENT_MAP[ContentReferenceType.COLLECTION],
        options: collections
            .filter(notNullablePredicate)
            .map<Item>(col => {
                const value = {
                    contentType: ContentReferenceType.COLLECTION,
                    contentKey: col.id,
                }
                const hash = `col_${col.id}`
                valueMap.set(hash, value)

                return {
                    type: 'selectable',
                    viewType: 'icon',
                    title: col.title,
                    icon: CONTENT_MAP[ContentReferenceType.COLLECTION],
                    hash,
                }
            }),
    }

    const pagesCategory: Item = {
        type: 'category',
        viewType: 'icon',
        hash: 'pages_category',
        title: 'Pages',
        icon: CONTENT_MAP[ContentReferenceType.CONTENT],
        options: pages
            .filter(page => !!page.publishedData)
            .map<Item>(page => {
                const value = {
                    contentType: ContentReferenceType.CONTENT,
                    contentKey: page.id,
                }
                const hash = `page_${page.id}`
                valueMap.set(hash, value)

                return {
                    type: 'selectable',
                    viewType: 'icon',
                    title: page.name,
                    icon: CONTENT_MAP[ContentReferenceType.CONTENT],
                    hash,
                }
            }),
    }

    return [
        ...staticContent,
        collectionCategory,
        pagesCategory
    ]
}

function filterTree(search: string, root: ItemTreeRoot): ItemTreeRoot {
    return  root.reduce<ItemTreeRoot>((newRoot, item) => {
        if (item.type === 'selectable' && item.title.includes(search)) {
            newRoot.push(item)
        } else if (item.type === 'category') {
            const subTree = filterTree(search, item.options)
            if (subTree.length) {
                newRoot.push({
                    ...item,
                    options: subTree
                })
            }
        }

        return newRoot
    }, [])
}