import { ThemeDescriptor } from '../model/theme';
import * as t from 'io-ts';
import Color from 'color';

export const ThemeLightCodec = t.type({
    type: t.literal('light'),
    accent: t.string
});
export type ThemeLight = t.TypeOf<typeof ThemeLightCodec>;
export const ThemeLightDescriptor: ThemeDescriptor<ThemeLight> = {
    key: 'light',
    name: 'Day',
    default: {
        type: 'light',
        accent: '#2F80ED'
    },
    resolve: (src) => {
        return {
            accent: src.accent,
            accentText: Color(src.accent).isDark()? '#fff' : '#000',
            accentIconTint: '#000',

            primary: '#000',
            primaryText: '#000',

            background: '#fff',
            backgroundText: '#000',

            secondary: '#fff',
            secondaryText: '#000',
            separator: '#eee',

            iconTint: '#000',

            bottomNavigationIcon: '#000',
            bottomNavigationText: '#000',
            bottomNavigationAccent: src.accent,
            bottomNavigationBackground: '#fff',
            bottomNavigationInactiveIcon: '#9b9b9b',
            bottomNavigationInactiveText: '#9b9b9b',

            toolBarText: '#000',
            toolBarIcon: '#000',
            toolBarAccent: src.accent,
            toolBarBackground: '#fff',

            priceBackground: '#db7a7a',
            priceOldText: '#e0e0e0',
            priceText: '#424242',

            toCartBackground: src.accent,
            toCartIconTint: Color(src.accent).isDark()? '#fff' : '#000',
            toCartText: Color(src.accent).isDark()? '#fff' : '#000',
        };
    }
}