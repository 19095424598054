import { StyleSheet } from 'react-native'

export const PhonePreviewStyle = StyleSheet.create(
    {
        wrapper: {
            paddingVertical: '2rem',
            paddingLeft: 33,
            overflow: 'hidden',
            flexDirection: 'column',
            // flexGrow: 1,
            // flexBasis: '18rem',
            // flexShrink: 1,
            minWidth: 0,
        },
        header: {
            borderTopLeftRadius: 32,
            borderTopRightRadius: 32,
            height: 72,
            flexDirection: 'column-reverse',
            paddingBottom: 6,
        },
        headerText: {
            fontWeight: '400',
            fontSize: 19,
            alignSelf: 'center',
            marginBottom: 6,
        },
        phone: {
            width: 258,
            height: 549,
            borderRadius: 32,
            borderColor: '#DFE3E8',
            borderWidth: 1,
            flexDirection: 'column',
            alignItems: 'stretch',
            position: 'relative',
        },
        contentWrapper: {
            height: 404,
            position: 'relative'
        },
        content: {
            flexBasis: '100%',
            flexShrink: 1,
            overflow: 'hidden',
            backgroundColor: 'red',
            minHeight: 403
        },
        bottomTip: {
            position: 'absolute',
            height: 20,
            width: 20,
        },
        footer: {
            position: 'relative',
            height: 72,
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            borderBottomLeftRadius: 32,
            borderBottomRightRadius: 32
        },
        dote: {
            height: 24,
            width: 24,
            borderRadius: 12,
        },
        suspense: {
            backgroundColor: 'transparent',
            marginTop: 180,
        },
    },
)
