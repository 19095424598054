import Bugsnag from "@bugsnag/browser";
import Constants from "expo-constants";
import mixpanel from 'mixpanel-browser';


export enum MixpanelEvent {
    CustomColorPicker = 'custom_color_picker',
    ColorPicler = 'color_picker',
    ThemeChooser = 'theme_switch',
    SimulatorTheme = 'simulator_theme_switch',
    MenuAdvanced = 'menu_advanced_opened',
    TabsChange = 'menu_tabs_changed',
    IconPicker = 'icon_picker_toggle',
    onIconChange = 'icon_selected',
    onTabTitleChange = 'tab_title_change',
    onTabContentChange = 'tab_content_change',
    FreeTrafficToggle = 'free_traffic_status_change',
    ForceSynchronization = 'force_synchronization',
    Survey = "survey",
    TrafficExchangeAwesome = 'traffic_exchange_like',
    CreatePush = 'create_push_notification',
    SmartBanner = 'smart_banner',
    OnboardingColorPicker = 'onboarding_colorPicker',
    OnboardingSmartBannerEnable = 'onboarding_smartBanner_enable',
    OnboardingComplete = 'onboarding_complete',
    OnboardingClose = 'onboarding-close',
    ReviewClose = 'review_modal_close',
    ReviewComplete = 'review_link_clicked',
}

export function mixpanelInit() {
    if (__DEV__) {
        return;
    }
    const token = Constants.manifest.extra.mixpanelPublicKey as string;
    console.log('mixpanel token', token);
    mixpanel.init(token);
}

export function trackEvent(name: string, params?: any) {
    if (__DEV__) {
        return;
    }
    try {
        mixpanel.track(name, params);
    } catch (error) {
        Bugsnag.notify(error as Error);
    }
}

export function identifyUser(id: string, name: string) {
    if (__DEV__) {
        return;
    }
    mixpanel.identify(id);
    mixpanel.people.set({ name });
}