import { animated, useTransition } from "@react-spring/native";
import { Spinner, DisplayText } from "@shopify/polaris";
import React, { useState } from "react";
import { View } from "react-native";

export const CreateAccountLoader = React.memo(() => {
    const [step, setStep] = useState(1);

    const transitions = useTransition(step, {
        from: { position: 'absolute', opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        delay: 100,
        config: {
            tension: 280,
            friction: 80,
        },
        onRest: () => {
            if (step !== 3) {
                setStep(step + 1)
            }
        },
    });

    return (
        <View style={{
            height: '100%', width: '100%',
            justifyContent: 'center', alignItems: 'center',
        }}>
            {transitions(({ opacity }, item) => {
                switch (item) {
                    case 1:
                        return <animated.View style={{
                            position: 'absolute',
                            opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
                        }}>
                            <View style={{
                                height: '100%', width: '100%',
                                justifyContent: 'center', alignItems: 'center',
                            }}>
                                <Spinner accessibilityLabel="Loading content" size="large" />
                                <View style={{ marginTop: 12 }}>
                                    <DisplayText size="large">Setting up your account...</DisplayText>
                                </View>
                            </View>
                        </animated.View>;
                    case 2:
                        return <animated.View style={{
                            position: 'absolute',
                            opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
                        }}>
                            <View style={{
                                justifyContent: 'center', alignItems: 'center',
                            }}>
                                <Spinner accessibilityLabel="Loading content" size="large" />
                                <View style={{ marginTop: 12 }}>
                                    <DisplayText size="large">Setting up your app...</DisplayText>
                                </View>
                            </View>
                        </animated.View>
                    case 3:
                        return <animated.View style={{
                            position: 'absolute',
                        }}>
                            <View style={{
                                justifyContent: 'center', alignItems: 'center',
                            }}>
                                <Spinner accessibilityLabel="Loading content" size="large" />
                                <View style={{ marginTop: 12 }}>
                                    <DisplayText size="large">Starting your app...</DisplayText>
                                </View>
                            </View>
                        </animated.View>
                    default:
                        return <></>
                }
            })}
        </View>
    );
});