import { Button, Card, Heading, Layout, Page, Stack, TextContainer, TextStyle } from "@shopify/polaris";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { AppBannerInputParams } from "../../api/spacex.types";
import { useClient } from "../../api/useClient";
import { KnobFile } from "../../configEditor/knobs/components/KnobFile";
import { FontWeightType } from "../../configEditor/knobs/types";
import { loadThemeSet, resolveThemeSet, ThemeSet } from "../../configure/theme/themes/themes";
import { useSaver } from "../../configure/utils/useSaver";
import { MixpanelEvent, trackEvent } from "../../utils/mixpanel";


const bannerScreenshot = require('../../assets/images/smartBanner_screenshot.png');
const logoScreenshot = require('../../assets/images/logo_screenshot.png');
const sync = require('../../assets/images/sync-img.png');

export const Wizard = React.memo((
) => {
    const [closed, setClosed] = useState(false);

    const client = useClient();
    const apps = client.useAllApps().apps;
    const sortedApps = useMemo(() => apps.sort((a, b) => a.name.localeCompare(b.name)), [apps]);
    const activeApps = useMemo(() => sortedApps.filter(app => app.active), [sortedApps]);
    const appId = activeApps[0].id;
    const appConfig = client.useAppConfig({ id: appId }).app;
    const app = client.useGetAppSettings({ id: appId }).app;
    const brandingLogo = appConfig.settings?.logo;

    const initialDesignerData = appConfig.draftConfig.design.designerData;

    const initialThemes = loadThemeSet(initialDesignerData);
    const onSaveCb = useCallback(async (updated) => {
        console.log('updated_', updated);
        console.log('update', resolveThemeSet(updated));
        const result = await client.mutateUpdateApp({
            id: appId,
            config: {
                design: {
                    designerData: JSON.stringify(updated),
                    colors: {
                        ...resolveThemeSet(updated),
                    },
                },
            },
        });
        console.log('result', result);
    }, []);

    const [onboardingForm, setOnboardingForm] = useState<AppBannerInputParams>({
        icon: app.settings?.banner?.params?.icon || brandingLogo || '',
        title: app.settings?.banner?.params?.title || '',
        titleColor: app.settings?.banner?.params?.titleColor,
        titleFontWeight: app.settings?.banner?.params?.titleFontWeight as FontWeightType,
        text: app.settings?.banner?.params?.text || '',
        textColor: app.settings?.banner?.params?.textColor,
        textFontWeight: app.settings?.banner?.params?.textFontWeight,
        buttonText: app.settings?.banner?.params?.buttonText || '',
        buttonTextColor: app.settings?.banner?.params?.buttonTextColor,
        buttonTextFontWeight: app.settings?.banner?.params?.buttonTextFontWeight,
        backgroundColor: app.settings?.banner?.params?.backgroundColor,
        stickToBottom: app.settings?.banner?.params?.stickToBottom,
        fullScreen: app.settings?.banner?.params?.fullScreen
    });

    const [logo, setLogo] = useState<string | null>(appConfig.settings?.logo || null);
    const [pageUpdated, setModalUpdated] = useState<number>(1);
    const [bannerEnabled, setBannerEnabled] = useState<boolean>(Boolean(app.settings?.banner?.scriptTagId));
    const [bannerLoading, setBannerLoading] = useState<boolean>(false);

    const [themeSet, loading, setThemeSet] = useSaver<ThemeSet>(initialThemes, onSaveCb);

    const defaultBannerProps = useMemo(() => {
        return {
            icon: brandingLogo ? brandingLogo : '',
            title: app.name,
            titleColor: '#fff',
            titleFontWeight: '700',
            text: "Our store is much more convenient to use in a mobile app!",
            textColor: '#fff',
            textFontWeight: '400',
            buttonText: "Install",
            buttonTextColor: "#fff",
            buttonTextFontWeight: "400",
            backgroundColor: '#555555',
            stickToBottom: false as boolean,
            fullScreen: true as boolean
        }
    }, [app.name, brandingLogo]);

    const handleUpdateLogo = useCallback((logo) => {
        if (typeof logo === "string" && logo.length === 0) {
            logo = null;
        }
        client.mutateUpdateApp({
            id: appId,
            config: {
                logo
            },
        })
            .then((res) => {
                console.log('logo updated', res);
                client.refetchAppConfig({ id: appId })
                    .then((res) => {
                        console.log('refetch res', res);
                        trackEvent(MixpanelEvent.OnboardingColorPicker);
                    })
                    .catch((e) => { console.warn('refetch error', e) });
            })
            .catch((err) => { console.warn('logo not updated', err) });
    }, [client, logo]);

    const history = useHistory()

    const Redirection = useCallback(() => {
        history.push('/')
    }, [])

    const handleFormSubmit = useCallback(() => {
        if (!onboardingForm) return;

        let newIcon = onboardingForm?.icon
            ? onboardingForm.icon
            : brandingLogo
                ? brandingLogo
                : '';
        let input: AppBannerInputParams = { ...defaultBannerProps };
        input.icon = newIcon;
        setBannerLoading(true);
        console.log("Start update banner", input);
        client.mutateUpdateBannerParams({ appId: appId, input })
            .then((result) => {
                if (result.editBannerParams?.settings?.banner?.params) {
                    setOnboardingForm(result.editBannerParams.settings.banner.params);
                }
                console.log("Banner updated", result);
                Redirection();
            })
            .catch((err) => {
                console.error(err);
            }).finally(() => {
                setBannerLoading(false);
                setClosed(true);
                trackEvent(MixpanelEvent.OnboardingComplete);
            });
    }, [brandingLogo, onboardingForm, appId, defaultBannerProps, setOnboardingForm]);

    const handleIconChange = useCallback((value) => {
        setLogo(value);
        handleUpdateLogo(value);
    }, [setLogo, handleUpdateLogo]);

    const handleBannerEnabledChange = useCallback((value: boolean) => {
        // setBannerLoading(true);
        console.log('Banner state change');
        if (value) {
            client.queryEnableBanner({ appId: appId })
                .then((result) => {
                    // setBannerLoading(false);
                    setBannerEnabled(value);
                    console.log('Banner enabled', result);
                    trackEvent(MixpanelEvent.OnboardingSmartBannerEnable, { enabled: true });
                })
                .catch((err) => {
                    // setBannerLoading(false);
                    console.error(err);
                });
        } else {
            client.queryDisableBanner({ appId: appId })
                .then((result) => {
                    // setBannerLoading(false);
                    setBannerEnabled(value);
                    console.log('Banner disabled', result);
                    trackEvent(MixpanelEvent.OnboardingSmartBannerEnable, { enabled: false });
                })
                .catch((err) => {
                    // setBannerLoading(false);
                    console.error(err);
                });
        }
    }, [appId]);


    return (
        <Page
            title={'Initial setup'}
        >
            <Layout>
                <Layout.Section>
                    {
                        ({
                            1: <>
                                <Card>
                                    <Card.Section>
                                        <TextContainer>
                                            <Heading>
                                                Your store logo
                                            </Heading>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '15px', }}>
                                                <TextStyle>
                                                    Customers will see your store logo at the top of the app. You can always change it by going to <b>Customize -&gt; Branding</b>.
                                                </TextStyle>
                                                <img src={logoScreenshot} alt="" style={{ maxWidth: '35%' }} />
                                            </div>
                                        </TextContainer>
                                    </Card.Section>
                                    <Card.Section>
                                        <Heading>
                                            {'Upload logo'}
                                        </Heading>
                                        <KnobFile knob={{
                                            type: 'file' as const,
                                            defaultValue: '',
                                            description: '',
                                            clearable: true,
                                        }}
                                            value={logo}
                                            innerKey={"AppLogo"}
                                            onChange={
                                                (knobValue, key: string) => handleIconChange(knobValue)
                                            }
                                            dropZoneProps={{
                                                accept: "image/*"
                                            }}
                                        />

                                    </Card.Section>
                                    <Card.Section>
                                        <Stack distribution={'trailing'}>
                                            <Stack.Item>
                                                <Button
                                                    primary
                                                    onClick={() => setModalUpdated(pageUpdated + 1)}
                                                >
                                                    Next
                                                </Button>
                                            </Stack.Item>
                                        </Stack>
                                    </Card.Section>
                                </Card>
                            </>,
                            2: <>
                                <Card>
                                    <Card.Section>
                                        <TextContainer>
                                            <Heading>
                                                App Promotion Banner
                                            </Heading>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '200px' }}>
                                                <div style={{maxWidth:'400px'}}>
                                                    <TextStyle>
                                                        We recommend including the App Promotion Banner right away because it's the best way to tell customers about your mobile store. It invites customers to shop through the mobile app. The more customers who install the app, the more money you make💰 You can enable, disable or customize it by going to  <b>Customize -&gt; App Promotion Banner</b>.
                                                </TextStyle>
                                                </div>
                                                <img src={bannerScreenshot} alt="" style={{ maxWidth: '15%' }} />
                                            </div>
                                        </TextContainer>
                                    </Card.Section>
                                    <Card.Section>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div style={{ width: '70%' }}>
                                                App Banner is <TextStyle variation={bannerEnabled ? 'positive' : 'negative'}>{bannerEnabled ? <b>enabled</b> : <b>disabled</b>}</TextStyle>. <br />
                                                Click Enable to activate your banner -&gt;
                                            </div>
                                            <Button destructive={bannerEnabled} primary onClick={() => handleBannerEnabledChange(!bannerEnabled)}>{bannerEnabled ? "Disable" : "Enable"}</Button>
                                        </div>
                                    </Card.Section>
                                    <Card.Section>
                                        <Stack distribution={'equalSpacing'}>
                                            <Stack.Item>
                                                <Button onClick={() => setModalUpdated(pageUpdated - 1)}>Back</Button>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Button
                                                    primary
                                                    onClick={() => setModalUpdated(pageUpdated + 1)}
                                                >
                                                    Next
                                                </Button>
                                            </Stack.Item>
                                        </Stack>
                                    </Card.Section>
                                </Card>
                            </>,
                            3: <>
                                <Card>
                                    <Card.Section>
                                        <TextContainer>
                                            <Heading>
                                                Your products are in sync
                                     </Heading>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                                                <TextStyle>
                                                    We are synchronizing your store with the Cartmate app now. This process will take from 5 to 120 minutes depending on the number of your items. You will be able to use your mobile store as soon as the synchronization is complete. Thanks for the wait!
                                         </TextStyle>
                                                <img src={sync} alt="" style={{ width: '40%' }} />
                                            </div>
                                        </TextContainer>
                                    </Card.Section>
                                    <Card.Section>
                                        <Stack distribution={'equalSpacing'}>
                                            <Stack.Item>
                                                <Button onClick={() => setModalUpdated(pageUpdated - 1)}>Back</Button>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Button
                                                    primary
                                                    onClick={handleFormSubmit}
                                                    disabled={!onboardingForm.title || onboardingForm.title.length === 0}
                                                    loading={bannerLoading}>
                                                    Got it!
                                         </Button>
                                            </Stack.Item>
                                        </Stack>
                                    </Card.Section>
                                </Card>
                            </>
                        } as any)[pageUpdated]
                    }
                </Layout.Section>
            </Layout>
        </Page >
    );
});