import React from "react";
import { View } from "react-native";
import { AppEmulatorConfig } from "../types";
import { useEmulatorConfig } from "./ScalableEmulatorComponent";

export const EmulatorNewTab = React.memo(({ config }: { config: AppEmulatorConfig, }) => {
    const emulator = useEmulatorConfig();
    const textContent = 'You can select icon, title and content type';
    return (
        <>
            <View
                style={{
                    height: '100%',
                    width: '100%'
                }}
            >
                <p
                    style={{
                        padding:  '0 40px',
                        marginLeft: `${emulator.isSmall ? '10px' : '-10px'}`,
                        color: config.design.colors[emulator.theme].primaryText,
                        fontSize: '44px',
                        textAlign: "center",
                        marginTop: '50%'
                    }}
                >
                    {textContent}
                </p>
            </View>
        </>
    );
})