import React from 'react'
import { Button, ButtonProps } from '@shopify/polaris';
import { IconView } from '../../types';
import { View, StyleSheet } from 'react-native';
import { useFont, useFontComponent } from '../IconPicker/iconsData';
import { Icon, IconProps } from '@expo/vector-icons/build/createIconSet';

const style = StyleSheet.create({
    iconWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        backgroundColor: '#F5F5F6',
        borderRadius: 3
    }
})

export const IconWrapper = React.memo<React.PropsWithChildren<{}>>(({children}) => {
    return (
        <View style={style.iconWrapper}>
            {children}
        </View>
    )
})

type Props = {
    value: IconView | null
}

export const IconViewButton = React.memo<Props & ButtonProps>(({value, ...buttonProps}) => {
    const [font, loading] = useFont(value?.collection)
    const Icon = (font?.iconSet &&  <font.iconSet name={value?.name} size={18}/> || <View/>) as React.ReactElement
    return (
        <Button {...buttonProps}
                loading={loading}
                disabled={loading}
                icon={Icon}/>
    )
})

export const DynamicIcon = React.memo<Props & Omit<IconProps<any>, 'name'>>(({value, ...props}) => {

    const Icon = useFontComponent(value?.collection)

    return <Icon name={value?.name} {...props}/>
})