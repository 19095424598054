import { useClient } from '../../api/useClient';
import React, { useCallback, useState } from 'react';
import { Toast } from '@shopify/polaris'
import { Page_page } from '../../api/spacex.types';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/es6/Either';
import { ViewConfig } from '../../configEditor/ConfigEditor';
import { initRenderBlock } from '../../configEditor/subRender/subRenderUtils';

export function useRevertAction(page: Page_page, onPagesChange:(items:ViewConfig.NodeBlockExtended[]) => void) {
    const client = useClient()

    const [active, setActive] = useState(false);

    const toggleClose = useCallback(() => setActive((active) => false), []);

    const toastMarkup = active ? (
        <Toast content="Reverted" onDismiss={toggleClose}/>
    ) : null;

    const onPublish = useCallback(() => {
        if (page.publishedData) {
            const revertedPage:Page_page = {
                ...page,
                draftData: page.publishedData
            }

            const content = pipe(ViewConfig.PageToSeverPage.decode(revertedPage), getOrElse(() => (null as ViewConfig.Page | null)))
            if (content) {
                onPagesChange(content.blocks.map(b => initRenderBlock(b)))
                const data = ViewConfig.PageToInputPage.encode(content)
                client.mutateUpdatePage({id: page.id, input: data}).then(() => setActive(true))
            }
        }
    }, [page.publishedData, page.id, onPagesChange])

    return [toastMarkup, onPublish, toggleClose] as const
}