import { useAppBridge } from "@shopify/app-bridge-react";
import { Page, Layout, Card, Link } from "@shopify/polaris";
import React from "react";
import { View } from "react-native";
import { useHistory, useParams } from "react-router";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";

const pushGif = require('../../../assets/gifs/push-platform.gif');
const pushImageGif = require('../../../assets/gifs/push-image.gif');
const pushRouteGif = require('../../../assets/gifs/push-route.gif');

export const Push = React.memo(() => {
    const appBridge = useAppBridge();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    return (<>
        <Page
            title={'Send platform-targeted (iOS / Android) push notifications'}
            breadcrumbs={[{
                content: 'About',
                onAction: () => { history.length > 2 ? history.goBack() : history.push({ pathname: '/about' }) }
            }]
            }
        >
            <Layout>
                <Layout.Section>
                    {/* <View style={{ marginVertical: 16 }}>
                        <DisplayText size={'medium'}>
                            {'Engage with your customers'}
                        </DisplayText>
                    </View> */}
                    <Card title={'Send platform-targeted (iOS / Android) push notifications'} sectioned>
                        <p>
                            You can send push notifications to your customers in the <Link onClick={() => {
                                const redirect = ShopifyRedirect.create(appBridge);
                                redirect.dispatch(ShopifyRedirect.Action.APP, '/notifications');
                            }}>
                                Push notifications
                            </Link>
                            {' '}tab.
                        </p>
                        <p>
                            Every Cartmate App user who has added your store to their favorite stores list and has granted push notification permissions will receive notifications. You can select a specific platform or send out notifications to all of your customers by editing the push notification title message.
                        </p>
                        <View style={{
                            justifyContent: 'flex-start', alignItems: 'center',
                            width: '100%', marginVertical: 16,
                            flexDirection: 'row'
                        }}>
                            <img src={pushGif} alt="push-platform" />
                        </View>
                        <p>
                            Additionally, you can upload an image that will be shown in the notification.
                        </p>
                        <View style={{
                            justifyContent: 'flex-start', alignItems: 'center',
                            width: '100%', marginVertical: 16,
                            flexDirection: 'row'
                        }}>
                            <img src={pushImageGif} alt="push-image" />
                        </View>
                        <p>
                            And select where to navigate your customer to when they open the notification.
                        </p>
                        <View style={{
                            justifyContent: 'flex-start', alignItems: 'center',
                            width: '100%', marginVertical: 16,
                            flexDirection: 'row'
                        }}>
                            <img src={pushRouteGif} alt="push-route" onClick={() => {
                                history.push({pathname: '/plugin/kad'});
                            }}/>
                        </View>
                    </Card>
                    <div style={{ height: 100 }} />
                </Layout.Section>
            </Layout>
        </Page>
    </>
    )
});