import Cookies from "js-cookie";
import { getEndpoint } from "../api/getEndpoint";

export async function authenticateBySessionToken(sessionToken: string) {
    console.log('[auth]: session token', sessionToken);
    const answer: { token?: string | null } = await fetch(getEndpoint() + '/shopify/auth/session-token', {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: sessionToken })
    }).then(res => res.json());


    if (typeof answer.token !== "string") {
        throw new Error('Invalid response');
    }
    return answer.token!;
}

export async function authenticationUrl(shop: string) {

    const answer = await fetch(getEndpoint() + '/shopify/auth/link?shop=' + shop, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

    if (typeof answer.url !== "string") {
        throw new Error('Invalid response');
    }
    return answer.url!;
}

export async function authenticateByOAuth(authParams: any) {    
    console.log('[auth]: shopify auth', authParams);
    const ref = Cookies.get('ref');
    let requestUrl = getEndpoint() + '/shopify/auth/oauth';
    if (ref) {
        requestUrl += '?ref='+ref;
    }
    const answer = await fetch(requestUrl, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',            
        },
        body: JSON.stringify({ shopify: authParams })
    }).then(res => res.json());

    if (typeof answer.token !== "string") {
        throw new Error('Invalid response');
    }
    if (ref) {
        Cookies.remove('ref', {path: '/', secure: true, sameSite: 'None', domain: '.cartmate.com'});
    }
    return answer.token!;
}