import { config, useTransition } from "@react-spring/core";
import { animated } from "@react-spring/native";
import { CalloutCard, Card } from "@shopify/polaris";
import React from "react";
import { useHistory, useRouteMatch } from "react-router";
import { useInfoBanner } from "../../../../components/banners/useInfoBanner";
import { TipKey } from "../../../../components/banners/useTip";

const communityIcon = require('../../../../assets/images/logo-chats.svg');

export const CommunityCalloutCard = React.memo(() => {
    const history = useHistory<{ from?: Location }>();
    const match = useRouteMatch();

    const [communityBanner] = useInfoBanner(
        TipKey.TrafficExchangeCommunityInfo,
        'Find new friends with Cartmate Community',
        'You can find fellow Shopifyers who would like to connect and exchange traffic'
    );

    const closeCommunityBanner = useTransition(communityBanner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    });

    const communityBannerView = closeCommunityBanner(
        (style, banner) => banner
            && <animated.View style={[{ marginBottom: '2rem' }, style]}>
                <Card>{banner}</Card>
            </animated.View>
    );

    return (
        <CalloutCard
            title="Find your fellow Shopifiers via Cartmate Community"
            illustration={communityIcon}
            primaryAction={{
                content: 'To the Community',
                onAction: () => {
                    if (__DEV__) history.push(`${match.url}/community`);
                },
            }}
        >
            {communityBannerView}
            <p>Find new Shopify mates with Cartmate! Meet, advertise each other's products and find new customers every day.</p>
        </CalloutCard>
    );
});