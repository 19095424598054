import { Badge, Card, EmptyState, Heading, Icon, Layout, Page, Pagination, ResourceList, Stack, TextStyle, Tooltip } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ListPushMailing_listPushMailing, ListPushMailing_listPushMailing_edges_node, PushMailingPlatform, PushMailingStatus } from "../api/spacex.types";
import { useClient } from "../api/useClient";
import { PushOnboarding } from "../components/onboarding/PushOnboarding";
import { useAccessControl } from "../configure/utils/useAccessControl";
import { IconElement } from "../content/elements/IconElement";
import { PushLimitComponent } from "./PushLimitComponent";

export const ListPushMailing = React.memo(() => {
    const client = useClient();
    const [results, setResults] = useState<ListPushMailing_listPushMailing>();
    const [loading, setLoading] = useState<boolean>(false);
    const pageSize = 8;
    const limit = client.useAccounPricingLimit({ key: 'pushPerWeek' }).accountPricingLimit;
    const loadNextPage = useCallback(() => {
        setLoading(true);
        client.queryListPushMailing({
            first: pageSize,
            after: results?.pageInfo.endCursor,
        })
            .then((result) => {
                // console.log('Nex page loaded', result);
                setResults(result.listPushMailing);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [client, results]);

    const loadPrevPage = useCallback(() => {
        if (!results?.pageInfo.hasPreviousPage) {
            throw new Error("No previous page");
        }
        setLoading(true);

        client.queryListPushMailing({
            last: pageSize,
            before: results.pageInfo.startCursor,
        })
            .then((result) => {
                console.log('Next page loaded', result);
                setResults(result.listPushMailing);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [pageSize, results]);

    useEffect(() => {
        loadNextPage();
    }, []);

    return (
        <Page
            title={"Push mailings"}
            primaryAction={{
                content: "Create",
                url: '/push/mailing/create',
                disabled: limit ? (limit.count >= limit.limit) : true
            }}
        >
            <Layout>
                <Layout.Section>
                    <Card
                        title={(
                            <Stack distribution={'leading'} alignment={'center'}>
                                <Stack.Item fill>
                                    <Heading>Push mailings lims</Heading>
                                </Stack.Item>
                                <Stack.Item>
                                    <PushLimitComponent />
                                </Stack.Item>
                            </Stack>
                        )}
                    >
                        <Card.Section>
                            {(results?.edges.length === 0) && <EmptyState
                                heading="No mailings yet"
                                image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png" />}
                            {(results?.edges && results?.edges.length > 0) && (
                                <ResourceList
                                    items={results?.edges.map((e) => e.node)}
                                    loading={loading}
                                    renderItem={(i) => {
                                        return (
                                            <ResourceList.Item url={`/push/mailing/${i.id}/edit`} id={i.id} >
                                                <PushMailingListItem node={i} key={'pm-w-' + i.id} />
                                            </ResourceList.Item>
                                        );
                                    }}
                                />
                            )}
                        </Card.Section>
                        {(results?.pageInfo.hasNextPage || results?.pageInfo.hasPreviousPage) && <Card.Section>
                            <Stack distribution={"center"}>
                                <Pagination
                                    hasPrevious={results?.pageInfo.hasPreviousPage}
                                    onPrevious={() => {
                                        loadPrevPage()
                                    }}
                                    hasNext={results?.pageInfo.hasNextPage}
                                    onNext={() => {
                                        loadNextPage()
                                    }}
                                />
                            </Stack>
                        </Card.Section>}
                    </Card>
                </Layout.Section>
            </Layout>
            <PushOnboarding/>
        </Page >
    );
});

const PushMailingListItem = React.memo(({ node: initialValue }: { node: ListPushMailing_listPushMailing_edges_node }) => {
    const client = useClient();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(initialValue);
    const ac = useAccessControl();
    useEffect(() => {
        const updateInterval = setInterval(() => {
            if (value.status === PushMailingStatus.SCHEDULED && !loading) {
                setLoading(true);
                client.queryPushMailing({ id: value.id }, { fetchPolicy: "network-only" })
                    .then((result) => {
                        setLoading(false);
                        setValue(result.pushMailing);
                    })
                    .catch((e) => {
                        setLoading(false);
                        clearInterval(updateInterval);
                        console.warn(e);
                        console.log('[PushMailingListItem]: Clear interval error: ', value.id);
                    });
            } else if (value.status !== PushMailingStatus.SCHEDULED) {
                clearInterval(updateInterval);
            }
        }, 750);
        return () => {
            clearInterval(updateInterval);
        }

    }, [value, loading]);


    const [badge, additionalDate, counters, icon] = useMemo(() => {
        let badge: JSX.Element,
            additionalDate: JSX.Element | undefined,
            counters: JSX.Element | undefined = undefined,
            icon: JSX.Element;
        switch (value.target.platform) {
            case PushMailingPlatform.ALL:
                icon = (
                    <Tooltip content={"All"}>
                        <IconElement
                            name={'megaphone'}
                            collection={'ionicons'}
                            size={18}
                        />
                    </Tooltip>
                );
                break;
            case PushMailingPlatform.ANDROID:
                icon = (
                    <Tooltip content={"Android"}>
                        <IconElement
                            name={'logo-android'}
                            collection={'ionicons'}
                            size={18}
                        />
                    </Tooltip>
                );
                break;
            case PushMailingPlatform.IOS:
                icon = (
                    <Tooltip content={"IOS"}>
                        <IconElement
                            name={'logo-apple'}
                            collection={'ionicons'}
                            size={18}
                        />
                    </Tooltip>
                );
                break;
        }
        switch (value.status) {
            case PushMailingStatus.DRAFT:
                badge = (<Badge key={`info-${value.id}`} status={"info"} progress={loading ? "partiallyComplete" : "incomplete"}>Draft</Badge>);
                additionalDate = undefined;
                break;
            case PushMailingStatus.COMPLETE:
                badge = (<Badge key={`success-${value.id}`} status={"success"} progress={loading ? "partiallyComplete" : "complete"}>Complete</Badge>);
                additionalDate = value.sentAt ? (
                    <Stack.Item>
                        <TextStyle>Sent at: </TextStyle>
                        <TextStyle variation={"strong"}>{moment(parseInt(value.sentAt)).format('DD.MM.YYYY HH:mm')}</TextStyle>
                    </Stack.Item>
                ) : undefined;
                counters = (
                    <Stack.Item>
                        <TextStyle>Opens: </TextStyle>
                        <TextStyle variation={"strong"}>{value.openingCount}</TextStyle>
                    </Stack.Item>
                );
                break;
            case PushMailingStatus.SCHEDULED:
                let delta = Math.abs(Math.round(Date.now() / 1000) - Math.round(parseInt(value.scheduledAt || '0') / 1000));
                if (delta < 30) {
                    badge = (<Badge key={`warnong-${value.id}`} status={"attention"} progress={loading ? "incomplete" : "complete"}>Sending</Badge>);
                } else {
                    badge = (<Badge key={`warnong-${value.id}`} status={"warning"} progress={loading ? "incomplete" : "complete"}>Scheduled</Badge>);
                }

                additionalDate = value.scheduledAt ? (
                    <Stack.Item>
                        <TextStyle>Scheduled at: </TextStyle>
                        <TextStyle variation={"strong"}>{moment(parseInt(value.scheduledAt)).format('DD.MM.YYYY HH:mm')}</TextStyle>
                    </Stack.Item>
                ) : undefined;
                break;
            case PushMailingStatus.FAIL:
                badge = (<Badge key={`critiacal-${value.id}`} status={'critical'} progress={loading ? "partiallyComplete" : "incomplete"} >Fail</Badge>);
                additionalDate = value.sentAt ? (
                    <Stack.Item>
                        <TextStyle>Sent at: </TextStyle>
                        <TextStyle variation={"strong"}>{moment(parseInt(value.sentAt)).format('DD.MM.YYYY HH:mm')}</TextStyle>
                    </Stack.Item>
                ) : undefined;
                break;
            case PushMailingStatus.CANCELED:
                badge = (<Badge key={`critiacal2-${value.id}`} status={'critical'} progress={loading ? "partiallyComplete" : "complete"} >Canceled</Badge>);
                additionalDate = undefined;
                break;
        }
        return [badge, additionalDate, counters, icon];
    }, [value, loading]);


    const handleCancelMailing = useCallback(() => {
        client.mutateStopPushMailing({ id: value.id })
            .then((result) => {
                setValue(result.stopPushMailing);
            })
            .catch((e) => {
                console.warn(e);
            });
    }, [value]);
    const handleSendNowMailing = useCallback(() => {
        client.mutateSendPushMailing({ id: value.id })
            .then((result) => {
                setValue(result.sendPushMailing);
            })
            .catch((e) => {
                console.warn(e);
            });
    }, [value]);

    return (

        <Stack vertical alignment={"leading"} distribution={'leading'}>
            <Stack.Item>
                <Stack distribution={"leading"} alignment={"center"} spacing={"tight"}>
                    <Stack.Item>
                        {icon}
                    </Stack.Item>
                    <Stack.Item>
                        <TextStyle variation={"strong"}>{value.content.title}</TextStyle>
                    </Stack.Item>
                    <Stack.Item>
                        {badge}
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <Stack distribution={"leading"}>
                    <Stack.Item>
                        <TextStyle>Created at: </TextStyle>
                        <TextStyle variation={"strong"}>{moment(parseInt(value.createAt)).format('DD.MM.YYYY HH:mm')}</TextStyle>
                    </Stack.Item>
                    {additionalDate}
                    {counters}
                </Stack>
            </Stack.Item>
        </Stack>

    );
});