import React from 'react';
import Select, { NamedProps } from 'react-select';
import { components } from 'react-select';
import DropDownIcon from './DropdownIcon'
import { OptionTypeBase } from 'react-select/src/types';
import { IndicatorProps } from 'react-select/src/components/indicators';
import { TypographyStyle } from '../Typography/commonTypography';


export type SelectOption<T = any> = {
    value: T,
    label: React.ReactNode
}

export type StyledSelectProps = NamedProps<SelectOption, false>

const styleConfig: NamedProps['styles'] = {
    menu: base => ({
        ...base,
        marginTop: 4,
        marginBottom: 4
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    container: base => ({
        ...base,
        fontFamily: TypographyStyle.fontFamily,
    }),
    menuList: base => ({
        ...base,
        padding: 0,
        borderRadius: 4,
        fontFamily: TypographyStyle.fontFamily
    }),
    indicatorsContainer: base => ({ ...base, cursor: 'pointer' }),
    option: base => ({ ...base, cursor: 'pointer' })
}

const DropdownIndicator = <OptionType extends OptionTypeBase, IsMulti extends boolean>(
    props: IndicatorProps<OptionType, IsMulti>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <DropDownIcon />
        </components.DropdownIndicator>
    );
};

const componentsConfig = {
    DropdownIndicator
}

export type StyledSelectRef = Select<SelectOption, false>

export const StyledSelect = React.forwardRef<StyledSelectRef, StyledSelectProps>((props, ref) => (
    <Select
        ref={ref}
        menuPortalTarget={document.body}
        {...props}
        isMulti={false}
        components={componentsConfig}
        styles={styleConfig}
    />
))