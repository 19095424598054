import React from 'react'
import { Stack, Icon, SkeletonDisplayText } from '@shopify/polaris';
import { ContentReferenceType } from '../../../api/spacex.types';
import {
    CartMajor,
    CollectionsMajor,
    FeaturedContentMajor, ImageWithTextMajor,
    RiskMinor,
} from '@shopify/polaris-icons';
import { IconSource } from '@shopify/polaris/dist/types/3.4/src/types';
import { DynamicIcon } from '../../Icon/IconView/Icons';
import { IconView } from '../../types';
import { CONTENT_MAP } from '../ContentPicker/categoryDataTree';

type Props = {
    contentType: ContentReferenceType
    text?: string
    loading?: boolean
}

export const ContentView = React.memo<Props>(({contentType, text, loading}) => (
    <Stack distribution="fill" alignment={'center'}>
        <Stack.Item>
            <DynamicIcon size={20} value={CONTENT_MAP[contentType]}/>
        </Stack.Item>
        <Stack.Item>
            {loading && <SkeletonDisplayText size="small" /> || text}
        </Stack.Item>
    </Stack>
))