import { RenderDNDElementProps, SortableDndList } from '../../components/sortableDNDList/SortableDndList';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { Badge, Button, Card, EmptyState, Icon, Stack, TextStyle, UnstyledLink } from '@shopify/polaris';
import { DeleteMinor, DragHandleMinor, EditMinor } from '@shopify/polaris-icons';
import { useClient } from '../../api/useClient';
import { View } from 'react-native';
import { TabDesigner } from '../../components/types';
import { DynamicIcon, IconWrapper } from '../../components/Icon/IconView/Icons';
import { useSaver } from '../utils/useSaver';
import { MixpanelEvent, trackEvent } from '../../utils/mixpanel';
import { useAccessControl } from '../utils/useAccessControl';
import { Capability } from '../../utils/enums';
import { AccessControl } from 'accesscontrol';
import Bugsnag from '@bugsnag/js';

function getItemId(tab: TabDesigner) {
    return tab.key
}

export const AppMenuItems = React.memo(() => {
    const { id } = useParams<{ id: string }>();
    const client = useClient();
    const ac = useAccessControl();
    const serverTabsDesigner = client.useAppTabsConfig({ id }).app.draftConfig.tabsDesigner.map(i => {
        delete i.__typename
        delete i.icon?.__typename
        return i
    });

    console.log('Menu items rerendered', serverTabsDesigner);

    const onSave = useCallback((tabs: TabDesigner[]) => {
        console.log('[AppMenuItems]: onSave', tabs);
        return client.mutateUpdateApp({
            id,
            config: {
                tabs,
            },
        }).then((result) => {
            console.log('[AppMenuItems]: updated result', result.appUpdate.draftConfig.tabsDesigner);
            client
                .refetchAppTabsConfig({ id })
                .then((res) => {
                    console.log('[AppMenuItems]: App tabs config refetched', res);
                });
            trackEvent(MixpanelEvent.TabsChange, { tabs });
        }).catch(e => {
            Bugsnag.notify(e);
        })
    }, [client, id]);

    const [tabs, loading, onTabChange] = useSaver<TabDesigner[]>(serverTabsDesigner, onSave);

    const match = useRouteMatch();

    const primaryFooterAction = (tabs.length < 5 && ac.can('user').createOwn(Capability.MainMenu).granted) ? {
        content: 'Add item',
        url: `${match.url}/new`,
    } : undefined
    if (!ac.can('user').readOwn(Capability.MainMenu).granted) {
        return (
            <Card sectioned>
                <EmptyState
                    heading="Not allowed"
                    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                >
                    <p>You do not have required permissions</p>
                </EmptyState>
            </Card>
        );
    }
    return (

        <Card
            title='Bottom navigation panel'
            primaryFooterAction={primaryFooterAction}
            footerActionAlignment={'right'}
        >
            <SortableDndList
                key={`SortableDndList-${tabs.length}`}
                droppableKey={`MoreDroppable-${tabs.length}`}
                items={tabs}
                getKey={getItemId}
                onChangeList={onTabChange}
                conformOnRemove={true}
                renderItem={(props) => {
                    return renderItem(props, ac);
                }}
            />
        </Card>

    );
})

function renderItem({
    entity,
    provided,
    snapshot,
    onRemove,
}: RenderDNDElementProps<TabDesigner>, ac: AccessControl): React.ReactElement<HTMLElement> {
    const {
        key,
        title,
        default: isDefault,
        contentType,
        contentKey,
        icon,
    } = entity


    const match = useRouteMatch()

    return (<View style={{ height: 40 }} key={key}>
        <Stack alignment="center" distribution="center">
            <Stack.Item fill>
                <UnstyledLink url={`${match.url}/item/${entity.key}`}>
                    <Stack alignment="center">
                        <Stack.Item>
                            <IconWrapper>
                                <DynamicIcon value={icon} size={22} />
                            </IconWrapper>
                        </Stack.Item>
                        <Stack.Item fill>
                            <TextStyle variation="strong">{title}</TextStyle>
                            {isDefault && <Badge>default</Badge>}
                        </Stack.Item>
                    </Stack>
                </UnstyledLink>
            </Stack.Item>
            {ac.can('user').updateOwn(Capability.MainMenu).granted && (
                <Stack.Item>
                    <UnstyledLink url={`${match.url}/item/${entity.key}`}>
                        <Icon
                            source={EditMinor}
                            color="base"
                        />
                    </UnstyledLink>
                </Stack.Item>
            )}
            {(!isDefault && ac.can('user').deleteOwn(Capability.MainMenu).granted) && (
                <Stack.Item>
                    <Button
                        plain
                        onClick={onRemove}
                        icon={<Icon source={DeleteMinor} />}
                    />
                </Stack.Item>
            )}
            {ac.can('user').updateOwn(Capability.MainMenu).granted && (
                <Stack.Item>
                    <div {...provided.dragHandleProps}>
                        <Button
                            plain
                            icon={<Icon source={DragHandleMinor} />}
                        />
                    </div>
                </Stack.Item>
            )}
        </Stack>
    </View>
    );
}