import React, { useRef } from 'react'
import { View, StyleSheet, ScrollView, Text } from 'react-native';
import { IconPickerListStyle } from './commonViewStyle';
import { FlatIconCollectionTitleWrapper } from './iconsData';
import { Stack } from '@shopify/polaris';
import { IconElement } from '../../../content/elements/IconElement';
import { FavoriteIconCollectionTitleWrapper } from './FavoritesIcons';

type Props = {
    currentActiveIndex: number
    collections: FlatIconCollectionTitleWrapper[]
    onClick: (item: FlatIconCollectionTitleWrapper | FavoriteIconCollectionTitleWrapper) => void
    setFavoritesScreenShown: (state: boolean) => void,
    favoritesScreenShown: boolean,
    favoritesCollections: FavoriteIconCollectionTitleWrapper[],
}

const EXPANDED_WIDTH = IconPickerListStyle.width


const style = StyleSheet.create({
    wrapper: {
        width: EXPANDED_WIDTH,
        position: 'relative',
        height: 40
        // marginBottom: 20,
    },
    scrollView: {
        // position: 'absolute',
        justifyContent: 'flex-start',
        // paddingLeft: COLLAPSED_WITH + 10,
        // width: EXPANDED_WIDTH,
        height: "100%",
    },
    innerContent: {
        // height: 40,
        justifyContent: 'center',
    }
})

const emptyScroll = () => <span />

export const IconPickerFooter = React.memo<Props>(({
    onClick,
    collections,
    currentActiveIndex,
    setFavoritesScreenShown,
    favoritesScreenShown,
    favoritesCollections
}) => {
    const scrollViewRef = useRef<ScrollView>(null);
    return (
        <View style={{ flex: 1, flexWrap: 'nowrap', flexDirection: 'row' }}>
            <ScrollView horizontal style={{ width: '250px' }} ref={scrollViewRef}>
                <Stack spacing='tight' distribution={"leading"} alignment={'center'} key={'collections-list'}>
                    {
                        favoritesCollections.map((collection, index) => {
                            const isSelected = favoritesScreenShown && (currentActiveIndex >= collection.flatIndexStart && currentActiveIndex < collection.flatIndexEnd)
                            return (
                                <Stack.Item key={collection.iconPackData.title + index}>
                                    <div
                                        className={"icon-picker-list-item footer " + (isSelected ? "selected" : "")}
                                        title={collection.iconPackData.title}
                                        onClickCapture={(event) => {
                                            if (!favoritesScreenShown) {
                                                setFavoritesScreenShown(true);
                                                setTimeout(() => onClick(collection), 20); // Navigate after show favorites list
                                            } else {
                                                onClick(collection);
                                            }
                                        }}
                                    >
                                        <IconElement
                                            collection={collection.iconPackData.iconPreview.collection}
                                            name={collection.iconPackData.iconPreview.name}
                                            size={20}
                                        />
                                    </div>
                                </Stack.Item>
                            );
                        })
                    }
                    <Stack.Item>
                        <div
                            key={"swap-collections-all"}
                            style={{ textAlign: 'right' }}
                            className={"icon-picker-list-item footer " + (!favoritesScreenShown ? "selected" : '')}
                            title={"All"}
                            onClickCapture={() => { setFavoritesScreenShown(false); }}
                        >
                            <Text>More</Text>
                        
                        </div>
                    </Stack.Item>
                </Stack>
            </ScrollView>        
        </View>
    );
})