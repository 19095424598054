import React from 'react';
import { FormProviderProps } from 'react-hook-form/dist/types';
import { View, StyleSheet } from 'react-native';
import { FormProvider } from 'react-hook-form';

const style = StyleSheet.create({
    formWrapper: {
        alignItems: 'stretch',
        flexDirection: 'column',
        zIndex: 1
    }
})

export const FormContext = React.memo<FormProviderProps<Record<string, any>>>((props) => (
    <View style={style.formWrapper}>
        <FormProvider {...props}/>
    </View>
))