import { Stack, TextStyle } from "@shopify/polaris";
import React from "react";
import { ListCommunityListings_listCommunityListings_edges_node } from "../../../api/spacex.types";

export const CommunityAdLisingItem = React.memo(({ item }: { item: ListCommunityListings_listCommunityListings_edges_node }) => {
    return (
        <Stack vertical>
            <Stack.Item>
                <p><TextStyle variation={'strong'}>{item.storeInfo?.title}</TextStyle></p>
            </Stack.Item>
            <Stack.Item>
                <Stack>
                    <Stack.Item>
                        <p>Type: <TextStyle variation={'subdued'}>{item.adListing.type}</TextStyle></p>
                    </Stack.Item>
                    <Stack.Item>
                        <p>Bid: <TextStyle variation={'strong'}>{item.adListing.bidPerClick}</TextStyle></p>
                    </Stack.Item>
                    <Stack.Item>
                        {/* <p>Type: <TextStyle variation={variation}>{capitalize(item.type.toString().toLocaleLowerCase())}</TextStyle></p> */}
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
});