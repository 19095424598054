import { useAppBridge } from "@shopify/app-bridge-react";
import { Modal, Heading, Stack, Button } from "@shopify/polaris";
import React from "react";
import { View, Text } from "react-native";
import { useAccount } from "../../configure/utils/useAccount";
import { Carousel } from "../Carousel";
import { OnboardingModal, OnboardingType } from "../modals/OnboardingModal";
import { OnboardingStory } from "./OnboardingStory";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { useBoolLocalStorage } from "../banners/useBoolLocalStorage";

const contentPagesGif = require('../../assets/gifs/content-page-example.gif');
const pushOnboarding = require('../../assets/gifs/onboarding-push-image.gif');
const cec = require('../../assets/images/onboarding-cec.png');

export const DashboardOnboarding = React.memo(() => {
    const [closed, setClosed] = useBoolLocalStorage(OnboardingType.Dashboard);
    const account = useAccount();
    if (account.pricing.pricing?.price !== 0) {
        return null;
    }
    const appBridge = useAppBridge();

    const stories = [
        {
            title: 'Create custom home screens with content pages',
            message: 'We have created a simple and user-friendly content pages builder so that you can make your mobile store as attractive and customer-friendly as possible at any time. Build your pages with blocks and add your own banners and collections. Now, turning your mobile store into a world-class mobile app is a piece of cake!',
            media: contentPagesGif
        }, {
            title: 'Alert your buyers with customized Push notifications',
            message: 'Add images to your Push notifications with our PRO plan. Easily draw buyer\'s attention and increase your sales. Schedule notifications to plan your mailings in advance and make them as effective as possible. You\'ll never again forget to inform your customers about any activity in your store. PRO plan allows you to send up to 10 push notifications per week.',
            media: pushOnboarding
        }, {
            title: 'Grow your audience',
            message: 'Exchange customers and get more traffic and sales inside Cartmate! Use our Cartmate Exchange Catalog (CEC)! It is a list of stores that use cross-selling for their businesses. These stores can exchange customers and get more traffic. Our PRO plan will boost your store\'s CEC rankings and mark it with a Pro badge that will make it stand out from the rest.',
            media: cec
        }
    ];

    return (
        <OnboardingModal
            title={'Experience full Cartmate potential with our Pro plan!'}
            _key={OnboardingType.Dashboard}
            actions={[
                {
                    primary: true,
                    onClick: () => {
                        setClosed(true);
                        const redirect = ShopifyRedirect.create(appBridge);
                        redirect.dispatch(ShopifyRedirect.Action.APP, '/subscription');
                    },
                    children: 'View plans'
                }
            ]}
        >
            <Modal.Section>
                <View style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <View style={{ minHeight: '440px', marginTop: '8px' }}>
                        <Carousel
                            items={
                                stories.map((s, index) => {
                                    const content = <OnboardingStory
                                        key={`story-${index}`}
                                        title={s.title}
                                        message={s.message}
                                        media={s.media}
                                    />;
                                    return (
                                        {
                                            id: `story-${index}`,
                                            content: content
                                        }
                                    );
                                })
                            }
                            interval={20000}
                            onFinish={() => {
                                console.log('[Carousel] finished')
                            }}
                            loop
                        />
                    </View>
                </View>
            </Modal.Section>
        </OnboardingModal >
    );
});