import { Tooltip } from "@shopify/polaris"
import React from "react"
import { CirclePicker } from "react-color"
import { TouchableOpacity, View } from "react-native"
import { MixpanelEvent, trackEvent } from "../../utils/mixpanel"
import { ThemeColorPicker } from "./ThemeColorPicker"
import { ThemeDarkDescriptor } from "./themes/dark.theme"
import { ThemeSet } from "./themes/themes"

export const accentColorsSet = [
    ThemeDarkDescriptor.default.accent,
    '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5',
    '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50',
    '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800',
    '#ff5722', '#795548', '#607d8b'
]

export const ThemeSetComponent = React.memo(({
    themeSet,
    onChange
}: {
    themeSet: ThemeSet,
    onChange: (themeSet: ThemeSet) => void
}) => {
    console.log('ThemeSetComponent', themeSet);
    return (
        <View style={{ flexDirection: 'row', width: '100%', flex: 1 }}>
            <View style={{ marginRight: 14, justifyContent: 'center' }}>
                <ThemeColorPicker
                    themeSet={themeSet}
                    onChange={(v) => {
                        trackEvent(MixpanelEvent.CustomColorPicker, { color: `${v.dark.accent}` });
                        onChange(v);
                    }}
                />
            </View>
            <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', flexGrow: 1 }}>
                {accentColorsSet.map((color, index) => {
                    const isSelected = themeSet.dark.accent === color;
                    return (
                        <TouchableOpacity
                            key={`${color}-${index}`}
                            style={{ margin: 4 }}
                            onPress={() => {
                                trackEvent(MixpanelEvent.ColorPicler, { color: `${color}` });
                                onChange({
                                    light: { ...themeSet.light, accent: color },
                                    dark: { ...themeSet.dark, accent: color },
                                });
                            }}
                        >
                            <div style={{
                                height: 28, width: 28,
                                backgroundColor: color,
                                borderRadius: 14,
                                boxShadow: isSelected
                                    ? `${color} 0px 0px 0px 3px inset, ${color} 0px 0px 5px, rgb(255 255 255) 0px 0px 0px 5px inset, ${color} 0px 0px 0px 20px inset`
                                    : undefined
                            }} />

                        </TouchableOpacity>);
                })}
            </View>
        </View>
    )
})