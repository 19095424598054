import { ThemeDescriptor } from '../model/theme';
import * as t from 'io-ts';
import Color from 'color';

export const ThemeCNightCodec = t.type({
    type: t.literal('color-night'),
    accent: t.string
});
export type ThemeCNight = t.TypeOf<typeof ThemeCNightCodec>;
export const ThemeCNightDescriptor: ThemeDescriptor<ThemeCNight> = {
    key: 'color-night',
    name: 'Colored night',
    default: {
        type: 'color-night',
        accent: '#2F80ED'
    },
    resolve: (src) => {
        return {
            accent: src.accent,
            accentText: Color(src.accent).isDark()? '#f5f5f5' : '#212121',
            accentIconTint: Color(src.accent).isDark()? '#f5f5f5' : '#212121',

            primary: '#f5f5f5',
            primaryText: '#f5f5f5',

            background: Color(src.accent).darken(0.7).hex(),
            backgroundText: '#ddd',

            secondary: '#ddd',
            secondaryText: '#ddd',
            separator: '#ddd',

            iconTint: src.accent,

            bottomNavigationIcon: src.accent,
            bottomNavigationText: src.accent,
            bottomNavigationAccent: src.accent,
            bottomNavigationBackground: Color(src.accent).darken(0.5).hex(),
            bottomNavigationInactiveIcon: '#9b9b9b',
            bottomNavigationInactiveText: '#9b9b9b',

            toolBarText: '#f5f5f5',
            toolBarIcon: '#f5f5f5',
            toolBarAccent: src.accent,
            toolBarBackground: Color(src.accent).darken(0.5).hex(),

            priceBackground: '#db7a7a',
            priceOldText: '#9b9b9b',
            priceText: '#fff',

            toCartBackground: src.accent,
            toCartIconTint: Color(src.accent).isDark()? '#f5f5f5' : '#212121',
            toCartText: Color(src.accent).isDark()? '#f5f5f5' : '#212121',
        };
    }
}