import { EmptyState } from "@shopify/polaris";
import moment from "moment";
import React, { useState } from "react";
import { HorizontalGridLines, XAxis, XYPlot, YAxis, LineMarkSeries, VerticalGridLines, Hint, VerticalBarSeries } from "react-vis";
import { useClient } from "../../../api/useClient";
import "react-vis/dist/style.css";
import { capitalize, dateFormat } from "../../../utils/textUtils";

export const Chart = React.memo((
    {
        selectedPeriod,
        selectedType
    }: {
        selectedPeriod: string,
        selectedType: string
    }
) => {
    const client = useClient();
    const performance = client.useAdListingsPerformance({ days: parseInt(selectedPeriod) }).adListingsPerformance;

    const [hoveredPoint, setHoveredPoint] = useState<{
        x: number;
        y: number;
    }>();
    const [isHoveringOverLine, setIsHovering] = useState<boolean>();

    console.log('adListings performance', performance);

    const data = performance.map((p) => {
        return {
            x: parseInt(p.date),
            y: selectedType === 'clicks' ? p.clicksCount : p.viewsCount
        }
    });

    const width = 500;
    const height = 250;

    console.log(data);

    if (!data || data.length === 0) {
        return (
            <EmptyState
                heading="No data availible"
                image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
            />
        );
    }

    return (
        <XYPlot
            width={width}
            height={height}>
            <HorizontalGridLines />
            <VerticalGridLines />
            <VerticalBarSeries
                data={data}
                fill={undefined}
                barWidth={0.5}
                // curve={'curveMonotoneX'}
                // lineStyle={{ fill: 'transparent' }}
                onSeriesMouseOver={(e) => {
                    setIsHovering(true);
                }}
                onSeriesMouseOut={(e: React.MouseEvent<HTMLOrSVGElement>) => {
                    setIsHovering(false);
                    setHoveredPoint(undefined);
                }}
                onNearestXY={(e, { index }) => {
                    if (isHoveringOverLine) {
                        setHoveredPoint({
                            x: e.x as number,
                            y: e.y as number,
                        });
                    }
                }}
            />
            {hoveredPoint && (
                <Hint
                    value={hoveredPoint}
                    format={(value) => {
                        return (
                            [
                                { title: `Date`, value: `${moment(value.x).format(dateFormat)}` },
                                { title: `${capitalize(selectedType)}`, value: `${value.y}` }
                            ]
                        );
                    }}
                />
            )}
            <XAxis
                tickFormat={(tick) => {
                    console.log('tick', tick);
                    return moment(tick).format('MM.DD');
                }}
                tickTotal={6}
            />
            <YAxis />
        </XYPlot>
    )
});