import { Modal, TextStyle } from "@shopify/polaris";
import React from "react";
import { AdListings_adListings } from "../../../api/spacex.types";

export const DeactivateModal = React.memo((
    {
        item,
        handleCancel,
        handleDeactivate,
    }: {
        item: AdListings_adListings,
        handleCancel: () => void,
        handleDeactivate: (item: AdListings_adListings) => void,
    }
) => {

    return (
        <Modal
            open={true}
            onClose={handleCancel}
            title={`Deactivating ${item.caption}`}
            primaryAction={{
                content: 'Deactivate',
                destructive: true,
                onAction: () => handleDeactivate(item),
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: handleCancel,
                },
            ]}
        >
            <Modal.Section>
                <p>
                    <TextStyle>
                        {'You are about to deactivate your Traffic Exchange Ad listing. Are you sure?'}
                    </TextStyle>
                </p>
            </Modal.Section>
        </Modal>
    );
});