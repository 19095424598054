import { Button, FormLayout, Labelled, OptionList, Popover, ResourceItem, ResourceList, TextStyle } from "@shopify/polaris"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { View } from "react-native"
import { Collection_collection } from "../../../api/spacex.types"
import { useClient } from "../../../api/useClient"
import { mapCollection } from "../helpers/mapCollection"
import { mapNodeToProduct } from "../helpers/mapNodeToProduct"
import { renderCollectionsTags } from "../helpers/renderCollectionsTags"
import { Knob } from "../Knob"

export const KnobCollections = React.memo<Knob.KnobComponentProps<Knob.KnobCollectionsDescriptor>>(({ knob, value: initialValue, onChange, innerKey }) => {
    console.log('[KnobCollections] initialValue', initialValue);
    const { description } = knob;
    const client = useClient();
    const collections = client.useCollections();
    const options: any = collections.collections.map((collection) => {
        if (collection) {
            return {
                value: JSON.stringify(mapCollection(collection)),
                label: collection?.title,
            }
        }
    }).filter((item) => item);

    const [popoverActive, setPopoverActive] = useState(false);
    const [selected, setSelected] = useState<string[]>(initialValue.map((collection) => {
        return JSON.stringify({ id: collection?.id, title: collection?.title, image: collection?.image })
    }));

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );

    const removeCollection = useCallback(
        (collection: Knob.Collection) => {
            let temp = [];
            temp.push(...selected);
            let index = temp
                .map((option) => JSON.parse(option))
                .findIndex((item) => item.id === collection.id);
            if (index !== -1) {
                temp.splice(index, 1);
                setSelected(temp);
            }
        },
        [selected, setSelected, knob, innerKey],
    );

    const activator = (
        <Button fullWidth onClick={togglePopoverActive}>
            Add collections
        </Button>
    );

    useEffect(() => {
        onChange(selected.map((option) => JSON.parse(option)), innerKey)
    }, [selected, knob, innerKey, collections]);

    const tags = useMemo(() => {
        console.log('renderCollectionsTags', selected);
        return renderCollectionsTags(selected.map((option) => JSON.parse(option)), removeCollection);
    }, [selected, removeCollection]);

    return <FormLayout>
        <Labelled id={innerKey} label={description}>
            <Popover
                active={popoverActive}
                activator={activator}
                onClose={togglePopoverActive}
            >
                <OptionList
                    title={description}
                    onChange={setSelected}
                    options={options}
                    selected={selected}
                    allowMultiple
                />
            </Popover>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 4 }}>
                {tags}
            </View>
        </Labelled>
    </FormLayout>
})
