import { Page } from "@shopify/polaris";
import React from "react";
import { AppStoreForm } from "../components/AppStoreForm";
import { GooglePlayForm } from "../components/GooglePlayForm";

export const StoresForm = React.memo(() => {
    return (
        <>
            <Page title={'Standalone app'} breadcrumbs={[{ content: 'Settings', url: '/Settings' }]}>
                <GooglePlayForm />
                <AppStoreForm />
            </Page>
        </>
    )
});