import { FormLayout, OptionList, Button, Popover, Icon, Heading, Labelled } from "@shopify/polaris"
import { ArrowLeftMinor, ArrowRightMinor, ViewportNarrowMajor } from "@shopify/polaris-icons"
import React, { useCallback, useEffect, useState } from "react"
import { Knob } from "../Knob"

export const KnobTextPosition = React.memo<Knob.KnobComponentProps<Knob.KnobTextPosotionDescriptor>>(({ knob, value: initValue, onChange, innerKey }) => {
    const { description } = knob
    const [selected, setSelected] = useState<string[]>([initValue])
    const [popoverActive, setPopoverActive] = useState(false);

    useEffect(() => {
        onChange(selected[0], innerKey)
    }, [selected, knob, innerKey])

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );

    const options = [
        {
            value: 'center', label: 'Center', media: (
                <Icon
                    source={ViewportNarrowMajor}
                    color="base" />
            )
        },
        {
            value: 'flex-start', label: 'Left', media: (
                <Icon
                    source={ArrowLeftMinor}
                    color="base" />
            )
        },
        {
            value: 'flex-end', label: 'Right', media: (
                <Icon
                    source={ArrowRightMinor}
                    color="base" />
            )
        }
    ];

    const selectedLabelIndex = options.findIndex((option) => {
        return option.value === selected[0];
    });

    const activator = (
        <Button
            fullWidth
            onClick={togglePopoverActive}
            icon={(selectedLabelIndex !== -1) ? options[selectedLabelIndex].media : undefined}
        >
            {(selectedLabelIndex !== -1) ? options[selectedLabelIndex].label : 'Text position'}
        </Button>
    );

    return (
        <FormLayout>
            <Labelled id={innerKey} label={description}>
                <Popover
                    active={popoverActive}
                    activator={activator}
                    onClose={togglePopoverActive}
                >
                    <OptionList
                        title={description}
                        onChange={setSelected}
                        options={options}
                        selected={selected}
                    />
                </Popover>
            </Labelled>
        </FormLayout>
    )
})