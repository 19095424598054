import { Spinner } from '@shopify/polaris';
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

export const Loader = React.memo((props: { style?: StyleProp<ViewStyle> }) => {
    return (
        <View
            style={[{
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                alignItems: 'center',
                justifyContent: 'center'
            }, props.style]}
        >
            <Spinner accessibilityLabel="Loading content" size="large" />
        </View>
    );
});