import {useLocation} from "react-router";
import {useMemo} from "react";

export function useQueryParams<T extends Object>(): Partial<T> {
    const location = useLocation();
    return useMemo(() => {
        const res = {} as any
        new URLSearchParams(location.search).forEach((value, key) => {
            res[key] = value
        })
        return res as Partial<T>
    }, [location.search]);
}