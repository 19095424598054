import React from "react";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Page, Layout, Card, Link } from "@shopify/polaris";
import { View } from "react-native";
import { useParams, useHistory } from "react-router";

const trafficGif = require('../../../assets/gifs/promote.gif');

export const Traffic = React.memo(() => {
    const appBridge = useAppBridge();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    return (<>
        <Page
            title={'Get more customers with the Cartmate Traffic Exchange Program'}
            breadcrumbs={[{
                content: 'About',
                onAction: () => { history.length > 2 ? history.goBack() : history.push({ pathname: '/about' }) }
            }]}
        >
            <Layout>
                <Layout.Section>
                    <Card title={'Complite Traffic Exchange Program review process and enable \'Traffic Exchange\''} sectioned>
                        <p>
                            Fill out your <Link onClick={() => {
                                const redirect = ShopifyRedirect.create(appBridge);
                                redirect.dispatch(ShopifyRedirect.Action.APP, '/promote');
                            }}>
                                application
                            </Link>
                            {' '}today and send it to the traffic exchange program.
                        </p>
                        <View style={{
                            justifyContent: 'flex-start', alignItems: 'center',
                            width: '100%', marginVertical: 16,
                            flexDirection: 'row'
                        }}>
                            <img src={trafficGif} alt="promote" />
                        </View>
                        <p>
                            {'Moderated stores that have enabled the traffic exchange program are added to the Cartmate Exchange Catalog (CEC). Once active, they will be available for viewing in the mobile app for customers who have added other program members to their favorites (followed the link or scanned the QR code) or for users who have installed the Cartmate mobile app organically via download from the AppStore or GooglePlay.'}
                        </p>
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    </>
    )
});