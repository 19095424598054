import { Button, Collapsible, FormLayout, Icon, Labelled, Select, Stack, TextField } from "@shopify/polaris";
import { DeleteMinor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { useClient } from "../../../api/useClient";
import { Suspense } from "../../../components/Suspense";
import { Knob } from "../Knob";
import { KnobCollection } from "./KnobCollection";
import { KnobProduct } from "./KnobProduct";

const options = [
    { label: 'Product', value: 'Product' },
    { label: 'Collection', value: 'Collection' },
];

export const KnobNavigation = React.memo<Knob.KnobComponentProps<Knob.KnobNavigationDescriptor>>((
    {
        knob,
        value: initValue,
        onChange,
        innerKey,
        disabled
    }) => {
    const client = useClient();
    const { description } = knob;
    const [selected, setSelected] = useState<Knob.Navigation | undefined>(initValue);
    const [openedCollapsible, setOpenedCollapsible] = useState<number>(0);

    useEffect(() => {
        console.log('[KnobNavigation] selected: ', initValue, selected);
        if (selected && selected?.url !== initValue.url && selected?.title !== initValue.title) {
            console.log('[KnobNavigation] onChange: ', selected);
            onChange(selected, innerKey)
        }
    }, [selected, innerKey, initValue]);

    console.log('[KnobNavigation] selected: ', selected);

    if (disabled) {
        return (
            <FormLayout>
                <TextField
                    label={"Navigation"}
                    labelHidden
                    prefix={selected?.type}
                    value={(selected as Knob.Product | Knob.Collection | undefined)?.title}
                    disabled
                    autoComplete={'off'}
                />
            </FormLayout>
        );
    }

    const onClear = useCallback(
        () => {
            setSelected({
                id: '',
                title: '',
                type: openedCollapsible === 0
                    ? 'Product'
                    : 'Collection'
            });
        },
        [setSelected],
    );

    const handleSelectChange = useCallback((value) => {
        onClear()
        setOpenedCollapsible(value === 'Product' ? 0 : 1);
    }, [onClear, setOpenedCollapsible]);
    
    return (
        <FormLayout>
            <Labelled id={`navigation-${innerKey}`} label={description}>
                <Stack alignment={'center'} distribution={'center'}>
                    <Stack.Item>
                        <Select
                            label={'Type'}
                            options={options}
                            onChange={handleSelectChange}
                            value={openedCollapsible === 0 ? 'Product' : 'Collection'}
                        />
                    </Stack.Item>
                    <Stack.Item fill>
                        <Collapsible
                            open={openedCollapsible === 0}
                            id="product"
                            transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                            expandOnPrint
                        >
                            <Suspense>
                                <KnobProduct
                                    knob={{ type: 'product' as const, description: 'Select a product' }}
                                    onChange={(knobValue: Knob.Product, key: string) => {
                                        if ({ ...knobValue, ...{ type: 'Product' } } !== selected) {
                                            setSelected(
                                                { ...knobValue, ...{ type: 'Product', url: `/product/${knobValue.id}` } });
                                        }
                                    }}
                                    innerKey={`ProductKnob-${innerKey}`}
                                    value={
                                        (selected && selected?.type === 'Product')
                                            ? {
                                                id: selected.id || '',
                                                title: selected.title || '',
                                                image: undefined
                                            }
                                            : {
                                                id: '',
                                                title: '',
                                                image: undefined
                                            }
                                    }
                                />
                            </Suspense>
                        </Collapsible>
                        <Collapsible
                            open={openedCollapsible === 1}
                            id="collection"
                            transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                            expandOnPrint
                        >
                            <Suspense>
                                <KnobCollection
                                    knob={{ type: 'collection' as const, description: 'Select collection' }}
                                    onChange={(knobValue: Knob.Collection, key: string) => {
                                        if ({ ...knobValue, ...{ type: 'Collection' } } !== selected) {
                                            setSelected({ ...knobValue, ...{ type: 'Collection', url: `/products/${knobValue.id}` } });
                                        }
                                    }}
                                    innerKey={`CollectionKnob-${innerKey}`}
                                    value={
                                        (selected && selected?.type === 'Collection')
                                            ? {
                                                id: selected.id || '',
                                                title: selected.title || '',
                                                image: undefined
                                            }
                                            : {
                                                id: '',
                                                title: '',
                                                image: undefined
                                            }
                                    }
                                />
                            </Suspense>
                        </Collapsible>
                    </Stack.Item>
                    <Stack.Item>
                        {(selected && selected.url && selected.url.length !== 0) && (
                            <View style={{
                                flexGrow: 1,
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Button
                                    plain
                                    destructive
                                    onClick={onClear}
                                    icon={<Icon source={DeleteMinor} />}
                                >
                                    {'Clear'}
                                </Button>
                            </View>
                        )}
                    </Stack.Item>
                </Stack>
            </Labelled>
        </FormLayout>
    );
})