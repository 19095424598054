import { useAppBridge } from "@shopify/app-bridge-react";
import { Card, Layout, Link, Page } from "@shopify/polaris";
import React from "react";
import { View } from "react-native";
import { useHistory, useParams } from "react-router";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";

const bannerGif = require('../../../assets/gifs/banner.gif');
const dashboard = require('../../../assets/images/share-link.png');

export const Promote = React.memo(() => {
    const appBridge = useAppBridge();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    return (<>
        <Page
            title={'Promote your mobile in-app storefront'}
            breadcrumbs={[{
                content: 'About',
                onAction: () => { history.length > 2 ? history.goBack() : history.push({ pathname: '/about' }) }
            }]}
        >
            <Layout>
                <Layout.Section>
                    <Card sectioned>
                        <p>
                            Share your mobile in-app store with your friends and customers via QR-code and direct link from the <Link onClick={() => {
                                const redirect = ShopifyRedirect.create(appBridge);
                                redirect.dispatch(ShopifyRedirect.Action.APP, `/dashboard`);
                            }}>Mobile app dashboard</Link>.
                        </p>
                        <View style={{
                            justifyContent: 'flex-start', alignItems: 'center',
                            width: '100%', marginVertical: 16,
                            flexDirection: 'row'
                        }}>
                            <img src={dashboard} alt="share your shop" />
                        </View>
                        <p>
                            Customize your mobile in-app storefronts promotional banner in the <Link onClick={() => {
                                const redirect = ShopifyRedirect.create(appBridge);
                                redirect.dispatch(ShopifyRedirect.Action.APP, `/apps/${id}/promo`);
                            }}>app promotion banner</Link>
                            {' '}tab.
                        </p>
                        <View style={{
                            justifyContent: 'flex-start', alignItems: 'center',
                            width: '100%', marginVertical: 16,
                            flexDirection: 'row'
                        }}>
                            <img src={bannerGif} alt="banner" />
                        </View>
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    </>
    )
});