import React, { useCallback, useEffect, useState } from 'react';
import { Card, Layout, Loading, Page, Modal } from '@shopify/polaris';
import { useClient } from '../../api/useClient';
import { Prompt, useHistory } from 'react-router';
import { ViewConfig } from '../../configEditor/ConfigEditor';
import { ContentPageType } from '../../api/spacex.types';
import { useValidatePageName } from '../../content/contentPage/editPageUtils';
import { PageName } from '../../content/contentPage/PageName';
import { PageBlocks } from '../../content/contentPage/PageBlocks';
import { PhonePreviewLayout } from '../../configure/theme/preview/PhonePreview';

export const PagesNewModal = React.memo((
    {
        open,
        close
    }:{
        open: boolean,
        close: ()=>void
    }
) => {
    const client = useClient()
    const history = useHistory()
    const [loading, setLoading] = useState(false) //todo useSuspense

    const [name, setName] = useState('')
    const [blocks, setBlocks] = useState<ViewConfig.NodeBlockExtended[]>([])
    const [pageNeedSave, setPageNeedSave] = useState(false)
    const [errorMsg, clean, verifyName] = useValidatePageName(name)

    useEffect(clean, [name])
    useEffect(() => setPageNeedSave(!!name.trim().length || !!blocks.length), [name, blocks])

    const onSubmit = useCallback(() => {
        if (verifyName()) {
            setPageNeedSave(false)
            setLoading(true)
            client.mutateCreatePage({
                input: ViewConfig.PageToInputPage.encode({
                    id: 'new',
                    name,
                    type: ContentPageType.BLOCK,
                    blocks,
                    contentPageId: 'new'
                }),
            }).then(({pageCreate}) => {
                const location = {
                    ...history.location,
                    pathname: `/pages/${pageCreate.id}`,
                }
                console.log('Page created!');
                client.mutatePublishPage({id: pageCreate.id})
                .then((result) =>{
                    console.log('Page published');
                    client.refetchPages().finally(() => {
                        close();
                    })
                });
            }).finally(() => {
                setLoading(false)
                setPageNeedSave(true)
            })
        }

    }, [client, blocks, name])

    return (
        <Modal title="Add new page"
               open={open}
              primaryAction={{content: 'Create', onAction: onSubmit}}
        onClose={close}>
            {loading && <Loading/>}
            <PhonePreviewLayout applicationId={'unknown'}>
                <Layout.Section>
                    <Card>
                        <Prompt message={'All changes will be lost'} when={pageNeedSave}/>
                        <PageName name={name} onChange={setName} errorMsg={errorMsg || false}/>
                    </Card>
                    <Card title="Page content">
                        <PageBlocks blocks={blocks} onChange={setBlocks}/>
                    </Card>
                </Layout.Section>
            </PhonePreviewLayout>
        </Modal>
    )
});