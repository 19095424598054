import { Button, ButtonProps, Modal, Stack } from "@shopify/polaris";
import React from "react";
import { useBoolLocalStorage } from "../banners/useBoolLocalStorage";

export enum OnboardingType {
    Branding = 'Branding',
    Menu = 'Menu',
    Pages = 'Pages',
    Banner = 'Banner',
    Dashboard = 'Dashboard',
    Push = 'Push'
}

export const OnboardingModal = React.memo((
    {
        title,
        _key,
        children,
        actions
    }: {
        title: string,
        _key: string,
        children: any,
        actions?: ButtonProps[]
    }
) => {
    console.log('[OnboardingModal]', title, _key);
    const [closed, setClosed] = useBoolLocalStorage(_key);
    console.log('[OnboardingModal]', closed);

    return (
        <Modal
            large
            open={!closed}
            title={title}
            onClose={() => setClosed(true)}
        >
            {children}
            <Modal.Section>
                <Stack distribution={'trailing'} alignment={'center'}>
                    <Button plain onClick={() => {
                        setClosed(true);
                    }}>
                        {'Skip'}
                    </Button>
                    {actions?.map((action) => {
                        return <Button {...action} onClick={() => {
                            if (action.onClick) {
                                action.onClick();
                            }
                            setClosed(true);
                        }} />
                    })}
                </Stack>
            </Modal.Section>
        </Modal>
    );
});