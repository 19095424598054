import { FormLayout, Icon, Labelled, Popover, ResourceItem, ResourceList, TextField, TextStyle } from "@shopify/polaris";
import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { debounce } from "ts-debounce";
import { ListCollectionsVariables, Pages_pages } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { CleanSpaceX } from "../../../components/types";
import { Knob } from "../Knob";

type PagesFilter = {
    pageSize: number,
    query?: string
}

type Page = CleanSpaceX<Pages_pages>;

export const KnobPagePicker = React.memo<Knob.KnobComponentProps<Knob.KnobPagePickerDescriptor>>((
    { knob, innerKey, value: initialValue, onChange, disabled }
) => {
    const client = useClient();
    const [popoverActive, setPopoverActive] = useState(false);
    const [value, setValue] = useState<Knob.PickedPage>();
    const [filters, setFilters] = useState<PagesFilter>({ pageSize: knob.pageSize || 10 });
    // const [results, setResults] = useState<Pages>();
    const [list, setList] = useState<Page[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const loadPage = useCallback((params: ListCollectionsVariables, replace: boolean = false) => {
        setLoading(true);
        client.queryPages()
            .then((result) => {
                // setResults(result.pages);
                const items = result.pages.filter((page) => page.publishedData && page.draftData === page.publishedData);
                setList(replace ? items : list.concat(items));
            })
            .catch(console.warn)
            .finally(() => { setLoading(false); });
    }, [client, loading, list]);

    const nodeToValue = useCallback((node: Page) => {
        return node.contentPageId;
    }, []);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((prevValue) => {
            return !prevValue
        }),
        [knob, innerKey],
    );
    const handleNodeSelect = useCallback((node: Page) => {
        const newValue = nodeToValue(node);
        setValue(newValue);
        handleFiltersChange("query", node.name);
        console.log('[KnobPagePicker] onChange', newValue);
        onChange(newValue, innerKey);
        togglePopoverActive();
    }, [innerKey, onChange]);

    const debFilter = useCallback(
        debounce(
            (filters: PagesFilter, lp: (params: ListCollectionsVariables, replace?: boolean) => Promise<any> | any) => {
                lp({ first: filters.pageSize, after: undefined }, true)
            },
            300
        ), []);

    const handleFiltersChange = useCallback((field: keyof PagesFilter, newValue) => {
        setFilters((prevState) => {
            let newState = { ...prevState, [field]: newValue };
            return newState;
        });
    }, []);

    // const handleScrolledToBottom = useCallback(() => {        
    //     if (loading) {            
    //         return;
    //     }
    //     if (results?.pageInfo.hasNextPage) {
    //         loadPage({
    //             first: filters.pageSize,
    //             after: results?.pageInfo.endCursor,
    //         }, false);
    //     }
    // }, [results, filters, loading]);

    const handleClear = useCallback(() => {
        handleFiltersChange("query", '');
        setValue('');
        onChange('', innerKey)
    }, [innerKey, onChange]);

    useEffect(() => {
        if (initialValue && initialValue !== value) {
            client.queryPage({ id: initialValue.trim() })
                .then((result) => {
                    setValue(nodeToValue(result.page));
                    handleFiltersChange("query", result.page.name);
                })
                .catch(console.warn);
        }
    }, [initialValue]);

    useEffect(() => {
        debFilter(filters, loadPage);
    }, [filters]);

    const activator = (
        <div className={'knob_picker_activator'}>

            <TextField
                label={knob.description}
                labelHidden
                value={filters.query}
                onChange={(query) => {
                    handleFiltersChange("query", query);
                }}
                onFocus={() => { !disabled ? setPopoverActive(true) : undefined; }}
                autoComplete={'off'}
                disabled={disabled}
                readOnly
                clearButton={knob.clerable || false}
                onClearButtonClick={handleClear}
                placeholder={"Page"}
                suffix={(
                    <div style={{ cursor: 'pointer' }}>
                        <Icon
                            source={popoverActive ? ChevronUpMinor : ChevronDownMinor}
                            color="base"
                        />
                    </div>
                )}
            />
        </div>
    );
    return (
        <FormLayout>
            <Labelled id={innerKey} label={knob.description}>
                <Popover
                    activator={activator}
                    active={popoverActive}
                    onClose={togglePopoverActive}
                    fullWidth
                    preferredAlignment={'left'}
                    sectioned
                    ariaHaspopup={false}
                >
                    <Popover.Pane
                    // onScrolledToBottom={handleScrolledToBottom}
                    >
                        <View style={{ maxHeight: filters.pageSize * 50 }}>
                            <ResourceList
                                items={list}
                                renderItem={renderPageItem}
                            />
                        </View>
                    </Popover.Pane>
                </Popover>
            </Labelled>
        </FormLayout>
    );

    function renderPageItem(node: Page) {
        return (
            <ResourceItem
                id={node.id}
                onClick={() => { handleNodeSelect(node) }}
                // media={<Thumbnail size={"medium"} source={node.image?.src || ''} alt={node.title} />}
                verticalAlignment={"center"}
            >
                <TextStyle>{node.name}</TextStyle>
            </ResourceItem>
        );
    }
});