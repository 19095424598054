import { Collections_collections } from "../../../api/spacex.types";
import { Knob } from "../Knob";
import { ResizeMode } from "../types";

export function mapCollection(collection: Collections_collections): Knob.Collection {
    return {
        id: collection?.id,
        title: collection?.title,
        image: {
            src: collection.image?.src || null,
            blurhash: collection.image?.blurhash || null,
            resizeMode: ResizeMode.Cover
        }
    }
}