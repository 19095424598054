import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Prompt, useHistory, useParams, useRouteMatch } from "react-router";
import { Button, ButtonGroup, Card, Layout, Loading, Page } from '@shopify/polaris';
import { useClient } from "../../api/useClient";
import { ViewConfig } from "../../configEditor/ConfigEditor";
import { useValidatePageName } from "../../content/contentPage/editPageUtils";
import { ContentPageType, Page_page } from "../../api/spacex.types";
import { PageName } from "../../content/contentPage/PageName";
import { PageBlocks } from "../../content/contentPage/PageBlocks";
import { useSaver } from "../utils/useSaver";
import { useRenamePageModal } from "../../content/contentPage/useRenamePageModal";
import { usePublishAction } from "../../content/contentPage/usePublishAction";
import { useRevertAction } from "../../content/contentPage/useRevertAction";
import { View } from "react-native";
import { PromptLoading } from "../../routing/PromptLoading";
import { EditPageById } from "../pages/EditPageById";
import { pipe } from "fp-ts/function";
import { getOrElse } from "fp-ts/Either";
import { initRenderBlock } from "../../configEditor/subRender/subRenderUtils";
import { Suspense } from "../../components/Suspense";

function getBlocks(page: Page_page): ViewConfig.NodeBlockExtended[] {
    const nodeBlocks = pipe(ViewConfig.PageToSeverPage.decode(page), getOrElse(() => (null as ViewConfig.Page | null)))?.blocks || [];

    return nodeBlocks.map(node => initRenderBlock(node))
}

export const AppPageEdit = React.memo(() => {
    const { id } = useParams<{ id: string }>();
    const client = useClient();
    const app = client.useApp({ id }).app;
    const returnPath = useMemo(() => `/apps/${id}/pages`, [id]);

    const history = useHistory()
    const params = useRouteMatch<Record<'pageId', string>>().params
    const page = client.usePage({ id: params.pageId }).page

    const initBlocks: ViewConfig.NodeBlockExtended[] = useMemo(() => getBlocks(page), [page.id])

    const onChange = useCallback(async (newState: ViewConfig.NodeBlockExtended[]) => {
        const data = ViewConfig.PageToInputPage.encode({
            name: '',
            id: '',
            type: ContentPageType.BLOCK,
            blocks: newState,
            contentPageId: ''
        })
        await client.mutateUpdatePage({
            id: page.id,
            input: {
                body: data.body,
            },
        })
    }, [page?.id])

    const [pageData, loading, setPageData] = useSaver<ViewConfig.NodeBlockExtended[]>(initBlocks, onChange)

    const needPublish = !page.publishedData || page.draftData !== page.publishedData

    const [modalRename, openModal] = useRenamePageModal(page.id)

    const [toastPublish, onPublish] = usePublishAction(page.id)
    const [toastRevert, onRevert] = useRevertAction(page, setPageData)

    return (
        <Page
            title={`Edit page: ${page.name}`}
            breadcrumbs={[
                { content: "Apps", url: "/apps" },
                { content: app.name, url: returnPath }
            ]}
            secondaryActions={[
                { content: 'Rename', onAction: openModal },
                {
                    content: 'Revert changes',
                    disabled: !needPublish,
                    accessibilityLabel: 'Revert changes to published version',
                    onAction: onRevert,
                },

            ]}
            primaryAction={{
                content: 'Publish',
                onAction: onPublish,
                disabled: !needPublish,
                loading: loading
            }}
        >
            <View>
                {modalRename}
                {toastPublish}
                {toastRevert}
                <PromptLoading message={'Updating page'} when={loading} />
                <Card title={'Content'}>
                    <Suspense>
                        <EditPageById pageId={page.id} />
                    </Suspense>
                </Card>
            </View>
        </Page>
    )
});