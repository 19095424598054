import { AppProvider } from '@shopify/polaris';
import * as React from 'react';
import enTranslations from '@shopify/polaris/locales/en.json';
import { Link } from './routing/routing';
import { Router } from './routing/routing';
import { RecoilRoot } from 'recoil';
import { View } from 'react-native';
import { colors } from './assets/colors';
import { useBoolLocalStorage } from './components/banners/useBoolLocalStorage';

export const BootProviders = React.memo((props: { children?: any }) => {
    const [isDark] = useBoolLocalStorage('IsDarkTheme');

    return (
        <View style={{
            backgroundColor: isDark
                ? colors.darkBackground
                : colors.lightBackground,
            height: '100vh'
        }}>
            <AppProvider
                theme={{ colorScheme: isDark ? 'dark' : 'light' }}
                features={{
                    newDesignLanguage: false
                }}
                i18n={enTranslations}
                linkComponent={(props) => <Link to={props.url} {...(props as any)}>{props.children}</Link>}
            >
                <RecoilRoot>
                    <Router>
                        {props.children}
                    </Router>
                </RecoilRoot>
            </AppProvider>
        </View>
    )
});