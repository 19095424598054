import React, { useCallback, useEffect, useState } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native';
import { SearchField } from '../../SearchField/SearchField';
import { IconPickerListStyle } from './commonViewStyle';
import { useSpring, a } from '@react-spring/native';
import { FlatIconCollectionTitleWrapper } from './iconsData';
import { Button, Stack } from '@shopify/polaris';
import { Scrollbars } from 'react-custom-scrollbars';

type Props = {
    currentActiveIndex: number
    search: string | null,
    onSearchChange: (val: string | null) => void
    collections: FlatIconCollectionTitleWrapper[]
    onClick: (item: FlatIconCollectionTitleWrapper) => void
}

const EXPANDED_WIDTH = IconPickerListStyle.width
const COLLAPSED_WITH = 40

const style = StyleSheet.create({
    wrapper: {
        width: "100%",
        position: 'relative',
    },
    inputWrapper: {
        position: 'absolute',
    },
    scrollView: {
        // position: 'absolute',
        justifyContent: 'flex-start',
        // paddingLeft: COLLAPSED_WITH + 10,
        // width: EXPANDED_WIDTH,
        height: 40,
    },
    innerContent: {
        height: 40,
        justifyContent: 'center',
    }
})

const emptyScroll = () => <span/>

export const IconPickerHeader = React.memo<Props>(({
                                                       onClick,
                                                       search,
                                                       onSearchChange,
                                                       collections,
                                                       currentActiveIndex,
                                                   }) => {
    const [expandedSearch, setExpanded] = useState(false)
    const onEnterToSearch = useCallback(() => setExpanded(true), [])
    const onBlur = useCallback(() => {
        setExpanded(false)
        if (!search?.trim()) {
            onSearchChange(null)
        }
    }, [search, onSearchChange])
    const onCancel = useCallback(() => onSearchChange(null), [onSearchChange])

    const expandedStyle = useSpring({
        to: {
            width: expandedSearch || search ? EXPANDED_WIDTH : COLLAPSED_WITH,
        },
    })

    return (
        <View style={style.wrapper}>
            <View>
                <SearchField
                    onChange={onSearchChange}
                    value={search || ''}
                    onFocus={onEnterToSearch}
                    onBlur={onBlur}
                    onCancel={onCancel}
                    placeholder="Search"
                    showFocusBorder
                />
            </View>
        </View>
    )
})