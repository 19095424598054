import { ContentReferenceType } from '../../api/spacex.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useClient } from '../../api/useClient';
import { debounce } from 'ts-debounce';
import { TabDesigner } from '../types';


export type TabContent = Pick<TabDesigner, 'contentType' | 'contentKey'>

type SectionDescriptor = {
    options: ContentDescriptor[];
    title?: string;
}

export type ContentDescriptor = {
    label: string,
    hash: string
    value: TabContent
}

const DEFAULT_EMPTY: ContentDescriptor = {
    label: 'Empty',
    hash: 'empty',
    value: {
        contentKey: null,
        contentType: ContentReferenceType.EMPTY,
    },
}

const DEFAULT_ALL_COLLECTIONS: ContentDescriptor = {
    label: 'All collections',
    hash: 'all_collections',
    value: {
        contentKey: null,
        contentType: ContentReferenceType.ALL_COLLECTIONS,
    },
}

const DEFAULT_COLLECTION: ContentDescriptor = {
    label: 'Collection',
    hash: 'collection',
    value: {
        contentKey: null,
        contentType: ContentReferenceType.COLLECTION,
    },
}

const DEFAULT_CART: ContentDescriptor = {
    label: 'Cart',
    hash: 'cart',
    value: {
        contentKey: null,
        contentType: ContentReferenceType.CART,
    },
}

const DEFAULT_FAVORITES: ContentDescriptor = {
    label: 'Favorites',
    hash: 'Favorites',
    value: {
        contentKey: null,
        contentType: ContentReferenceType.FAVORITES,
    },
}

const DEFAULT_PRODUCTS_BY_COLLLECTIONS: ContentDescriptor = {
    label: 'Products by Collections',
    hash: 'products_by_collections',
    value: {
        contentKey: null,
        contentType: ContentReferenceType.PRODUCTS_BY_COLLECTIONS,
    },
}

const DEFAULT_SHOP_ORDERS: ContentDescriptor = {
    label: 'Orders',
    hash: 'shop_orders',
    value: {
        contentKey: null,
        contentType: ContentReferenceType.SHOP_ORDERS,
    },
}

export function useContentLoader(content: TabContent) {
    const [des, setDesc] = useState<null | ContentDescriptor>(null)
    const client = useClient()
    const loading = !des

    useEffect(() => {
        let actual = true

        let promise: Promise<ContentDescriptor>
        const { contentType, contentKey } = content

        switch (contentType) {
            case ContentReferenceType.CONTENT:
                promise = client.queryPage({ id: contentKey! })
                    .then(page => ({
                        label: page.page.name,
                        hash: getContentValueHash(content),
                        value: content,
                    }))
                break;
            case ContentReferenceType.ALL_COLLECTIONS:
                promise = Promise.resolve(DEFAULT_ALL_COLLECTIONS)
                break;
            case ContentReferenceType.PRODUCTS_BY_COLLECTIONS:
                promise = Promise.resolve(DEFAULT_PRODUCTS_BY_COLLLECTIONS)
                break;
            case ContentReferenceType.SHOP_ORDERS:
                promise = Promise.resolve(DEFAULT_SHOP_ORDERS)
                break;
            case ContentReferenceType.FAVORITES:
                promise = Promise.resolve(DEFAULT_FAVORITES)
                break;
            case ContentReferenceType.COLLECTION:
                if (contentKey === null) {
                    promise = Promise.resolve(DEFAULT_ALL_COLLECTIONS)
                } else {
                    promise = client.queryCollections().then(cols => {
                        console.log('useContentLoader', cols);
                        const col = cols.collections.find(c => c?.id === contentKey);
                        console.log('useContentLoader', col);
                        console.log('useContentLoader', content);
                        if (col) {
                            return {
                                label: col.title,
                                hash: getContentValueHash(content),
                                value: content,
                            }
                        } else {
                            return DEFAULT_ALL_COLLECTIONS
                        }
                    })
                }
                break;
            case ContentReferenceType.CART:
                promise = Promise.resolve(DEFAULT_CART)
                break;
            case ContentReferenceType.EMPTY:
            case ContentReferenceType.FEED:
            default:
                promise = Promise.resolve(DEFAULT_EMPTY)
                break;
        }

        promise.then(des => {
            if (actual) {
                setDesc(des)
            }
        })

        return () => {
            actual = false
        }
    }, [content.contentType, content.contentKey, client])

    return [des, loading] as ([null, true] | [ContentDescriptor, false])
}

export function getContentValueHash(content: TabContent) {
    if (content.contentType === ContentReferenceType.EMPTY) {
        return DEFAULT_EMPTY.hash
    }

    if (content.contentType === ContentReferenceType.CART) {
        return DEFAULT_CART.hash
    }

    if (content.contentType === ContentReferenceType.ALL_COLLECTIONS) {
        return DEFAULT_ALL_COLLECTIONS.hash
    }

    if (content.contentType === ContentReferenceType.PRODUCTS_BY_COLLECTIONS) {
        return DEFAULT_PRODUCTS_BY_COLLLECTIONS.hash
    }

    if (content.contentType === ContentReferenceType.SHOP_ORDERS) {
        return DEFAULT_SHOP_ORDERS.hash
    }

    if (content.contentType === ContentReferenceType.COLLECTION && content.contentKey === null) {
        return DEFAULT_COLLECTION.hash
    }

    return `item_${content.contentType}_${content.contentKey}`
}

export function useContentList(search?: string) {
    const client = useClient()
    const map = useMemo(() => new Map<string, ContentDescriptor>([
        [DEFAULT_EMPTY.hash, DEFAULT_EMPTY],
        [DEFAULT_ALL_COLLECTIONS.hash, DEFAULT_ALL_COLLECTIONS],
        [DEFAULT_CART.hash, DEFAULT_CART],
    ] as const), [])
    const [allItems, setAllItems] = useState<SectionDescriptor[] | null>(null)
    const [renderItems, setRenderItems] = useState<SectionDescriptor[]>([])

    useEffect(() => {
        let actual = true

        Promise.all([
            Promise.resolve({
                title: 'Content',
                options: [
                    DEFAULT_ALL_COLLECTIONS,
                    DEFAULT_EMPTY,
                    DEFAULT_CART,
                    DEFAULT_FAVORITES,
                    DEFAULT_PRODUCTS_BY_COLLLECTIONS,
                    DEFAULT_SHOP_ORDERS
                ]
            } as SectionDescriptor),
            client.queryCollections().then(data => {
                return {
                    options: data.collections.map(col => {
                        if (col === null) {
                            return DEFAULT_ALL_COLLECTIONS
                        }

                        const value = {
                            contentType: ContentReferenceType.COLLECTION,
                            contentKey: col.id,
                        }

                        const item: ContentDescriptor = {
                            label: col.title,
                            hash: getContentValueHash(value),
                            value,
                        }

                        map.set(item.hash, item)
                        return item
                    }),
                    title: 'Collections',
                } as SectionDescriptor
            }),
            client.queryPages().then(data => {
                return {
                    options: data.pages
                        .filter(page => !!page.publishedData)
                        .map(page => {
                            const value = {
                                contentType: ContentReferenceType.CONTENT,
                                contentKey: page.id,
                            }

                            const item: ContentDescriptor = {
                                label: page.name,
                                hash: getContentValueHash(value),
                                value,
                            }
                            map.set(item.hash, item)
                            return item
                        }),
                    title: 'Pages',
                } as SectionDescriptor
            }),
        ]).then(sections => {
            if (actual) {
                setAllItems(sections)
            }
        })

        return () => {
            actual = false
        }
    }, [client])

    const debFilter = useCallback(debounce((_search: string) => {
        const search = _search.trim().toLowerCase()
        const items = allItems || []

        if (!search) {
            setRenderItems(items)
        } else {
            setRenderItems(items.map<SectionDescriptor>(item => ({
                ...item,
                options: item.options.filter(op => op.label.toLocaleLowerCase().includes(search)),
            })).filter(item => item.options.length))
        }

    }, 300), [allItems])

    useEffect(() => {
        debFilter(search || '')
    }, [debFilter, search])

    const loading = !allItems

    return [renderItems, map, loading] as const
}