import { Toast, Layout, Banner, Card, FormLayout, TextField, DropZone, Icon, Button } from "@shopify/polaris";
import { LegalMajor, DeleteMajor } from "@shopify/polaris-icons";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { useClient } from "../../../api/useClient";

export const AppStoreForm = React.memo(() => {
    const client = useClient();
    const settings = client.useSettings();

    const [teamId, setTeamId] = useState<string>(settings.storeConnections.appStore ? settings.storeConnections.appStore.teamId : '');
    const [issuerId, setIssuerId] = useState<string>(settings.storeConnections.appStore ? settings.storeConnections.appStore.issuerId : '');
    const [keyId, setKeyId] = useState<string>(settings.storeConnections.appStore ? settings.storeConnections.appStore.keyId : '');
    const [key, setKey] = useState<string | null>(settings.storeConnections.appStore ? settings.storeConnections.appStore.key : '');
    const [appStoreConnected, setAppStoreConnected] = useState<boolean>(settings.checkAppStoreConnection);
    const [dirty, setdirty] = useState(false);
    const onFileSelected = useCallback((files: File[]) => {
        let reader = new FileReader();
        reader.readAsText(files[0], 'utf-8');
        reader.onload = function (evt) {
            let body = evt.target!.result as string;
            if (!body.startsWith('-----BEGIN PRIVATE KEY-----')) {
                toggleActive
                return;
            }
            if (!body.endsWith('-----END PRIVATE KEY-----')) {
                toggleActive
                return;
            }
            setKey(body);
            setdirty(true);
        }
    }, []);

    const [active, setActive] = useState(false);
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const wrongKeyToast = active ? (
        <Toast content="Wrong key!" error onDismiss={toggleActive} />
    ) : null;

    const onSave = React.useCallback(() => {
        client.mutateSaveAppStore({
            input: {
                issuerId: issuerId,
                keyId: keyId,
                teamId: teamId,
                key: key ? key : '',
            }
        }).then((result) => console.log(result)).catch((error) => console.log(error));
    }, [teamId, issuerId, key, keyId]);



    return (
        <Layout.AnnotatedSection
            title="App Store"
            // description="Appstore connection parameters"
            description={
                <Banner
                    title={"Connected status"}
                    status={appStoreConnected ? "success" : "critical"}
                >
                    <p>{appStoreConnected ? "Connected 🥳" : "Not connected ☠️"}</p>
                </Banner>
            }
        >
            <Card sectioned>
                <FormLayout>
                    <TextField label="Team ID" onChange={(value) => {
                        setTeamId(value);
                        setdirty(true);
                    }} disabled={false} value={teamId} />
                    <TextField label="Issuer ID" onChange={(value) => {
                        setIssuerId(value);
                        setdirty(true);
                    }} disabled={false} value={issuerId} />
                    <TextField label="Key ID" onChange={(value) => {
                        setKeyId(value);
                        setdirty(true);
                    }} disabled={false} value={keyId} />
                    {!key && (
                        <DropZone allowMultiple={false} label="API Token" onDrop={onFileSelected}>
                            {!key && (
                                <DropZone.FileUpload
                                    actionTitle="Add key file"
                                    actionHint="Private key from App Store connect"
                                />
                            )}
                        </DropZone>
                    )}
                    {key && (
                        <View style={{ flexDirection: 'row' }}>
                            <Icon source={LegalMajor} />
                            <Button icon={<Icon source={DeleteMajor} />} onClick={() => {
                                setKey(null);
                                setdirty(true);
                            }} />
                        </View>
                    )}
                    <Button disabled={!dirty} primary onClick={onSave}>Update App Store connection</Button>
                </FormLayout>
            </Card>
            {wrongKeyToast}
        </Layout.AnnotatedSection>
    )
});