import Bugsnag from "@bugsnag/js";
import { animated, config, useTransition } from "@react-spring/native";
import { Badge, Card, Checkbox, Icon, Layout, Modal, Page, SettingToggle, Stack, TextStyle, Tooltip } from "@shopify/polaris";
import { CircleInformationMajor } from "@shopify/polaris-icons";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { useParams } from "react-router";
import { AccountContactType, AccountPricingStatus, AppCardPublishedStatus, AppCardStatus } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { useInfoBanner } from "../../../components/banners/useInfoBanner";
import { TipKey } from "../../../components/banners/useTip";
import { ComingSoonCard } from "../../../components/ComingSoonCard";
import { EmulatorSuspense } from "../../../components/Suspense";
import { AppCard, AppCardPublished } from "../../../components/types";
import { AppCardComponent } from "../../../configure/listing/AppCardComponent";
import { useAccount } from "../../../configure/utils/useAccount";
import { useAppCard } from "../../../configure/utils/useAppCard";
import { ExploreScreen } from "../../../content/emulator/components/ExploreScreen";
import { IPhoneEmulatorContent } from "../../../content/emulator/components/IPhoneEmulatorContent";
import { ScalableEmulatorComponent } from "../../../content/emulator/components/ScalableEmulatorComponent";
import { AppEmulatorConfig } from "../../../content/emulator/types";
import { MixpanelEvent, trackEvent } from "../../../utils/mixpanel";
import { TrafficExchangeContactForm } from "../../Settings/components/TrafficExchangeContactForm";
import { PublicationStatus } from "../pages/TrafficExchangeProfile";
import { mapStatusDescription, PromoteStatuses, PromoteStatusType } from "../utils";
import { AdsCalloutCard } from "./callout/AdsCalloutCard";
import { CommunityCalloutCard } from "./callout/CommunityCalloutCard";
import { ProfileCalloutCard } from "./callout/ProfileCalloutCard";

const findMoreGif = require('../../../assets/gifs/find-more-button.gif');

export const ExchangeCatalogProfile = React.memo(() => {
    const client = useClient();
    const account = useAccount();
    const { id } = useParams<{ id: string }>();
    const contacts = client.useContacts().contacts;

    const [initialAppCard, initialPublishedCard] = useAppCard(id);
    const [publishedAppCard, setPublishedAppCard] = useState<AppCardPublished | null>(initialPublishedCard);
    const [appCard, setAppCard] = useState<AppCard>(initialAppCard);
    const [freeTrafficModalOpen, setFreeTrafficModalOpen] = useState(false);

    const app = client.useApp({ id }).app;
    const appConfig = client.useAppEmulatorConfig({ id }).app.draftConfig as AppEmulatorConfig;

    let publicationBadge;
    let publicationStatus;
    if (
        appCard.status !== AppCardStatus.ON_REVIEW
        && publishedAppCard?.status === AppCardPublishedStatus.ACTIVE
    ) {
        publicationBadge = (<Badge status={"success"}>{PublicationStatus.Published}</Badge>);
        publicationStatus = PublicationStatus.Published;
    } else if (
        appCard.status !== AppCardStatus.ON_REVIEW
        && publishedAppCard?.status === AppCardPublishedStatus.INACTIVE
    ) {
        publicationBadge = (<Badge status={"critical"}>{PublicationStatus.Unpublished}</Badge>);
        publicationStatus = PublicationStatus.Unpublished;
    } else if (appCard.status === AppCardStatus.ON_REVIEW) {
        publicationBadge = (<Badge status={"warning"}>{PublicationStatus.OnReview}</Badge>);
        publicationStatus = PublicationStatus.OnReview;
    }

    let [freeTrafficStatus, freeTrafficStatusDescr] = mapStatusDescription(
        account.pricing.status,
        app.freeTrafficActive,
        publicationStatus
    );

    const handleFreeTrafficEnabledChange = useCallback(
        (active: boolean) => {
            client.mutateSetFreeTraffic({ id: id, active: active })
                .then(() => {
                    client.refetchApp({ id: id })
                }).catch((e) => {
                    Bugsnag.notify(e);
                });
        },
        [id, client],
    );

    const [banner] = useInfoBanner(
        TipKey.FreeTrafficInfo,
        'Request Cartmate Traffic Exchange program publication',
        'Fill out and submit the form, we will review it shortly and your shop will be availible in the \'Explore Cartmate\''
    );

    const closeBanner = useTransition(banner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    });

    const infoBanner = closeBanner(
        (style, banner) => banner
            && <animated.View style={[{ marginBottom: '2rem' }, style]}>
                <Card>{banner}</Card>
            </animated.View>
    );

    return (
        <Layout>
            <Layout.Section>
                {!publishedAppCard && infoBanner}
                <Card title={'Settings'} sectioned>
                    <Stack vertical>
                        <Stack.Item>
                            Traffic Exchange Status: <Badge
                                status={freeTrafficStatus === PromoteStatusType.Active ? 'success' : 'warning'}
                            >
                                {freeTrafficStatus}
                            </Badge><br />
                            {freeTrafficStatusDescr}
                        </Stack.Item>
                        <Stack.Item>
                            <Stack>
                                <Checkbox
                                    label={'Show \'Find more stores\' button'}
                                    checked={app.freeTrafficActive}
                                    onChange={() => setFreeTrafficModalOpen(true)}
                                />
                                <Tooltip content={
                                    <View style={{ width: 350 }}>
                                        <img src={findMoreGif} alt="theme" />
                                    </View>
                                }>
                                    <Icon
                                        source={CircleInformationMajor}
                                        color='interactive'
                                    />
                                </Tooltip>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                    <Modal
                        open={freeTrafficModalOpen}
                        title={undefined}
                        onClose={() => setFreeTrafficModalOpen(false)}
                        primaryAction={{
                            destructive: app.freeTrafficActive,
                            content: app.freeTrafficActive ? 'Hide' : 'Show',
                            onAction: () => {
                                trackEvent(
                                    MixpanelEvent.FreeTrafficToggle,
                                    { enabled: !app.freeTrafficActive }
                                );
                                handleFreeTrafficEnabledChange(!app.freeTrafficActive);
                                setFreeTrafficModalOpen(false);
                            },
                        }}
                        secondaryActions={[
                            {
                                content: 'Cancel',
                                onAction: () => setFreeTrafficModalOpen(false)
                            }
                        ]}
                    >
                        <Modal.Section>
                            <h3>
                                <TextStyle variation="strong">
                                    {`${app.freeTrafficActive ? 'Hide' : 'Show'} 'Find more stores' button?`}
                                </TextStyle>
                            </h3>
                        </Modal.Section>
                    </Modal>
                </Card>
                {__DEV__ && (
                    <Card sectioned title={'Constact information'}>
                        <label>{'Your contact info that will be visible only for Traffic Exchange Program participants'}</label>
                        <TrafficExchangeContactForm
                            contact={contacts?.find((c) => c.type === AccountContactType.TrafficExchange)}
                        />
                    </Card>
                )}
                <AppCardComponent onCardChange={(card) => {
                    console.log('[AppPromote] onCardChange', card);
                    setAppCard(card);
                }} />
                <View style={{ marginTop: '1rem' }}>
                    <ComingSoonCard
                        event={MixpanelEvent.TrafficExchangeAwesome}
                        featureTitle={'Traffic Exchange'}
                    >
                        <ProfileCalloutCard />
                        <AdsCalloutCard />
                        <CommunityCalloutCard />
                    </ComingSoonCard>
                </View>
            </Layout.Section>
            <Layout.Section secondary>
                {/* <Suspense> */}
                <ScalableEmulatorComponent>
                    <IPhoneEmulatorContent
                        appId={id}
                        config={appConfig}
                        showNavigation={false}
                        colored={false}
                        bannerScreen={false}
                    >
                        <EmulatorSuspense>
                            <ExploreScreen
                                card={appCard}
                                config={appConfig}
                            />
                        </EmulatorSuspense>
                    </IPhoneEmulatorContent>
                </ScalableEmulatorComponent>
            </Layout.Section>
        </Layout>
    );
})