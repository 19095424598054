import React, { useCallback, useMemo, useState } from 'react'

type RestoreLastPreview = () => void
type RenderCb<P> = (props: P) => React.ReactNode

export enum PhonePreviews {
    PAGE_EDITOR = 0,
    TAB_VIEW,
    APP_VIEW,
    DEFAULT,
}

export enum PhonePreviewsAccent {
    DEFAULT,
    CONTENT,
    NAVIGATION_BOTTOM_BAR,
}

type RenderMediator<P> = {
    setState(newProps: P): void
    state: Readonly<P>
    destroy: RestoreLastPreview
    render: () => React.ReactNode
    priority: PhonePreviews
    accent: PhonePreviewsAccent
    realPhone: boolean
    deepUrl: string
}

type PhonePreviewState = {
    pushNewPreview<P>(render: RenderCb<P>, initState: P, accent: PhonePreviewsAccent, priority?: PhonePreviews, phoneProps?: {
        realPhone: boolean
        deepUrl: string
    }): RenderMediator<P>
    first: RenderMediator<unknown>
}

const DEFAULT_PREVIEW: RenderMediator<any> = {
    setState() {
    },
    state: {},
    destroy: () => {
    },
    render: () => <div>
        DEFAULT_PREVIEW
    </div>,
    priority: PhonePreviews.DEFAULT,
    accent: PhonePreviewsAccent.DEFAULT,
    realPhone: false,
    deepUrl: ''
}

const EMPTY_STATE: PhonePreviewState = {
    pushNewPreview<P>() {
        return (DEFAULT_PREVIEW as RenderMediator<P>)
    },
    first: DEFAULT_PREVIEW,
}

export const PhonePreviewContext = React.createContext<PhonePreviewState>(EMPTY_STATE)

type State = [RenderMediator<unknown>, ...RenderMediator<unknown>[]]

export const PhonePreviewState = React.memo<React.PropsWithChildren<{}>>(({children}) => {

    const [previews, setPreviews] = useState<State>([DEFAULT_PREVIEW])

    const first = previews[0] || DEFAULT_PREVIEW

    const pushNewPreview = useCallback((render: RenderCb<unknown>, initState: unknown, accent: PhonePreviewsAccent, priority: PhonePreviews = PhonePreviews.DEFAULT, phoneProps?: {
        realPhone: boolean
        deepUrl: string
    }) => {

        const newPreview: RenderMediator<unknown> = {
            render: () => render(newPreview.state),
            state: initState as Readonly<unknown>,
            setState: (newProps: unknown) => {
                newPreview.state = newProps as Readonly<unknown>
                setPreviews(pre => pre.concat() as State)
            },
            destroy: () => setPreviews(pre => pre.filter(p => p !== newPreview) as State),
            priority,
            accent,
            realPhone: !!phoneProps?.realPhone,
            deepUrl: phoneProps?.deepUrl || ''
        };

        setPreviews(pre => {
            const newValue = [newPreview, ...pre];
            newValue.sort((a, b) => a.priority - b.priority)
            return newValue as State
        })

        return newPreview
    }, [])

    const value = useMemo(() => ({
        pushNewPreview,
        first,
    } as PhonePreviewState), [first])

    return (
        <PhonePreviewContext.Provider value={value}>
            {children}
        </PhonePreviewContext.Provider>
    )
})