import React from "react";

export const CheckCircle = React.memo(({state} : {state: "active" | "inactive" | "yellow"}) => {
    switch (state) {
        case "active":
            return (
                <div className="UsageIconBackdrop UsageIconBackdropCorrect"><span
                    className="_3P-mg _1ClWv UsageIcon UsageIconCorrect"
                    aria-label="Do"><svg id="SVGIconCheckmark" viewBox="0 0 20 20"><path d="M19.268 3.232c-.977-.977-2.56-.977-3.535 0L7.5 11.465 4.268 8.232c-.977-.977-2.56-.977-3.535 0s-.977 2.56 0 3.535l5 5c.488.49 1.127.733 1.767.733s1.28-.244 1.768-.732l10-10c.976-.977.976-2.56 0-3.536z"></path></svg></span></div>
            );
        case "inactive":
            return (
                <div className="UsageIconBackdrop UsageIconBackdropIncorrect"><span
                    className="_3P-mg _1ClWv UsageIcon UsageIconIncorrect"
                    aria-label="Don’t">
                                                        <svg id="SVGIconClose" viewBox="0 0 20 20"><path d="M13.535 10l5.732-5.732c.977-.977.977-2.56 0-3.535s-2.56-.977-3.535 0L10 6.465 4.268.732c-.977-.977-2.56-.977-3.535 0s-.977 2.56 0 3.535L6.465 10 .733 15.732c-.977.977-.977 2.56 0 3.535.488.49 1.127.733 1.767.733s1.28-.244 1.768-.732L10 13.535l5.732 5.732c.49.49 1.128.733 1.768.733s1.28-.244 1.768-.732c.977-.977.977-2.56 0-3.535L13.535 10z"></path></svg>
                                                    </span></div>
            );
        case "yellow":
            return (
                <div className="UsageIconBackdrop UsageIconBackdropYellow"><span
                    className="_3P-mg _1ClWv UsageIcon UsageIconYellow"
                    aria-label="Do"><svg id="SVGIconCheckmark" viewBox="0 0 20 20"><path d="M19.268 3.232c-.977-.977-2.56-.977-3.535 0L7.5 11.465 4.268 8.232c-.977-.977-2.56-.977-3.535 0s-.977 2.56 0 3.535l5 5c.488.49 1.127.733 1.767.733s1.28-.244 1.768-.732l10-10c.976-.977.976-2.56 0-3.536z"></path></svg></span></div>
            );
        default:
            throw new Error("Invalid checkcircle state");
    }
});