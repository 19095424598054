import { useCallback, useState } from 'react';

export function useValidatePageName(name: string | undefined) {
    return useValidateEmptyString(name, 'Page name is required')
}

export function useValidateEmptyString(value: string | undefined | null, errorTxt: string) {
    const validator = useCallback((v: string | undefined | null) => {
        if (!v || !v.trim()) {
            return errorTxt
        } else {
            return null
        }
    }, [errorTxt])

    return useCommonValidate(value, validator)
}

export function useValidateEmptyObject<T>(value: T | undefined | null, errorTxt: string) {
    const validator = useCallback((v: T | undefined | null) => {
        if (v === undefined || v === null) {
            return errorTxt
        } else {
            return null
        }
    }, [errorTxt])

    return useCommonValidate(value, validator)
}

function useCommonValidate<T>(value: T, validator: (v: T) => string | null) {
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const clean = useCallback(() => setErrorMsg(null), [])
    const verify = useCallback(() => {
        const hasProblem = validator(value)
        if (hasProblem !== null) {
            setErrorMsg(hasProblem)
            return false
        } else {
            return true
        }
    }, [value, validator])

    return [errorMsg, clean, verify] as const
}