import React, { useEffect, useState } from 'react'
import { Knob } from '../Knob';
import { TextField } from '@shopify/polaris'

export const KnobString = React.memo<Knob.KnobComponentProps<Knob.KnobStringDescriptor>>(({knob, value: initValue, onChange, innerKey}) => {
    const {description, placeholder} = knob
    const [value, setValue] = useState(initValue)

    useEffect(() => {
        if (value !== initValue) {
            onChange(value, innerKey)
        }
    }, [value, knob, innerKey])

    return <TextField
        label={description}
        value={value}
        onChange={setValue}
        placeholder={placeholder ?? ''}
    />
})