import { Button, Collapsible, Icon, InlineError, Select, Stack, TextStyle, Tooltip } from "@shopify/polaris";
import { CircleInformationMajor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { useParams } from "react-router";
import { AdListingType } from "../../../api/spacex.types";
import { Suspense } from "../../../components/Suspense";
import { KnobCollection } from "../../../configEditor/knobs/components/KnobCollection";
import { KnobProduct } from "../../../configEditor/knobs/components/KnobProduct";
import { Knob } from "../../../configEditor/knobs/Knob";
import { useAppCard } from "../../../configure/utils/useAppCard";
import { shortenString } from "../../../utils/textUtils";

export type ListingContent = {
    type: AdListingType,
    id: string,
    title: string
}

const description = 'You can select a type of traffic exchange content you want to display';
const options = [
    { value: '1', label: 'Product' },
    { value: '2', label: 'Collection' },
    { value: '3', label: 'Shop Info' },
];

export const ListingContentSelector = React.memo((
    {
        initialValue,
        onChange,
        error
    }: {
        initialValue?: ListingContent,
        onChange: (value?: ListingContent) => void,
        error?: string
    }
) => {
    console.log('[ListingContentSelector] initialValue:', initialValue);
    const [selected, setSelected] = useState<ListingContent | undefined>();
    const [openedCollapsible, setOpenedCollapsible] = useState<number | undefined>(1);
    const { id } = useParams<{ id: string }>();
    const [appCard, publishedCard] = useAppCard(id);
    if (!publishedCard) {
        return <p><TextStyle>{'Please, complete Traffic Exchange Program moderation'}</TextStyle></p>
    }

    useEffect(() => {
        if (selected !== initialValue) {
            onChange(selected);
        }
    }, [selected]);

    const toggleCollapsible = useCallback(
        (index: number) => {
            if (index === 3) {
                setSelected({ type: AdListingType.STORE, id: publishedCard.id, title: 'Store info' });
            }
            setOpenedCollapsible(index);
        },
        [openedCollapsible, setOpenedCollapsible],
    );

    return (
        <View>
            <Stack vertical>
                <Stack>
                    <Stack.Item>
                        <label>Listing content</label>
                    </Stack.Item>
                    <Stack.Item>
                        <Tooltip content={description}>
                            <Icon
                                source={CircleInformationMajor}
                                color='interactive'
                            />
                        </Tooltip>
                    </Stack.Item>
                </Stack>
                <Stack distribution={'fill'}>
                    <Stack.Item>
                        <Select
                            label=""
                            labelHidden
                            options={options}
                            onChange={(value) => {
                                const index = parseInt(value);
                                toggleCollapsible(index);
                            }}
                            value={openedCollapsible?.toString()}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Collapsible
                            open={openedCollapsible === 1}
                            id="product"
                            transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                            expandOnPrint
                        >
                            <Suspense>
                                <KnobProduct
                                    knob={{ type: 'product' as const, description: '' }}
                                    onChange={(knobValue: Knob.Product, key: string) => {
                                        if ({ ...knobValue, ...{ type: 'Product' } } !== selected) {
                                            setSelected({ type: AdListingType.PRODUCT, id: knobValue.id, title: knobValue.title });
                                        }
                                    }}
                                    innerKey={'ProductNav'}
                                    value={{
                                        id: selected
                                            ? selected.id
                                            : '',
                                        title: selected
                                            ? selected.title
                                            : 'Select a product',
                                        image: undefined
                                    }}
                                />
                            </Suspense>
                        </Collapsible>
                        <Collapsible
                            open={openedCollapsible === 2}
                            id="collection"
                            transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                            expandOnPrint
                        >
                            <Suspense>
                                <KnobCollection
                                    knob={{ type: 'collection' as const, description: '' }}
                                    onChange={(knobValue: Knob.Collection, key: string) => {
                                        if ({ ...knobValue, ...{ type: 'Collection' } } !== selected) {
                                            setSelected({ type: AdListingType.COLLECTION, title: knobValue.title, id: knobValue.id });
                                        }
                                    }}
                                    innerKey={'CollectionNav'}
                                    value={{
                                        id: selected
                                            ? selected.id
                                            : '',
                                        title: selected
                                            ? selected.title
                                            : 'Select a collection',
                                        image: undefined
                                    }}
                                />
                            </Suspense>
                        </Collapsible>
                    </Stack.Item>
                </Stack>
            </Stack>
            {error && <InlineError message={error} fieldID="content-type" />}
        </View>
    );
});