import { Heading, Stack, TextStyle } from "@shopify/polaris";
import React from "react"
import { View, Text } from "react-native"

export const OnboardingStory = React.memo((
    {
        title,
        message,
        media
    }: {
        title?: string,
        message?: string
        media?: any
    }
) => {
    return (
        <View style={{
            flex: 1,
            flexGrow: 1,
            flexWrap: 'wrap',
            backgroundColor: '#fff',
            flexDirection: 'row',
            justifyContent: 'center',
            height: '410px',
            width: '100%'
        }}>
            {media && (
                <View style={{
                    justifyContent: 'center', alignItems: 'center',
                    height: '410px',
                    width: '410px',
                    borderRadius: 8,
                    backgroundColor: '#f6f6f7',
                    overflow: 'hidden',
                }}>

                    <img
                        style={{
                            maxHeight: '410px',
                            minWidth: '200px',
                        }}
                        src={media}
                        alt=''
                    />
                </View>
            )}
            <View style={{
                maxWidth: '370px',
                margin: 16,
                marginTop: 8
            }}>
                <Heading>
                    {title}
                </Heading>
                <TextStyle>
                    {message}
                </TextStyle>
            </View>
        </View>
    );
});