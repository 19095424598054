import React, { SetStateAction, useCallback, useMemo, useState } from 'react'
import { Button, ButtonGroup, FooterHelp, Layout, Link, Page } from '@shopify/polaris';
import { Prompt, Redirect, useParams } from 'react-router';
import { useClient } from '../../api/useClient';
import { PhonePreviewLayout } from '../theme/preview/PhonePreview';
import { TabDesigner } from '../../components/types';
import { ContentReferenceType } from '../../api/spacex.types';
import { useManualSaver } from '../utils/useSaver';
import { EditMoreMenuItem, useVerifyEditFormState } from "./EditMoreMenuItem";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { useAppBridge } from '@shopify/app-bridge-react';

export const AppMoreMenuNew = React.memo(() => {
    const { id } = useParams<{ id: string }>();
    const client = useClient();
    const appBridge = useAppBridge();
    const app = client.useApp({ id }).app;
    const tabs = client.useAppTabsConfig({ id }).app.draftConfig.moreTabsDesigner.map(i => {
        delete i.__typename
        delete i.icon?.__typename
        return i
    });
    const [discard, setDiscard] = useState(false)
    const [haveChanges, setHaveChanges] = useState(false)
    const discardChanges = useCallback(() => setDiscard(true), [])

    const returnPath = useMemo(() => `/apps/${id}/menu`, [id]);

    const onSave = useCallback((tab: TabDesigner) => {
        return client.mutateUpdateApp({
            id,
            config: {
                moreTabs: [...tabs, tab],
            },
        }).then(() => {
            discardChanges()
        }).catch(e => {
            console.error(e, tab)
        })
    }, [client, id])

    const [tab, loading, _onTabChange, doSave] = useManualSaver<TabDesigner>({
        contentKey: null,
        contentUrl: null,
        title: '',
        default: false,
        contentType: ContentReferenceType.EMPTY,
        icon: null,
        key: `tab-${Date.now()}`,
    }, onSave)

    const onTabChange = useCallback((tab: SetStateAction<TabDesigner>) => {
        _onTabChange(tab)
        setHaveChanges(true)
    }, [_onTabChange])

    const [errorState, verify] = useVerifyEditFormState(tab)

    const verifyAndSave = useCallback(() => {
        if (verify()) {
            doSave()
        }
    }, [verify, doSave])

    if (discard) {
        return <Redirect to={returnPath} />
    }

    return (
        <Page title={`Create new tab`}
            breadcrumbs={[{ content: 'Apps', url: '/apps' }, { content: app.name, url: returnPath }]}
            primaryAction={
                <ButtonGroup>
                    {haveChanges && <Button
                        destructive
                        onClick={discardChanges}
                        plain>
                        Discard changes
                    </Button>}
                    <Button>
                        Preview
                    </Button>
                    <Button
                        loading={loading}
                        onClick={verifyAndSave}
                        primary>
                        Create
                    </Button>
                </ButtonGroup>
            }>
            <Prompt message={'All changes will be lost'} when={haveChanges} />
            <PhonePreviewLayout applicationId={id}>
                <Layout.Section>
                    <EditMoreMenuItem item={tab} errorState={errorState} onChange={onTabChange} onSubmit={verifyAndSave} />
                </Layout.Section>
            </PhonePreviewLayout>
            <FooterHelp>
                Learn more about{' '}
                <Link onClick={() => {
                    const redirect = ShopifyRedirect.create(appBridge);
                    redirect.dispatch(ShopifyRedirect.Action.APP, '/about/customize');
                }}>
                    menu items and navigation menu
                </Link>
            </FooterHelp>
        </Page>
    )
})