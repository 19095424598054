import { Button, ButtonGroup, Layout, Page, } from "@shopify/polaris"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { View } from "react-native"
import { useClient } from "../../../api/useClient"
import { IconElement } from "../../../content/elements/IconElement"
import { TabDescriptor } from "@shopify/polaris/dist/types/latest/src/components/Tabs/types"
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router"
import { Suspense } from "../../../components/Suspense"
import { AppEmulator, AppEmulatorMode } from "../../../content/emulator/components/AppEmulator";
import { AppEmulatorPlatform } from "../../../content/emulator/types";
import { AccountPricingStatus, AppBannerInputParams } from "../../../api/spacex.types";
import { getAccountPricingStatusBadge } from "../../../utils/helpers"
import { useAccount } from "../../utils/useAccount"
import { useAppBridge } from "@shopify/app-bridge-react"
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";

export const AppetizerFrame = React.memo((props: {
    sessionId?: string,
    appId?: string,
    frm: React.RefObject<HTMLIFrameElement>,
    showPreview?: boolean,
    smartBanner?: AppBannerInputParams,
    children: any
}) => {
    const [sessionToken, setsessionToken] = useState<string | undefined>();
    const client = useClient();
    const pricing = client.useDefaultPricing().defaultPricing;
    const match = useRouteMatch();
    const appBridge = useAppBridge();

    const handleSubscribe = useCallback(() => {
        client.mutateSubscribeToPricing({ id: account.id, pricingId: pricing.id, returnUrl: match.url }).then((result) => {
            console.log('Subscriiibe result', result);
            const redirect = ShopifyRedirect.create(appBridge);
            if (result.subscribeToPricing) {
                redirect.dispatch(ShopifyRedirect.Action.REMOTE, result.subscribeToPricing);
            }
        }).catch((e) => {
            console.warn(e);
        });
    }, [pricing, match]);

    const { id } = useParams<{ id: string }>();
    const app = client.useApp({ id }).app;
    const account = useAccount();


    let badge = getAccountPricingStatusBadge(account);

    useEffect(() => {
        if (!(props.appId && props.sessionId)) {
            setsessionToken(undefined);
        } else {
            client.queryPreviewToken({ appId: props.appId, sessionId: props.sessionId }).then((value) => {
                setsessionToken(value.previewToken.previewToken);
            });
        }
    }, [props.appId, props.sessionId]);

    // Popover proprs
    const location = useLocation();
    const splitted = location.pathname.split('/menu');
    const showAppetize = splitted.length > 1 ? splitted[1].length > 0 : true;
    const [selected, setSelected] = useState<number>(showAppetize ? 1 : 0);
    const tabs: TabDescriptor[] = [
        {
            id: 'device',
            content: (
                <IconElement
                    name={'ios-qr-code-outline'}
                    collection={'ionicons'}
                    size={24}
                />
            ),
            accessibilityLabel: 'Device',
            panelID: 'device-preview',
        },
        {
            id: 'emulator',
            content: (
                <IconElement
                    name={'ios-phone-portrait-outline'}
                    collection={'ionicons'}
                    size={24}
                />
            ),
            accessibilityLabel: 'Preview on emulator',
            panelID: 'emulator-preview'
        }
    ];

    useEffect(() => {
        if (showAppetize) {
            setSelected(1);
        } else {
            setSelected(0);
        }
    }, [showAppetize]);

    // const match = useRouteMatch();
    const history = useHistory();
    const loc = useMemo(() => { return history.location; }, [history.location.pathname]);
    const params = useParams<{ id: string, pageId?: string }>();
    const deviceList: { label: string, value: AppEmulatorPlatform }[] = [
        { label: 'iPhone 11 Pro', value: "ios" },
        { label: 'Google Pixel 4', value: "android" }
    ];
    const [selectedDevice, setSelectedDevice] = useState<AppEmulatorPlatform>("ios");
    return (
        <Page
            title={`${app.name}`}
            titleMetadata={badge}
            primaryAction={
                <ButtonGroup>
                    {account.pricing.status === AccountPricingStatus.TRIAL && <Button
                        primary
                        onClick={handleSubscribe}
                    >
                        Upgrade subscription
                    </Button>}
                </ButtonGroup>
            }>
            <Layout>
                <Layout.Section>
                    {props.children}
                </Layout.Section>
                <Layout.Section secondary>
                    <View style={{ flex: 1, paddingTop: 72 }}>
                        <Switch location={loc}>
                            <Route path={`${match.path}/menu/item/:key`}>
                                <Suspense>
                                    <AppEmulator
                                        appId={params.id}
                                        mode={AppEmulatorMode.Tab}
                                        selectedPlatform={selectedDevice}
                                        showNavigation={true}
                                    />
                                </Suspense>
                            </Route>
                            <Route path={`${match.path}/menu/new`}>
                                <Suspense>
                                    <AppEmulator
                                        appId={params.id}
                                        mode={AppEmulatorMode.NewTab}
                                        selectedPlatform={selectedDevice}
                                        showNavigation={true}
                                    />
                                </Suspense>
                            </Route>
                            <Route path={`${match.path}/menu`}>
                                <Suspense>
                                    <AppEmulator
                                        appId={params.id}
                                        mode={AppEmulatorMode.Menu}
                                        selectedPlatform={selectedDevice}
                                        showNavigation={true}
                                    />
                                </Suspense>
                            </Route>
                            <Route path={`${match.path}/theme`}>
                                <Suspense>
                                    <AppEmulator
                                        appId={params.id}
                                        mode={AppEmulatorMode.Theme}
                                        selectedPlatform={selectedDevice}
                                        showNavigation={true}
                                    />
                                </Suspense>
                            </Route>
                            <Route path={`${match.path}/pages/:pageId`}>
                                <Suspense>
                                    <AppEmulator
                                        appId={params.id}
                                        mode={AppEmulatorMode.Page}
                                        selectedPlatform={selectedDevice}
                                        showNavigation={true}
                                    />
                                </Suspense>
                            </Route>
                            <Route path={`${match.path}/pages`}>
                                <Suspense>
                                    <AppEmulator
                                        appId={params.id}
                                        mode={AppEmulatorMode.Page}
                                        selectedPlatform={selectedDevice}
                                        showNavigation={true}
                                    />
                                </Suspense>
                            </Route>
                            <Route path={`${match.path}/promo`}>
                                <Suspense>
                                    <AppEmulator
                                        appId={params.id}
                                        mode={AppEmulatorMode.SmartBanner}
                                        selectedPlatform={selectedDevice}
                                        showNavigation={false}
                                    />
                                </Suspense>
                            </Route>
                        </Switch>
                    </View>
                </Layout.Section>
            </Layout>
        </Page>
    );
});