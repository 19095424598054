import * as React from 'react';
import { Button, Text, View } from 'react-native';
import { useClient } from '../api/useClient';
import { TypographyStyle } from '../components/Typography/commonTypography';
import { Chip } from '../components/Chips/Chip';
import { PrimaryChip } from '../components/Chips/PrimaryChip';
import { StyledCheckbox } from '../components/StyledCheckbox/StyledCheckbox';
import { LayoutForm, LayoutPage } from '../components/Layout';

export const Home = React.memo(() => {
    const client = useClient();
    const shop = client.useMyShop();
    const apps = client.useAllApps().apps;
    const account = client.useAccountWrapper().account;

    return (
        <LayoutPage>
            <LayoutForm>
                <Text style={TypographyStyle.display4}>display 4</Text>
                <Text style={TypographyStyle.display3}>display 3</Text>
                <Text style={TypographyStyle.display2}>display 2</Text>
                <Text style={TypographyStyle.display1}>display 1</Text>
                <Text style={TypographyStyle.display1}>display 1</Text>
                <Text style={TypographyStyle.headline}>headline</Text>
                <Text style={TypographyStyle.title}>title</Text>
                <Text style={TypographyStyle.subheading}>subheading</Text>
                <Text style={TypographyStyle.body2}>body2 Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate
                    aliquid ad quas sunt voluptatum officia dolorum cumque, possimus nihil molestias sapiente
                    necessitatibus dolor saepe inventore, soluta id accusantium voluptas beatae.</Text>
                <Text style={TypographyStyle.body1}>body1 Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate
                    aliquid ad quas sunt voluptatum officia dolorum cumque, possimus nihil molestias sapiente
                    necessitatibus dolor saepe inventore, soluta id accusantium voluptas beatae.</Text>
                <Text style={TypographyStyle.caption}>caption</Text>
                <Text style={TypographyStyle.button}>button</Text>

                <View style={{marginVertical: 16}}>
                    <Text style={TypographyStyle.title}>Chips</Text>
                    <View style={{flexDirection: 'row', margin: -4, flexWrap: 'wrap'}}>
                        <Chip style={{margin: 4}}>Chip 1</Chip>
                        <Chip style={{margin: 4}}>Chip 2</Chip>
                        <PrimaryChip style={{margin: 4}}>Chip 3</PrimaryChip>
                        <PrimaryChip style={{margin: 4}}>Chip 4</PrimaryChip>
                    </View>
                </View>

                <View style={{marginVertical: 16}}>
                    <Text style={TypographyStyle.title}>Checkbox</Text>
                    <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                        <StyledCheckbox value={true}/>
                        <StyledCheckbox value={false}/>
                    </View>
                </View>

            </LayoutForm>
            <View style={{alignSelf: 'stretch', flexDirection: 'row', height: 64}}>
                <View>
                    <Text>
                        {shop.shop!.name}:{shop.syncState.status}
                    </Text>
                    <Button title={'make account inactive: ' + account.status} onPress={() => {
                        client.mutateAccountReset(account)
                    }}/>
                </View>
            </View>

            {apps.length === 0 && (
                <View>
                    <Button title="Create App" onPress={() => {
                    }}/>
                </View>
            )}

            {apps.length === 1 && (
                <View>
                    <Text>{apps[0].name}</Text>
                </View>
            )}

            {apps.length > 1 && (
                <View>
                    {/* Draw list */}
                </View>
            )}
        </LayoutPage>
    );
});