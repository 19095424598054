import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from "react-router";
import { useClient } from "../../api/useClient";
import deepMerge from "deepmerge";
import { AppBannerInputParams, ListPricing_listPricing } from "../../api/spacex.types";
import {
    Button,
    Card,
    Layout,
    SettingToggle,
    TextStyle,
    Form,
    FormLayout,
    Stack,
    Collapsible,
    Modal,
    Banner,
    Link,
    FooterHelp,
    CalloutCard,
    InlineError
} from '@shopify/polaris';
import { View } from "react-native";
import { CleanSpaceX } from "../../components/types";
import { KnobFile } from "../../configEditor/knobs/components/KnobFile";
import { useTransition, config, animated } from '@react-spring/native';
import { useInfoBanner } from '../../components/banners/useInfoBanner';
import { TipKey } from '../../components/banners/useTip';
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { useAccessControl } from '../utils/useAccessControl';
import { Capability } from '../../utils/enums';
import { KnobAdvancedText } from '../../configEditor/knobs/components/KnobAdvancedText';
import { FontWeightType } from '../../configEditor/knobs/types';
import { KnobBoolean } from '../../configEditor/knobs/components/KnobBoolean';
import { Knob } from '../../configEditor/knobs/Knob';
import { useSmartBanner } from '../../SmartBannerContext';
import { KnobColor } from '../../configEditor/knobs/components/KnobColor';
import { BannerOnboarding } from '../../components/onboarding/BannerOnboarding';
import { MixpanelEvent, trackEvent } from '../../utils/mixpanel';

export const AppPromotionComponent = React.memo(() => {
    const [_, setSmartBanner] = useSmartBanner();
    const client = useClient();
    const { id } = useParams<{ id: string }>();
    const appBridge = useAppBridge();
    const params = useParams<{ id: string, appId: string }>();
    const appConfig = client.useAppConfig({ id: params.id }).app;
    const brandingLogo = appConfig.settings?.logo;
    const app = client.useGetAppSettings({ id }).app;

    const [form, setForm] = useState<AppBannerInputParams>({
        icon: app.settings?.banner?.params?.icon || brandingLogo || '',
        title: app.settings?.banner?.params?.title || '',
        titleColor: app.settings?.banner?.params?.titleColor,
        titleFontWeight: app.settings?.banner?.params?.titleFontWeight as FontWeightType,
        text: app.settings?.banner?.params?.text || '',
        textColor: app.settings?.banner?.params?.textColor,
        textFontWeight: app.settings?.banner?.params?.textFontWeight,
        buttonText: app.settings?.banner?.params?.buttonText || '',
        buttonTextColor: app.settings?.banner?.params?.buttonTextColor,
        buttonTextFontWeight: app.settings?.banner?.params?.buttonTextFontWeight,
        backgroundColor: app.settings?.banner?.params?.backgroundColor,
        stickToBottom: app.settings?.banner?.params?.stickToBottom,
        fullScreen: app.settings?.banner?.params?.fullScreen
    });
    const [bannerEnabled, setBannerEnabled] = useState<boolean>(Boolean(app.settings?.banner?.scriptTagId));
    const [advancedOpened, setAdvanceOpened] = useState<boolean>(false);
    const [resetModalOpen, setResetModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const defaultBannerProps = useMemo(() => {
        return {
            icon: brandingLogo ? brandingLogo : '',
            title: app.name,
            titleColor: '#fff',
            titleFontWeight: '700',
            text: "Our store is much more convenient to use in a mobile app!",
            textColor: '#fff',
            textFontWeight: '400',
            buttonText: "Install",
            buttonTextColor: "#fff",
            buttonTextFontWeight: "400",
            backgroundColor: '#555555',
            stickToBottom:  false as boolean,
            fullScreen: true as boolean
        }
    }, [app.name, brandingLogo]);

    const handleFormSubmit = useCallback(() => {
        if (!form) return;

        let newIcon = form?.icon
            ? form.icon
            : brandingLogo
                ? brandingLogo
                : '';
        let input: AppBannerInputParams = { ...defaultBannerProps, ...form };
        input.icon = newIcon;
        setLoading(true);
        console.log("Start update banner", input);
        client.mutateUpdateBannerParams({ appId: id, input })
            .then((result) => {
                if (result.editBannerParams?.settings?.banner?.params) {
                    let res = {
                        ...result.editBannerParams?.settings?.banner?.params,
                        __typename: undefined
                    };
                    setForm(res);
                }
                console.log("Banner updated", result);
            })
            .catch((err) => {
                console.error(err);
            }).finally(() => {
                setLoading(false);
            });
    }, [brandingLogo, form, id, defaultBannerProps]);

    const handleReset = useCallback(() => {
        client.mutateUpdateBannerParams({ appId: id, input: defaultBannerProps })
            .then((result) => {
                if (result.editBannerParams?.settings?.banner?.params) {
                    let res = {
                        ...defaultBannerProps,
                        __typename: undefined
                    };
                    setForm(res);
                }
                // setForm(res);
                console.log("Banner updated", result);
            })
            .catch((err) => {
                console.error(err);
            }).finally(() => {
                setLoading(false);
                setResetModalOpen(false);
            });
    }, [client, defaultBannerProps, setForm]);

    const handleBannerEnabledChange = useCallback((value: boolean) => {
        setLoading(true);
        console.log('Banner state change');
        if (value) {
            client.queryEnableBanner({ appId: id })
                .then((result) => {
                    setLoading(false);
                    setBannerEnabled(value);
                    console.log('Banner enabled', result);
                    trackEvent(MixpanelEvent.SmartBanner, { enabled: true });
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        } else {
            client.queryDisableBanner({ appId: id })
                .then((result) => {
                    setLoading(false);
                    setBannerEnabled(value);
                    console.log('Banner disabled', result);
                    trackEvent(MixpanelEvent.SmartBanner, { enabled: false });
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        }
    }, [id]);

    const handleIconChange = useCallback((v) => {
        console.log('[handleIconChange]', v);
        setForm((prevValue) => {
            return {
                ...prevValue, ...{
                    icon: v
                }
            }
        });
    }, [form]);

    useEffect(() => {
        console.log('form setSmartBanner', form);
        setSmartBanner(form);
    }, [form, setSmartBanner]);

    const handleModalChange = useCallback(() => { setResetModalOpen(!resetModalOpen) }, [resetModalOpen]);

    const isDisabled = loading;

    const [banner] = useInfoBanner(
        TipKey.SmartBannerInfo,
        'Convert your mobile web traffic into loyal customers!',
        'App Banner leads directly to your in-app storefront.'
    );

    const closeBanner = useTransition(banner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    })

    const infoBanner = closeBanner(
        (style, banner) => banner
            && <animated.View style={[{ marginBottom: '2rem' }, style]}>
                <Card>{banner}</Card>
            </animated.View>
    );

    const history = useHistory();

    const ac = useAccessControl();
    const hasReadBannerAccess = ac.can('user').readOwn(Capability.PromotionBanner).granted
    const hasUpdateBannerAccess = ac.can('user').updateOwn(Capability.PromotionBanner).granted

    return (
        <View>
            {infoBanner}
            {hasReadBannerAccess && (
                <Layout>
                    <Layout.Section>
                        <SettingToggle
                            action={{
                                content: bannerEnabled ? "Disable" : "Enable",
                                onAction: () => {
                                    handleBannerEnabledChange(!bannerEnabled);
                                },
                                destructive: bannerEnabled
                            }}
                            enabled={bannerEnabled}
                        >
                            App Banner is <TextStyle variation={bannerEnabled ? 'positive' : 'negative'}>{bannerEnabled ? <b>enabled</b> : <b>disabled</b>}</TextStyle>.<br />
                            Displaying App Banner encourages your visitors to install your Mobile in-app storefront and helps to retain customers
                        </SettingToggle>
                        {!hasUpdateBannerAccess && (
                            <CalloutCard
                                illustration="https://cdn.shopify.com/shopifycloud/online-store-web/assets/e6c5834a54458b58a2eab0e2d51bcc02.svg"
                                primaryAction={{
                                    content: 'Upgrade Plan',
                                    onAction: () => {
                                        const redirect = ShopifyRedirect.create(appBridge);
                                        redirect.dispatch(ShopifyRedirect.Action.APP, `/subscription`);
                                    }
                                }}
                                title={'Upgrade your plan'}
                            >
                                <p>To improve your customer's experience and conversion rate, you can customize your App Promotion Banner</p>
                            </CalloutCard>
                        )}
                        {hasUpdateBannerAccess && (
                            <Card title={'Customize App Banner'}>
                                <Card.Section>
                                    <FormLayout>
                                        <KnobAdvancedText
                                            knob={{
                                                type: 'advanced_text' as const,
                                                defaultValue: {
                                                    value: '',
                                                    textColor: '#fff',
                                                    textShadow: false,
                                                    showCaption: true,
                                                    fontWeight: FontWeightType.Normal
                                                },
                                                description: 'Title',
                                                noTextAlign: true
                                            }}
                                            onChange={(v) => {
                                                setForm((prevValue) => {
                                                    return {
                                                        ...prevValue, ...{
                                                            title: v.value.replace(/\n/g, ' '),
                                                            titleColor: v.textColor,
                                                            titleFontWeight: v.fontWeight
                                                        }
                                                    }
                                                });
                                            }}
                                            innerKey={'SmartBannerTitle'}
                                            value={{
                                                showCaption: true,
                                                value: form.title,
                                                textColor: form.titleColor,
                                                fontWeight: form.titleFontWeight as FontWeightType || FontWeightType.Bold
                                            }}
                                            disabled={false}
                                        />
                                        {(!form.title || form.title.length === 0) && <InlineError message={'This field is required'} fieldID="title-field" />}
                                        <KnobAdvancedText
                                            knob={{
                                                type: 'advanced_text' as const,
                                                switchable: false,
                                                defaultValue: {
                                                    value: '',
                                                    textColor: '#fff',
                                                    textShadow: false,
                                                    showCaption: true,
                                                    fontWeight: FontWeightType.Normal
                                                },
                                                description: 'Subtitle',
                                                noTextAlign: true
                                            }}
                                            onChange={(v) => {
                                                setForm((prevValue) => {
                                                    return {
                                                        ...prevValue, ...{
                                                            text: v.value.replace(/\n/g, ' '),
                                                            textColor: v.textColor,
                                                            textFontWeight: v.fontWeight
                                                        }
                                                    }
                                                });
                                            }}
                                            innerKey={'SmartBannerSubtitle'}
                                            value={{
                                                showCaption: true,
                                                value: form.text,
                                                textColor: form.textColor,
                                                fontWeight: form.textFontWeight as FontWeightType || FontWeightType.Light
                                            }}
                                            disabled={false}
                                        />
                                        <KnobAdvancedText
                                            knob={{
                                                type: 'advanced_text' as const,
                                                switchable: false,
                                                noFontWeight: true,
                                                noTextAlign: true,
                                                defaultValue: {
                                                    value: '',
                                                    textColor: '#555',
                                                    textShadow: false,
                                                },
                                                description: 'Background color',
                                                noValue: true,
                                            }}
                                            onChange={(v) => {
                                                setForm((prevValue) => {
                                                    return {
                                                        ...prevValue, ...{
                                                            backgroundColor: v.textColor || undefined
                                                        }
                                                    };
                                                });
                                            }}
                                            innerKey={'SmartBannerBg'}
                                            value={{
                                                value: '',
                                                textColor: form.backgroundColor || '#555',
                                            }}
                                            disabled={false}
                                        />
                                        {(!app.settings?.banner?.params?.icon && brandingLogo) && (
                                            <Banner status="warning">
                                                <TextStyle>
                                                    Your banner logo is not set, please press 'Save' to use your branding logo. Otherwise default Cartmate logo will be used.
                                                </TextStyle>
                                            </Banner>
                                        )}
                                        {(brandingLogo && !form.icon) && (
                                            <Banner status="warning">
                                                <TextStyle>
                                                    Your banner logo is not set, please press 'Save' to use your branding logo for banner.
                                                </TextStyle>
                                            </Banner>
                                        )}
                                        {(!brandingLogo && !app.settings?.banner?.params?.icon) && (
                                            <Banner status="warning">
                                                <TextStyle>
                                                    {/* Your banner and branding logo is not set, default Cartmate logo will be used. */}
                                                    Your banner and branding logo is not set, element with logo is removed from banner design.
                                                </TextStyle>
                                            </Banner>
                                        )}
                                        <Button
                                            onClick={() => { setAdvanceOpened(!advancedOpened) }}
                                            ariaExpanded={advancedOpened}
                                            ariaControls={"banner-advanced"}
                                            disclosure={advancedOpened ? 'up' : 'down'}
                                            fullWidth
                                            plain
                                        >
                                            {advancedOpened ? 'Hide advanced' : 'Advanced'}
                                        </Button>
                                        <Collapsible
                                            id={"banner-advanced"}
                                            open={advancedOpened}
                                            transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
                                            expandOnPrint
                                        >
                                            {!form.fullScreen ? <div style={{
                                                marginBottom: '1.6rem'
                                            }}>
                                                <KnobBoolean
                                                    knob={{
                                                        type: 'boolean',
                                                        defaultValue: false,
                                                        description: 'Show banner on the bottom of screen'
                                                    }}
                                                    value={form.stickToBottom || false}
                                                    innerKey={'SmartBannerStickTobottom'}
                                                    onChange={(v) => {
                                                        console.log('SmartBannerStickTobottom', v);
                                                        setForm((prevValue) => {
                                                            return { ...prevValue, ...{ stickToBottom: v as boolean } }
                                                        });
                                                    }}
                                                    disabled={form.fullScreen || false}
                                                />
                                            </div> : undefined}
                                            {!form.stickToBottom ? <div style={{
                                                marginBottom: '1.6rem'
                                            }}>
                                                <KnobBoolean
                                                    knob={{
                                                        type: 'boolean',
                                                        defaultValue: false,
                                                        description: 'Show banner in fullscreen mode'
                                                    }}
                                                    value={form.fullScreen || false}
                                                    innerKey={'SmartBannerFullScreen'}
                                                    onChange={(v) => {
                                                        console.log('SmartBannerFullScreen', v);
                                                        setForm((prevValue) => {
                                                            return { ...prevValue, ...{ fullScreen: v as boolean } }
                                                        });
                                                    }}
                                                />
                                            </div> : undefined}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "stretch",
                                                    width: "100%"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        boxSizing: "border-box",
                                                        width: "50%",
                                                        paddingRight: "20px"
                                                    }}
                                                >
                                                    <KnobAdvancedText
                                                        knob={{
                                                            type: 'advanced_text' as const,
                                                            switchable: false,
                                                            noTextAlign: true,
                                                            defaultValue: {
                                                                value: '',
                                                                textColor: '#fff',
                                                                textShadow: false,
                                                                showCaption: true,
                                                                fontWeight: FontWeightType.Normal
                                                            },
                                                            description: 'Button text',
                                                        }}
                                                        onChange={(v) => {
                                                            setForm((prevValue) => {
                                                                return {
                                                                    ...prevValue, ...{
                                                                        buttonText: v.value.replace(/\n/g, ' '),
                                                                        buttonTextColor: v.textColor,
                                                                        buttonTextFontWeight: v.fontWeight
                                                                    }
                                                                }
                                                            });
                                                        }}
                                                        innerKey={'buttonTitle'}
                                                        value={{
                                                            showCaption: true,
                                                            value: form.buttonText,
                                                            textColor: form.buttonTextColor,
                                                            fontWeight: form.buttonTextFontWeight as FontWeightType || FontWeightType.Bold,
                                                        }}
                                                        disabled={false}
                                                    />
                                                    <KnobAdvancedText
                                                        knob={{
                                                            type: 'advanced_text' as const,
                                                            switchable: false,
                                                            noTextAlign: true,
                                                            noFontWeight: true,
                                                            defaultValue: {
                                                                value: '',
                                                                textColor: '#0f80ff',
                                                                textShadow: false,
                                                            },
                                                            description: 'Button color',
                                                            noValue: true
                                                        }}
                                                        onChange={(v) => {
                                                            setForm((prevValue) => {
                                                                return { ...prevValue, ...{ buttonColor: v.textColor || undefined } }
                                                            });
                                                        }}
                                                        innerKey={'SmartBannerButton'}
                                                        value={{
                                                            value: '',
                                                            textColor: form.buttonColor || '#0f80ff',
                                                        }}
                                                        disabled={false}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        boxSizing: "border-box",
                                                        width: "50%"
                                                    }}
                                                >
                                                    <View key={"Icon"}>
                                                        <KnobFile knob={{
                                                            type: 'file' as const,
                                                            defaultValue: '',
                                                            description: 'Icon on banner',
                                                            clearable: true
                                                        }}
                                                            value={form.icon}
                                                            innerKey={"BannerIcon"}
                                                            onChange={
                                                                (knobValue, key: string) => handleIconChange(knobValue)
                                                            }
                                                            disabled={isDisabled}
                                                            dropZoneProps={{
                                                                accept: "image/*"
                                                            }}
                                                        />
                                                    </View>
                                                </div>
                                            </div>
                                        </Collapsible>
                                        <Stack distribution={"trailing"}>
                                            <Button loading={loading} onClick={handleModalChange}>Reset to default</Button>
                                            <Button onClick={handleFormSubmit} disabled={!form.title || form.title.length === 0} primary loading={loading}>Save</Button>
                                        </Stack>
                                    </FormLayout>
                                </Card.Section>
                            </Card>
                        )}
                    </Layout.Section>
                </Layout>
            )}
            <Modal
                open={resetModalOpen}
                title={"Confirm reset to default"}
                onClose={handleModalChange}
                primaryAction={{
                    content: "Confirm",
                    onAction: handleReset,
                }}
                secondaryActions={[
                    {
                        content: "Close",
                        onAction: handleModalChange
                    }
                ]}
            >
                <Modal.Section>
                    <TextStyle variation={"strong"}>Reset banner props to default?</TextStyle>
                </Modal.Section>
            </Modal>
            <FooterHelp>
                Learn more about{' '}
                <Link onClick={() => {
                    history.push({ pathname: '/about/customize' });
                }}>
                    App Promotion Banner
                </Link>
            </FooterHelp>
            <BannerOnboarding />
        </View>
    );
});
export interface BannerContextInterface {
    title: string,
    text: string
}

export const BannerContext = React.createContext<BannerContextInterface>(undefined as any);

export function useBannerConfig(): BannerContextInterface {
    return React.useContext(BannerContext);
}

type ListPricing = CleanSpaceX<ListPricing_listPricing>;
