import { Modal, TextStyle } from "@shopify/polaris";
import React from "react";
import { ListAdListingsItems_listAdListingItems_edges_node } from "../../../api/spacex.types";
import { SkeletonSuspense, SkeletonType } from "../../../components/Skeleton/SkeletonSuspense";
import { ListingItemContentPreview } from "./ListingItemContentPreview";

export const ReviewListingItemModal = React.memo((
    {
        item,
        handleCancel,
        handleAccept,
        handleSuspend
    }: {
        item?: ListAdListingsItems_listAdListingItems_edges_node,
        handleCancel: () => void,
        handleAccept?: (item?: ListAdListingsItems_listAdListingItems_edges_node) => void
        handleSuspend?: (item?: ListAdListingsItems_listAdListingItems_edges_node) => void
    }
) => {

    return (
        <Modal
            open={item !== undefined}
            onClose={handleCancel}
            title={`Request review`}
            primaryAction={{
                content: handleSuspend ? 'Stop' : 'Accept',
                destructive: handleSuspend ? true : false,
                onAction: () => {
                    if (!handleSuspend) {
                        if (handleAccept) handleAccept(item);
                        return;
                    }
                    handleSuspend(item);
                },
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: handleCancel,
                },
            ]}
        >
            <Modal.Section>
                <p>
                    <TextStyle>
                        {'If you accept this request this position will be visible on your Cartmate storefront ' +
                            ' and you will recieve points for every click on this position form your Cartmate storefront'}
                    </TextStyle>
                </p>
            </Modal.Section>
            <Modal.Section>
                <SkeletonSuspense type={SkeletonType.Text}>
                    <ListingItemContentPreview type={item?.adListing.type} id={item?.adListing.id} />
                </SkeletonSuspense>
            </Modal.Section>
        </Modal>
    );
});