import { useAppBridge } from "@shopify/app-bridge-react";
import { CalloutCard } from "@shopify/polaris";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import React from "react";

const pushIcon = require('../../../../assets/images/push-icon.svg');

export const PushCalloutCard = React.memo(() => {
    const appBridge = useAppBridge();

    return (
        <CalloutCard
            title="Send push notifications"
            illustration={pushIcon}
            primaryAction={{
                content: 'Send',
                onAction: () => {
                    const redirect = ShopifyRedirect.create(appBridge);
                    redirect.dispatch(ShopifyRedirect.Action.APP, '/push/mailing');
                }
            }}
        >
            <p>Push notifications is a good way to boost your sales. You can customize title and text on all plans, but image adding and notification scheduling if availible only at PRO plan. Standard plan allows you to send 1 push notification per week, but PRO plan grants 7 notifications per week.</p>
        </CalloutCard>
    );
});