import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { useClient } from '../../../api/useClient';
import { defaultTheme, loadTheme, resolveTheme } from '../themes/themes';
import { Layout, Icon } from '@shopify/polaris';
import { useMediaQuery } from '@react-hook/media-query';
import { PhonePreviewContext, PhonePreviewsAccent } from './PhonePreviewContext';
import { ResolvedTheme } from '../model/theme';
import { Suspense } from '../../../components/Suspense';
import { PhonePreviewStyle } from './PhonePreviewStyles';
import { animated, useSpring, useTransition, config as SpringConfig, useChain } from '@react-spring/native'
import { ArrowRightMinor } from '@shopify/polaris-icons';
import { Scrollbars } from 'react-custom-scrollbars'
import { Appetizer } from './Appetizer';

type Props = {
    applicationId: string | 'unknown'
}

const emptyScroll = () => <span />

export const PhonePreview = React.memo<Props>(({ applicationId }) => {
    const client = useClient();
    let config: ResolvedTheme
    let appName = ''

    if (applicationId === 'unknown') {
        config = defaultTheme.resolve(defaultTheme.default)
    } else {
        const app = useClient().useApp({ id: applicationId }).app;
        appName = app.name
        const initialDesignerData = client.useAppConfig({ id: applicationId }).app.draftConfig.design.designerData;
        const initialTheme = loadTheme(initialDesignerData);
        config = resolveTheme(initialTheme)
    }


    const { accent, render: getContent, realPhone, deepUrl } = useContext(PhonePreviewContext).first

    const isBottom = accent === PhonePreviewsAccent.NAVIGATION_BOTTOM_BAR;

    const phoneShiftRef = useRef(null)
    const phoneShift = useSpring({
        ref: phoneShiftRef,
        from: { opacity: 0, top: -800 },
        to: { opacity: 1, top: isBottom ? -450 : 0 },
    })

    const aConfig = useSpring({
        to: config,
    })

    const tipTransitionRef = useRef(null)
    const tipTransition = useTransition(isBottom, {
        ref: tipTransitionRef,
        enter: {
            opacity: 1,
        },
        leave: {
            opacity: 0,
        },
    })

    const tipShift = useSpring({
        loop: { reverse: true },
        from: {
            left: -30,
        },
        to: {
            left: -20,
        },
        config: SpringConfig.stiff,
    })

    const tip = tipTransition((style, isBottom) => isBottom &&
        <animated.View style={[PhonePreviewStyle.bottomTip, style, tipShift]}>
            <Icon source={ArrowRightMinor} color={'primary'} />
        </animated.View>,
    )

    const contentTransition = useTransition(getContent, {
        from: { opacity: 0, translateX: 100 },
        enter: { opacity: 1, translateX: 0 },
        leave: { opacity: 0, translateX: -50 },
    })

    const content = contentTransition((style, renderContent) => {
        return <animated.View style={{ height: 404, width: '100%', position: 'absolute', ...style }}>
            <Scrollbars renderThumbVertical={emptyScroll}>
                <animated.View style={[PhonePreviewStyle.content, { backgroundColor: aConfig.background }]}>
                    <Suspense style={PhonePreviewStyle.suspense}>
                        {renderContent()}
                    </Suspense>
                </animated.View>
            </Scrollbars>
        </animated.View>
    })

    useChain(useMemo(() => !isBottom ? [tipTransitionRef, phoneShiftRef] : [phoneShiftRef, tipTransitionRef], [isBottom]))

    const frm = useRef<HTMLIFrameElement>(null)
    const [log, setLog] = useState<string[]>([])
    useEffect(() => {
        const messageEventHandler = function (event: MessageEvent) {
            if (event.data.type === 'debug') {
                setLog(all => [...all, JSON.stringify(event.data.message, undefined, 4)]);
            }
        };

        window.addEventListener("message", messageEventHandler, false)

        frm.current?.contentWindow?.postMessage({ type: 'openUrl', value: deepUrl }, '*')

        // clean up
        return () => window.removeEventListener("message", messageEventHandler)
    }, [deepUrl, realPhone])

    if (realPhone) {
        return <View style={{ padding: 20 }}>
            <Appetizer
                public='6r89mwz6m4ufhb6h1vxma2tw38'
                color='white'
                device='nexus5'
                deviceColor="black"
                autoplay={true}
                scale={60}
                ifrRef={frm}
                debug={false}
                xdoc
                apk={true}
                style={{
                    width: 258,
                    height: 549,
                }}
                link={deepUrl}
            />

            <View><Text>deepUrl: {deepUrl}</Text></View>
            <View><Text>Log:</Text></View>
            <ScrollView style={{ height: 300, width: 300 }}>
                {log.map((l, index) => <View key={index}><Text>{l}</Text></View>)}
            </ScrollView>
        </View>
    }

    return (
        <View style={PhonePreviewStyle.wrapper}>
            <animated.View style={[PhonePreviewStyle.phone, phoneShift]}>
                <animated.View
                    style={[PhonePreviewStyle.header, { backgroundColor: aConfig.toolBarBackground }]}>
                    <animated.Text
                        style={[PhonePreviewStyle.headerText, { color: aConfig.backgroundText }]}>{appName}</animated.Text>
                </animated.View>
                <View style={PhonePreviewStyle.contentWrapper}>
                    {content}
                </View>
                <animated.View
                    style={[PhonePreviewStyle.footer, { backgroundColor: aConfig.bottomNavigationBackground }]}>
                    {tip}
                    <animated.View style={[PhonePreviewStyle.dote, { backgroundColor: config.accent }]} />
                    <View style={[PhonePreviewStyle.dote, { backgroundColor: '#BDBDBD' }]} />
                    <View style={[PhonePreviewStyle.dote, { backgroundColor: '#BDBDBD' }]} />
                    <View style={[PhonePreviewStyle.dote, { backgroundColor: '#BDBDBD' }]} />
                </animated.View>
            </animated.View>
        </View>
    )
});

export const PhonePreviewLayout = React.memo<React.PropsWithChildren<Props>>(({ children, applicationId }) => {
    const matches = useMediaQuery('(min-width: 67.0625em)')

    return (
        <Layout>
            {children}
            {/* {matches && <PhonePreview applicationId={applicationId} />} */}
        </Layout>
    )
})