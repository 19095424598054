import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { AppEmulatorConfig } from "../types";
import { AppEmulatorMenu } from "./AppEmulatorMenu";
import { IOSToolbar } from "./IOSToolbar";
import { useMediaQuery } from "@react-hook/media-query";
import { useEmulatorConfig } from "./ScalableEmulatorComponent";
import { ThemeType } from "../States";
import { propTypes } from "qrcode.react";

const iosBg = require('../../../assets/images/iPhoneXS-SpaceGray.png');


export interface EmulatorContextInterface {
    width: number,
    height: number,
    fontSize: number,
    aspectRatio: number,
    baseWidth: number,
    baseHeight: number,
    baseFontSize: number,
    contentWidth: number,
    navigationVisible: boolean,
    fs: (baseFontSize: number) => number
    w: (baseWidth: number) => number
    h: (baseHeight: number) => number
    cw: () => number
    setNavigationVisible: (state: boolean) => void
}

export const IPhoneEmulatorContent = React.memo<PropsWithChildren<{ appId: string, config: AppEmulatorConfig, showNavigation: boolean, colored: boolean, bannerScreen: boolean }>>((
    {
        appId,
        config,
        children,
        showNavigation,
        colored,
        bannerScreen
    }
) => {
    const aspectRatio = 765 / 1544;
    const MAX_WIDTH = 300;
    const CONTENT_WIDTH = 284;
    const BASE_FONT_SIZE = 24;
    const BASE_WIDTH = MAX_WIDTH;
    const BASE_HEIGHT = BASE_WIDTH / aspectRatio;
    const emulator = useEmulatorConfig();

    const colors = config.design.colors[emulator.theme];
    console.log('theme;', emulator.theme);

    const bg = colors.background;

    let bgl
    let bgd
    if (emulator.theme === ThemeType.Light) {
        bgl = '#fff'
        bgd = '#ececec'
    } else {
        // bgl = '#000'
        bgl = '#1b1b1b'
        bgd = '#353535'
    }
    if (bannerScreen) {
        bgl = '#fff'
        bgd = '#fff'
    }
    const [width, setWidth] = useState<number>(MAX_WIDTH);
    const [height, setHeight] = useState<number>(MAX_WIDTH / aspectRatio);
    const [fontSize, setFontSize] = useState<number>(BASE_FONT_SIZE);
    const [navigationVisible, setNavigationVisible] = useState<boolean>(showNavigation);

    const handleResize = useCallback((_event) => {
        const blockWidth = (document.querySelector('#ios-emulator')?.clientWidth || MAX_WIDTH);
        // console.log('Width', document.querySelector('#ios-emulator'), document.querySelector('#ios-emulator')?.clientWidth, blockWidth)
        const newWidth = Math.min(blockWidth, MAX_WIDTH);
        const newHeight = newWidth / aspectRatio;
        const newFontSize = (BASE_FONT_SIZE / 100) * (newWidth / (MAX_WIDTH / 100));
        // console.log('New dims', newWidth, newHeight, newFontSize);
        setWidth(newWidth);
        setHeight(newHeight);
        setFontSize(newFontSize);
    }, [aspectRatio, setHeight, setWidth, MAX_WIDTH, document.querySelector('#ios-emulator'), BASE_FONT_SIZE, setFontSize]);

    useEffect(() => {
        handleResize(null);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        setNavigationVisible(showNavigation);
    }, [showNavigation]);

    const convertFontSize = useCallback((baseFontSize: number): number => {
        return baseFontSize / (BASE_FONT_SIZE / fontSize)
    }, [fontSize, BASE_FONT_SIZE]);

    const convertHeight = useCallback((baseHeight: number): number => {
        return baseHeight / (BASE_HEIGHT / height);
    }, [height, BASE_HEIGHT]);

    const convertWidth = useCallback((baseWidth: number): number => {
        return baseWidth / (BASE_WIDTH / width);
    }, [width, BASE_WIDTH]);

    const convertContentWidth = useCallback(() => {
        return convertWidth(CONTENT_WIDTH);
    }, [convertWidth, CONTENT_WIDTH]);

    const context = useMemo<EmulatorContextInterface>(() => {
        return {
            width,
            height,
            fontSize,
            aspectRatio,
            baseWidth: MAX_WIDTH,
            baseHeight: MAX_WIDTH / aspectRatio,
            baseFontSize: BASE_FONT_SIZE,
            contentWidth: CONTENT_WIDTH,
            navigationVisible,
            fs: convertFontSize,
            w: convertWidth,
            h: convertHeight,
            cw: convertContentWidth,
            setNavigationVisible: (navigationVisible) => {
                if (showNavigation) return setNavigationVisible(navigationVisible);
                return setNavigationVisible(false);
            }
        };
    }, [width, height, fontSize, aspectRatio, MAX_WIDTH, BASE_FONT_SIZE, convertFontSize, navigationVisible, setNavigationVisible, showNavigation]);
    const matches = useMediaQuery('(min-width: 740px)');

    return (

        <div
            id={"ios-emulator"}
            style={{
                // marginTop: "50px",
                // marginRight: "5rem",
                // marginLeft: matches ? 0 : "3rem",

                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                flexShrink: 1,
                width: `100%`,
                height: '100%',
                position: 'relative',
                fontSize: '24px',
                zIndex: 5,
                // transform: 'scale(0.4)'
            }}
        >
            <div style={{
                position: 'relative',
                // overflow: 'hidden',
                zIndex: 5,
                borderWidth: '3px',
                // borderWidth: 20,
                borderStyle: "solid",
                // borderColor: "gray",
                // borderColor: "transparent",
                borderColor: "#213744",
                // borderColor: "rgb(248, 244, 238)",
                // borderColor: "#000",
                // borderRadius: "10% / 5%",
                // borderRadius: "10% / 5%",
                // borderRadius: "123px / 104px",
                borderRadius: `${emulator.isSmall ? '99px / 104px' : '123px / 104px'}`,
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                flexShrink: 1,
                flex: 1,
                height: "100%",
                // margin:'0 10px'
                // boxShadow: "0px 0px 10px #000"
            }}
            >
                <div style={{
                    position: 'relative',
                    // overflow: 'hidden',
                    zIndex: 5,
                    borderWidth: '3px',
                    // borderWidth: 20,
                    borderStyle: "solid",
                    // borderColor: "gray",
                    // borderColor: "transparent",
                    borderColor: "#8EADC1",
                    // borderColor: "rgb(248, 244, 238)",
                    // borderColor: "#000",
                    // borderRadius: "10% / 5%",
                    // borderRadius: "10% / 5%",
                    // borderRadius: "120px / 101px",
                    borderRadius: `${emulator.isSmall ? '96px / 101px' : '120px / 101px'}`,
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    flexShrink: 1,
                    flex: 1,
                    height: "100%",
                    // margin:'0 10px'
                    // boxShadow: "0px 0px 10px #000"
                }}
                >
                    <div style={{
                        position: 'relative',
                        // overflow: 'hidden',
                        zIndex: 5,
                        borderWidth: 6,
                        // borderWidth: 20,
                        borderStyle: "solid",
                        // borderColor: "gray",
                        // borderColor: "transparent",
                        borderColor: "#213744",
                        // borderColor: "rgb(248, 244, 238)",
                        // borderColor: "#000",
                        // borderRadius: "10% / 5%",
                        // borderRadius: "10% / 5%",
                        // borderRadius: "115px / 99px",
                        borderRadius: `${emulator.isSmall ? '92px / 99px' : '115px / 99px'}`,
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        flexShrink: 1,
                        flex: 1,
                        height: "100%",
                        // margin:'0 10px'
                        // boxShadow: "0px 0px 10px #000"
                    }}
                    >
                        <div
                            className={"ios__content-wrapper"}
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                // border: "30px solid black",
                                border: `${emulator.isSmall ? '25px' : '30px'} solid black`,
                                // borderRadius: "15% / 7%",
                                // borderRadius: "106px / 93px",
                                borderRadius: `${emulator.isSmall ? '86px / 93px' : '106px / 93px'}`,
                                overflow: "hidden",
                                flexGrow: 1,
                                flexShrink: 1,
                                backgroundColor: bannerScreen ? '#fff':  bg,
                                // position:'relative'

                            }}
                        >
                            <div className={'ios__button-left1'}
                                style={{
                                    position: 'absolute',
                                    width: '6px',
                                    height: `${emulator.isSmall ? '40px' : '50px'}`,
                                    left: '-7px',
                                    top: `${emulator.isSmall ? '205px' : '250px'}`,
                                    backgroundColor: '#213744',
                                    transform: 'translateX(-10px)',
                                    borderTopLeftRadius: '5px',
                                    borderBottomLeftRadius: '5px',
                                }} />
                            <div className={'ios__button-left2'}
                                style={{
                                    position: 'absolute',
                                    width: '6px',
                                    height: `${emulator.isSmall ? '80px' : '100px'}`,
                                    // height: '100px',
                                    left: '-7px',
                                    top: `${emulator.isSmall ? '285px' : '340px'}`,
                                    backgroundColor: '#213744',
                                    transform: 'translateX(-10px)',
                                    borderTopLeftRadius: '5px',
                                    borderBottomLeftRadius: '5px',
                                }}
                            />
                            <div className={'ios__button-left3'}
                                style={{
                                    position: 'absolute',
                                    width: '6px',
                                    height: `${emulator.isSmall ? '80px' : '100px'}`,
                                    left: '-7px',
                                    top: `${emulator.isSmall ? '395px' : '470px'}`,
                                    backgroundColor: '#213744',
                                    transform: 'translateX(-10px)',
                                    borderTopLeftRadius: '5px',
                                    borderBottomLeftRadius: '5px',
                                }}
                            />
                            <div className={'ios__button-right'}
                                style={{
                                    position: 'absolute',
                                    width: '6px',
                                    height: `${emulator.isSmall ? '145px' : '170px'}`,
                                    right: '-27px',
                                    top: `${emulator.isSmall ? '305px' : '370px'}`,
                                    backgroundColor: '#213744',
                                    transform: 'translateX(-10px)',
                                    borderTopRightRadius: '5px',
                                    borderBottomRightRadius: '5px',
                                }}
                            />
                            <IOSToolbar config={config} transparent={false} colored={colored} smartBanner={bannerScreen}/>
                            <div
                                className="ios__content"
                                style={{
                                    width: "100%",
                                    // height: '540px',
                                    // backgroundColor: config.design.colors.background,
                                    // backgroundColor: bgg,
                                    backgroundColor: `${colored ? bg : bgl}`,
                                    zIndex: 10,
                                    overflow: "hidden",
                                    flexGrow: 1,
                                    flexShrink: 1,
                                    display: "flex",
                                    flexDirection: 'column',
                                    // marginTop: '-1px'
                                    marginTop: '1px'
                                }}
                            >
                                {children}
                            </div>
                            {navigationVisible && <div className="ios__tabs" style={{
                                width: "100%",
                                padding: '10px 0',
                                backgroundColor: colors.bottomNavigationBackground,
                                position: 'relative',
                                zIndex: 1,
                                flexGrow: 0,
                                flexShrink: 0,
                                marginBottom: '-1px'
                            }}>
                                <AppEmulatorMenu config={config} selectedPlatform={"ios"} />
                            </div>}
                            <div
                                className={"ios__safe-area"}
                                style={{
                                    width: "100%",
                                    // height: '40px',
                                    height: `${emulator.isSmall ? '25px' : '40px'}`,
                                    // backgroundColor: colors.bottomNavigationBackground,
                                    backgroundColor: `${colored ? bg : bgl}`,
                                    position: 'relative',
                                    zIndex: 1,
                                    flexGrow: 0,
                                    flexShrink: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <div
                                    className={"ios__safe-area-control"}
                                    style={{
                                        // height: '8px',
                                        height: `${emulator.isSmall ? '6px' : '8px'}`,
                                        margin: '6px 10px',
                                        backgroundColor: '#000',
                                        width: "30%",
                                        borderRadius: '20px'
                                    }}
                                >

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </View> */}

        </div>


    );
});