import { TextField } from "@shopify/polaris"
import React, { useEffect, useState } from "react"
import { Knob } from "../Knob"

export const KnobWeb = React.memo<Knob.KnobComponentProps<Knob.KnobWebDescriptor>>(({ knob, value: initValue, onChange, innerKey }) => {
    const { description } = knob;
    const [value, setValue] = useState(initValue);

    useEffect(() => {
        console.log('KnobWeb', initValue, value);
        if (value.url !== initValue.url) {
            onChange(value, innerKey);
        }
    }, [value, knob, innerKey]);

    return <TextField
        label={description}
        value={value.url}
        inputMode={'url'}
        onChange={(text) => {
            console.log('text', text, value);
            if (text !== value.url) {
                setValue({ url: encodeURIComponent(text) });
            }
        }}
    />
});


export const KnobShop = React.memo<Knob.KnobComponentProps<Knob.KnobShopDescriptor>>(({ knob, value: initValue, onChange, innerKey }) => {
    const { description } = knob;
    const [value, setValue] = useState(initValue);

    useEffect(() => {
        console.log('KnobShop', initValue, value);     
        onChange(value, innerKey);       
    }, []);

    return <TextField
        label={description}
        value={'/main'}
        disabled        
    />
});
