import { Card, Layout, Page, ResourceItem, ResourceList, TextStyle } from '@shopify/polaris';
import React, {useEffect, useMemo} from 'react';
import { useClient } from '../api/useClient';
import {useHistory, useRouteMatch} from 'react-router';

export const Apps = React.memo(() => {
    const apps = useClient().useAllApps().apps;
    const match = useRouteMatch()

    const sortedApps = useMemo(() => apps.sort((a, b) => a.name.localeCompare(b.name)), [apps])

    const activeApps = useMemo(() => sortedApps.filter(app => app.active), [sortedApps])

    const activeAppsList = <Card>
        <ResourceList
            items={activeApps}
            renderItem={(a) => {
                return (
                    <ResourceItem
                        id={a.id}
                        url={`${match.url}/${a.id}`}
                        name={a.name}
                    >
                        <h3>
                            <TextStyle variation="strong">{a.name}</TextStyle>
                        </h3>
                    </ResourceItem>
                )
            }}
        />
    </Card>

    const notActiveApps = useMemo(() => sortedApps.filter(app => !app.active), [sortedApps]);
    const history = useHistory<{ from?: Location }>()

    useEffect(()=>{
        if (activeApps.length === 1) {
            history.replace({pathname:`/apps/${activeApps[0].id}`});
        }
    }, [activeApps]);

    return (
        <Page title="Apps" primaryAction={{content: 'Create', url: `${match.url}/new`}}>
            {notActiveApps.length ? <Layout>
                <Layout.AnnotatedSection title={'Active apps'}>
                    {activeAppsList}
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection title={'Archived apps'}>
                    <Card>
                        <ResourceList
                            items={notActiveApps}
                            renderItem={(a) => {
                                return (
                                    <ResourceItem
                                        id={a.id}
                                        url={`${match.url}/${a.id}`}
                                        name={a.name}
                                    >
                                        <h3>
                                            <TextStyle>{a.name}</TextStyle>
                                        </h3>
                                    </ResourceItem>
                                )
                            }}
                        />
                    </Card>
                </Layout.AnnotatedSection>
            </Layout> : activeAppsList}
        </Page>
    );
});