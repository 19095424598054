import { RenderDNDElementProps, SortableDndList } from '../../components/sortableDNDList/SortableDndList';
import * as React from 'react';
import { useCallback } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { Badge, Button, Card, EmptyState, Icon, Stack, TextStyle, UnstyledLink } from '@shopify/polaris';
import { DeleteMinor, DragHandleMinor, EditMinor } from '@shopify/polaris-icons';
import { useClient } from '../../api/useClient';
import { useInfoBanner } from '../../components/banners/useInfoBanner';
import { TipKey } from '../../components/banners/useTip';
import { View } from 'react-native';
import { TabDesigner } from '../../components/types';
import { DynamicIcon, IconWrapper } from '../../components/Icon/IconView/Icons';
import { config, useTransition, animated } from '@react-spring/native';
import { useSaver } from '../utils/useSaver';
import { useAccessControl } from '../utils/useAccessControl';
import { Capability } from '../../utils/enums';
import { AccessControl } from 'accesscontrol';
import Bugsnag from '@bugsnag/browser';

function getItemId(tab: TabDesigner) {
    return tab.key
}

export const AppMoreMenuItems = React.memo(() => {
    const { id } = useParams<{ id: string }>();
    const client = useClient();
    const serverTabsDesigner = client.useAppTabsConfig({ id }).app.draftConfig.moreTabsDesigner.map(i => {
        delete i.__typename
        delete i.icon?.__typename
        return i
    });
    const ac = useAccessControl();
    const onSave = useCallback((tabs: TabDesigner[]) => {
        return client.mutateUpdateApp({
            id,
            config: {
                moreTabs: tabs,
            },
        }).then(() => {
            client
                .refetchAppTabsConfig({ id })
                .then((res) => {
                    console.log('[AppMenuItems]: App tabs config refetched', res);
                });
        }).catch(e => {
            Bugsnag.notify(e);
        });
    }, [client, id])

    const [tabs, loading, onTabChange] = useSaver<TabDesigner[]>(serverTabsDesigner, onSave)

    const [banner] = useInfoBanner(
        TipKey.Max5Items,
        // '\'More\' bottom navigation tab',
        // 'You can add up to 5 items to your \'more\' tab menu')
        '\'More\' navigation button customization',
        'With the “More” navigation button, you can customize items that are important for the users but which are not as crucial as the items in the bottom navigation panel. This menu contains “My Cart”, “My Wishlist”, and “My Orders” by default. You can add more, but no more than 5 items.')

    const match = useRouteMatch()

    const primaryFooterAction = (serverTabsDesigner.length < 5 && ac.can('user').createOwn(Capability.MoreMenu).granted) ? {
        content: 'Add item',
        url: `${match.url}/more-new`,
    } : undefined

    const closeBanner = useTransition(banner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    })

    const bannerC = closeBanner((style, banner) => banner
        && <animated.View style={[{ marginBottom: '2rem' }, style]}><Card>{banner}</Card></animated.View>)

    if (!ac.can('user').readOwn(Capability.MoreMenu).granted) {
        return (
            <Card sectioned>
                <EmptyState
                    heading="Not allowed"
                    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                >
                    <p>You do not have required permissions</p>
                </EmptyState>
            </Card>
        );
    }
    return (
        <View style={{ marginTop: '2rem' }}>
            {bannerC}
            <Card title={'\'More\' screen tab settings'}
                primaryFooterAction={(!tabs || tabs.length === 0) ? undefined : primaryFooterAction}
                footerActionAlignment={'right'}>
                {(!tabs || tabs.length === 0) && (
                    <EmptyState
                        heading="No items"
                        action={{ content: 'Add item', url: primaryFooterAction?.url }}
                        image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
                    />
                )}
                <SortableDndList
                    key={`SortableDndListMore-${tabs.length}`}
                    droppableKey={`MoreDroppable-${tabs.length}`}
                    items={tabs}
                    getKey={getItemId}
                    onChangeList={onTabChange}
                    conformOnRemove={true}
                    renderItem={(props) => renderItem(props, ac)}
                />
            </Card>
        </View>
    );
})

function renderItem({
    entity,
    provided,
    snapshot,
    onRemove,
}: RenderDNDElementProps<TabDesigner>, ac: AccessControl): React.ReactElement<HTMLElement> {
    const {
        key,
        title,
        default: isDefault,
        contentType,
        contentKey,
        icon,
    } = entity

    const history = useHistory();
    const match = useRouteMatch();

    return (<View style={{ height: 40 }} key={`more-${key}`}>
        <Stack alignment="center" distribution="center">
            <Stack.Item fill>
                <UnstyledLink url={`${match.url}/more-item/${entity.key}`}>
                    <Stack alignment="center">
                        <Stack.Item>
                            <IconWrapper>
                                <DynamicIcon value={icon} size={22} />
                            </IconWrapper>
                        </Stack.Item>
                        <Stack.Item fill>
                            <TextStyle variation="strong">{title}</TextStyle>
                            {isDefault && <Badge>default</Badge>}
                        </Stack.Item>
                    </Stack>
                </UnstyledLink>
            </Stack.Item>
            {ac.can('user').updateOwn(Capability.MoreMenu).granted && (
                <Stack.Item>
                    <Button
                        plain
                        onClick={() => history.push(`${match.url}/more-item/${entity.key}`)}
                        icon={<Icon source={EditMinor} />}
                    />
                </Stack.Item>
            )}
            {ac.can('user').deleteOwn(Capability.MoreMenu).granted &&
                <Stack.Item>
                    <Button
                        plain
                        onClick={onRemove}
                        icon={<Icon source={DeleteMinor} />}
                    />
                </Stack.Item>
            }
            {ac.can('user').updateOwn(Capability.MoreMenu).granted &&
                <Stack.Item>
                    <div {...provided.dragHandleProps}>
                        <Button
                            plain
                            icon={<Icon source={DragHandleMinor} />} />
                    </div>
                </Stack.Item>
            }
        </Stack>
    </View>
    )
}