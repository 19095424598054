import { TextField, Button, Card, EmptyState, Form, FormLayout, Heading, Modal, Pagination, ResourceList, Stack, TextContainer, TextStyle } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { ListPointsTransactions_listPointsTransactions, ListPointsTransactions_listPointsTransactions_edges_node } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { ResourceListSkeleton } from "../../../components/Skeleton/ResourceListSkeleton";
import { BuyPointsModal } from "./BuyPointsModal";
import { PointsTransactionItem } from "./PointsTransactionItem";

export const PointsTransactions = React.memo(() => {
    const client = useClient();
    const [results, setResults] = useState<ListPointsTransactions_listPointsTransactions>();
    const [loading, setLoading] = useState<boolean>(false);
    const pageSize = 8;

    const loadNextPage = useCallback(() => {
        setLoading(true);
        client.queryListPointsTransactions({
            first: pageSize,
            after: results?.pageInfo.endCursor,
        })
            .then((result) => {
                console.log('Nex page loaded', result);
                setResults(result.listPointsTransactions);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [client, results]);

    const loadPrevPage = useCallback(() => {
        if (!results?.pageInfo.hasPreviousPage) {
            throw new Error("No previous page");
        }
        setLoading(true);

        client.queryListPointsTransactions({
            last: pageSize,
            before: results.pageInfo.startCursor,
        })
            .then((result) => {
                console.log('Next page loaded', result);
                setResults(result.listPointsTransactions);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [pageSize, results]);

    useEffect(() => {
        loadNextPage();
    }, []);    
    if (loading) {
        return (<ResourceListSkeleton />);
    }

    return (
        <Card>
            <Card.Section>
                <View style={{ flexDirection: 'row' }}>
                    <Stack.Item fill>
                        <Stack vertical>
                            <Stack.Item>
                                <Heading>
                                    <TextStyle variation={'strong'}>
                                        {'Transactions history'}
                                    </TextStyle>
                                </Heading>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <BuyPointsModal />
                    </Stack.Item>
                </View>
            </Card.Section>
            <Card.Section>
                {(results?.edges.length === 0) && <EmptyState
                    heading="No transactions yet"
                    image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png" />}
                {(results?.edges && results?.edges.length > 0) && (
                    <ResourceList
                        items={results?.edges.map((e) => e.node)}
                        renderItem={(i) => {
                            return (
                                <ResourceList.Item
                                    onClick={() => { }}
                                    id={i.id}
                                >
                                    <PointsTransactionItem item={i} />
                                </ResourceList.Item>
                            );
                        }}
                    />
                )}
            </Card.Section>
            {(results?.pageInfo.hasNextPage || results?.pageInfo.hasPreviousPage) && <Card.Section>
                <Stack distribution={"center"}>
                    <Pagination
                        hasPrevious={results?.pageInfo.hasPreviousPage}
                        onPrevious={() => {
                            loadPrevPage()
                        }}
                        hasNext={results?.pageInfo.hasNextPage}
                        onNext={() => {
                            loadNextPage()
                        }}
                    />
                </Stack>
            </Card.Section>}
        </Card>
    );
});