import * as React from 'react';
import { useParams } from 'react-router';
import { useClient } from '../../api/useClient';
import { loadThemeSet, resolveThemeSet, ThemeSet } from './themes/themes';
import { useSaver } from '../utils/useSaver';
import { Button, Card, FooterHelp, Heading, Image, Layout, Link, Modal, Stack, TextStyle } from '@shopify/polaris';
import { ThemeSetChooser } from './ThemeChooser';
import { useCallback, useState } from 'react';
import { KnobFile } from "../../configEditor/knobs/components/KnobFile";
import { View } from "react-native";
import { ThemeSetComponent } from './ThemeSetComponent';
import { useInfoBanner } from '../../components/banners/useInfoBanner';
import { TipKey } from '../../components/banners/useTip';
import { animated, config, useTransition } from '@react-spring/native';
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { useAppBridge } from '@shopify/app-bridge-react';
import { useAccessControl } from '../utils/useAccessControl';
import { ThemeOnboarding } from '../../components/onboarding/ThemeOnboarding';

export const AppThemeComponent = React.memo(function VersionThemeComponent() {
    const params = useParams<{ id: string, appId: string }>();
    const client = useClient();
    const appBridge = useAppBridge();
    const ac = useAccessControl();
    const appConfig = client.useAppConfig({ id: params.id }).app;
    console.log('App config', appConfig, params);
    const initialDesignerData = appConfig.draftConfig.design.designerData;

    const initialThemes = loadThemeSet(initialDesignerData);
    const onSaveCb = useCallback(async (updated) => {
        console.log('updated_', updated);
        console.log('update', resolveThemeSet(updated));
        const result = await client.mutateUpdateApp({
            id: params.id,
            config: {
                design: {
                    designerData: JSON.stringify(updated),
                    colors: {
                        ...resolveThemeSet(updated),
                    },
                },
            },
        });
        console.log('result', result);
    }, []);
    const [themeSet, loading, setThemeSet] = useSaver<ThemeSet>(initialThemes, onSaveCb);
    const [logo, setLogo] = useState<string | null>(appConfig.settings?.logo || null);
    const handleUpdateLogo = useCallback((logo) => {
        if (typeof logo === "string" && logo.length === 0) {
            logo = null;
        }
        client.mutateUpdateApp({
            id: params.id,
            config: {
                logo
            },
        })
            .then((res) => {
                console.log('logo updated', res);
                client.refetchAppConfig({ id: params.id })
                    .then((res) => { console.log('refetch res', res) })
                    .catch((e) => { console.warn('refetch error', e) });
            })
            .catch((err) => { console.warn('logo not updated', err) });
    }, [client, params, logo]);
    const handleIconChange = useCallback((value) => {
        setLogo(value);
        handleUpdateLogo(value);
    }, [setLogo, handleUpdateLogo]);


    const [banner] = useInfoBanner(
        TipKey.ShopLogo,
        'Customize your branding',
        "With Cartmate, you can upload your logo to be used as your shop's icon as a default across the app (additional customizations available in the advanced section of the app settings)"
    );

    const closeBanner = useTransition(banner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    })

    const infoBanner = closeBanner(
        (style, banner) => banner
            && <animated.View style={[{ marginBottom: '2rem' }, style]}>
                <Card>{banner}</Card>
            </animated.View>
    );

    return (
        <Layout sectioned>
            <Card title={'Theme'}>
                <Card.Section>
                    <ThemeSetChooser themeSet={themeSet} onChange={setThemeSet} />
                </Card.Section>
                <Card.Section>
                    <ThemeSetComponent themeSet={themeSet} onChange={setThemeSet} />
                </Card.Section>
                <Card.Section>
                    {infoBanner}
                    <View
                        key={"Icon"}
                        style={{ width: "50%" }}
                    >
                        <Heading>
                            {'Logo'}
                        </Heading>
                        <KnobFile knob={{
                            type: 'file' as const,
                            defaultValue: '',
                            description: '',
                            clearable: true,
                        }}
                            value={logo}
                            innerKey={"AppLogo"}
                            onChange={
                                (knobValue, key: string) => handleIconChange(knobValue)
                            }
                            dropZoneProps={{
                                accept: "image/*"
                            }}
                        />
                    </View>
                </Card.Section>
            </Card>
            <FooterHelp>
                Learn more about{' '}
                <Link onClick={() => {
                    const redirect = ShopifyRedirect.create(appBridge);
                    redirect.dispatch(ShopifyRedirect.Action.APP, '/about/customize');
                }}>
                    Themes and in-app storefront customisation
                </Link>
            </FooterHelp>
            <ThemeOnboarding/>
        </Layout>
    );
});