import React, { useCallback, useEffect, useState } from 'react';
import { useClient } from '../../api/useClient';
import { AccountCreateInput, AccountStatus } from '../../api/spacex.types';
import { RedirectToOriginPath } from '../../routing/RedirectToOriginPath';
import { useForm } from 'react-hook-form';
import { FormContext } from '../../components/Form/FormContext';
import { HowAreYouSelling } from './fragments/HowAreYouSelling';
import { WhatIsYouRevenue } from './fragments/WhatIsYouRevenue';
import { WhichIndustries } from './fragments/WhichIndustries';
import { AppForClient } from './fragments/AppForClient';
import { StyleSheet } from 'react-native'
import { WhatIsYouTeamSize } from './fragments/WhatIsYouTeamSize';
import { Card, Page } from '@shopify/polaris';
import { MixpanelEvent, trackEvent } from '../../utils/mixpanel';
import { CreateAccountLoader } from './fragments/CreateAccountLoader';
import { WhatIsYourReferrer } from './fragments/WhatIsYourReferrer';
import { Redirect } from 'react-router';

export const CreateAccount = React.memo(() => {
    const client = useClient();

    const [account, setAccount] = useState(client.useAccountWrapper().account);
    const [isSetup, setIsSetup] = useState(account.status !== AccountStatus.CREATED);

    const { status, id } = client.useAccountWrapper().account;
    const methods = useForm<AccountCreateInput>({
        defaultValues: {
            industries: undefined,
            sellingStatus: undefined,
            currentMonthlySales: undefined,
            forClient: false,
            referrer: undefined
        },
        mode: 'all'
    });

    const onSubmit = useCallback((data, reward: boolean) => {
        console.log('submit', data);
        client.mutateCreateAccount({
            id: account.id, inputs: data
        }).then((result) => {
            setAccount(result.accountCreate);
        }).catch(e => {
            methods.setError('', e)
        })
    }, [account, client, methods]);

    useEffect(() => {
        if (!isSetup) {
            setIsSetup(true);
            console.log('Start setup account');
            client.mutateSetupAccount({ id: account.id }).then((account) => {
                console.log('Account setup finished', account.setupAccount);
                setIsSetup(false);
                setAccount(account.setupAccount);
            }).catch((e) => {
                setIsSetup(false);
            });
        }
    }, [account, isSetup]);

    console.log('[CreateAccount]', account);

    if (account.status === AccountStatus.CREATED) {
        return <CreateAccountLoader />;
    }
    if (status === AccountStatus.ACTIVE && account.survey) {
        console.log('[CreateAccount]', 'redirecting...');
        return <Redirect to={{ pathname: `/wizard` }} />
    } else {
        return (
            <Page
                title={'Welcome to Cartmate!'}
                subtitle={'Your app is ready! Please answer a few questions so that we can optimize your automatic app setup.'}
            >
                <Card
                    primaryFooterAction={{
                        content: 'Submit',
                        onAction: () => {
                            methods.handleSubmit((data) => {
                                console.log(data);
                                onSubmit(data, true);
                            })();
                            trackEvent(MixpanelEvent.Survey, { action: 'submit' });
                        }
                    }}
                    secondaryFooterActions={[{
                        content: 'Skip',
                        onAction: () => {
                            onSubmit(
                                {
                                    industries: [],
                                    sellingStatus: '3',
                                    currentMonthlySales: '6',
                                    teamSize: '4',
                                    forClient: false,
                                    referrer: undefined
                                },
                                false
                            );
                            trackEvent(MixpanelEvent.Survey, { action: 'skip' });
                        }
                    }]}
                >
                    <FormContext {...methods}>
                        <Card.Section>
                            <HowAreYouSelling />
                        </Card.Section>
                        <Card.Section>
                            <WhichIndustries />
                        </Card.Section>
                        <Card.Section>
                            <WhatIsYouRevenue />
                        </Card.Section>
                        <Card.Section>
                            <WhatIsYouTeamSize />
                        </Card.Section>
                        <Card.Section>
                            <AppForClient />
                        </Card.Section>
                        <Card.Section>
                            <WhatIsYourReferrer />
                        </Card.Section>
                    </FormContext>
                </Card>
            </Page >
        );
    }
})