import { TextStyle, Page, Layout } from "@shopify/polaris";
import { TabDescriptor } from "@shopify/polaris/dist/types/latest/src/components/Tabs/types";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { useClient } from "../../../api/useClient";
import { ProductPublishingComponent } from "../../../components/ProductPublishingComponent";
import { TermsAndConditions } from "../../../components/TermsAndConditions";
import { Synchronize } from "../contents/Synchronize";

export const Generals = React.memo(() => {

    const [product, setProduct] = useState('');

    const handleProductChange = useCallback((value) => setProduct(value), []);

    const productOptions = [
        { label: 'Jewelry', value: 'jewelry' },
        { label: 'Painting', value: 'painting' },
        { label: 'Sports', value: 'sports' },
    ];

    const client = useClient();
    const settings = client.useSettings();


    const [credentials, setCredentials] = React.useState<string>(settings.storeConnections.googlePlay ? settings.storeConnections.googlePlay.credentials : '');
    const [googlePlayConnected, setGooglePlayConnected] = React.useState<boolean>(settings.checkGooglePlayConnection);
    const [dirty, setdirty] = useState(false);

    const [name, setName] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [sender, setSender] = React.useState<string>('');
    const [company, setCompany] = React.useState<string>('');
    const [phone, setPhone] = React.useState<string>('');
    const [adress, setAdress] = React.useState<string>('');
    const [apartments, setApartments] = React.useState<string>('');
    const [city, setCity] = React.useState<string>('');

    const [country, setCountry] = React.useState<string>('');
    const handleCountryChange = useCallback((value) => setCountry(value), []);

    const countryOptions = [
        { label: 'Unnited States', value: 'unitedStates' },
        { label: 'Painting', value: 'painting' },
        { label: 'Sports', value: 'sports' },
    ];

    const [state, setState] = React.useState<string>('');
    const handleStateChange = useCallback((value) => setState(value), []);

    const stateOptions = [
        { label: 'California', value: 'california' },
        { label: 'Painting', value: 'painting' },
        { label: 'Sports', value: 'sports' },
    ];

    const [code, setCode] = React.useState<string>('');

    const [time, setTime] = React.useState<string>('');
    const handleTimeChange = useCallback((value) => setState(value), []);

    const timeOptions = [
        { label: '(GMT +03:00) Moscow, St. Petersburg', value: 'moscow' },
        { label: 'Painting', value: 'painting' },
        { label: 'Sports', value: 'sports' },
    ];

    const [unit, setUnit] = React.useState<string>('');
    const handleUnitChange = useCallback((value) => setUnit(value), []);

    const unitOptions = [
        { label: 'Imperial system', value: 'imperialSystem' },
        { label: 'Metric system', value: 'metricSystem' },
    ];
    const [weight, setWeight] = React.useState<string>('');
    const handleWeightChange = useCallback((value) => setWeight(value), []);

    const weightOptions = [
        { label: 'Pound (lb)', value: 'pound' },
        { label: 'Ounce (oz)', value: 'ounce' },
    ];

    const [prefix, setPrefix] = React.useState<string>('#');
    const [suffix, setSuffix] = React.useState<string>('');

    const [currency, setCurrency] = React.useState<string>('');
    const handleCurrecyChange = useCallback((value) => setCurrency(value), []);

    const currencyOptions = [
        { label: 'US dollars (USD)', value: '(USD)' },
        { label: 'euros', value: '(EUR)' },
    ];
    const [currencyExpanded, setCurrencyExpanded] = React.useState<boolean>(false);
    const [htmlCurrenncy, setHtmlCurrenncy] = React.useState<string>(`{{amount}} ${currencyOptions[0].value}`);
    const [htmlWoCurrenncy, setHtmlWoCurrenncy] = React.useState<string>('${{amount}}');
    const [emailCurrenncy, setEmailCurrenncy] = React.useState<string>(`{{amount}} ${currencyOptions[0].value}`);
    const [emailWoCurrenncy, setEmailWoCurrenncy] = React.useState<string>('${{amount}}');

    const [contactsBaseEmail, setContactsBaseEmail] = React.useState<string>('');
    const [contactsBasePhone, setContactsBasePhone] = React.useState<string>('');
    const [contactsBaseName, setContactsBaseName] = React.useState<string>('');
    const [contactsOrderEmail, setContactsOrderEmail] = React.useState<string>('');
    const [contactsOrderPhone, setContactsOrderPhone] = React.useState<string>('');
    const [contactsOrderName, setContactsOrderName] = React.useState<string>('');

    const [selected, setSelected] = useState<number>(0);
    const tabs: TabDescriptor[] = [
        {
            id: 'base',
            content: (
                <TextStyle variation="strong">{'Base contacts'} </TextStyle>
            ),
            accessibilityLabel: 'Base Contacts',
            panelID: 'base-tab',
        },
        {
            id: 'order',
            content: (
                <TextStyle variation="strong">{'Order contacts'} </TextStyle>
            ),
            accessibilityLabel: 'Order Contacts',
            panelID: 'order-tab'
        },
        {
            id: 'legal',
            content: (
                <TextStyle variation="strong">{'Legal'} </TextStyle>
            ),
            accessibilityLabel: 'legal',
            panelID: 'legal-tab'
        }
    ];

    return (
        <Page title={'General'} breadcrumbs={[{ content: 'Settings', url: '/settings' }]}>
            <form>
                <View style={{ marginBottom: '1.6rem' }}>
                    <Layout>
                        <ProductPublishingComponent />
                        <Layout.AnnotatedSection
                            title={'Synchronization'}
                            description={'To speed up your mobile storefronts, we synchronize the products and collections available for the Cartmate sales channel into our database in almost realtime. If you are stuck with a delay, you can force the synchronization by clicking the \"Force Sync\" button'}
                        >
                            <Synchronize />
                        </Layout.AnnotatedSection>
                        <Layout.AnnotatedSection
                            title="Terms and conditions"
                            description="Our Terms of use and Privacy Policy are shown here."
                        >
                            <TermsAndConditions />
                        </Layout.AnnotatedSection>
                    </Layout>
                </View>
            </form>
        </Page>
    )
});