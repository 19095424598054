import { useAppBridge } from "@shopify/app-bridge-react";
import { CalloutCard } from "@shopify/polaris";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import React from "react";

const exchangeIcon = require('../../../../assets/images/exchange-icon.svg');

export const ExchangeCalloutCard = React.memo(() => {
    const appBridge = useAppBridge();

    return (
        <CalloutCard
            title="Exchange Traffic with your fellow Shopifiers with Cartmate community"
            illustration={exchangeIcon}
            primaryAction={{
                content: 'Exchange',
                onAction: () => {
                    const redirect = ShopifyRedirect.create(appBridge);
                    redirect.dispatch(ShopifyRedirect.Action.APP, '/promote');
                }
            }}
        >
            <p>Customize your profile, find friendly stores and exchange traffic</p>
        </CalloutCard>
    );
});