import { QueryCacheProvider } from '@openland/spacex';
import * as React from 'react';
import { SkeletonSuspense, SkeletonType } from './Skeleton/SkeletonSuspense';
import { Suspense } from './Suspense';

export function wrapPage(Component: any) {
    return React.memo(props => (
        <div style={{ position: 'relative', height: '100%', display: 'flex' }}>
            <QueryCacheProvider>
                <Suspense debug={false}>
                    <div style={{ position: 'relative', width: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
                        <Component {...props} />
                    </div>
                </Suspense>
            </QueryCacheProvider>
        </div>
    ));
}

export function wrapPageWithSkeleton(Component: any) {
    return React.memo(props => (
        <div style={{ position: 'relative', height: '100%', display: 'flex' }}>
            <QueryCacheProvider>
                <SkeletonSuspense type={SkeletonType.Page}>
                    <div style={{ position: 'relative', width: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
                        <Component {...props} />
                    </div>
                </SkeletonSuspense>
            </QueryCacheProvider>
        </div>
    ));
}