import { Button, Checkbox, Collapsible, Popover, RangeSlider, Stack, TextField, TextStyle } from "@shopify/polaris"
import React, { useCallback, useEffect, useState } from "react"
import { ColorResult, SketchPicker } from "react-color"
import { Knob } from "../Knob"

export const KnobBorder = React.memo<Knob.KnobComponentProps<Knob.KnobBorderDescriptor>>(
    ({ knob, value: initValue, onChange, innerKey }) => {
        const { description, min, max, step, disableAlpha } = knob
        const [border, setBorder] = useState((initValue.border || 0));
        const [borderColor, setBorderColor] = useState((initValue.color));
        const [showSettings, setShowSettings] = useState<boolean>(initValue.showBorder);

        const handleChange = useCallback((newChecked) => setShowSettings(newChecked), []);

        const [popoverActive, setPopoverActive] = useState(false);

        const togglePopoverActive = useCallback(
            () => setPopoverActive((popoverActive) => !popoverActive),
            [],
        );

        const onChangeCb = useCallback((val: ColorResult) => {

            if (disableAlpha) {
                setBorderColor(val.hex)
            } else {
                if (val.rgb.a) {
                    let alphaHex = Math.round(val.rgb.a * 255).toString(16)
                    if (alphaHex.length < 2) alphaHex = '0' + alphaHex;
                    setBorderColor(val.hex + `${alphaHex}`)
                } else {
                    setBorderColor(val.hex + '00')
                }
            }
        }, [])


        // useEffect(() => {
        //     if (value !== initValue) {
        //         onChange(value, innerKey)
        //     }
        // }, [value, knob, innerKey])

        useEffect(() => {
            const newValue = {
                border: border,
                color: borderColor,
                showBorder: showSettings
            }
            if (newValue !== initValue) {
                onChange(newValue, innerKey)
            }
        }, [knob, innerKey, border, borderColor, showSettings]);

        const activator = (
            <Checkbox
                label="Enable border"
                checked={showSettings}
                onChange={handleChange}
            />
        );

        const colorButton = (
            <button
                onClick={togglePopoverActive}
                // disabled={showCaption !== undefined && !checked}
                style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    width: '36px',
                    height: '36px',
                    outline: 'none',
                    cursor: 'pointer',
                    marginTop: '-2px',
                    border: '1px solid rgba(186, 191, 195, 1)',
                    borderRadius: '0.4rem',
                    backgroundColor: '#fff'
                }}>
                <div style={{
                    width: '100px',
                    height: '16px',
                    border: '1px solid #000',
                    backgroundColor: borderColor
                }}
                />
            </button>
        );

        return (
            <>
                {activator}
                <Collapsible
                    open={showSettings}
                    id="border-collapsible"
                    transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                    expandOnPrint
                >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '0.4rem'
                    }}>
                        <div>
                            <Popover
                                active={popoverActive}
                                activator={colorButton}
                                onClose={togglePopoverActive}
                            >
                                <SketchPicker width={'200px'} color={borderColor} onChange={onChangeCb} disableAlpha={disableAlpha} />
                            </Popover>
                        </div>
                        <div style={{
                            flexGrow: 1,
                            marginLeft: '0.4rem'
                        }}>
                            <TextField
                                type={'number'}
                                min={min}
                                max={max}
                                step={1}
                                label={'border width'}
                                value={border.toString()}
                                onChange={(value) => setBorder(Number(value))}
                            />
                            <div style={{
                                marginTop: '1rem'
                            }}>
                                <RangeSlider
                                    label={''}
                                    value={border}
                                    min={min}
                                    max={max}
                                    step={1}
                                    onChange={(value) => setBorder((typeof value === 'number') ? value : 0)}
                                />
                            </div>
                        </div>
                    </div>
                </Collapsible>
            </>
        )
    })