import React from 'react'
import { View, ViewProps } from 'react-native';
import { Loader } from '../../Loader';
import { IconPickerStyle } from './commonViewStyle';
import { StyleSheet } from 'react-native'
import { animated, useSpring, to, config } from '@react-spring/native'
import { FormLayout, Labelled, LabelledProps, Popover, Stack } from '@shopify/polaris'

const style = StyleSheet.create({
    wrapper: {position: 'relative'},
    loadingBody: {justifyContent: 'center', position: 'absolute', backgroundColor: 'white'},
})

export const LoadingIconsView = React.memo<React.PropsWithChildren<{ loading: boolean }>>(({children, loading}) => {
    const enter = useSpring({from: {val: 1}, to: {val: loading ? 1 : 0}})
    const display = to(enter.val, opacity => (opacity === 0 ? 'none' : undefined))

    return (
        <Popover.Section>
            {children}
        </Popover.Section>
    )
})