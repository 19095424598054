import { config, useTransition } from "@react-spring/core";
import { animated } from "@react-spring/native";
import React from "react";
import { useHistory, useRouteMatch } from "react-router";
import { CalloutCard, Card } from "@shopify/polaris";
import { useInfoBanner } from "../../../../components/banners/useInfoBanner";
import { TipKey } from "../../../../components/banners/useTip";

const exchangeIcon = require('../../../../assets/images/exchange-icon.svg');
const exchangeDescription = 'Other stores like you can put up products and collections for sale in friendly mobile in-app storefronts. '
    + 'List your products or collections in friendly Cartmate Stores. '
    + 'You can choose an item (product, collection or your store\'s profile card), select indusries it\'s related to and a visibility time span.';


export const AdsCalloutCard = React.memo(() => {
    const history = useHistory<{ from?: Location }>();
    const match = useRouteMatch();

    const [exchangeBanner] = useInfoBanner(
        TipKey.TrafficExchangeLisingInfo,
        'Create traffic exchange items',
        'You can submit your products or collections for sale by exchanging positions with other Shopifiers from the Cartmate Traffic Exchange Program'
    );

    const closeExchangeBanner = useTransition(exchangeBanner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    });

    const exchangeBannerView = closeExchangeBanner(
        (style, banner) => banner
            && <animated.View style={[{ marginBottom: '2rem' }, style]}>
                <Card>{banner}</Card>
            </animated.View>
    );

    return (
        <CalloutCard
            title="Exchange Traffic"
            illustration={exchangeIcon}
            primaryAction={{
                content: 'Exchange',
                onAction: () => {
                    if (__DEV__) history.push(`${match.url}/ads`);
                }
            }}
        >
            {exchangeBannerView}
            <p>{exchangeDescription}</p>
        </CalloutCard>
    )
})