import { Card, Page } from "@shopify/polaris";
import React, { useCallback } from "react";
import { CommunityStores } from "../components/CommunityStores";
import { FriendlyStores } from "../components/FriendlyStores";

export const TrafficExchangeCommunity = React.memo(() => {
    const onNewFriend = useCallback(
        () => {
            
        },
        [],
    );
    
    return (
        <Page title={'Community'} breadcrumbs={[{ content: 'Promote', url: '/promote' }]}>
            <CommunityStores onNewFriend={onNewFriend} />
            <FriendlyStores/>
        </Page>
    );
});