import { Button, FormLayout, Icon, Labelled, Select, Stack } from "@shopify/polaris";
import { DeleteMinor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { Knob } from "../Knob";
import { KnobCollectionPicker } from "./KnobCollectionPicker";
import { KnobPagePicker } from "./KnobPagePicker";
import { KnobProductPicker } from "./KnobProductPicker";


enum NavigationType {
    Product = 'product',
    Collection = 'products',
    Page = 'content'
};
type KnobValue = {
    navType: NavigationType,
    value: Knob.PickedProduct | Knob.PickedCollection
}

const urlRegexp = /^\/(\w+)(?:\/(.+))?$/;
const options = [
    { label: 'Product', value: NavigationType.Product },
    { label: 'Collection', value: NavigationType.Collection },
    { label: 'Page', value: NavigationType.Page },
];

export const KnobNavigationPicker = React.memo<Knob.KnobComponentProps<Knob.KnobNavigationPickerDescriptor>>((
    { knob, innerKey, value: initialValue, onChange, disabled }) => {
    const [value, setValue] = useState<Knob.PickedNavigation>();
    const [knobValue, setKnobValue] = useState<KnobValue>({ navType: NavigationType.Product, value: '' });

    const parseValue = useCallback((value: string): KnobValue => {
        const match = value.match(urlRegexp);
        // console.log('[KnobNavigationPicker]: Match: ', match);
        let result: KnobValue = { navType: NavigationType.Product, value: '' };
        if (match && match.length === 3) {
            switch (match[1]) {
                case NavigationType.Product:
                    result.value = match[2] || '';
                    break;
                case NavigationType.Collection:
                    result.navType = NavigationType.Collection;
                    result.value = match[2] || '';
                    break;
                case NavigationType.Page:
                    result.navType = NavigationType.Page;
                    result.value = match[2] || '';
                    break;
            }
        }
        // console.log('[KnobNavigationPicker]: Parsed: ', result)
        return result;
    }, []);

    const knobToValue = useCallback((kv: KnobValue) => {
        if (!kv.value) {
            // console.log(`[KnobNavigationPicker]: Empty value ${kv.navType}`);
        }
        switch (kv.navType) {
            case NavigationType.Collection:
                return kv.value ? `/${NavigationType.Collection}/${kv.value}` : '';
            case NavigationType.Product:
                return kv.value ? `/${NavigationType.Product}/${kv.value}` : '';
            case NavigationType.Page:
                return kv.value ? `/${NavigationType.Page}/${kv.value}` : '';
            default:
                throw new Error(`[KnobNavigationPicker]: Invalid navigation type: ${kv.navType}`);
        }
    }, []);


    const handleUpdateValue = useCallback((newState: KnobValue) => {
        console.log('[KnobNavigationPicker], handleUpdateValue', newState);
        const newValue = knobToValue(newState);
        setValue(newValue);
        onChange(newValue, innerKey);
    }, [innerKey, onChange]);

    const handleKnobValueChange = useCallback((field: keyof KnobValue, newValue) => {
        setKnobValue((prevState) => {
            let newState = { ...prevState, [field]: newValue };
            if (field === "navType" && prevState.navType !== newValue) {
                // console.log('[KnobNavigationPicker]: Nav type change', prevState.navType, newValue);
                newState.value = '';
            }
            if (field === "value" && prevState.value !== newValue) {
                handleUpdateValue(newState);
            }
            // console.log('[KnobNavigationPicker]: New state: ', newState)
            return newState;
        });
    }, [handleUpdateValue]);

    const handleClear = useCallback(() => {
        handleKnobValueChange("value", '');
    }, [handleKnobValueChange]);

    const handlePickCollection = useCallback((picked: Knob.PickedCollection, key: string) => {
        // console.log('[KnobNavigationPicker]: Picked col', picked, knobValue);
        if (knobValue.value !== picked) {
            handleKnobValueChange("value", picked);
        }
    }, [knobValue, handleKnobValueChange]);

    const handlePickPage = useCallback((picked: Knob.PickedPage, key: string) => {
        if (knobValue.value !== picked) {
            handleKnobValueChange("value", picked);
        }
    }, [knobValue, handleKnobValueChange]);

    const handlePickProduct = useCallback((picked: Knob.PickedProduct, key: string) => {
        // console.log('[KnobNavigationPicker]: Picked product', picked, knobValue);
        if (knobValue.value !== picked) {
            handleKnobValueChange("value", picked);
        }
    }, [knobValue, handleKnobValueChange]);

    useEffect(() => {
        if (initialValue && initialValue !== value) {
            // console.log('[KnobNavigationPicker]: Init value chage: ', initialValue)
            setKnobValue(parseValue(initialValue));
            setValue(initialValue);
        }
    }, [initialValue, handleKnobValueChange]);

    // console.log('KNOB', knobValue);

    return (
        <FormLayout key={innerKey}>
            <Labelled id={`navigation-${innerKey}`} label={knob.description}>
                <Stack alignment={'center'} distribution={'center'}>
                    <Stack.Item>
                        <Select
                            label={'Type'}
                            options={options}
                            onChange={(value) => { handleKnobValueChange("navType", value); }}
                            value={knobValue.navType}
                            disabled={disabled || false}
                        />
                    </Stack.Item>
                    <Stack.Item fill>
                        {knobValue.navType === NavigationType.Product &&
                            <View>
                                <KnobProductPicker
                                    knob={{ type: 'product_picker' as const, description: 'Select a product' }}
                                    onChange={handlePickProduct}
                                    innerKey={`ProductKnob-${innerKey}`}
                                    value={knobValue.navType === NavigationType.Product ? knobValue.value as Knob.PickedProduct : ''}
                                    disabled={disabled || false}
                                />
                            </View>
                        }
                        {knobValue.navType === NavigationType.Collection &&
                            <View>
                                <KnobCollectionPicker
                                    knob={{ type: 'collection_picker' as const, description: 'Select a collection' }}
                                    onChange={handlePickCollection}
                                    innerKey={`CollectionKnob-${innerKey}`}
                                    value={knobValue.navType === NavigationType.Collection ? knobValue.value as Knob.PickedCollection : ''}
                                    disabled={disabled || false}
                                />
                            </View>
                        }
                        {knobValue.navType === NavigationType.Page &&
                            <View>
                                <KnobPagePicker
                                    knob={{ type: 'page_picker' as const, description: 'Select a collection' }}
                                    onChange={handlePickPage}
                                    innerKey={`PageKnob-${innerKey}`}
                                    value={knobValue.navType === NavigationType.Page ? knobValue.value as Knob.PickedPage : ''}
                                    disabled={disabled || false}
                                />
                            </View>
                        }
                    </Stack.Item>
                    <Stack.Item>
                        {(knob.clerable && knobValue.value) && (
                            <View style={{
                                flexGrow: 1,
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Button
                                    plain
                                    destructive
                                    onClick={handleClear}
                                    icon={<Icon source={DeleteMinor} />}
                                >
                                    {'Clear'}
                                </Button>
                            </View>
                        )}
                    </Stack.Item>
                </Stack>
            </Labelled>
        </FormLayout>
    );
})