import React from 'react';
import { useEffect, useState } from "react";

const iosLock = require('../assets/images/Fill.svg');
const androidLock = require('../assets/images/lock.svg');

function Clock(props: { device: string }) {

    const [date, setDate] = useState(new Date());
    let styledTime, styledDate, styledLock

    function tick() {
        setDate(new Date());
    }

    useEffect(() => {
        let timerID = setInterval(() => tick(), 1000);

        return function cleanup() {
            clearInterval(timerID);
        };
    });
    if (props.device === 'ios') {
        styledTime = {
            fontSize: '70px',
            fontWeight: 200,
            lineHeight: '70px',
            marginBottom: '5px',
            paddingTop: '70px',
        }
        styledDate = {
            fontSize: '17px',
            lineHeight: '20px',
            marginBottom: '25px'
        }
        styledLock = {
            width: '23px',
            height: '34px',
            backgroundImage: `url(${iosLock})`,
            backgroundSize: 'cover',
            margin: '0 auto 25px'
        }
    } else {
        styledTime = {
            fontSize: '65px',
            fontWeight: 100,
            lineHeight: '65px',
            marginBottom: '5px',
            paddingTop: '85px',
            letterSpacing: '2.03px'
        }
        styledDate = {
            fontSize: '17px',
            lineHeight: '17px',
            fontWeight: 300,
            marginBottom: '25px'
        }
        styledLock = {
            backgroundImage: `url(${androidLock})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '25px',
            height: '30px',
            margin: '0 auto 45px'
        }
    }
    return <>
        <div style={styledTime}>
            <div style={styledLock}></div>
            {date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
        </div>
        <div style={styledDate}>
            {date.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}
        </div>
    </>
}
export default Clock