import React, { useMemo } from 'react'
import { useClient } from '../../../api/useClient';
import { TabDesigner } from '../../../components/types';
import { useTabPhonePreview } from './useTabPhonePreview';
import { PhonePreviews, PhonePreviewsAccent } from './PhonePreviewContext';

export function UseAppPhonePreview(applicationId: string, accent: PhonePreviewsAccent) {
    const client = useClient()
    const tabs = client.useAppTabsConfig({id: applicationId}).app.draftConfig.tabsDesigner
    const defaultTab: TabDesigner | undefined = useMemo(() => tabs.find(tab => tab.default), [tabs])
    useTabPhonePreview(defaultTab, accent, PhonePreviews.APP_VIEW)
}
