import { FormLayout, Labelled, TextField } from "@shopify/polaris"
import React, { useEffect, useState } from "react"
import { useClient } from "../../../api/useClient"
import { StyledSelect, StyledSelectRef } from "../../../components/StyledSelect/StyledSelect"
import { mapCollection } from "../helpers/mapCollection"
import { Knob } from "../Knob"

export const KnobCollection = React.memo<Knob.KnobComponentProps<Knob.KnobCollectionDescriptor>>(({ knob, value: initValue, onChange, innerKey }) => {
    const { description } = knob;
    const client = useClient();
    const ref = React.createRef<StyledSelectRef>();
    const collections = client.useCollections();
    const options: any = collections
        .collections
        .map((collection) => {
            if (collection) {
                return {
                    value: mapCollection(collection),
                    label: collection?.title
                }
            }
        }).filter((item) => item);

    return <FormLayout>
        <Labelled id={`collection-${innerKey}`} label={description}>
            <div style={{ width: '100%' }}>
                <StyledSelect
                    ref={ref}
                    options={options}
                    isSearchable={true}
                    onChange={(item) => {
                        onChange(item?.value, innerKey);
                    }}
                    value={
                        initValue
                            ? {
                                value: { id: initValue?.id, title: initValue?.title, image: initValue?.image },
                                label: initValue?.title
                            }
                            : undefined
                    }
                />
            </div>
        </Labelled>
    </FormLayout>
});