import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from "react-router";
import deepMerge from "deepmerge";
import { useClient } from "./../../api/useClient";
import { KnobFile } from '../../configEditor/knobs/components/KnobFile';
import { AccountPricingStatus, AccountStatus, AppCardPublishedStatus, AppCardStatus } from "./../../api/spacex.types";
import {
    Banner,
    Button,
    Card,
    Form,
    FormLayout,
    Stack,
    TextField,
    Collapsible,
    InlineError,
    TextStyle
} from '@shopify/polaris';
import { View } from "react-native";
import { AppCard, AppCardPublished } from "../../components/types";
import { useAppCard } from "../utils/useAppCard";
import { useAccount } from "../utils/useAccount";
import { TrialModal } from "../modals/TrialModal";
import { WhichIndustries } from '../../routes/CreateAccount/fragments/WhichIndustries';
import { FormContext } from '../../components/Form/FormContext';
import { useForm } from 'react-hook-form';
import { PromoteFormError } from '../../routes/Promote/utils';

export const AppCardComponent = React.memo((
    { onCardChange }: { onCardChange: (card: AppCard) => void }
) => {
    const client = useClient();
    const account = useAccount();
    const { id: appId } = useParams<{ id: string }>();
    const app = client.useGetAppSettings({ id: appId }).app;
    const [initialAppCard, initialPublishedCard] = useAppCard(appId);
    const [dirty, setDirty] = useState<boolean>(false);
    const [appCard, setAppCard] = useState<AppCard>(initialAppCard);
    const [publishedAppCard, setPublishedAppCard] = useState<AppCardPublished | null>(initialPublishedCard);

    const isDisabled =
        appCard?.status === AppCardStatus.ON_REVIEW ||
        account.status !== AccountStatus.ACTIVE ||
        account.pricing.status !== AccountPricingStatus.ACTIVE;

    const [title, setTitle] = React.useState<string>(appCard.title || '');
    const [description, setDescription] = React.useState<string>(appCard.description || '');
    const [icon, setIcon] = React.useState<string>(appCard.icon?.src || app.settings?.logo || '');
    const [splash, setSplash] = React.useState<string>(appCard.splash?.src || '');
    const [background, setBackground] = React.useState<string>(appCard.background?.src || '');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [errors, setErrors] = useState<PromoteFormError[]>([]);

    const [advancedOpen, setAdvanceOpen] = useState<boolean>(false);

    const markDirty = useCallback(() => {
        setDirty(true);
        setErrors([]);
    }, []);

    const defaultIndustires = appCard?.data?.industries.map((i) => i.id);
    const industiriesMethods = useForm<{ industries: string[] }>({
        defaultValues: {
            industries: defaultIndustires || [],
        },
    });
    const industriesValue = industiriesMethods.watch().industries;
    useEffect(() => {
        console.log('industriesValue', industriesValue);
        if (industriesValue !== defaultIndustires) {
            markDirty();
        }
    }, [industriesValue]);

    const handleTitleChange = React.useCallback((value) => { markDirty(); setTitle(value) }, []);
    const handleDescriptionChange = React.useCallback((value) => { markDirty(); setDescription(value) }, []);
    const handleIconChange = React.useCallback((value) => { markDirty(); setIcon(value) }, []);
    const handleBackgroundChange = React.useCallback((value) => { markDirty(); setBackground(value) }, []);

    const checkInputs = useCallback(
        () => {
            console.log('[AppCardComponent] checkInputs', appCard);
            const errors: PromoteFormError[] = [];
            if (!appCard.title || appCard.title.length === 0) errors.push(PromoteFormError.Title);
            if (!appCard.description || appCard.description.length === 0) errors.push(PromoteFormError.Description);
            if (!appCard.icon?.src || appCard.icon.src.length === 0) errors.push(PromoteFormError.Icon);
            if (
                !industiriesMethods.getValues().industries
                || industiriesMethods.getValues().industries.length === 0
            ) errors.push(PromoteFormError.Industires)
            return errors;
        },
        [appCard, industiriesMethods],
    );

    const handleSaveAction = React.useCallback(() => {
        const errors = checkInputs();
        if (errors.length > 0) {
            setErrors(errors);
            return;
        }
        setLoading(true);
        const industries = industiriesMethods.getValues().industries;
        let inputData = { title, description, icon, splash, background, industries };
        console.log('Input data', inputData);
        client.mutateUpdateAppCard({
            appId: appId,
            input: inputData
        }).then((result) => {
            console.log('[AppCardComponent] mutateUpdateAppCard', result);
            setAppCard(result.updateAppCard);
            onCardChange(result.updateAppCard);
            setDirty(false);
            setLoading(false);
        }).catch((e) => {
            console.error(e);
            setLoading(false);
        })
    }, [title, description, icon, splash, background, industiriesMethods]);

    const handleSubmitAction = React.useCallback(() => {
        const errors = checkInputs();
        if (errors.length > 0) {
            setErrors(errors);
            return;
        }
        setLoading(true);
        console.log('Input data', appId);
        client.mutateSubmitAppCard({
            appId: appId
        }).then((result) => {
            onCardChange(result.submitAppCard);
            setAppCard(result.submitAppCard);
            setLoading(false);
        }).catch((e) => {
            console.error(e);
            setLoading(false);
        })
    }, [appId, checkInputs]);

    const handleDraftAction = React.useCallback(() => {
        const errors = checkInputs();
        if (errors.length > 0) {
            setErrors(errors);
            return;
        }
        setLoading(true);
        console.log('Draft', appId);
        client.mutateDraftAppCard({
            appId: appId
        }).then((result) => {
            console.log('Draft result', result);
            setAppCard(result.draftAppCard);
            onCardChange(result.draftAppCard);
            setDirty(false);
            setLoading(false);
        }).catch((e) => {
            console.error(e);
            setLoading(false);
        })
    }, [appId]);

    const handleFormSubmit = useCallback((_event) => {
        console.log('FormSubmit', _event);
        handleSaveAction();
    }, [title, description, icon, splash, background]);

    const handleRemove = useCallback(
        () => {
            client.mutateDeactivateAppCard({ appId: appId })
                .then(() => {
                    client.refetchGetAppCard({ appId: appId })
                });
        },
        [appId],
    );

    useEffect(() => {
        let tempCard = appCard;
        tempCard.title = title;
        tempCard.description = description;
        tempCard.icon = { src: icon };
        tempCard.background = { src: background };

        onCardChange(deepMerge(appCard, tempCard));

    }, [title, description, icon, splash, background, appCard]);

    useEffect(() => {
        setAppCard(initialAppCard);
        setPublishedAppCard(initialPublishedCard);
    }, [initialAppCard, initialPublishedCard, setAppCard, setPublishedAppCard]);

    let moderationFailedText = 'The reason for the refusal to publish'

    return (
        <Card title={'Profile'}>
            <Card.Section>
                <Form onSubmit={handleFormSubmit} preventDefault>
                    <FormLayout>
                        {appCard.status === AppCardStatus.ON_REVIEW &&
                            <Banner
                                status={"warning"}
                                title={"Your request for moderation is in progress, please wait"}
                                action={{ content: "Cancel", onAction: handleDraftAction }}>
                                <p>Generally requests are reviewed within <TextStyle variation={'strong'}>1-5 business days.</TextStyle></p>
                                <p>If you want to change description press "Cancel" button.</p>
                            </Banner>
                        }
                        {(appCard.status !== AppCardStatus.ON_REVIEW
                            && publishedAppCard?.status === AppCardPublishedStatus.ACTIVE)
                            &&
                            <Banner title='You’re qualified!' status='info'>
                                <p>Your shop is now listed in the Cartmate Traffic Exchange catalog. Congratulations!</p>
                            </Banner>}
                        {(appCard.status !== AppCardStatus.ON_REVIEW
                            && publishedAppCard?.status === AppCardPublishedStatus.INACTIVE) &&
                            <Banner title='Moderation failed' status='critical'>
                                <p>{moderationFailedText}</p>
                            </Banner>}
                        <TextField
                            error={
                                errors.findIndex((e) => e === PromoteFormError.Title) !== -1
                                    ? PromoteFormError.Title
                                    : undefined
                            }
                            label={"Title"}
                            value={title}
                            onChange={handleTitleChange}
                            disabled={isDisabled}
                        />
                        <TextField
                            error={
                                errors.findIndex((e) => e === PromoteFormError.Description) !== -1
                                    ? PromoteFormError.Description
                                    : undefined
                            }
                            label={"Description"}
                            value={description}
                            onChange={handleDescriptionChange}
                            multiline={6}
                            disabled={isDisabled}
                            autoFocus
                        />
                        <Stack>
                            <View key={'Logo-input'} style={{ marginBottom: 10, }}>
                                <KnobFile
                                    disabled={isDisabled}
                                    knob={{
                                        type: 'file' as const,
                                        defaultValue: '',
                                        description: 'Logo',
                                        clearable: true,
                                    }}
                                    value={icon}
                                    onChange={(knobValue: any) => handleIconChange(knobValue)}
                                    innerKey={'IconMall'}
                                />
                            </View>
                            <View key={"Background-input"}>
                                <KnobFile knob={{
                                    type: 'file' as const,
                                    defaultValue: "",
                                    description: 'Cover',
                                    clearable: true,
                                }}
                                    value={background}
                                    innerKey={"BackgroundIcon"}
                                    onChange={
                                        (knobValue) => handleBackgroundChange(knobValue)
                                    }
                                    dropZoneProps={{
                                        accept: "image/*"
                                    }}
                                />
                            </View>
                        </Stack>
                        <FormContext {...industiriesMethods}>
                            <WhichIndustries
                                errorMessage={
                                    errors.findIndex((e) => e === PromoteFormError.Industires) !== -1
                                        ? PromoteFormError.Industires
                                        : undefined
                                }
                                hideCaption
                                label={'Industries'}
                            />
                        </FormContext>
                        {
                            errors.findIndex((e) => e === PromoteFormError.Icon) !== -1
                            && <InlineError message={PromoteFormError.Icon} fieldID="Icon" />
                        }
                        <Collapsible
                            id={"promote-advanced"}
                            open={advancedOpen}
                            transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
                            expandOnPrint
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "stretch",
                                    width: "100%"
                                }}
                            >
                                <div
                                    style={{
                                        boxSizing: "border-box",
                                        width: "50%"
                                    }}
                                >
                                </div>
                            </div>
                        </Collapsible>
                        <Stack distribution={"trailing"}>
                            {publishedAppCard?.status === AppCardPublishedStatus.ACTIVE
                                && <Button
                                    onClick={handleRemove}
                                    destructive
                                >Remove from CEC</Button>}
                            {appCard.status === AppCardStatus.ON_REVIEW
                                && <Button onClick={handleDraftAction}>Change</Button>}
                            {appCard.status !== AppCardStatus.ON_REVIEW
                                && <Button
                                    disabled={dirty}
                                    onClick={handleSubmitAction}
                                >Submit to review</Button>}
                            {appCard.status !== AppCardStatus.ON_REVIEW
                                && <Button
                                    disabled={!dirty}
                                    submit
                                    primary
                                >Save</Button>}
                        </Stack>
                    </FormLayout>
                </Form>
            </Card.Section>
            <TrialModal />
        </Card>

    );

});