import React, { useCallback, useMemo, useState } from 'react'
import { Button, ButtonGroup, FooterHelp, Layout, Link, Page } from '@shopify/polaris';
import { Prompt, Redirect, useParams } from 'react-router';
import { useClient } from '../../api/useClient';
import { useManualSaver } from '../utils/useSaver';
import { EditMenuItem, useVerifyEditFormState } from './EditMenuItem';
import { setNewValueArray } from '../../configEditor/elementsPickerComponents/ElementsListUtils';
import { TabDesigner } from '../../components/types';
import { useTabPhonePreview } from '../theme/preview/useTabPhonePreview';
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { useAppBridge } from '@shopify/app-bridge-react';
import { PhonePreviews, PhonePreviewsAccent } from '../theme/preview/PhonePreviewContext';
import { useAccount } from '../utils/useAccount';
import { useAccessControl } from '../utils/useAccessControl';

function getKey(key: string) {
    return (tab: TabDesigner) => tab.key === key
}

export const AppPageDetail = React.memo(() => {
    const { id, key } = useParams<{ id: string, key: string }>();
    const client = useClient();
    const appBridge = useAppBridge();
    const app = client.useApp({ id }).app;
    const tabs = client.useAppTabsConfig({ id }).app.draftConfig.tabsDesigner.map(i => {
        delete i.__typename
        delete i.icon?.__typename
        return i
    });

    const account = useAccount();
    const ac = useAccessControl();
    console.log('AC',
        ac.can('user').readOwn('mainMenu').granted,
        ac.can('user').createOwn('mainMenu').granted,
        ac.can('user').updateOwn('mainMenu').granted,
        ac.can('user').deleteOwn('mainMenu').granted,
    );

    const [discard, setDiscard] = useState(false)
    const discardChanges = useCallback(() => setDiscard(true), [])

    const returnPath = useMemo(() => `/apps/${id}/menu`, [id]);

    const initTabData = useMemo(() => tabs.find(getKey(key)), [tabs])

    if (!initTabData) {
        return <Redirect to={returnPath} />
    }

    const onSave = useCallback((tab: TabDesigner) => {
        return client.mutateUpdateApp({
            id,
            config: {
                tabs: setNewValueArray(tabs, tabs.findIndex(getKey(key)), tab),
            },
        }).then(() => {
            discardChanges()
        }).catch(e => {
            console.error(e, tab)
        })
    }, [client, id]);

    const [tab, loading, onTabChange, doSave] = useManualSaver<TabDesigner>(initTabData, onSave);

    console.log('[AppPageDetail]', initTabData, tab);

    useTabPhonePreview(tab, PhonePreviewsAccent.CONTENT, PhonePreviews.TAB_VIEW);

    const [errorState, verify] = useVerifyEditFormState(tab);

    const verifyAndSave = useCallback(() => {
        if (verify()) {
            doSave()
        }
    }, [verify, doSave])

    if (discard) {
        return <Redirect to={`/apps/${id}/menu`} />
    }

    const haveChanges = initTabData !== tab

    return (
        <Page
            title={tab.title || 'Tab'}
            fullWidth
            breadcrumbs={[{ content: 'Apps', url: '/apps' }, { content: app.name, url: returnPath }]}
            primaryAction={
                <ButtonGroup>
                    {haveChanges && <Button
                        destructive
                        onClick={discardChanges}
                        plain>
                        Discard changes
                    </Button>}
                    <Button
                        disabled={initTabData === tab}
                        loading={loading}
                        onClick={verifyAndSave}
                        primary>
                        Save
                    </Button>
                </ButtonGroup>
            }>
            <Prompt message={'All changes will be lost'} when={haveChanges} />
            <EditMenuItem item={tab} onChange={onTabChange} onSubmit={verifyAndSave} errorState={errorState} />
            {/* <PhonePreviewLayout applicationId={id}>
            </PhonePreviewLayout> */}
            <FooterHelp>
                Learn more about{' '}
                <Link onClick={() => {
                    const redirect = ShopifyRedirect.create(appBridge);
                    redirect.dispatch(ShopifyRedirect.Action.APP, '/about/customize');
                }}>
                    menu items and navigation menu
                </Link>
            </FooterHelp>
        </Page>
    )
})