import { Button, Card, Layout, Page, PageActions } from "@shopify/polaris";
import React from "react";
import { View } from "react-native";
import { TextEditor } from "../components/TextEditor";

export const Legal = React.memo(() => {
    return (
        <Page title={'Legal'} breadcrumbs={[{ content: 'Settings', url: '/Settings' }]}>
            <View style={{ marginBottom: '2rem' }}>
                <Layout>
                    <Layout.AnnotatedSection title={'Legal pages'} description={'You can create your own legal pages for Cartmate, different from the type of documents, created for tour store. You also can use templates of legal pages. These templates aren’t legal advice and need to be customized for your app.'}>
                        <form>
                            <Card sectioned title={'Refund policy'}>
                                <View style={{ marginBottom: '1.6rem' }}>
                                    <TextEditor id={"refund"} />
                                </View>
                                <Button>Create from template</Button>
                            </Card>
                            <Card sectioned title={'Privacy policy'}>
                                <View style={{ marginBottom: '1.6rem' }}>
                                    <TextEditor id={"privacy"} />
                                </View>
                                <Button>Create from template</Button>
                            </Card>
                            <Card sectioned title={'Terms of service'}>
                                <View style={{ marginBottom: '1.6rem' }}>
                                    <TextEditor id={"terms"} />
                                </View>
                                <Button>Create from template</Button>
                            </Card>
                            <Card sectioned title={'Shipping policy'}>
                                <View style={{ marginBottom: '1.6rem' }}>
                                    <TextEditor id={"policy"} />
                                </View>
                                <Button>Create from template</Button>
                            </Card>
                        </form>
                    </Layout.AnnotatedSection>
                </Layout>
            </View>
            <PageActions
                primaryAction={{
                    content: 'Save'
                }}
            />
        </Page>
    )
});