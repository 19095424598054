import { Labelled, Select } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { Knob } from "../Knob";
import { ResizeMode } from "../types";

export const KnobResizeMode = React.memo<Knob.KnobComponentProps<Knob.KnobResizeModeDescriptor>>((
    { knob, value: initValue, onChange, innerKey }
) => {
    const { description } = knob
    const [resizeMode, setresizeMode] = useState(initValue);

    useEffect(() => {
        if (resizeMode !== initValue) {
            onChange(resizeMode, innerKey)
        }

    }, [resizeMode, knob, innerKey])

    return (
        <Labelled id={`resize-mode-${innerKey}`} label={description}>
            <Select
                label="Resize mode"
                labelInline
                options={[
                    {
                        label: 'contain',
                        value: ResizeMode.Contain,
                    },
                    {
                        label: 'cover',
                        value: ResizeMode.Cover,
                    },
                    {
                        label: 'stretch',
                        value: ResizeMode.Stretch,
                    },
                    {
                        label: 'center',
                        value: ResizeMode.Center,
                    },
                ]}
                onChange={(value) => setresizeMode(value as ResizeMode)}
                value={resizeMode}
            />
        </Labelled>
    );
});