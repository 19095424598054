import React from 'react'
import { TopBar } from '@shopify/polaris';

type Props = typeof TopBar.SearchField extends ((props: infer P) => void) ? P : unknown

// const theme = {
//     colors: {
//         topBar: {
//             background: '#fff',
//             backgroundLighter: '#F4F6F8',
//             backgroundDarker: '#DFE3E8',
//             border: '#C4CDD5',
//             color: '#212B36',
//         },
//     },
// }

export const SearchField = React.memo<Props>((props) => (
    // <ThemeProvider theme={theme}>
    <TopBar.SearchField
        {...props}
    />
    // </ThemeProvider>
))