import React from "react"
import { Collections_collections } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { useEmulatorConfig } from "../../../content/emulator/components/ScalableEmulatorComponent";
import { AppEmulatorConfig } from "../../../content/emulator/types";
import { emptyCartmateIcon } from "../../../assets/helpers";
import { Text } from "react-native";

export const ProductsPreview = React.memo(({ appId, collection }: { appId: string, collection: Collections_collections }) => {
    const client = useClient();
    const emulator = useEmulatorConfig();
    const appConfig = client.useAppEmulatorConfig({ id: appId }).app.draftConfig as AppEmulatorConfig;
    const colors = appConfig.design.colors[emulator.theme];
    const collectionProducts = client.useListProducts({
        appId,
        first: 4,
        collectionId: collection?.id
    })
        .listProducts
        .edges
        .map((edge) => edge.node);

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                flexWrap: 'wrap'
            }}
            key={`collection-${collection?.id}`}
        >
            <div className="ios__content-title" style={{
                textTransform: 'uppercase',
                fontSize: `${emulator.fontSize}px`,
                fontWeight: 600,
                color: colors.backgroundText,
                padding: '5px 10px 0 25px',
                width: '100%',
                lineHeight: '35px',
                marginTop: '20px'
            }}>
                {collection?.title}
            </div>
            {collectionProducts.length === 0 && (
                <div className="ios__content-title" style={{
                    textTransform: 'uppercase',
                    fontSize: `${emulator.fontSize}px`,
                    fontWeight: 500,
                    color: colors.backgroundText,
                    padding: '5px 10px 0 25px',
                    width: '100%',
                    lineHeight: '35px',
                    marginTop: '20px'
                }}>
                    Collection is empty
                </div>
            )}
            {collectionProducts.map((product, index) => {
                let pricing: string | undefined = undefined;

                if (product?.variants?.length === 1) {
                    pricing = product?.variants[0]?.formatted_price ? product?.variants[0]?.formatted_price : undefined;
                } else if (product?.variants && product?.variants?.length > 1) {
                    const variants = product.variants.sort(function sortVariant(a, b) {
                        let c = parseFloat(a?.price ? a.price : '0');
                        let d = parseFloat(b?.price ? b.price : '0');
                        if (c < d) {
                            return -1;
                        }
                        if (c > d) {
                            return 1;
                        }
                        return 0;
                    }).map((variant) => variant?.formatted_price);

                    if (variants[0] === variants[variants.length - 1]) {
                        pricing = product.variants[0]?.formatted_price ? product.variants[0].formatted_price : undefined;
                    } else {
                        pricing = `${variants[0]}-${variants[variants.length - 1]}`
                    }
                }
                return (
                    <div
                        key={`${product.id}-${index}`}
                        className="ios__content-item"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor: colors.toolBarBackground,
                            height: `${emulator.maxHeight}px`,
                            padding: '10px 10px',
                            borderRadius: '14px',
                            margin: "7px",
                            width: `${emulator.maxWidth}px`,
                            overflow: "hidden"
                        }}>
                        <div className="ios__content-item-img" style={{
                            backgroundImage: `url(${product.images && product.images.length > 0
                                ? product.images[0]
                                    ? product.images[0].src
                                    : emptyCartmateIcon
                                : emptyCartmateIcon
                                })`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            width: '98%',
                            height: `${emulator.maxImgHeight}px`,
                            borderRadius: '10px',
                            marginBottom: '13px',
                            flexShrink: 0,
                            flexGrow: 0,
                        }} />
                        <div className="ios__content-item-title" style={{
                            color: colors.primaryText,
                            fontSize: '28px',
                            alignSelf: 'flex-start',
                            margin: '0 0 0 5px',
                            height: '80px'
                        }}>
                            <Text
                                numberOfLines={2}
                                ellipsizeMode={"tail"}
                                style={{
                                    fontSize: 28,
                                    fontWeight: '500',
                                    color: colors.primaryText,
                                }}
                            >
                                {product.title}
                            </Text>
                        </div>
                        <div className="ios__content-item-text" style={{
                            color: colors.priceText,
                            fontSize: '27px',
                            fontWeight: 500,
                            alignSelf: 'flex-start',
                            margin: '0px 0 0 5px',
                        }}>
                            {pricing}
                        </div>
                    </div>
                );
            })}
            <div style={{
                textAlign: "center",
                width: "100%",
                margin: '50px 0 20px 0'
            }}>
                <span style={{
                    borderWidth: 2,
                    borderColor: colors.accent,
                    borderRadius: 30,
                    borderStyle: "solid",
                    fontSize: 30,
                    color: colors.accent,
                    padding: '15px 120px'
                }}>
                    View All ({collection?.itemsCount})
                </span>
            </div>
        </div>
    );
})