import { Icon, Stack, Tooltip } from "@shopify/polaris";
import { CircleInformationMajor } from "@shopify/polaris-icons";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormContext } from "../../../components/Form/FormContext";
import { WhichIndustries } from "../../CreateAccount/fragments/WhichIndustries";

const description = 'You can select the industries that will be used to define the context for this ad';

export const IndustriesSelector = React.memo((
    {
        defaultIndustires,
        onChange,
        error
    }: {
        defaultIndustires?: string[],
        onChange: (value: string[]) => void,
        error?: string
    }) => {
    console.log('[IndustriesSelector] initialValue:', defaultIndustires);
    const industiriesMethods = useForm<{ industries: string[] }>({
        defaultValues: {
            industries: defaultIndustires || [],
        },
    });
    const industriesValue = industiriesMethods.watch().industries;

    useEffect(() => {
        console.log('industriesValue', industriesValue);
        if (industriesValue !== defaultIndustires) {
            onChange(industriesValue);
        }
    }, [industriesValue, onChange, defaultIndustires]);

    return (
        <FormContext {...industiriesMethods}>
            <Stack vertical>
                <Stack>
                    <Stack.Item>
                        <label>Industries</label>
                    </Stack.Item>
                    <Stack.Item>
                        <Tooltip content={description}>
                            <Icon
                                source={CircleInformationMajor}
                                color='interactive'
                            />
                        </Tooltip>
                    </Stack.Item>
                </Stack>
                <Stack.Item>
                    <WhichIndustries
                        errorMessage={error}
                        hideCaption
                        label={''}
                    />
                </Stack.Item>
            </Stack>
        </FormContext>
    );
});