import { TabDesigner } from '../../../components/types';
import React, { useContext, useEffect } from 'react';
import { PhonePreviewContext, PhonePreviews, PhonePreviewsAccent } from './PhonePreviewContext';
import { ContentReferenceType } from '../../../api/spacex.types';
import { ShowPageContent } from './usePageEditorPreview';
import { View } from 'react-native';
import { TextStyle } from '@shopify/polaris';
import { IconElement } from '../../../content/elements/IconElement';
import { CollectionsPreview } from './CollectionsPreview';
import { FavoritesPreview } from './FavoritesPreview';
import { ShopOrdersPreview } from './ShopOrdersPreview';
import { ProductsByCollectionsPreview } from './ProductsByCollectionsPreview';
import { CollectionPreview } from './CollectionPreview';
import { CartPreview } from './CartPreview';

export function useTabPhonePreview(tab: TabDesigner | undefined, accent: PhonePreviewsAccent, priority: PhonePreviews.TAB_VIEW | PhonePreviews.APP_VIEW) {
    const renderPreviewContext = useContext(PhonePreviewContext)

    useEffect(() => {
        if (tab) {
            const mediator = renderPreviewContext.pushNewPreview<TabDesigner>(({
                contentType,
                title,
                contentKey,
            }: TabDesigner) => {
                switch (contentType) {
                    case ContentReferenceType.CONTENT:
                        return contentKey && <ShowPageContent pageId={contentKey} />
                    case ContentReferenceType.CART:
                        return (<CartPreview />);
                    case ContentReferenceType.COLLECTION:
                        return (<CollectionPreview collectionId={contentKey} />);
                    case ContentReferenceType.PRODUCTS_BY_COLLECTIONS:
                        return (<ProductsByCollectionsPreview />);
                    case ContentReferenceType.SHOP_ORDERS:
                        return (<ShopOrdersPreview />);
                    case ContentReferenceType.FAVORITES:
                        return (<FavoritesPreview />);
                    case ContentReferenceType.ALL_COLLECTIONS:
                        return (<CollectionsPreview />);
                    case ContentReferenceType.FEED:
                    case ContentReferenceType.EMPTY:
                    default:
                        return <div>
                            <p>Content without preview</p>
                            <p>Page content</p>
                            <p>title: {title}</p>
                            <p>contentType: {contentType}</p>
                            <p>contentKey: {contentKey}</p>
                        </div>
                }
            }, tab, accent, priority, {
                deepUrl: 'cartmate://' + tab.contentType,
                realPhone: false
            })
            return () => {
                mediator.destroy()
            }
        }
    }, [tab, accent])
}