import React from 'react'
import { Card } from '@shopify/polaris';
import { ContentReferenceType } from '../../../api/spacex.types';
import { TabDesigner } from '../../types';
import { EditPageById } from '../../../configure/pages/EditPageById';
import { Suspense } from '../../Suspense';
import { usePublishAction } from '../../../content/contentPage/usePublishAction';
import { useClient } from '../../../api/useClient';
import { ProductsByCollectionSettings } from '../../../configure/screen/ProductsByCollectionSettings';
import { SkeletonSuspense, SkeletonType } from '../../Skeleton/SkeletonSuspense';
import { CollectionsSettings } from '../../../configure/screen/CollectionsSettings';

type Props = {
    tab: TabDesigner
}

export const TabViewer = React.memo<Props>(({ tab }) => {

    const { contentType, contentKey } = tab
    if (contentType === ContentReferenceType.CONTENT) {
        const client = useClient();
        const page = client.usePage({ id: contentKey! }).page;
        const [toastPublish, onPublish, toggleClose, loading] = usePublishAction(contentKey!);

        const needPublish = !page.publishedData || page.draftData !== page.publishedData

        return (
            <Suspense style={{ marginTop: 20 }}>
                <Card title="Page content" actions={[
                    {
                        disabled: !needPublish || loading,
                        content: loading ? 'Publishing...' : 'Publish',
                        onAction: onPublish
                    }
                ]}>
                    <Suspense>
                        <EditPageById key={contentKey!} pageId={contentKey!} />
                    </Suspense>
                </Card>
                {toastPublish}
            </Suspense>
        )
    }

    if (contentType === ContentReferenceType.PRODUCTS_BY_COLLECTIONS) {
        return (
            <SkeletonSuspense type={SkeletonType.Card} style={{ marginTop: 20 }}>
                <ProductsByCollectionSettings />
            </SkeletonSuspense>
        );
    }

    if (contentType === ContentReferenceType.ALL_COLLECTIONS) {
        return (
            <SkeletonSuspense type={SkeletonType.Card} style={{ marginTop: 20 }}>
                <CollectionsSettings />
            </SkeletonSuspense>
        );
    }

    switch (contentType) {
        case ContentReferenceType.EMPTY:
        case ContentReferenceType.CART:
        case ContentReferenceType.COLLECTION:
        case ContentReferenceType.FEED:
        default:
            return null
    }
})