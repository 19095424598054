import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Prompt, useHistory, useParams} from "react-router";
import {Button, ButtonGroup, Card, Layout, Loading, Page} from '@shopify/polaris';
import {useClient} from "../../api/useClient";
import {ViewConfig} from "../../configEditor/ConfigEditor";
import {useValidatePageName} from "../../content/contentPage/editPageUtils";
import {ContentPageType} from "../../api/spacex.types";
import {PageName} from "../../content/contentPage/PageName";
import {PageBlocks} from "../../content/contentPage/PageBlocks";

export const AppPageNew = React.memo(() => {
    const {id} = useParams<{ id: string }>();
    const client = useClient();
    const app = client.useApp({id}).app;
    const returnPath = useMemo(() => `/apps/${id}/pages`, [id]);

    const history = useHistory()
    const [loading, setLoading] = useState(false) //todo useSuspense

    const [name, setName] = useState('')
    const [blocks, setBlocks] = useState<ViewConfig.NodeBlockExtended[]>([])
    const [pageNeedSave, setPageNeedSave] = useState(false)
    const [errorMsg, clean, verifyName] = useValidatePageName(name)

    useEffect(clean, [name])
    useEffect(() => setPageNeedSave(!!name.trim().length || !!blocks.length), [name, blocks])

    const onSubmit = useCallback(() => {
        if (verifyName()) {
            setPageNeedSave(false)
            setLoading(true)
            client.mutateCreatePage({
                input: ViewConfig.PageToInputPage.encode({
                    id: 'new',
                    name,
                    type: ContentPageType.BLOCK,
                    blocks,
                    contentPageId: 'new'
                }),
            }).then(({pageCreate}) => {
                client.refetchPages();
                const location = {
                    ...history.location,
                    pathname: `/apps/${id}/pages/${pageCreate.id}`,
                }
                history.replace(location);
            }).finally(() => {
                setLoading(false);
                setPageNeedSave(true);
            });
        }

    }, [client, blocks, name])

    return (
        <Page
            title={"Create new page"}
            breadcrumbs={[
                {content: "Apps", url: "/apps"},
                {content: app.name, url: returnPath}
            ]}
            primaryAction={{
                content: "Create",
                onAction: onSubmit
            }}
        >
            {loading && <Loading/>}
            <Card>
                <Prompt message={'All changes will be lost'} when={pageNeedSave}/>
                <PageName name={name} onChange={setName} errorMsg={errorMsg || false}/>
            </Card>
            <Card title="Page content">
                <PageBlocks blocks={blocks} onChange={setBlocks}/>
            </Card>
        </Page>
    );
});