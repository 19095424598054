import { Spinner } from '@shopify/polaris';
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

export const CartmateLoader = React.memo((props: { style?: StyleProp<ViewStyle> }) => {
    return (
        <View
            style={[{
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                alignItems: 'center',
                justifyContent: 'center'
            }, props.style]}
        >
            <div style={{
                width: '100px',
                height: '100px'
            }}>
                <svg id="logo" width="100" height="100" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask-path" x="0" y="0" width="1" height="1">
                        <circle className="crcl" cx="200" cy="200" r="200" fill="#00A5FE" />
                    </mask>
                    <path mask="url(#mask-path)"
                        d="M244.533 279.529C258.624 279.529 270.048 261.708 270.048 239.725C270.048 217.742 258.624 199.922 244.533 199.922C230.442 199.922 219.019 217.742 219.019 239.725C219.019 261.708 230.442 279.529 244.533 279.529Z"
                        fill="#00A5FE" className="logo-b logo-eye logo-eye-right" />
                    <path mask="url(#mask-path)"
                        d="M155.301 279.529C169.392 279.529 180.815 261.708 180.815 239.725C180.815 217.742 169.392 199.922 155.301 199.922C141.209 199.922 129.786 217.742 129.786 239.725C129.786 261.708 141.209 279.529 155.301 279.529Z"
                        fill="#00A5FE" className="logo-b logo-eye logo-eye-left" />
                    <path mask="url(#mask-path)"
                        d="M290.873 128.44C288.942 128.302 287.011 128.027 285.08 127.889L292.942 69.6297C294.459 57.9227 286.322 47.1799 274.599 45.5271L272.254 45.2516C260.669 43.5989 249.912 51.8626 248.257 63.5696L239.982 125.135C230.052 124.859 219.846 124.584 209.227 124.584H205.365C203.572 124.584 201.779 124.584 199.986 124.584C198.193 124.584 196.4 124.584 194.607 124.584H190.746C180.264 124.584 169.92 124.859 159.99 125.135L151.716 63.5696C149.923 51.8626 139.165 43.5989 127.442 45.1139L125.098 45.3894C113.375 46.9044 105.1 57.785 106.755 69.492L114.616 127.751C112.685 127.889 110.754 128.165 108.824 128.302C66.0697 132.985 34.9006 163.561 35.0385 201.161C35.1765 235.731 42.3481 273.056 54.8985 303.494C65.7938 330.075 93.2391 348.393 126.339 351.148C147.026 352.938 176.54 355.004 199.159 355.004H200.814C223.432 355.004 252.808 352.938 273.633 351.148C306.733 348.393 334.041 330.075 345.074 303.494C357.624 273.056 364.934 235.731 364.934 201.161C364.934 163.561 333.765 132.985 290.873 128.44ZM307.285 288.068C302.182 300.326 287.425 309.278 270.048 310.656C249.36 312.446 220.949 314.374 199.848 314.374C178.747 314.374 150.336 312.446 129.649 310.656C112.272 309.141 97.5145 300.326 92.4116 288.068C81.7921 262.313 75.5859 230.084 75.5859 201.023C75.5859 184.909 91.5841 171.136 113.237 168.795C135.579 166.453 162.749 165.214 190.608 165.076C193.642 165.076 196.676 165.076 199.848 165.076C202.882 165.076 205.917 165.076 209.089 165.076C236.948 165.214 264.255 166.453 286.46 168.795C308.112 171.136 324.111 184.909 324.111 201.023C324.111 230.084 317.904 262.313 307.285 288.068Z"
                        fill="#00A5FE" strokeWidth="1" className="logo-b logo-body" />
                    <path strokeWidth="1" strokeDasharray="15"
                        d="M244.533 279.529C258.624 279.529 270.048 261.708 270.048 239.725C270.048 217.742 258.624 199.922 244.533 199.922C230.442 199.922 219.019 217.742 219.019 239.725C219.019 261.708 230.442 279.529 244.533 279.529Z"
                        className="logo-stroked logo-eye logo-eye-right" />
                    <path strokeWidth="1" strokeDasharray="15"
                        d="M155.301 279.529C169.392 279.529 180.815 261.708 180.815 239.725C180.815 217.742 169.392 199.922 155.301 199.922C141.209 199.922 129.786 217.742 129.786 239.725C129.786 261.708 141.209 279.529 155.301 279.529Z"
                        className="logo-stroked logo-eye logo-eye-left" />
                    <path strokeWidth="1" strokeDasharray="15"
                        d="M290.873 128.44C288.942 128.302 287.011 128.027 285.08 127.889L292.942 69.6297C294.459 57.9227 286.322 47.1799 274.599 45.5271L272.254 45.2516C260.669 43.5989 249.912 51.8626 248.257 63.5696L239.982 125.135C230.052 124.859 219.846 124.584 209.227 124.584H205.365C203.572 124.584 201.779 124.584 199.986 124.584C198.193 124.584 196.4 124.584 194.607 124.584H190.746C180.264 124.584 169.92 124.859 159.99 125.135L151.716 63.5696C149.923 51.8626 139.165 43.5989 127.442 45.1139L125.098 45.3894C113.375 46.9044 105.1 57.785 106.755 69.492L114.616 127.751C112.685 127.889 110.754 128.165 108.824 128.302C66.0697 132.985 34.9006 163.561 35.0385 201.161C35.1765 235.731 42.3481 273.056 54.8985 303.494C65.7938 330.075 93.2391 348.393 126.339 351.148C147.026 352.938 176.54 355.004 199.159 355.004H200.814C223.432 355.004 252.808 352.938 273.633 351.148C306.733 348.393 334.041 330.075 345.074 303.494C357.624 273.056 364.934 235.731 364.934 201.161C364.934 163.561 333.765 132.985 290.873 128.44ZM307.285 288.068C302.182 300.326 287.425 309.278 270.048 310.656C249.36 312.446 220.949 314.374 199.848 314.374C178.747 314.374 150.336 312.446 129.649 310.656C112.272 309.141 97.5145 300.326 92.4116 288.068C81.7921 262.313 75.5859 230.084 75.5859 201.023C75.5859 184.909 91.5841 171.136 113.237 168.795C135.579 166.453 162.749 165.214 190.608 165.076C193.642 165.076 196.676 165.076 199.848 165.076C202.882 165.076 205.917 165.076 209.089 165.076C236.948 165.214 264.255 166.453 286.46 168.795C308.112 171.136 324.111 184.909 324.111 201.023C324.111 230.084 317.904 262.313 307.285 288.068Z"
                        className="logo-stroked" />
                </svg>
            </div>
        </View>
    );
});