import React, { useEffect, useState } from "react";

const ThemeContext = React.createContext<boolean | undefined>(undefined);

export const ThemeContextLoader = React.memo((props: { children: any }) => {
    const getCurrentTheme = () => window.matchMedia("(prefers-color-scheme: dark)").matches;
    const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
    const mqListener = ((e: any) => {
        setIsDarkTheme(e.matches);
    });

    useEffect(() => {
        const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
        darkThemeMq.addListener(mqListener);
        return () => darkThemeMq.removeListener(mqListener);
    }, []);

    return (
        <ThemeContext.Provider value={isDarkTheme}>
            {props.children}
        </ThemeContext.Provider>
    );
});

export function useThemeContext(): boolean | undefined {
    let isDark = React.useContext(ThemeContext);
    return isDark;
}