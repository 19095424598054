import { Modal, Stack, TextStyle, Image, Button, Heading } from "@shopify/polaris";
import React from "react";
import { View, Text } from "react-native";
import { OnboardingModal, OnboardingType } from "../modals/OnboardingModal";

const menuOnboarding = require('../../assets/gifs/onboarding-navigation.gif');

export const MenuOnboarding = React.memo(() => {
    return (
        <OnboardingModal
            title={'Menu customization available with PRO plan'}
            _key={OnboardingType.Branding}
        >
            <Modal.Section>
                <View style={{
                    flexDirection: 'row',
                    flex: 1, flexWrap: 'wrap',
                    justifyContent: 'center'
                }}>
                    <img
                        style={{
                            display: 'flex',
                            flex: 1,
                            maxHeight: '400px',
                            maxWidth: '400px'
                        }}
                        src={menuOnboarding}
                        alt="Branding"
                    />
                    <View style={{
                        flex: 1,
                        maxHeight: '400px',
                        maxWidth: '400px',
                        margin: '16px'
                    }}>
                        <View style={{ marginBottom: '10px' }}>
                            <Heading>
                                {'Customize bottom navigation panel'}
                            </Heading>
                            <Text>
                                {'You can change your navigation panel as you like; delete, add or rearrange panel items to ensure your in-app shop is convenient as possible for customers. The max number of items in this panel is limited to 5.'}
                            </Text>
                        </View>
                        <View style={{ marginBottom: '10px' }}>
                            <Heading>
                                {'Highlight your navigation links in the \'More\' menu'}
                            </Heading>
                            <Text>
                                {'With the \'More\' navigation menu, you can customize items that are important for the users, but which are not as crucial as the items in the bottom navigation panel. Add links in this menu to the main elements of your app that are not used in the main navigation panel, or duplicate particularly important items.'}
                            </Text>
                        </View>
                    </View>
                </View>
            </Modal.Section>
        </OnboardingModal>
    );
});