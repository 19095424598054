import { Badge } from "@shopify/polaris";
import { BlurView } from "expo-blur";
import React, { useEffect, useState } from "react";
import { View, Text, Image } from "react-native";
import { Collection_collection } from "../../api/spacex.types";
import { useClient } from "../../api/useClient";
import { ViewType } from "../../configEditor/knobs/types";
import { mapTextAlign } from "../../utils/textUtils";
import { CollectionProps } from "../components";

export const CollectionView = React.memo((props: CollectionProps) => {
    const client = useClient();
    const [collection, setCollection] = useState<Collection_collection>();

    console.log('[ProductView]: props', props);

    useEffect(() => {
        client
            .queryCollection({ id: props.id })
            .then((res) => setCollection(res.collection));
    }, [props.id, setCollection]);

    if (!collection) {
        return (
            <View style={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <Badge size={'medium'} status={'critical'}>
                    {'Collection not found'}
                </Badge>
            </View>
        );
    }

    const image = collection.image;

    const imageSrc = props.forcePlaceholder
        ? props.placeholderImage?.src || image?.src
        : image?.src || props.placeholderImage?.src;

    const imageResizeMode = props.forcePlaceholder
        ? props.placeholderImage?.resizeMode || props.imageResizeMode
        : props.imageResizeMode

    return (
        <View style={[{ flexGrow: 1 }, props.style]}>
            <Image
                source={{ uri: imageSrc || '' }}
                resizeMode={imageResizeMode || 'cover'}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    alignSelf: 'flex-start',
                }}
            />
            {(props.showCaption && collection.title && collection.title.length > 0)
                && (
                    <BlurView
                        style={{
                            flexGrow: 1,
                            height: props.viewType === ViewType.Circles ? undefined : 68,
                            alignItems: props.textAlign
                                ? props.textAlign
                                : 'flex-start',
                            justifyContent: 'center',
                            padding: 8,
                            position: 'absolute',
                            left: 0, right: 0, bottom: 0,
                            top: props.viewType === ViewType.Circles ? 0 : undefined,
                            overflow: 'hidden'
                        }}
                        tint={'dark'}
                        intensity={props.blurIntencity}
                    >
                        <Text
                            style={{
                                color: props.textColor
                                    ? props.textColor
                                    : '#000',
                                fontSize: 16,
                                fontWeight: props.fontWeight
                                    ? props.fontWeight
                                    : '600',
                                    textAlign: mapTextAlign(props.textAlign)
                            }}
                            numberOfLines={2}
                            ellipsizeMode={'tail'}
                        >
                            {props.caption ? props.caption : collection.title}
                        </Text>
                    </BlurView>
                )}
        </View>
    )
})