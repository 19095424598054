import React, { useEffect, useState } from "react";
import { TextContainer } from "@shopify/polaris";
import { AppEmulatorConfig } from "../types";
import { IOSScreen } from "./IOSScreen";
import { IOSScreenContent } from "./IOSScreenContent";
import { IOSSearchBar } from "./IOSSearchBar";
import { IOSTitlebar } from "./IOSTitlebar";
import { useEmulatorConfig } from "./ScalableEmulatorComponent";
import { animated, useSpring } from "@react-spring/web";
import { View } from "react-native";
import { useClient } from "../../../api/useClient";

const roundLogo = require('../../../assets/images/logo_round.svg');

export const CatalogSync = React.memo(({ config }: { config: AppEmulatorConfig }) => {
    const emulator = useEmulatorConfig();
    const colors = config.design.colors[emulator.theme];

    const [flip, set] = useState(false)
    const props = useSpring({
        to: {
            opacity: 1,
            width: '150px',
            height: '150px',
        },
        from: {
            opacity: 0.8,
            width: '140px',
            height: '140px',
        },
        reset: true,
        reverse: flip,
        delay: 50,
        config: {
            tension: 300,
            friction: 60,
        },
        onRest: () => set(!flip),
    });

    const client = useClient();
    useEffect(() => {
        const intId = setInterval(() => {
            client.refetchMyShop().then((result) => {
                console.log('My shop refetched', result);
            });
        }, 1000);
        return () => {
            clearInterval(intId);
        }
    }, []);

    return (
        <IOSScreen>
            <IOSTitlebar config={config} />
            <IOSSearchBar config={config} />
            <IOSScreenContent toggleNavigation>
                <View style={{
                    padding: '0px 0 24px 0',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <div style={{
                        height: '240px',
                        width: '100%'
                    }} />
                    <div style={{
                        height: '160px', width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        <animated.img
                            src={roundLogo}
                            style={props}>
                        </animated.img>
                    </div>
                    <span style={{
                        fontSize: 36,
                        textAlign: 'center',
                        color: colors.accent,
                    }}>
                        {`Your collections and products \n are synchronizing...`}
                    </span>
                </View>
            </IOSScreenContent>
        </IOSScreen >
    )
});