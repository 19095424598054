import { Collapsible, Icon, InlineError, Select, Stack, Tooltip } from "@shopify/polaris"
import { CircleInformationMajor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useState } from "react"
import { AdListingScope } from "../../../api/spacex.types";
import { Suspense } from "../../../components/Suspense";
import { StoreSelector } from "./StoreSelector";

const options = [
    { value: '0', label: 'Whole Community' },
    { value: '1', label: 'Selected Stores' },
];

export type ListingPlacementData = { type: AdListingScope.COMMUNITY }
    | { type: AdListingScope.TARGETED, stores?: string[] };

export const ListingPlacement = React.memo((
    {
        value,
        onChange,
        error
    }: {
        value?: ListingPlacementData,
        onChange: (data: ListingPlacementData) => void,
        error?: string
    }
) => {
    const [option, setOption] = useState(
        value?.type === AdListingScope.TARGETED
            ? options[1]
            : options[0]
    );

    useEffect(() => {
        if (option === options[0]) {
            onChange({ type: AdListingScope.COMMUNITY });
        }
    }, [option, onChange]);

    const handleIputChange = useCallback(
        (data: string[]) => {
            const type = option === options[0]
                ? AdListingScope.COMMUNITY
                : AdListingScope.TARGETED;
            const value = type === AdListingScope.TARGETED ? {
                type: AdListingScope.TARGETED,
                stores: data
            } : {
                type: AdListingScope.COMMUNITY
            }
            onChange(value);
        },
        [option, onChange],
    );

    return (
        <Stack vertical>
            <Stack>
                <Stack.Item>
                    <label>{'Item placement'}</label>
                </Stack.Item>
                <Stack.Item>
                    <Tooltip content={''}>
                        <Icon
                            source={CircleInformationMajor}
                            color='interactive'
                        />
                    </Tooltip>
                </Stack.Item>
            </Stack>
            <Stack distribution={'fill'} alignment={'baseline'}>
                <Stack.Item>
                    <Select
                        label=""
                        labelHidden
                        options={options}
                        onChange={(value) => {
                            setOption(options[parseInt(value)]);
                            console.log('Select on change: ', value);
                        }}
                        value={option.value}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Collapsible
                        id={'storeSelector-collapsible'}
                        open={option === options[1]}
                    >
                        <Suspense>
                            <StoreSelector
                                onChange={(data) => {
                                    console.log('[StoreSelector]', data);
                                    handleIputChange(data)
                                }}
                            />
                        </Suspense>
                    </Collapsible>
                </Stack.Item>
            </Stack>
            {error && <InlineError message={error} fieldID="listing-placement" />}
        </Stack>
    );
});