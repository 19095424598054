import { Stack, TextStyle } from "@shopify/polaris";
import React from "react";
import { FriendlyStores_friendlyStores } from "../../../api/spacex.types";
import { SocialType } from "../../Settings/components/SocialsInput";

export const FriendlyStoreItem = React.memo(({ item }: { item: FriendlyStores_friendlyStores }) => {

    return (
        <Stack vertical>
            <Stack.Item>
                <p><TextStyle variation={'strong'}>{item.contact.data.name}</TextStyle></p>
            </Stack.Item>
            <Stack.Item>
                <Stack>
                    <Stack.Item>
                        <p>Email: <TextStyle variation={'subdued'}>{item.contact.data.email}</TextStyle></p>
                    </Stack.Item>
                    <Stack.Item>
                        <p>Phone: <TextStyle variation={'subdued'}>{item.contact.data.phoneNumber}</TextStyle></p>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack distribution={'fill'}>
                            <Stack.Item>
                                <p>Facebook:
                                    <TextStyle variation={'subdued'}>
                                        {
                                            ` @${item
                                                .contact
                                                .data
                                                .socials
                                                ?.find((s) => s.socialName === SocialType.Facebook)?.socialId}`
                                        }
                                    </TextStyle>
                                </p>
                            </Stack.Item>
                            <Stack.Item>
                                <p>Instagram:
                                    <TextStyle variation={'subdued'}>
                                        {
                                            ` @${item
                                                .contact
                                                .data
                                                .socials
                                                ?.find((s) => s.socialName === SocialType.Instagram)?.socialId}`
                                        }
                                    </TextStyle>
                                </p>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
});