import {Badge} from "@shopify/polaris";
import {CleanSpaceX} from "../components/types";
import {
    AccountMonitoringStatus,
    AccountPricingStatus,
    AccountStatus,
    AccountWrapper_account,
    AdminAccountsList_adminListAccount_edges_node
} from "../api/spacex.types";
import React, { useMemo } from "react";
import moment from "moment";
import {useHistory} from "react-router";
import { useClient } from "../api/useClient";

export type Account = CleanSpaceX<AccountWrapper_account>;
export type AdminAccount = CleanSpaceX<AdminAccountsList_adminListAccount_edges_node>;

export function getAccountStatusBadge(account: Account | any) {
    let accountStatusBadge;
    switch (account.status) {
        case AccountStatus.ACTIVE:
            accountStatusBadge = (<Badge status={"success"}>Active</Badge>);
            break;
        case AccountStatus.INACTIVE:
            accountStatusBadge = (<Badge status={"warning"}>Inactive</Badge>);
            break
        case AccountStatus.CREATED:
            accountStatusBadge = (<Badge status={"new"}>Created</Badge>);
            break;
        case AccountStatus.DELETED:
            accountStatusBadge = (<Badge status={"critical"}>Deleted</Badge>);
            break;
        default:
            accountStatusBadge = (<Badge status={"attention"}>Blocked</Badge>);
            break;
    }
    return accountStatusBadge;
}

export function getAccountPricingStatusBadge(account: Account | any, withHistory: boolean = true) {
    let pricingBadge;
    const content = getAccountPricingBadgeContent(account);
    if (account.pricing.status === AccountPricingStatus.TRIAL && withHistory) {
        console.log(parseInt(account.pricing.trialEndAt!));
        const history = useHistory<{from?: Location}>();
        return (
            <div
                style={{cursor: "pointer"}}
                onClick={() => {
                    history.push({pathname: '/subscription'})
                }}
            >
                <Badge status={content.status}>{content.text}</Badge>
            </div>
        );
    } else {
        return (
            <Badge status={content.status}>{content.text}</Badge>
        );
    }
}

export interface AccountPricingBadgeContent {
    text: string;
    status: 'success' | 'info' | 'attention' | 'critical' | 'warning' | 'new';
}

export function getAccountPricingBadgeContent(account: Account | any): AccountPricingBadgeContent {
    if (account.pricing.status === AccountPricingStatus.TRIAL) {
        console.log(parseInt(account.pricing.trialEndAt!));
        let endAt = 'Trial' + (account.pricing.trialEndAt ?
                (' end ' + moment(parseInt(account.pricing.trialEndAt)).fromNow()) : ''
        ) + '.';
        return {text: endAt, status: "warning"};
    } else if (account.pricing.status === AccountPricingStatus.ACTIVE) {
        return {text: `Subscription active: ${account.pricing.pricing?.title || 'default'}`, status: "success"};
    } else if (account.pricing.status === AccountPricingStatus.PENDING) {
        return {text: "Subscription pending", status: "attention"};
    } else if (account.pricing.status === AccountPricingStatus.INACTIVE) {
        return {text: "Subscription inactive", status: "critical"};
    } else if (account.pricing.status === AccountPricingStatus.CREATED) {
        return {text: "Subscription created", status: "new"};
    } else {
        return {text: "Unknown subscription status", status: "critical"};
    }
}

export function getAccountMonitoringBadge(account: AdminAccount) {
    switch (account.monitoringStatus?.status) {
        case AccountMonitoringStatus.ERROR:
            return (<Badge status={"critical"}>Monitoring failed</Badge>);
        case AccountMonitoringStatus.WARNING:
            return (<Badge status={"warning"}>Monitoring warning</Badge>);
        case AccountMonitoringStatus.SUCCESS:
            return (<Badge status={"success"}>Monitoring success</Badge>);
        default:
            return (<Badge status={"attention"}>UNKNOWN</Badge>);
    }
}

export function getClientDevice(): {mobile: boolean, os?: "android" | "ios", isTablet: boolean} {
    const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(navigator.userAgent);
    let isTablet = false;
    let os: "android" | "ios" | undefined = undefined,
        clientStrings: {s: "android" | "ios", r: RegExp}[] = [
            { s: 'android', r: /Android/ },
            { s: 'ios', r: /(iPhone|iPad|iPod)/ }
        ];
    for (let i = 0; i < clientStrings.length; ++i) {
        let cs = clientStrings[i];
        if (cs.r.test(navigator.userAgent)) {
            os = cs.s;
            break;
        }
    }
    switch (os) {
        case 'android':
            isTablet = (/mobile/i.test(navigator.userAgent.toLowerCase()) || document.documentElement.clientWidth > 700);
            break;

        case 'ios':
            isTablet = (/ipad/i.test(navigator.userAgent.toLowerCase()));
            break;
    }
    return {mobile, os, isTablet};
}

export function useFirstActiveApp() {
    const apps = useClient().useAllApps().apps;

    const sortedApps = useMemo(() => apps.sort((a, b) => a.name.localeCompare(b.name)), [apps])

    const activeApps = useMemo(() => sortedApps.filter(app => app.active), [sortedApps])    
    if (activeApps.length < 1) {
        throw new Error("Invalid apps length");
    }
    return activeApps[0];    
}

export function formatToUSD(price: number) {
    var formatter = new Intl.NumberFormat('en', {
        style: 'currency',
        currency: 'USD',
    });

    return formatter.format(price).replace('US', '');
}