import React, { useEffect, useState } from "react";
import { Text } from "react-native";
import { useClient } from "../../../api/useClient";
import { ExploreEmulatorCardScreen } from "./ExploreEmulatorCardScreen";
import { ExploreEmulatorTop } from "./ExploreEmulatorTop";
import { IOSScreen } from "./IOSScreen";
import { IOSScreenContent } from "./IOSScreenContent";
import { IOSToolbar } from "./IOSToolbar";
import { useEmulatorConfig } from "./ScalableEmulatorComponent";
import { AppEmulatorConfig } from "../types";
import { ThemeType } from "../States";
import { AppCard } from "../../../components/types";
import { Tooltip } from "@shopify/polaris";
import Bugsnag from "@bugsnag/browser";
import { PublishedCardList_publishedShopsList_edges_node } from "../../../api/spacex.types";

const arrow = require('../../../assets/images/ios-arrow.svg');

export const ExploreScreen = React.memo((
    { config, card }: { config: AppEmulatorConfig, card: AppCard }
) => {
    console.log('[ExploreScreen]', card);
    const client = useClient();
    const emulator = useEmulatorConfig();
    const [screen, setScreen] = useState<boolean>(false);
    const [focusedCard, setFocusedCard] = useState<PublishedCardList_publishedShopsList_edges_node>();
    const [cards, setcards] = useState<PublishedCardList_publishedShopsList_edges_node[]>();

    useEffect(() => {
        client.queryPublishedCardList().then((result) => {
            setcards(result.publishedShopsList.edges.map(edge => edge.node));
        }).catch((e) => {
            Bugsnag.notify(e);
        })
    }, [config, card, client]);

    let image
    let imageBlock

    const openShopInfo = (card: any) => {
        setScreen(true);
        setFocusedCard(card);
    }

    let bgImage = focusedCard?.background?.src || ''
    let cardImage = focusedCard?.icon?.src || ''

    let bgl: string
    let bgd: string

    if (emulator.theme === ThemeType.Light) {
        bgl = '#fff'
        bgd = '#ececec'
    } else {
        bgl = '#1b1b1b'
        bgd = '#353535'
    }
    let textColor
    let secondaryText
    let emptyBg
    if (bgl === '#fff') {
        textColor = '#000'
        secondaryText = config.design.colors.light.secondaryText
        emptyBg = '#b7b7b7'
    } else {
        textColor = '#fff'
        secondaryText = config.design.colors.dark.secondaryText
        emptyBg = '#000'
    }

    if (cardImage.length == 0) {
        imageBlock = <div className="ios__content-item-img" style={{
            width: `${emulator.isSmall ? '100px' : '120px'}`,
            height: `${emulator.isSmall ? '100px' : '120px'}`,
            color: '#fff',
            flexShrink: 0
        }}>
            <div className="default-img" style={{
                // width: '120px',
                width: `${emulator.isSmall ? '100px' : '120px'}`,
                height: `${emulator.isSmall ? '100px' : '120px'}`,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: '#b7b7b7',
                color: textColor,
                borderRadius: '30px',
                fontSize: '32px',
                fontWeight: 500,
                lineHeight: '34px'
            }}>
                YOUR<br />
                SHOP<br />
                LOGO<br />
            </div>
        </div>
    } else {
        image = focusedCard?.icon?.src
        imageBlock = <div className="ios__content-item-img" style={{
            background: `#fff url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            width: `${emulator.isSmall ? '100px' : '120px'}`,
            height: `${emulator.isSmall ? '100px' : '120px'}`,
            flexShrink: 0,
            borderRadius: '30px',
        }}></div>
    }

    let description

    if (focusedCard?.description.length == 0) {
        description = 'See how your shop description will look'
    } else {
        description = focusedCard?.description
    }

    let industiresStr;
    if (focusedCard && focusedCard.data && focusedCard.data.industries.length > 0) {
        industiresStr = focusedCard.data.industries.map((i) => i.title).filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
        }).join(', ');
    }

    console.log('focusedCard', focusedCard);

    let openedBgStyle
    let openedBgImage
    if (cardImage.length == 0 && bgImage.length == 0) {
        openedBgImage = 'none'
        openedBgStyle = 'gray'
    } else if (cardImage.length > 0 && bgImage.length == 0) {
        openedBgImage = `url(${focusedCard?.icon?.src})`
        openedBgStyle = 'none'
    } else if (bgImage.length > 0) {
        openedBgImage = `url(${focusedCard?.background?.src})`
        openedBgStyle = 'none'
    }

    let screenContent
    if (screen) {
        screenContent =
            <div className="ios__content-items" style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '10px 20px 20px',
                backgroundColor: bgl,
                flexWrap: 'wrap',
                height: `${emulator.openedHeight}px`,
                width: '100%',
            }}>
                <div className="ios__content-item-wrapper" style={{
                    position: 'fixed',
                    // top: '38px',

                    top: `${emulator.isSmall ? '42px' : '48px'}`,

                    bottom: '0px',
                    right: '0px',

                    // left: '42px',
                    left: `${emulator.isSmall ? '42px' : '46px'}`,
                    height: '1065px',
                    width: `${emulator.openedScreen}px`,
                    backgroundColor: bgl,
                    // borderTopLeftRadius: '106px 93px',
                    // borderTopLeftRadius: '58px 61px',
                    borderTopLeftRadius: `${emulator.isSmall ? '51px' : '62px'} 107px`,
                    borderTopRightRadius: `${emulator.isSmall ? '51px' : '62px'} 107px`

                }}>
                    <div className="ios__content-opened-top" style={{
                        position: 'absolute',
                        inset: '0px',
                        display: 'flex',
                        backgroundColor: 'transparent',
                        width: '100%',
                        padding: '25px 0',
                        alignItems: 'center',
                        height: '95px',
                    }}>
                        <div className="toolbar-wrapper" style={{
                            position: 'absolute',
                            zIndex: 100,
                            // top: '0px',
                            top: '-1px',
                            width: `${emulator.openedToolbar}px`
                        }}>
                            <IOSToolbar config={config} transparent={true} colored={true} smartBanner={false} />
                        </div>
                        <div className="ios__content-opened-bg" style={{
                            backgroundColor: openedBgStyle,
                            backgroundImage: openedBgImage,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            position: 'absolute',
                            top: '-1px',
                            right: '0px',
                            left: '0px',
                            height: '360px',
                            zIndex: 1,
                            borderTopLeftRadius: '74px 62px',
                            borderTopRightRadius: '74px 62px',

                        }}>
                            <div style={{
                                position: 'absolute',
                                backgroundColor: '#fff',
                                top: '0px',
                                left: '0px',
                                right: '0px',
                                bottom: '0px',
                                filter: 'opacity(0.5)',
                                borderTopLeftRadius: '74px 62px',
                                borderTopRightRadius: '74px 62px',
                            }}>

                            </div>
                        </div>
                        <div className="ios__content-top-arrow" style={{
                            backgroundImage: `url(${arrow})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            width: '24px',
                            height: '44px',
                            marginLeft: '26px',
                            cursor: 'pointer',
                            // marginTop: '101px',
                            marginTop: `${emulator.isSmall ? '78px' : '95px'}`,
                            zIndex: 5
                        }} onClick={() => setScreen(false)}></div>
                    </div>
                </div>
                <div className="ios__content-item-opened"
                    style={{
                        backgroundColor: bgd,
                        position: 'absolute',
                        top: '200px',
                        left: `${emulator.openedMargin}px`,
                        width: '90%',
                        borderRadius: '30px',
                        padding: '30px'
                    }}>
                    <div className="ios__content-item-top"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: '20px',
                            borderBottom: `2px solid ${textColor}`,
                            marginBottom: '20px'
                        }}>
                        {imageBlock}
                        <div className="ios__content-item-top-info"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignSelf: 'stretch',
                                marginLeft: `${emulator.isSmall ? '15px' : '30px'}`,
                            }}>
                            <div className="ios__content-item-top-title" style={{
                                marginBottom: '10px'
                            }}>
                                <Text
                                    numberOfLines={2}
                                    ellipsizeMode={"tail"}
                                    style={{
                                        fontSize: emulator.isSmall ? 32 : 40,
                                        color: textColor,
                                        fontWeight: '600',
                                    }}
                                >
                                    {focusedCard?.title}
                                </Text>
                            </div>
                            <div className="ios__content-item-top-title" style={{
                                marginBottom: '10px'
                            }}>
                                <Text
                                    numberOfLines={2}
                                    ellipsizeMode={"tail"}
                                    style={{
                                        fontSize: emulator.isSmall ? 22 : 24,
                                        color: secondaryText,
                                        fontWeight: '500',
                                    }}
                                >
                                    {industiresStr}
                                </Text>
                            </div>
                            <div className="ios__content-item-button" style={{
                                color: textColor,
                                backgroundColor: '#2e96f7',
                                padding: '1px 30px',
                                borderRadius: '30px',
                                fontSize: `${emulator.isSmall ? '25px' : '30px'}`,
                                fontWeight: 600,
                                alignSelf: 'flex-start'
                            }}>
                                ADD
                            </div>
                        </div>
                    </div>
                    <div className="ios__content-item-description">
                        <Text
                            numberOfLines={8}
                            ellipsizeMode={"tail"}
                            style={{
                                fontSize: emulator.isSmall ? 18 : 24,
                                color: textColor,
                                lineHeight: emulator.isSmall ? 22 : 30
                            }}
                        >
                            {description}
                        </Text>
                    </div>
                </div>
            </div >
    } else {
        screenContent = <>
            <div className="ios__content-items" style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: `10px ${emulator.explorePadding}px 20px`,
                backgroundColor: bgl,
                flexWrap: 'wrap',
                width: '100%',
            }}>
                <Tooltip content='Your in-app shop page will look like this in the "Explore Cartmate" menu after moderation.'>
                    <div
                        key={`${card.id}`}
                        style={{
                            marginTop: `${emulator.isSmall ? '5px' : '15px'}`,
                            width: '100%',
                        }}
                        onClick={() => {
                            console.log('openShopInfo', card);
                            openShopInfo(card);
                        }}
                    >
                        <ExploreEmulatorCardScreen
                            bgl={bgl}
                            bgd={bgd}
                            config={config}
                            title={card.title}
                            image={card.icon?.src || ''}
                            selectedPlatform='ios'
                            description={card.description}
                            isAppCard
                        />
                    </div>
                </Tooltip>
                {cards?.filter((c) => c.app.id !== card.app.id).map((card) => {
                    return (
                        <Tooltip
                            key={`tooltip-${card.id}`}
                            content='Shops in this list have been moderated. Users can see them in the “Explore Cartmate” menu inside the Cartmate app. (Click this item to see it open.) '
                        >
                            <div
                                key={`${card.id}`}
                                style={{
                                    marginTop: `${emulator.isSmall ? '5px' : '15px'}`,
                                    width: '100%'
                                }}
                                onClick={() => { openShopInfo(card) }}
                            >

                                <ExploreEmulatorCardScreen
                                    bgl={bgl}
                                    bgd={bgd}
                                    config={config}
                                    title={card.title}
                                    image={card.icon?.src || ''}
                                    selectedPlatform='ios'
                                    description={card.description}
                                />

                            </div>
                        </Tooltip>
                    )
                })}
            </div>
        </>
    }

    return (
        <IOSScreen>
            <IOSScreenContent toggleNavigation>
                <div
                    className={"ios__categories-list"}
                    style={{
                        position: "sticky",
                        top: 0,
                        left: 0,
                        backgroundColor: bgl,
                        width: '100%',
                        marginBottom: '-10px',
                        overflow: 'hidden',
                        paddingBottom: '94px',
                        height: '4px'
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: '40px',
                            overflowY: "hidden",
                            overflowX: "auto",
                            paddingBottom: "100%",
                            marginBottom: "-20px",
                        }}
                    >
                        <ExploreEmulatorTop
                            bgl={bgl}
                            bgd={bgd}
                            title='knslckms'
                            image=''
                            selectedPlatform='ios'
                            description='leknvlkdnv '
                        />
                    </div>
                </div>
                {screenContent}
            </IOSScreenContent>
        </IOSScreen >
    );
});