import { Icon, Stack } from "@shopify/polaris";
import { ClockMajor } from '@shopify/polaris-icons';
import React from "react";
import { IconWrapper } from "../../components/Icon/IconView/Icons";
import {
    ContentPressableNavigator,
    ContentText,
    ContentTimer,
    ContentView,
} from '../../content/components';
import { TemplateWithVariables } from "../knobs/TemplateWithVariables";
import { FontWeightType, TextAlignType } from "../knobs/types";
import { ConfigBlock } from "./BlockTypes";


const Variables = {
    bannerTitle: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: 'Caption sample text',
            textAlign: TextAlignType.Center,
            textColor: '#000',
            textShadow: false,
            showCaption: true,
            fontWeight: FontWeightType.Normal
        },
        switchable: true,
        description: 'Caption',
        disableAlpha: true,
    },
    time: {
        type: 'date_time' as const,
        defaultValue: {
            selected: new Date(),
            disabled: false
        },
        description: 'Date Picker',
    },
    digitStyle: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: 'Caption sample text',
            textAlign: TextAlignType.Start,
            textColor: '#000',
            textShadow: false,
            showCaption: true,
            fontWeight: FontWeightType.Normal
        },
        noValue: true,
        noTextAlign: true,
        description: 'Digits style',
        disableAlpha: true,
    },
    labelColor: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: 'Caption sample text',
            textAlign: TextAlignType.Start,
            textColor: '#ff9c1a',
            textShadow: false,
            showCaption: true,
            fontWeight: FontWeightType.Normal
        },
        noValue: true,
        noFontWeight: true,
        noTextAlign: true,
        description: 'Label color',
        disableAlpha: true,
    },
    height: {
        type: 'range' as const,
        defaultValue: 20,
        description: 'Blocks height',
        min: 10,
        max: 30
    },
    url: {
        type: 'navigation_picker' as const,
        description: 'Navigation Link',
        defaultValue: '',
    },
    model: {
        type: 'select' as const,
        options: [
            { label: 'D, H, M, S', value: 'DHMS' },
            { label: 'D, H, M', value: 'DHM' },
            { label: 'D, H', value: 'DH' },
            { label: 'D', value: 'D' },
            { label: 'H, M', value: 'HM' },
            { label: 'H', value: 'H' },
            { label: 'M, S', value: 'MS' },
            { label: 'M', value: 'M' },
        ],
        defaultValue: { label: 'D, H, M, S', value: 'DHMS' },
        description: 'Number of labels (D, H, M, S)',
        isAdvanced: true,
    },
    borderColor: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: 'Caption sample text',
            textAlign: TextAlignType.Center,
            textColor: '#000',
            textShadow: false,
            showCaption: true,
            fontWeight: FontWeightType.Normal
        },
        switchable: true,
        description: 'Border Settings',
        noValue: true,
        noFontWeight: true,
        noTextAlign: true,
        disableAlpha: true,
        isAdvanced: true
    },
}

const Timer = TemplateWithVariables(Variables, ({
    bannerTitle,
    digitStyle,
    labelColor,
    height,
    url,
    time,
    model,
    borderColor
}) => {
    console.log('[time]', time);
    return (
        <ContentView>
            <ContentTimer
                titleValue={bannerTitle.value}
                showCaption={bannerTitle.showCaption}
                titleStyle={{
                    color: bannerTitle.textColor,
                    fontWeight: bannerTitle.fontWeight,
                    textAlign: bannerTitle.textAlign
                }}
                size={height}
                showBorder={borderColor.showCaption}
                borderColor={borderColor.textColor}
                labelColor={labelColor.textColor}
                digitColor={digitStyle.textColor}
                digitFontWeight={digitStyle.fontWeight}
                until={time.selected}
                timeToShow={model.value}
            />
            <ContentPressableNavigator route={url || ''} />
        </ContentView>
    )
});

export default {
    Preview: () => <Stack alignment="center" distribution="center">
        <Stack.Item>
            <IconWrapper>
                <Icon source={ClockMajor} />
            </IconWrapper>
        </Stack.Item>
        <Stack.Item fill>
            Countdown timer
        </Stack.Item>
    </Stack>,
    RenderBlock: Timer,
    Icon: ClockMajor,
    Name: 'Countdown timer'
} as ConfigBlock