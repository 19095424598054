import { Icon, Stack } from "@shopify/polaris";
import { ProductsMajor } from "@shopify/polaris-icons";
import React from "react";
import { IconWrapper } from "../../components/Icon/IconView/Icons";
import { ContentHtmlMarkup, ContentView } from "../../content/components";
import { TemplateWithVariables } from "../knobs/TemplateWithVariables";
import { ConfigBlock } from "./BlockTypes";

const Variables = {
    text: {
        type: 'html_markup' as const,
        defaultValue: '',
        description: 'Text editor'
    }
};


const HtmlMarkup = TemplateWithVariables(Variables, ({
    text,
}) => {
    return (
        <ContentView
            style={{
                // height: height,
                // flexDirection: 'row',
                // borderRadius: borderRadius,
                // paddingLeft: paddingCoordinates.left,
                // paddingRight: paddingCoordinates.right,
                // paddingTop: paddingCoordinates.top,
                // paddingBottom: paddingCoordinates.bottom,
                // marginBottom: marginCoordinates.bottom,
                // marginTop: marginCoordinates.top,
                // marginLeft: marginCoordinates.left,
                // marginRight: marginCoordinates.right,
                overflow: 'hidden'
            }}
        >
            <ContentHtmlMarkup
                text={text}
            />
        </ContentView>
    );
});

export default {
    Preview: () => <Stack alignment="center" distribution="center">
        <Stack.Item>
            <IconWrapper>
                <Icon source={ProductsMajor} />
            </IconWrapper>
        </Stack.Item>
        <Stack.Item fill>
            HTML Markup
        </Stack.Item>
    </Stack>,
    RenderBlock: HtmlMarkup,
    Icon: ProductsMajor,
    Name:'HTML Markup'
} as ConfigBlock