import { useAppBridge } from "@shopify/app-bridge-react";
import { Button, Card, Icon, Layout, Page } from "@shopify/polaris";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import React from "react";
import { Switch, useRouteMatch } from "react-router";
import { ArrowLeftMinor } from "@shopify/polaris-icons";
import { AccountRequireRoute } from "../../routing/AccountRequireRoute";
import { Promote } from "./pages/Promote";
import { Push } from "./pages/Push";
import { View } from "react-native";
import { Traffic } from "./pages/Traffic";
import { Customize } from "./pages/Customize";
import { HelpItem } from "./components/HelpItem";
import { CartmatePointsInfo } from "./pages/CartmatePointsInfo";
import { Plan } from "./pages/Plan";

export const Help = React.memo(() => {
    const appBridge = useAppBridge();
    const match = useRouteMatch();
    return (<>
        <Switch>
            <AccountRequireRoute path={`${match.path}/promote`} component={Promote} />
            <AccountRequireRoute path={`${match.path}/customize`} component={Customize} />
            <AccountRequireRoute path={`${match.path}/traffic`} component={Traffic} />
            <AccountRequireRoute path={`${match.path}/push`} component={Push} />
            <AccountRequireRoute path={`${match.path}/plan`} component={Plan} />
            {__DEV__ && <AccountRequireRoute path={`${match.path}/what-is-cartmate-point`} component={CartmatePointsInfo} />}
            <AccountRequireRoute path={match.path}>
                <Page>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: '2rem' }}>
                        <Button
                            onClick={() => {
                                const redirect = ShopifyRedirect.create(appBridge);
                                redirect.dispatch(ShopifyRedirect.Action.APP, `/dashboard`);
                            }}
                            icon={<Icon source={ArrowLeftMinor} color="base" />}
                        />
                        <div style={{
                            fontSize: '2rem',
                            fontWeight: 600,
                            marginLeft: '2rem'
                        }}>
                            Cartmate Mobile Apps Metaverse
                        </div>
                    </View>
                    <Layout>
                        <Layout.Section>
                            <Card>
                                <div style={{
                                    margin: '0px',
                                    display: 'grid',
                                    gridTemplateRows: 'repeat( 8, auto)',
                                    gridTemplateColumns: 'repeat( 3, auto)',
                                    gridColumnGap: '1.6rem',
                                    padding: '1.6rem'
                                }}>
                                    <HelpItem
                                        title={'Promotion'}
                                        route={`${match.path}/promote`}
                                        description={'Promote your mobile in-app storefront'}
                                    />
                                    <HelpItem
                                        title={'Customization'}
                                        route={`${match.path}/customize`}
                                        description={'Make your mobile in-app storefront stand out'}
                                    />
                                    <HelpItem
                                        title={'Traffic Exchange'}
                                        route={`${match.path}/traffic`}
                                        description={'Get more customers with the Cartmate Traffic Exchange Program'}
                                    />
                                    <HelpItem
                                        title={'Push notifications'}
                                        route={`${match.path}/push`}
                                        description={'Send platform-targeted (iOS / Android) push notifications'}
                                        />
                                    {__DEV__ && <HelpItem
                                        title={'What is Cartmate Point'}
                                        description={'Learn more about the Cartmate Point and see what you can do with it'}
                                        route={`${match.path}/what-is-cartmate-point`}
                                    />}
                                </div>
                            </Card>
                        </Layout.Section>
                    </Layout>
                </Page>
            </AccountRequireRoute>
        </Switch>
    </>
    );
});