import { Checkbox, Select } from "@shopify/polaris";
import React, { useState, useCallback, useEffect } from "react";
import { Knob } from "../Knob";

export const KnobSelect = React.memo<Knob.KnobComponentProps<Knob.KnobSelectDescriptor>>(
    ({ knob, value: initValue, onChange, innerKey }) => {

        const [value, setValue] = useState(initValue);

        const { description, options } = knob


        const handleKnobChange = useCallback((key: keyof Knob.Select, value: any) => {
            setValue((prevValue) => {
                const newValue = { ...prevValue, ...{ [key]: value } };
                onChange(newValue, innerKey)
                return newValue;
            });
        }, [innerKey, onChange, setValue]);
    
        useEffect(() => {
            setValue(initValue)
        }, [initValue]);

        const handleSelectChange = useCallback((value) => setValue(value), []);

        return (
            <Select
                label="Date range"
                options={options}
                onChange={(v)=>{handleKnobChange('value', v)}}
                value={value.value}
            />

        )


    });