import React, { useEffect, useState } from "react";
import { useEmulatorConfig } from "./emulator/components/ScalableEmulatorComponent";

function Clock(props: { device: string }) {

    const [date, setDate] = useState(new Date());
    const emulator = useEmulatorConfig();
    let styledTime

    function tick() {
        setDate(new Date());
    }

    useEffect(() => {
        let timerID = setInterval(() => tick(), 1000);

        return function cleanup() {
            clearInterval(timerID);
        };
    });
    if (props.device === 'ios') {
        styledTime = {
            // fontSize: '30px',
            fontSize: `${emulator.isSmall ? '25px' : '30px'}`,
            fontWeight: 600,
            lineHeight: '35px',
            // marginLeft:'40px'
            marginLeft: `${emulator.clockMargin}px`,
            marginTop:`${emulator.isSmall ? `7px` : '0px'}`
        }
    } else {
        styledTime = {
            fontSize: '10px',
            fontWeight: 600,
            lineHeight: '20px',
        }
    }
    return <>
        <div style={styledTime}>
            {date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
        </div>
    </>
}
export default Clock