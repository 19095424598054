/* tslint:disable */
/* eslint-disable */
import * as Types from './spacex.types';
import { SpaceXClientParameters, GraphqlActiveSubscription, QueryParameters, MutationParameters, SubscriptionParameters, GraphqlSubscriptionHandler, BaseSpaceXClient, SpaceQueryWatchParameters } from '@openland/spacex';

export class ShopClient extends BaseSpaceXClient {
    constructor(params: SpaceXClientParameters) {
        super(params);
    }
    withParameters(params: Partial<SpaceXClientParameters>) {
        return new ShopClient({ ... params, engine: this.engine, globalCache: this.globalCache});
    }
    queryAccountWrapper(params?: QueryParameters): Promise<Types.AccountWrapper> {
        return this.query('AccountWrapper', undefined, params);
    }
    queryCoinStatus(params?: QueryParameters): Promise<Types.CoinStatus> {
        return this.query('CoinStatus', undefined, params);
    }
    querySurveyIndustryList(params?: QueryParameters): Promise<Types.SurveyIndustryList> {
        return this.query('SurveyIndustryList', undefined, params);
    }
    queryContacts(params?: QueryParameters): Promise<Types.Contacts> {
        return this.query('Contacts', undefined, params);
    }
    queryAccounPricingLimit(variables: Types.AccounPricingLimitVariables, params?: QueryParameters): Promise<Types.AccounPricingLimit> {
        return this.query('AccounPricingLimit', variables, params);
    }
    queryAdminAppCard(variables: Types.AdminAppCardVariables, params?: QueryParameters): Promise<Types.AdminAppCard> {
        return this.query('AdminAppCard', variables, params);
    }
    queryAdminAccountMonitoringList(variables: Types.AdminAccountMonitoringListVariables, params?: QueryParameters): Promise<Types.AdminAccountMonitoringList> {
        return this.query('AdminAccountMonitoringList', variables, params);
    }
    queryAdminUser(params?: QueryParameters): Promise<Types.AdminUser> {
        return this.query('AdminUser', undefined, params);
    }
    queryAdminAccountsList(variables: Types.AdminAccountsListVariables, params?: QueryParameters): Promise<Types.AdminAccountsList> {
        return this.query('AdminAccountsList', variables, params);
    }
    queryAdminGetAccount(variables: Types.AdminGetAccountVariables, params?: QueryParameters): Promise<Types.AdminGetAccount> {
        return this.query('AdminGetAccount', variables, params);
    }
    queryAdminGetAppCard(variables: Types.AdminGetAppCardVariables, params?: QueryParameters): Promise<Types.AdminGetAppCard> {
        return this.query('AdminGetAppCard', variables, params);
    }
    queryAllApps(params?: QueryParameters): Promise<Types.AllApps> {
        return this.query('AllApps', undefined, params);
    }
    queryApp(variables: Types.AppVariables, params?: QueryParameters): Promise<Types.App> {
        return this.query('App', variables, params);
    }
    queryPreviewToken(variables: Types.PreviewTokenVariables, params?: QueryParameters): Promise<Types.PreviewToken> {
        return this.query('PreviewToken', variables, params);
    }
    queryAppConfig(variables: Types.AppConfigVariables, params?: QueryParameters): Promise<Types.AppConfig> {
        return this.query('AppConfig', variables, params);
    }
    queryAppEmulatorConfig(variables: Types.AppEmulatorConfigVariables, params?: QueryParameters): Promise<Types.AppEmulatorConfig> {
        return this.query('AppEmulatorConfig', variables, params);
    }
    queryAppTabsConfig(variables: Types.AppTabsConfigVariables, params?: QueryParameters): Promise<Types.AppTabsConfig> {
        return this.query('AppTabsConfig', variables, params);
    }
    queryGetAppCard(variables: Types.GetAppCardVariables, params?: QueryParameters): Promise<Types.GetAppCard> {
        return this.query('GetAppCard', variables, params);
    }
    queryEnableBanner(variables: Types.EnableBannerVariables, params?: QueryParameters): Promise<Types.EnableBanner> {
        return this.query('EnableBanner', variables, params);
    }
    queryDisableBanner(variables: Types.DisableBannerVariables, params?: QueryParameters): Promise<Types.DisableBanner> {
        return this.query('DisableBanner', variables, params);
    }
    queryGetAppSettings(variables: Types.GetAppSettingsVariables, params?: QueryParameters): Promise<Types.GetAppSettings> {
        return this.query('GetAppSettings', variables, params);
    }
    queryAppStat(variables: Types.AppStatVariables, params?: QueryParameters): Promise<Types.AppStat> {
        return this.query('AppStat', variables, params);
    }
    queryGetSiteScreenshot(variables: Types.GetSiteScreenshotVariables, params?: QueryParameters): Promise<Types.GetSiteScreenshot> {
        return this.query('GetSiteScreenshot', variables, params);
    }
    queryGenerateSharLink(variables: Types.GenerateSharLinkVariables, params?: QueryParameters): Promise<Types.GenerateSharLink> {
        return this.query('GenerateSharLink', variables, params);
    }
    queryScreenSettings(variables: Types.ScreenSettingsVariables, params?: QueryParameters): Promise<Types.ScreenSettings> {
        return this.query('ScreenSettings', variables, params);
    }
    queryAppVersionsPage(variables: Types.AppVersionsPageVariables, params?: QueryParameters): Promise<Types.AppVersionsPage> {
        return this.query('AppVersionsPage', variables, params);
    }
    queryPublishedCardList(params?: QueryParameters): Promise<Types.PublishedCardList> {
        return this.query('PublishedCardList', undefined, params);
    }
    queryMyShop(params?: QueryParameters): Promise<Types.MyShop> {
        return this.query('MyShop', undefined, params);
    }
    queryPages(params?: QueryParameters): Promise<Types.Pages> {
        return this.query('Pages', undefined, params);
    }
    queryPage(variables: Types.PageVariables, params?: QueryParameters): Promise<Types.Page> {
        return this.query('Page', variables, params);
    }
    queryBlurHash(variables: Types.BlurHashVariables, params?: QueryParameters): Promise<Types.BlurHash> {
        return this.query('BlurHash', variables, params);
    }
    queryListPricing(variables: Types.ListPricingVariables, params?: QueryParameters): Promise<Types.ListPricing> {
        return this.query('ListPricing', variables, params);
    }
    queryDefaultPricing(params?: QueryParameters): Promise<Types.DefaultPricing> {
        return this.query('DefaultPricing', undefined, params);
    }
    queryProducts(variables: Types.ProductsVariables, params?: QueryParameters): Promise<Types.Products> {
        return this.query('Products', variables, params);
    }
    queryProduct(variables: Types.ProductVariables, params?: QueryParameters): Promise<Types.Product> {
        return this.query('Product', variables, params);
    }
    queryProductStatus(params?: QueryParameters): Promise<Types.ProductStatus> {
        return this.query('ProductStatus', undefined, params);
    }
    querySearchListProducts(variables: Types.SearchListProductsVariables, params?: QueryParameters): Promise<Types.SearchListProducts> {
        return this.query('SearchListProducts', variables, params);
    }
    queryListProducts(variables: Types.ListProductsVariables, params?: QueryParameters): Promise<Types.ListProducts> {
        return this.query('ListProducts', variables, params);
    }
    queryCollections(params?: QueryParameters): Promise<Types.Collections> {
        return this.query('Collections', undefined, params);
    }
    queryCollection(variables: Types.CollectionVariables, params?: QueryParameters): Promise<Types.Collection> {
        return this.query('Collection', variables, params);
    }
    queryListCollections(variables: Types.ListCollectionsVariables, params?: QueryParameters): Promise<Types.ListCollections> {
        return this.query('ListCollections', variables, params);
    }
    queryListPushMailing(variables: Types.ListPushMailingVariables, params?: QueryParameters): Promise<Types.ListPushMailing> {
        return this.query('ListPushMailing', variables, params);
    }
    queryPushMailing(variables: Types.PushMailingVariables, params?: QueryParameters): Promise<Types.PushMailing> {
        return this.query('PushMailing', variables, params);
    }
    querySettings(params?: QueryParameters): Promise<Types.Settings> {
        return this.query('Settings', undefined, params);
    }
    queryAdListings(params?: QueryParameters): Promise<Types.AdListings> {
        return this.query('AdListings', undefined, params);
    }
    queryAdListingsPerformance(variables: Types.AdListingsPerformanceVariables, params?: QueryParameters): Promise<Types.AdListingsPerformance> {
        return this.query('AdListingsPerformance', variables, params);
    }
    queryListAdListings(variables: Types.ListAdListingsVariables, params?: QueryParameters): Promise<Types.ListAdListings> {
        return this.query('ListAdListings', variables, params);
    }
    queryListCommunityListings(variables: Types.ListCommunityListingsVariables, params?: QueryParameters): Promise<Types.ListCommunityListings> {
        return this.query('ListCommunityListings', variables, params);
    }
    queryListAdListingsItems(variables: Types.ListAdListingsItemsVariables, params?: QueryParameters): Promise<Types.ListAdListingsItems> {
        return this.query('ListAdListingsItems', variables, params);
    }
    queryAdListingContentPreview(variables: Types.AdListingContentPreviewVariables, params?: QueryParameters): Promise<Types.AdListingContentPreview> {
        return this.query('AdListingContentPreview', variables, params);
    }
    queryPreviewListingContent(variables: Types.PreviewListingContentVariables, params?: QueryParameters): Promise<Types.PreviewListingContent> {
        return this.query('PreviewListingContent', variables, params);
    }
    queryAdListingSummury(params?: QueryParameters): Promise<Types.AdListingSummury> {
        return this.query('AdListingSummury', undefined, params);
    }
    queryIncomingRequestsCount(params?: QueryParameters): Promise<Types.IncomingRequestsCount> {
        return this.query('IncomingRequestsCount', undefined, params);
    }
    queryFriendlyStores(params?: QueryParameters): Promise<Types.FriendlyStores> {
        return this.query('FriendlyStores', undefined, params);
    }
    queryListCommunityStores(variables: Types.ListCommunityStoresVariables, params?: QueryParameters): Promise<Types.ListCommunityStores> {
        return this.query('ListCommunityStores', variables, params);
    }
    queryListPointsTransactions(variables: Types.ListPointsTransactionsVariables, params?: QueryParameters): Promise<Types.ListPointsTransactions> {
        return this.query('ListPointsTransactions', variables, params);
    }
    queryShowAdListingContent(variables: Types.ShowAdListingContentVariables, params?: QueryParameters): Promise<Types.ShowAdListingContent> {
        return this.query('ShowAdListingContent', variables, params);
    }
    queryExchangeEarnings(params?: QueryParameters): Promise<Types.ExchangeEarnings> {
        return this.query('ExchangeEarnings', undefined, params);
    }
    queryGetCoinsPrice(variables: Types.GetCoinsPriceVariables, params?: QueryParameters): Promise<Types.GetCoinsPrice> {
        return this.query('GetCoinsPrice', variables, params);
    }
    refetchAccountWrapper(params?: QueryParameters): Promise<Types.AccountWrapper> {
        return this.refetch('AccountWrapper', undefined, params);
    }
    refetchCoinStatus(params?: QueryParameters): Promise<Types.CoinStatus> {
        return this.refetch('CoinStatus', undefined, params);
    }
    refetchSurveyIndustryList(params?: QueryParameters): Promise<Types.SurveyIndustryList> {
        return this.refetch('SurveyIndustryList', undefined, params);
    }
    refetchContacts(params?: QueryParameters): Promise<Types.Contacts> {
        return this.refetch('Contacts', undefined, params);
    }
    refetchAccounPricingLimit(variables: Types.AccounPricingLimitVariables, params?: QueryParameters): Promise<Types.AccounPricingLimit> {
        return this.refetch('AccounPricingLimit', variables, params);
    }
    refetchAdminAppCard(variables: Types.AdminAppCardVariables, params?: QueryParameters): Promise<Types.AdminAppCard> {
        return this.refetch('AdminAppCard', variables, params);
    }
    refetchAdminAccountMonitoringList(variables: Types.AdminAccountMonitoringListVariables, params?: QueryParameters): Promise<Types.AdminAccountMonitoringList> {
        return this.refetch('AdminAccountMonitoringList', variables, params);
    }
    refetchAdminUser(params?: QueryParameters): Promise<Types.AdminUser> {
        return this.refetch('AdminUser', undefined, params);
    }
    refetchAdminAccountsList(variables: Types.AdminAccountsListVariables, params?: QueryParameters): Promise<Types.AdminAccountsList> {
        return this.refetch('AdminAccountsList', variables, params);
    }
    refetchAdminGetAccount(variables: Types.AdminGetAccountVariables, params?: QueryParameters): Promise<Types.AdminGetAccount> {
        return this.refetch('AdminGetAccount', variables, params);
    }
    refetchAdminGetAppCard(variables: Types.AdminGetAppCardVariables, params?: QueryParameters): Promise<Types.AdminGetAppCard> {
        return this.refetch('AdminGetAppCard', variables, params);
    }
    refetchAllApps(params?: QueryParameters): Promise<Types.AllApps> {
        return this.refetch('AllApps', undefined, params);
    }
    refetchApp(variables: Types.AppVariables, params?: QueryParameters): Promise<Types.App> {
        return this.refetch('App', variables, params);
    }
    refetchPreviewToken(variables: Types.PreviewTokenVariables, params?: QueryParameters): Promise<Types.PreviewToken> {
        return this.refetch('PreviewToken', variables, params);
    }
    refetchAppConfig(variables: Types.AppConfigVariables, params?: QueryParameters): Promise<Types.AppConfig> {
        return this.refetch('AppConfig', variables, params);
    }
    refetchAppEmulatorConfig(variables: Types.AppEmulatorConfigVariables, params?: QueryParameters): Promise<Types.AppEmulatorConfig> {
        return this.refetch('AppEmulatorConfig', variables, params);
    }
    refetchAppTabsConfig(variables: Types.AppTabsConfigVariables, params?: QueryParameters): Promise<Types.AppTabsConfig> {
        return this.refetch('AppTabsConfig', variables, params);
    }
    refetchGetAppCard(variables: Types.GetAppCardVariables, params?: QueryParameters): Promise<Types.GetAppCard> {
        return this.refetch('GetAppCard', variables, params);
    }
    refetchEnableBanner(variables: Types.EnableBannerVariables, params?: QueryParameters): Promise<Types.EnableBanner> {
        return this.refetch('EnableBanner', variables, params);
    }
    refetchDisableBanner(variables: Types.DisableBannerVariables, params?: QueryParameters): Promise<Types.DisableBanner> {
        return this.refetch('DisableBanner', variables, params);
    }
    refetchGetAppSettings(variables: Types.GetAppSettingsVariables, params?: QueryParameters): Promise<Types.GetAppSettings> {
        return this.refetch('GetAppSettings', variables, params);
    }
    refetchAppStat(variables: Types.AppStatVariables, params?: QueryParameters): Promise<Types.AppStat> {
        return this.refetch('AppStat', variables, params);
    }
    refetchGetSiteScreenshot(variables: Types.GetSiteScreenshotVariables, params?: QueryParameters): Promise<Types.GetSiteScreenshot> {
        return this.refetch('GetSiteScreenshot', variables, params);
    }
    refetchGenerateSharLink(variables: Types.GenerateSharLinkVariables, params?: QueryParameters): Promise<Types.GenerateSharLink> {
        return this.refetch('GenerateSharLink', variables, params);
    }
    refetchScreenSettings(variables: Types.ScreenSettingsVariables, params?: QueryParameters): Promise<Types.ScreenSettings> {
        return this.refetch('ScreenSettings', variables, params);
    }
    refetchAppVersionsPage(variables: Types.AppVersionsPageVariables, params?: QueryParameters): Promise<Types.AppVersionsPage> {
        return this.refetch('AppVersionsPage', variables, params);
    }
    refetchPublishedCardList(params?: QueryParameters): Promise<Types.PublishedCardList> {
        return this.refetch('PublishedCardList', undefined, params);
    }
    refetchMyShop(params?: QueryParameters): Promise<Types.MyShop> {
        return this.refetch('MyShop', undefined, params);
    }
    refetchPages(params?: QueryParameters): Promise<Types.Pages> {
        return this.refetch('Pages', undefined, params);
    }
    refetchPage(variables: Types.PageVariables, params?: QueryParameters): Promise<Types.Page> {
        return this.refetch('Page', variables, params);
    }
    refetchBlurHash(variables: Types.BlurHashVariables, params?: QueryParameters): Promise<Types.BlurHash> {
        return this.refetch('BlurHash', variables, params);
    }
    refetchListPricing(variables: Types.ListPricingVariables, params?: QueryParameters): Promise<Types.ListPricing> {
        return this.refetch('ListPricing', variables, params);
    }
    refetchDefaultPricing(params?: QueryParameters): Promise<Types.DefaultPricing> {
        return this.refetch('DefaultPricing', undefined, params);
    }
    refetchProducts(variables: Types.ProductsVariables, params?: QueryParameters): Promise<Types.Products> {
        return this.refetch('Products', variables, params);
    }
    refetchProduct(variables: Types.ProductVariables, params?: QueryParameters): Promise<Types.Product> {
        return this.refetch('Product', variables, params);
    }
    refetchProductStatus(params?: QueryParameters): Promise<Types.ProductStatus> {
        return this.refetch('ProductStatus', undefined, params);
    }
    refetchSearchListProducts(variables: Types.SearchListProductsVariables, params?: QueryParameters): Promise<Types.SearchListProducts> {
        return this.refetch('SearchListProducts', variables, params);
    }
    refetchListProducts(variables: Types.ListProductsVariables, params?: QueryParameters): Promise<Types.ListProducts> {
        return this.refetch('ListProducts', variables, params);
    }
    refetchCollections(params?: QueryParameters): Promise<Types.Collections> {
        return this.refetch('Collections', undefined, params);
    }
    refetchCollection(variables: Types.CollectionVariables, params?: QueryParameters): Promise<Types.Collection> {
        return this.refetch('Collection', variables, params);
    }
    refetchListCollections(variables: Types.ListCollectionsVariables, params?: QueryParameters): Promise<Types.ListCollections> {
        return this.refetch('ListCollections', variables, params);
    }
    refetchListPushMailing(variables: Types.ListPushMailingVariables, params?: QueryParameters): Promise<Types.ListPushMailing> {
        return this.refetch('ListPushMailing', variables, params);
    }
    refetchPushMailing(variables: Types.PushMailingVariables, params?: QueryParameters): Promise<Types.PushMailing> {
        return this.refetch('PushMailing', variables, params);
    }
    refetchSettings(params?: QueryParameters): Promise<Types.Settings> {
        return this.refetch('Settings', undefined, params);
    }
    refetchAdListings(params?: QueryParameters): Promise<Types.AdListings> {
        return this.refetch('AdListings', undefined, params);
    }
    refetchAdListingsPerformance(variables: Types.AdListingsPerformanceVariables, params?: QueryParameters): Promise<Types.AdListingsPerformance> {
        return this.refetch('AdListingsPerformance', variables, params);
    }
    refetchListAdListings(variables: Types.ListAdListingsVariables, params?: QueryParameters): Promise<Types.ListAdListings> {
        return this.refetch('ListAdListings', variables, params);
    }
    refetchListCommunityListings(variables: Types.ListCommunityListingsVariables, params?: QueryParameters): Promise<Types.ListCommunityListings> {
        return this.refetch('ListCommunityListings', variables, params);
    }
    refetchListAdListingsItems(variables: Types.ListAdListingsItemsVariables, params?: QueryParameters): Promise<Types.ListAdListingsItems> {
        return this.refetch('ListAdListingsItems', variables, params);
    }
    refetchAdListingContentPreview(variables: Types.AdListingContentPreviewVariables, params?: QueryParameters): Promise<Types.AdListingContentPreview> {
        return this.refetch('AdListingContentPreview', variables, params);
    }
    refetchPreviewListingContent(variables: Types.PreviewListingContentVariables, params?: QueryParameters): Promise<Types.PreviewListingContent> {
        return this.refetch('PreviewListingContent', variables, params);
    }
    refetchAdListingSummury(params?: QueryParameters): Promise<Types.AdListingSummury> {
        return this.refetch('AdListingSummury', undefined, params);
    }
    refetchIncomingRequestsCount(params?: QueryParameters): Promise<Types.IncomingRequestsCount> {
        return this.refetch('IncomingRequestsCount', undefined, params);
    }
    refetchFriendlyStores(params?: QueryParameters): Promise<Types.FriendlyStores> {
        return this.refetch('FriendlyStores', undefined, params);
    }
    refetchListCommunityStores(variables: Types.ListCommunityStoresVariables, params?: QueryParameters): Promise<Types.ListCommunityStores> {
        return this.refetch('ListCommunityStores', variables, params);
    }
    refetchListPointsTransactions(variables: Types.ListPointsTransactionsVariables, params?: QueryParameters): Promise<Types.ListPointsTransactions> {
        return this.refetch('ListPointsTransactions', variables, params);
    }
    refetchShowAdListingContent(variables: Types.ShowAdListingContentVariables, params?: QueryParameters): Promise<Types.ShowAdListingContent> {
        return this.refetch('ShowAdListingContent', variables, params);
    }
    refetchExchangeEarnings(params?: QueryParameters): Promise<Types.ExchangeEarnings> {
        return this.refetch('ExchangeEarnings', undefined, params);
    }
    refetchGetCoinsPrice(variables: Types.GetCoinsPriceVariables, params?: QueryParameters): Promise<Types.GetCoinsPrice> {
        return this.refetch('GetCoinsPrice', variables, params);
    }
    updateAccountWrapper(updater: (data: Types.AccountWrapper) => Types.AccountWrapper | null): Promise<boolean> {
        return this.updateQuery(updater, 'AccountWrapper', undefined);
    }
    updateCoinStatus(updater: (data: Types.CoinStatus) => Types.CoinStatus | null): Promise<boolean> {
        return this.updateQuery(updater, 'CoinStatus', undefined);
    }
    updateSurveyIndustryList(updater: (data: Types.SurveyIndustryList) => Types.SurveyIndustryList | null): Promise<boolean> {
        return this.updateQuery(updater, 'SurveyIndustryList', undefined);
    }
    updateContacts(updater: (data: Types.Contacts) => Types.Contacts | null): Promise<boolean> {
        return this.updateQuery(updater, 'Contacts', undefined);
    }
    updateAccounPricingLimit(variables: Types.AccounPricingLimitVariables, updater: (data: Types.AccounPricingLimit) => Types.AccounPricingLimit | null): Promise<boolean> {
        return this.updateQuery(updater, 'AccounPricingLimit', variables);
    }
    updateAdminAppCard(variables: Types.AdminAppCardVariables, updater: (data: Types.AdminAppCard) => Types.AdminAppCard | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminAppCard', variables);
    }
    updateAdminAccountMonitoringList(variables: Types.AdminAccountMonitoringListVariables, updater: (data: Types.AdminAccountMonitoringList) => Types.AdminAccountMonitoringList | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminAccountMonitoringList', variables);
    }
    updateAdminUser(updater: (data: Types.AdminUser) => Types.AdminUser | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminUser', undefined);
    }
    updateAdminAccountsList(variables: Types.AdminAccountsListVariables, updater: (data: Types.AdminAccountsList) => Types.AdminAccountsList | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminAccountsList', variables);
    }
    updateAdminGetAccount(variables: Types.AdminGetAccountVariables, updater: (data: Types.AdminGetAccount) => Types.AdminGetAccount | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminGetAccount', variables);
    }
    updateAdminGetAppCard(variables: Types.AdminGetAppCardVariables, updater: (data: Types.AdminGetAppCard) => Types.AdminGetAppCard | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminGetAppCard', variables);
    }
    updateAllApps(updater: (data: Types.AllApps) => Types.AllApps | null): Promise<boolean> {
        return this.updateQuery(updater, 'AllApps', undefined);
    }
    updateApp(variables: Types.AppVariables, updater: (data: Types.App) => Types.App | null): Promise<boolean> {
        return this.updateQuery(updater, 'App', variables);
    }
    updatePreviewToken(variables: Types.PreviewTokenVariables, updater: (data: Types.PreviewToken) => Types.PreviewToken | null): Promise<boolean> {
        return this.updateQuery(updater, 'PreviewToken', variables);
    }
    updateAppConfig(variables: Types.AppConfigVariables, updater: (data: Types.AppConfig) => Types.AppConfig | null): Promise<boolean> {
        return this.updateQuery(updater, 'AppConfig', variables);
    }
    updateAppEmulatorConfig(variables: Types.AppEmulatorConfigVariables, updater: (data: Types.AppEmulatorConfig) => Types.AppEmulatorConfig | null): Promise<boolean> {
        return this.updateQuery(updater, 'AppEmulatorConfig', variables);
    }
    updateAppTabsConfig(variables: Types.AppTabsConfigVariables, updater: (data: Types.AppTabsConfig) => Types.AppTabsConfig | null): Promise<boolean> {
        return this.updateQuery(updater, 'AppTabsConfig', variables);
    }
    updateGetAppCard(variables: Types.GetAppCardVariables, updater: (data: Types.GetAppCard) => Types.GetAppCard | null): Promise<boolean> {
        return this.updateQuery(updater, 'GetAppCard', variables);
    }
    updateEnableBanner(variables: Types.EnableBannerVariables, updater: (data: Types.EnableBanner) => Types.EnableBanner | null): Promise<boolean> {
        return this.updateQuery(updater, 'EnableBanner', variables);
    }
    updateDisableBanner(variables: Types.DisableBannerVariables, updater: (data: Types.DisableBanner) => Types.DisableBanner | null): Promise<boolean> {
        return this.updateQuery(updater, 'DisableBanner', variables);
    }
    updateGetAppSettings(variables: Types.GetAppSettingsVariables, updater: (data: Types.GetAppSettings) => Types.GetAppSettings | null): Promise<boolean> {
        return this.updateQuery(updater, 'GetAppSettings', variables);
    }
    updateAppStat(variables: Types.AppStatVariables, updater: (data: Types.AppStat) => Types.AppStat | null): Promise<boolean> {
        return this.updateQuery(updater, 'AppStat', variables);
    }
    updateGetSiteScreenshot(variables: Types.GetSiteScreenshotVariables, updater: (data: Types.GetSiteScreenshot) => Types.GetSiteScreenshot | null): Promise<boolean> {
        return this.updateQuery(updater, 'GetSiteScreenshot', variables);
    }
    updateGenerateSharLink(variables: Types.GenerateSharLinkVariables, updater: (data: Types.GenerateSharLink) => Types.GenerateSharLink | null): Promise<boolean> {
        return this.updateQuery(updater, 'GenerateSharLink', variables);
    }
    updateScreenSettings(variables: Types.ScreenSettingsVariables, updater: (data: Types.ScreenSettings) => Types.ScreenSettings | null): Promise<boolean> {
        return this.updateQuery(updater, 'ScreenSettings', variables);
    }
    updateAppVersionsPage(variables: Types.AppVersionsPageVariables, updater: (data: Types.AppVersionsPage) => Types.AppVersionsPage | null): Promise<boolean> {
        return this.updateQuery(updater, 'AppVersionsPage', variables);
    }
    updatePublishedCardList(updater: (data: Types.PublishedCardList) => Types.PublishedCardList | null): Promise<boolean> {
        return this.updateQuery(updater, 'PublishedCardList', undefined);
    }
    updateMyShop(updater: (data: Types.MyShop) => Types.MyShop | null): Promise<boolean> {
        return this.updateQuery(updater, 'MyShop', undefined);
    }
    updatePages(updater: (data: Types.Pages) => Types.Pages | null): Promise<boolean> {
        return this.updateQuery(updater, 'Pages', undefined);
    }
    updatePage(variables: Types.PageVariables, updater: (data: Types.Page) => Types.Page | null): Promise<boolean> {
        return this.updateQuery(updater, 'Page', variables);
    }
    updateBlurHash(variables: Types.BlurHashVariables, updater: (data: Types.BlurHash) => Types.BlurHash | null): Promise<boolean> {
        return this.updateQuery(updater, 'BlurHash', variables);
    }
    updateListPricing(variables: Types.ListPricingVariables, updater: (data: Types.ListPricing) => Types.ListPricing | null): Promise<boolean> {
        return this.updateQuery(updater, 'ListPricing', variables);
    }
    updateDefaultPricing(updater: (data: Types.DefaultPricing) => Types.DefaultPricing | null): Promise<boolean> {
        return this.updateQuery(updater, 'DefaultPricing', undefined);
    }
    updateProducts(variables: Types.ProductsVariables, updater: (data: Types.Products) => Types.Products | null): Promise<boolean> {
        return this.updateQuery(updater, 'Products', variables);
    }
    updateProduct(variables: Types.ProductVariables, updater: (data: Types.Product) => Types.Product | null): Promise<boolean> {
        return this.updateQuery(updater, 'Product', variables);
    }
    updateProductStatus(updater: (data: Types.ProductStatus) => Types.ProductStatus | null): Promise<boolean> {
        return this.updateQuery(updater, 'ProductStatus', undefined);
    }
    updateSearchListProducts(variables: Types.SearchListProductsVariables, updater: (data: Types.SearchListProducts) => Types.SearchListProducts | null): Promise<boolean> {
        return this.updateQuery(updater, 'SearchListProducts', variables);
    }
    updateListProducts(variables: Types.ListProductsVariables, updater: (data: Types.ListProducts) => Types.ListProducts | null): Promise<boolean> {
        return this.updateQuery(updater, 'ListProducts', variables);
    }
    updateCollections(updater: (data: Types.Collections) => Types.Collections | null): Promise<boolean> {
        return this.updateQuery(updater, 'Collections', undefined);
    }
    updateCollection(variables: Types.CollectionVariables, updater: (data: Types.Collection) => Types.Collection | null): Promise<boolean> {
        return this.updateQuery(updater, 'Collection', variables);
    }
    updateListCollections(variables: Types.ListCollectionsVariables, updater: (data: Types.ListCollections) => Types.ListCollections | null): Promise<boolean> {
        return this.updateQuery(updater, 'ListCollections', variables);
    }
    updateListPushMailing(variables: Types.ListPushMailingVariables, updater: (data: Types.ListPushMailing) => Types.ListPushMailing | null): Promise<boolean> {
        return this.updateQuery(updater, 'ListPushMailing', variables);
    }
    updatePushMailing(variables: Types.PushMailingVariables, updater: (data: Types.PushMailing) => Types.PushMailing | null): Promise<boolean> {
        return this.updateQuery(updater, 'PushMailing', variables);
    }
    updateSettings(updater: (data: Types.Settings) => Types.Settings | null): Promise<boolean> {
        return this.updateQuery(updater, 'Settings', undefined);
    }
    updateAdListings(updater: (data: Types.AdListings) => Types.AdListings | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdListings', undefined);
    }
    updateAdListingsPerformance(variables: Types.AdListingsPerformanceVariables, updater: (data: Types.AdListingsPerformance) => Types.AdListingsPerformance | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdListingsPerformance', variables);
    }
    updateListAdListings(variables: Types.ListAdListingsVariables, updater: (data: Types.ListAdListings) => Types.ListAdListings | null): Promise<boolean> {
        return this.updateQuery(updater, 'ListAdListings', variables);
    }
    updateListCommunityListings(variables: Types.ListCommunityListingsVariables, updater: (data: Types.ListCommunityListings) => Types.ListCommunityListings | null): Promise<boolean> {
        return this.updateQuery(updater, 'ListCommunityListings', variables);
    }
    updateListAdListingsItems(variables: Types.ListAdListingsItemsVariables, updater: (data: Types.ListAdListingsItems) => Types.ListAdListingsItems | null): Promise<boolean> {
        return this.updateQuery(updater, 'ListAdListingsItems', variables);
    }
    updateAdListingContentPreview(variables: Types.AdListingContentPreviewVariables, updater: (data: Types.AdListingContentPreview) => Types.AdListingContentPreview | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdListingContentPreview', variables);
    }
    updatePreviewListingContent(variables: Types.PreviewListingContentVariables, updater: (data: Types.PreviewListingContent) => Types.PreviewListingContent | null): Promise<boolean> {
        return this.updateQuery(updater, 'PreviewListingContent', variables);
    }
    updateAdListingSummury(updater: (data: Types.AdListingSummury) => Types.AdListingSummury | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdListingSummury', undefined);
    }
    updateIncomingRequestsCount(updater: (data: Types.IncomingRequestsCount) => Types.IncomingRequestsCount | null): Promise<boolean> {
        return this.updateQuery(updater, 'IncomingRequestsCount', undefined);
    }
    updateFriendlyStores(updater: (data: Types.FriendlyStores) => Types.FriendlyStores | null): Promise<boolean> {
        return this.updateQuery(updater, 'FriendlyStores', undefined);
    }
    updateListCommunityStores(variables: Types.ListCommunityStoresVariables, updater: (data: Types.ListCommunityStores) => Types.ListCommunityStores | null): Promise<boolean> {
        return this.updateQuery(updater, 'ListCommunityStores', variables);
    }
    updateListPointsTransactions(variables: Types.ListPointsTransactionsVariables, updater: (data: Types.ListPointsTransactions) => Types.ListPointsTransactions | null): Promise<boolean> {
        return this.updateQuery(updater, 'ListPointsTransactions', variables);
    }
    updateShowAdListingContent(variables: Types.ShowAdListingContentVariables, updater: (data: Types.ShowAdListingContent) => Types.ShowAdListingContent | null): Promise<boolean> {
        return this.updateQuery(updater, 'ShowAdListingContent', variables);
    }
    updateExchangeEarnings(updater: (data: Types.ExchangeEarnings) => Types.ExchangeEarnings | null): Promise<boolean> {
        return this.updateQuery(updater, 'ExchangeEarnings', undefined);
    }
    updateGetCoinsPrice(variables: Types.GetCoinsPriceVariables, updater: (data: Types.GetCoinsPrice) => Types.GetCoinsPrice | null): Promise<boolean> {
        return this.updateQuery(updater, 'GetCoinsPrice', variables);
    }
    useAccountWrapper(params: SpaceQueryWatchParameters & { suspense: false }): Types.AccountWrapper | null;
    useAccountWrapper(params?: SpaceQueryWatchParameters): Types.AccountWrapper;
    useAccountWrapper(params?: SpaceQueryWatchParameters): Types.AccountWrapper | null {;
        return this.useQuery('AccountWrapper', undefined, params);
    }
    useCoinStatus(params: SpaceQueryWatchParameters & { suspense: false }): Types.CoinStatus | null;
    useCoinStatus(params?: SpaceQueryWatchParameters): Types.CoinStatus;
    useCoinStatus(params?: SpaceQueryWatchParameters): Types.CoinStatus | null {;
        return this.useQuery('CoinStatus', undefined, params);
    }
    useSurveyIndustryList(params: SpaceQueryWatchParameters & { suspense: false }): Types.SurveyIndustryList | null;
    useSurveyIndustryList(params?: SpaceQueryWatchParameters): Types.SurveyIndustryList;
    useSurveyIndustryList(params?: SpaceQueryWatchParameters): Types.SurveyIndustryList | null {;
        return this.useQuery('SurveyIndustryList', undefined, params);
    }
    useContacts(params: SpaceQueryWatchParameters & { suspense: false }): Types.Contacts | null;
    useContacts(params?: SpaceQueryWatchParameters): Types.Contacts;
    useContacts(params?: SpaceQueryWatchParameters): Types.Contacts | null {;
        return this.useQuery('Contacts', undefined, params);
    }
    useAccounPricingLimit(variables: Types.AccounPricingLimitVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AccounPricingLimit | null;
    useAccounPricingLimit(variables: Types.AccounPricingLimitVariables, params?: SpaceQueryWatchParameters): Types.AccounPricingLimit;
    useAccounPricingLimit(variables: Types.AccounPricingLimitVariables, params?: SpaceQueryWatchParameters): Types.AccounPricingLimit | null {;
        return this.useQuery('AccounPricingLimit', variables, params);
    }
    useAdminAppCard(variables: Types.AdminAppCardVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminAppCard | null;
    useAdminAppCard(variables: Types.AdminAppCardVariables, params?: SpaceQueryWatchParameters): Types.AdminAppCard;
    useAdminAppCard(variables: Types.AdminAppCardVariables, params?: SpaceQueryWatchParameters): Types.AdminAppCard | null {;
        return this.useQuery('AdminAppCard', variables, params);
    }
    useAdminAccountMonitoringList(variables: Types.AdminAccountMonitoringListVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminAccountMonitoringList | null;
    useAdminAccountMonitoringList(variables: Types.AdminAccountMonitoringListVariables, params?: SpaceQueryWatchParameters): Types.AdminAccountMonitoringList;
    useAdminAccountMonitoringList(variables: Types.AdminAccountMonitoringListVariables, params?: SpaceQueryWatchParameters): Types.AdminAccountMonitoringList | null {;
        return this.useQuery('AdminAccountMonitoringList', variables, params);
    }
    useAdminUser(params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminUser | null;
    useAdminUser(params?: SpaceQueryWatchParameters): Types.AdminUser;
    useAdminUser(params?: SpaceQueryWatchParameters): Types.AdminUser | null {;
        return this.useQuery('AdminUser', undefined, params);
    }
    useAdminAccountsList(variables: Types.AdminAccountsListVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminAccountsList | null;
    useAdminAccountsList(variables: Types.AdminAccountsListVariables, params?: SpaceQueryWatchParameters): Types.AdminAccountsList;
    useAdminAccountsList(variables: Types.AdminAccountsListVariables, params?: SpaceQueryWatchParameters): Types.AdminAccountsList | null {;
        return this.useQuery('AdminAccountsList', variables, params);
    }
    useAdminGetAccount(variables: Types.AdminGetAccountVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminGetAccount | null;
    useAdminGetAccount(variables: Types.AdminGetAccountVariables, params?: SpaceQueryWatchParameters): Types.AdminGetAccount;
    useAdminGetAccount(variables: Types.AdminGetAccountVariables, params?: SpaceQueryWatchParameters): Types.AdminGetAccount | null {;
        return this.useQuery('AdminGetAccount', variables, params);
    }
    useAdminGetAppCard(variables: Types.AdminGetAppCardVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminGetAppCard | null;
    useAdminGetAppCard(variables: Types.AdminGetAppCardVariables, params?: SpaceQueryWatchParameters): Types.AdminGetAppCard;
    useAdminGetAppCard(variables: Types.AdminGetAppCardVariables, params?: SpaceQueryWatchParameters): Types.AdminGetAppCard | null {;
        return this.useQuery('AdminGetAppCard', variables, params);
    }
    useAllApps(params: SpaceQueryWatchParameters & { suspense: false }): Types.AllApps | null;
    useAllApps(params?: SpaceQueryWatchParameters): Types.AllApps;
    useAllApps(params?: SpaceQueryWatchParameters): Types.AllApps | null {;
        return this.useQuery('AllApps', undefined, params);
    }
    useApp(variables: Types.AppVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.App | null;
    useApp(variables: Types.AppVariables, params?: SpaceQueryWatchParameters): Types.App;
    useApp(variables: Types.AppVariables, params?: SpaceQueryWatchParameters): Types.App | null {;
        return this.useQuery('App', variables, params);
    }
    usePreviewToken(variables: Types.PreviewTokenVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.PreviewToken | null;
    usePreviewToken(variables: Types.PreviewTokenVariables, params?: SpaceQueryWatchParameters): Types.PreviewToken;
    usePreviewToken(variables: Types.PreviewTokenVariables, params?: SpaceQueryWatchParameters): Types.PreviewToken | null {;
        return this.useQuery('PreviewToken', variables, params);
    }
    useAppConfig(variables: Types.AppConfigVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AppConfig | null;
    useAppConfig(variables: Types.AppConfigVariables, params?: SpaceQueryWatchParameters): Types.AppConfig;
    useAppConfig(variables: Types.AppConfigVariables, params?: SpaceQueryWatchParameters): Types.AppConfig | null {;
        return this.useQuery('AppConfig', variables, params);
    }
    useAppEmulatorConfig(variables: Types.AppEmulatorConfigVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AppEmulatorConfig | null;
    useAppEmulatorConfig(variables: Types.AppEmulatorConfigVariables, params?: SpaceQueryWatchParameters): Types.AppEmulatorConfig;
    useAppEmulatorConfig(variables: Types.AppEmulatorConfigVariables, params?: SpaceQueryWatchParameters): Types.AppEmulatorConfig | null {;
        return this.useQuery('AppEmulatorConfig', variables, params);
    }
    useAppTabsConfig(variables: Types.AppTabsConfigVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AppTabsConfig | null;
    useAppTabsConfig(variables: Types.AppTabsConfigVariables, params?: SpaceQueryWatchParameters): Types.AppTabsConfig;
    useAppTabsConfig(variables: Types.AppTabsConfigVariables, params?: SpaceQueryWatchParameters): Types.AppTabsConfig | null {;
        return this.useQuery('AppTabsConfig', variables, params);
    }
    useGetAppCard(variables: Types.GetAppCardVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.GetAppCard | null;
    useGetAppCard(variables: Types.GetAppCardVariables, params?: SpaceQueryWatchParameters): Types.GetAppCard;
    useGetAppCard(variables: Types.GetAppCardVariables, params?: SpaceQueryWatchParameters): Types.GetAppCard | null {;
        return this.useQuery('GetAppCard', variables, params);
    }
    useEnableBanner(variables: Types.EnableBannerVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.EnableBanner | null;
    useEnableBanner(variables: Types.EnableBannerVariables, params?: SpaceQueryWatchParameters): Types.EnableBanner;
    useEnableBanner(variables: Types.EnableBannerVariables, params?: SpaceQueryWatchParameters): Types.EnableBanner | null {;
        return this.useQuery('EnableBanner', variables, params);
    }
    useDisableBanner(variables: Types.DisableBannerVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.DisableBanner | null;
    useDisableBanner(variables: Types.DisableBannerVariables, params?: SpaceQueryWatchParameters): Types.DisableBanner;
    useDisableBanner(variables: Types.DisableBannerVariables, params?: SpaceQueryWatchParameters): Types.DisableBanner | null {;
        return this.useQuery('DisableBanner', variables, params);
    }
    useGetAppSettings(variables: Types.GetAppSettingsVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.GetAppSettings | null;
    useGetAppSettings(variables: Types.GetAppSettingsVariables, params?: SpaceQueryWatchParameters): Types.GetAppSettings;
    useGetAppSettings(variables: Types.GetAppSettingsVariables, params?: SpaceQueryWatchParameters): Types.GetAppSettings | null {;
        return this.useQuery('GetAppSettings', variables, params);
    }
    useAppStat(variables: Types.AppStatVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AppStat | null;
    useAppStat(variables: Types.AppStatVariables, params?: SpaceQueryWatchParameters): Types.AppStat;
    useAppStat(variables: Types.AppStatVariables, params?: SpaceQueryWatchParameters): Types.AppStat | null {;
        return this.useQuery('AppStat', variables, params);
    }
    useGetSiteScreenshot(variables: Types.GetSiteScreenshotVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.GetSiteScreenshot | null;
    useGetSiteScreenshot(variables: Types.GetSiteScreenshotVariables, params?: SpaceQueryWatchParameters): Types.GetSiteScreenshot;
    useGetSiteScreenshot(variables: Types.GetSiteScreenshotVariables, params?: SpaceQueryWatchParameters): Types.GetSiteScreenshot | null {;
        return this.useQuery('GetSiteScreenshot', variables, params);
    }
    useGenerateSharLink(variables: Types.GenerateSharLinkVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.GenerateSharLink | null;
    useGenerateSharLink(variables: Types.GenerateSharLinkVariables, params?: SpaceQueryWatchParameters): Types.GenerateSharLink;
    useGenerateSharLink(variables: Types.GenerateSharLinkVariables, params?: SpaceQueryWatchParameters): Types.GenerateSharLink | null {;
        return this.useQuery('GenerateSharLink', variables, params);
    }
    useScreenSettings(variables: Types.ScreenSettingsVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.ScreenSettings | null;
    useScreenSettings(variables: Types.ScreenSettingsVariables, params?: SpaceQueryWatchParameters): Types.ScreenSettings;
    useScreenSettings(variables: Types.ScreenSettingsVariables, params?: SpaceQueryWatchParameters): Types.ScreenSettings | null {;
        return this.useQuery('ScreenSettings', variables, params);
    }
    useAppVersionsPage(variables: Types.AppVersionsPageVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AppVersionsPage | null;
    useAppVersionsPage(variables: Types.AppVersionsPageVariables, params?: SpaceQueryWatchParameters): Types.AppVersionsPage;
    useAppVersionsPage(variables: Types.AppVersionsPageVariables, params?: SpaceQueryWatchParameters): Types.AppVersionsPage | null {;
        return this.useQuery('AppVersionsPage', variables, params);
    }
    usePublishedCardList(params: SpaceQueryWatchParameters & { suspense: false }): Types.PublishedCardList | null;
    usePublishedCardList(params?: SpaceQueryWatchParameters): Types.PublishedCardList;
    usePublishedCardList(params?: SpaceQueryWatchParameters): Types.PublishedCardList | null {;
        return this.useQuery('PublishedCardList', undefined, params);
    }
    useMyShop(params: SpaceQueryWatchParameters & { suspense: false }): Types.MyShop | null;
    useMyShop(params?: SpaceQueryWatchParameters): Types.MyShop;
    useMyShop(params?: SpaceQueryWatchParameters): Types.MyShop | null {;
        return this.useQuery('MyShop', undefined, params);
    }
    usePages(params: SpaceQueryWatchParameters & { suspense: false }): Types.Pages | null;
    usePages(params?: SpaceQueryWatchParameters): Types.Pages;
    usePages(params?: SpaceQueryWatchParameters): Types.Pages | null {;
        return this.useQuery('Pages', undefined, params);
    }
    usePage(variables: Types.PageVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.Page | null;
    usePage(variables: Types.PageVariables, params?: SpaceQueryWatchParameters): Types.Page;
    usePage(variables: Types.PageVariables, params?: SpaceQueryWatchParameters): Types.Page | null {;
        return this.useQuery('Page', variables, params);
    }
    useBlurHash(variables: Types.BlurHashVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.BlurHash | null;
    useBlurHash(variables: Types.BlurHashVariables, params?: SpaceQueryWatchParameters): Types.BlurHash;
    useBlurHash(variables: Types.BlurHashVariables, params?: SpaceQueryWatchParameters): Types.BlurHash | null {;
        return this.useQuery('BlurHash', variables, params);
    }
    useListPricing(variables: Types.ListPricingVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.ListPricing | null;
    useListPricing(variables: Types.ListPricingVariables, params?: SpaceQueryWatchParameters): Types.ListPricing;
    useListPricing(variables: Types.ListPricingVariables, params?: SpaceQueryWatchParameters): Types.ListPricing | null {;
        return this.useQuery('ListPricing', variables, params);
    }
    useDefaultPricing(params: SpaceQueryWatchParameters & { suspense: false }): Types.DefaultPricing | null;
    useDefaultPricing(params?: SpaceQueryWatchParameters): Types.DefaultPricing;
    useDefaultPricing(params?: SpaceQueryWatchParameters): Types.DefaultPricing | null {;
        return this.useQuery('DefaultPricing', undefined, params);
    }
    useProducts(variables: Types.ProductsVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.Products | null;
    useProducts(variables: Types.ProductsVariables, params?: SpaceQueryWatchParameters): Types.Products;
    useProducts(variables: Types.ProductsVariables, params?: SpaceQueryWatchParameters): Types.Products | null {;
        return this.useQuery('Products', variables, params);
    }
    useProduct(variables: Types.ProductVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.Product | null;
    useProduct(variables: Types.ProductVariables, params?: SpaceQueryWatchParameters): Types.Product;
    useProduct(variables: Types.ProductVariables, params?: SpaceQueryWatchParameters): Types.Product | null {;
        return this.useQuery('Product', variables, params);
    }
    useProductStatus(params: SpaceQueryWatchParameters & { suspense: false }): Types.ProductStatus | null;
    useProductStatus(params?: SpaceQueryWatchParameters): Types.ProductStatus;
    useProductStatus(params?: SpaceQueryWatchParameters): Types.ProductStatus | null {;
        return this.useQuery('ProductStatus', undefined, params);
    }
    useSearchListProducts(variables: Types.SearchListProductsVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.SearchListProducts | null;
    useSearchListProducts(variables: Types.SearchListProductsVariables, params?: SpaceQueryWatchParameters): Types.SearchListProducts;
    useSearchListProducts(variables: Types.SearchListProductsVariables, params?: SpaceQueryWatchParameters): Types.SearchListProducts | null {;
        return this.useQuery('SearchListProducts', variables, params);
    }
    useListProducts(variables: Types.ListProductsVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.ListProducts | null;
    useListProducts(variables: Types.ListProductsVariables, params?: SpaceQueryWatchParameters): Types.ListProducts;
    useListProducts(variables: Types.ListProductsVariables, params?: SpaceQueryWatchParameters): Types.ListProducts | null {;
        return this.useQuery('ListProducts', variables, params);
    }
    useCollections(params: SpaceQueryWatchParameters & { suspense: false }): Types.Collections | null;
    useCollections(params?: SpaceQueryWatchParameters): Types.Collections;
    useCollections(params?: SpaceQueryWatchParameters): Types.Collections | null {;
        return this.useQuery('Collections', undefined, params);
    }
    useCollection(variables: Types.CollectionVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.Collection | null;
    useCollection(variables: Types.CollectionVariables, params?: SpaceQueryWatchParameters): Types.Collection;
    useCollection(variables: Types.CollectionVariables, params?: SpaceQueryWatchParameters): Types.Collection | null {;
        return this.useQuery('Collection', variables, params);
    }
    useListCollections(variables: Types.ListCollectionsVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.ListCollections | null;
    useListCollections(variables: Types.ListCollectionsVariables, params?: SpaceQueryWatchParameters): Types.ListCollections;
    useListCollections(variables: Types.ListCollectionsVariables, params?: SpaceQueryWatchParameters): Types.ListCollections | null {;
        return this.useQuery('ListCollections', variables, params);
    }
    useListPushMailing(variables: Types.ListPushMailingVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.ListPushMailing | null;
    useListPushMailing(variables: Types.ListPushMailingVariables, params?: SpaceQueryWatchParameters): Types.ListPushMailing;
    useListPushMailing(variables: Types.ListPushMailingVariables, params?: SpaceQueryWatchParameters): Types.ListPushMailing | null {;
        return this.useQuery('ListPushMailing', variables, params);
    }
    usePushMailing(variables: Types.PushMailingVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.PushMailing | null;
    usePushMailing(variables: Types.PushMailingVariables, params?: SpaceQueryWatchParameters): Types.PushMailing;
    usePushMailing(variables: Types.PushMailingVariables, params?: SpaceQueryWatchParameters): Types.PushMailing | null {;
        return this.useQuery('PushMailing', variables, params);
    }
    useSettings(params: SpaceQueryWatchParameters & { suspense: false }): Types.Settings | null;
    useSettings(params?: SpaceQueryWatchParameters): Types.Settings;
    useSettings(params?: SpaceQueryWatchParameters): Types.Settings | null {;
        return this.useQuery('Settings', undefined, params);
    }
    useAdListings(params: SpaceQueryWatchParameters & { suspense: false }): Types.AdListings | null;
    useAdListings(params?: SpaceQueryWatchParameters): Types.AdListings;
    useAdListings(params?: SpaceQueryWatchParameters): Types.AdListings | null {;
        return this.useQuery('AdListings', undefined, params);
    }
    useAdListingsPerformance(variables: Types.AdListingsPerformanceVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdListingsPerformance | null;
    useAdListingsPerformance(variables: Types.AdListingsPerformanceVariables, params?: SpaceQueryWatchParameters): Types.AdListingsPerformance;
    useAdListingsPerformance(variables: Types.AdListingsPerformanceVariables, params?: SpaceQueryWatchParameters): Types.AdListingsPerformance | null {;
        return this.useQuery('AdListingsPerformance', variables, params);
    }
    useListAdListings(variables: Types.ListAdListingsVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.ListAdListings | null;
    useListAdListings(variables: Types.ListAdListingsVariables, params?: SpaceQueryWatchParameters): Types.ListAdListings;
    useListAdListings(variables: Types.ListAdListingsVariables, params?: SpaceQueryWatchParameters): Types.ListAdListings | null {;
        return this.useQuery('ListAdListings', variables, params);
    }
    useListCommunityListings(variables: Types.ListCommunityListingsVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.ListCommunityListings | null;
    useListCommunityListings(variables: Types.ListCommunityListingsVariables, params?: SpaceQueryWatchParameters): Types.ListCommunityListings;
    useListCommunityListings(variables: Types.ListCommunityListingsVariables, params?: SpaceQueryWatchParameters): Types.ListCommunityListings | null {;
        return this.useQuery('ListCommunityListings', variables, params);
    }
    useListAdListingsItems(variables: Types.ListAdListingsItemsVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.ListAdListingsItems | null;
    useListAdListingsItems(variables: Types.ListAdListingsItemsVariables, params?: SpaceQueryWatchParameters): Types.ListAdListingsItems;
    useListAdListingsItems(variables: Types.ListAdListingsItemsVariables, params?: SpaceQueryWatchParameters): Types.ListAdListingsItems | null {;
        return this.useQuery('ListAdListingsItems', variables, params);
    }
    useAdListingContentPreview(variables: Types.AdListingContentPreviewVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdListingContentPreview | null;
    useAdListingContentPreview(variables: Types.AdListingContentPreviewVariables, params?: SpaceQueryWatchParameters): Types.AdListingContentPreview;
    useAdListingContentPreview(variables: Types.AdListingContentPreviewVariables, params?: SpaceQueryWatchParameters): Types.AdListingContentPreview | null {;
        return this.useQuery('AdListingContentPreview', variables, params);
    }
    usePreviewListingContent(variables: Types.PreviewListingContentVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.PreviewListingContent | null;
    usePreviewListingContent(variables: Types.PreviewListingContentVariables, params?: SpaceQueryWatchParameters): Types.PreviewListingContent;
    usePreviewListingContent(variables: Types.PreviewListingContentVariables, params?: SpaceQueryWatchParameters): Types.PreviewListingContent | null {;
        return this.useQuery('PreviewListingContent', variables, params);
    }
    useAdListingSummury(params: SpaceQueryWatchParameters & { suspense: false }): Types.AdListingSummury | null;
    useAdListingSummury(params?: SpaceQueryWatchParameters): Types.AdListingSummury;
    useAdListingSummury(params?: SpaceQueryWatchParameters): Types.AdListingSummury | null {;
        return this.useQuery('AdListingSummury', undefined, params);
    }
    useIncomingRequestsCount(params: SpaceQueryWatchParameters & { suspense: false }): Types.IncomingRequestsCount | null;
    useIncomingRequestsCount(params?: SpaceQueryWatchParameters): Types.IncomingRequestsCount;
    useIncomingRequestsCount(params?: SpaceQueryWatchParameters): Types.IncomingRequestsCount | null {;
        return this.useQuery('IncomingRequestsCount', undefined, params);
    }
    useFriendlyStores(params: SpaceQueryWatchParameters & { suspense: false }): Types.FriendlyStores | null;
    useFriendlyStores(params?: SpaceQueryWatchParameters): Types.FriendlyStores;
    useFriendlyStores(params?: SpaceQueryWatchParameters): Types.FriendlyStores | null {;
        return this.useQuery('FriendlyStores', undefined, params);
    }
    useListCommunityStores(variables: Types.ListCommunityStoresVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.ListCommunityStores | null;
    useListCommunityStores(variables: Types.ListCommunityStoresVariables, params?: SpaceQueryWatchParameters): Types.ListCommunityStores;
    useListCommunityStores(variables: Types.ListCommunityStoresVariables, params?: SpaceQueryWatchParameters): Types.ListCommunityStores | null {;
        return this.useQuery('ListCommunityStores', variables, params);
    }
    useListPointsTransactions(variables: Types.ListPointsTransactionsVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.ListPointsTransactions | null;
    useListPointsTransactions(variables: Types.ListPointsTransactionsVariables, params?: SpaceQueryWatchParameters): Types.ListPointsTransactions;
    useListPointsTransactions(variables: Types.ListPointsTransactionsVariables, params?: SpaceQueryWatchParameters): Types.ListPointsTransactions | null {;
        return this.useQuery('ListPointsTransactions', variables, params);
    }
    useShowAdListingContent(variables: Types.ShowAdListingContentVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.ShowAdListingContent | null;
    useShowAdListingContent(variables: Types.ShowAdListingContentVariables, params?: SpaceQueryWatchParameters): Types.ShowAdListingContent;
    useShowAdListingContent(variables: Types.ShowAdListingContentVariables, params?: SpaceQueryWatchParameters): Types.ShowAdListingContent | null {;
        return this.useQuery('ShowAdListingContent', variables, params);
    }
    useExchangeEarnings(params: SpaceQueryWatchParameters & { suspense: false }): Types.ExchangeEarnings | null;
    useExchangeEarnings(params?: SpaceQueryWatchParameters): Types.ExchangeEarnings;
    useExchangeEarnings(params?: SpaceQueryWatchParameters): Types.ExchangeEarnings | null {;
        return this.useQuery('ExchangeEarnings', undefined, params);
    }
    useGetCoinsPrice(variables: Types.GetCoinsPriceVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.GetCoinsPrice | null;
    useGetCoinsPrice(variables: Types.GetCoinsPriceVariables, params?: SpaceQueryWatchParameters): Types.GetCoinsPrice;
    useGetCoinsPrice(variables: Types.GetCoinsPriceVariables, params?: SpaceQueryWatchParameters): Types.GetCoinsPrice | null {;
        return this.useQuery('GetCoinsPrice', variables, params);
    }
    mutateCreateAccount(variables: Types.CreateAccountVariables, params?: MutationParameters): Promise<Types.CreateAccount> {
        return this.mutate('CreateAccount', variables, params)
    }
    mutateAccountReset(variables: Types.AccountResetVariables, params?: MutationParameters): Promise<Types.AccountReset> {
        return this.mutate('AccountReset', variables, params)
    }
    mutateSetupAccount(variables: Types.SetupAccountVariables, params?: MutationParameters): Promise<Types.SetupAccount> {
        return this.mutate('SetupAccount', variables, params)
    }
    mutateRewardCoins(variables: Types.RewardCoinsVariables, params?: MutationParameters): Promise<Types.RewardCoins> {
        return this.mutate('RewardCoins', variables, params)
    }
    mutateChargeCoins(variables: Types.ChargeCoinsVariables, params?: MutationParameters): Promise<Types.ChargeCoins> {
        return this.mutate('ChargeCoins', variables, params)
    }
    mutateCreateOrUpdateContact(variables: Types.CreateOrUpdateContactVariables, params?: MutationParameters): Promise<Types.CreateOrUpdateContact> {
        return this.mutate('CreateOrUpdateContact', variables, params)
    }
    mutateAdminUpdatePricing(variables: Types.AdminUpdatePricingVariables, params?: MutationParameters): Promise<Types.AdminUpdatePricing> {
        return this.mutate('AdminUpdatePricing', variables, params)
    }
    mutateAdminGenerateAccountToken(variables: Types.AdminGenerateAccountTokenVariables, params?: MutationParameters): Promise<Types.AdminGenerateAccountToken> {
        return this.mutate('AdminGenerateAccountToken', variables, params)
    }
    mutateAdminPublishAppCard(variables: Types.AdminPublishAppCardVariables, params?: MutationParameters): Promise<Types.AdminPublishAppCard> {
        return this.mutate('AdminPublishAppCard', variables, params)
    }
    mutateAdminMakeAccountTrial(variables: Types.AdminMakeAccountTrialVariables, params?: MutationParameters): Promise<Types.AdminMakeAccountTrial> {
        return this.mutate('AdminMakeAccountTrial', variables, params)
    }
    mutateCreatePreviewSession(variables: Types.CreatePreviewSessionVariables, params?: MutationParameters): Promise<Types.CreatePreviewSession> {
        return this.mutate('CreatePreviewSession', variables, params)
    }
    mutateClosePreviewSession(variables: Types.ClosePreviewSessionVariables, params?: MutationParameters): Promise<Types.ClosePreviewSession> {
        return this.mutate('ClosePreviewSession', variables, params)
    }
    mutateUpdateApp(variables: Types.UpdateAppVariables, params?: MutationParameters): Promise<Types.UpdateApp> {
        return this.mutate('UpdateApp', variables, params)
    }
    mutateCreateApp(variables: Types.CreateAppVariables, params?: MutationParameters): Promise<Types.CreateApp> {
        return this.mutate('CreateApp', variables, params)
    }
    mutateRenameApp(variables: Types.RenameAppVariables, params?: MutationParameters): Promise<Types.RenameApp> {
        return this.mutate('RenameApp', variables, params)
    }
    mutateArchiveApp(variables: Types.ArchiveAppVariables, params?: MutationParameters): Promise<Types.ArchiveApp> {
        return this.mutate('ArchiveApp', variables, params)
    }
    mutateUpdatePreviewSessionUrl(variables: Types.UpdatePreviewSessionUrlVariables, params?: MutationParameters): Promise<Types.UpdatePreviewSessionUrl> {
        return this.mutate('UpdatePreviewSessionUrl', variables, params)
    }
    mutateUpdateAppIcon(variables: Types.UpdateAppIconVariables, params?: MutationParameters): Promise<Types.UpdateAppIcon> {
        return this.mutate('UpdateAppIcon', variables, params)
    }
    mutateUpdateAppSplash(variables: Types.UpdateAppSplashVariables, params?: MutationParameters): Promise<Types.UpdateAppSplash> {
        return this.mutate('UpdateAppSplash', variables, params)
    }
    mutateShareMall(variables: Types.ShareMallVariables, params?: MutationParameters): Promise<Types.ShareMall> {
        return this.mutate('ShareMall', variables, params)
    }
    mutateUpdateAppCard(variables: Types.UpdateAppCardVariables, params?: MutationParameters): Promise<Types.UpdateAppCard> {
        return this.mutate('UpdateAppCard', variables, params)
    }
    mutateSubmitAppCard(variables: Types.SubmitAppCardVariables, params?: MutationParameters): Promise<Types.SubmitAppCard> {
        return this.mutate('SubmitAppCard', variables, params)
    }
    mutateDraftAppCard(variables: Types.DraftAppCardVariables, params?: MutationParameters): Promise<Types.DraftAppCard> {
        return this.mutate('DraftAppCard', variables, params)
    }
    mutateDeactivateAppCard(variables: Types.DeactivateAppCardVariables, params?: MutationParameters): Promise<Types.DeactivateAppCard> {
        return this.mutate('DeactivateAppCard', variables, params)
    }
    mutateSyncCatalog(params?: MutationParameters): Promise<Types.SyncCatalog> {
        return this.mutate('SyncCatalog', undefined, params)
    }
    mutateUpdateBannerParams(variables: Types.UpdateBannerParamsVariables, params?: MutationParameters): Promise<Types.UpdateBannerParams> {
        return this.mutate('UpdateBannerParams', variables, params)
    }
    mutateSetFreeTraffic(variables: Types.SetFreeTrafficVariables, params?: MutationParameters): Promise<Types.SetFreeTraffic> {
        return this.mutate('SetFreeTraffic', variables, params)
    }
    mutateUpdateScreenSettings(variables: Types.UpdateScreenSettingsVariables, params?: MutationParameters): Promise<Types.UpdateScreenSettings> {
        return this.mutate('UpdateScreenSettings', variables, params)
    }
    mutateCreateNewAppVersion(variables: Types.CreateNewAppVersionVariables, params?: MutationParameters): Promise<Types.CreateNewAppVersion> {
        return this.mutate('CreateNewAppVersion', variables, params)
    }
    mutateCreatePage(variables: Types.CreatePageVariables, params?: MutationParameters): Promise<Types.CreatePage> {
        return this.mutate('CreatePage', variables, params)
    }
    mutateUpdatePage(variables: Types.UpdatePageVariables, params?: MutationParameters): Promise<Types.UpdatePage> {
        return this.mutate('UpdatePage', variables, params)
    }
    mutatePublishPage(variables: Types.PublishPageVariables, params?: MutationParameters): Promise<Types.PublishPage> {
        return this.mutate('PublishPage', variables, params)
    }
    mutateDeletePage(variables: Types.DeletePageVariables, params?: MutationParameters): Promise<Types.DeletePage> {
        return this.mutate('DeletePage', variables, params)
    }
    mutateSubscribeToPricing(variables: Types.SubscribeToPricingVariables, params?: MutationParameters): Promise<Types.SubscribeToPricing> {
        return this.mutate('SubscribeToPricing', variables, params)
    }
    mutateConfirmBilling(params?: MutationParameters): Promise<Types.ConfirmBilling> {
        return this.mutate('ConfirmBilling', undefined, params)
    }
    mutateCreatePushMailing(variables: Types.CreatePushMailingVariables, params?: MutationParameters): Promise<Types.CreatePushMailing> {
        return this.mutate('CreatePushMailing', variables, params)
    }
    mutateSendPushMailing(variables: Types.SendPushMailingVariables, params?: MutationParameters): Promise<Types.SendPushMailing> {
        return this.mutate('SendPushMailing', variables, params)
    }
    mutateStopPushMailing(variables: Types.StopPushMailingVariables, params?: MutationParameters): Promise<Types.StopPushMailing> {
        return this.mutate('StopPushMailing', variables, params)
    }
    mutateSaveAppStore(variables: Types.SaveAppStoreVariables, params?: MutationParameters): Promise<Types.SaveAppStore> {
        return this.mutate('SaveAppStore', variables, params)
    }
    mutateSaveGooglePlay(variables: Types.SaveGooglePlayVariables, params?: MutationParameters): Promise<Types.SaveGooglePlay> {
        return this.mutate('SaveGooglePlay', variables, params)
    }
    mutateAddOrUpdateAdListing(variables: Types.AddOrUpdateAdListingVariables, params?: MutationParameters): Promise<Types.AddOrUpdateAdListing> {
        return this.mutate('AddOrUpdateAdListing', variables, params)
    }
    mutateCreateAdListing(variables: Types.CreateAdListingVariables, params?: MutationParameters): Promise<Types.CreateAdListing> {
        return this.mutate('CreateAdListing', variables, params)
    }
    mutateDeactivateAdLising(variables: Types.DeactivateAdLisingVariables, params?: MutationParameters): Promise<Types.DeactivateAdLising> {
        return this.mutate('DeactivateAdLising', variables, params)
    }
    mutateAcceptCommunityListing(variables: Types.AcceptCommunityListingVariables, params?: MutationParameters): Promise<Types.AcceptCommunityListing> {
        return this.mutate('AcceptCommunityListing', variables, params)
    }
    mutateAcceptTargetedListing(variables: Types.AcceptTargetedListingVariables, params?: MutationParameters): Promise<Types.AcceptTargetedListing> {
        return this.mutate('AcceptTargetedListing', variables, params)
    }
    mutateStopAdListingItem(variables: Types.StopAdListingItemVariables, params?: MutationParameters): Promise<Types.StopAdListingItem> {
        return this.mutate('StopAdListingItem', variables, params)
    }
    mutateAddOrUpdateFriendlyStore(variables: Types.AddOrUpdateFriendlyStoreVariables, params?: MutationParameters): Promise<Types.AddOrUpdateFriendlyStore> {
        return this.mutate('AddOrUpdateFriendlyStore', variables, params)
    }
    mutateCreateCoinCharge(variables: Types.CreateCoinChargeVariables, params?: MutationParameters): Promise<Types.CreateCoinCharge> {
        return this.mutate('CreateCoinCharge', variables, params)
    }
    mutateConfitmCoinCharge(variables: Types.ConfitmCoinChargeVariables, params?: MutationParameters): Promise<Types.ConfitmCoinCharge> {
        return this.mutate('ConfitmCoinCharge', variables, params)
    }
    subscribeWatchAppEmulatorConfig(variables: Types.WatchAppEmulatorConfigVariables, handler: GraphqlSubscriptionHandler<Types.WatchAppEmulatorConfig>, params?: SubscriptionParameters): GraphqlActiveSubscription<Types.WatchAppEmulatorConfig> {
        return this.subscribe(handler, 'WatchAppEmulatorConfig', variables, params);
    }
}
