import { Icon, Stack, TextStyle } from "@shopify/polaris";
import { CategoriesMajor } from "@shopify/polaris-icons";
import React from "react";
import { FlexAlignType, View } from "react-native";
import { IconWrapper } from "../../components/Icon/IconView/Icons";
import { ContentView, ContentText, ContentScrollView } from "../../content/components";
import { mapTextAlign } from "../../utils/textUtils";
import { TemplateWithVariables } from "../knobs/TemplateWithVariables";
import { FontWeightType, ResizeMode, TextAlignType } from "../knobs/types";
import { ConfigBlock } from "./BlockTypes";
import { ProductBannerItem } from "./components/product/ProductBannerItem";
import { ProductCircleListItem } from "./components/product/ProductCircleListItem";
import { ProductGridItem } from "./components/product/ProductGridItem";
import { ProductHorizontalListItem } from "./components/product/ProductHorizontalListItem";
import { ProductListItem } from "./components/product/ProductListItem";

const Variables = {
    products: {
        type: 'products' as const,
        defaultValue: [] as any[],
        description: 'Add products to display',
    },
    blockTitle: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: '',
            textAlign: TextAlignType.Start,
            textColor: '#000',
            textShadow: false,
            showCaption: true,
            fontWeight: FontWeightType.Normal
        },
        description: 'Blocks caption',
        placeholder: 'Enter caption',
        disableAlpha: true,
    },
    blurIntencity: {
        type: 'range' as const,
        defaultValue: 50,
        description: 'Captions background intencity',
        min: 0,
        max: 100
    },
    viewType: {
        type: 'view_type' as const,
        defaultValue: 'list',
        description: 'View type',
    },
    titleProps: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: '',
            textAlign: TextAlignType.Start,
            textColor: '#FFF',
            textShadow: false,
            showCaption: true,
            fontWeight: FontWeightType.Normal
        },
        description: 'Products text parameters',
        placeholder: 'Enter caption placeholder',
        disableAlpha: true,
        noValue: true
    },
    imageResizeMode: {
        type: 'resize_mode' as const,
        defaulValue: ResizeMode.Cover,
        description: 'Image resize mode'
    },
    showPrice: {
        type: 'boolean' as const,
        defaultValue: false,
        description: 'Show product\'s price',
    },
    placeHolderImage: {
        type: 'blurhash_file' as const,
        defaultValue: { src: '', blurhash: null, resizeMode: null },
        description: 'Product\'s image placeholder',
        clearable: true,
        isAdvanced: true,
    },
    borderRadius: {
        type: 'range' as const,
        defaultValue: 16,
        description: 'Border radius',
        min: 0,
        max: 100,
        isAdvanced: true,
    },
    viewsHeight: {
        type: 'range' as const,
        defaultValue: 172,
        description: 'Height of cards',
        min: 0,
        max: 500,
    },
    showTextBackground: {
        type: 'boolean' as const,
        defaultValue: true,
        description: 'Show text background',
        isAdvanced: true,
    },
    // showShadow: {
    //     type: 'boolean' as const,
    //     defaultValue: false,
    //     description: 'Enable banner shadow',
    //     isAdvanced: true,
    // },
    viewsMargins: {
        type: 'range' as const,
        defaultValue: 16,
        description: 'Margins between cards on block',
        min: 0,
        max: 100,
        isAdvanced: true,
    },
    marginCoordinates: {
        type: 'coordinates' as const,
        defaultValue: { left: 10, right: 10, top: 8, bottom: 0 },
        description: 'margin',
        min: 0,
        isAdvanced: true,
    },
    paddingCoordinates: {
        type: 'coordinates' as const,
        defaultValue: { left: 0, right: 0, top: 0, bottom: 0 },
        description: 'padding',
        min: 0,
        isAdvanced: true,
    },
}

const ProductsList = TemplateWithVariables(Variables, ({
    products,
    viewType,
    viewsHeight,
    viewsMargins,
    placeHolderImage,
    blockTitle,
    borderRadius,
    // showShadow, 
    showTextBackground,
    titleProps,
    marginCoordinates,
    paddingCoordinates,
    imageResizeMode,
    blurIntencity,
    showPrice
}) => {

    let views: any[] = [];

    switch (viewType) {
        case 'list': {
            views = products.map((product) => {
                return (<ProductListItem product={product} textColor={blockTitle.textColor} />);
            });
            break;
        }
        case 'circles': {
            views = products.map((product) => {
                return (
                    <ProductCircleListItem
                        id={product.id}
                        textColor={titleProps.textColor}
                        viewsHeight={viewsHeight}
                        // showShadow={showShadow}
                        showPrice={showPrice}
                        textAlign={titleProps.textAlign as TextAlignType}
                        showTextBackground={showTextBackground}
                        showCaption={titleProps.showCaption}
                        placeholderImage={placeHolderImage}
                        viewsMargins={viewsMargins}
                        fontWeight={titleProps.fontWeight}
                        imageResizeMode={imageResizeMode as ResizeMode}
                        blurIntencity={blurIntencity}
                    />
                )
            });
            break;
        }

        case 'blocks': {
            const grid: any[] = [[], []]
            products.forEach((product, index) => {
                const colNumber = index % 2;
                grid[colNumber].push(
                    <ProductGridItem
                        id={product.id}
                        colNumber={colNumber}
                        borderRadius={borderRadius}
                        viewsHeight={viewsHeight}
                        viewsMargins={viewsMargins}
                        showCaption={titleProps.showCaption}
                        textAlign={titleProps.textAlign}
                        showTextBackground={showTextBackground}
                        // showShadow={showShadow}
                        textColor={titleProps.textColor}
                        placeholderImage={placeHolderImage}
                        fontWeight={titleProps.fontWeight}
                        imageResizeMode={imageResizeMode as ResizeMode}
                        blurIntencity={blurIntencity}
                        showPrice={showPrice}
                    />
                )
            })

            views = [
                <ContentView style={{ flexDirection: 'row' }}>
                    <ContentView style={{ width: '50%' }}>
                        {grid[0]}
                    </ContentView>
                    <ContentView style={{ width: '50%' }}>
                        {grid[1]}
                    </ContentView>
                </ContentView>
            ]
            break;
        }

        case 'banners': {
            views = products.map((product) => {
                return (
                    <ProductBannerItem
                        id={product.id}
                        viewsHeight={viewsHeight}
                        borderRadius={borderRadius}
                        viewsMargins={viewsMargins}
                        showCaption={titleProps.showCaption}
                        textAlign={titleProps.textAlign}
                        showTextBackground={showTextBackground}
                        // showShadow={showShadow}
                        textColor={titleProps.textColor}
                        placeholderImage={placeHolderImage}
                        imageResizeMode={imageResizeMode as ResizeMode}
                        blurIntencity={blurIntencity}
                        showPrice={showPrice}
                    />
                );
            })
            break;
        }

        case 'horizontal_list': {
            views = products.map((product) => {
                return (
                    <ProductHorizontalListItem
                        id={product.id}
                        viewsHeight={viewsHeight}
                        borderRadius={borderRadius}
                        viewsMargins={viewsMargins}
                        showCaption={titleProps.showCaption}
                        textAlign={titleProps.textAlign}
                        showTextBackground={showTextBackground}
                        // showShadow={showShadow}
                        textColor={titleProps.textColor}
                        placeholderImage={placeHolderImage}
                        imageResizeMode={imageResizeMode as ResizeMode}
                        blurIntencity={blurIntencity}
                        showPrice={showPrice}
                    />
                );
            })
            break;
        }
    }

    return (
        <ContentView
            style={{
                flexDirection: 'column',
                borderRadius: borderRadius,
                paddingLeft: paddingCoordinates.left,
                paddingRight: paddingCoordinates.right,
                paddingTop: paddingCoordinates.top,
                paddingBottom: paddingCoordinates.bottom,
                marginBottom: marginCoordinates.bottom,
                marginTop: marginCoordinates.top,
                marginLeft: marginCoordinates.left,
                marginRight: marginCoordinates.right,
                flexShrink: 1,
            }}
        >
            {blockTitle.showCaption && (
                <ContentText
                    style={{
                        color: blockTitle.textColor, fontSize: 18,
                        marginBottom: 4, marginLeft: 4,
                        alignSelf: blockTitle.textAlign as FlexAlignType,
                        fontWeight: blockTitle.fontWeight,
                        textAlign: mapTextAlign(blockTitle.textAlign)
                    }}
                    smartColor
                >
                    {blockTitle.value}
                </ContentText>
            )}
            <ContentScrollView
                horizontal={viewType === 'circles' || viewType === 'horizontal_list'}
                style={{ height: viewType === 'circles' ? viewsHeight : undefined }}
            >
                {(!views || views.length === 0) && (
                    <ContentView style={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                        <ContentText style={{
                            fontWeight: '700',
                            color: 'red'
                        }}>
                            {'No products selected'}
                        </ContentText>
                    </ContentView>
                )}
                {views}
            </ContentScrollView>
        </ContentView >
    )
});

export default {
    Preview: () => <Stack alignment="center" distribution="center">
        <Stack.Item>
            <IconWrapper>
                <Icon source={CategoriesMajor} />
            </IconWrapper>
        </Stack.Item>
        <Stack.Item fill>
            Products List
        </Stack.Item>
    </Stack>,
    RenderBlock: ProductsList,
    Icon: CategoriesMajor,
    Name:'Products List'
} as ConfigBlock