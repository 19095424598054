import React from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import { TypographyStyle, TypographyWeightsStyle } from '../../../components/Typography/commonTypography';
import { SelectOption } from '../../../components/StyledSelect/StyledSelect';
import { FormSelectChips } from '../../../components/Form/Components/FormSelectChips';
import { useFormContext } from 'react-hook-form';
import { Heading, InlineError, Stack, TextContainer } from '@shopify/polaris';
import { useClient } from '../../../api/useClient';

const options: SelectOption<string>[] = [
    { value: 'Beauty', label: 'Beauty' },
    { value: 'Clothing', label: 'Clothing' },
    { value: 'Electronics', label: 'Electronics' },
    { value: 'Furniture', label: 'Furniture' },
    { value: 'Handicrafts', label: 'Handicrafts' },
    { value: 'Jewelry', label: 'Jewelry' },
    { value: 'Painting', label: 'Painting' },
    { value: 'Photography', label: 'Photography' },
    { value: 'Restaurants', label: 'Restaurants' },
    { value: 'Groceries', label: 'Groceries' },
    { value: 'OtherFoodDrink', label: 'Other food & drink' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Toys', label: 'Toys' },
    { value: 'Services', label: 'Services' },
    { value: 'VirtualServices', label: 'Virtual services' },
    { value: 'Other', label: 'Other' },
]

const style = StyleSheet.create({
    title: {
        marginBottom: 3
    },
    caption: {
        marginBottom: 8
    },
    captionError: {
        color: 'red'
    },
    borderError: {
        borderWidth: 1,
        borderRadius: 4,
        borderColor: 'red',
        padding: 4,
        backgroundColor: '#fff4f4'
    }
});

const validate = (data?: []) => !!data?.length

export const WhichIndustries = React.memo(({
    hideCaption,
    label,
    errorMessage
}: {
    hideCaption?: boolean,
    label?: string,
    errorMessage?: string
}) => {
    const context = useFormContext<{ industries: string[] }>()
    const error = context.errors['industries']
    const client = useClient();
    const industries = client.useSurveyIndustryList().surveyIndustryList;
    const options: SelectOption<string>[] = industries.map((item) => {
        return { value: item.id, label: item.title }
    });

    if (hideCaption) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {label && <label style={{ marginBottom: 4 }}>{label}</label>}
                <View>
                    <FormSelectChips
                        name={'industries'}
                        defaultValue={[]}
                        rules={{ ...{ validate }, ...{ required: true } }}
                        options={options}
                    />
                </View>
                {errorMessage && <InlineError message={errorMessage} fieldID="industries-field" />}
            </div>
        );
    }
    return (
        <View>
            <View style={{ marginBottom: 8 }}>
                <TextContainer>
                    <Heading>
                        🏢 &nbsp; In which industry are you operating?
                    </Heading>
                </TextContainer>
            </View>
            <Text style={[TypographyStyle.caption, style.caption, error && style.captionError]}>
                {label ? label : 'Please, select all that apply'}
            </Text>
            <FormSelectChips
                name={'industries'}
                defaultValue={[]}
                rules={{ ...{ validate }, ...{ required: true } }}
                options={options}
            />
        </View>
    )
})