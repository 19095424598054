import { Page } from '@shopify/polaris';
import React, { useMemo } from 'react';
import { useClient } from '../api/useClient';
import { useHistory } from 'react-router';

export const ShopApp = React.memo(() => {
    const apps = useClient().useAllApps().apps;
    const history = useHistory();

    if (apps.length < 1) {
        history.replace(`/apps/new`);
    }

    const sortedApps = useMemo(() => apps.sort((a, b) => a.name.localeCompare(b.name)), [apps]);
    const activeApps = useMemo(() => sortedApps.filter(app => app.active), [sortedApps]);
    const singleApp = activeApps[0];

    if (singleApp) {
        history.replace(`/apps/${singleApp.id}`);
    }

    return (
        <Page title="App" >
        </Page>
    );
});