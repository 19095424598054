import React, { useCallback, useState } from "react";
import { useClient } from "../../api/useClient";
import { useAccount } from "../utils/useAccount";
import { useRouteMatch } from "react-router";
import { AccountPricingStatus } from "../../api/spacex.types";
import { View } from "react-native";
import { Icon, Link, Modal, Stack, TextContainer } from "@shopify/polaris";
import { CustomersMajor, SendMajor } from "@shopify/polaris-icons";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";

export const TrialModal = React.memo(() => {
    const client = useClient();
    const account = useAccount();
    const appBridge = useAppBridge();

    const match = useRouteMatch();    

    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(account.pricing.status === AccountPricingStatus.TRIAL);

    const pricing = client.useDefaultPricing().defaultPricing;

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleSubscribe = useCallback(() => {
        setLoading(true);
        client.mutateSubscribeToPricing({ id: account.id, pricingId: pricing.id, returnUrl: match.url }).then((result) => {
            setLoading(false);
            // console.log('Subscriiibe result', result);
            const redirect = ShopifyRedirect.create(appBridge);
            if (result.subscribeToPricing) {
                redirect.dispatch(ShopifyRedirect.Action.REMOTE, result.subscribeToPricing);
            }
        }).catch((e) => {
            console.warn(e);
            setLoading(false);
        });
        // console.log("Subscribe link", pricing.id, match);
    }, [pricing, match]);


    return (
        <Modal
            open={open}
            titleHidden
            title={"Upgrade your subscription"}
            onClose={handleClose}
            primaryAction={{
                content: 'Subscribe',
                onAction: handleSubscribe,
                loading: loading,
                disabled: loading
            }}
        >

            <View style={{
                flex: 1,
                width: "100%",
                height: "228px",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundColor: "rgb(135,180,104)"
            }}>
                <Stack alignment={"center"} distribution={"center"}>
                    <span style={{ color: "#fff", fontSize: "4rem", lineHeight: "12rem" }}>$</span>
                    <span style={{ color: "#fff", fontSize: "12rem", lineHeight: "12rem" }}>{pricing.price}</span>
                    <span style={{ color: "#fff", fontSize: "4rem", lineHeight: "12rem" }}>/month</span>
                </Stack>
            </View>

            <Modal.Section>
                <TextContainer>
                    <p>Unlock full Cartmate features by upgrading to Cartmate standart plan</p>
                    <Stack vertical alignment={"leading"} distribution={"center"} spacing={"tight"} wrap={false}>
                        <Stack alignment={"center"} distribution={"leading"} spacing={"tight"} wrap={false}>
                            <Stack.Item>
                                <Icon source={SendMajor} backdrop />
                            </Stack.Item>
                            <Stack.Item fill>
                                <p>Send <Link url={"/notifications"} removeUnderline>push&nbsp;notifications</Link> to your customers</p>
                            </Stack.Item>
                        </Stack>
                        <Stack alignment={"center"} distribution={"leading"} spacing={"tight"} wrap={false}>
                            <Stack.Item>
                                <Icon source={CustomersMajor} backdrop />
                            </Stack.Item>
                            <Stack.Item fill>
                                <p>Get new customers with <Link url={"/promote"} removeUnderline>traffic&nbsp;exchange</Link></p>
                            </Stack.Item>
                        </Stack>
                    </Stack>
                </TextContainer>
            </Modal.Section>

        </Modal>
    );
});