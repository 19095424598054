import * as React from 'react';
import { DynamicIcon } from '../../components/Icon/IconView/Icons';

export const SupportedCollections = ['material' , 'ionicons' , 'feather' , 'material-community'] as const;
export type IconCollection = typeof SupportedCollections[number];

export function IconElement(props: { collection: IconCollection, name: string, size?: number, color?: string }) {
    if (SupportedCollections.some(i => i === props.collection)) {
        return <DynamicIcon value={{collection: props.collection, name: name}} {...props}/>
    }
    return null;
}