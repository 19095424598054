import React, { useEffect, useState } from "react";
import { useClient } from "../../../api/useClient";
import Select, { components } from 'react-select';
import { Stack } from "@shopify/polaris";
import Bugsnag from "@bugsnag/js";

export const StoreSelector = React.memo((
    {
        value,
        onChange,
        description
    }: {
        value?: string[] | null,
        onChange: (data: string[]) => void,
        description?: string
    }) => {
    const client = useClient();
    const [selected, setSelected] = useState<string[]>(value ? value : []);
    const [cards, setcards] = useState<any[]>();

    useEffect(() => {
        client.queryPublishedCardList().then((result) => {
            setcards(result.publishedShopsList.edges.map(edge => edge.node));
        }).catch((e) => {
            Bugsnag.notify(e);
        })
    }, [value, description, onChange, client]);


    useEffect(() => {
        onChange(selected);
    }, [selected]);

    const multiValue = (cards ? cards : []).filter((c) => selected?.indexOf(c.id) !== -1).map((f) => {
        return {
            value: f.id,
            label: f.title
        }
    });

    console.log('multiValue', multiValue);

    return (
        <Stack vertical>
            {description && (
                <Stack.Item>
                    <label>{description}</label>
                </Stack.Item>
            )}
            <Stack.Item>
                <Select
                    options={cards?.map((c) => {
                        return {
                            value: c.id,
                            label: c.title,
                        }
                    })}
                    components={{
                        MultiValue: props => {
                            const content = props?.data.label;
                            return (
                                <components.MultiValue
                                    {...props}>
                                    {content}
                                </components.MultiValue>
                            );
                        }
                    }}
                    menuPortalTarget={document.body}
                    isMulti={true}
                    isClearable
                    value={multiValue}
                    onChange={(data) => {
                        console.log(data);
                        const temp = data?.map((d) => d.value);
                        setSelected(temp ? temp : []);
                    }}
                />
            </Stack.Item>
        </Stack>
    );
});