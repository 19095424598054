
import { Modal, Stack, TextStyle } from "@shopify/polaris";
import React, { useState } from "react";
import { Text, View } from "react-native";
import { MixpanelEvent, trackEvent } from "../../utils/mixpanel";
import { useBoolLocalStorage } from "../banners/useBoolLocalStorage";

const reviewIcon = require('../../assets/images/friendly-store-icon.svg');

export const ReviewModal = React.memo(() => {
    const [closed, setClosed] = useBoolLocalStorage('Review');
    return (
        <Modal
            open={parseInt(localStorage.getItem('NumberCustomizationViews') || '0') >= 10 && !closed}
            title={'How is your experience with our app?'}
            onClose={() => {
                setClosed(true);
                trackEvent(MixpanelEvent.ReviewClose);
            }}
        >
            <Modal.Section>
                <View style={{
                    flex: 1,
                    width: '100%',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <View style={{
                        flexGrow: 1,
                        flex: 1, 
                        maxWidth: '450px'
                    }}>
                        <Text style={{ flexShrink: 1 }}>
                            {'We value your opinion. Let other merchants know how our app is working for you by leaving a review on the '}
                            <a
                                onClick={() => {
                                    setClosed(true);
                                    trackEvent(MixpanelEvent.ReviewComplete);
                                }}
                                target="_blank"
                                href="https://apps.shopify.com/cartmate#modal-show=ReviewListingModal"
                            >{'Shopify App Store'}</a>
                        </Text>
                    </View>
                    <img
                        alt="review-icon"
                        width="100px"
                        height="100px"
                        style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                        }}
                        src={reviewIcon}
                    />
                </View>
            </Modal.Section>
        </Modal>
    );
});