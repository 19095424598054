import { Icon, Stack } from "@shopify/polaris";
import { ListMajor } from "@shopify/polaris-icons";
import React from "react";
import { IconWrapper } from "../../components/Icon/IconView/Icons";
import {
    ContentCollection,
    ContentView,
} from '../../content/components';
import { TemplateWithVariables } from "../knobs/TemplateWithVariables";
import { FontWeightType, ResizeMode, TextAlignType } from "../knobs/types";
import { ConfigBlock } from "./BlockTypes";
import { DafaultBlockValues } from "./defaults";

const Variables = {
    collection: {
        type: 'collection_picker' as const,
        defaultValue: '',
        description: 'Collection',
    },
    blockTitle: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: '',
            textAlign: TextAlignType.Start,
            textColor: '#FFF',
            textShadow: false,
            showCaption: true,
            fontWeight: FontWeightType.Normal
        },
        description: 'Collection\'s caption',
        placeholder: 'Enter caption...',
        disableAlpha: true,
    },
    blurIntencity: {
        type: 'range' as const,
        defaultValue: 50,
        description: 'Captions background intencity',
        min: 0,
        max: 100
    },
    imageResizeMode: {
        type: 'resize_mode' as const,
        defaulValue: ResizeMode.Cover,
        description: 'Image resize mode'
    },
    border: {
        type: 'border' as const,
        defaultValue: {
            border: 1,
            color: '#676767',
            showBorder: false,
        },
        description: 'Border settings',
        min: 0,
        max: 5,
        step: 1,
        disableAlpha: true,
        isAdvanced: true
    },
    collectionPlaceholderImage: {
        type: 'blurhash_file' as const,
        defaultValue: { src: null, blurhash: null, resizeMode: ResizeMode.Cover },
        description: 'Collection\'s image replacement',
        clearable: true,
        isAdvanced: true
    },
    height: {
        type: 'range' as const,
        defaultValue: 100,
        description: 'Height',
        min: 100,
        max: 1000,
    },
    borderRadius: {
        type: 'range' as const,
        defaultValue: DafaultBlockValues.borderRadius,
        description: 'Border radius',
        min: 0,
        max: 100,
        isAdvanced: true
    },
    // showShadow: {
    //     type: 'boolean' as const,
    //     defaultValue: false,
    //     description: 'Enable banner shadow',
    //     isAdvanced: true,
    // },
    showTextBackground: {
        type: 'boolean' as const,
        defaultValue: true,
        description: 'Show caption background',
        isAdvanced: true,
    },
    backgroundColor: {
        type: 'color' as const,
        defaultValue: DafaultBlockValues.color,
        description: 'Banner background color',
        disableAlpha: false,
        isAdvanced: true,
    },
    marginCoordinates: {
        type: 'coordinates' as const,
        defaultValue: { left: 10, right: 10, top: 8, bottom: 0 },
        description: 'margin',
        min: 0,
        isAdvanced: true,
    },
    paddingCoordinates: {
        type: 'coordinates' as const,
        defaultValue: { left: 0, right: 0, top: 0, bottom: 0 },
        description: 'padding',
        min: 0,
        isAdvanced: true,
    },
}

const Collection = TemplateWithVariables(Variables, ({
    height,
    borderRadius,
    collection,
    blockTitle,
    collectionPlaceholderImage,
    // showShadow,
    backgroundColor,
    marginCoordinates,
    paddingCoordinates,
    border,
    imageResizeMode,
    blurIntencity
}) => {
    return (
        <ContentView
            style={{
                height: height,
                flexDirection: 'row',
                borderRadius: borderRadius,
                paddingTop: paddingCoordinates.top,
                paddingBottom: paddingCoordinates.bottom,
                paddingLeft: paddingCoordinates.left,
                paddingRight: paddingCoordinates.right,
                marginBottom: marginCoordinates.bottom,
                marginTop: marginCoordinates.top,
                marginRight: marginCoordinates.right,
                marginLeft: marginCoordinates.left,
                backgroundColor: backgroundColor,
                // shadowOpacity: showShadow ? 0.5 : undefined,
                // shadowRadius: showShadow ? 6 : undefined,
                borderWidth: border.showBorder ? border.border : 0,
                borderColor: border.color,
                overflow: 'hidden'
            }}
        >
            <ContentCollection
                id={collection}
                caption={blockTitle.value}
                textColor={blockTitle.textColor}
                textAlign={blockTitle.textAlign}
                showCaption={blockTitle.showCaption}
                placeholderImage={collectionPlaceholderImage}
                fontWeight={blockTitle.fontWeight}
                imageResizeMode={imageResizeMode as ResizeMode}
                forcePlaceholder
                blurIntencity={blurIntencity}
                style={{
                    borderRadius: borderRadius,
                    overflow: 'hidden'
                }}
            />
        </ContentView>
    )
});

export default {
    Preview: () => <Stack alignment="center" distribution="center">
        <Stack.Item>
            <IconWrapper>
                <Icon source={ListMajor} />
            </IconWrapper>
        </Stack.Item>
        <Stack.Item fill>
            Collection
        </Stack.Item>
    </Stack>,
    RenderBlock: Collection,
    Icon: ListMajor,
    Name: 'Collection'
} as ConfigBlock