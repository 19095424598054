import { Card, EmptyState, Layout, Badge, Page, ResourceItem, ResourceList, TextStyle } from '@shopify/polaris';
import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { useClient } from '../../api/useClient';

const EmptyStateMarkup = <EmptyState
    heading="Create page to get started"
    action={{content: 'Add new page', url: '/pages/new'}}
    image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
>
    <p>
        You can create new page for your app
    </p>
</EmptyState>

export const Pages = React.memo(() => {
    const client = useClient();
    const pages = client.usePages({fetchPolicy: 'cache-and-network'}).pages;
    const match = useRouteMatch()
    const sortedPages = useMemo(() => pages.sort((a, b) => a.id.localeCompare(b.id)), [pages])

    return (
        <Page title="Pages" primaryAction={{content: 'Add new page', url: `${match.url}/new`}}>
            <Layout>
                <Layout.Section>
                    <Card>
                        <ResourceList
                            emptyState={EmptyStateMarkup}
                            items={sortedPages}
                            renderItem={(a) => (
                                <ResourceItem
                                    id={a.id}
                                    url={`${match.url}/${a.id}`}
                                    name={a.name}
                                >
                                    <h3>
                                        <TextStyle variation="strong">{a.name} </TextStyle>
                                        {(!a.publishedData || a.draftData !== a.publishedData) &&
                                        <Badge status='attention' progress="partiallyComplete">unpublished</Badge>}
                                    </h3>
                                </ResourceItem>
                            )}
                        />
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    )
})