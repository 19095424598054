import React, { useEffect } from "react";
import Cookies from 'js-cookie'
import { authenticateByOAuth } from "../auth/api";
import { useParams } from "react-router";

const PLUGIN_URL = 'https://apps.shopify.com/cartmate';

export const ShopifyPlugin = React.memo(() => {
    const { ref } = useParams<{ ref: string }>();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const utmSource = urlSearchParams.get('utm_source') || Math.random();
    console.log('UTM SOURCE', utmSource);
    // alert(utmSource);
    // let res = Cookies.set('ref1', JSON.stringify({source: utmSource, k: Math.random() }), { domain: '.cartmate.com', path: '/'});
    // Cookies.set('ref2', JSON.stringify({source: utmSource, k: Math.random() }), { domain: '.cartmate.com', path: '/', sameSite: 'None', secure: true, expires: 10, httpOnly: true});
    // Cookies.set('ref', ref, { domain: '.cartmate.com', sameSite: 'None', secure: true, expires: 10});
    // Cookies.set('ref3', JSON.stringify({source: utmSource, k: Math.random() }), { domain: 'cartmate.com', sameSite: 'None', secure: true, expires: 10});
    useEffect(() => {
        // let res = Cookies.set('ref', JSON.stringify({source: utmSource, k: Math.random() }), { path: '/'});
        // let k = Cookies.get('ref');
        // alert(k);
        // console.log('Cookie set', res, k);
        authenticateByOAuth({});
        // window.location.href = PLUGIN_URL;
    }, []);
    return (
        <></>
    );
});