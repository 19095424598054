import { Badge, Card, Stack, TextStyle } from "@shopify/polaris";
import React from "react";
import { useHistory, useRouteMatch } from "react-router";
import { useClient } from "../../../api/useClient";

export const PositionsCard = React.memo(() => {
    const history = useHistory();
    const client = useClient();
    const summury = client.useAdListingSummury().adListingSummury;
    const match = useRouteMatch();

    return (
        <Card
            title={'Your positions'}
            primaryFooterAction={{
                content: 'Manage',
                onAction: () => { history.push(`${match.url}/positions`) }
            }}
        >
            <Card.Section>
                <label>{'Active positions: '}</label>
                <Badge status={'success'}>
                    {`${summury.positionsCount}`}
                </Badge>
            </Card.Section>
            <Card.Section>
                <label>{'Views summary: '}</label>
                <Badge status={'info'}>
                    {`${summury.views}`}
                </Badge>
            </Card.Section>
            <Card.Section>
                <label>{`Clicks summary: `}</label>
                <Badge status={'info'}>
                    {` ${summury.clicks}`}
                </Badge>
            </Card.Section>
        </Card>
    );
});