import { Badge, TextStyle } from "@shopify/polaris";
import { BlurView } from "expo-blur";
import React, { useEffect, useState } from "react"
import { View, Image, Text, ViewStyle } from "react-native";
import { useParams } from "react-router";
import { Product_product } from "../../api/spacex.types";
import { useClient } from "../../api/useClient";
import { ViewType } from "../../configEditor/knobs/types";
import { mapTextAlign } from "../../utils/textUtils";
import { ProductProps } from "../components";

export const ProductView = React.memo((props: ProductProps) => {
    const client = useClient();
    const [product, setProduct] = useState<Product_product>();

    console.log('[ProductView]: props', props);

    useEffect(() => {
        client
            .queryProduct({ id: props.id })
            .then((res) => setProduct(res.product));
    }, [props.id, setProduct]);

    if (!product || product.__typename === 'ProductMissing') {
        return (
            <View style={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <Badge size={'medium'} status={'critical'}>
                    {'Product not found'}
                </Badge>
            </View>
        );
    }

    const image = (product.images && product.images.length > 0)
        ? product.images[0]
        : undefined;

    console.log('[ProductView], image', image);

    const imageSrc = props.forcePlaceholder
        ? props.placeholderImage?.src || image?.src
        : image?.src || props.placeholderImage?.src;

    const imageResizeMode = props.forcePlaceholder
        ? props.placeholderImage?.resizeMode || props.imageResizeMode
        : props.imageResizeMode

    console.log('[ProductView], imageSrc', imageSrc);

    if (props.viewType === ViewType.Circles) {
        return (
            <View style={{ flexGrow: 1 }}>
                <Image
                    source={{ uri: imageSrc || '' }}
                    resizeMode={imageResizeMode || 'cover'}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        alignSelf: 'flex-start',
                    }}
                />
                {props.showCaption && (product.title.length > 0) && (
                    <BlurView style={{
                        flexGrow: 1,
                        alignItems: props.textAlign
                            ? props.textAlign
                            : 'flex-start',
                        justifyContent: 'center',
                        padding: 8,
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        top: 0
                    }}
                        tint={'dark'}
                        intensity={props.blurIntencity}
                    >
                        <Text style={{
                            color: props.textColor,
                            fontSize: 14,
                            fontWeight: '600',
                            textAlign: mapTextAlign(props.textAlign)
                        }}>
                            {product.title}
                        </Text>
                        {props.showPrice && product.variants && (
                            <Text
                                style={{
                                    color: props.textColor
                                        ? props.textColor
                                        : '#000',
                                    fontSize: 16,
                                    fontWeight: props.fontWeight
                                        ? props.fontWeight
                                        : '600',
                                    textAlign: mapTextAlign(props.textAlign)
                                }}
                                numberOfLines={2}
                                ellipsizeMode={'tail'}
                            >
                                {product.variants[0]?.formatted_price}
                            </Text>
                        )}
                    </BlurView>
                )}
            </View>
        );
    }

    const hasTitle = props.showCaption && product?.title && product?.title.length > 0;

    return (
        <View style={[
            { flexGrow: 1 },
            props.style,
            {
                height: Number((props.style as ViewStyle)?.height) + 80,
            }
        ]}>
            <Image
                source={{ uri: imageSrc || '' }}
                resizeMode={imageResizeMode || 'cover'}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: hasTitle ? 80 : 0,
                    alignSelf: 'flex-start',
                }}
            />
            {hasTitle
                && (
                    <BlurView
                        style={{
                            flexGrow: 1,
                            height: 80,
                            alignItems: props.textAlign
                                ? props.textAlign
                                : 'flex-start',
                            justifyContent: 'center',
                            padding: 8,
                            position: 'absolute', left: 0, right: 0, bottom: 0,
                            overflow: 'hidden'
                        }}
                        tint={'dark'}
                        intensity={props.blurIntencity}
                    >
                        <Text
                            style={{
                                color: props.textColor
                                    ? props.textColor
                                    : '#000',
                                fontSize: 16,
                                fontWeight: props.fontWeight
                                    ? props.fontWeight
                                    : '600',
                            }}
                            numberOfLines={2}
                            ellipsizeMode={'tail'}
                        >
                            {props.caption ? props.caption : product.title}
                        </Text>
                        {props.showPrice && product.variants && (
                            <Text
                                style={{
                                    color: props.textColor
                                        ? props.textColor
                                        : '#000',
                                    fontSize: 16,
                                    fontWeight: props.fontWeight
                                        ? props.fontWeight
                                        : '600',
                                }}
                                numberOfLines={2}
                                ellipsizeMode={'tail'}
                            >
                                {product.variants[0]?.formatted_price}
                            </Text>
                        )}
                    </BlurView>
                )}
        </View>
    )
});