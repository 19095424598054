import { FormLayout, Labelled } from "@shopify/polaris";
import React, { useState, useEffect } from "react";
import { useClient } from "../../../api/useClient";
import { StyledSelect } from "../../../components/StyledSelect/StyledSelect";
import { Knob } from "../Knob";

export const KnobProduct = React.memo<Knob.KnobComponentProps<Knob.KnobProductDescriptor>>(({ knob, value: initValue, onChange, innerKey }) => {
    const client = useClient();
    const { description } = knob
    const [query, setQuery] = useState<string>();

    const products = client.useSearchListProducts({ query: query }).listProducts.edges.map((edge) => {
        const img = edge.node.images ? edge.node.images[0] : '';
        const price = edge.node.variants
            ? edge.node.variants[0]
                ? edge.node.variants[0].formatted_price
                : undefined
            : undefined;
        return {
            value: JSON.stringify({
                id: edge.node.id,
                title: edge.node.title,
                image: img,
                price: price
            }),
            label: edge.node?.title
        }
    });

    console.log('[KnobProduct], initValue', initValue);

    return <FormLayout>
        <Labelled id={`product-${innerKey}`} label={description}>
            <StyledSelect
                options={products}
                onChange={(item) => {
                    onChange(JSON.parse(item?.value), innerKey);
                }}
                onInputChange={setQuery}
                isSearchable={true}
                value={
                    initValue
                        ? {
                            value: { id: initValue.id, title: initValue.title, image: initValue.image },
                            label: initValue.title
                        }
                        : undefined
                }
            />
        </Labelled>
    </FormLayout>
})