import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { TypographyStyle, TypographyWeightsStyle } from '../../../components/Typography/commonTypography';
import { FormCheckbox } from '../../../components/Form/Components/FormCheckbox';
import { useFormContext } from 'react-hook-form';
import { Heading, TextContainer } from '@shopify/polaris';

const style = StyleSheet.create({
    title: {
        marginBottom: 20
    },
    error: {
        color: 'red'
    }
})

const validate = (data?: boolean) => typeof data !== "undefined"

export const AppForClient = React.memo(() => {
    const context = useFormContext<{ forClient: string[] }>()
    const error = context.errors['forClient']

    return (
        <View>
            <View style={{ marginBottom: 8 }}>
                <TextContainer>
                    <Heading>
                        🤝 &nbsp; Are you setting up a mobile shop for a client?
                    </Heading>
                </TextContainer>
            </View>
            <FormCheckbox
                name={'forClient'}
                rules={{ validate }}
                defaultValue={false}
                text={<TextContainer>
                    Yes, I’m designing/developing an app for a client
                </TextContainer>}
            />
        </View>
    )
})