import React from 'react';
import { CheckBoxProps, TouchableOpacity, StyleSheet, View, Text } from 'react-native';
import CheckboxTrue from './CheckboxTrue'
import { TypographyStyle } from '../Typography/commonTypography';

const style = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    checkbox: {
        width: 14,
        height: 14,
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: 2,
        margin: 4
    },
    valFalse: {
        borderColor: '#333333'
    },
    valTrue: {
        borderColor: '#3BA1DB',
        backgroundColor: '#3BA1DB',
    }
})

export type StyledCheckboxProps = CheckBoxProps & {
    text?: React.ReactNode
}

export const StyledCheckbox = React.memo(
    React.forwardRef<TouchableOpacity, StyledCheckboxProps>(({
                                                                 onChange,
                                                                 value,
                                                                 style: s,
                                                                 text,
                                                                 ...viewProps
                                                             }, ref) => (
        <TouchableOpacity style={style.container} ref={ref} onPress={() => onChange && onChange(!value)}>
            <View {...viewProps} style={[style.checkbox, value ? style.valTrue : style.valFalse, s]}>
                {value && <CheckboxTrue style={{marginLeft: -4}}/>}
            </View>
            <Text style={TypographyStyle.body1}>{text}</Text>
        </TouchableOpacity>
    )))