import {
    DraggableStateSnapshot,
    DraggingStyle,
    NotDraggingStyle
} from 'react-beautiful-dnd';

export function reorderArray<T>(list: ArrayLike<T>, startIndex: number, endIndex: number): T[] {
    const result = Array.from<T>(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export function setNewValueArray<T>(list: ArrayLike<T>, index: number, value: T): T[] {
    const result = Array.from<T>(list);
    result.splice(index, 1, value);
    console.log('[setNewValueArray]', result);
    return result;
}

export function verticalStyle(inputStyles: DraggingStyle | NotDraggingStyle | undefined, snapshot:DraggableStateSnapshot) {
    if (!inputStyles) {
        return {}
    }

    const { transform, ...draggableStyle } = inputStyles
    // const isDragging = snapshot.isDragging;
    // const styles = {
    //     boxShadow: isDragging ? "0 0 10px rgba(0, 0, 0, 0.11111)" : "none",
    //     ...draggableStyle
    // };
    if (!transform) {
        return draggableStyle;
    }
    return {
        ...draggableStyle,
        transform: `translate(0, ${transform.substring(
            transform.indexOf(",") + 1,
            transform.indexOf(")")
        )})`
    };
}