/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountWrapper
// ====================================================

export interface AccountWrapper_account_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface AccountWrapper_account_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AccountWrapper_account_pricing_pricing | null;
}

export interface AccountWrapper_account_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
}

export interface AccountWrapper_account_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface AccountWrapper_account_capabilities {
  __typename: "AccountCapabilities";
  grant: AccountWrapper_account_capabilities_grant[];
}

export interface AccountWrapper_account {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AccountWrapper_account_pricing;
  monitoringStatus: AccountWrapper_account_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: AccountWrapper_account_capabilities | null;
}

export interface AccountWrapper {
  account: AccountWrapper_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CoinStatus
// ====================================================

export interface CoinStatus_account {
  __typename: "Account";
  id: string;
  cartCoins: number | null;
}

export interface CoinStatus {
  account: CoinStatus_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAccount
// ====================================================

export interface CreateAccount_accountCreate_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface CreateAccount_accountCreate_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: CreateAccount_accountCreate_pricing_pricing | null;
}

export interface CreateAccount_accountCreate_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
}

export interface CreateAccount_accountCreate_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface CreateAccount_accountCreate_capabilities {
  __typename: "AccountCapabilities";
  grant: CreateAccount_accountCreate_capabilities_grant[];
}

export interface CreateAccount_accountCreate {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: CreateAccount_accountCreate_pricing;
  monitoringStatus: CreateAccount_accountCreate_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: CreateAccount_accountCreate_capabilities | null;
}

export interface CreateAccount {
  accountCreate: CreateAccount_accountCreate;
}

export interface CreateAccountVariables {
  id: string;
  inputs: AccountCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AccountReset
// ====================================================

export interface AccountReset_accountReset_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface AccountReset_accountReset_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AccountReset_accountReset_pricing_pricing | null;
}

export interface AccountReset_accountReset_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
}

export interface AccountReset_accountReset_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface AccountReset_accountReset_capabilities {
  __typename: "AccountCapabilities";
  grant: AccountReset_accountReset_capabilities_grant[];
}

export interface AccountReset_accountReset {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AccountReset_accountReset_pricing;
  monitoringStatus: AccountReset_accountReset_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: AccountReset_accountReset_capabilities | null;
}

export interface AccountReset {
  accountReset: AccountReset_accountReset;
}

export interface AccountResetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetupAccount
// ====================================================

export interface SetupAccount_setupAccount_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface SetupAccount_setupAccount_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: SetupAccount_setupAccount_pricing_pricing | null;
}

export interface SetupAccount_setupAccount_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
}

export interface SetupAccount_setupAccount_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface SetupAccount_setupAccount_capabilities {
  __typename: "AccountCapabilities";
  grant: SetupAccount_setupAccount_capabilities_grant[];
}

export interface SetupAccount_setupAccount {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: SetupAccount_setupAccount_pricing;
  monitoringStatus: SetupAccount_setupAccount_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: SetupAccount_setupAccount_capabilities | null;
}

export interface SetupAccount {
  setupAccount: SetupAccount_setupAccount;
}

export interface SetupAccountVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RewardCoins
// ====================================================

export interface RewardCoins_addCartCoins {
  __typename: "Account";
  id: string;
  cartCoins: number | null;
}

export interface RewardCoins {
  addCartCoins: RewardCoins_addCartCoins;
}

export interface RewardCoinsVariables {
  id: string;
  amount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChargeCoins
// ====================================================

export interface ChargeCoins_chargeCartCoins {
  __typename: "Account";
  id: string;
  cartCoins: number | null;
}

export interface ChargeCoins {
  chargeCartCoins: ChargeCoins_chargeCartCoins;
}

export interface ChargeCoinsVariables {
  id: string;
  price: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SurveyIndustryList
// ====================================================

export interface SurveyIndustryList_surveyIndustryList {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
  sort: number;
}

export interface SurveyIndustryList {
  surveyIndustryList: SurveyIndustryList_surveyIndustryList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Contacts
// ====================================================

export interface Contacts_contacts_data_socials {
  __typename: "AccountContactSocial";
  socialName: string;
  socialId: string;
}

export interface Contacts_contacts_data {
  __typename: "AccountContactData";
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
  socials: Contacts_contacts_data_socials[] | null;
  visible: boolean | null;
}

export interface Contacts_contacts {
  __typename: "AccountContact";
  id: string;
  type: AccountContactType;
  data: Contacts_contacts_data;
}

export interface Contacts {
  contacts: Contacts_contacts[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrUpdateContact
// ====================================================

export interface CreateOrUpdateContact_accountCreateOrUpdateContact_data_socials {
  __typename: "AccountContactSocial";
  socialName: string;
  socialId: string;
}

export interface CreateOrUpdateContact_accountCreateOrUpdateContact_data {
  __typename: "AccountContactData";
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
  socials: CreateOrUpdateContact_accountCreateOrUpdateContact_data_socials[] | null;
  visible: boolean | null;
}

export interface CreateOrUpdateContact_accountCreateOrUpdateContact {
  __typename: "AccountContact";
  id: string;
  type: AccountContactType;
  data: CreateOrUpdateContact_accountCreateOrUpdateContact_data;
}

export interface CreateOrUpdateContact {
  accountCreateOrUpdateContact: CreateOrUpdateContact_accountCreateOrUpdateContact;
}

export interface CreateOrUpdateContactVariables {
  input: AccountContactInput;
  type: AccountContactType;
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccounPricingLimit
// ====================================================

export interface AccounPricingLimit_accountPricingLimit {
  __typename: "AccountPricingLimit";
  id: string;
  key: string;
  status: AccountPricingLimitStatus;
  period: number;
  limit: number;
  count: number;
  createdAt: string;
  updatedAt: string;
  lastSyncDate: string | null;
}

export interface AccounPricingLimit {
  accountPricingLimit: AccounPricingLimit_accountPricingLimit | null;
}

export interface AccounPricingLimitVariables {
  key: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminAppCard
// ====================================================

export interface AdminAppCard_adminListAppCard_edges_node_icon {
  __typename: "Image";
  src: string;
}

export interface AdminAppCard_adminListAppCard_edges_node_splash {
  __typename: "Image";
  src: string;
}

export interface AdminAppCard_adminListAppCard_edges_node_background {
  __typename: "Image";
  src: string;
}

export interface AdminAppCard_adminListAppCard_edges_node_app {
  __typename: "App";
  id: string;
}

export interface AdminAppCard_adminListAppCard_edges_node_data_industries {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
}

export interface AdminAppCard_adminListAppCard_edges_node_data {
  __typename: "AppCardData";
  industries: AdminAppCard_adminListAppCard_edges_node_data_industries[];
}

export interface AdminAppCard_adminListAppCard_edges_node {
  __typename: "AppCard";
  id: string;
  status: AppCardStatus;
  updatedAt: string | null;
  title: string;
  description: string;
  icon: AdminAppCard_adminListAppCard_edges_node_icon | null;
  splash: AdminAppCard_adminListAppCard_edges_node_splash | null;
  background: AdminAppCard_adminListAppCard_edges_node_background | null;
  createdAt: string;
  app: AdminAppCard_adminListAppCard_edges_node_app;
  data: AdminAppCard_adminListAppCard_edges_node_data | null;
  currency: string;
}

export interface AdminAppCard_adminListAppCard_edges {
  __typename: "AppCardEdge";
  node: AdminAppCard_adminListAppCard_edges_node;
  cursor: string;
}

export interface AdminAppCard_adminListAppCard_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

export interface AdminAppCard_adminListAppCard {
  __typename: "AppCardConnection";
  edges: AdminAppCard_adminListAppCard_edges[];
  pageInfo: AdminAppCard_adminListAppCard_pageInfo;
}

export interface AdminAppCard {
  adminListAppCard: AdminAppCard_adminListAppCard;
}

export interface AdminAppCardVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  status?: AppCardStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminAccountMonitoringList
// ====================================================

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters_shopify {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters_database {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters_index {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters {
  __typename: "AccountMonitoringCatalogCounters";
  shopify: AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters_shopify;
  database: AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters_database;
  index: AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters_index;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data {
  __typename: "AccountMonitoringData";
  catalogCounters: AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node {
  __typename: "AccountMonitoring";
  id: string;
  status: AccountMonitoringStatus;
  message: string | null;
  createdAt: string;
  data: AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_edges {
  __typename: "AdminAccountMonitoringEventEdge";
  node: AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node;
  cursor: string;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents {
  __typename: "AdminAccountMonitoringEventConnection";
  edges: AdminAccountMonitoringList_adminAccountMonitoringEvents_edges[];
  pageInfo: AdminAccountMonitoringList_adminAccountMonitoringEvents_pageInfo;
}

export interface AdminAccountMonitoringList {
  adminAccountMonitoringEvents: AdminAccountMonitoringList_adminAccountMonitoringEvents;
}

export interface AdminAccountMonitoringListVariables {
  accountId: string;
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  status?: AccountMonitoringStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminUser
// ====================================================

export interface AdminUser_adminUser {
  __typename: "AdminUser";
  id: string;
  email: string;
  role: AdminUserRole;
}

export interface AdminUser {
  adminUser: AdminUser_adminUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminUpdatePricing
// ====================================================

export interface AdminUpdatePricing_adminUpdatePricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  price: number;
  trialDays: number;
}

export interface AdminUpdatePricing {
  adminUpdatePricing: AdminUpdatePricing_adminUpdatePricing;
}

export interface AdminUpdatePricingVariables {
  input: PricingInput;
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminAccountsList
// ====================================================

export interface AdminAccountsList_adminListAccount_edges_node_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface AdminAccountsList_adminListAccount_edges_node_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AdminAccountsList_adminListAccount_edges_node_pricing_pricing | null;
}

export interface AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters_shopify {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters_database {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters_index {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters {
  __typename: "AccountMonitoringCatalogCounters";
  shopify: AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters_shopify;
  database: AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters_database;
  index: AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters_index;
}

export interface AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data {
  __typename: "AccountMonitoringData";
  catalogCounters: AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters;
}

export interface AdminAccountsList_adminListAccount_edges_node_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
  id: string;
  message: string | null;
  createdAt: string;
  data: AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data;
}

export interface AdminAccountsList_adminListAccount_edges_node_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface AdminAccountsList_adminListAccount_edges_node_capabilities {
  __typename: "AccountCapabilities";
  grant: AdminAccountsList_adminListAccount_edges_node_capabilities_grant[];
}

export interface AdminAccountsList_adminListAccount_edges_node {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AdminAccountsList_adminListAccount_edges_node_pricing;
  monitoringStatus: AdminAccountsList_adminListAccount_edges_node_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: AdminAccountsList_adminListAccount_edges_node_capabilities | null;
}

export interface AdminAccountsList_adminListAccount_edges {
  __typename: "AccountEdge";
  node: AdminAccountsList_adminListAccount_edges_node;
  cursor: string;
}

export interface AdminAccountsList_adminListAccount_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

export interface AdminAccountsList_adminListAccount {
  __typename: "AccountConnection";
  edges: AdminAccountsList_adminListAccount_edges[];
  pageInfo: AdminAccountsList_adminListAccount_pageInfo;
}

export interface AdminAccountsList {
  adminListAccount: AdminAccountsList_adminListAccount;
}

export interface AdminAccountsListVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminGenerateAccountToken
// ====================================================

export interface AdminGenerateAccountToken {
  adminGenerateAccountToken: string;
}

export interface AdminGenerateAccountTokenVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminGetAccount
// ====================================================

export interface AdminGetAccount_adminAccount_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface AdminGetAccount_adminAccount_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AdminGetAccount_adminAccount_pricing_pricing | null;
}

export interface AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters_shopify {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters_database {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters_index {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters {
  __typename: "AccountMonitoringCatalogCounters";
  shopify: AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters_shopify;
  database: AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters_database;
  index: AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters_index;
}

export interface AdminGetAccount_adminAccount_monitoringStatus_data {
  __typename: "AccountMonitoringData";
  catalogCounters: AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters;
}

export interface AdminGetAccount_adminAccount_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
  id: string;
  message: string | null;
  createdAt: string;
  data: AdminGetAccount_adminAccount_monitoringStatus_data;
}

export interface AdminGetAccount_adminAccount_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface AdminGetAccount_adminAccount_capabilities {
  __typename: "AccountCapabilities";
  grant: AdminGetAccount_adminAccount_capabilities_grant[];
}

export interface AdminGetAccount_adminAccount {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AdminGetAccount_adminAccount_pricing;
  monitoringStatus: AdminGetAccount_adminAccount_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: AdminGetAccount_adminAccount_capabilities | null;
}

export interface AdminGetAccount {
  adminAccount: AdminGetAccount_adminAccount;
}

export interface AdminGetAccountVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminGetAppCard
// ====================================================

export interface AdminGetAppCard_adminAppCard_icon {
  __typename: "Image";
  src: string;
}

export interface AdminGetAppCard_adminAppCard_splash {
  __typename: "Image";
  src: string;
}

export interface AdminGetAppCard_adminAppCard_background {
  __typename: "Image";
  src: string;
}

export interface AdminGetAppCard_adminAppCard_app {
  __typename: "App";
  id: string;
}

export interface AdminGetAppCard_adminAppCard_data_industries {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
}

export interface AdminGetAppCard_adminAppCard_data {
  __typename: "AppCardData";
  industries: AdminGetAppCard_adminAppCard_data_industries[];
}

export interface AdminGetAppCard_adminAppCard {
  __typename: "AppCard";
  id: string;
  status: AppCardStatus;
  updatedAt: string | null;
  title: string;
  description: string;
  icon: AdminGetAppCard_adminAppCard_icon | null;
  splash: AdminGetAppCard_adminAppCard_splash | null;
  background: AdminGetAppCard_adminAppCard_background | null;
  createdAt: string;
  app: AdminGetAppCard_adminAppCard_app;
  data: AdminGetAppCard_adminAppCard_data | null;
  currency: string;
}

export interface AdminGetAppCard {
  adminAppCard: AdminGetAppCard_adminAppCard;
}

export interface AdminGetAppCardVariables {
  appCardId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminPublishAppCard
// ====================================================

export interface AdminPublishAppCard_adminPublishAppCard_icon {
  __typename: "Image";
  src: string;
}

export interface AdminPublishAppCard_adminPublishAppCard_splash {
  __typename: "Image";
  src: string;
}

export interface AdminPublishAppCard_adminPublishAppCard_background {
  __typename: "Image";
  src: string;
}

export interface AdminPublishAppCard_adminPublishAppCard_app {
  __typename: "App";
  id: string;
}

export interface AdminPublishAppCard_adminPublishAppCard_data_industries {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
}

export interface AdminPublishAppCard_adminPublishAppCard_data {
  __typename: "AppCardData";
  industries: AdminPublishAppCard_adminPublishAppCard_data_industries[];
}

export interface AdminPublishAppCard_adminPublishAppCard {
  __typename: "AppCardPublished";
  id: string;
  status: AppCardPublishedStatus;
  publishedAt: string;
  title: string;
  description: string;
  icon: AdminPublishAppCard_adminPublishAppCard_icon | null;
  splash: AdminPublishAppCard_adminPublishAppCard_splash | null;
  background: AdminPublishAppCard_adminPublishAppCard_background | null;
  createdAt: string;
  app: AdminPublishAppCard_adminPublishAppCard_app;
  data: AdminPublishAppCard_adminPublishAppCard_data | null;
}

export interface AdminPublishAppCard {
  adminPublishAppCard: AdminPublishAppCard_adminPublishAppCard;
}

export interface AdminPublishAppCardVariables {
  appCardId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminMakeAccountTrial
// ====================================================

export interface AdminMakeAccountTrial_adminMakeAccountTrial_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AdminMakeAccountTrial_adminMakeAccountTrial_pricing_pricing | null;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters_shopify {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters_database {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters_index {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters {
  __typename: "AccountMonitoringCatalogCounters";
  shopify: AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters_shopify;
  database: AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters_database;
  index: AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters_index;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data {
  __typename: "AccountMonitoringData";
  catalogCounters: AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
  id: string;
  message: string | null;
  createdAt: string;
  data: AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_capabilities {
  __typename: "AccountCapabilities";
  grant: AdminMakeAccountTrial_adminMakeAccountTrial_capabilities_grant[];
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AdminMakeAccountTrial_adminMakeAccountTrial_pricing;
  monitoringStatus: AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: AdminMakeAccountTrial_adminMakeAccountTrial_capabilities | null;
}

export interface AdminMakeAccountTrial {
  adminMakeAccountTrial: AdminMakeAccountTrial_adminMakeAccountTrial;
}

export interface AdminMakeAccountTrialVariables {
  accountId: string;
  input: AdminMakeTrialInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllApps
// ====================================================

export interface AllApps_apps_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface AllApps_apps_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface AllApps_apps_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: AllApps_apps_draftConfig_design_colors_light;
  dark: AllApps_apps_draftConfig_design_colors_dark;
}

export interface AllApps_apps_draftConfig_design {
  __typename: "AppDesign";
  colors: AllApps_apps_draftConfig_design_colors;
}

export interface AllApps_apps_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: AllApps_apps_draftConfig_design;
}

export interface AllApps_apps_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  buttonTextColor: string | null;
  buttonColor: string | null;
  buttonTextFontWeight: string | null;
  text: string;
  textColor: string | null;
  textFontWeight: string | null;
  title: string;
  titleColor: string | null;
  titleFontWeight: string | null;
  icon: string;
  backgroundColor: string | null;
  stickToBottom: boolean | null;
  hideIcon: boolean | null;
  fullScreen: boolean | null;
}

export interface AllApps_apps_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: AllApps_apps_settings_banner_params | null;
}

export interface AllApps_apps_settings {
  __typename: "AppSettings";
  banner: AllApps_apps_settings_banner | null;
  logo: string | null;
}

export interface AllApps_apps {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: AllApps_apps_draftConfig;
  settings: AllApps_apps_settings | null;
  freeTrafficActive: boolean;
}

export interface AllApps {
  apps: AllApps_apps[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: App
// ====================================================

export interface App_app_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface App_app_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface App_app_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: App_app_draftConfig_design_colors_light;
  dark: App_app_draftConfig_design_colors_dark;
}

export interface App_app_draftConfig_design {
  __typename: "AppDesign";
  colors: App_app_draftConfig_design_colors;
}

export interface App_app_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: App_app_draftConfig_design;
}

export interface App_app_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  buttonTextColor: string | null;
  buttonColor: string | null;
  buttonTextFontWeight: string | null;
  text: string;
  textColor: string | null;
  textFontWeight: string | null;
  title: string;
  titleColor: string | null;
  titleFontWeight: string | null;
  icon: string;
  backgroundColor: string | null;
  stickToBottom: boolean | null;
  hideIcon: boolean | null;
  fullScreen: boolean | null;
}

export interface App_app_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: App_app_settings_banner_params | null;
}

export interface App_app_settings {
  __typename: "AppSettings";
  banner: App_app_settings_banner | null;
  logo: string | null;
}

export interface App_app {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: App_app_draftConfig;
  settings: App_app_settings | null;
  freeTrafficActive: boolean;
}

export interface App {
  app: App_app;
}

export interface AppVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePreviewSession
// ====================================================

export interface CreatePreviewSession_createPreviewSession {
  __typename: "PreviewEvent";
  id: string;
  location: string;
}

export interface CreatePreviewSession {
  createPreviewSession: CreatePreviewSession_createPreviewSession;
}

export interface CreatePreviewSessionVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClosePreviewSession
// ====================================================

export interface ClosePreviewSession {
  closePreviewSession: boolean;
}

export interface ClosePreviewSessionVariables {
  sessionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PreviewToken
// ====================================================

export interface PreviewToken_previewToken {
  __typename: "PreviewToken";
  previewToken: string;
}

export interface PreviewToken {
  previewToken: PreviewToken_previewToken;
}

export interface PreviewTokenVariables {
  appId: string;
  sessionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppConfig
// ====================================================

export interface AppConfig_app_settings {
  __typename: "AppSettings";
  logo: string | null;
}

export interface AppConfig_app_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  accent: string;
}

export interface AppConfig_app_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: AppConfig_app_draftConfig_design_colors_light;
}

export interface AppConfig_app_draftConfig_design {
  __typename: "AppDesign";
  designerData: string;
  colors: AppConfig_app_draftConfig_design_colors;
}

export interface AppConfig_app_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: AppConfig_app_draftConfig_design;
  icon: string | null;
  splash: string | null;
}

export interface AppConfig_app_publishedConfig_design_colors_light {
  __typename: "AppDesignColors";
  accent: string;
}

export interface AppConfig_app_publishedConfig_design_colors {
  __typename: "AppDesignThemes";
  light: AppConfig_app_publishedConfig_design_colors_light;
}

export interface AppConfig_app_publishedConfig_design {
  __typename: "AppDesign";
  designerData: string;
  colors: AppConfig_app_publishedConfig_design_colors;
}

export interface AppConfig_app_publishedConfig {
  __typename: "AppConfig";
  id: string;
  design: AppConfig_app_publishedConfig_design;
  icon: string | null;
  splash: string | null;
}

export interface AppConfig_app {
  __typename: "App";
  id: string;
  settings: AppConfig_app_settings | null;
  draftConfig: AppConfig_app_draftConfig;
  publishedConfig: AppConfig_app_publishedConfig | null;
}

export interface AppConfig {
  app: AppConfig_app;
}

export interface AppConfigVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppEmulatorConfig
// ====================================================

export interface AppEmulatorConfig_app_draftConfig_connection {
  __typename: "AppConnection";
  token: string;
  endpoint: string;
}

export interface AppEmulatorConfig_app_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface AppEmulatorConfig_app_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface AppEmulatorConfig_app_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: AppEmulatorConfig_app_draftConfig_design_colors_light;
  dark: AppEmulatorConfig_app_draftConfig_design_colors_dark;
}

export interface AppEmulatorConfig_app_draftConfig_design_values {
  __typename: "AppDesignValues";
  toolBarTextSize: number | null;
  toolBarTitleTextSize: number | null;
  bottomNavigationTextSize: number | null;
  buttonTextSize: number | null;
  headLineTextSize: number | null;
  subtitleTextSize: number | null;
  bodyTextSize: number | null;
}

export interface AppEmulatorConfig_app_draftConfig_design {
  __typename: "AppDesign";
  colors: AppEmulatorConfig_app_draftConfig_design_colors;
  values: AppEmulatorConfig_app_draftConfig_design_values;
}

export interface AppEmulatorConfig_app_draftConfig_tabs_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface AppEmulatorConfig_app_draftConfig_tabs {
  __typename: "AppTab";
  id: string;
  title: string;
  url: string | null;
  icon: AppEmulatorConfig_app_draftConfig_tabs_icon | null;
}

export interface AppEmulatorConfig_app_draftConfig {
  __typename: "AppConfig";
  id: string;
  connection: AppEmulatorConfig_app_draftConfig_connection;
  design: AppEmulatorConfig_app_draftConfig_design;
  tabs: AppEmulatorConfig_app_draftConfig_tabs[];
}

export interface AppEmulatorConfig_app {
  __typename: "App";
  id: string;
  draftConfig: AppEmulatorConfig_app_draftConfig;
}

export interface AppEmulatorConfig {
  app: AppEmulatorConfig_app;
}

export interface AppEmulatorConfigVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppTabsConfig
// ====================================================

export interface AppTabsConfig_app_draftConfig_tabsDesigner_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface AppTabsConfig_app_draftConfig_tabsDesigner {
  __typename: "AppDesignerTab";
  key: string;
  title: string;
  default: boolean;
  contentType: ContentReferenceType;
  contentKey: string | null;
  contentUrl: string | null;
  icon: AppTabsConfig_app_draftConfig_tabsDesigner_icon | null;
}

export interface AppTabsConfig_app_draftConfig_moreTabsDesigner_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface AppTabsConfig_app_draftConfig_moreTabsDesigner {
  __typename: "AppDesignerTab";
  key: string;
  title: string;
  default: boolean;
  contentType: ContentReferenceType;
  contentKey: string | null;
  contentUrl: string | null;
  icon: AppTabsConfig_app_draftConfig_moreTabsDesigner_icon | null;
}

export interface AppTabsConfig_app_draftConfig {
  __typename: "AppConfig";
  id: string;
  tabsDesigner: AppTabsConfig_app_draftConfig_tabsDesigner[];
  moreTabsDesigner: AppTabsConfig_app_draftConfig_moreTabsDesigner[];
}

export interface AppTabsConfig_app {
  __typename: "App";
  id: string;
  draftConfig: AppTabsConfig_app_draftConfig;
}

export interface AppTabsConfig {
  app: AppTabsConfig_app;
}

export interface AppTabsConfigVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateApp
// ====================================================

export interface UpdateApp_appUpdate_settings {
  __typename: "AppSettings";
  logo: string | null;
}

export interface UpdateApp_appUpdate_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  accent: string;
}

export interface UpdateApp_appUpdate_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: UpdateApp_appUpdate_draftConfig_design_colors_light;
}

export interface UpdateApp_appUpdate_draftConfig_design {
  __typename: "AppDesign";
  designerData: string;
  colors: UpdateApp_appUpdate_draftConfig_design_colors;
}

export interface UpdateApp_appUpdate_draftConfig_tabsDesigner_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface UpdateApp_appUpdate_draftConfig_tabsDesigner {
  __typename: "AppDesignerTab";
  key: string;
  title: string;
  default: boolean;
  contentType: ContentReferenceType;
  contentKey: string | null;
  contentUrl: string | null;
  icon: UpdateApp_appUpdate_draftConfig_tabsDesigner_icon | null;
}

export interface UpdateApp_appUpdate_draftConfig_moreTabsDesigner_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface UpdateApp_appUpdate_draftConfig_moreTabsDesigner {
  __typename: "AppDesignerTab";
  key: string;
  title: string;
  default: boolean;
  contentType: ContentReferenceType;
  contentKey: string | null;
  contentUrl: string | null;
  icon: UpdateApp_appUpdate_draftConfig_moreTabsDesigner_icon | null;
}

export interface UpdateApp_appUpdate_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: UpdateApp_appUpdate_draftConfig_design;
  icon: string | null;
  splash: string | null;
  tabsDesigner: UpdateApp_appUpdate_draftConfig_tabsDesigner[];
  moreTabsDesigner: UpdateApp_appUpdate_draftConfig_moreTabsDesigner[];
}

export interface UpdateApp_appUpdate {
  __typename: "App";
  id: string;
  settings: UpdateApp_appUpdate_settings | null;
  draftConfig: UpdateApp_appUpdate_draftConfig;
}

export interface UpdateApp {
  appUpdate: UpdateApp_appUpdate;
}

export interface UpdateAppVariables {
  id: string;
  config: AppConfigurationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateApp
// ====================================================

export interface CreateApp_appCreate_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface CreateApp_appCreate_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface CreateApp_appCreate_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: CreateApp_appCreate_draftConfig_design_colors_light;
  dark: CreateApp_appCreate_draftConfig_design_colors_dark;
}

export interface CreateApp_appCreate_draftConfig_design {
  __typename: "AppDesign";
  colors: CreateApp_appCreate_draftConfig_design_colors;
}

export interface CreateApp_appCreate_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: CreateApp_appCreate_draftConfig_design;
}

export interface CreateApp_appCreate_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  buttonTextColor: string | null;
  buttonColor: string | null;
  buttonTextFontWeight: string | null;
  text: string;
  textColor: string | null;
  textFontWeight: string | null;
  title: string;
  titleColor: string | null;
  titleFontWeight: string | null;
  icon: string;
  backgroundColor: string | null;
  stickToBottom: boolean | null;
  hideIcon: boolean | null;
  fullScreen: boolean | null;
}

export interface CreateApp_appCreate_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: CreateApp_appCreate_settings_banner_params | null;
}

export interface CreateApp_appCreate_settings {
  __typename: "AppSettings";
  banner: CreateApp_appCreate_settings_banner | null;
  logo: string | null;
}

export interface CreateApp_appCreate {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: CreateApp_appCreate_draftConfig;
  settings: CreateApp_appCreate_settings | null;
  freeTrafficActive: boolean;
}

export interface CreateApp {
  appCreate: CreateApp_appCreate;
}

export interface CreateAppVariables {
  name: string;
  repeatKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RenameApp
// ====================================================

export interface RenameApp_appRename_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface RenameApp_appRename_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface RenameApp_appRename_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: RenameApp_appRename_draftConfig_design_colors_light;
  dark: RenameApp_appRename_draftConfig_design_colors_dark;
}

export interface RenameApp_appRename_draftConfig_design {
  __typename: "AppDesign";
  colors: RenameApp_appRename_draftConfig_design_colors;
}

export interface RenameApp_appRename_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: RenameApp_appRename_draftConfig_design;
}

export interface RenameApp_appRename_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  buttonTextColor: string | null;
  buttonColor: string | null;
  buttonTextFontWeight: string | null;
  text: string;
  textColor: string | null;
  textFontWeight: string | null;
  title: string;
  titleColor: string | null;
  titleFontWeight: string | null;
  icon: string;
  backgroundColor: string | null;
  stickToBottom: boolean | null;
  hideIcon: boolean | null;
  fullScreen: boolean | null;
}

export interface RenameApp_appRename_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: RenameApp_appRename_settings_banner_params | null;
}

export interface RenameApp_appRename_settings {
  __typename: "AppSettings";
  banner: RenameApp_appRename_settings_banner | null;
  logo: string | null;
}

export interface RenameApp_appRename {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: RenameApp_appRename_draftConfig;
  settings: RenameApp_appRename_settings | null;
  freeTrafficActive: boolean;
}

export interface RenameApp {
  appRename: RenameApp_appRename;
}

export interface RenameAppVariables {
  id: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveApp
// ====================================================

export interface ArchiveApp_appArchive_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface ArchiveApp_appArchive_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface ArchiveApp_appArchive_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: ArchiveApp_appArchive_draftConfig_design_colors_light;
  dark: ArchiveApp_appArchive_draftConfig_design_colors_dark;
}

export interface ArchiveApp_appArchive_draftConfig_design {
  __typename: "AppDesign";
  colors: ArchiveApp_appArchive_draftConfig_design_colors;
}

export interface ArchiveApp_appArchive_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: ArchiveApp_appArchive_draftConfig_design;
}

export interface ArchiveApp_appArchive_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  buttonTextColor: string | null;
  buttonColor: string | null;
  buttonTextFontWeight: string | null;
  text: string;
  textColor: string | null;
  textFontWeight: string | null;
  title: string;
  titleColor: string | null;
  titleFontWeight: string | null;
  icon: string;
  backgroundColor: string | null;
  stickToBottom: boolean | null;
  hideIcon: boolean | null;
  fullScreen: boolean | null;
}

export interface ArchiveApp_appArchive_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: ArchiveApp_appArchive_settings_banner_params | null;
}

export interface ArchiveApp_appArchive_settings {
  __typename: "AppSettings";
  banner: ArchiveApp_appArchive_settings_banner | null;
  logo: string | null;
}

export interface ArchiveApp_appArchive {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: ArchiveApp_appArchive_draftConfig;
  settings: ArchiveApp_appArchive_settings | null;
  freeTrafficActive: boolean;
}

export interface ArchiveApp {
  appArchive: ArchiveApp_appArchive;
}

export interface ArchiveAppVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePreviewSessionUrl
// ====================================================

export interface UpdatePreviewSessionUrl_updatePreviewSessionUrl {
  __typename: "PreviewEvent";
  id: string;
  location: string;
}

export interface UpdatePreviewSessionUrl {
  updatePreviewSessionUrl: UpdatePreviewSessionUrl_updatePreviewSessionUrl;
}

export interface UpdatePreviewSessionUrlVariables {
  appId: string;
  sessionId: string;
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAppIcon
// ====================================================

export interface UpdateAppIcon_updateAppIcon {
  __typename: "App";
  id: string;
}

export interface UpdateAppIcon {
  updateAppIcon: UpdateAppIcon_updateAppIcon;
}

export interface UpdateAppIconVariables {
  id: string;
  url?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAppSplash
// ====================================================

export interface UpdateAppSplash_updateAppSplash {
  __typename: "App";
  id: string;
}

export interface UpdateAppSplash {
  updateAppSplash: UpdateAppSplash_updateAppSplash;
}

export interface UpdateAppSplashVariables {
  id: string;
  url?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShareMall
// ====================================================

export interface ShareMall {
  shareMall: boolean;
}

export interface ShareMallVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAppCard
// ====================================================

export interface GetAppCard_app_card_icon {
  __typename: "Image";
  src: string;
}

export interface GetAppCard_app_card_splash {
  __typename: "Image";
  src: string;
}

export interface GetAppCard_app_card_background {
  __typename: "Image";
  src: string;
}

export interface GetAppCard_app_card_app {
  __typename: "App";
  id: string;
}

export interface GetAppCard_app_card_data_industries {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
}

export interface GetAppCard_app_card_data {
  __typename: "AppCardData";
  industries: GetAppCard_app_card_data_industries[];
}

export interface GetAppCard_app_card {
  __typename: "AppCard";
  id: string;
  status: AppCardStatus;
  updatedAt: string | null;
  title: string;
  description: string;
  icon: GetAppCard_app_card_icon | null;
  splash: GetAppCard_app_card_splash | null;
  background: GetAppCard_app_card_background | null;
  createdAt: string;
  app: GetAppCard_app_card_app;
  data: GetAppCard_app_card_data | null;
  currency: string;
}

export interface GetAppCard_app_publishedCard_icon {
  __typename: "Image";
  src: string;
}

export interface GetAppCard_app_publishedCard_splash {
  __typename: "Image";
  src: string;
}

export interface GetAppCard_app_publishedCard_background {
  __typename: "Image";
  src: string;
}

export interface GetAppCard_app_publishedCard_app {
  __typename: "App";
  id: string;
}

export interface GetAppCard_app_publishedCard_data_industries {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
}

export interface GetAppCard_app_publishedCard_data {
  __typename: "AppCardData";
  industries: GetAppCard_app_publishedCard_data_industries[];
}

export interface GetAppCard_app_publishedCard {
  __typename: "AppCardPublished";
  id: string;
  status: AppCardPublishedStatus;
  publishedAt: string;
  title: string;
  description: string;
  icon: GetAppCard_app_publishedCard_icon | null;
  splash: GetAppCard_app_publishedCard_splash | null;
  background: GetAppCard_app_publishedCard_background | null;
  createdAt: string;
  app: GetAppCard_app_publishedCard_app;
  data: GetAppCard_app_publishedCard_data | null;
}

export interface GetAppCard_app {
  __typename: "App";
  id: string;
  card: GetAppCard_app_card;
  publishedCard: GetAppCard_app_publishedCard | null;
}

export interface GetAppCard {
  app: GetAppCard_app;
}

export interface GetAppCardVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAppCard
// ====================================================

export interface UpdateAppCard_updateAppCard_icon {
  __typename: "Image";
  src: string;
}

export interface UpdateAppCard_updateAppCard_splash {
  __typename: "Image";
  src: string;
}

export interface UpdateAppCard_updateAppCard_background {
  __typename: "Image";
  src: string;
}

export interface UpdateAppCard_updateAppCard_app {
  __typename: "App";
  id: string;
}

export interface UpdateAppCard_updateAppCard_data_industries {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
}

export interface UpdateAppCard_updateAppCard_data {
  __typename: "AppCardData";
  industries: UpdateAppCard_updateAppCard_data_industries[];
}

export interface UpdateAppCard_updateAppCard {
  __typename: "AppCard";
  id: string;
  status: AppCardStatus;
  updatedAt: string | null;
  title: string;
  description: string;
  icon: UpdateAppCard_updateAppCard_icon | null;
  splash: UpdateAppCard_updateAppCard_splash | null;
  background: UpdateAppCard_updateAppCard_background | null;
  createdAt: string;
  app: UpdateAppCard_updateAppCard_app;
  data: UpdateAppCard_updateAppCard_data | null;
  currency: string;
}

export interface UpdateAppCard {
  updateAppCard: UpdateAppCard_updateAppCard;
}

export interface UpdateAppCardVariables {
  appId: string;
  input: AppCardInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitAppCard
// ====================================================

export interface SubmitAppCard_submitAppCard_icon {
  __typename: "Image";
  src: string;
}

export interface SubmitAppCard_submitAppCard_splash {
  __typename: "Image";
  src: string;
}

export interface SubmitAppCard_submitAppCard_background {
  __typename: "Image";
  src: string;
}

export interface SubmitAppCard_submitAppCard_app {
  __typename: "App";
  id: string;
}

export interface SubmitAppCard_submitAppCard_data_industries {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
}

export interface SubmitAppCard_submitAppCard_data {
  __typename: "AppCardData";
  industries: SubmitAppCard_submitAppCard_data_industries[];
}

export interface SubmitAppCard_submitAppCard {
  __typename: "AppCard";
  id: string;
  status: AppCardStatus;
  updatedAt: string | null;
  title: string;
  description: string;
  icon: SubmitAppCard_submitAppCard_icon | null;
  splash: SubmitAppCard_submitAppCard_splash | null;
  background: SubmitAppCard_submitAppCard_background | null;
  createdAt: string;
  app: SubmitAppCard_submitAppCard_app;
  data: SubmitAppCard_submitAppCard_data | null;
  currency: string;
}

export interface SubmitAppCard {
  submitAppCard: SubmitAppCard_submitAppCard;
}

export interface SubmitAppCardVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DraftAppCard
// ====================================================

export interface DraftAppCard_draftAppCard_icon {
  __typename: "Image";
  src: string;
}

export interface DraftAppCard_draftAppCard_splash {
  __typename: "Image";
  src: string;
}

export interface DraftAppCard_draftAppCard_background {
  __typename: "Image";
  src: string;
}

export interface DraftAppCard_draftAppCard_app {
  __typename: "App";
  id: string;
}

export interface DraftAppCard_draftAppCard_data_industries {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
}

export interface DraftAppCard_draftAppCard_data {
  __typename: "AppCardData";
  industries: DraftAppCard_draftAppCard_data_industries[];
}

export interface DraftAppCard_draftAppCard {
  __typename: "AppCard";
  id: string;
  status: AppCardStatus;
  updatedAt: string | null;
  title: string;
  description: string;
  icon: DraftAppCard_draftAppCard_icon | null;
  splash: DraftAppCard_draftAppCard_splash | null;
  background: DraftAppCard_draftAppCard_background | null;
  createdAt: string;
  app: DraftAppCard_draftAppCard_app;
  data: DraftAppCard_draftAppCard_data | null;
  currency: string;
}

export interface DraftAppCard {
  draftAppCard: DraftAppCard_draftAppCard;
}

export interface DraftAppCardVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeactivateAppCard
// ====================================================

export interface DeactivateAppCard {
  deactivateAppCard: boolean;
}

export interface DeactivateAppCardVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncCatalog
// ====================================================

export interface SyncCatalog_shopRestartSync {
  __typename: "SyncState";
  id: string;
}

export interface SyncCatalog {
  shopRestartSync: SyncCatalog_shopRestartSync;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EnableBanner
// ====================================================

export interface EnableBanner_enableBanner_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface EnableBanner_enableBanner_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface EnableBanner_enableBanner_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: EnableBanner_enableBanner_draftConfig_design_colors_light;
  dark: EnableBanner_enableBanner_draftConfig_design_colors_dark;
}

export interface EnableBanner_enableBanner_draftConfig_design {
  __typename: "AppDesign";
  colors: EnableBanner_enableBanner_draftConfig_design_colors;
}

export interface EnableBanner_enableBanner_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: EnableBanner_enableBanner_draftConfig_design;
}

export interface EnableBanner_enableBanner_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  buttonTextColor: string | null;
  buttonColor: string | null;
  buttonTextFontWeight: string | null;
  text: string;
  textColor: string | null;
  textFontWeight: string | null;
  title: string;
  titleColor: string | null;
  titleFontWeight: string | null;
  icon: string;
  backgroundColor: string | null;
  stickToBottom: boolean | null;
  hideIcon: boolean | null;
  fullScreen: boolean | null;
}

export interface EnableBanner_enableBanner_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: EnableBanner_enableBanner_settings_banner_params | null;
}

export interface EnableBanner_enableBanner_settings {
  __typename: "AppSettings";
  banner: EnableBanner_enableBanner_settings_banner | null;
  logo: string | null;
}

export interface EnableBanner_enableBanner {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: EnableBanner_enableBanner_draftConfig;
  settings: EnableBanner_enableBanner_settings | null;
  freeTrafficActive: boolean;
}

export interface EnableBanner {
  enableBanner: EnableBanner_enableBanner;
}

export interface EnableBannerVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DisableBanner
// ====================================================

export interface DisableBanner_disableBanner_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface DisableBanner_disableBanner_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface DisableBanner_disableBanner_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: DisableBanner_disableBanner_draftConfig_design_colors_light;
  dark: DisableBanner_disableBanner_draftConfig_design_colors_dark;
}

export interface DisableBanner_disableBanner_draftConfig_design {
  __typename: "AppDesign";
  colors: DisableBanner_disableBanner_draftConfig_design_colors;
}

export interface DisableBanner_disableBanner_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: DisableBanner_disableBanner_draftConfig_design;
}

export interface DisableBanner_disableBanner_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  buttonTextColor: string | null;
  buttonColor: string | null;
  buttonTextFontWeight: string | null;
  text: string;
  textColor: string | null;
  textFontWeight: string | null;
  title: string;
  titleColor: string | null;
  titleFontWeight: string | null;
  icon: string;
  backgroundColor: string | null;
  stickToBottom: boolean | null;
  hideIcon: boolean | null;
  fullScreen: boolean | null;
}

export interface DisableBanner_disableBanner_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: DisableBanner_disableBanner_settings_banner_params | null;
}

export interface DisableBanner_disableBanner_settings {
  __typename: "AppSettings";
  banner: DisableBanner_disableBanner_settings_banner | null;
  logo: string | null;
}

export interface DisableBanner_disableBanner {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: DisableBanner_disableBanner_draftConfig;
  settings: DisableBanner_disableBanner_settings | null;
  freeTrafficActive: boolean;
}

export interface DisableBanner {
  disableBanner: DisableBanner_disableBanner;
}

export interface DisableBannerVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBannerParams
// ====================================================

export interface UpdateBannerParams_editBannerParams_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface UpdateBannerParams_editBannerParams_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface UpdateBannerParams_editBannerParams_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: UpdateBannerParams_editBannerParams_draftConfig_design_colors_light;
  dark: UpdateBannerParams_editBannerParams_draftConfig_design_colors_dark;
}

export interface UpdateBannerParams_editBannerParams_draftConfig_design {
  __typename: "AppDesign";
  colors: UpdateBannerParams_editBannerParams_draftConfig_design_colors;
}

export interface UpdateBannerParams_editBannerParams_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: UpdateBannerParams_editBannerParams_draftConfig_design;
}

export interface UpdateBannerParams_editBannerParams_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  buttonTextColor: string | null;
  buttonColor: string | null;
  buttonTextFontWeight: string | null;
  text: string;
  textColor: string | null;
  textFontWeight: string | null;
  title: string;
  titleColor: string | null;
  titleFontWeight: string | null;
  icon: string;
  backgroundColor: string | null;
  stickToBottom: boolean | null;
  hideIcon: boolean | null;
  fullScreen: boolean | null;
}

export interface UpdateBannerParams_editBannerParams_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: UpdateBannerParams_editBannerParams_settings_banner_params | null;
}

export interface UpdateBannerParams_editBannerParams_settings {
  __typename: "AppSettings";
  banner: UpdateBannerParams_editBannerParams_settings_banner | null;
  logo: string | null;
}

export interface UpdateBannerParams_editBannerParams {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: UpdateBannerParams_editBannerParams_draftConfig;
  settings: UpdateBannerParams_editBannerParams_settings | null;
  freeTrafficActive: boolean;
}

export interface UpdateBannerParams {
  editBannerParams: UpdateBannerParams_editBannerParams;
}

export interface UpdateBannerParamsVariables {
  appId: string;
  input: AppBannerInputParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAppSettings
// ====================================================

export interface GetAppSettings_app_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface GetAppSettings_app_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface GetAppSettings_app_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: GetAppSettings_app_draftConfig_design_colors_light;
  dark: GetAppSettings_app_draftConfig_design_colors_dark;
}

export interface GetAppSettings_app_draftConfig_design {
  __typename: "AppDesign";
  colors: GetAppSettings_app_draftConfig_design_colors;
}

export interface GetAppSettings_app_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: GetAppSettings_app_draftConfig_design;
}

export interface GetAppSettings_app_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  buttonTextColor: string | null;
  buttonColor: string | null;
  buttonTextFontWeight: string | null;
  text: string;
  textColor: string | null;
  textFontWeight: string | null;
  title: string;
  titleColor: string | null;
  titleFontWeight: string | null;
  icon: string;
  backgroundColor: string | null;
  stickToBottom: boolean | null;
  hideIcon: boolean | null;
  fullScreen: boolean | null;
}

export interface GetAppSettings_app_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: GetAppSettings_app_settings_banner_params | null;
}

export interface GetAppSettings_app_settings {
  __typename: "AppSettings";
  banner: GetAppSettings_app_settings_banner | null;
  logo: string | null;
}

export interface GetAppSettings_app {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: GetAppSettings_app_draftConfig;
  settings: GetAppSettings_app_settings | null;
  freeTrafficActive: boolean;
}

export interface GetAppSettings {
  app: GetAppSettings_app;
}

export interface GetAppSettingsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppStat
// ====================================================

export interface AppStat_appStat {
  __typename: "AccountStatistic";
  visits: number;
  orders: number;
  installs: number;
  ordersSum: number;
}

export interface AppStat {
  appStat: AppStat_appStat;
}

export interface AppStatVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSiteScreenshot
// ====================================================

export interface GetSiteScreenshot {
  siteScreenshot: string;
}

export interface GetSiteScreenshotVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GenerateSharLink
// ====================================================

export interface GenerateSharLink {
  generateShareLink: string;
}

export interface GenerateSharLinkVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: WatchAppEmulatorConfig
// ====================================================

export interface WatchAppEmulatorConfig_appConfig_connection {
  __typename: "AppConnection";
  token: string;
  endpoint: string;
}

export interface WatchAppEmulatorConfig_appConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface WatchAppEmulatorConfig_appConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface WatchAppEmulatorConfig_appConfig_design_colors {
  __typename: "AppDesignThemes";
  light: WatchAppEmulatorConfig_appConfig_design_colors_light;
  dark: WatchAppEmulatorConfig_appConfig_design_colors_dark;
}

export interface WatchAppEmulatorConfig_appConfig_design_values {
  __typename: "AppDesignValues";
  toolBarTextSize: number | null;
  toolBarTitleTextSize: number | null;
  bottomNavigationTextSize: number | null;
  buttonTextSize: number | null;
  headLineTextSize: number | null;
  subtitleTextSize: number | null;
  bodyTextSize: number | null;
}

export interface WatchAppEmulatorConfig_appConfig_design {
  __typename: "AppDesign";
  colors: WatchAppEmulatorConfig_appConfig_design_colors;
  values: WatchAppEmulatorConfig_appConfig_design_values;
}

export interface WatchAppEmulatorConfig_appConfig_tabs_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface WatchAppEmulatorConfig_appConfig_tabs {
  __typename: "AppTab";
  id: string;
  title: string;
  url: string | null;
  icon: WatchAppEmulatorConfig_appConfig_tabs_icon | null;
}

export interface WatchAppEmulatorConfig_appConfig {
  __typename: "AppConfig";
  id: string;
  connection: WatchAppEmulatorConfig_appConfig_connection;
  design: WatchAppEmulatorConfig_appConfig_design;
  tabs: WatchAppEmulatorConfig_appConfig_tabs[];
}

export interface WatchAppEmulatorConfig {
  appConfig: WatchAppEmulatorConfig_appConfig;
}

export interface WatchAppEmulatorConfigVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetFreeTraffic
// ====================================================

export interface SetFreeTraffic_appSetFreeTraffic {
  __typename: "App";
  id: string;
}

export interface SetFreeTraffic {
  appSetFreeTraffic: SetFreeTraffic_appSetFreeTraffic;
}

export interface SetFreeTrafficVariables {
  id: string;
  active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScreenSettings
// ====================================================

export interface ScreenSettings_screenSettings {
  __typename: "ScreenSettings";
  id: string;
  screen: Screen;
  settings: string;
}

export interface ScreenSettings {
  screenSettings: ScreenSettings_screenSettings;
}

export interface ScreenSettingsVariables {
  screen: Screen;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateScreenSettings
// ====================================================

export interface UpdateScreenSettings_updateScreenSettings {
  __typename: "ScreenSettings";
  id: string;
  screen: Screen;
  settings: string;
}

export interface UpdateScreenSettings {
  updateScreenSettings: UpdateScreenSettings_updateScreenSettings;
}

export interface UpdateScreenSettingsVariables {
  screen: Screen;
  settings: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppVersionsPage
// ====================================================

export interface AppVersionsPage_appVersions_edges_node_builds_buildInfo {
  __typename: "AppBuildInfo";
  id: string;
  status: string | null;
  statusText: string | null;
  percentageComplete: number | null;
}

export interface AppVersionsPage_appVersions_edges_node_builds {
  __typename: "AppBuild";
  id: string;
  state: string;
  buildInfo: AppVersionsPage_appVersions_edges_node_builds_buildInfo | null;
}

export interface AppVersionsPage_appVersions_edges_node {
  __typename: "AppVersion";
  id: string;
  platform: AppPlatforms;
  status: AppVersionStatuses;
  version: string;
  number: number;
  createdAt: string;
  builds: AppVersionsPage_appVersions_edges_node_builds[];
}

export interface AppVersionsPage_appVersions_edges {
  __typename: "AppVersionEdge";
  node: AppVersionsPage_appVersions_edges_node;
}

export interface AppVersionsPage_appVersions_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface AppVersionsPage_appVersions {
  __typename: "AppVersionConnection";
  edges: AppVersionsPage_appVersions_edges[];
  pageInfo: AppVersionsPage_appVersions_pageInfo;
}

export interface AppVersionsPage {
  appVersions: AppVersionsPage_appVersions | null;
}

export interface AppVersionsPageVariables {
  id: string;
  first?: number | null;
  after?: string | null;
  platform?: AppPlatforms | null;
  status?: AppVersionStatuses | null;
  sort?: AppVersionsSorts | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateNewAppVersion
// ====================================================

export interface CreateNewAppVersion_createNewAppVersion {
  __typename: "AppVersion";
  id: string;
}

export interface CreateNewAppVersion {
  createNewAppVersion: CreateNewAppVersion_createNewAppVersion;
}

export interface CreateNewAppVersionVariables {
  id: string;
  input: NewAppVersionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PublishedCardList
// ====================================================

export interface PublishedCardList_publishedShopsList_edges_node_icon {
  __typename: "Image";
  src: string;
}

export interface PublishedCardList_publishedShopsList_edges_node_background {
  __typename: "Image";
  src: string;
}

export interface PublishedCardList_publishedShopsList_edges_node_app {
  __typename: "App";
  id: string;
}

export interface PublishedCardList_publishedShopsList_edges_node_data_industries {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
}

export interface PublishedCardList_publishedShopsList_edges_node_data {
  __typename: "AppCardData";
  industries: PublishedCardList_publishedShopsList_edges_node_data_industries[];
}

export interface PublishedCardList_publishedShopsList_edges_node {
  __typename: "AppCardPublished";
  id: string;
  status: AppCardPublishedStatus;
  title: string;
  description: string;
  icon: PublishedCardList_publishedShopsList_edges_node_icon | null;
  background: PublishedCardList_publishedShopsList_edges_node_background | null;
  app: PublishedCardList_publishedShopsList_edges_node_app;
  data: PublishedCardList_publishedShopsList_edges_node_data | null;
}

export interface PublishedCardList_publishedShopsList_edges {
  __typename: "AppCardPublishedEdge";
  node: PublishedCardList_publishedShopsList_edges_node;
}

export interface PublishedCardList_publishedShopsList {
  __typename: "AppCardPublishedConnection";
  edges: PublishedCardList_publishedShopsList_edges[];
}

export interface PublishedCardList {
  publishedShopsList: PublishedCardList_publishedShopsList;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MyShop
// ====================================================

export interface MyShop_shop_properties {
  __typename: "StoreProperties";
  name: string;
  email: string;
  phone: string | null;
}

export interface MyShop_shop {
  __typename: "Shop";
  id: string;
  domain: string;
  name: string;
  properties: MyShop_shop_properties;
}

export interface MyShop_syncState {
  __typename: "SyncState";
  id: string;
  status: SyncStatus;
}

export interface MyShop {
  shop: MyShop_shop;
  syncState: MyShop_syncState;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Pages
// ====================================================

export interface Pages_pages {
  __typename: "PageEditor";
  id: string;
  name: string;
  draftData: string;
  publishedData: string | null;
  contentPageId: string;
}

export interface Pages {
  pages: Pages_pages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Page
// ====================================================

export interface Page_page {
  __typename: "PageEditor";
  id: string;
  name: string;
  draftData: string;
  publishedData: string | null;
  contentPageId: string;
}

export interface Page {
  page: Page_page;
}

export interface PageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BlurHash
// ====================================================

export interface BlurHash_getBlurHash {
  __typename: "BlurHashImage";
  src: string;
  blurhash: string;
}

export interface BlurHash {
  getBlurHash: BlurHash_getBlurHash;
}

export interface BlurHashVariables {
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePage
// ====================================================

export interface CreatePage_pageCreate {
  __typename: "PageEditor";
  id: string;
  name: string;
  draftData: string;
  publishedData: string | null;
  contentPageId: string;
}

export interface CreatePage {
  pageCreate: CreatePage_pageCreate;
}

export interface CreatePageVariables {
  input: ContentPageCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePage
// ====================================================

export interface UpdatePage_pageUpdate {
  __typename: "PageEditor";
  id: string;
  name: string;
  draftData: string;
  publishedData: string | null;
  contentPageId: string;
}

export interface UpdatePage {
  pageUpdate: UpdatePage_pageUpdate;
}

export interface UpdatePageVariables {
  id: string;
  input: ContentPageUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PublishPage
// ====================================================

export interface PublishPage_pagePublish {
  __typename: "PageEditor";
  id: string;
  name: string;
  draftData: string;
  publishedData: string | null;
  contentPageId: string;
}

export interface PublishPage {
  pagePublish: PublishPage_pagePublish;
}

export interface PublishPageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePage
// ====================================================

export interface DeletePage {
  pageDelete: boolean;
}

export interface DeletePageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListPricing
// ====================================================

export interface ListPricing_listPricing_edges_node {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  price: number;
  trialDays: number;
  status: PricingStatus;
  isDefault: boolean;
  hidden: boolean | null;
}

export interface ListPricing_listPricing_edges {
  __typename: "PricingEdge";
  node: ListPricing_listPricing_edges_node;
  cursor: string;
}

export interface ListPricing_listPricing_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ListPricing_listPricing {
  __typename: "PricingConnection";
  edges: ListPricing_listPricing_edges[];
  pageInfo: ListPricing_listPricing_pageInfo;
}

export interface ListPricing {
  listPricing: ListPricing_listPricing;
}

export interface ListPricingVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DefaultPricing
// ====================================================

export interface DefaultPricing_defaultPricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  price: number;
  trialDays: number;
  status: PricingStatus;
  isDefault: boolean;
  hidden: boolean | null;
}

export interface DefaultPricing {
  defaultPricing: DefaultPricing_defaultPricing;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubscribeToPricing
// ====================================================

export interface SubscribeToPricing {
  subscribeToPricing: string | null;
}

export interface SubscribeToPricingVariables {
  id: string;
  pricingId: string;
  returnUrl?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmBilling
// ====================================================

export interface ConfirmBilling_confirmBilling_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface ConfirmBilling_confirmBilling_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: ConfirmBilling_confirmBilling_pricing_pricing | null;
}

export interface ConfirmBilling_confirmBilling_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
}

export interface ConfirmBilling_confirmBilling_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface ConfirmBilling_confirmBilling_capabilities {
  __typename: "AccountCapabilities";
  grant: ConfirmBilling_confirmBilling_capabilities_grant[];
}

export interface ConfirmBilling_confirmBilling {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: ConfirmBilling_confirmBilling_pricing;
  monitoringStatus: ConfirmBilling_confirmBilling_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: ConfirmBilling_confirmBilling_capabilities | null;
}

export interface ConfirmBilling {
  confirmBilling: ConfirmBilling_confirmBilling;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Products
// ====================================================

export interface Products_products_images {
  __typename: "Image";
  id: string;
  src: string;
  blurhash: string | null;
}

export interface Products_products_variants {
  __typename: "ProductVariant";
  id: string;
  price: string;
  title: string;
  formatted_price: string | null;
  compare_at_price: string | null;
}

export interface Products_products {
  __typename: "Product";
  id: string;
  title: string;
  images: (Products_products_images | null)[] | null;
  variants: (Products_products_variants | null)[] | null;
}

export interface Products {
  products: Products_products[];
}

export interface ProductsVariables {
  query?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Product
// ====================================================

export interface Product_product_ProductMissing {
  __typename: "ProductMissing";
}

export interface Product_product_Product_images {
  __typename: "Image";
  id: string;
  src: string;
  blurhash: string | null;
}

export interface Product_product_Product_variants {
  __typename: "ProductVariant";
  id: string;
  price: string;
  title: string;
  formatted_price: string | null;
  compare_at_price: string | null;
}

export interface Product_product_Product {
  __typename: "Product";
  id: string;
  title: string;
  images: (Product_product_Product_images | null)[] | null;
  variants: (Product_product_Product_variants | null)[] | null;
}

export type Product_product = Product_product_ProductMissing | Product_product_Product;

export interface Product {
  product: Product_product;
}

export interface ProductVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductStatus
// ====================================================

export interface ProductStatus {
  productStatus: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchListProducts
// ====================================================

export interface SearchListProducts_listProducts_edges_node_images {
  __typename: "Image";
  src: string;
  id: string;
  blurhash: string | null;
}

export interface SearchListProducts_listProducts_edges_node_variants {
  __typename: "ProductVariant";
  id: string;
  price: string;
  title: string;
  formatted_price: string | null;
  compare_at_price: string | null;
}

export interface SearchListProducts_listProducts_edges_node {
  __typename: "Product";
  id: string;
  title: string;
  images: (SearchListProducts_listProducts_edges_node_images | null)[] | null;
  variants: (SearchListProducts_listProducts_edges_node_variants | null)[] | null;
}

export interface SearchListProducts_listProducts_edges {
  __typename: "ProductEdge";
  node: SearchListProducts_listProducts_edges_node;
}

export interface SearchListProducts_listProducts {
  __typename: "ProductConnection";
  edges: SearchListProducts_listProducts_edges[];
}

export interface SearchListProducts {
  listProducts: SearchListProducts_listProducts;
}

export interface SearchListProductsVariables {
  query?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListProducts
// ====================================================

export interface ListProducts_listProducts_edges_node_images {
  __typename: "Image";
  src: string;
  id: string;
  blurhash: string | null;
}

export interface ListProducts_listProducts_edges_node_variants {
  __typename: "ProductVariant";
  id: string;
  price: string;
  title: string;
  formatted_price: string | null;
  compare_at_price: string | null;
}

export interface ListProducts_listProducts_edges_node_collections {
  __typename: "Collection";
  id: string;
}

export interface ListProducts_listProducts_edges_node {
  __typename: "Product";
  id: string;
  title: string;
  images: (ListProducts_listProducts_edges_node_images | null)[] | null;
  variants: (ListProducts_listProducts_edges_node_variants | null)[] | null;
  collections: (ListProducts_listProducts_edges_node_collections | null)[] | null;
}

export interface ListProducts_listProducts_edges {
  __typename: "ProductEdge";
  node: ListProducts_listProducts_edges_node;
}

export interface ListProducts_listProducts_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ListProducts_listProducts {
  __typename: "ProductConnection";
  edges: ListProducts_listProducts_edges[];
  pageInfo: ListProducts_listProducts_pageInfo;
}

export interface ListProducts {
  listProducts: ListProducts_listProducts;
}

export interface ListProductsVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  query?: string | null;
  appId?: string | null;
  collectionId?: string | null;
  sort?: ProductsSorts | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Collections
// ====================================================

export interface Collections_collections_image {
  __typename: "Image";
  id: string;
  src: string;
  blurhash: string | null;
}

export interface Collections_collections {
  __typename: "Collection";
  id: string;
  title: string;
  image: Collections_collections_image | null;
  itemsCount: number | null;
}

export interface Collections {
  collections: (Collections_collections | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Collection
// ====================================================

export interface Collection_collection_image {
  __typename: "Image";
  id: string;
  src: string;
  blurhash: string | null;
}

export interface Collection_collection {
  __typename: "Collection";
  id: string;
  title: string;
  image: Collection_collection_image | null;
  itemsCount: number | null;
}

export interface Collection {
  collection: Collection_collection;
}

export interface CollectionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListCollections
// ====================================================

export interface ListCollections_listCollections_edges_node_image {
  __typename: "Image";
  id: string;
  src: string;
  blurhash: string | null;
}

export interface ListCollections_listCollections_edges_node {
  __typename: "Collection";
  id: string;
  title: string;
  image: ListCollections_listCollections_edges_node_image | null;
  itemsCount: number | null;
}

export interface ListCollections_listCollections_edges {
  __typename: "CollectionEdge";
  node: ListCollections_listCollections_edges_node;
}

export interface ListCollections_listCollections_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ListCollections_listCollections {
  __typename: "CollectionConnection";
  edges: ListCollections_listCollections_edges[];
  pageInfo: ListCollections_listCollections_pageInfo;
}

export interface ListCollections {
  listCollections: ListCollections_listCollections;
}

export interface ListCollectionsVariables {
  appId?: string | null;
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePushMailing
// ====================================================

export interface CreatePushMailing_createPushMailing_target {
  __typename: "PushMailingTarget";
  platform: PushMailingPlatform;
}

export interface CreatePushMailing_createPushMailing_content {
  __typename: "PushMailingContent";
  title: string;
  message: string;
  image: string | null;
  navigation: string | null;
}

export interface CreatePushMailing_createPushMailing {
  __typename: "PushMailing";
  id: string;
  createAt: string;
  updateadAt: string;
  status: PushMailingStatus;
  target: CreatePushMailing_createPushMailing_target;
  content: CreatePushMailing_createPushMailing_content;
  scheduledAt: string | null;
  sentAt: string | null;
  openingCount: number;
  receivingCount: number;
}

export interface CreatePushMailing {
  createPushMailing: CreatePushMailing_createPushMailing;
}

export interface CreatePushMailingVariables {
  input: PushMailingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListPushMailing
// ====================================================

export interface ListPushMailing_listPushMailing_edges_node_target {
  __typename: "PushMailingTarget";
  platform: PushMailingPlatform;
}

export interface ListPushMailing_listPushMailing_edges_node_content {
  __typename: "PushMailingContent";
  title: string;
  message: string;
  image: string | null;
  navigation: string | null;
}

export interface ListPushMailing_listPushMailing_edges_node {
  __typename: "PushMailing";
  id: string;
  createAt: string;
  updateadAt: string;
  status: PushMailingStatus;
  target: ListPushMailing_listPushMailing_edges_node_target;
  content: ListPushMailing_listPushMailing_edges_node_content;
  scheduledAt: string | null;
  sentAt: string | null;
  openingCount: number;
  receivingCount: number;
}

export interface ListPushMailing_listPushMailing_edges {
  __typename: "PushMailingEdge";
  node: ListPushMailing_listPushMailing_edges_node;
}

export interface ListPushMailing_listPushMailing_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ListPushMailing_listPushMailing {
  __typename: "PushMailingConnection";
  edges: ListPushMailing_listPushMailing_edges[];
  pageInfo: ListPushMailing_listPushMailing_pageInfo;
}

export interface ListPushMailing {
  listPushMailing: ListPushMailing_listPushMailing;
}

export interface ListPushMailingVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  status?: PushMailingStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendPushMailing
// ====================================================

export interface SendPushMailing_sendPushMailing_target {
  __typename: "PushMailingTarget";
  platform: PushMailingPlatform;
}

export interface SendPushMailing_sendPushMailing_content {
  __typename: "PushMailingContent";
  title: string;
  message: string;
  image: string | null;
  navigation: string | null;
}

export interface SendPushMailing_sendPushMailing {
  __typename: "PushMailing";
  id: string;
  createAt: string;
  updateadAt: string;
  status: PushMailingStatus;
  target: SendPushMailing_sendPushMailing_target;
  content: SendPushMailing_sendPushMailing_content;
  scheduledAt: string | null;
  sentAt: string | null;
  openingCount: number;
  receivingCount: number;
}

export interface SendPushMailing {
  sendPushMailing: SendPushMailing_sendPushMailing;
}

export interface SendPushMailingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PushMailing
// ====================================================

export interface PushMailing_pushMailing_target {
  __typename: "PushMailingTarget";
  platform: PushMailingPlatform;
}

export interface PushMailing_pushMailing_content {
  __typename: "PushMailingContent";
  title: string;
  message: string;
  image: string | null;
  navigation: string | null;
}

export interface PushMailing_pushMailing {
  __typename: "PushMailing";
  id: string;
  createAt: string;
  updateadAt: string;
  status: PushMailingStatus;
  target: PushMailing_pushMailing_target;
  content: PushMailing_pushMailing_content;
  scheduledAt: string | null;
  sentAt: string | null;
  openingCount: number;
  receivingCount: number;
}

export interface PushMailing {
  pushMailing: PushMailing_pushMailing;
}

export interface PushMailingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StopPushMailing
// ====================================================

export interface StopPushMailing_stopPushMailing_target {
  __typename: "PushMailingTarget";
  platform: PushMailingPlatform;
}

export interface StopPushMailing_stopPushMailing_content {
  __typename: "PushMailingContent";
  title: string;
  message: string;
  image: string | null;
  navigation: string | null;
}

export interface StopPushMailing_stopPushMailing {
  __typename: "PushMailing";
  id: string;
  createAt: string;
  updateadAt: string;
  status: PushMailingStatus;
  target: StopPushMailing_stopPushMailing_target;
  content: StopPushMailing_stopPushMailing_content;
  scheduledAt: string | null;
  sentAt: string | null;
  openingCount: number;
  receivingCount: number;
}

export interface StopPushMailing {
  stopPushMailing: StopPushMailing_stopPushMailing;
}

export interface StopPushMailingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Settings
// ====================================================

export interface Settings_storeConnections_appStore {
  __typename: "AppStoreConnection";
  id: string;
  teamId: string;
  issuerId: string;
  keyId: string;
  key: string;
}

export interface Settings_storeConnections_googlePlay {
  __typename: "GooglePlayConnection";
  id: string;
  authType: GooglePlayConnectAuthType;
  credentials: string;
  active: boolean;
}

export interface Settings_storeConnections {
  __typename: "StoreConnections";
  id: string;
  appStore: Settings_storeConnections_appStore | null;
  googlePlay: Settings_storeConnections_googlePlay | null;
}

export interface Settings {
  storeConnections: Settings_storeConnections;
  checkAppStoreConnection: boolean;
  checkGooglePlayConnection: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveAppStore
// ====================================================

export interface SaveAppStore_connectAppStore_appStore {
  __typename: "AppStoreConnection";
  id: string;
  teamId: string;
  issuerId: string;
  keyId: string;
}

export interface SaveAppStore_connectAppStore {
  __typename: "StoreConnections";
  id: string;
  appStore: SaveAppStore_connectAppStore_appStore | null;
}

export interface SaveAppStore {
  connectAppStore: SaveAppStore_connectAppStore;
}

export interface SaveAppStoreVariables {
  input: AppStoreConnectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveGooglePlay
// ====================================================

export interface SaveGooglePlay_connectGooglePlay_googlePlay {
  __typename: "GooglePlayConnection";
  id: string;
  authType: GooglePlayConnectAuthType;
  credentials: string;
  active: boolean;
}

export interface SaveGooglePlay_connectGooglePlay {
  __typename: "GooglePlayConnectionWrapper";
  id: string;
  googlePlay: SaveGooglePlay_connectGooglePlay_googlePlay | null;
}

export interface SaveGooglePlay {
  connectGooglePlay: SaveGooglePlay_connectGooglePlay;
}

export interface SaveGooglePlayVariables {
  input: GooglePlayConnectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdListings
// ====================================================

export interface AdListings_adListings_account {
  __typename: "Account";
  id: string;
  domain: string | null;
}

export interface AdListings_adListings_context {
  __typename: "AdListingContext";
  industries: string[];
  tags: string[] | null;
}

export interface AdListings_adListings_content {
  __typename: "AdListingContent";
  id: string;
  contactId: string | null;
}

export interface AdListings_adListings {
  __typename: "AdListing";
  id: string;
  caption: string;
  type: AdListingType;
  account: AdListings_adListings_account;
  createdAt: string;
  updatedAt: string;
  expireAt: string;
  lastViewAt: string | null;
  status: AdListingStatus;
  context: AdListings_adListings_context;
  content: AdListings_adListings_content;
  viewsCount: number;
  clicksCount: number;
  maxSpendings: number;
  bidPerClick: number;
  scope: AdListingScope;
}

export interface AdListings {
  adListings: AdListings_adListings[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdListingsPerformance
// ====================================================

export interface AdListingsPerformance_adListingsPerformance {
  __typename: "AdListingsPerformance";
  date: string;
  clicksCount: number;
  viewsCount: number;
}

export interface AdListingsPerformance {
  adListingsPerformance: AdListingsPerformance_adListingsPerformance[];
}

export interface AdListingsPerformanceVariables {
  days: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListAdListings
// ====================================================

export interface ListAdListings_listAdListings_edges_node_account {
  __typename: "Account";
  id: string;
  domain: string | null;
}

export interface ListAdListings_listAdListings_edges_node_context {
  __typename: "AdListingContext";
  industries: string[];
  tags: string[] | null;
}

export interface ListAdListings_listAdListings_edges_node_content {
  __typename: "AdListingContent";
  id: string;
  contactId: string | null;
}

export interface ListAdListings_listAdListings_edges_node {
  __typename: "AdListing";
  id: string;
  caption: string;
  type: AdListingType;
  account: ListAdListings_listAdListings_edges_node_account;
  createdAt: string;
  updatedAt: string;
  expireAt: string;
  lastViewAt: string | null;
  status: AdListingStatus;
  context: ListAdListings_listAdListings_edges_node_context;
  content: ListAdListings_listAdListings_edges_node_content;
  viewsCount: number;
  clicksCount: number;
  maxSpendings: number;
  bidPerClick: number;
  scope: AdListingScope;
}

export interface ListAdListings_listAdListings_edges {
  __typename: "AdListingEdge";
  node: ListAdListings_listAdListings_edges_node;
}

export interface ListAdListings_listAdListings_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ListAdListings_listAdListings {
  __typename: "AdListingConnection";
  edges: ListAdListings_listAdListings_edges[];
  pageInfo: ListAdListings_listAdListings_pageInfo;
}

export interface ListAdListings {
  listAdListings: ListAdListings_listAdListings;
}

export interface ListAdListingsVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  status: AdListingStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListCommunityListings
// ====================================================

export interface ListCommunityListings_listCommunityListings_edges_node_adListing_account {
  __typename: "Account";
  id: string;
  domain: string | null;
}

export interface ListCommunityListings_listCommunityListings_edges_node_adListing_context {
  __typename: "AdListingContext";
  industries: string[];
  tags: string[] | null;
}

export interface ListCommunityListings_listCommunityListings_edges_node_adListing_content {
  __typename: "AdListingContent";
  id: string;
  contactId: string | null;
}

export interface ListCommunityListings_listCommunityListings_edges_node_adListing {
  __typename: "AdListing";
  id: string;
  caption: string;
  type: AdListingType;
  account: ListCommunityListings_listCommunityListings_edges_node_adListing_account;
  createdAt: string;
  updatedAt: string;
  expireAt: string;
  lastViewAt: string | null;
  status: AdListingStatus;
  context: ListCommunityListings_listCommunityListings_edges_node_adListing_context;
  content: ListCommunityListings_listCommunityListings_edges_node_adListing_content;
  viewsCount: number;
  clicksCount: number;
  maxSpendings: number;
  bidPerClick: number;
  scope: AdListingScope;
}

export interface ListCommunityListings_listCommunityListings_edges_node_storeInfo_image {
  __typename: "Image";
  src: string;
  blurhash: string | null;
}

export interface ListCommunityListings_listCommunityListings_edges_node_storeInfo {
  __typename: "AdListingStoreInfo";
  title: string;
  description: string | null;
  image: ListCommunityListings_listCommunityListings_edges_node_storeInfo_image | null;
}

export interface ListCommunityListings_listCommunityListings_edges_node_content_image {
  __typename: "Image";
  src: string;
  blurhash: string | null;
}

export interface ListCommunityListings_listCommunityListings_edges_node_content {
  __typename: "AdListingContentPreview";
  adListingId: string;
  title: string;
  description: string | null;
  image: ListCommunityListings_listCommunityListings_edges_node_content_image | null;
}

export interface ListCommunityListings_listCommunityListings_edges_node {
  __typename: "CommunityAdListing";
  adListing: ListCommunityListings_listCommunityListings_edges_node_adListing;
  storeInfo: ListCommunityListings_listCommunityListings_edges_node_storeInfo | null;
  content: ListCommunityListings_listCommunityListings_edges_node_content;
}

export interface ListCommunityListings_listCommunityListings_edges {
  __typename: "CommunityAdListingEdge";
  node: ListCommunityListings_listCommunityListings_edges_node;
}

export interface ListCommunityListings_listCommunityListings_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ListCommunityListings_listCommunityListings {
  __typename: "CommunityAdListingConnection";
  edges: ListCommunityListings_listCommunityListings_edges[];
  pageInfo: ListCommunityListings_listCommunityListings_pageInfo;
}

export interface ListCommunityListings {
  listCommunityListings: ListCommunityListings_listCommunityListings;
}

export interface ListCommunityListingsVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListAdListingsItems
// ====================================================

export interface ListAdListingsItems_listAdListingItems_edges_node_adListing_account {
  __typename: "Account";
  id: string;
  domain: string | null;
}

export interface ListAdListingsItems_listAdListingItems_edges_node_adListing_context {
  __typename: "AdListingContext";
  industries: string[];
  tags: string[] | null;
}

export interface ListAdListingsItems_listAdListingItems_edges_node_adListing_content {
  __typename: "AdListingContent";
  id: string;
  contactId: string | null;
}

export interface ListAdListingsItems_listAdListingItems_edges_node_adListing {
  __typename: "AdListing";
  id: string;
  caption: string;
  type: AdListingType;
  account: ListAdListingsItems_listAdListingItems_edges_node_adListing_account;
  createdAt: string;
  updatedAt: string;
  expireAt: string;
  lastViewAt: string | null;
  status: AdListingStatus;
  context: ListAdListingsItems_listAdListingItems_edges_node_adListing_context;
  content: ListAdListingsItems_listAdListingItems_edges_node_adListing_content;
  viewsCount: number;
  clicksCount: number;
  maxSpendings: number;
  bidPerClick: number;
  scope: AdListingScope;
}

export interface ListAdListingsItems_listAdListingItems_edges_node_account_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface ListAdListingsItems_listAdListingItems_edges_node_account_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: ListAdListingsItems_listAdListingItems_edges_node_account_pricing_pricing | null;
}

export interface ListAdListingsItems_listAdListingItems_edges_node_account_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
}

export interface ListAdListingsItems_listAdListingItems_edges_node_account_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface ListAdListingsItems_listAdListingItems_edges_node_account_capabilities {
  __typename: "AccountCapabilities";
  grant: ListAdListingsItems_listAdListingItems_edges_node_account_capabilities_grant[];
}

export interface ListAdListingsItems_listAdListingItems_edges_node_account {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: ListAdListingsItems_listAdListingItems_edges_node_account_pricing;
  monitoringStatus: ListAdListingsItems_listAdListingItems_edges_node_account_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: ListAdListingsItems_listAdListingItems_edges_node_account_capabilities | null;
}

export interface ListAdListingsItems_listAdListingItems_edges_node {
  __typename: "AdListingItem";
  id: string;
  adListing: ListAdListingsItems_listAdListingItems_edges_node_adListing;
  account: ListAdListingsItems_listAdListingItems_edges_node_account;
  createdAt: string;
  status: AdListingItemStatus | null;
  updatedAt: string;
  viewsCount: number;
  lastViewAt: string | null;
  clicksCount: number;
  lastClickAt: string | null;
}

export interface ListAdListingsItems_listAdListingItems_edges {
  __typename: "AdListingItemEdge";
  node: ListAdListingsItems_listAdListingItems_edges_node;
}

export interface ListAdListingsItems_listAdListingItems_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ListAdListingsItems_listAdListingItems {
  __typename: "AdListingItemConnection";
  edges: ListAdListingsItems_listAdListingItems_edges[];
  pageInfo: ListAdListingsItems_listAdListingItems_pageInfo;
}

export interface ListAdListingsItems {
  listAdListingItems: ListAdListingsItems_listAdListingItems;
}

export interface ListAdListingsItemsVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  status: AdListingItemStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdListingContentPreview
// ====================================================

export interface AdListingContentPreview_previewListingContent_image {
  __typename: "Image";
  src: string;
  blurhash: string | null;
}

export interface AdListingContentPreview_previewListingContent {
  __typename: "AdListingContentPreview";
  adListingId: string;
  title: string;
  description: string | null;
  image: AdListingContentPreview_previewListingContent_image | null;
}

export interface AdListingContentPreview {
  previewListingContent: AdListingContentPreview_previewListingContent;
}

export interface AdListingContentPreviewVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddOrUpdateAdListing
// ====================================================

export interface AddOrUpdateAdListing_updateAdListing_account {
  __typename: "Account";
  id: string;
  domain: string | null;
}

export interface AddOrUpdateAdListing_updateAdListing_context {
  __typename: "AdListingContext";
  industries: string[];
  tags: string[] | null;
}

export interface AddOrUpdateAdListing_updateAdListing_content {
  __typename: "AdListingContent";
  id: string;
  contactId: string | null;
}

export interface AddOrUpdateAdListing_updateAdListing {
  __typename: "AdListing";
  id: string;
  caption: string;
  type: AdListingType;
  account: AddOrUpdateAdListing_updateAdListing_account;
  createdAt: string;
  updatedAt: string;
  expireAt: string;
  lastViewAt: string | null;
  status: AdListingStatus;
  context: AddOrUpdateAdListing_updateAdListing_context;
  content: AddOrUpdateAdListing_updateAdListing_content;
  viewsCount: number;
  clicksCount: number;
  maxSpendings: number;
  bidPerClick: number;
  scope: AdListingScope;
}

export interface AddOrUpdateAdListing {
  updateAdListing: AddOrUpdateAdListing_updateAdListing;
}

export interface AddOrUpdateAdListingVariables {
  account?: string | null;
  id?: string | null;
  input: AdListingUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAdListing
// ====================================================

export interface CreateAdListing_createAdListing_account {
  __typename: "Account";
  id: string;
  domain: string | null;
}

export interface CreateAdListing_createAdListing_context {
  __typename: "AdListingContext";
  industries: string[];
  tags: string[] | null;
}

export interface CreateAdListing_createAdListing_content {
  __typename: "AdListingContent";
  id: string;
  contactId: string | null;
}

export interface CreateAdListing_createAdListing {
  __typename: "AdListing";
  id: string;
  caption: string;
  type: AdListingType;
  account: CreateAdListing_createAdListing_account;
  createdAt: string;
  updatedAt: string;
  expireAt: string;
  lastViewAt: string | null;
  status: AdListingStatus;
  context: CreateAdListing_createAdListing_context;
  content: CreateAdListing_createAdListing_content;
  viewsCount: number;
  clicksCount: number;
  maxSpendings: number;
  bidPerClick: number;
  scope: AdListingScope;
}

export interface CreateAdListing {
  createAdListing: CreateAdListing_createAdListing;
}

export interface CreateAdListingVariables {
  account?: string | null;
  input: AdListingUpdateInput;
  target?: AdListingItemTargeted | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeactivateAdLising
// ====================================================

export interface DeactivateAdLising_deactivateLising_account {
  __typename: "Account";
  id: string;
  domain: string | null;
}

export interface DeactivateAdLising_deactivateLising_context {
  __typename: "AdListingContext";
  industries: string[];
  tags: string[] | null;
}

export interface DeactivateAdLising_deactivateLising_content {
  __typename: "AdListingContent";
  id: string;
  contactId: string | null;
}

export interface DeactivateAdLising_deactivateLising {
  __typename: "AdListing";
  id: string;
  caption: string;
  type: AdListingType;
  account: DeactivateAdLising_deactivateLising_account;
  createdAt: string;
  updatedAt: string;
  expireAt: string;
  lastViewAt: string | null;
  status: AdListingStatus;
  context: DeactivateAdLising_deactivateLising_context;
  content: DeactivateAdLising_deactivateLising_content;
  viewsCount: number;
  clicksCount: number;
  maxSpendings: number;
  bidPerClick: number;
  scope: AdListingScope;
}

export interface DeactivateAdLising {
  deactivateLising: DeactivateAdLising_deactivateLising;
}

export interface DeactivateAdLisingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptCommunityListing
// ====================================================

export interface AcceptCommunityListing_acceptCommunityListing_adListing_account {
  __typename: "Account";
  id: string;
  domain: string | null;
}

export interface AcceptCommunityListing_acceptCommunityListing_adListing_context {
  __typename: "AdListingContext";
  industries: string[];
  tags: string[] | null;
}

export interface AcceptCommunityListing_acceptCommunityListing_adListing_content {
  __typename: "AdListingContent";
  id: string;
  contactId: string | null;
}

export interface AcceptCommunityListing_acceptCommunityListing_adListing {
  __typename: "AdListing";
  id: string;
  caption: string;
  type: AdListingType;
  account: AcceptCommunityListing_acceptCommunityListing_adListing_account;
  createdAt: string;
  updatedAt: string;
  expireAt: string;
  lastViewAt: string | null;
  status: AdListingStatus;
  context: AcceptCommunityListing_acceptCommunityListing_adListing_context;
  content: AcceptCommunityListing_acceptCommunityListing_adListing_content;
  viewsCount: number;
  clicksCount: number;
  maxSpendings: number;
  bidPerClick: number;
  scope: AdListingScope;
}

export interface AcceptCommunityListing_acceptCommunityListing_account_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface AcceptCommunityListing_acceptCommunityListing_account_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AcceptCommunityListing_acceptCommunityListing_account_pricing_pricing | null;
}

export interface AcceptCommunityListing_acceptCommunityListing_account_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
}

export interface AcceptCommunityListing_acceptCommunityListing_account_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface AcceptCommunityListing_acceptCommunityListing_account_capabilities {
  __typename: "AccountCapabilities";
  grant: AcceptCommunityListing_acceptCommunityListing_account_capabilities_grant[];
}

export interface AcceptCommunityListing_acceptCommunityListing_account {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AcceptCommunityListing_acceptCommunityListing_account_pricing;
  monitoringStatus: AcceptCommunityListing_acceptCommunityListing_account_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: AcceptCommunityListing_acceptCommunityListing_account_capabilities | null;
}

export interface AcceptCommunityListing_acceptCommunityListing {
  __typename: "AdListingItem";
  id: string;
  adListing: AcceptCommunityListing_acceptCommunityListing_adListing;
  account: AcceptCommunityListing_acceptCommunityListing_account;
  createdAt: string;
  status: AdListingItemStatus | null;
  updatedAt: string;
  viewsCount: number;
  lastViewAt: string | null;
  clicksCount: number;
  lastClickAt: string | null;
}

export interface AcceptCommunityListing {
  acceptCommunityListing: AcceptCommunityListing_acceptCommunityListing;
}

export interface AcceptCommunityListingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptTargetedListing
// ====================================================

export interface AcceptTargetedListing_acceptTargetedListing_adListing_account {
  __typename: "Account";
  id: string;
  domain: string | null;
}

export interface AcceptTargetedListing_acceptTargetedListing_adListing_context {
  __typename: "AdListingContext";
  industries: string[];
  tags: string[] | null;
}

export interface AcceptTargetedListing_acceptTargetedListing_adListing_content {
  __typename: "AdListingContent";
  id: string;
  contactId: string | null;
}

export interface AcceptTargetedListing_acceptTargetedListing_adListing {
  __typename: "AdListing";
  id: string;
  caption: string;
  type: AdListingType;
  account: AcceptTargetedListing_acceptTargetedListing_adListing_account;
  createdAt: string;
  updatedAt: string;
  expireAt: string;
  lastViewAt: string | null;
  status: AdListingStatus;
  context: AcceptTargetedListing_acceptTargetedListing_adListing_context;
  content: AcceptTargetedListing_acceptTargetedListing_adListing_content;
  viewsCount: number;
  clicksCount: number;
  maxSpendings: number;
  bidPerClick: number;
  scope: AdListingScope;
}

export interface AcceptTargetedListing_acceptTargetedListing_account_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface AcceptTargetedListing_acceptTargetedListing_account_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AcceptTargetedListing_acceptTargetedListing_account_pricing_pricing | null;
}

export interface AcceptTargetedListing_acceptTargetedListing_account_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
}

export interface AcceptTargetedListing_acceptTargetedListing_account_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface AcceptTargetedListing_acceptTargetedListing_account_capabilities {
  __typename: "AccountCapabilities";
  grant: AcceptTargetedListing_acceptTargetedListing_account_capabilities_grant[];
}

export interface AcceptTargetedListing_acceptTargetedListing_account {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AcceptTargetedListing_acceptTargetedListing_account_pricing;
  monitoringStatus: AcceptTargetedListing_acceptTargetedListing_account_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: AcceptTargetedListing_acceptTargetedListing_account_capabilities | null;
}

export interface AcceptTargetedListing_acceptTargetedListing {
  __typename: "AdListingItem";
  id: string;
  adListing: AcceptTargetedListing_acceptTargetedListing_adListing;
  account: AcceptTargetedListing_acceptTargetedListing_account;
  createdAt: string;
  status: AdListingItemStatus | null;
  updatedAt: string;
  viewsCount: number;
  lastViewAt: string | null;
  clicksCount: number;
  lastClickAt: string | null;
}

export interface AcceptTargetedListing {
  acceptTargetedListing: AcceptTargetedListing_acceptTargetedListing;
}

export interface AcceptTargetedListingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PreviewListingContent
// ====================================================

export interface PreviewListingContent_previewListingContent_image {
  __typename: "Image";
  src: string;
}

export interface PreviewListingContent_previewListingContent {
  __typename: "AdListingContentPreview";
  adListingId: string;
  title: string;
  description: string | null;
  image: PreviewListingContent_previewListingContent_image | null;
}

export interface PreviewListingContent {
  previewListingContent: PreviewListingContent_previewListingContent;
}

export interface PreviewListingContentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdListingSummury
// ====================================================

export interface AdListingSummury_adListingSummury {
  __typename: "AdListingSummury";
  clicks: number;
  views: number;
  positionsCount: number;
}

export interface AdListingSummury {
  adListingSummury: AdListingSummury_adListingSummury;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IncomingRequestsCount
// ====================================================

export interface IncomingRequestsCount {
  incomingRequestsCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StopAdListingItem
// ====================================================

export interface StopAdListingItem {
  stopAdListingItem: boolean;
}

export interface StopAdListingItemVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FriendlyStores
// ====================================================

export interface FriendlyStores_friendlyStores_contact_data_socials {
  __typename: "AccountContactSocial";
  socialName: string;
  socialId: string;
}

export interface FriendlyStores_friendlyStores_contact_data {
  __typename: "AccountContactData";
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
  socials: FriendlyStores_friendlyStores_contact_data_socials[] | null;
  visible: boolean | null;
}

export interface FriendlyStores_friendlyStores_contact {
  __typename: "AccountContact";
  id: string;
  type: AccountContactType;
  data: FriendlyStores_friendlyStores_contact_data;
}

export interface FriendlyStores_friendlyStores {
  __typename: "FriendlyStoreContact";
  contact: FriendlyStores_friendlyStores_contact;
  acceptAllRequests: boolean;
}

export interface FriendlyStores {
  friendlyStores: FriendlyStores_friendlyStores[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListCommunityStores
// ====================================================

export interface ListCommunityStores_listCommunityStores_edges_node_contact_data_socials {
  __typename: "AccountContactSocial";
  socialName: string;
  socialId: string;
}

export interface ListCommunityStores_listCommunityStores_edges_node_contact_data {
  __typename: "AccountContactData";
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
  socials: ListCommunityStores_listCommunityStores_edges_node_contact_data_socials[] | null;
  visible: boolean | null;
}

export interface ListCommunityStores_listCommunityStores_edges_node_contact {
  __typename: "AccountContact";
  id: string;
  type: AccountContactType;
  data: ListCommunityStores_listCommunityStores_edges_node_contact_data;
}

export interface ListCommunityStores_listCommunityStores_edges_node {
  __typename: "FriendlyStoreContact";
  contact: ListCommunityStores_listCommunityStores_edges_node_contact;
  acceptAllRequests: boolean;
}

export interface ListCommunityStores_listCommunityStores_edges {
  __typename: "FriendlyStoreContactEdge";
  node: ListCommunityStores_listCommunityStores_edges_node;
}

export interface ListCommunityStores_listCommunityStores_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ListCommunityStores_listCommunityStores {
  __typename: "FriendlyStoreContactConnection";
  edges: ListCommunityStores_listCommunityStores_edges[];
  pageInfo: ListCommunityStores_listCommunityStores_pageInfo;
}

export interface ListCommunityStores {
  listCommunityStores: ListCommunityStores_listCommunityStores;
}

export interface ListCommunityStoresVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddOrUpdateFriendlyStore
// ====================================================

export interface AddOrUpdateFriendlyStore_addOrUpdateFriendlyStore_contact_data_socials {
  __typename: "AccountContactSocial";
  socialName: string;
  socialId: string;
}

export interface AddOrUpdateFriendlyStore_addOrUpdateFriendlyStore_contact_data {
  __typename: "AccountContactData";
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
  socials: AddOrUpdateFriendlyStore_addOrUpdateFriendlyStore_contact_data_socials[] | null;
  visible: boolean | null;
}

export interface AddOrUpdateFriendlyStore_addOrUpdateFriendlyStore_contact {
  __typename: "AccountContact";
  id: string;
  type: AccountContactType;
  data: AddOrUpdateFriendlyStore_addOrUpdateFriendlyStore_contact_data;
}

export interface AddOrUpdateFriendlyStore_addOrUpdateFriendlyStore {
  __typename: "FriendlyStoreContact";
  contact: AddOrUpdateFriendlyStore_addOrUpdateFriendlyStore_contact;
  acceptAllRequests: boolean;
}

export interface AddOrUpdateFriendlyStore {
  addOrUpdateFriendlyStore: AddOrUpdateFriendlyStore_addOrUpdateFriendlyStore;
}

export interface AddOrUpdateFriendlyStoreVariables {
  input: FriendlyStoreInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListPointsTransactions
// ====================================================

export interface ListPointsTransactions_listPointsTransactions_edges_node_account_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface ListPointsTransactions_listPointsTransactions_edges_node_account_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: ListPointsTransactions_listPointsTransactions_edges_node_account_pricing_pricing | null;
}

export interface ListPointsTransactions_listPointsTransactions_edges_node_account_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
}

export interface ListPointsTransactions_listPointsTransactions_edges_node_account_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface ListPointsTransactions_listPointsTransactions_edges_node_account_capabilities {
  __typename: "AccountCapabilities";
  grant: ListPointsTransactions_listPointsTransactions_edges_node_account_capabilities_grant[];
}

export interface ListPointsTransactions_listPointsTransactions_edges_node_account {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: ListPointsTransactions_listPointsTransactions_edges_node_account_pricing;
  monitoringStatus: ListPointsTransactions_listPointsTransactions_edges_node_account_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: ListPointsTransactions_listPointsTransactions_edges_node_account_capabilities | null;
}

export interface ListPointsTransactions_listPointsTransactions_edges_node {
  __typename: "AccountCoinTransaction";
  id: string;
  type: CoinTransactionType;
  account: ListPointsTransactions_listPointsTransactions_edges_node_account;
  amount: number;
  createdAt: string;
  prevHash: string | null;
  reason: string;
}

export interface ListPointsTransactions_listPointsTransactions_edges {
  __typename: "AccountCoinTransactionEdge";
  node: ListPointsTransactions_listPointsTransactions_edges_node;
}

export interface ListPointsTransactions_listPointsTransactions_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ListPointsTransactions_listPointsTransactions {
  __typename: "AccountCoinTransactionConnection";
  edges: ListPointsTransactions_listPointsTransactions_edges[];
  pageInfo: ListPointsTransactions_listPointsTransactions_pageInfo;
}

export interface ListPointsTransactions {
  listPointsTransactions: ListPointsTransactions_listPointsTransactions;
}

export interface ListPointsTransactionsVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  reasonType?: CoinTransactionReasonType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ShowAdListingContent
// ====================================================

export interface ShowAdListingContent_showAdListingContent_image {
  __typename: "BlurHashImage";
  src: string;
  blurhash: string;
}

export interface ShowAdListingContent_showAdListingContent {
  __typename: "AdListingItemShowContent";
  id: string;
  appId: string;
  type: AdListingType;
  title: string;
  description: string | null;
  image: ShowAdListingContent_showAdListingContent_image | null;
}

export interface ShowAdListingContent {
  showAdListingContent: ShowAdListingContent_showAdListingContent;
}

export interface ShowAdListingContentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExchangeEarnings
// ====================================================

export interface ExchangeEarnings {
  exchangeEarnings: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCoinCharge
// ====================================================

export interface CreateCoinCharge_createCoinChargeRequest {
  __typename: "AccountCoinCharge";
  id: string;
  amount: number;
  createdAt: string;
  updatedAt: string;
  status: AccountCoinChargeStatus;
  confirmUrl: string | null;
}

export interface CreateCoinCharge {
  createCoinChargeRequest: CreateCoinCharge_createCoinChargeRequest;
}

export interface CreateCoinChargeVariables {
  input: AccountCoinChargeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCoinsPrice
// ====================================================

export interface GetCoinsPrice {
  getCoinsPrice: number;
}

export interface GetCoinsPriceVariables {
  input: AccountCoinChargeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfitmCoinCharge
// ====================================================

export interface ConfitmCoinCharge_confirmCoinCharge {
  __typename: "AccountCoinCharge";
  id: string;
  amount: number;
  createdAt: string;
  updatedAt: string;
  status: AccountCoinChargeStatus;
  confirmUrl: string | null;
}

export interface ConfitmCoinCharge {
  confirmCoinCharge: ConfitmCoinCharge_confirmCoinCharge;
}

export interface ConfitmCoinChargeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PricingBaseFragment
// ====================================================

export interface PricingBaseFragment {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AccountPricingFragment
// ====================================================

export interface AccountPricingFragment_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface AccountPricingFragment {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AccountPricingFragment_pricing | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullAccount
// ====================================================

export interface FullAccount_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface FullAccount_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: FullAccount_pricing_pricing | null;
}

export interface FullAccount_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
}

export interface FullAccount_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface FullAccount_capabilities {
  __typename: "AccountCapabilities";
  grant: FullAccount_capabilities_grant[];
}

export interface FullAccount {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: FullAccount_pricing;
  monitoringStatus: FullAccount_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: FullAccount_capabilities | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullSurveyIndustry
// ====================================================

export interface FullSurveyIndustry {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
  sort: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContactFragment
// ====================================================

export interface ContactFragment_data_socials {
  __typename: "AccountContactSocial";
  socialName: string;
  socialId: string;
}

export interface ContactFragment_data {
  __typename: "AccountContactData";
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
  socials: ContactFragment_data_socials[] | null;
  visible: boolean | null;
}

export interface ContactFragment {
  __typename: "AccountContact";
  id: string;
  type: AccountContactType;
  data: ContactFragment_data;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AccountPricingLimitFragment
// ====================================================

export interface AccountPricingLimitFragment {
  __typename: "AccountPricingLimit";
  id: string;
  key: string;
  status: AccountPricingLimitStatus;
  period: number;
  limit: number;
  count: number;
  createdAt: string;
  updatedAt: string;
  lastSyncDate: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminPricingFragment
// ====================================================

export interface AdminPricingFragment {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  price: number;
  trialDays: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminAccountMonitoring
// ====================================================

export interface AdminAccountMonitoring_data_catalogCounters_shopify {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountMonitoring_data_catalogCounters_database {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountMonitoring_data_catalogCounters_index {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountMonitoring_data_catalogCounters {
  __typename: "AccountMonitoringCatalogCounters";
  shopify: AdminAccountMonitoring_data_catalogCounters_shopify;
  database: AdminAccountMonitoring_data_catalogCounters_database;
  index: AdminAccountMonitoring_data_catalogCounters_index;
}

export interface AdminAccountMonitoring_data {
  __typename: "AccountMonitoringData";
  catalogCounters: AdminAccountMonitoring_data_catalogCounters;
}

export interface AdminAccountMonitoring {
  __typename: "AccountMonitoring";
  id: string;
  status: AccountMonitoringStatus;
  message: string | null;
  createdAt: string;
  data: AdminAccountMonitoring_data;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminAccount
// ====================================================

export interface AdminAccount_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface AdminAccount_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AdminAccount_pricing_pricing | null;
}

export interface AdminAccount_monitoringStatus_data_catalogCounters_shopify {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccount_monitoringStatus_data_catalogCounters_database {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccount_monitoringStatus_data_catalogCounters_index {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccount_monitoringStatus_data_catalogCounters {
  __typename: "AccountMonitoringCatalogCounters";
  shopify: AdminAccount_monitoringStatus_data_catalogCounters_shopify;
  database: AdminAccount_monitoringStatus_data_catalogCounters_database;
  index: AdminAccount_monitoringStatus_data_catalogCounters_index;
}

export interface AdminAccount_monitoringStatus_data {
  __typename: "AccountMonitoringData";
  catalogCounters: AdminAccount_monitoringStatus_data_catalogCounters;
}

export interface AdminAccount_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
  id: string;
  message: string | null;
  createdAt: string;
  data: AdminAccount_monitoringStatus_data;
}

export interface AdminAccount_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface AdminAccount_capabilities {
  __typename: "AccountCapabilities";
  grant: AdminAccount_capabilities_grant[];
}

export interface AdminAccount {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AdminAccount_pricing;
  monitoringStatus: AdminAccount_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: AdminAccount_capabilities | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AccountMonitoringCounterFragment
// ====================================================

export interface AccountMonitoringCounterFragment {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppFragment
// ====================================================

export interface AppFragment_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface AppFragment_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface AppFragment_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: AppFragment_draftConfig_design_colors_light;
  dark: AppFragment_draftConfig_design_colors_dark;
}

export interface AppFragment_draftConfig_design {
  __typename: "AppDesign";
  colors: AppFragment_draftConfig_design_colors;
}

export interface AppFragment_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: AppFragment_draftConfig_design;
}

export interface AppFragment_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  buttonTextColor: string | null;
  buttonColor: string | null;
  buttonTextFontWeight: string | null;
  text: string;
  textColor: string | null;
  textFontWeight: string | null;
  title: string;
  titleColor: string | null;
  titleFontWeight: string | null;
  icon: string;
  backgroundColor: string | null;
  stickToBottom: boolean | null;
  hideIcon: boolean | null;
  fullScreen: boolean | null;
}

export interface AppFragment_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: AppFragment_settings_banner_params | null;
}

export interface AppFragment_settings {
  __typename: "AppSettings";
  banner: AppFragment_settings_banner | null;
  logo: string | null;
}

export interface AppFragment {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: AppFragment_draftConfig;
  settings: AppFragment_settings | null;
  freeTrafficActive: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ThemeColors
// ====================================================

export interface ThemeColors {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ThemesColors
// ====================================================

export interface ThemesColors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface ThemesColors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface ThemesColors {
  __typename: "AppDesignThemes";
  light: ThemesColors_light;
  dark: ThemesColors_dark;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Config
// ====================================================

export interface Config_connection {
  __typename: "AppConnection";
  token: string;
  endpoint: string;
}

export interface Config_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface Config_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface Config_design_colors {
  __typename: "AppDesignThemes";
  light: Config_design_colors_light;
  dark: Config_design_colors_dark;
}

export interface Config_design_values {
  __typename: "AppDesignValues";
  toolBarTextSize: number | null;
  toolBarTitleTextSize: number | null;
  bottomNavigationTextSize: number | null;
  buttonTextSize: number | null;
  headLineTextSize: number | null;
  subtitleTextSize: number | null;
  bodyTextSize: number | null;
}

export interface Config_design {
  __typename: "AppDesign";
  colors: Config_design_colors;
  values: Config_design_values;
}

export interface Config_tabs_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface Config_tabs {
  __typename: "AppTab";
  id: string;
  title: string;
  url: string | null;
  icon: Config_tabs_icon | null;
}

export interface Config {
  __typename: "AppConfig";
  id: string;
  connection: Config_connection;
  design: Config_design;
  tabs: Config_tabs[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ConfigFragment
// ====================================================

export interface ConfigFragment_design_colors_light {
  __typename: "AppDesignColors";
  accent: string;
}

export interface ConfigFragment_design_colors {
  __typename: "AppDesignThemes";
  light: ConfigFragment_design_colors_light;
}

export interface ConfigFragment_design {
  __typename: "AppDesign";
  designerData: string;
  colors: ConfigFragment_design_colors;
}

export interface ConfigFragment {
  __typename: "AppConfig";
  id: string;
  design: ConfigFragment_design;
  icon: string | null;
  splash: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IconFragment
// ====================================================

export interface IconFragment {
  __typename: "Icon";
  collection: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TabsDesignerFragment
// ====================================================

export interface TabsDesignerFragment_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface TabsDesignerFragment {
  __typename: "AppDesignerTab";
  key: string;
  title: string;
  default: boolean;
  contentType: ContentReferenceType;
  contentKey: string | null;
  contentUrl: string | null;
  icon: TabsDesignerFragment_icon | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TabsConfigFragment
// ====================================================

export interface TabsConfigFragment_tabsDesigner_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface TabsConfigFragment_tabsDesigner {
  __typename: "AppDesignerTab";
  key: string;
  title: string;
  default: boolean;
  contentType: ContentReferenceType;
  contentKey: string | null;
  contentUrl: string | null;
  icon: TabsConfigFragment_tabsDesigner_icon | null;
}

export interface TabsConfigFragment_moreTabsDesigner_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface TabsConfigFragment_moreTabsDesigner {
  __typename: "AppDesignerTab";
  key: string;
  title: string;
  default: boolean;
  contentType: ContentReferenceType;
  contentKey: string | null;
  contentUrl: string | null;
  icon: TabsConfigFragment_moreTabsDesigner_icon | null;
}

export interface TabsConfigFragment {
  __typename: "AppConfig";
  id: string;
  tabsDesigner: TabsConfigFragment_tabsDesigner[];
  moreTabsDesigner: TabsConfigFragment_moreTabsDesigner[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ImageFragment
// ====================================================

export interface ImageFragment {
  __typename: "Image";
  src: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppCardContentFragment
// ====================================================

export interface AppCardContentFragment_icon {
  __typename: "Image";
  src: string;
}

export interface AppCardContentFragment_splash {
  __typename: "Image";
  src: string;
}

export interface AppCardContentFragment_background {
  __typename: "Image";
  src: string;
}

export interface AppCardContentFragment_app {
  __typename: "App";
  id: string;
}

export interface AppCardContentFragment_data_industries {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
}

export interface AppCardContentFragment_data {
  __typename: "AppCardData";
  industries: AppCardContentFragment_data_industries[];
}

export interface AppCardContentFragment {
  __typename: "AppCardPublished" | "AppCard";
  title: string;
  description: string;
  icon: AppCardContentFragment_icon | null;
  splash: AppCardContentFragment_splash | null;
  background: AppCardContentFragment_background | null;
  createdAt: string;
  app: AppCardContentFragment_app;
  data: AppCardContentFragment_data | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppCardFragment
// ====================================================

export interface AppCardFragment_icon {
  __typename: "Image";
  src: string;
}

export interface AppCardFragment_splash {
  __typename: "Image";
  src: string;
}

export interface AppCardFragment_background {
  __typename: "Image";
  src: string;
}

export interface AppCardFragment_app {
  __typename: "App";
  id: string;
}

export interface AppCardFragment_data_industries {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
}

export interface AppCardFragment_data {
  __typename: "AppCardData";
  industries: AppCardFragment_data_industries[];
}

export interface AppCardFragment {
  __typename: "AppCard";
  id: string;
  status: AppCardStatus;
  updatedAt: string | null;
  title: string;
  description: string;
  icon: AppCardFragment_icon | null;
  splash: AppCardFragment_splash | null;
  background: AppCardFragment_background | null;
  createdAt: string;
  app: AppCardFragment_app;
  data: AppCardFragment_data | null;
  currency: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppCardPublishedFragment
// ====================================================

export interface AppCardPublishedFragment_icon {
  __typename: "Image";
  src: string;
}

export interface AppCardPublishedFragment_splash {
  __typename: "Image";
  src: string;
}

export interface AppCardPublishedFragment_background {
  __typename: "Image";
  src: string;
}

export interface AppCardPublishedFragment_app {
  __typename: "App";
  id: string;
}

export interface AppCardPublishedFragment_data_industries {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
}

export interface AppCardPublishedFragment_data {
  __typename: "AppCardData";
  industries: AppCardPublishedFragment_data_industries[];
}

export interface AppCardPublishedFragment {
  __typename: "AppCardPublished";
  id: string;
  status: AppCardPublishedStatus;
  publishedAt: string;
  title: string;
  description: string;
  icon: AppCardPublishedFragment_icon | null;
  splash: AppCardPublishedFragment_splash | null;
  background: AppCardPublishedFragment_background | null;
  createdAt: string;
  app: AppCardPublishedFragment_app;
  data: AppCardPublishedFragment_data | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullPage
// ====================================================

export interface FullPage {
  __typename: "PageEditor";
  id: string;
  name: string;
  draftData: string;
  publishedData: string | null;
  contentPageId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PricingFragment
// ====================================================

export interface PricingFragment {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  price: number;
  trialDays: number;
  status: PricingStatus;
  isDefault: boolean;
  hidden: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductVariantDescr
// ====================================================

export interface ProductVariantDescr {
  __typename: "ProductVariant";
  id: string;
  price: string;
  title: string;
  formatted_price: string | null;
  compare_at_price: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductDescr
// ====================================================

export interface ProductDescr_images {
  __typename: "Image";
  id: string;
  src: string;
  blurhash: string | null;
}

export interface ProductDescr_variants {
  __typename: "ProductVariant";
  id: string;
  price: string;
  title: string;
  formatted_price: string | null;
  compare_at_price: string | null;
}

export interface ProductDescr {
  __typename: "Product";
  id: string;
  title: string;
  images: (ProductDescr_images | null)[] | null;
  variants: (ProductDescr_variants | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CollectionDescr
// ====================================================

export interface CollectionDescr_image {
  __typename: "Image";
  id: string;
  src: string;
  blurhash: string | null;
}

export interface CollectionDescr {
  __typename: "Collection";
  id: string;
  title: string;
  image: CollectionDescr_image | null;
  itemsCount: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PushMailingFragment
// ====================================================

export interface PushMailingFragment_target {
  __typename: "PushMailingTarget";
  platform: PushMailingPlatform;
}

export interface PushMailingFragment_content {
  __typename: "PushMailingContent";
  title: string;
  message: string;
  image: string | null;
  navigation: string | null;
}

export interface PushMailingFragment {
  __typename: "PushMailing";
  id: string;
  createAt: string;
  updateadAt: string;
  status: PushMailingStatus;
  target: PushMailingFragment_target;
  content: PushMailingFragment_content;
  scheduledAt: string | null;
  sentAt: string | null;
  openingCount: number;
  receivingCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdListingFragment
// ====================================================

export interface AdListingFragment_account {
  __typename: "Account";
  id: string;
  domain: string | null;
}

export interface AdListingFragment_context {
  __typename: "AdListingContext";
  industries: string[];
  tags: string[] | null;
}

export interface AdListingFragment_content {
  __typename: "AdListingContent";
  id: string;
  contactId: string | null;
}

export interface AdListingFragment {
  __typename: "AdListing";
  id: string;
  caption: string;
  type: AdListingType;
  account: AdListingFragment_account;
  createdAt: string;
  updatedAt: string;
  expireAt: string;
  lastViewAt: string | null;
  status: AdListingStatus;
  context: AdListingFragment_context;
  content: AdListingFragment_content;
  viewsCount: number;
  clicksCount: number;
  maxSpendings: number;
  bidPerClick: number;
  scope: AdListingScope;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AccountCoinChargeFragment
// ====================================================

export interface AccountCoinChargeFragment {
  __typename: "AccountCoinCharge";
  id: string;
  amount: number;
  createdAt: string;
  updatedAt: string;
  status: AccountCoinChargeStatus;
  confirmUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageInfoFragment
// ====================================================

export interface PageInfoFragment {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdListingItemFragment
// ====================================================

export interface AdListingItemFragment_adListing_account {
  __typename: "Account";
  id: string;
  domain: string | null;
}

export interface AdListingItemFragment_adListing_context {
  __typename: "AdListingContext";
  industries: string[];
  tags: string[] | null;
}

export interface AdListingItemFragment_adListing_content {
  __typename: "AdListingContent";
  id: string;
  contactId: string | null;
}

export interface AdListingItemFragment_adListing {
  __typename: "AdListing";
  id: string;
  caption: string;
  type: AdListingType;
  account: AdListingItemFragment_adListing_account;
  createdAt: string;
  updatedAt: string;
  expireAt: string;
  lastViewAt: string | null;
  status: AdListingStatus;
  context: AdListingItemFragment_adListing_context;
  content: AdListingItemFragment_adListing_content;
  viewsCount: number;
  clicksCount: number;
  maxSpendings: number;
  bidPerClick: number;
  scope: AdListingScope;
}

export interface AdListingItemFragment_account_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface AdListingItemFragment_account_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AdListingItemFragment_account_pricing_pricing | null;
}

export interface AdListingItemFragment_account_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
}

export interface AdListingItemFragment_account_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface AdListingItemFragment_account_capabilities {
  __typename: "AccountCapabilities";
  grant: AdListingItemFragment_account_capabilities_grant[];
}

export interface AdListingItemFragment_account {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AdListingItemFragment_account_pricing;
  monitoringStatus: AdListingItemFragment_account_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: AdListingItemFragment_account_capabilities | null;
}

export interface AdListingItemFragment {
  __typename: "AdListingItem";
  id: string;
  adListing: AdListingItemFragment_adListing;
  account: AdListingItemFragment_account;
  createdAt: string;
  status: AdListingItemStatus | null;
  updatedAt: string;
  viewsCount: number;
  lastViewAt: string | null;
  clicksCount: number;
  lastClickAt: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FriendlyStoreContactFragment
// ====================================================

export interface FriendlyStoreContactFragment_contact_data_socials {
  __typename: "AccountContactSocial";
  socialName: string;
  socialId: string;
}

export interface FriendlyStoreContactFragment_contact_data {
  __typename: "AccountContactData";
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
  socials: FriendlyStoreContactFragment_contact_data_socials[] | null;
  visible: boolean | null;
}

export interface FriendlyStoreContactFragment_contact {
  __typename: "AccountContact";
  id: string;
  type: AccountContactType;
  data: FriendlyStoreContactFragment_contact_data;
}

export interface FriendlyStoreContactFragment {
  __typename: "FriendlyStoreContact";
  contact: FriendlyStoreContactFragment_contact;
  acceptAllRequests: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AccountCoinTransactionFragment
// ====================================================

export interface AccountCoinTransactionFragment_account_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
}

export interface AccountCoinTransactionFragment_account_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AccountCoinTransactionFragment_account_pricing_pricing | null;
}

export interface AccountCoinTransactionFragment_account_monitoringStatus {
  __typename: "AccountMonitoring";
  status: AccountMonitoringStatus;
}

export interface AccountCoinTransactionFragment_account_capabilities_grant {
  __typename: "AccountGrant";
  role: string;
  resource: string;
  action: string;
  attributes: string;
}

export interface AccountCoinTransactionFragment_account_capabilities {
  __typename: "AccountCapabilities";
  grant: AccountCoinTransactionFragment_account_capabilities_grant[];
}

export interface AccountCoinTransactionFragment_account {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AccountCoinTransactionFragment_account_pricing;
  monitoringStatus: AccountCoinTransactionFragment_account_monitoringStatus | null;
  cartCoins: number | null;
  domain: string | null;
  capabilities: AccountCoinTransactionFragment_account_capabilities | null;
}

export interface AccountCoinTransactionFragment {
  __typename: "AccountCoinTransaction";
  id: string;
  type: CoinTransactionType;
  account: AccountCoinTransactionFragment_account;
  amount: number;
  createdAt: string;
  prevHash: string | null;
  reason: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountCoinChargeStatus {
  ACTIVE = "ACTIVE",
  CREATED = "CREATED",
  DECLINED = "DECLINED",
  EXPIRED = "EXPIRED",
  PENDING = "PENDING",
}

export enum AccountContactType {
  Base = "Base",
  Billing = "Billing",
  Legal = "Legal",
  Orders = "Orders",
  TrafficExchange = "TrafficExchange",
}

export enum AccountMonitoringStatus {
  CREATED = "CREATED",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
}

export enum AccountPricingLimitStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum AccountPricingStatus {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  CREATED = "CREATED",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
  TRIAL = "TRIAL",
}

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  CREATED = "CREATED",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
  ON_REVIEW = "ON_REVIEW",
}

export enum AdListingItemStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  REJECTED = "REJECTED",
  SUSPENDED = "SUSPENDED",
}

export enum AdListingScope {
  COMMUNITY = "COMMUNITY",
  TARGETED = "TARGETED",
}

export enum AdListingStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum AdListingType {
  COLLECTION = "COLLECTION",
  PRODUCT = "PRODUCT",
  STORE = "STORE",
  STORE_CONTACT = "STORE_CONTACT",
}

export enum AdminUserRole {
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
}

export enum AppCardPublishedStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum AppCardStatus {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  DRAFT = "DRAFT",
  INACTIVE = "INACTIVE",
  ON_REVIEW = "ON_REVIEW",
}

export enum AppPlatforms {
  ANDROID = "ANDROID",
  IOS = "IOS",
}

export enum AppVersionStatuses {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  DRAFT = "DRAFT",
  PRIVATE = "PRIVATE",
}

export enum AppVersionsSorts {
  DATE_CREATED_ASC = "DATE_CREATED_ASC",
  DATE_CREATED_DESC = "DATE_CREATED_DESC",
  VERSION_NUMBER_ASC = "VERSION_NUMBER_ASC",
  VERSION_NUMBER_DESC = "VERSION_NUMBER_DESC",
}

export enum CoinTransactionReasonType {
  ACHIVEMENT = "ACHIVEMENT",
  BUY = "BUY",
  DEFAULT = "DEFAULT",
  EXCHANGE_CLICKS = "EXCHANGE_CLICKS",
  EXCHANGE_RETURN = "EXCHANGE_RETURN",
  EXCHNAGE_NEW_POSITION = "EXCHNAGE_NEW_POSITION",
  NEW_USER = "NEW_USER",
  PUSH_NOTIFICATION = "PUSH_NOTIFICATION",
  RETURN = "RETURN",
  REWARD = "REWARD",
}

export enum CoinTransactionType {
  ADD = "ADD",
  CHARGE = "CHARGE",
}

export enum ContentPageBlockType {
  HTML = "HTML",
  REACT = "REACT",
}

export enum ContentPageType {
  BLOCK = "BLOCK",
  HTML = "HTML",
  URL = "URL",
}

export enum ContentReferenceType {
  ALL_COLLECTIONS = "ALL_COLLECTIONS",
  CART = "CART",
  COLLECTION = "COLLECTION",
  COLLECTIONS = "COLLECTIONS",
  CONTENT = "CONTENT",
  EMPTY = "EMPTY",
  FAVORITES = "FAVORITES",
  FEED = "FEED",
  PRODUCTS_BY_COLLECTIONS = "PRODUCTS_BY_COLLECTIONS",
  SHOP_ORDERS = "SHOP_ORDERS",
}

export enum GooglePlayConnectAuthType {
  SERVICE_ACCOUNT = "SERVICE_ACCOUNT",
}

export enum PricingStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum ProductsSorts {
  ALPHA_ASC = "ALPHA_ASC",
  ALPHA_DESC = "ALPHA_DESC",
  BEST_SELLING = "BEST_SELLING",
  CREATED = "CREATED",
  CREATED_DESC = "CREATED_DESC",
  DEFAULT = "DEFAULT",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
}

export enum PushMailingPlatform {
  ALL = "ALL",
  ANDROID = "ANDROID",
  IOS = "IOS",
}

export enum PushMailingStatus {
  CANCELED = "CANCELED",
  COMPLETE = "COMPLETE",
  DRAFT = "DRAFT",
  FAIL = "FAIL",
  SCHEDULED = "SCHEDULED",
}

export enum Screen {
  COLLECTIONS = "COLLECTIONS",
  PRODUCTS_BY_COLLECTIONS = "PRODUCTS_BY_COLLECTIONS",
}

export enum SyncStatus {
  IN_PROGRES = "IN_PROGRES",
  PENDING = "PENDING",
  READY = "READY",
}

export interface AccountCoinChargeInput {
  amount: number;
}

export interface AccountContactInput {
  email?: string | null;
  phoneNumber?: string | null;
  name?: string | null;
  visible?: boolean | null;
  socials?: AccountContactSocialInput[] | null;
}

export interface AccountContactSocialInput {
  socialName: string;
  socialId: string;
}

export interface AccountCreateInput {
  industries: string[];
  sellingStatus: string;
  currentMonthlySales: string;
  teamSize?: string | null;
  forClient: boolean;
  referrer?: string | null;
}

export interface AdListingContentInput {
  id: string;
  contactId?: string | null;
}

export interface AdListingContextInput {
  industries: string[];
  tags: string[];
}

export interface AdListingItemTargeted {
  stores: string[];
}

export interface AdListingUpdateInput {
  type: AdListingType;
  caption: string;
  expireAt: string;
  context: AdListingContextInput;
  content: AdListingContentInput;
  scope: AdListingScope;
  maxSpendings: number;
  bidPerClick: number;
}

export interface AdminMakeTrialInput {
  timestamp: string;
  hours: string;
  minutes: string;
}

export interface AppBannerInputParams {
  buttonText: string;
  buttonColor?: string | null;
  buttonTextColor?: string | null;
  buttonTextFontWeight?: string | null;
  text: string;
  textColor?: string | null;
  textFontWeight?: string | null;
  title: string;
  titleColor?: string | null;
  titleFontWeight?: string | null;
  icon: string;
  backgroundColor?: string | null;
  stickToBottom?: boolean | null;
  hideIcon?: boolean | null;
  fullScreen?: boolean | null;
}

export interface AppCardInput {
  title: string;
  description: string;
  icon?: string | null;
  splash?: string | null;
  background?: string | null;
  industries: string[];
}

export interface AppConfigurationInput {
  design?: AppDesignInput | null;
  tabs?: AppTabInput[] | null;
  moreTabs?: AppTabInput[] | null;
  logo?: string | null;
}

export interface AppDesignColorInput {
  primary?: string | null;
  primaryText?: string | null;
  accent?: string | null;
  accentText?: string | null;
  background?: string | null;
  backgroundText?: string | null;
  secondary?: string | null;
  secondaryText?: string | null;
  separator?: string | null;
  priceText?: string | null;
  priceOldText?: string | null;
  priceBackground?: string | null;
  iconTint?: string | null;
  accentIconTint?: string | null;
  bottomNavigationIcon?: string | null;
  bottomNavigationInactiveIcon?: string | null;
  bottomNavigationText?: string | null;
  bottomNavigationInactiveText?: string | null;
  bottomNavigationAccent?: string | null;
  bottomNavigationBackground?: string | null;
  toolBarText?: string | null;
  toolBarIcon?: string | null;
  toolBarAccent?: string | null;
  toolBarBackground?: string | null;
  toCartBackground?: string | null;
  toCartText?: string | null;
  toCartIconTint?: string | null;
}

export interface AppDesignInput {
  designerData: string;
  colors?: AppThemesColorsInput | null;
  values?: AppDesignValuesInput | null;
}

export interface AppDesignValuesInput {
  toolBarTextSize?: number | null;
  toolBarTitleTextSize?: number | null;
  bottomNavigationTextSize?: number | null;
  buttonTextSize?: number | null;
  headLineTextSize?: number | null;
  subtitleTextSize?: number | null;
  bodyTextSize?: number | null;
}

export interface AppStoreConnectInput {
  teamId: string;
  issuerId: string;
  keyId: string;
  key: string;
}

export interface AppTabInput {
  key: string;
  title: string;
  icon?: IconInput | null;
  contentType: ContentReferenceType;
  contentUrl?: string | null;
  contentKey?: string | null;
  default: boolean;
}

export interface AppThemesColorsInput {
  light?: AppDesignColorInput | null;
  dark?: AppDesignColorInput | null;
}

export interface ContentPageBlockInput {
  type: ContentPageBlockType;
  body: string;
  key: string;
}

export interface ContentPageBody {
  designerData: string;
  type: ContentPageType;
  blocks?: ContentPageBlockInput[] | null;
  url?: string | null;
  html?: string | null;
}

export interface ContentPageCreateInput {
  name: string;
  body: ContentPageBody;
}

export interface ContentPageUpdateInput {
  name?: string | null;
  body?: ContentPageBody | null;
}

export interface FriendlyStoreInput {
  id: string;
  acceptAllRequests: boolean;
}

export interface GooglePlayConnectInput {
  authType: GooglePlayConnectAuthType;
  credentials: string;
}

export interface IconInput {
  collection: string;
  name: string;
}

export interface NewAppVersionInput {
  platform: AppPlatforms;
  version?: string | null;
  buildPreview: boolean;
  buildRelease: boolean;
  buildAppetize: boolean;
  buildMall: boolean;
}

export interface PricingInput {
  title: string;
  description: string;
  trialDays: number;
  price: number;
  isDefault?: boolean | null;
  capabilities?: string | null;
  hidden?: boolean | null;
}

export interface PushMailingInput {
  title: string;
  message: string;
  platform: PushMailingPlatform;
  navigation?: string | null;
  image?: string | null;
  scheduledAt?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
