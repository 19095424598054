import { Button, Card, Collapsible, FormLayout, Popover, Select, Stack, Subheading, TextField } from "@shopify/polaris";
import { BaseEmoji, Picker } from "emoji-mart";
import React, { useCallback, useState, useEffect } from "react";
import { View } from "react-native";
import { PushMailingPlatform } from "../api/spacex.types";
import { DateTimePicker } from "../components/DatePicker/DateTimePicker";
import { KnobFile } from "../configEditor/knobs/components/KnobFile";
import { KnobNavigationPicker } from "../configEditor/knobs/components/KnobNavigationPicker";
import { useAccessControl } from "../configure/utils/useAccessControl";
import { IconElement } from "../content/elements/IconElement";
import { Capability } from "../utils/enums";
import 'emoji-mart/css/emoji-mart.css'

export type PushMailing = {
    title: string,
    message: string,
    image?: string | null,
    navigation?: string,
    platform: PushMailingPlatform,
    scheduledAt?: Date
};

enum SchedulingType {
    Immediately = 'i',
    Scheduled = 's'
}

export const EditPushMailingForm = React.memo(({
    initialValue,
    onChange,
    disabled
}: {
    initialValue?: PushMailing,
    onChange: (value: PushMailing) => void,
    disabled?: {
        target?: boolean,
        title?: boolean,
        message?: boolean,
        image?: boolean,
        navigation: boolean,
        scheduling?: boolean
    }
}) => {
    const ac = useAccessControl();
    const options = [
        {
            label: 'All customers',
            value: PushMailingPlatform.ALL,
            prefix: <IconElement
                name={'megaphone'}
                collection={'ionicons'}
                size={24}
            />
        },
        {
            label: 'iOS customers',
            value: PushMailingPlatform.IOS,
            prefix: <IconElement
                name={'logo-apple'}
                collection={'ionicons'}
                size={24}
            />
        },
        {
            label: 'Android customers',
            value: PushMailingPlatform.ANDROID,
            prefix: <IconElement
                name={'logo-android'}
                collection={'ionicons'}
                size={24}
            />
        },
    ];
    const [value, setValue] = useState<PushMailing>(initialValue || {
        title: '',
        message: '',
        image: null,
        navigation: undefined,
        platform: options[0].value,
        scheduledAt: undefined
    });
    const handleFormChange = useCallback((field: keyof PushMailing, newValue) => {
        setValue((prevState) => {
            let newState = { ...prevState, [field]: newValue };
            onChange(newState);
            return newState;
        });
    }, []);

    const [schedulingType, setSchedulingType] = useState<SchedulingType>(SchedulingType.Immediately);

    useEffect(() => {
        if (schedulingType === SchedulingType.Immediately) {
            handleFormChange('scheduledAt', undefined);
        } else {
            handleFormChange('scheduledAt', new Date());
        }
    }, [schedulingType]);

    const [messageEmojiActive, setMessageEmojiActive] = useState(false);
    const toggleMessageEmojiActive = useCallback(
        () => {
            setMessageEmojiActive((popoverActive) => !popoverActive)
        },
        [],
    );
    const messageActivator = (
        <Button
            disabled={disabled?.message || false}
            icon={<div>{'😀 😘 🔥'}</div>}
            plain
            removeUnderline
            onClick={toggleMessageEmojiActive}
        >
            {' '}
        </Button>
    );

    const [titleEmojiActive, setTitleEmojiActive] = useState(false);
    const toggleTitleEmojiActive = useCallback(
        () => {
            setTitleEmojiActive((popoverActive) => !popoverActive)
        },
        [],
    );
    const titleActivator = (
        <Button
            disabled={disabled?.title || false}
            icon={<div>{'😀 😘 🔥'}</div>}
            plain
            removeUnderline
            onClick={toggleTitleEmojiActive}
        >
            {' '}
        </Button>
    );

    return (
        <>
            <Card.Section title={"Target"}>
                <FormLayout>
                    <Select
                        label="Customer platforms"
                        options={options}
                        onChange={(value: PushMailingPlatform) => { handleFormChange('platform', value); }}
                        value={value.platform}
                        disabled={disabled?.target || false}
                    />
                </FormLayout>
            </Card.Section>
            <Card.Section title={"Content"}>
                <FormLayout>
                    <TextField
                        label={"Title"}
                        maxLength={100}
                        value={value.title}
                        onChange={(value) => { handleFormChange('title', value) }}
                        placeholder={'Notification title'}
                        disabled={disabled?.title || false}
                        showCharacterCount
                        requiredIndicator
                        id={'title_input_text_field'}
                        suffix={
                            <Popover
                                fluidContent
                                active={titleEmojiActive}
                                activator={titleActivator}
                                onClose={toggleTitleEmojiActive}
                            >
                                <Popover.Pane fixed>
                                    <Picker
                                        sheetSize={16}
                                        onSelect={(val) => {
                                            handleFormChange('title', value.title + (val as BaseEmoji).native);
                                        }}
                                    />
                                </Popover.Pane>
                            </Popover>
                        }
                    />
                    <Stack distribution={"center"} alignment={'fill'}>
                        <Stack.Item fill>
                            <View style={{ flex: 1 }}>
                                <TextField
                                    label={"Message"}
                                    maxLength={255}
                                    showCharacterCount
                                    value={value.message}
                                    onChange={(value) => { handleFormChange('message', value) }}
                                    multiline={4}
                                    placeholder={'Notification message'}
                                    disabled={disabled?.message || false}
                                    id={'message_input_text_field'}
                                    suffix={
                                        <Popover
                                            fluidContent
                                            active={messageEmojiActive}
                                            activator={messageActivator}
                                            onClose={toggleMessageEmojiActive}
                                        >
                                            <Popover.Pane fixed>
                                                <Picker
                                                    sheetSize={16}
                                                    onSelect={(val) => {
                                                        handleFormChange('message', value.message + (val as BaseEmoji).native);
                                                    }}
                                                />
                                            </Popover.Pane>
                                        </Popover>
                                    }
                                />
                            </View>
                        </Stack.Item>
                        {
                            ac.can('user').createOwn(Capability.PushNotificationsImage).granted &&
                            <View key={'Image'} style={{ marginBottom: 10 }}>
                                <KnobFile
                                    knob={{
                                        type: 'file' as const,
                                        defaultValue: '',
                                        description: 'Upload push notification image',
                                        clearable: true,
                                    }}
                                    value={value.image || null}
                                    onChange={(knobValue: any, key: string) => handleFormChange('image', knobValue)}
                                    innerKey={'PushImage'}
                                    disabled={disabled?.image || false}
                                />
                            </View>
                        }
                    </Stack>
                </FormLayout>
            </Card.Section>
            <Card.Section title={"Navigation"}>
                <FormLayout>
                    <View style={{ marginBottom: '0.6rem' }}>
                        <KnobNavigationPicker
                            knob={{
                                type: 'navigation_picker',
                                description: 'Select where to direct your customers when a push notification is opened',
                            }}
                            value={value.navigation || ''}
                            innerKey={'nav-value'}
                            onChange={(value) => { handleFormChange('navigation', value) }}
                            disabled={disabled?.navigation || false}
                        />
                    </View>
                </FormLayout>
            </Card.Section>
            {
                ac.can('user').createOwn(Capability.PushNotificationsScheduled).granted &&
                <Card.Section
                    title={(
                        <Stack vertical distribution={'leading'} alignment={'fill'}>
                            <Stack.Item fill>
                                <Subheading element={"h3"}>{"Scheduling"}</Subheading>
                            </Stack.Item>
                            {!disabled?.scheduling && <Stack.Item>
                                <Select
                                    options={[
                                        { label: 'Immediately', value: SchedulingType.Immediately },
                                        { label: 'Scheduled', value: SchedulingType.Scheduled },
                                    ]}
                                    label={"Type"}
                                    onChange={(value: SchedulingType) => {
                                        setSchedulingType(value);
                                    }}
                                    value={schedulingType}
                                    labelHidden
                                />
                            </Stack.Item>}
                        </Stack>
                    )}
                >
                    <Collapsible
                        open={schedulingType === SchedulingType.Scheduled || disabled?.scheduling === true}
                        id="push-mailing-scheduling-collapsible"
                        transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                        expandOnPrint
                    >
                        <FormLayout>
                            <DateTimePicker
                                disabled={disabled?.scheduling || false}
                                selected={value.scheduledAt}
                                onChange={(value) => { handleFormChange('scheduledAt', value); }}
                            />
                        </FormLayout>
                    </Collapsible>
                </Card.Section>
            }
        </>
    );
});