import { Card, Page } from "@shopify/polaris";
import React from "react";
import { useRouteMatch } from "react-router";
import { ActiveAdListingItems } from "../components/ActiveAdListingItems";
import { CommunityListings } from "../components/CommunityListings";
import { IncomingAdListingItems } from "../components/IncomingAdListingItems";

export const IncomingAdListings = React.memo(() => {
    const match = useRouteMatch();
    return (
        <Page
            title={'Community and Friendly stores requests'}
            subtitle={'Review your fellow stores requests or earn points accepting community listed positions'}
            breadcrumbs={[{ content: 'Ads', url: `${match.url.replace('/incoming-listings', '')}` }]}
        >
            <CommunityListings/>
            <IncomingAdListingItems/>
        </Page>
    );
});