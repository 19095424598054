import React from "react";
import { ContentView, CollectionProps, ContentCollection } from "../../../../content/components";
import { ResizeMode } from "../../../knobs/types";

export const CollectionGridItem = React.memo((
    props: {
        viewsHeight: number,
        viewsMargins: number,
        borderRadius: number,
        showShadow?: boolean,
        colNumber: number,
    } & CollectionProps
) => {
    return (
        <ContentView style={{
            height: props.viewsHeight,
            marginRight: props.colNumber === 0 ? props.viewsMargins / 2 : undefined,
            marginLeft: props.colNumber === 1 ? props.viewsMargins / 2 : undefined,
            marginBottom: props.viewsMargins,
            borderRadius: props.borderRadius,
            shadowOpacity: props.showShadow ? 0.5 : undefined,
            shadowRadius: props.showShadow ? 6 : undefined,
            overflow: 'hidden'
        }}>
            <ContentCollection
                id={props.id}
                caption={props.caption}
                textColor={props.textColor}
                textAlign={props.textAlign}
                showCaption={props.showCaption}
                placeholderImage={props.placeholderImage}
                showTextBackground={props.showTextBackground}
                fontWeight={props.fontWeight}
                viewType={props.viewType}
                imageResizeMode={props.imageResizeMode as ResizeMode}
                blurIntencity={props.blurIntencity}
            />
        </ContentView>
    );
});