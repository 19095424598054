import { Popover, Stack, DatePicker as ShopifyDatePicker, TextField } from "@shopify/polaris";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { View } from "react-native";

export const DatePicker = React.memo(({
    selected,
    onChange,
    format,
    disabled
}: {
    selected?: Date,
    onChange: (value: Date) => void,
    format?: string,
    disabled?: boolean
}) => {
    const now = moment(new Date());
    const displayFormat = format || 'DD.MM.YYYY';
    const [{ month, year }, setShowDate] = useState({ month: now.month(), year: now.year() });
    const handleMonthChange = useCallback(
        (month, year) => setShowDate({ month, year }),
        [],
    );
    const [value, setValue] = useState<Date>(selected || now.toDate());
    const [stringDate, setStringDate] = useState(moment(value).format(displayFormat));

    const [error, setError] = useState<boolean>(false);

    const handleDateChange = useCallback((newValue: Date) => {
        setValue(newValue);
        onChange(newValue);
        setStringDate(moment(newValue).format(displayFormat));
        togglePopoverActive();
    }, [onChange]);

    const [popoverActive, setPopoverActive] = useState(false);

    const togglePopoverActive = useCallback(() => setPopoverActive((popoverActive) => !popoverActive), []);


    return (
        <Stack alignment={'trailing'} distribution={'leading'}>
            <Stack.Item>
                <Popover
                    activator={
                        <TextField
                            label={'Date'}
                            value={stringDate}
                            onChange={setStringDate}
                            onFocus={() => { !disabled ? setPopoverActive(true) : undefined; }}
                            autoComplete={'off'}
                            error={error}
                            readOnly
                            disabled={disabled || false}
                        />
                    }
                    active={popoverActive}
                    onClose={togglePopoverActive}
                    fluidContent
                >
                    <Popover.Pane>
                        <Popover.Section>
                            <View style={{ width: 250, height: 283 }}>
                                <ShopifyDatePicker
                                    month={month}
                                    year={year}
                                    onMonthChange={handleMonthChange}
                                    onChange={(selected) => { handleDateChange(selected.start) }}
                                    selected={value}
                                    disableDatesBefore={now.subtract(1, 'days').toDate()}
                                />
                            </View>
                        </Popover.Section>
                    </Popover.Pane>
                </Popover>
            </Stack.Item>
        </Stack>

    );
});