import React from "react";
import { CalloutCard, Card } from "@shopify/polaris";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { useAppBridge } from "@shopify/app-bridge-react";
import { useParams } from "react-router";
import { useAccessControl } from "../../../../configure/utils/useAccessControl";
import { Capability } from "../../../../utils/enums";

export const CustomizeMenuCalloutCard = React.memo(() => {
    const { id } = useParams<{ id: string }>();
    const appBridge = useAppBridge();

    console.log('[CustomizeMenuCalloutCard]', id);

    const ac = useAccessControl();
    const hasAccess = ac.can('user').readOwn(Capability.MainMenu).granted
        && ac.can('user').createOwn(Capability.MainMenu).granted
        && ac.can('user').updateOwn(Capability.MainMenu).granted
        && ac.can('user').deleteOwn(Capability.MainMenu).granted

    console.log('[CustomizeMenuCalloutCard]', hasAccess);

    return (
        <CalloutCard
            title="Customize your Cartmate pages content"
            illustration="https://cdn.shopify.com/shopifycloud/online-store-web/assets/e6c5834a54458b58a2eab0e2d51bcc02.svg"
            primaryAction={{
                content: hasAccess ? 'Customize' : 'Upgrade Plan',
                onAction: () => {
                    const redirect = ShopifyRedirect.create(appBridge);
                    redirect.dispatch(ShopifyRedirect.Action.APP, hasAccess ? `/apps/${id}/menu` : `/subscription`);
                }
            }}
        >
            <p>Сreate a unique eye catching design for your store and increase your sales with ease</p>
        </CalloutCard>
    );
});