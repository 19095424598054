import {
    Card,
    EmptyState,
    Layout,
    Badge,
    Page,
    ResourceItem,
    ResourceList,
    TextStyle,
    Stack,
    UnstyledLink, Button, Icon, CalloutCard
} from '@shopify/polaris';
import React, { CSSProperties, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { View } from "react-native";
import { DeleteMinor, DragHandleMinor } from "@shopify/polaris-icons";
import { useClient } from '../../api/useClient';
import { PageItem } from './components/PageItem';
import { useAccessControl } from '../../configure/utils/useAccessControl';
import { Capability } from '../../utils/enums';
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { PagesOnboarding } from '../../components/onboarding/PagesOnboarding';

const EmptyStateMarkup = <EmptyState
    heading="Create page to get started"
    action={{ content: 'Add new page', url: '/pages/new' }}
    image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
>
    <p>
        You can create new page for your app
    </p>
</EmptyState>

export const PagesComponent = React.memo(() => {
    const client = useClient();
    const history = useHistory();
    const pages = client.usePages({ fetchPolicy: 'cache-and-network' }).pages;
    const match = useRouteMatch()
    const appBridge = useAppBridge();
    const sortedPages = useMemo(() => pages.sort((a, b) => a.id.localeCompare(b.id)), [pages]);
    // TODO: Pagination

    const ac = useAccessControl();

    if (!ac.can('user').updateOwn(Capability.MainMenu).granted) {
        return (
            <CalloutCard
                illustration="https://cdn.shopify.com/shopifycloud/online-store-web/assets/e6c5834a54458b58a2eab0e2d51bcc02.svg"
                primaryAction={{
                    content: 'Upgrade Plan',
                    onAction: () => {
                        const redirect = ShopifyRedirect.create(appBridge);
                        redirect.dispatch(ShopifyRedirect.Action.APP, `/subscription`);
                    }
                }}
                title={'Upgrade your plan'}
            >
                <Stack vertical>
                    <p>Сreate a unique eye catching design for your store and increase your sales with ease. You can create custom tab content pages, change tabs contents, icons and caption</p>
                </Stack>
            </CalloutCard>
        );
    }

    return (<>
        <Card
            title={"Pages"}
            primaryFooterAction={(!sortedPages || sortedPages.length === 0) ? undefined : {
                content: 'Add page',
                onAction: () => {
                    history.push({ pathname: `${match.url}/new` })
                }
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    paddingBottom: 20,
                    paddingTop: 20,
                }}
            >
                {(!sortedPages || sortedPages.length === 0) && (
                    <EmptyState
                        heading="No pages"
                        action={{ content: 'Add page', url: `${match.url}/new` }}
                        image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
                    />
                )}
                {sortedPages.map((page) => {
                    return (
                        <View key={`page-item-${page.id}`}>
                            <PageItem page={page} />
                            <View style={{
                                backgroundColor: '#f3f4f5',
                                height: 1,
                                width: '100%',
                                position: 'absolute',
                                top: 0
                            }} />
                            <View style={{
                                backgroundColor: '#f3f4f5',
                                height: 1,
                                width: '100%',
                                position: 'absolute',
                                bottom: -1
                            }} />
                        </View>
                    );
                })}
            </div>
        </Card>
        <PagesOnboarding/>
        </>
    )
})