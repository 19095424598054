import React, { useCallback, useMemo } from 'react';
import {
    Controller, ControllerRenderProps,
    Validate,
    ValidationRule,
    ValidationValueMessage
} from 'react-hook-form';
import { SelectOption, StyledSelect, StyledSelectProps, StyledSelectRef } from '../../StyledSelect/StyledSelect';
import { getOptionByValue } from '../../StyledSelect/StyledSelectUtils';
import { ValueType } from 'react-select';

type PartialSelectProps = Pick<StyledSelectProps, 'options' | 'placeholder' | 'isSearchable'>

type Props = {
    name: string,
    defaultValue?: any
    rules?: Partial<{
        required: string | boolean | ValidationValueMessage<boolean>;
        min: ValidationRule<React.ReactText>;
        max: ValidationRule<React.ReactText>;
        maxLength: ValidationRule<React.ReactText>;
        minLength: ValidationRule<React.ReactText>;
        pattern: ValidationRule<RegExp>;
        validate: Validate | Record<string, Validate>;
        valueAsNumber: boolean;
        valueAsDate: boolean;
        setValueAs: (value: any) => any;
    }> | undefined
} & PartialSelectProps


const RenderSelect = React.forwardRef<StyledSelectRef, ControllerRenderProps & PartialSelectProps>(({options, value, onChange, ...props}, ref) => {
    const selectedValue = useMemo(() => getOptionByValue(options, value), [value, options])
    const onSelectChange = useCallback((opt: ValueType<SelectOption, false>) => onChange(opt?.value), [onChange])
    return <StyledSelect {...props}
                         ref={ref}
                         options={options}
                         onChange={onSelectChange}
                         value={selectedValue}/>
})

export const FormSelect = React.memo<Props>(React.forwardRef(({name, defaultValue, rules, options, placeholder, isSearchable}, ref) => (
    <Controller
        ref={ref}
        render={valueProps => (
            <RenderSelect
                options={options}
                placeholder={placeholder}
                isSearchable={isSearchable}
                {...valueProps}/>
        )}
        defaultValue={defaultValue}
        name={name}
        rules={rules}
    />
)))