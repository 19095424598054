import { Checkbox, Modal, Stack, TextStyle } from "@shopify/polaris";
import React, { useState } from "react";
import { View } from "react-native";
import { ListCommunityStores_listCommunityStores_edges_node } from "../../../api/spacex.types";
import { SocialType } from "../../Settings/components/SocialsInput";

export const ReviewCommunityStoreModal = React.memo((
    {
        item,
        handleCancel,
        handleAccept
    }: {
        item?: ListCommunityStores_listCommunityStores_edges_node,
        handleCancel: () => void,
        handleAccept: (input?: { id: string, acceptAllRequests: boolean }) => void
    }
) => {
    const [allowAllRequests, setAllowAllRequests] = useState(false);

    return (
        <Modal
            title={item ? item.contact.data.name : ''}
            open={item ? true : false}
            onClose={() => handleCancel()}
            primaryAction={{
                content: 'Add to Friendly Stores',
                onAction: () => {
                    if (item) {
                        handleAccept({
                            id: item?.contact.id,
                            acceptAllRequests: allowAllRequests
                        });
                    }
                },
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: handleCancel
                }
            ]}
        >
            {item && (
                <Modal.Section>
                    <Stack vertical>
                        {(item.contact.data.email && item.contact.data.email.length > 0) && (
                            <Stack.Item>
                                <p>Email: <TextStyle variation={'subdued'}>{item.contact.data.email}</TextStyle></p>
                            </Stack.Item>
                        )}
                        {(item.contact.data.phoneNumber && item.contact.data.phoneNumber.length > 0) && (
                            <Stack.Item>
                                <p>Phone: <TextStyle variation={'subdued'}>{item.contact.data.phoneNumber}</TextStyle></p>
                            </Stack.Item>
                        )}
                        {(item.contact.data.socials && item.contact.data.socials.length > 0) && (
                            <Stack.Item>
                                <Stack distribution={'fill'}>
                                    <Stack.Item>
                                        <p>Facebook:
                                            <TextStyle variation={'subdued'}>
                                                {
                                                    ` @${item
                                                        .contact
                                                        .data
                                                        .socials
                                                        ?.find((s) => s.socialName === SocialType.Facebook)?.socialId}`
                                                }
                                            </TextStyle>
                                        </p>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <p>Instagram:
                                            <TextStyle variation={'subdued'}>
                                                {
                                                    ` @${item
                                                        .contact
                                                        .data
                                                        .socials
                                                        ?.find((s) => s.socialName === SocialType.Instagram)?.socialId}`
                                                }
                                            </TextStyle>
                                        </p>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        )}
                    </Stack>
                </Modal.Section>
            )}
            <Modal.Section>
                <Checkbox
                    label="Allow all incoming requests from this store"
                    checked={allowAllRequests}
                    onChange={(value: boolean) => setAllowAllRequests(value)}
                />
            </Modal.Section>
        </Modal>
    );
});