// Pre-boot
import './src/PreBoot';

// Boot
import * as React from 'react';
import { View } from 'react-native';
import { colors } from './src/assets/colors';
import { BootProviders } from './src/BootProviders';
import { Boot } from './src/Boot';
import { ThemeContextLoader } from './src/ThemeContext';
export default () => {
    return (
        <ThemeContextLoader>
            <BootProviders>
                <Boot />
            </BootProviders>
        </ThemeContextLoader>
    );
}
