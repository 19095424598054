import React from "react";
import { useHistory } from "react-router";

export const HelpItem = React.memo(({
    title,
    description,
    route
}: {
    title: string,
    description?: string,
    route: string
}) => {
    const history = useHistory();
    return (
        <div
            onClick={() => {
                history.push(route);
            }}
            style={{
                display: 'flex',
                padding: '16px',
                cursor: 'pointer'
            }}
        >
            <div className="settings-info">
                <div className="settings-title" style={{
                    color: '#006fbb',
                    fontWeight: 600
                }}>
                    {title}
                </div>
                <div className="settings-text" style={{
                    color: '#6d7175',
                }}>
                    {description}
                </div>
            </div>
        </div>
    );
});