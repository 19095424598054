import {
    Button,
    ButtonGroup,
    Card,
    Heading,
    Layout,
    Page,
    Stack,
    TextContainer,
    TextField,
    Toast,
    Link,
    FooterHelp
} from "@shopify/polaris";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { useClient } from "../api/useClient";
import { useAccount } from "../configure/utils/useAccount";
import { StyleSheet, View } from "react-native";
import QRCode from "qrcode.react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { AccountPricingStatus, AppCardPublishedStatus } from "../api/spacex.types";
import { CheckCircle } from "../components/elements/CheckCircle";
import { ProgressBar } from "../components/elements/ProgressBar";
import { getAccountPricingStatusBadge, getClientDevice } from "../utils/helpers";
import { ProductPublishingComponent } from "../components/ProductPublishingComponent";
import { TermsAndConditions } from "../components/TermsAndConditions";
import { useBoolLocalStorage } from "../components/banners/useBoolLocalStorage";
import { StatCard } from "../components/dashboard/StatCard";
import { useAccessControl } from "../configure/utils/useAccessControl";
import { CustomizeMenuCalloutCard } from "./Promote/components/callout/CustomizeMenuCalloutCard";
import { CustomizeThemeCalloutCard } from "./Promote/components/callout/CustomizeThemeCalloutCard";
import { ExchangeCalloutCard } from "./Promote/components/callout/ExchangeCalloutCard";
import { SkeletonSuspense, SkeletonType } from "../components/Skeleton/SkeletonSuspense";
import { PushCalloutCard } from "./Promote/components/callout/PushCalloutCard";
import { DashboardOnboarding } from "../components/onboarding/DashboardOnboarding";
import { ReviewModal } from "../components/modals/ReviewModal";
const googlePlayBadge = require('../assets/images/google-play-badge.png');
const appStoreBadge = require('../assets/images/app-store-badge.svg');

const cardStyle = StyleSheet.create({
    statCard: {
        backgroundColor: '#fff',
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: '#c4cdd5',
        borderRadius: 5
    }
});

const exchangeIcon = require('../assets/images/exchange-icon.svg');

export const AppDashboard = React.memo(() => {
    const { id } = useParams<{ id: string }>();
    const client = useClient();
    const [isDark] = useBoolLocalStorage('IsDarkTheme');
    const card = client.useGetAppCard({ appId: id });

    const account = useAccount();
    const ac = useAccessControl();
    console.log('AC', ac.can('user').readOwn('theme').granted);
    const history = useHistory<{ from?: Location }>();

    const pricing = client.useDefaultPricing().defaultPricing;
    const match = useRouteMatch();
    const appBridge = useAppBridge();

    const app = client.useGetAppSettings({ id }).app;
    const appStat = client.useAppStat({ appId: app.id }).appStat;

    const pages = client.usePages({ fetchPolicy: 'cache-and-network' }).pages;
    const sortedPages = useMemo(() => pages.sort((a, b) => a.id.localeCompare(b.id)), [pages]);


    const [copiedShare, setCopiedShare] = useState(false);
    const shareToast = copiedShare ? (
        <Toast content="Your mobile storefront link copied!" onDismiss={() => setCopiedShare(false)} />
    ) : null;


    let badge = getAccountPricingStatusBadge(account);

    const [visitsPopover, setVisitsPopover] = useState<boolean>(false);
    const [ordersPopover, setOrdersPopover] = useState<boolean>(false);
    const [ordersTotalPopover, setOrdersTotalPopover] = useState<boolean>(false);
    const [installsPopover, setInstallsPopover] = useState<boolean>(false);

    const handleSubscribe = useCallback(() => {
        client.mutateSubscribeToPricing({ id: account.id, pricingId: pricing.id, returnUrl: match.url }).then((result) => {
            console.log('Subscriiibe result', result);
            if (result.subscribeToPricing) {
                const redirect = ShopifyRedirect.create(appBridge);
                redirect.dispatch(ShopifyRedirect.Action.REMOTE, result.subscribeToPricing);
            }
        }).catch((e) => {
            console.warn(e);
        });
    }, [pricing, match]);

    const bannerPublished = Boolean(app.settings?.banner?.scriptTagId);

    const progress = useMemo<number>(() => {
        let res = 30;
        if (bannerPublished) {
            res += 35;
            console.log('Banner published +35')
        }
        if (sortedPages.length > 0) {
            res += 35;
            console.log('Pages created +35')
        }
        if (card.app.publishedCard && card.app.publishedCard.status === AppCardPublishedStatus.ACTIVE) {
            res += 25;
            console.log('AppCard published +25')
        }

        return res;
    }, [account, bannerPublished, sortedPages]);

    console.log('Progress', progress);

    const shareLink = client.useGenerateSharLink({ appId: id }).generateShareLink;
    const deviceInfo = getClientDevice();

    return (
        <Page title={`Mobile in-app storefront: ${app.name}`} titleMetadata={badge} primaryAction={
            <ButtonGroup>
                {account.pricing.status === AccountPricingStatus.TRIAL && <Button
                    primary
                    onClick={handleSubscribe}
                >
                    Upgrade subscription
                </Button>}
            </ButtonGroup>
        }>
            <Layout>
                <Layout.Section>
                    <Layout>
                        <Layout.Section>
                            <div className={"stat-wrapper"}>
                                <StatCard
                                    popoverActive={installsPopover}
                                    setPopoverActive={setInstallsPopover}
                                    title={'Installs'}
                                    popoverText={'Number of users who have installed your mobile in-app storefront'}
                                    value={appStat.installs.toString()}
                                />
                                <StatCard
                                    popoverActive={visitsPopover}
                                    setPopoverActive={setVisitsPopover}
                                    title={'Visits'}
                                    popoverText={'Number of visits on your mobile in-app storefront'}
                                    value={appStat.visits.toString()}
                                />
                                <StatCard
                                    popoverActive={ordersPopover}
                                    setPopoverActive={setOrdersPopover}
                                    title={'Orders'}
                                    popoverText={'The number of orders made through mobile app'}
                                    value={appStat.orders.toString()}
                                />
                                <StatCard
                                    popoverActive={ordersTotalPopover}
                                    setPopoverActive={setOrdersTotalPopover}
                                    title={'Sales'}
                                    popoverText={'Mobile App Sales'}
                                    value={`$${appStat.ordersSum}`}
                                />
                            </div>
                        </Layout.Section>
                        <Layout.Section>
                            <Card title={"Onboarding"}>
                                <Card.Section>
                                    <Stack vertical>
                                        <Stack.Item>
                                            <ProgressBar progress={progress} />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Stack alignment={"center"}>
                                                <Stack.Item>
                                                    <CheckCircle state={"active"} />
                                                </Stack.Item>
                                                <Stack.Item fill>
                                                    <div>
                                                        <p>Available in the AppStore &amp; Google Play</p>
                                                    </div>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Stack alignment={"center"}>
                                                <Stack.Item>
                                                    <CheckCircle state={bannerPublished ? "active" : "inactive"} />
                                                </Stack.Item>
                                                <Stack.Item fill>
                                                    <div>
                                                        {bannerPublished && <p>App Banner is installed on your online store</p>}
                                                        {!bannerPublished && <p>App Banner is not installed on your online store</p>}
                                                    </div>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Link onClick={() => {
                                                        const redirect = ShopifyRedirect.create(appBridge);
                                                        redirect.dispatch(ShopifyRedirect.Action.APP, '/apps/' + id + '/promo');
                                                    }}>Change</Link>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item>
                                            {/* <Stack alignment={"center"}>
                                                <Stack.Item>
                                                    <CheckCircle state={account.pricing.status === AccountPricingStatus.ACTIVE ? "active" : "inactive"} />
                                                </Stack.Item>
                                                <Stack.Item fill>
                                                    <div>
                                                        <p>Subscription plan: <span>{account.pricing.status === AccountPricingStatus.ACTIVE ? "Active" : account.pricing.status === AccountPricingStatus.TRIAL ? "Trial" : "Inactive"}</span></p>
                                                    </div>
                                                </Stack.Item>
                                                {account.pricing.status !== AccountPricingStatus.ACTIVE && <Stack.Item>
                                                    <Link onClick={() => {
                                                        const redirect = ShopifyRedirect.create(appBridge);
                                                        redirect.dispatch(ShopifyRedirect.Action.APP, '/subscription');
                                                    }}>Subscribe</Link>
                                                </Stack.Item>}
                                            </Stack> */}
                                            <Stack alignment={"center"}>
                                                <Stack.Item>
                                                    <CheckCircle state={sortedPages.length > 0 ? "active" : "inactive"} />
                                                </Stack.Item>
                                                <Stack.Item fill>
                                                    <div>
                                                        <p>Customization: <span>{sortedPages.length > 0 ? "Content Pages created"  : "Content Pages not created"}</span></p>
                                                    </div>
                                                </Stack.Item>
                                                {!(sortedPages.length > 0) && <Stack.Item>
                                                    <Link onClick={() => {
                                                        const redirect = ShopifyRedirect.create(appBridge);
                                                        redirect.dispatch(ShopifyRedirect.Action.APP, '/apps/' + id + '/pages');
                                                    }}>Create</Link>
                                                </Stack.Item>}
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Stack alignment={"center"}>
                                                <Stack.Item>
                                                    <CheckCircle state={card.app.publishedCard && card.app.publishedCard.status === AppCardPublishedStatus.ACTIVE ? "active" : "inactive"} />
                                                </Stack.Item>
                                                <Stack.Item fill>
                                                    <div>
                                                        <p>Cartmate Exchange Catalog: <span>{card.app.publishedCard && card.app.publishedCard.status === AppCardPublishedStatus.ACTIVE ? "Active" : "Inactive"}</span></p>
                                                    </div>
                                                </Stack.Item>
                                                {(!card.app.publishedCard || card.app.publishedCard.status !== AppCardPublishedStatus.ACTIVE) && <Stack.Item>
                                                    <Link onClick={() => {
                                                        const redirect = ShopifyRedirect.create(appBridge);
                                                        redirect.dispatch(ShopifyRedirect.Action.APP, '/promote');
                                                    }}>Change</Link>
                                                </Stack.Item>}
                                            </Stack>
                                        </Stack.Item>
                                    </Stack>
                                </Card.Section>
                            </Card>
                        </Layout.Section>
                        {__DEV__ && (
                            <Layout.Section>
                                <ExchangeCalloutCard />
                            </Layout.Section>
                        )}
                        <Layout.Section>
                            <CustomizeThemeCalloutCard />
                        </Layout.Section>
                        <Layout.Section>
                            <CustomizeMenuCalloutCard />
                        </Layout.Section>
                        <Layout.Section>
                            <PushCalloutCard />
                        </Layout.Section>
                        <Layout.Section>
                            <SkeletonSuspense type={SkeletonType.Card}>
                                <ProductPublishingComponent notAnnotated />
                            </SkeletonSuspense>
                        </Layout.Section>
                        <Layout.Section>
                            <TermsAndConditions />
                        </Layout.Section>
                    </Layout>
                </Layout.Section>
                <Layout.Section secondary>
                    <Card>
                        <Card.Section>
                            <TextContainer>
                                <Heading>Your Cartmate storefront is ready</Heading>
                                <p>Scan QR-code or open the direct link on your device to see how it works on both iOS or Android</p>
                            </TextContainer>
                        </Card.Section>
                        <Card.Section>
                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                {!deviceInfo.mobile && <QRCode
                                    value={shareLink}
                                    size={210}
                                    bgColor={'#f6f7f8'}
                                    fgColor={'#008060'}
                                />}
                                {deviceInfo.mobile && <div className={"mobile-download"}>
                                    {deviceInfo.os === "android" && <View>
                                        <a href={shareLink} target={"_blank"}>
                                            <img className={"store-badge android-store-badge"} src={googlePlayBadge} />
                                        </a>
                                    </View>}
                                    {deviceInfo.os === "ios" && <View>
                                        <a href={shareLink} target={"_blank"}>
                                            <img className={"store-badge ios-store-badge"} src={appStoreBadge} />
                                        </a>
                                    </View>}
                                </div>}
                            </View>
                        </Card.Section>
                        <Card.Section>
                            <TextContainer>
                                <Heading>This is a direct link to your mobile storefront. Share it and engage with new customers!</Heading>
                                <TextField label={"Share link"} value={shareLink} labelHidden onChange={() => { }} />
                                <CopyToClipboard text={shareLink}
                                    onCopy={() => setCopiedShare(true)}>
                                    <Button primary>Copy link</Button>
                                </CopyToClipboard>
                            </TextContainer>
                        </Card.Section>
                        {shareToast}
                    </Card>
                </Layout.Section>
            </Layout>
            {/* <CustomizatonModal/> */}
            <ReviewModal />
            <DashboardOnboarding />
            <FooterHelp>
                Learn more about{' '}
                <Link onClick={() => {
                    const redirect = ShopifyRedirect.create(appBridge);
                    redirect.dispatch(ShopifyRedirect.Action.APP, '/about/promote');
                }}>
                    dashboard
                </Link>
            </FooterHelp>
            {deviceInfo.mobile && <View style={{
                height: "40px",
            }} />}
        </Page>
    );
});