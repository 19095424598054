import { FormLayout, Popover, Button, Pagination, ResourceItem, ResourceList, Stack, TextStyle, Labelled, EmptyState, Tag } from "@shopify/polaris";
import React, { useState, useCallback, useEffect } from "react";
import { View } from "react-native";
import { ListProducts_listProducts, ListProducts_listProducts_edges_node } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { mapNodeToProduct } from "../helpers/mapNodeToProduct";
import { renderProductTags } from "../helpers/renderProductTags";
import { Knob } from "../Knob";

export const KnobProducts = React.memo<Knob.KnobComponentProps<Knob.KnobProductsDescriptor>>(({
    knob, value: initialValue, onChange, innerKey
}) => {
    const client = useClient();
    const { description } = knob
    const [popoverActive, setPopoverActive] = useState(false);
    const [pageSize, setPageSize] = useState<number>(10);
    const [products, setProducts] = useState<Knob.Product[]>(initialValue);

    const addProduct = useCallback(
        (node: ListProducts_listProducts_edges_node) => {
            if (!products.find((item) => item.id === node.id)) {
                let temp: Knob.Product[] = [];
                temp = temp.concat(products);
                temp.push(mapNodeToProduct(node));
                if (JSON.stringify(temp) !== JSON.stringify(initialValue)) {
                    onChange(temp, innerKey);
                }
                setProducts(temp);
            }
        },
        [products, knob, innerKey, onChange, initialValue],
    );

    const removeProduct = useCallback(
        (product: Knob.Product) => {
            console.log('removeProduct');
            let temp: Knob.Product[] = [];
            temp = temp.concat(products);
            let index = temp.findIndex((item) => item.id === product.id);
            if (index !== -1) {
                temp.splice(index, 1);
                if (JSON.stringify(temp) !== JSON.stringify(initialValue)) {
                    console.log('removeProduct removing');
                    onChange(temp, innerKey);
                }
                setProducts(temp);
            }
        },
        [products, knob, innerKey, onChange, initialValue],
    );

    const [results, setResults] = useState<ListProducts_listProducts>();

    const loadNextPage = useCallback(() => {
        const queryData = { first: pageSize, after: results?.pageInfo.endCursor };
        client.queryListProducts(queryData).then((result) => {
            setResults(result.listProducts);
        });
    }, [pageSize, results, knob, innerKey]);

    const loadPrevPage = useCallback(() => {
        if (!results?.pageInfo.hasPreviousPage) { throw new Error("No previous page"); }
        const queryData = { last: pageSize, before: results.pageInfo.startCursor };
        client.queryListProducts(queryData).then((result) => {
            setResults(result.listProducts);
        });
    }, [pageSize, results, knob, innerKey]);

    useEffect(() => {
        loadNextPage();
    }, [knob, innerKey]);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [knob, innerKey],
    );

    const activator = (
        <Button fullWidth onClick={togglePopoverActive} disclosure>
            {!(products.length > 0) ? ('Select products') : (`Products (${products.length})`)}
        </Button>
    );

    const tags = renderProductTags(products, removeProduct);

    return (
        <FormLayout>
            <Labelled id={innerKey} label={description}>
                <Popover
                    active={popoverActive}
                    activator={activator}
                    onClose={togglePopoverActive}
                    fullWidth
                >
                    {(!results || results.edges.length === 0) && (
                        <View style={{ minHeight: 400 }}>
                            <EmptyState
                                heading="No products found"
                                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                            />
                        </View>
                    )}
                    {(results && results.edges.length > 0) && (
                        <View style={{ flex: 1 }}>
                            <ResourceList items={results?.edges || []} renderItem={(item) => {
                                const node = item.node;
                                return (
                                    <ResourceItem
                                        id={node.id}
                                        onClick={() => { addProduct(node) }}
                                    >
                                        <h3><TextStyle>{node.title}</TextStyle></h3>
                                    </ResourceItem>
                                );
                            }} />
                            {(results.pageInfo.hasNextPage || results.pageInfo.hasPreviousPage) && (
                                <Stack distribution={"center"}>
                                    <Pagination
                                        hasPrevious={results?.pageInfo.hasPreviousPage}
                                        onPrevious={() => { loadPrevPage() }}
                                        hasNext={results?.pageInfo.hasNextPage}
                                        onNext={() => { loadNextPage() }}
                                    />
                                </Stack>
                            )}
                        </View>
                    )}
                </Popover>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 4 }}>
                    {tags}
                </View>
            </Labelled>
        </FormLayout>
    );
});