import React, { } from "react";
import { AppEmulatorConfig, AppEmulatorPlatform } from "../types";
import { useClient } from "../../../api/useClient";
import { useAccount } from "../../../configure/utils/useAccount";
import { useEmulatorConfig } from "../components/ScalableEmulatorComponent";
import { View } from "react-native";
import { useAccessControl } from "../../../configure/utils/useAccessControl";
import { Capability } from "../../../utils/enums";

export const PageScreen = React.memo((
    {
        appId,
        config,
        selectedPlatform,
        pageId
    }: {
        appId: string,
        config: AppEmulatorConfig,
        selectedPlatform: AppEmulatorPlatform,
        pageId?: string
    }
) => {
    const client = useClient();
    const account = useAccount();
    const emulator = useEmulatorConfig();
    const ac = useAccessControl();
    const permited = ac.can('user').updateOwn(Capability.MainMenu).granted;

    return (
        <>
            <div
                className={`${selectedPlatform}__page-content`}
                style={{
                    position: 'fixed',
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "column",
                    height: "100%",
                    width: `${emulator.listWidth}px`,
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                {pageId === "new" && <span
                    style={{
                        color: config.design.colors[emulator.theme].bottomNavigationAccent,
                        fontSize: '22px',
                        textAlign: "center",
                        padding: "0 10px"
                    }}
                >
                    {'Please, save content page for preview'}
                </span>}
                {!pageId && (
                    <View style={{ flexWrap: 'wrap', padding: 50 }}>
                        <span
                            style={{
                                color: config.design.colors[emulator.theme].bottomNavigationAccent,
                                fontSize: emulator.fontSize + 4,
                                lineHeight: '46px',
                                textAlign: "center",
                                padding: "0 10px",
                                marginTop: '50%'
                            }}
                        >
                            {permited && 'Please, select content page to preview its content 👈'}
                            {!permited && 'Please, upgrade your plan'}
                        </span>
                    </View>
                )}
            </div>
        </>
    );
});