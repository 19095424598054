import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Knob } from '../Knob';
import { Button, Collapsible, Icon, TextStyle } from '@shopify/polaris'
import { FontWeightType, TextAlignType } from '../types';
import { KnobAdvancedText } from './KnobAdvancedText';
import { DafaultBlockValues } from '../../blocks/defaults';
import { KnobBlurhashImage } from './KnobBlurhashImage';
import {
    DeleteMinor,
    EditMinor,
    MinusMinor,
    MobileCancelMajor
} from '@shopify/polaris-icons';
import { KnobNavigationPicker } from './KnobNavigationPicker';


export const KnobSlider = React.memo<Knob.KnobComponentProps<Knob.KnobSliderDescriptor>>((
    { knob, value: initValue, onChange, innerKey }
) => {
    const { description, defaultSettings } = knob;

    const [slider, setSlider] = useState<Knob.Slider>({
        listOfSlides: initValue.listOfSlides,
        opened: initValue.opened,
        current: initValue.current
    });

    console.log('sliderINIT', slider);

    const createSlide = useCallback(() => {
        const newItem: Knob.Slide = {
            caption: {
                value: defaultSettings.value,
                textColor: defaultSettings.textColor,
                showCaption: defaultSettings.showCaption,
                fontWeight: defaultSettings.fontWeight,
                textAlign: defaultSettings.textAlign,
            },
            img: {
                src: DafaultBlockValues.backgroundUrl,
                blurhash: null,
                resizeMode: 'cover'
            },
            url: '',
            id: Date.now().toString()
        }
        if (slider.listOfSlides === null) {
            let temp = []
            temp.push(newItem);
            const index = temp.findIndex((i) => i.id === newItem.id);
            onChange({ listOfSlides: temp, opened: true, current: index }, innerKey)
        } else {
            let temp = slider.listOfSlides;
            temp.push(newItem);
            const index = temp.findIndex((i) => i.id === newItem.id);
            onChange({ listOfSlides: temp, opened: true, current: index }, innerKey)
        }
        console.log('NEW ITEM ', slider.listOfSlides);

    }, [onChange, slider, innerKey, initValue, knob]);

    const deleteSlide = useCallback((index) => {
        let temp = slider;
        temp.listOfSlides!.splice(index, 1);
        onChange({ ...temp, current: null }, innerKey);
    }, [slider, onChange, initValue, knob, innerKey])

    const ChooseSlide = useCallback((index) => {
        console.log('[KnobSlider]', index);
        onChange({ ...slider, ...{current: index} }, innerKey);
    }, [slider, onChange, initValue, knob, innerKey]);

    useEffect(() => {
        setSlider(initValue)
    }, [initValue]);

    const views = useMemo(() => {
        console.log('memo list', slider)
        if (slider.listOfSlides !== null) {
            return slider.listOfSlides!.map((element, index) => {
                return (<>
                    {slider.current === index
                        ? (
                            <div style={{
                                position: 'relative',
                                borderRadius: '8px', border: '1px solid rgba(23, 24, 24, 0.3',
                                margin: '10px 0', padding: '30px 10px 10px'
                            }}>
                                <div style={{
                                    position: 'absolute',
                                    top: '10px', right: '10px',
                                    cursor: 'pointer',
                                    display: 'flex', gap: '10px'
                                }}>
                                    <div onClick={() => {
                                        onChange({ ...slider, current: null }, innerKey)
                                    }}>
                                        <Icon
                                            source={MinusMinor}
                                        />
                                    </div>
                                    <div onClick={() => deleteSlide(index)} >
                                        <Icon
                                            source={MobileCancelMajor}
                                        />
                                    </div>
                                </div>
                                <KnobAdvancedText
                                    knob={{
                                        type: 'advanced_text' as const,
                                        defaultValue: {
                                            value: '',
                                            textColor: '#fff',
                                            textShadow: false,
                                            showCaption: true,
                                            fontWeight: FontWeightType.Normal,
                                            textAlign: TextAlignType.Center
                                        },
                                        description: 'Banner caption',
                                        switchable: true
                                    }}
                                    onChange={(v) => {
                                        console.log('item ', slider);
                                        setSlider((prevValue) => {
                                            const newValue: Knob.Slider = {
                                                ...prevValue,
                                            };
                                            newValue.listOfSlides![index].caption = {
                                                value: v.value,
                                                textColor: v.textColor,
                                                textAlign: v.textAlign,
                                                fontWeight: v.fontWeight,
                                                showCaption: v.showCaption
                                            }
                                            onChange(newValue, innerKey)
                                            return newValue;
                                        });
                                    }}
                                    innerKey={'SliderItem'}
                                    value={{
                                        showCaption: !slider.listOfSlides || slider.listOfSlides.length === 0 ? true : element.caption.showCaption,
                                        value: !slider.listOfSlides || slider.listOfSlides.length === 0 ? defaultSettings.value : element.caption.value,
                                        textColor: !slider.listOfSlides || slider.listOfSlides.length === 0 ? defaultSettings.textColor : element.caption.textColor,
                                        fontWeight: !slider.listOfSlides || slider.listOfSlides.length === 0 ? FontWeightType.Bold : element.caption.fontWeight,
                                        textAlign: !slider.listOfSlides || slider.listOfSlides.length === 0 ? TextAlignType.Center : element.caption.textAlign
                                    }}
                                    disabled={false}
                                />
                                <KnobBlurhashImage
                                    knob={{
                                        type: 'blurhash_file' as const,
                                        defaultValue: { src: DafaultBlockValues.backgroundUrl, blurhash: null, resizeMode: null },
                                        description: 'Background image',
                                        clearable: true,
                                    }}
                                    onChange={(v) => {
                                        setSlider((prevValue) => {
                                            const newValue: Knob.Slider = {
                                                ...prevValue,
                                            };
                                            newValue.listOfSlides![index].img = {
                                                src: v.src,
                                                blurhash: v.blurhash,
                                                resizeMode: v.resizeMode
                                            }
                                            onChange(newValue, innerKey)
                                            return newValue;
                                        });
                                    }}
                                    innerKey={'SliderItem'}
                                    value={{
                                        src: !slider.listOfSlides || slider.listOfSlides.length === 0 ? DafaultBlockValues.backgroundUrl : element.img.src,
                                        blurhash: !slider.listOfSlides || slider.listOfSlides.length === 0 ? null : element.img.blurhash,
                                        resizeMode: !slider.listOfSlides || slider.listOfSlides.length === 0 ? 'cover' : element.img.resizeMode
                                    }}
                                    disabled={false}
                                />
                                <KnobNavigationPicker
                                    knob={{
                                        type: 'navigation_picker' as const,
                                        description: 'Select where to direct your customers when they click on the banner',
                                    }}
                                    value={element.url || ''}
                                    innerKey={'nav-value'}
                                    onChange={(v) => {
                                        setSlider((prevValue) => {
                                            const newValue: Knob.Slider = {
                                                ...prevValue,
                                            };
                                            newValue.listOfSlides![index].url = v
                                            onChange(newValue, innerKey)
                                            return newValue;
                                        });
                                    }}
                                    disabled={false}
                                />
                            </div>
                        )
                        : <div
                            style={{ width: '100%', padding: '0 20px 0 0', display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '1px solid rgba(23, 24, 24, 0.3', margin: '15px 0', borderRadius: '8px' }}>
                            <div onClick={() => ChooseSlide(index)} style={{ flexGrow: 1, padding: '20px 0 20px 20px', cursor: 'pointer' }}>
                                <TextStyle variation="strong">
                                    {element.caption.value}
                                </TextStyle>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                                <Button
                                    plain
                                    onClick={() => ChooseSlide(index)}
                                    icon={<Icon source={EditMinor} />}
                                />
                                <Button
                                    plain
                                    onClick={() => { deleteSlide(index) }}
                                    icon={<Icon source={DeleteMinor} />}
                                />
                            </div>
                        </div>
                    }
                </>
                )
            })
        } else {
            return <div></div>
        }
    }, [slider, onChange, innerKey, initValue, knob]);

    return (
        <div>
            <div>
                <Collapsible
                    open={slider.opened}
                    id="basic-collapsible"
                    transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                    expandOnPrint>
                    {views}
                </Collapsible>
            </div>
            <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                <Button
                    primary
                    onClick={createSlide}
                    disabled={slider.listOfSlides === null || slider.listOfSlides.length < 8 ? false : true}
                >
                    Add slide
                </Button>
                {slider.listOfSlides !== null && slider.listOfSlides.length > 7 && <TextStyle variation="negative">You can't create more than 8 slides</TextStyle>}
            </div>
        </div>
    );
});