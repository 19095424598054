import { FormLayout, OptionList, Button, Popover, Icon, Heading, Labelled, Stack } from "@shopify/polaris"
import { ArrowLeftMinor, ArrowRightMinor, ViewportNarrowMajor } from "@shopify/polaris-icons"
import React, { useCallback, useEffect, useState } from "react"
import { Knob } from "../Knob"
import { KnobBoolean } from "./KnobBoolean"
import { KnobTextPosition } from "./KnobTextPosition"

export const KnobTitleProps = React.memo<Knob.KnobComponentProps<Knob.KnobTitlePropsDescriptor>>(({ knob, value: initValue, onChange, innerKey }) => {
    const { description } = knob
    const [enabled, setEnabled] = useState(initValue.enabled);
    const [position, setPosition] = useState(initValue.position);

    useEffect(() => {
        onChange({
            position: position,
            enabled: enabled
        }, innerKey);
    }, [position, enabled, knob, innerKey]);

    return (
        <FormLayout>
            <Labelled id={innerKey} label={description}>
                <Stack distribution={'fill'} alignment={'trailing'}>
                    <KnobBoolean
                        knob={{
                            type: 'boolean',
                            defaultValue: true,
                            description: 'Enable'
                        }}
                        value={initValue.enabled}
                        innerKey={`${innerKey}-enabled`}
                        onChange={setEnabled}
                    />
                    <KnobTextPosition
                        knob={{
                            type: 'text_position',
                            defaultValue: 'center',
                            description: ''
                        }}
                        value={initValue.position}
                        innerKey={`${innerKey}-text-position`}
                        onChange={setPosition}
                    />
                </Stack>
            </Labelled>
        </FormLayout>
    )
})