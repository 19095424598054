import {
    Page,
    Button,
    Badge, ButtonGroup, FooterHelp, Link, Layout} from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { View } from 'react-native'
import { Switch, useHistory, useParams, useRouteMatch } from "react-router";
import { useClient } from "../../api/useClient";
import { useAccount } from "../../configure/utils/useAccount";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { AccountPricingStatus, AppCardPublishedStatus, AppCardStatus } from "../../api/spacex.types";
import moment from "moment";
import { AppEmulatorConfig } from "../../content/emulator/types";
import { AppCard, AppCardPublished } from "../../components/types";
import { useAppCard } from "../../configure/utils/useAppCard";
import { TrafficExchangeListing } from "./pages/TrafficExchangeListing";
import { AccountRequireRoute } from "../../routing/AccountRequireRoute";
import { PublicationStatus, TrafficExchangeProfile } from "./pages/TrafficExchangeProfile";
import { TrafficExchangeCommunity } from "./pages/TrafficExchangeCommunity";
import { TrafficExchangeDashboard } from "./components/TrafficExchangeDashboard";
import { Suspense } from "../../components/Suspense";
import { CartmatePointsDashboard } from "./components/CartmatePointsDashboard";
import { ExchangeCatalogProfile } from "./components/ExchangeCatalogProfile";

export const AppPromote = React.memo(() => {
    const { id } = useParams<{ id: string }>();

    const client = useClient();
    const account = useAccount();
    const history = useHistory<{ from?: Location }>();
    const pricing = client.useDefaultPricing().defaultPricing;
    const match = useRouteMatch();
    const appBridge = useAppBridge();
    const app = client.useApp({ id }).app;
    const appConfig = client.useAppEmulatorConfig({ id }).app.draftConfig as AppEmulatorConfig;

    appConfig.design.colors.light.toolBarBackground = '#fff'
    appConfig.design.colors.light.bottomNavigationBackground = '#fff'

    const [initialAppCard, initialPublishedCard] = useAppCard(id);
    const [appCard, setAppCard] = useState<AppCard>(initialAppCard);
    const [publishedAppCard, setPublishedAppCard] = useState<AppCardPublished | null>(initialPublishedCard);

    let subscriptionBadge;
    if (account.pricing.status === AccountPricingStatus.TRIAL) {
        console.log(parseInt(account.pricing.trialEndAt!));
        let endAt = 'Trial' + (account.pricing.trialEndAt ?
            ('. End ' + moment(parseInt(account.pricing.trialEndAt!)).fromNow()) : ''
        ) + '.';
        subscriptionBadge = (<div style={{
            cursor: 'pointer',
        }} onClick={() => {
            history.push({ pathname: '/subscription' })
        }}><Badge status={"warning"}>{endAt}</Badge></div>);
    } else {
        subscriptionBadge = (<Badge status={"success"}>{`Subscription active: ${account.pricing.pricing?.title || 'default'}`}</Badge>);
    }

    let publicationBadge;
    let publicationStatus;
    if (
        appCard.status !== AppCardStatus.ON_REVIEW
        && publishedAppCard?.status === AppCardPublishedStatus.ACTIVE
    ) {
        publicationBadge = (<Badge status={"success"}>{PublicationStatus.Published}</Badge>);
        publicationStatus = PublicationStatus.Published;
    } else if (
        appCard.status !== AppCardStatus.ON_REVIEW
        && publishedAppCard?.status === AppCardPublishedStatus.INACTIVE
    ) {
        publicationBadge = (<Badge status={"critical"}>{PublicationStatus.Unpublished}</Badge>);
        publicationStatus = PublicationStatus.Unpublished;
    } else if (appCard.status === AppCardStatus.ON_REVIEW) {
        publicationBadge = (<Badge status={"warning"}>{PublicationStatus.OnReview}</Badge>);
        publicationStatus = PublicationStatus.OnReview;
    }

    const handleSubscribe = useCallback(() => {
        client.mutateSubscribeToPricing({
            id: account.id,
            pricingId: pricing.id,
            returnUrl: match.url
        }).then((result) => {
            console.log('Subscriiibe result', result);
            const redirect = ShopifyRedirect.create(appBridge);
            if (result.subscribeToPricing) {
                redirect.dispatch(ShopifyRedirect.Action.REMOTE, result.subscribeToPricing);
            }
        }).catch((e) => {
            console.warn(e);
        });
    }, [pricing, match]);

    console.log('[AppPromote] appCard', appCard);

    return (
        <Switch>
            {__DEV__ && (<AccountRequireRoute path={`${match.path}/profile`} component={TrafficExchangeProfile} />)}
            {__DEV__ && (<AccountRequireRoute path={`${match.path}/ads`} component={TrafficExchangeListing} />)}
            {__DEV__ && (<AccountRequireRoute path={`${match.path}/community`} component={TrafficExchangeCommunity} />)}
            {__DEV__ && (<AccountRequireRoute path={`${match.path}/cartmate-points`} component={CartmatePointsDashboard} />)}
            <AccountRequireRoute path={match.path}>
                <Page
                    title={'Cartmate Exchange Catalog'}
                    titleMetadata={<View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={{ marginRight: '5px' }}>{subscriptionBadge}</View>
                        {publicationBadge}
                    </View>}
                    primaryAction={
                        <ButtonGroup>
                            {account.pricing.status === AccountPricingStatus.TRIAL && <Button
                                primary
                                onClick={handleSubscribe}
                            >
                                Upgrade subscription
                            </Button>}
                        </ButtonGroup>
                    }>
                    <Layout>
                        <Layout.Section>
                            <ExchangeCatalogProfile />
                        </Layout.Section>
                        {__DEV__ && (<Layout.Section>
                            <Suspense>
                                <TrafficExchangeDashboard />
                            </Suspense>
                        </Layout.Section>)}
                        <Layout.Section>
                            <FooterHelp>
                                Learn more about{' '}
                                <Link onClick={() => {
                                    const redirect = ShopifyRedirect.create(appBridge);
                                    redirect.dispatch(ShopifyRedirect.Action.APP, '/about/traffic');
                                }}>
                                    Cartmate Exchange Catalog
                                </Link>
                            </FooterHelp>
                        </Layout.Section>
                    </Layout>
                </Page>
            </AccountRequireRoute>
        </Switch>
    );
});