import React, {useCallback, useEffect, useState} from "react";
import {View} from "react-native";
import {useLocation, useRouteMatch} from "react-router";
import {useClient} from "../api/useClient";
import {useQueryParams} from "../utils/useQueryParams";
import {useAppBridge} from "@shopify/app-bridge-react";
import {Redirect as ShopifyRedirect} from "@shopify/app-bridge/actions";
import {Loader} from "../components/Loader";
import {
    Banner,
    Button,
    Card,
    Form,
    FormLayout,
    Icon,
    Link,
    Modal,
    Stack,
    TextContainer,
    TextField,
    TextStyle,
    DisplayText
} from '@shopify/polaris';
import { backoff } from "@openland/patterns";

export const BillingApply = React.memo(()=>{
    const match = useRouteMatch();
    const client = useClient();
    const loc = useLocation();
    const params = useQueryParams<{returnUrl?:string}>();
    const appBridge = useAppBridge();

    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);    
    const handleConfirmBilling = useCallback(async () => {
        
        await backoff(async () => {
            setLoading(true);        
            try {
                console.log('Start applying billing');
                await client.mutateConfirmBilling();                
                setSuccess(true);
            } catch (e) {
                setSuccess(false);
                console.warn(e);
                return;
            } finally {
                console.log('Billing applyed');
                setLoading(false);
            }
            const redirect = ShopifyRedirect.create(appBridge);
            let redirectUrl = params.returnUrl || '/apps';
            if (params.returnUrl && params.returnUrl.indexOf('dashboard') !== -1) {
                redirectUrl = '/dashboard';
            }
            console.log('Redirect', redirectUrl);
            redirect.dispatch(ShopifyRedirect.Action.APP, redirectUrl);
        });
    }, [params]);
    useEffect(() => {
        handleConfirmBilling();
    }, []);
    console.log('[BillingApply] Apply billing');
    return (
        <View style={{
            width: '100%', height: '100%',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
        }} >
            {loading &&
                <View>
                    <TextContainer>
                        <DisplayText size={"large"}>
                            <TextStyle variation={"positive"}>Processing...</TextStyle>
                        </DisplayText>
                    </TextContainer>
                    <Loader />
                </View>
            }
            {(!loading && success) &&
                <View>
                    <TextContainer>
                        <DisplayText size={"large"}>
                            <TextStyle variation={"positive"}>Success! Redirecting...</TextStyle>
                        </DisplayText>
                    </TextContainer>
                </View>
            }
            {(!loading && !success) &&
                <View>
                    <TextContainer>
                        <DisplayText size={"large"}>
                            <TextStyle variation={"negative"}>Error! Please contact with support</TextStyle>
                        </DisplayText>
                    </TextContainer>
                    <Button primary onClick={handleConfirmBilling} loading={loading}>Try again</Button>
                </View>
            }
        </View>
    );
})