import React from 'react';
import { Text, TextProps, StyleSheet, TouchableOpacity, GestureResponderEvent } from 'react-native'
import { TypographyStyle } from '../Typography/commonTypography';

const style = StyleSheet.create({
    chip: {
        ...TypographyStyle.body1Object,
        paddingVertical: 5,
        paddingHorizontal: 16,
        borderRadius: 40,
        borderStyle: 'solid',
        alignSelf: 'baseline',
        borderWidth: 1,
        flexShrink: 0,
        borderColor: '#F2F2F2',
        backgroundColor: '#FFFFFF'
    }
})

export type ChipProps = React.PropsWithChildren<TextProps> & {
    onPress?: (event: GestureResponderEvent) => void
}

export const Chip = React.memo<ChipProps>(({onPress, ...props}) => (
    <TouchableOpacity onPress={onPress}>
        <Text {...props} style={[style.chip, props.style]}/>
    </TouchableOpacity>
))