import { Icon, Stack } from "@shopify/polaris";
import { SlideshowMajor } from '@shopify/polaris-icons';
import React from "react";
import { IconWrapper } from "../../components/Icon/IconView/Icons";
import {
    ContentSlider,
    ContentView,
} from '../../content/components';
import { TemplateWithVariables } from "../knobs/TemplateWithVariables";
import { FontWeightType, TextAlignType } from "../knobs/types";
import { ConfigBlock } from "./BlockTypes";
import { DafaultBlockValues } from "./defaults";


const Variables = {
    createBanners: {
        type: 'slider' as const,
        defaultValue: {
            listOfSlides: null,
            opened: false,
            current: null 
        },
        defaultSettings: {
            value: 'Caption',
            textColor: '#000',
            switchable: true,
            showCaption: true,
            fontWeight: FontWeightType.Normal,
            textAlign: TextAlignType.Center
        },
        description: 'Caption',
        disableAlpha: true,
    },
    height: {
        type: 'range' as const,
        defaultValue: 150,
        description: 'Height',
        min: 0,
        max: 300,
        isAdvanced: true
    },
    borderRadius: {
        type: 'range' as const,
        defaultValue: DafaultBlockValues.borderRadius,
        description: 'Border radius',
        min: 0,
        max: 100,
        isAdvanced: true
    },
    autoplay: {
        type: 'switchable_range' as const,
        defaultValue: { enabled: false, interval: 1 },
        description: 'Autoplay',
        min: 1,
        max: 30,
        isAdvanced: true
    },
}

const Slider = TemplateWithVariables(Variables, ({
    createBanners,
    borderRadius,
    autoplay,
    height
}) => {
    return (
        <ContentView>
            <ContentSlider
                listOfSlides={createBanners.listOfSlides}
                borderRadius={borderRadius}
                autoplay={autoplay}
                height={height}
                current={createBanners.current}

            />
        </ContentView>
    )
});

export default {
    Preview: () => <Stack alignment="center" distribution="center">
        <Stack.Item>
            <IconWrapper>
                <Icon source={SlideshowMajor} />
            </IconWrapper>
        </Stack.Item>
        <Stack.Item fill>
            Slider with banners
        </Stack.Item>
    </Stack>,
    RenderBlock: Slider,
    Icon: SlideshowMajor,
    Name: 'Slider with banners'
} as ConfigBlock