import Bugsnag from "@bugsnag/browser";
import { Button, Modal, Stack, TextStyle } from "@shopify/polaris";
import React, { useCallback } from "react";
import { useClient } from "../../../api/useClient";

export const DeletePageModal = React.memo((
    {
        pageName,
        pageId,
        open,
        onClose
    }: {
        pageName: string,
        pageId: string,
        open: boolean,
        onClose: () => void
    }) => {
    const client = useClient();
    const onDelete = useCallback(
        () => {
            client.mutateDeletePage({ id: pageId })
                .then((res) => {
                    client.refetchPages();
                }).catch((error) => {
                    Bugsnag.notify(error);
                }).finally(() => {
                    onClose();
                });
        },
        [client, pageId, onClose],
    );

    return (
        <Modal
            open={open}
            title={`Delete page: ${pageName}`}
            onClose={onClose}
        >
            <Modal.Section>
                <TextStyle>
                    {'Are you sure you want to delete this page? Please note that all references to this page will be removed from navigation menus and other content pages.'}
                </TextStyle>
            </Modal.Section>
            <Modal.Section>
                <Stack distribution={'trailing'}>
                    <Button onClick={onClose}>
                        {'Cancel'}
                    </Button>
                    <Button
                        destructive
                        onClick={onDelete}
                    >
                        {'Delete'}
                    </Button>
                </Stack>
            </Modal.Section>
        </Modal>
    );
});