import { Button, Card, FormLayout, Modal, Select, TextContainer, TextField, Page, Layout, FooterHelp, Link } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { useParams } from "react-router";
import { useClient } from "../api/useClient";
import { KnobFile } from "../configEditor/knobs/components/KnobFile";
import { KnobNavigation } from "../configEditor/knobs/components/KnobNavigation";
import { IconElement } from "../content/elements/IconElement";
import { TrialModal } from "../configure/modals/TrialModal";
import { Push } from "./Push";
import { useTransition, config, animated } from "@react-spring/native";
import { useInfoBanner } from "../components/banners/useInfoBanner";
import { TipKey } from "../components/banners/useTip";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { useAppBridge } from "@shopify/app-bridge-react";
import { DateTimePicker } from "../components/DatePicker/DateTimePicker";
import { parseTypeFromUrl } from "./helpers";
import { PushOnboarding } from "../components/onboarding/PushOnboarding";

const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';

export const AppPushNotifications = React.memo(() => {
    const client = useClient();
    const options = [
        {
            label: 'All customers',
            value: 'all',
        },
        {
            label: 'iOS customers',
            value: 'ios',
            prefix: <IconElement
                name={'logo-apple'}
                collection={'ionicons'}
                size={24}
            />
        },
        {
            label: 'Android customers',
            value: 'android',
            prefix: <IconElement
                name={'logo-android'}
                collection={'ionicons'}
                size={24}
            />
        },
    ];
    const [title, setTitle] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [image, setImage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [active, setActive] = useState(false);
    const [navigation, setNavigation] = useState<string>('');
    const [selectedPlatform, setSelectedPlatform] = useState<any>(options[0].value);

    const handlePlatformChange = useCallback((value) => setSelectedPlatform(value), []);
    const handleTitleChange = useCallback((value) => setTitle(value), []);
    const handleMessageOnChange = useCallback((value) => setMessage(value), []);
    const handleImageChange = useCallback((value) => setImage(value), []);
    const handleChange = useCallback(() => setActive(!active), [active]);
    const handleNavChange = useCallback((value) => setNavigation(value), []);


    const handleSendAction = React.useCallback(() => {
        handleChange();
        setLoading(true);
        let inputData = { title, message, image, navigation, selectedPlatform };
        console.log('input', inputData);

        // TODO send by platform 
    }, [title, message, image, selectedPlatform, navigation]);

    const [banner] = useInfoBanner(
        TipKey.PushNotificationsInfo,
        'Send Push Notifications!',
        'Push Notifications allow you to engage customers, retarget lost customers, build relationships with personalized ads and banners, increase customer retention and conversion rates (higher than email marketing) while generating more leads'
    );

    const closeBanner = useTransition(banner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    })

    const infoBanner = closeBanner(
        (style, banner) => banner
            && <animated.View style={[{ marginBottom: '2rem' }, style]}>
                <Card>{banner}</Card>
            </animated.View>
    );

    const [formBanner] = useInfoBanner(
        TipKey.PushNotificationsInfo,
        'Customize your Push Notification',
        'Cartmate allows you to target customers by platform including, All/Android/iOS. Fields like: title, message, content image, and navigation route (Product or Collection) can be edited here'
    );

    const closeFormBanner = useTransition(formBanner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    })

    const formInfoBanner = closeFormBanner(
        (style, banner) => banner
            && <animated.View style={[{ marginBottom: '4rem', }, style]}>
                <Card>{formBanner}</Card>
            </animated.View>
    );

    const appBridge = useAppBridge();
    const { id } = useParams<{ id: string }>();
    const now = new Date();


    const [scheduleDate, setScheduleDate] = useState<Date>(now);
    console.log('SCHEDULE', scheduleDate);
    return (<>
        <Page title={"Push notifications"}>
            {infoBanner}
            <Layout>
                <Layout.Section>
                    <Card>
                        <Card.Section>
                            {formInfoBanner}
                            <FormLayout>
                                <Select
                                    label="Customer platforms"
                                    options={options}
                                    onChange={handlePlatformChange}
                                    value={selectedPlatform}
                                />
                                <TextField
                                    label={"Title"}
                                    value={title}
                                    onChange={handleTitleChange}
                                    placeholder={'Notification title'}
                                />
                                <TextField
                                    label={"Message"}
                                    value={message}
                                    onChange={handleMessageOnChange}
                                    multiline={4}
                                    placeholder={'Notification message'}
                                />
                                <DateTimePicker
                                    selected={scheduleDate}
                                    onChange={setScheduleDate}
                                />

                                <View key={'Image'} style={{ marginBottom: 10 }}>
                                    <KnobFile
                                        knob={{
                                            type: 'file' as const,
                                            defaultValue: '',
                                            description: 'Upload push notification image',
                                            clearable: true,
                                        }}
                                        value={image}
                                        onChange={(knobValue: any, key: string) => handleImageChange(knobValue)}
                                        innerKey={'PushImage'}
                                    />
                                </View>
                                <View style={{ marginBottom: '0.6rem' }}>
                                    <KnobNavigation
                                        knob={{ type: 'navigation', description: 'Select where to direct your customers when a push notification is opened', }}
                                        value={{
                                            id: '',
                                            url: navigation || '',
                                            title: '',
                                            type: parseTypeFromUrl(navigation || '')
                                        }}
                                        innerKey={'nav-value'}
                                        onChange={handleNavChange}
                                    />
                                </View>
                                <div style={{ textAlign: 'right' }}>
                                    <Button primary onClick={() => setActive(true)}>Send</Button>
                                </div>
                            </FormLayout>
                        </Card.Section>
                        <Modal
                            open={active}
                            onClose={handleChange}
                            title={`Are you sure you want to send to ${selectedPlatform} customers?`}
                            primaryAction={{
                                content: 'Send',
                                onAction: handleSendAction,
                            }}
                            secondaryActions={[
                                {
                                    content: 'Cancel',
                                    onAction: handleChange,
                                },
                            ]}
                        >
                            <Modal.Section>
                                <TextContainer>
                                    <p>
                                        You are about to send push notifications to all of your selected shop customers.
                                    </p>
                                </TextContainer>
                            </Modal.Section>
                        </Modal>
                    </Card>
                </Layout.Section>
                <Layout.Section secondary>
                    <Push
                        title={title}
                        message={message}
                        selectedPlatform={selectedPlatform}
                        image={image}
                    />
                </Layout.Section>
                <TrialModal />
            </Layout>
            <FooterHelp>
                Learn more about{' '}
                <Link onClick={() => {
                    const redirect = ShopifyRedirect.create(appBridge);
                    redirect.dispatch(ShopifyRedirect.Action.APP, '/about/push');
                }}>
                    Cartmate push notifications
                </Link>
            </FooterHelp>
        </Page>
        </>
    );
});