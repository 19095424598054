import { SkeletonBodyText } from "@shopify/polaris";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { SuspenseLoader } from "../Suspense";
import { PageSkeleton } from "./PageSkeleton";
import { ResourceListSkeleton } from "./ResourceListSkeleton";
import { SkeletonCard } from "./SkeletonCard";


export enum SkeletonType {
    ResourceList = 'resource-list',
    Page = 'page',
    Text = 'text',
    Card = "Card"
}

type Props = {
    style?: StyleProp<ViewStyle>
    debug?: boolean,
    type?: SkeletonType
}

export const SkeletonSuspenseLoader = React.memo<Props>((props: Props) => {
    let skeleton = <ResourceListSkeleton />;

    switch (props.type) {
        case SkeletonType.ResourceList:
            skeleton = <ResourceListSkeleton />;
            break;
        case SkeletonType.Page:
            skeleton = <PageSkeleton />;
            break;
        case SkeletonType.Text:
            skeleton = <SkeletonBodyText />;
            break;
        case SkeletonType.Card:
            skeleton = <SkeletonCard />;
            break;
        default:
            return <SuspenseLoader />;
    }

    return (
        <div style={{ width: '100%', height: '100%', display: 'block' }}>
            {skeleton}
        </div>
    );
});

export const SkeletonSuspense = React.memo<React.PropsWithChildren<Props>>(props => {

    return (
        <React.Suspense fallback={<SkeletonSuspenseLoader {...props} />}>
            {props.children}
        </React.Suspense>
    );
});