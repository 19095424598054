import { Popover } from "@shopify/polaris";
import React, { useCallback, useMemo, useState } from "react";
import { SketchPicker } from "react-color";
import { Pressable, View, StyleSheet } from "react-native";
import { Theme, ThemeSet } from "./themes/themes";
import HexaIcon from "./HexaIcon";
import { accentColorsSet } from "./ThemeSetComponent";


const colorPickerIcon = require('../../assets/images/custom-color-picker.svg');

export const ThemeColorPicker = React.memo((props: {
    themeSet: ThemeSet,
    onChange: (themeSet: ThemeSet) => void
}) => {
    const [popoverActive, setPopoverActive] = useState(false);
    const currentAccent = props.themeSet.light.accent;
    const isSelected = accentColorsSet.findIndex((c) => currentAccent === c) === -1;

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );

    const style = useMemo(() => StyleSheet.create({
        circle: {
            height: 40, width: 40,
            borderRadius: 20,
            alignItems: 'center',
            justifyContent: 'center',
            padding: 1,
            cursor: 'pointer',
            borderWidth: 2,
            borderColor: currentAccent,
            boxShadow: `0px 0px 5px ${currentAccent}`
        }
    }), [currentAccent, isSelected]);

const activator = useMemo(
    () => <Pressable
        onPress={togglePopoverActive}
        style={style.circle}
    >
        <div className="theme__color_picker" style={{
            textAlign: 'center',
            width: '28px',
            height: '28px',
            backgroundImage: `url(${colorPickerIcon})`,
            backgroundSize: 'cover',
        }}>
        </div>
    </Pressable>, [isSelected, currentAccent]);

return (
    <View style={{ flexDirection: 'column' }}>
        <Popover
            active={popoverActive}
            activator={activator}
            onClose={togglePopoverActive}
        >
            <SketchPicker
                color={currentAccent}
                onChange={(v) => props.onChange({
                    light: { ...props.themeSet.light, accent: v.hex },
                    dark: { ...props.themeSet.dark, accent: v.hex },
                })}
                disableAlpha={true}
            />
        </Popover>
    </View>
);
});