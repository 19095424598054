import { Card, Layout, Page, Stack, TextField } from "@shopify/polaris";
import React from "react";
import { View } from "react-native";
import { AccountContactType } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { BaseContactForm } from "../components/BaseContactForm";
import { LegalContactForm } from "../components/LegalContactForm";
import { TextEditor } from "../components/TextEditor";
import { TrafficExchangeContactForm } from "../components/TrafficExchangeContactForm";

export const Contacts = React.memo(() => {
    const client = useClient();
    const contacts = client.useContacts().contacts;

    console.log('[Contacts]', contacts);

    return (
        <Page title={'Contact information'} breadcrumbs={[{ content: 'Settings', url: '/settings' }]}>
            <View style={{ marginBottom: '1.6rem' }}>
                <Layout>
                    <Layout.AnnotatedSection
                        title={'General'}
                        description={'General contact infomation used for notifiactions, support emails etc.'}
                    >
                        <Card sectioned>
                            <BaseContactForm contact={contacts?.find((c) => c.type === AccountContactType.Base)} />
                        </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection
                        title="Legal"
                        description={'Contact information used for legal questions'}
                    >
                        <Card sectioned>
                            <LegalContactForm contact={contacts?.find((c) => c.type === AccountContactType.Legal)} />
                        </Card>
                    </Layout.AnnotatedSection>
                    {__DEV__ && (
                        <Layout.AnnotatedSection
                            title="Traffic Exchange"
                            description={'Contact information used in Traffic Exchange Program'}
                        >
                            <Card sectioned>
                                <TrafficExchangeContactForm
                                    contact={contacts?.find((c) => c.type === AccountContactType.TrafficExchange)}
                                />
                            </Card>
                        </Layout.AnnotatedSection>
                    )}
                </Layout>
            </View>
        </Page>
    );
});