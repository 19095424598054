import { Icon, Stack } from "@shopify/polaris";
import { CategoriesMajor } from "@shopify/polaris-icons";
import React from "react";
import { FlexAlignType } from "react-native";
import { IconWrapper } from "../../components/Icon/IconView/Icons";
import {
    ContentScrollView,
    ContentText,
    ContentView,
} from '../../content/components';
import { mapTextAlign } from "../../utils/textUtils";
import { TemplateWithVariables } from "../knobs/TemplateWithVariables";
import { FontWeightType, ResizeMode, TextAlignType, ViewType } from "../knobs/types";
import { ConfigBlock } from "./BlockTypes";
import { CollectionBannersItem } from "./components/collection/CollectionBannersItem";
import { CollectionCircleListItem } from "./components/collection/CollectionCircleListItem";
import { CollectionGridItem } from "./components/collection/CollectionGridItem";
import { CollectionHorizontalListItem } from "./components/collection/CollectionHorizontalListItem";
import { CollectionListItem } from "./components/collection/CollectionListItem";
import { DafaultBlockValues } from "./defaults";

const Variables = {
    collections: {
        type: 'collections' as const,
        defaultValue: [] as any[],
        description: 'Collections to display',
    },
    blockTitle: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: '',
            textAlign: TextAlignType.Start,
            textColor: '#000',
            textShadow: false,
            showCaption: true,
            fontWeight: FontWeightType.Normal
        },
        description: 'Collection\'s caption placeholder',
        placeholder: 'Enter caption',
        disableAlpha: true,
    },
    blurIntencity: {
        type: 'range' as const,
        defaultValue: 50,
        description: 'Captions background intencity',
        min: 0,
        max: 100
    },
    viewType: {
        type: 'view_type' as const,
        defaultValue: 'list',
        description: 'View type',
    },
    imageResizeMode: {
        type: 'resize_mode' as const,
        defaulValue: ResizeMode.Cover,
        description: 'Image resize mode'
    },
    collectionCaptionProps: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: '',
            textAlign: TextAlignType.Start,
            textColor: '#FFF',
            textShadow: false,
            showCaption: true,
            fontWeight: FontWeightType.Normal
        },
        description: 'Caption parameters',
        placeholder: 'Enter caption placeholder',
        disableAlpha: true,
        noValue: true
    },
    viewsHeight: {
        type: 'range' as const,
        defaultValue: 172,
        description: 'Card height',
        min: 100,
        max: 1000,
    },
    borderRadius: {
        type: 'range' as const,
        defaultValue: 16,
        description: 'Border radius',
        min: 0,
        max: 100,
        isAdvanced: true
    },
    showTextBackground: {
        type: 'boolean' as const,
        defaultValue: true,
        description: 'Show text background',
        isAdvanced: true,
    },
    // showShadow: {
    //     type: 'boolean' as const,
    //     defaultValue: false,
    //     description: 'Enable banner shadow',
    //     isAdvanced: true,
    // },
    collectionPlaceholderImage: {
        type: 'blurhash_file' as const,
        defaultValue: { src: DafaultBlockValues.backgroundUrl, blurhash: null, resizeMode: ResizeMode.Cover },
        description: 'Collection\'s image replacement',
        clearable: true,
        isAdvanced: true
    },
    viewsMargins: {
        type: 'range' as const,
        defaultValue: 16,
        description: 'Margins between cards on block',
        min: 0,
        max: 100,
        isAdvanced: true,
    },
    marginCoordinates: {
        type: 'coordinates' as const,
        defaultValue: { left: 10, right: 10, top: 8, bottom: 0 },
        description: 'margin',
        min: 0,
        isAdvanced: true,
    },
    paddingCoordinates: {
        type: 'coordinates' as const,
        defaultValue: { left: 0, right: 0, top: 0, bottom: 0 },
        description: 'padding',
        min: 0,
        isAdvanced: true,
    },
}

const CollectionsList = TemplateWithVariables(Variables, ({
    collections,
    viewType,
    viewsHeight,
    viewsMargins,
    collectionPlaceholderImage,
    borderRadius,
    // showShadow, 
    showTextBackground, blockTitle,
    marginCoordinates,
    paddingCoordinates,
    imageResizeMode,
    collectionCaptionProps,
    blurIntencity
}) => {

    let views: any[] = [];

    switch (viewType) {
        case 'list': {
            views = collections.map((collection) => {
                return (<CollectionListItem collection={collection} textColor={blockTitle.textColor} />);
            });
            break;
        }
        case 'circles': {
            views = collections.map((collection, index) => {
                return (
                    <CollectionCircleListItem
                        key={`CollectionCircleListItem-${index}`}
                        id={collection.id}
                        textColor={collectionCaptionProps.textColor}
                        viewsHeight={viewsHeight}
                        showCaption={collectionCaptionProps.showCaption}
                        textAlign={collectionCaptionProps.textAlign}
                        showTextBackground={showTextBackground}
                        placeholderImage={collectionPlaceholderImage}
                        fontWeight={collectionCaptionProps.fontWeight}
                        // showShadow={showShadow}
                        viewsMargins={viewsMargins}
                        viewType={ViewType.Circles}
                        imageResizeMode={imageResizeMode as ResizeMode}
                        blurIntencity={blurIntencity}
                    />
                )
            });
            break;
        }

        case 'blocks': {
            const grid: any[] = [[], []]
            collections.forEach((collection, index) => {
                const colNumber = index % 2;
                grid[colNumber].push(
                    <CollectionGridItem
                        key={`CollectionGridItem-${index}`}
                        id={collection.id}
                        colNumber={colNumber}
                        borderRadius={borderRadius}
                        viewsHeight={viewsHeight}
                        viewsMargins={viewsMargins}
                        placeholderImage={collectionPlaceholderImage}
                        showCaption={collectionCaptionProps.showCaption}
                        textAlign={collectionCaptionProps.textAlign}
                        textColor={collectionCaptionProps.textColor}
                        fontWeight={collectionCaptionProps.fontWeight}
                        showTextBackground={showTextBackground}
                        // showShadow={showShadow}
                        imageResizeMode={imageResizeMode as ResizeMode}
                        blurIntencity={blurIntencity}
                    />
                );
            })

            views = [
                <ContentView style={{ flexDirection: 'row' }}>
                    <ContentView style={{ width: '50%' }}>
                        {grid[0]}
                    </ContentView>
                    <ContentView style={{ width: '50%' }}>
                        {grid[1]}
                    </ContentView>
                </ContentView>
            ]
            break;
        }

        case 'banners': {
            views = collections.map((collection, index) => {
                return (
                    <CollectionBannersItem
                        key={`CollectionBannersItem-${index}`}
                        id={collection.id}
                        viewsHeight={viewsHeight}
                        borderRadius={borderRadius}
                        viewsMargins={viewsMargins}
                        placeholderImage={collectionPlaceholderImage}
                        showCaption={collectionCaptionProps.showCaption}
                        textAlign={collectionCaptionProps.textAlign}
                        textColor={collectionCaptionProps.textColor}
                        fontWeight={collectionCaptionProps.fontWeight}
                        showTextBackground={showTextBackground}
                        // showShadow={showShadow}
                        imageResizeMode={imageResizeMode as ResizeMode}
                        blurIntencity={blurIntencity}
                    />
                );
            })
            break;
        }

        case 'horizontal_list': {
            views = collections.map((collection, index) => {
                return (
                    <CollectionHorizontalListItem
                        key={`CollectionHorizontalListItem-${index}`}
                        id={collection.id}
                        viewsHeight={viewsHeight}
                        borderRadius={borderRadius}
                        viewsMargins={viewsMargins}
                        placeholderImage={collectionPlaceholderImage}
                        showCaption={collectionCaptionProps.showCaption}
                        textAlign={collectionCaptionProps.textAlign}
                        textColor={collectionCaptionProps.textColor}
                        fontWeight={collectionCaptionProps.fontWeight}
                        showTextBackground={showTextBackground}
                        // showShadow={showShadow}
                        imageResizeMode={imageResizeMode as ResizeMode}
                        blurIntencity={blurIntencity}
                    />
                );
            })
            break;
        }
    }

    return (
        <ContentView
            style={{
                flexDirection: 'column',
                borderRadius: borderRadius,
                paddingLeft: paddingCoordinates.left,
                paddingTop: paddingCoordinates.top,
                paddingBottom: paddingCoordinates.bottom,
                paddingRight: paddingCoordinates.right,
                flexShrink: 1,
                marginBottom: marginCoordinates.bottom,
                marginTop: marginCoordinates.top,
                marginLeft: marginCoordinates.left,
                marginRight: marginCoordinates.right
            }}
        >
            <ContentText
                style={{
                    color: blockTitle.textColor, fontSize: 18,
                    marginBottom: 4, marginLeft: 4,
                    alignSelf: blockTitle.textAlign as FlexAlignType,
                    textAlign: mapTextAlign(blockTitle.textAlign)
                }}
                smartColor
            >
                {blockTitle.showCaption ? blockTitle.value : ''}
            </ContentText>
            <ContentScrollView
                horizontal={viewType === 'circles' || viewType === 'horizontal_list'}
                style={{
                    height: viewType === 'circles'
                        ? viewsHeight + 4
                        : undefined,

                }}
            >
                {views}
            </ContentScrollView>
        </ContentView>
    )
});

export default {
    Preview: () => <Stack alignment="center" distribution="center">
        <Stack.Item>
            <IconWrapper>
                <Icon source={CategoriesMajor} />
            </IconWrapper>
        </Stack.Item>
        <Stack.Item fill>
            Collections List
        </Stack.Item>
    </Stack>,
    RenderBlock: CollectionsList,
    Icon: CategoriesMajor,
    Name: 'Collections List'
} as ConfigBlock