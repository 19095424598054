import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Labelled, LabelledProps, Popover } from '@shopify/polaris'
import { IconViewButton } from '../IconView/Icons';
import { useBooleanState } from '../../hooks/useBooleanState';
import { IconView } from '../../types';
import { AllIconsView } from './AllIconsView';
import { FlatIconCollectionTitleWrapper, useAllIconsFlat } from './iconsData';
import { LoadingIconsView } from './LoadingIconsView';
import { IconPickerHeader } from './IconPickerHeader';
import type { FixedSizeList, ListOnItemsRenderedProps } from 'react-window';
import { FilteredIconsView } from './FilteredIconsView';
import { IconPickerFooter } from "./IconPickerFooter";
import { FavoriteFilteredIconsView, FavoriteIconCollectionTitleWrapper, FavoritesIcons, useFavoriteIconsFlat } from './FavoritesIcons';
import { MixpanelEvent, trackEvent } from '../../../utils/mixpanel';

type Props = {
    value: IconView | null
    onSelect: (value: IconView | null) => void
} & LabelledProps


export const IconPicker = React.memo<Props>(({ value, onSelect, error, label, id }) => {

    const [blockHeight, setBlockHeight] = useState(250);
    const [icons, collections, rawIcons, loading] = useAllIconsFlat(5);
    const [favIcons, favCollections, favRawIcons, favLoading] = useFavoriteIconsFlat(5);
    const [currentActiveIndex, setCurrentActiveIndex] = useState(0);
    const allScroll = useRef<FixedSizeList>(null)
    const onItemsRendered = useCallback((props: ListOnItemsRenderedProps) => {
        setCurrentActiveIndex(props.visibleStartIndex)
    }, [])

    const onClickCollection = useCallback((item: FlatIconCollectionTitleWrapper | FavoriteIconCollectionTitleWrapper) => {
        allScroll.current?.scrollToItem(item.flatIndexStart, 'start')
    }, [])
    const [searchValue, setSearchValue] = useState<null | string>(null)
    const [favoritesScreenShown, setFavoritesScreenShown] = useState<boolean>(true);
    const contentRef = useRef<HTMLDivElement>(null);


    const [popoverActive, openPopover, closePopover, togglePopover] = useBooleanState(false);

    const activator = useMemo(() => {
        return <Labelled error={error && ' '} label={label} id={id}>
            <IconViewButton disclosure onClick={() => {
                trackEvent(MixpanelEvent.IconPicker);
                togglePopover();
            }} value={value} />
        </Labelled>
    }, [value, label, id, error])

    useEffect(() => {
        if (contentRef.current) {
            // TODO: I think this is zhutkiy kostil
            const p = contentRef.current.parentNode?.parentNode;
            const p1 = contentRef.current.parentNode;
            if (p && p1) {
                (p as HTMLDivElement).style.overflow = "hidden";
                setTimeout(() => {
                    const styles = window.getComputedStyle(p as HTMLDivElement);
                    const margins = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
                    const height = Math.ceil((p as HTMLDivElement).offsetHeight + margins);
                    let listHeight = (p as HTMLDivElement).clientHeight;
                    const stylesP1 = window.getComputedStyle(p1 as HTMLDivElement);
                    const marginsP1 = parseFloat(stylesP1['paddingTop']) + parseFloat(stylesP1['paddingBottom']);
                    listHeight -= marginsP1;
                    setBlockHeight(listHeight);
                }, 300);
            }
        }
    }, [popoverActive]);
    console.log('IconPicker rerender')
    return (
        <Popover
            active={popoverActive}
            activator={activator}
            onClose={closePopover}
            ariaHaspopup={'dialog'}
            fluidContent
            fullHeight
        >
            <Popover.Pane fixed>
                <Popover.Section>
                    <IconPickerHeader search={searchValue} onSearchChange={setSearchValue} collections={collections} currentActiveIndex={currentActiveIndex} onClick={onClickCollection} />
                </Popover.Section>
            </Popover.Pane>
            <Popover.Pane>
                <LoadingIconsView loading={loading}>
                    <div style={{ width: "100%", position: "relative", height: 400 }} ref={contentRef}>
                        {!favoritesScreenShown &&
                            <FilteredIconsView height={blockHeight} value={value} onSelect={onSelect} search={searchValue} icons={rawIcons}>
                                <AllIconsView height={blockHeight} onItemsRendered={onItemsRendered} value={value} onSelect={onSelect} flatIcons={icons} ref={allScroll} />
                            </FilteredIconsView>}
                        {favoritesScreenShown &&
                            <FavoriteFilteredIconsView height={blockHeight} value={value} onSelect={onSelect} search={searchValue} icons={favRawIcons}>
                                <FavoritesIcons height={blockHeight} onItemsRendered={onItemsRendered} value={value} onSelect={onSelect} ref={allScroll} flatIcons={favIcons} />
                            </FavoriteFilteredIconsView>}
                    </div>
                </LoadingIconsView>
            </Popover.Pane>

            <Popover.Pane fixed>
                <Popover.Section>
                    <IconPickerFooter
                        collections={collections}
                        currentActiveIndex={currentActiveIndex}
                        onClick={onClickCollection}
                        favoritesScreenShown={favoritesScreenShown}
                        setFavoritesScreenShown={setFavoritesScreenShown}
                        favoritesCollections={favCollections}
                    />
                </Popover.Section>
            </Popover.Pane>
        </Popover>
    )
})
