import React, { SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { Card, Form, FormLayout, TextField, Stack } from '@shopify/polaris';
import { useValidateEmptyObject, useValidateEmptyString } from '../../content/contentPage/editPageUtils';
import { IconPicker } from '../../components/Icon/IconPicker/IconPicker';
import { TabContent } from '../../components/content/pageContentUtils';
import { ContentPicker } from '../../components/content/ContentPicker/ContentPicker';
import { TabViewer } from '../../components/content/ContentViewer/TabViewer';
import { IconView, TabDesigner } from '../../components/types';

type ErrorState = Record<'titleError' | 'iconError', string | null>

export function useVerifyEditFormState(tab: TabDesigner) {
    const {title, icon} = tab
    const [titleError, cleanTitleError, verifyTitle] = useValidateEmptyString(title, 'title can`t be empty')
    const [iconError, cleanIconError, verifyIcon] = useValidateEmptyObject(icon, 'icon can`t be empty')

    useEffect(cleanTitleError, [title])
    useEffect(cleanIconError, [icon])

    const errorState = useMemo<ErrorState>(() => ({
        titleError, iconError,
    }), [titleError, iconError])

    const verify = useCallback(() => {
        return [verifyTitle(), verifyIcon()].every(b => b)
    }, [verifyTitle, verifyIcon])

    return [errorState, verify] as const
}

type Props = {
    item: TabDesigner,
    errorState: ErrorState,
    onChange: (val: SetStateAction<TabDesigner>) => void
    onSubmit: () => void
}

export const EditMoreMenuItem = React.memo<Props>(({item, onChange, onSubmit, errorState}) => {

    const onTitleChange = useCallback((title: string) => {
        onChange(item => ({
            ...item,
            title,
        }))
    }, [onChange])

    const onIconChange = useCallback((icon: IconView | null) => {
        onChange(item => ({
            ...item,
            icon,
        }))
    }, [onChange])

    const {title, icon, contentType, contentKey} = item
    const content = useMemo(() => ({contentType, contentKey}), [contentType, contentKey])

    const onContentChange = useCallback((content: TabContent) => {
        onChange(item => ({
            ...item,
            ...content,
        }))
    }, [onChange])

    return (
        <>
            <Card title='Tab settings'>
                <Card.Section>
                    <Form onSubmit={onSubmit}>
                        <FormLayout>
                            <Stack>
                                <Stack.Item>
                                    <IconPicker label={'Icon'} id={'icons'} error={errorState.iconError || false}
                                                value={icon} onSelect={onIconChange}/>
                                </Stack.Item>
                                <Stack.Item fill>
                                    <TextField label="Title" value={title} onChange={onTitleChange}
                                               error={errorState.titleError || false}/>
                                </Stack.Item>
                            </Stack>
                            <ContentPicker id={'content'} value={content} label="Content" onSelect={onContentChange}/>
                        </FormLayout>
                    </Form>
                </Card.Section>
            </Card>
            <TabViewer tab={item}/>
        </>
    )
})