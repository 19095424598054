import { Button, Form, FormLayout, Stack, TextField, Toast } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { AccountContactType, Contacts_contacts } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";

export const LegalContactForm = React.memo((
    {
        contact
    }: {
        contact?: Contacts_contacts
    }) => {
    const client = useClient();

    const [name, setName] = useState(contact?.data.name);
    const [email, setEmail] = useState(contact?.data.email);
    const [phone, setPhone] = useState(contact?.data.phoneNumber);
    const [isDirty, setIsDirty] = useState(false);
    const [showSaveToast, setShowSaveToast] = useState(false);

    useEffect(() => {
        if (
            name !== contact?.data.name
            || email !== contact?.data.email
            || phone !== contact?.data.phoneNumber
        ) {
            setIsDirty(true);
        }
    }, [name, email, phone]);

    const saveToast = showSaveToast ? (
        <Toast content="Saved!" onDismiss={() => setShowSaveToast(false)} />
    ) : null;

    const submit = useCallback(
        () => {
            client.mutateCreateOrUpdateContact({
                type: AccountContactType.Legal,
                input: {
                    name: name,
                    email: email,
                    phoneNumber: phone
                },
                id: contact ? contact?.id : undefined
            }).then(() => {
                setShowSaveToast(true);
                setIsDirty(false);
                client.refetchContacts();
            })
        },
        [name, email, phone, client, contact, setIsDirty, setShowSaveToast],
    );

    return (
        <Form onSubmit={submit}>
            <FormLayout>
                <Stack vertical>
                    <Stack.Item>
                        <TextField
                            id={'name-input'}
                            placeholder={''}
                            label={'Contact name'}
                            error={''}
                            onChange={setName}
                            type="text"
                            value={name ? name : ''}
                        />
                    </Stack.Item>
                    <Stack distribution={'fill'}>
                        <Stack.Item>
                            <TextField
                                id={'email-input'}
                                placeholder={''}
                                label={'Email'}
                                error={''}
                                onChange={setEmail}
                                type={'email'}
                                inputMode={'email'}
                                value={email ? email : ''}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField
                                id={'phone-input'}
                                placeholder={''}
                                label={'Phone'}
                                error={''}
                                onChange={setPhone}
                                type={'tel'}
                                inputMode={'tel'}
                                value={phone ? phone : ''}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
                <Stack distribution={"trailing"}>
                    <Button primary={isDirty} disabled={!isDirty} submit>
                        {'Save'}
                    </Button>
                </Stack>
            </FormLayout>
            {saveToast}
        </Form>
    );
});