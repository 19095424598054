import Bugsnag from "@bugsnag/browser";
import { Collapsible, Modal, TextField } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { AdListingScope } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { Suspense } from "../../../components/Suspense";
import { AdListingPoints, AdListingPointsSettings } from "./AdListingPoints";
import { ExpireAtSelector } from "./ExpireAtSelector";
import { IndustriesSelector } from "./IndustriesSelector";
import { ListingPlacement, ListingPlacementData } from "./LisingPlacement";
import { ListingContent, ListingContentSelector } from "./ListingContentSelector";

export const AddLisingModal = React.memo((
    {
        active,
        handleCancel,
        onSuccess
    }: {
        active: boolean,
        handleCancel: () => void,
        onSuccess: () => void
    }
) => {
    const client = useClient();

    const walletBalance = client.useCoinStatus({ fetchPolicy: 'network-only' }).account.cartCoins;

    const [content, setContent] = useState<ListingContent>();
    const [contentError, setContentError] = useState<string>();
    const [industries, setIndustries] = useState<string[]>();
    const [industiresError, setIndustiresError] = useState<string>();
    const [expireAt, setExpireAt] = useState<number>();
    const [expireAtError, setExpireAtError] = useState<string>();
    const [caption, setCaption] = useState<string>();
    const [captionError, setCaptionError] = useState<string>();
    const [listingPlacement, setLisingPlacement] = useState<ListingPlacementData>();
    const [lisingPlacementError, setLisingPlacementError] = useState<string>();
    const [pointsSettings, setPointsSettings] = useState<AdListingPointsSettings>();
    const [pointsError, setPointsError] = useState<string>();

    const checkInputs = useCallback(
        () => {
            if (!content) {
                setContentError('Listing content is required');
            }
            if (!industries || industries.length === 0) {
                setIndustiresError('Select at least one industry');
            }
            if (!expireAt) {
                setExpireAtError('Listing expiration is required');
            }
            if (!caption) {
                setCaptionError('Caption is required');
            }
            if (
                !listingPlacement
                || (
                    listingPlacement.type === AdListingScope.TARGETED
                    && (
                        !listingPlacement.stores
                        || listingPlacement.stores.length === 0
                    )
                )
            ) {
                setLisingPlacementError('Item placement is required');
            }

            if (!walletBalance || walletBalance === 0) {
                setPointsError('You dont have enough Cartmate Points');
            }

            if (!pointsSettings || (pointsSettings?.maxSpendings < pointsSettings?.bidPerClick)) {
                setPointsError('Invalid Bid settings');
            }
        },
        [content, industries, expireAt, caption, pointsSettings, listingPlacement, walletBalance],
    );

    const handleAdd = useCallback(
        () => {
            checkInputs();
            if (
                content && caption && expireAt
                && industries && listingPlacement && pointsSettings
                && walletBalance && walletBalance >= pointsSettings.maxSpendings
                && pointsSettings.maxSpendings >= pointsSettings.bidPerClick
            ) {
                console.log('input is ready', content, caption, expireAt, industries);
                client.mutateCreateAdListing({
                    input: {
                        caption: caption,
                        type: content.type,
                        content: {
                            id: content.id
                        },
                        context: {
                            tags: [],
                            industries: industries
                        },
                        expireAt: expireAt.toString(),
                        scope: listingPlacement.type,
                        maxSpendings: pointsSettings?.maxSpendings,
                        bidPerClick: pointsSettings?.bidPerClick
                    },
                    target: listingPlacement.type === AdListingScope.TARGETED
                        ? { stores: listingPlacement.stores! }
                        : undefined
                }).then((res) => {
                    console.log('mutateAddOrUpdateAdListing', res);
                    onSuccess();
                    client.refetchAdListings();
                }).catch((e) => {
                    console.log('mutateAddOrUpdateAdListing', e);
                    Bugsnag.notify(e);
                });
            }
        },
        [
            checkInputs, handleCancel, client,
            content, caption, expireAt, industries,
            listingPlacement, pointsSettings, walletBalance
        ],
    );

    const onChangeContent = useCallback(
        (value?: ListingContent) => {
            setContentError(undefined);
            setContent(value);
        },
        [setContent, setContentError],
    );

    const onChangeIndustries = useCallback(
        (value: string[]) => {
            setIndustiresError(undefined);
            setIndustries(value);
        },
        [setIndustries, setIndustiresError],
    );

    const onChangeExpireAt = useCallback(
        (value?: number) => {
            setExpireAtError(undefined);
            setExpireAt(value);
        },
        [setExpireAt, setExpireAtError],
    );

    const onChangePlacement = useCallback(
        (value?: ListingPlacementData) => {
            setLisingPlacementError(undefined);
            setLisingPlacement(value);
        },
        [setLisingPlacement, setLisingPlacementError],
    );

    const onChangePointsSettings = useCallback(
        (value?: AdListingPointsSettings) => {
            console.log('onChangePointsSettings', value);
            setPointsError(undefined);
            setPointsSettings(value);
        },
        [setPointsSettings, setPointsError],
    );

    return (
        <Modal
            open={active}
            onClose={handleCancel}
            title={'New Traffic Exchange item'}
            primaryAction={{
                content: 'Add',
                onAction: handleAdd,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: handleCancel,
                },
            ]}
        >
            <Suspense>
                <Modal.Section>
                    <TextField
                        error={captionError}
                        label={"Label"}
                        value={caption}
                        onChange={(value) => {
                            setCaption(value);
                            setCaptionError(undefined);
                        }}
                    />
                </Modal.Section>
                <Modal.Section>
                    <ListingContentSelector
                        initialValue={content}
                        onChange={onChangeContent}
                        error={contentError}
                    />
                </Modal.Section>
                <Modal.Section>
                    <Suspense>
                        <ListingPlacement
                            value={listingPlacement}
                            onChange={onChangePlacement}
                            error={lisingPlacementError}
                        />
                    </Suspense>
                </Modal.Section>
                <Collapsible
                    id={'industries-collapsible'}
                    open={listingPlacement?.type !== AdListingScope.TARGETED}
                    transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                >
                    <Modal.Section>
                        <IndustriesSelector
                            defaultIndustires={industries}
                            onChange={onChangeIndustries}
                            error={industiresError}
                        />
                    </Modal.Section>
                </Collapsible>
                <Modal.Section>
                    <AdListingPoints
                        error={pointsError}
                        value={pointsSettings}
                        onChange={onChangePointsSettings}
                    />
                </Modal.Section>
                <Modal.Section>
                    <ExpireAtSelector
                        initialValue={expireAt}
                        onChange={onChangeExpireAt}
                        error={expireAtError}
                    />
                </Modal.Section>
            </Suspense>
        </Modal>
    );
});