import { Banner, List, Card, TextContainer, Modal, Page, TextField, Layout } from "@shopify/polaris";
import React, {useEffect, useMemo} from "react";
import {useHistory, useParams, useRouteMatch} from "react-router";
import {useClient} from "../api/useClient";
import {useAccount} from "../configure/utils/useAccount";
import {useAppBridge} from "@shopify/app-bridge-react";

export const PluginPromote = React.memo(() => {
    const apps = useClient().useAllApps().apps;

    const sortedApps = useMemo(() => apps.sort((a, b) => a.name.localeCompare(b.name)), [apps])

    const activeApps = useMemo(() => sortedApps.filter(app => app.active), [sortedApps])
    const history = useHistory<{ from?: Location }>()
    useEffect(()=>{
        if (activeApps.length > 0) {
            history.replace({pathname:`/apps/${activeApps[0].id}/promote`});
        }
    }, [activeApps]);

    return (
        <Page title={`Plugin Dashboard`} >
            <Layout>
                <Layout.Section>

                </Layout.Section>
            </Layout>
        </Page>
    );
});