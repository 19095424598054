import { SkeletonPage, Layout, Card, SkeletonBodyText, TextContainer, SkeletonDisplayText } from "@shopify/polaris";
import React from "react";

export const PageSkeleton = React.memo(() => {

    return (
        <SkeletonPage fullWidth narrowWidth={true} primaryAction secondaryActions={2}>
            <Card sectioned>
                <SkeletonBodyText />
            </Card>
            <Card sectioned>
                <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText />
                </TextContainer>
            </Card>
            <Card sectioned>
                <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText />
                </TextContainer>
            </Card>
            <Card>
                <Card.Section>
                    <TextContainer>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText lines={2} />
                    </TextContainer>
                </Card.Section>
                <Card.Section>
                    <SkeletonBodyText lines={1} />
                </Card.Section>
            </Card>
            <Card>
                <Card.Section>
                    <TextContainer>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText lines={2} />
                    </TextContainer>
                </Card.Section>
                <Card.Section>
                    <SkeletonBodyText lines={2} />
                </Card.Section>
            </Card>
        </SkeletonPage>
    );
});