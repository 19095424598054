import { Checkbox } from "@shopify/polaris";
import React, { useState, useCallback, useEffect } from "react";
import { Knob } from "../Knob";

export const KnobBoolean = React.memo<Knob.KnobComponentProps<Knob.KnobBooleanDescriptor>>(
    ({ knob, value: initValue, onChange, innerKey }) => {
        const { description, defaultValue } = knob
        console.log(`${innerKey} initValue`, initValue);

        const handleChange = useCallback((newChecked) => {
            if (newChecked !== initValue) {
                onChange(newChecked, innerKey)
            }
        }, [initValue, onChange]);

        return <Checkbox
            label={description}
            checked={initValue}
            onChange={handleChange}
        />
    });