import * as React from 'react';
import { matchPath, Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router';
import { AppThemeComponent } from './theme/AppThemeComponent';
import { Layout, Tabs } from '@shopify/polaris';
import { TabDescriptor } from '@shopify/polaris/dist/types/3.4/src/components/Tabs/types';
import { useCallback, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import { AppCardComponent } from "./listing/AppCardComponent";
import { AppPromotionComponent } from "./listing/AppPromotionComponent";
import { AppBannerInputParams } from "../api/spacex.types";
import { MenuComponent } from './menu/MenuComponent';
import { PagesComponent } from '../routes/Pages/PagesComponent';
import { useSmartBanner } from '../SmartBannerContext';


const tabs: TabDescriptor[] = [

    {
        content: 'Branding',
        id: 'theme',
    },
    {
        content: 'Menu',
        id: 'menu',
    },
    {
        content: "Content pages",
        id: "pages"
    },
    {
        content: 'App Promotion Banner',
        id: 'promo'
    }
];


export const AppComponent = React.memo(() => {
    const match = useRouteMatch();
    const history = useHistory()
    const selectedTab = useMemo(() => matchPath<{ tab: string }>(history.location.pathname, `${match.url}/:tab?`), [match])

    const selected = Math.max(tabs.findIndex(tab => tab.id === selectedTab?.params.tab), 0)
    const onSelect = useCallback((selected: number) => {
        history.push(`${match.url}/${tabs[selected].id}`);
    }, [])
    const params = useParams<{ id: string }>();

    const loc = useMemo(() => { console.log('pathname change', history); return history.location; }, [history.location.pathname]);
    const { pathname } = useLocation();
    useEffect(() => {
        console.log('start scroll', document.documentElement.scrollTop, document.body.scrollTop, window.pageYOffset)
        let mode = Math.floor(Math.random() * 2) + 1;
        if (mode === 1) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            console.log('scrolled with mode 1')
        } else {
            document.documentElement.scrollTop = 0;
            console.log('scrolled with mode 2')
        }


    }, [pathname]);
    useEffect(() => {
        // https://github.com/Shopify/polaris-react/issues/169
        setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
    }, []);
    return (
        <View style={{
            width: "100%",
            flex: 1,
            flexDirection: "column"
        }}>
            <Tabs tabs={tabs} selected={selected} onSelect={onSelect} fitted />
            <View style={{ position: 'relative', marginTop: '20px' }}>
                <View style={{ width: '100%' }}>
                    <Switch location={loc}>
                        <Route path={`${match.path}/menu`}>
                            <Layout sectioned>
                                <MenuComponent />
                                {/* <AppMoreMenuItems/> */}
                            </Layout>
                        </Route>
                        <Route path={`${match.path}/theme`} component={AppThemeComponent} />
                        <Route path={`${match.path}/pages`} component={PagesComponent} />
                        <Route path={`${match.path}/card`} component={AppCardComponent} />
                        <Route path={`${match.path}/promo`}>
                            <AppPromotionComponent />
                        </Route>
                        <Redirect from={`${match.path}/`} exact to={`${match.url}/theme`} />
                    </Switch>
                </View>
            </View>
        </View>
    );
});