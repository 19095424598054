import Color from "color";
import React from "react";
import { AppEmulatorConfig } from "../types";
import { useEmulatorConfig } from "./ScalableEmulatorComponent";

const search = require('../../../assets/images/search.svg');

export const IOSSearchBar = React.memo((
    {
        config
    }: {
        config: AppEmulatorConfig
    }
) => {
    const emulator = useEmulatorConfig();
    const colors = config.design.colors[emulator.theme];

    const inputContainerColor = Color(colors.background).isDark()
        ? Color(colors.background).lighten(0.1).hex()
        : Color(colors.background).darken(0.1).hex();

    return (
        <div
            className="ios__content-top"
            style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: colors.toolBarBackground,
                padding: '20px 20px 14px 20px',
                flexGrow: 0,
                flexShrink: 0,
            }}
        >
            {/* <div
                className="ios__content-top-btn"
                style={{
                    marginTop: '-1px',
                    marginRight: '2%',
                    flexGrow: 0,
                    flexShrink: 0,
                }}
            >
                <IconElement
                    name={'ios-menu'}
                    collection={'ionicons'}
                    size={40}
                    color={colors.toolBarIcon}
                />
            </div> */}
            <div
                className="ios__content-top-input"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: inputContainerColor,
                    // padding: '5px 32px 5px 0',
                    padding: '15px 0',
                    fontSize: '24px',
                    lineHeight: '30px',
                    borderRadius: '15px',
                    flexGrow: 1,
                    flexShrink: 1,
                }}
            >
                <img
                    src={search}
                    width={24}
                    alt={"Search"}
                    style={{
                        marginLeft: '20px',
                        flexGrow: 0,
                        flexShrink: 0
                    }}
                />
                <span
                    style={{
                        color: colors.bottomNavigationInactiveText,
                        marginLeft: '25px',
                        whiteSpace: 'nowrap',
                        fontSize: '30px',
                        lineHeight: '32px',
                    }}
                >
                    What are you looking for?
                </span>
            </div>
        </div>
    );
});