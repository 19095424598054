import { ActionList, Popover, Stack, TextField } from "@shopify/polaris";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { View } from "react-native";

export type TimePickerValue = { min: number, hours: number };

type TimePickerError = { min: boolean, hours: boolean };

export const TimePicker = React.memo(({
    selected,
    onChange,
    disabled
}: {
    selected?: TimePickerValue,
    onChange: (value: TimePickerValue) => void,
    disabled?: boolean
}) => {
    const now = moment(new Date());
    const [errors, setErrors] = useState<TimePickerError>({ min: false, hours: false });

    const [state, setState] = useState<TimePickerValue>(selected || { min: now.minutes(), hours: now.hours() });

    const handleHoursChange = useCallback((value) => {
        let newValue = parseInt(value), error = false;
        if (isNaN(newValue) || newValue < 0 || newValue > 23) {
            error = true;
        } else {
            setState(({ min }) => {
                let newState = { hours: newValue, min };
                onChange(newState);
                return newState;
            });
        }
        setErrors(({ min }) => {
            return { hours: error, min };
        });
    }, [onChange]);

    const handleMinChange = useCallback((value) => {
        let newValue = parseInt(value), error = false;
        if (isNaN(newValue) || newValue < 0 || newValue > 59) {
            error = true;
        } else {
            setState(({ hours }) => {
                let newState = { min: newValue, hours };
                onChange(newState);
                return newState;
            });
        }
        setErrors(({ hours }) => {
            return { min: error, hours };
        });
    }, [onChange]);
    const [hoursPopoverActive, setHoursPopoverActive] = useState(false);

    const toggleHoursPopoverActive = useCallback(() => setHoursPopoverActive((popoverActive) => !popoverActive), []);

    const [minPopoverActive, setMinPopoverActive] = useState(false);

    const toggleMinPopoverActive = useCallback(() => setMinPopoverActive((popoverActive) => !popoverActive), []);

    return (
        <Stack alignment={'trailing'} distribution={'leading'}>
            <Stack.Item>
                <Popover
                    activator={
                        <TextField
                            label={'Time (HH:MM)'}
                            value={String(state.hours)}
                            onChange={handleHoursChange}
                            type={'number'}
                            min={0}
                            max={23}
                            step={1}
                            error={errors.hours}
                            placeholder={"00"}
                            onFocus={() => { !disabled ? setHoursPopoverActive(true) : undefined; }}
                            disabled={disabled || false}
                        />
                    }
                    active={hoursPopoverActive}
                    onClose={toggleHoursPopoverActive}
                >
                    <Popover.Pane>
                        <View style={{ maxHeight: 200 }}>
                            <ActionList
                                items={(Array.from({ length: 24 })).map((item, index) => {
                                    return {
                                        content: String(index).padStart(2, '0'),
                                        onAction: () => {
                                            handleHoursChange(String(index));
                                            toggleHoursPopoverActive();
                                        }
                                    }
                                })}
                            />
                        </View>
                    </Popover.Pane>
                </Popover>
            </Stack.Item>
            <Stack.Item>
                <Popover
                    activator={
                        <TextField
                            label={'Minutes'}
                            labelHidden
                            placeholder={"00"}
                            value={String(state.min)}
                            onChange={handleMinChange}
                            type={'number'}
                            maxLength={2}
                            min={0}
                            max={59}
                            step={1}
                            error={errors.min}
                            onFocus={() => { !disabled ? setMinPopoverActive(true) : undefined; }}
                            disabled={disabled || false}
                        />
                    }
                    active={minPopoverActive}
                    onClose={toggleMinPopoverActive}

                >
                    <Popover.Pane>
                        <View style={{ maxHeight: 200 }}>
                            <ActionList
                                items={(Array.from({ length: 60 })).map((item, index) => {
                                    return {
                                        content: String(index).padStart(2, '0'),
                                        onAction: () => {
                                            handleMinChange(String(index));
                                            toggleMinPopoverActive();
                                        }
                                    }
                                })}
                            />
                        </View>
                    </Popover.Pane>
                </Popover>
            </Stack.Item>
        </Stack>

    );
});