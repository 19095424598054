import { SelectOption } from './StyledSelect';
import { GroupType } from 'react-select/src/types';

function isGroupedOption<T>(opt:GroupType<T> | T): opt is GroupType<T> {
    return (opt as GroupType<T>).hasOwnProperty('options')
}

export function getOptionByValue<T extends SelectOption>(options:ReadonlyArray<T|GroupType<T>> = [], value?: SelectOption) {
    let option:SelectOption|undefined

    options.forEach(opt => {
        if (isGroupedOption(opt)) {
            option = getOptionByValue(opt.options, value)
        } else if (opt.value === value) {
            option = opt
        }
    })

    return option
}