import * as React from 'react';
import { CalloutCard, Card, FooterHelp, Link, Stack } from '@shopify/polaris';
import { useInfoBanner } from '../../components/banners/useInfoBanner';
import { TipKey } from '../../components/banners/useTip';
import { View } from 'react-native';
import { config, useTransition, animated } from '@react-spring/native';
import { AppMoreMenuItems } from '../more/AppMoreMenuItems';
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { AppMenuItems } from './AppMenuItems';
import { useAccessControl } from '../utils/useAccessControl';
import { Capability } from '../../utils/enums';
import { MenuOnboarding } from '../../components/onboarding/MenuOnboarding';

export const MenuComponent = React.memo(() => {
    const appBridge = useAppBridge();

    const [banner] = useInfoBanner(
        TipKey.Max5Items,
        'Customize your bottom navigation menu',
        'You can change the navigation panel as you like;  making adjustments to ensure your in-app shop is as convenient as possible for customers.');

    const closeBanner = useTransition(banner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    });

    const bannerC = closeBanner((style, banner) => banner
        && <animated.View style={[{ marginBottom: '2rem' }, style]}><Card>{banner}</Card></animated.View>);

    const ac = useAccessControl();
    const hasMainMenuAccess = ac.can('user').readOwn(Capability.MainMenu).granted
        && ac.can('user').createOwn(Capability.MainMenu).granted
        && ac.can('user').updateOwn(Capability.MainMenu).granted
        && ac.can('user').deleteOwn(Capability.MainMenu).granted
    const hasMoreMenuAccess = ac.can('user').readOwn(Capability.MoreMenu).granted
        && ac.can('user').createOwn(Capability.MoreMenu).granted
        && ac.can('user').updateOwn(Capability.MoreMenu).granted
        && ac.can('user').deleteOwn(Capability.MoreMenu).granted

    return (
        <View>
            <View>
                {bannerC}
            </View>
            {(!hasMainMenuAccess || !hasMoreMenuAccess) && (
                <CalloutCard
                    illustration="https://cdn.shopify.com/shopifycloud/online-store-web/assets/e6c5834a54458b58a2eab0e2d51bcc02.svg"
                    primaryAction={{
                        content: 'Upgrade Plan',
                        onAction: () => {
                            const redirect = ShopifyRedirect.create(appBridge);
                            redirect.dispatch(ShopifyRedirect.Action.APP, `/subscription`);
                        }
                    }}
                    title={'Upgrade your plan'}
                >
                    <Stack vertical>
                        <p>Сreate a unique eye catching design for your store and increase your sales with ease. You can create custom tab content pages, change tabs contents, icons and caption</p>
                    </Stack>
                </CalloutCard>
            )}
            {hasMainMenuAccess && hasMoreMenuAccess && <AppMenuItems />}
            {hasMoreMenuAccess && hasMainMenuAccess && <AppMoreMenuItems />}
            <FooterHelp>
                Learn more about{' '}
                <Link onClick={() => {
                    const redirect = ShopifyRedirect.create(appBridge);
                    redirect.dispatch(ShopifyRedirect.Action.APP, '/about/customize');
                }}>
                    in-app storefront navigation
                </Link>
            </FooterHelp>
            <MenuOnboarding/>
        </View>
    );
})