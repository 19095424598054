import { Page } from "@shopify/polaris";
import React from "react";
import { useRouteMatch } from "react-router";
import { ActiveAdLisings } from "../components/ActiveAdLisings";
import { InactiveAdLisings } from "../components/InactiveAdLisings";

export const MyAdListings = React.memo(() => {
    const match = useRouteMatch();
    return (
        <Page
            title={'My Traffic Exchange Positions'}
            subtitle={'List your products and collections for sale or advertise your store in other stores from Cartmate Exchange Catalog'}
            breadcrumbs={[{ content: 'Ads', url: `${match.url.replace('/positions', '')}` }]}
        >
            <ActiveAdLisings />
            <InactiveAdLisings />
        </Page>
    );
});