import Bugsnag from "@bugsnag/js";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { Badge, Card, DisableableAction, EmptyState, Pagination, ResourceList, Stack } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { AdListingItemStatus, ListAdListingsItems_listAdListingItems, ListAdListingsItems_listAdListingItems_edges_node } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { ResourceListSkeleton } from "../../../components/Skeleton/ResourceListSkeleton";
import { ReviewListingItemModal } from "./ReviewListingItemModal";
import { TargetedAdLisingItem } from "./TargetedAdLisingItem";

const emptyCartmateIcon = require('../../../assets/images/empty-cartmate-icon.svg');

export const ActiveAdListingItems = React.memo(() => {
    const client = useClient();
    const earnings = client.useExchangeEarnings().exchangeEarnings;
    const [results, setResults] = useState<ListAdListingsItems_listAdListingItems>();
    const [loading, setLoading] = useState<boolean>(false);
    const [reviewModalItem, setReviewModalItem] = useState<ListAdListingsItems_listAdListingItems_edges_node>();
    const [pageSize, setPageSize] = useState<string>('4');

    let nextQueryData = {
        first: parseInt(pageSize),
        after: results?.pageInfo.endCursor,
        status: AdListingItemStatus.ACTIVE
    };

    const loadNextPage = useCallback(() => {
        console.log('Load next page', nextQueryData);
        setLoading(true);
        client.queryListAdListingsItems({
            first: parseInt(pageSize),
            after: results?.pageInfo.endCursor,
            status: AdListingItemStatus.ACTIVE
        })
            .then((result) => {
                console.log('Nex page loaded', result);
                setResults(result.listAdListingItems);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
        // loadPage(pageSize, results?.pageInfo.endCursor);
    }, [results, client, pageSize]);

    const loadPrevPage = useCallback(() => {
        if (!results?.pageInfo.hasPreviousPage) {
            throw new Error("No previous page");
        }
        setLoading(true);
        client.queryListAdListingsItems({
            last: parseInt(pageSize),
            before: results.pageInfo.startCursor,
            status: AdListingItemStatus.ACTIVE
        })
            .then((result) => {
                console.log('Prev page loaded', result);
                setResults(result.listAdListingItems);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
        // loadPage(pageSize, results.pageInfo.startCursor);
    }, [results, client, pageSize]);

    useEffect(() => {
        loadNextPage();
    }, []);

    const handleModalCancel = useCallback(
        () => {
            setReviewModalItem(undefined);
        },
        [setReviewModalItem],
    );

    const handleSuspend = useCallback(
        (listing?: ListAdListingsItems_listAdListingItems_edges_node) => {
            if (listing) {
                client.mutateStopAdListingItem({ id: listing.id }).then((res) => {
                    console.log('mutateAcceptCommunityListing', res);
                }).catch((e) => {
                    console.log('mutateAcceptCommunityListing error', e);
                    Bugsnag.notify(e);
                }).finally(() => {
                    handleModalCancel();
                });
            }
        },
        [client, handleModalCancel],
    );

    if (loading) {
        return (<ResourceListSkeleton />);
    }

    return (
        <Card title={'Active positions on your Cartmate storefront'}>
            {(earnings > 0) &&
                (
                    <Card.Section>
                        <label>{'Earnings: '}</label>
                        <Badge status={'success'}>{`${earnings}`}</Badge>
                    </Card.Section>
                )}
            <Card.Section>
                {(results?.edges.length === 0) && <EmptyState
                    heading="No positions yet"
                    image={emptyCartmateIcon} />}
                {(results?.edges && results?.edges.length > 0) && (
                    <ResourceList
                        items={results?.edges.map((e) => e.node)}
                        renderItem={(l) => {
                            let actions: DisableableAction[] = [
                                {
                                    content: 'Review',
                                    onAction: () => {
                                        setReviewModalItem(l);
                                    }
                                }
                            ];
                            if (l.adListing.account.domain) {
                                console.log('Domain', l.adListing.account);
                                actions.push({
                                    content: "View store",
                                    onAction: () => {
                                        if (l.account.domain) {
                                            window.open('https://' + l.account.domain, "_blank")?.focus();
                                        }
                                    }
                                });
                            }
                            return (
                                <ResourceList.Item
                                    onClick={() => { }}
                                    id={l.adListing.id}
                                    shortcutActions={actions}
                                >
                                    <TargetedAdLisingItem item={l} />
                                </ResourceList.Item>
                            );
                        }}
                    />
                )}
            </Card.Section>
            {(results?.pageInfo.hasNextPage || results?.pageInfo.hasPreviousPage) && <Card.Section>
                <Stack distribution={"center"}>
                    <Pagination
                        hasPrevious={results?.pageInfo.hasPreviousPage}
                        onPrevious={() => {
                            loadPrevPage()
                        }}
                        hasNext={results?.pageInfo.hasNextPage}
                        onNext={() => {
                            loadNextPage()
                        }}
                    />
                </Stack>
            </Card.Section>}
            <ReviewListingItemModal
                item={reviewModalItem}
                handleSuspend={handleSuspend}
                handleCancel={handleModalCancel}
            />
        </Card>
    );
});