import { Icon, Stack, TextStyle } from "@shopify/polaris";
import { ArrowDownMinor, ArrowUpMinor } from "@shopify/polaris-icons";
import moment from "moment";
import React from "react";
import { View } from "react-native";
import { CoinTransactionType, ListPointsTransactions_listPointsTransactions_edges_node } from "../../../api/spacex.types";
import { dateFormat } from "../../../utils/textUtils";

export const PointsTransactionItem = React.memo(({ item }: { item: ListPointsTransactions_listPointsTransactions_edges_node }) => {
    const positive = item.type === CoinTransactionType.ADD;
    return (
        <View>
            <Stack.Item fill>
                <Stack>
                    {(item.reason && item.reason.length > 0) && (
                        <Stack.Item>
                            <p>
                                <TextStyle variation={'strong'}>
                                    {`${item.reason}`}
                                </TextStyle>
                            </p>
                        </Stack.Item>
                    )}
                    <Stack.Item fill>
                        <Stack distribution={'trailing'}>
                            <TextStyle variation={positive ? 'positive' : 'negative'}>
                                {`${item.amount * (positive ? 1 : -1)}`}
                            </TextStyle>
                            <Icon
                                source={positive ? ArrowUpMinor : ArrowDownMinor}
                                color={positive ? 'success' : 'critical'}
                            />
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <p>Date:
                <TextStyle variation={'subdued'}>
                    {` ${moment(parseInt(String(item.createdAt))).format(dateFormat)}`}
                </TextStyle>
            </p>
        </View>
    );
});