import React, { useState } from "react";
import { AppBannerInputParams } from "./api/spacex.types";

export const SmartBannerContext = React.createContext<
    [AppBannerInputParams | undefined, (sb: AppBannerInputParams) => void]
>(
    [undefined, () => { }]
);

export const SmartBannerContextLoader = React.memo((props: { children: any }) => {
    const [smartBanner, setSmartBanner] = useState<AppBannerInputParams | undefined>(undefined);

    console.log('[SmartBannerContext] val', smartBanner);
    
    return (
        <SmartBannerContext.Provider value={[smartBanner, (val) => {
            console.log('[SmartBannerContext] set', val);
            setSmartBanner(val)
        }]}>
            {props.children}
        </SmartBannerContext.Provider>
    );
});

export function useSmartBanner(): [AppBannerInputParams | undefined, (sb: AppBannerInputParams) => void] {
    return React.useContext(SmartBannerContext);
}