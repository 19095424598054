import { Badge } from "@shopify/polaris";
import { BlurView } from "expo-blur";
import React, { } from "react"
import { View, Text, ScrollView, ColorValue } from "react-native";
import { SliderProps } from "../components";

export const SliderView = React.memo((props: SliderProps) => {
    console.log('[SliderView] current', props.current);
    if (props.listOfSlides?.length === 0 || !props.listOfSlides) {
        return (
            <div
                style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}
            >
                <Badge status="attention">
                    Slider is empty
                </Badge>
            </div>
        );
    }

    const currentItem = !props.current
        ? props.listOfSlides[0]
        : props.listOfSlides[props.current];
    return (
        <View>
            <div style={{
                backgroundImage: `url(${currentItem.img.src})`,
                backgroundSize: currentItem.img.resizeMode as string,
                margin: '15px 10px',
                borderRadius: props.borderRadius,
                overflow: 'hidden'
            }}>
                <div style={{
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    height: props.height,
                    width: '100%',
                    alignItems: 'center',
                    position: 'relative'
                }}>
                    {currentItem.caption.showCaption && currentItem.caption.value.length > 0
                        ?
                        < BlurView
                            style={{
                                flexGrow: 1,
                                height: 68,
                                alignItems: currentItem.caption.textAlign,
                                justifyContent: 'center',
                                paddingTop: 8,
                                paddingBottom: 8,
                                paddingLeft: 16,
                                paddingRight: 16,
                                position: 'absolute',
                                left: 0, right: 0, bottom: 0,
                                width: '100%',
                                overflow: 'hidden'
                            }}
                            tint={'dark'}
                            intensity={70}
                        >
                            <Text
                                style={{
                                    color: currentItem.caption.textColor as ColorValue,
                                    fontSize: 16,
                                    fontWeight: currentItem.caption.fontWeight,
                                }}
                                numberOfLines={2}
                                ellipsizeMode={'tail'}
                            >
                                {currentItem.caption.value.length > 0 ? currentItem.caption.value : 'Untitled banner'}
                            </Text>
                        </BlurView>
                        :
                        undefined
                    }
                </div>
            </div>
        </View>
    )
});

