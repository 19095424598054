import React, { useEffect } from "react"
import { View } from "react-native"
import { useRouteMatch } from "react-router";
import { MyShop_shop_properties } from "../api/spacex.types";
import { useClient } from "../api/useClient";
import { Intercom } from "../App";

export const IntercomComponent = React.memo(() => {
    const client = useClient();
    const shop = client.useMyShop();
    useEffect(() => {
      console.log('Start boot intercom');
      Intercom.boot(shop.shop.properties);
    }, [])
    
    
    return (<></>);
})