import { Badge, Stack, TextStyle } from "@shopify/polaris";
import React from "react";
import { useClient } from "../../../api/useClient";

export const PointsBalance = React.memo(() => {
    const client = useClient();
    const walletBalance = client.useCoinStatus().account.cartCoins;

    return (
        <Badge status={'success'}>
            {`${walletBalance}`}
        </Badge>
    );
});