import { config, useTransition } from "@react-spring/core";
import { animated } from "@react-spring/native";
import { CalloutCard, Card } from "@shopify/polaris";
import React from "react";
import { useHistory, useRouteMatch } from "react-router";
import { useInfoBanner } from "../../../../components/banners/useInfoBanner";
import { TipKey } from "../../../../components/banners/useTip";

const profileDescription = 'You can customize store\'s title, description, '
    + 'manage your Community contact info and'
    + 'industries you operate in, store\'s logo and info page background image. ';

const customizeIcon = require('../../../../assets/images/customize-icon.svg');

export const ProfileCalloutCard = React.memo(() => {
    const history = useHistory<{ from?: Location }>();
    const match = useRouteMatch();

    const [profileBanner] = useInfoBanner(
        TipKey.TrafficExchangeProfileInfo,
        'Fill out and go through store profile moderation',
        'To be listed in the Cartmate Exchange Catalog you must fill out, go through store profile moderation and enable Traffic Exchange.'
    );

    const closeProfileBanner = useTransition(profileBanner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    });

    const profileBannerView = closeProfileBanner(
        (style, banner) => banner
            && <animated.View style={[{ marginBottom: '2rem' }, style]}>
                <Card>{banner}</Card>
            </animated.View>
    );

    return (
        <CalloutCard
            title="Customize your Traffic Exchnage Store Profile"
            illustration={customizeIcon}
            primaryAction={{
                content: 'Customize',
                onAction: () => {
                    if (__DEV__) history.push(`${match.url}/profile`);
                }
            }}
        >
            {profileBannerView}
            <p>{profileDescription}</p>
        </CalloutCard>
    );
});