import { ListProducts_listProducts_edges_node } from "../../../api/spacex.types";
import { Knob } from "../Knob";

export function mapNodeToProduct(node: ListProducts_listProducts_edges_node) {
    const price = node.variants
            ? node.variants[0]
                ? node.variants[0].formatted_price
                : undefined
            : undefined;
    return {
        id: node.id,
        title: node.title,
        image: node.images ? node.images[0] || null : undefined,
        price: price
    } as Knob.Product
}