import React from "react";
import { ContentView, ContentMenuItem } from "../../../../content/components";

export const ProductListItem = React.memo(({ product, textColor }: { product: any, textColor: string }) => {

    return (
        <ContentView
            style={{
                height: 44,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <ContentMenuItem url={`/product/${product.id}`} text={product.title} textColor={textColor} />
        </ContentView>
    );
})