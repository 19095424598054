import { useAppBridge } from "@shopify/app-bridge-react";
import { Button, Card, Stack, TextStyle } from "@shopify/polaris";
import React from "react";
import { View } from "react-native";
import { useHistory, useRouteMatch } from "react-router";
import { useClient } from "../../../api/useClient";
import { Suspense } from "../../../components/Suspense";
import { PointsBalance } from "./PointsBalance";
import { PointsTransactionsPreview } from "./PointsTransactionsPreview";

export const PointsSummury = React.memo(() => {
    const match = useRouteMatch();
    const history = useHistory();

    return (
        <View style={{ flexGrow: 1 }}>
            <Stack vertical alignment={'fill'}>
                <Stack.Item>
                    <Suspense>
                        <p style={{ flexDirection: 'row' }}>
                            <TextStyle variation={'strong'}>
                                {`Cartmate Points `}
                            </TextStyle>
                            <PointsBalance />
                        </p>
                    </Suspense>
                </Stack.Item>
                <Stack.Item fill>
                    <Suspense>
                        <PointsTransactionsPreview />
                    </Suspense>
                </Stack.Item>
            <Button
                fullWidth
                plain
                onClick={() => {
                    history.push(`${match.url}/cartmate-points`)
                }}
            >
                {'More'}
            </Button>
            </Stack>
        </View>
    );
});