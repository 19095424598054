import { Button, Collapsible, RangeSlider, Stack, TextField, TextStyle } from "@shopify/polaris"
import React, { useCallback, useEffect, useState } from "react"
import { Knob } from "../Knob"

export const KnobCoordinates = React.memo<Knob.KnobComponentProps<Knob.KnobCoordinatesDescriptor>>(
    ({ knob, value: initValue, onChange, innerKey }) => {
        const { description, min, max, step } = knob
        const [top, setTop] = useState((initValue.top || 0));
        const [bottom, setBottom] = useState((initValue.bottom || 0));
        const [left, setLeft] = useState((initValue.left || 0));
        const [right, setRight] = useState((initValue.right || 0));
        const [showContent, setShowContent] = useState<boolean>(false);

        const toggleAdvanced = useCallback(
            () => setShowContent((showContent) => !showContent),
            [],
        );

        // useEffect(() => {
        //     if (value !== initValue) {
        //         onChange(value, innerKey)
        //     }
        // }, [value, knob, innerKey])

        useEffect(() => {
            const newValue = {
                left: left,
                right: right,
                top: top,
                bottom: bottom,
            }
            if (newValue !== initValue) {
                onChange(newValue, innerKey)
                console.log("COLOR ", bottom);
            }
        }, [knob, innerKey, left, right, top, bottom]);

        const activator = (
            <TextStyle variation={'strong'}>
                {`${description.split('').shift()?.toUpperCase()+description.split('').slice(1).join('')} Settings`}
            </TextStyle>
        );

        return (
            <>
                {activator}
                {/* <Collapsible
                    id={'advanced-knobs'}
                    open={showContent}
                    transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                > */}
                    <div style={{
                        display: 'flex',
                        marginTop: '20px'
                    }}>
                        <div style={{
                            width: '50%',
                            marginRight: '30px'
                        }}>
                            <div style={{
                                marginBottom: '10px'
                            }}>
                                <TextField
                                    type={'number'}
                                    min={min}
                                    max={max}
                                    step={1}
                                    label={'top'}
                                    value={top.toString()}
                                    onChange={(top) => setTop(Number(top))}
                                />
                                <div style={{ marginTop: '10px' }}>
                                    <RangeSlider
                                        label={''}
                                        value={top}
                                        min={min}
                                        max={max}
                                        step={2}
                                        onChange={(top) => setTop((typeof top === 'number') ? top : 0)}
                                    />
                                </div>
                            </div>
                            <div>
                                <TextField
                                    type={'number'}
                                    min={min}
                                    max={max}
                                    step={1}
                                    label={'bottom'}
                                    value={bottom.toString()}
                                    onChange={(value) => setBottom(Number(value))}
                                />
                                <div style={{ marginTop: '10px' }}>
                                    <RangeSlider
                                        label={''}
                                        value={bottom}
                                        min={min}
                                        max={max}
                                        step={1}
                                        onChange={(value) => setBottom((typeof value === 'number') ? value : 0)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{
                            width: '50%'
                        }}>
                            <div style={{
                                marginBottom: '10px'
                            }}>
                                <TextField
                                    type={'number'}
                                    min={min}
                                    max={max}
                                    step={1}
                                    label={'left'}
                                    value={left.toString()}
                                    onChange={(value) => setLeft(Number(value))}
                                />
                                <div style={{ marginTop: '10px' }}>
                                    <RangeSlider
                                        label={''}
                                        value={left}
                                        min={min}
                                        max={max}
                                        step={2}
                                        onChange={(value) => setLeft((typeof value === 'number') ? value : 0)}
                                    />
                                </div>
                            </div>
                            <div>
                                <TextField
                                    type={'number'}
                                    min={min}
                                    max={max}
                                    step={1}
                                    label={'right'}
                                    value={right.toString()}
                                    onChange={(value) => setRight(Number(value))}
                                />
                                <div style={{ marginTop: '10px' }}>
                                    <RangeSlider
                                        label={''}
                                        value={right}
                                        min={min}
                                        max={max}
                                        step={2}
                                        onChange={(value) => setRight((typeof value === 'number') ? value : 0)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </Collapsible> */}
            </>
        )
    })