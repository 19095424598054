import React, { PropsWithChildren } from 'react';
import { View, StyleSheet, ScrollView, ViewProps } from 'react-native';
import { colors } from '../assets/colors';

const style = StyleSheet.create({
    layoutPage: {
        padding: 16,
        flex: 1,
        flexBasis: 0,
        backgroundColor: colors.lightBackground,
    },
    layoutForm: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 24,
        backgroundColor: colors.lightBackground,
    }
})

export const LayoutPage = React.memo<PropsWithChildren<{}>>(({ children }) => (
    <ScrollView style={style.layoutPage}>{children}</ScrollView>
))

export const LayoutForm = React.memo<PropsWithChildren<ViewProps>>(props => (
    <View {...props} style={[style.layoutForm, props.style]} />
))