import * as React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { LayoutChangeEvent, View } from 'react-native';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Theme } from 'tinymce';
import { MixpanelEvent, trackEvent } from '../../../utils/mixpanel';
import { IconElement } from '../../elements/IconElement';
import { themeStateAtom, ThemeType } from '../States';
import Switch from "react-switch";

const EmulatorBody = React.memo((props: { children?: any }) => {
    const [bigHeight, setBigHeight] = useState<number>(1544);
    const [bigWidth, setBigWidth] = useState<number>(765);

    useEffect(() => {
        let frameWidth = document.documentElement.scrollWidth
        console.log(frameWidth);
        if (frameWidth < 1920) {
            setBigWidth(590)
            setBigHeight(1200)
        }
    }, []);
    return (
        <View style={{
            width: bigWidth, height: bigHeight,
            borderColor: 'transparent',
            borderWidth: 2, borderRadius: 30
        }}>
            <View style={{
                width: '100%', height: '100%',
                flexDirection: 'column',
                borderColor: 'transparent', borderWidth: 2, borderRadius: 28,
                backgroundColor: 'trasparent'
            }}>
                {props.children}
            </View>
        </View>
    );
});

const aspectRatio = 1544 / 765;
const EmulatorRender = React.memo((props: {
    width: number, height: number,
    children?: any,
    setTheme: (theme: ThemeType) => void,
    theme: ThemeType
}) => {
    const width = Math.min(props.width, 600);
    const height = width * aspectRatio;
    const [isSmall, setIsSmall] = useState<boolean>(false);
    useEffect(() => {
        let frameWidth = document.documentElement.scrollWidth
        console.log(frameWidth);
        if (frameWidth < 1920) {
            setIsSmall(true)
        }
    }, []);
    return (
        <View style={{ width, height }}>
            <View style={{
                width, height,
                transform: [
                    { translateX: -width / 2 },
                    { translateY: -height / 2 },
                    { scale: height / 1544 },
                    { translateX: width / 2 },
                    { translateY: height / 2 }
                ],
            }}>
                <EmulatorBody>

                    {props.children}
                    <div className="emulator-themeChooser" style={{
                        position: 'absolute',
                        left: `${isSmall ? '44.45%' : '46.2%'}`,
                        bottom: -80,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        <View style={{ transform: [{ scaleX: 2.4 }, { scaleY: 2.4 }], }}>
                            <Switch
                                onChange={(value: boolean) => {
                                    trackEvent(
                                        MixpanelEvent.SimulatorTheme,
                                        { theme: value ? 'dark' : 'light' }
                                    );
                                    if (value) {
                                        props.setTheme(ThemeType.Dark)
                                        return;
                                    }
                                    props.setTheme(ThemeType.Light)
                                }}
                                checked={props.theme === ThemeType.Dark}
                                onColor={'#000'}
                                uncheckedIcon={
                                    <View style={{
                                        height: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <IconElement
                                            collection={'ionicons'}
                                            name={'moon'}
                                            color={'#ddd'}
                                            size={20}
                                        />
                                    </View>
                                }
                                checkedIcon={
                                    <View style={{
                                        height: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <IconElement
                                            collection={'ionicons'}
                                            name={'sunny'}
                                            color={'#ddd'}
                                            size={20}
                                        />
                                    </View>
                                }
                                checkedHandleIcon={
                                    <View style={{
                                        height: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <IconElement
                                            collection={'ionicons'}
                                            name={'moon'}
                                            color={'#000'}
                                            size={20}
                                        />
                                    </View>
                                }
                                uncheckedHandleIcon={
                                    <View style={{
                                        height: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <IconElement
                                            collection={'ionicons'}
                                            name={'sunny'}
                                            color={'#ffc107'}
                                            size={20}
                                        />
                                    </View>
                                }
                            />
                        </View>
                    </div>
                </EmulatorBody>
            </View>
        </View>
    );
});

export interface EmulatorContextInterface {
    fontSize: number
    maxHeight: number
    maxImgHeight: number
    clockMargin: number
    indicatorMargin: number
    ExploreTitle: number
    openedScreen: number
    imgSizes: number
    explorePadding: number
    pageWidth: number
    maxWidth: number
    emulatorMaxWidth: number
    openedToolbar: number
    openedHeight: number
    smartBannerMargin: number
    smartBannerScreenshot: number
    infoWidth: number
    listWidth: number
    theme: ThemeType
    openedMargin: number
    toolbarIndicator: number
    isSmall: boolean,
    bannerHeight: number
}
export const EmulatorContext = createContext<EmulatorContextInterface>(undefined as any);

export function useEmulatorConfig(): EmulatorContextInterface {
    return useContext(EmulatorContext);
}

export const ScalableEmulatorComponent = React.memo((props: { children?: any }) => {
    const [layout, setLayout] = React.useState<{ width: number, height: number } | null>(null);
    const [fontSize, setFontSize] = useState<number>(30);
    const [maxHeight, setMaxHeight] = useState<number>(380);
    const [maxWidth, setMaxWidth] = useState<number>(243);
    const [maxImgHeight, setMaxImgHeight] = useState<number>(220);
    const [clockMargin, setClockMargin] = useState<number>(18);
    const [indicatorMargin, setIndicatorMargin] = useState<number>(-1);
    const [ExploreTitle, setExploreTitle] = useState<number>(32);
    const [openedScreen, setOpenedScreen] = useState<number>(505);
    const [imgSizes, setImgSizes] = useState<number>(100);
    const [explorePadding, setExplorePadding] = useState<number>(10);
    const [pageWidth, setPageWidth] = useState<number>(570);
    const [emulatorMaxWidth, setemulatorMaxWidth] = useState<number>(350);
    const [openedToolbar, setOpenedToolbar] = useState<number>(505);
    const [openedHeight, setOpenedHeight] = useState<number>(945);
    const [smartBannerMargin, setSmartBannerMargin] = useState<number>(10);
    const [smartBannerScreenshot, setSmartBannerScreenshot] = useState<number>(1110);
    const [infoWidth, setInfoWidth] = useState<number>(205);
    const [listWidth, setListWidth] = useState<number>(518);
    const [openedMargin, setOpenedMargin] = useState<number>(25);
    const [toolbarIndicator, setToolbarIndicator] = useState<number>(125);
    const [bannerHeight, setBannerHeight] = useState<number>(110);
    const [isSmall, setIsSmall] = useState<boolean>(true);
    const theme = useRecoilValue(themeStateAtom);
    const setTheme = useSetRecoilState(themeStateAtom);
    console.log(theme);

    const onLayout = React.useCallback((event: LayoutChangeEvent) => {
        setLayout({ width: event.nativeEvent.layout.width, height: event.nativeEvent.layout.height })
        let realWidth = document.documentElement.scrollWidth
        if (realWidth > 1920) {
            setFontSize(30)
            setMaxHeight(480)

            setMaxWidth(325)

            setMaxImgHeight(304)
            setClockMargin(40)
            setIndicatorMargin(3)
            setExploreTitle(38)
            setOpenedScreen(673)
            setImgSizes(120)
            // setExplorePadding(15)
            setExplorePadding(17)
            setPageWidth(730)
            setemulatorMaxWidth(450)
            setOpenedToolbar(673)
            setOpenedHeight(1250)
            setSmartBannerMargin(20)
            setSmartBannerScreenshot(1440)
            setInfoWidth(317)
            setListWidth(689)
            setOpenedMargin(34)
            setToolbarIndicator(100)
            setIsSmall(!isSmall)
            setBannerHeight(150)
        }
    }, []);
    const size = React.useMemo(() => {
        if (layout) {
            return { width: layout.width, height: layout.height };
        } else {
            return null;
        }
    }, [layout?.width, layout?.height]);

    return (
        <View
            style={{
                width: '100%',
                maxWidth: `${emulatorMaxWidth}px`,
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onLayout={onLayout}
        >
            <EmulatorContext.Provider value={{
                fontSize, maxHeight, maxImgHeight,
                maxWidth, clockMargin, indicatorMargin,
                ExploreTitle, openedScreen, imgSizes,
                explorePadding, pageWidth, emulatorMaxWidth,
                openedToolbar, openedHeight, smartBannerMargin,
                smartBannerScreenshot, infoWidth,
                listWidth, openedMargin, toolbarIndicator, isSmall, bannerHeight, theme
            }}>
                {size && (
                    <EmulatorRender
                        width={size.width}
                        height={size.height}
                        theme={theme}
                        setTheme={setTheme}
                    >
                        {props.children}
                    </EmulatorRender>
                )}
            </EmulatorContext.Provider>
        </View>
    );
});