import React from 'react'
import { TabContent, useContentLoader } from '../pageContentUtils';
import { ContentView } from './ContentView';

type Props = {
    value: TabContent
}

export const AutoContentView = React.memo<Props>(({value}) => {
    const [desc, loading] = useContentLoader(value)
    return (
        <ContentView contentType={value.contentType} loading={loading} text={desc?.label} />
    )
})