import { Card, Layout, Page } from "@shopify/polaris"
import React from "react"

export const CartmatePointsInfo = React.memo(() => {

    return (
        <Page title={'What is Cartmate Point'}>
            <Layout>
                <Layout.Section>
                    <Card sectioned>
                        // TODO: make some sence about this, please
                        {'Cartmate point is a '}
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    )
})