import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TabContent } from '../pageContentUtils'
import {
    Labelled,
    LabelledProps,
    Popover,
    Button,
    Stack,
    Icon, TextStyle,
} from '@shopify/polaris';
import { useBooleanState } from '../../hooks/useBooleanState';
import { SearchField } from '../../SearchField/SearchField';
import { AutoContentView } from '../ContentView/AutoContentView';
import { View, TouchableOpacity } from 'react-native';
import { Item, ItemCategory, ItemTreeRoot, useContentTree } from './categoryDataTree';
import { ChevronLeftMinor } from '@shopify/polaris-icons';
import { CategoryView } from './CategoryView';
import { PagesNewModal } from "../../../routes/Pages/PageNewModal";

type Props = {
    value: TabContent
    onSelect: (value: TabContent) => void
} & LabelledProps

export const ContentPicker = React.memo<Props>(({ value, onSelect, ...labeledProps }) => {
    const [popoverActive, _, closePopover, togglePopover] = useBooleanState(false);
    const [searchValue, setSearchValue] = useState('')
    const [itemsMap, searchTree] = useContentTree(searchValue)
    const [selectedNode, setSelectedNode] = useState<ItemCategory | null>(null)
    const [addPageOpened, setAddPageOpened] = useState<boolean>(false);

    const closeAddPageModal = useCallback(() => {
        setAddPageOpened(false);
        setSearchValue(searchValue + '');
    }, []);

    const itemsToRender: ItemTreeRoot = selectedNode?.type === 'category' && selectedNode.options || searchTree
    const isRoot = searchTree === itemsToRender

    useEffect(() => setSelectedNode(null), [searchTree])

    const onBackCb = useCallback(() => setSelectedNode(null), []) // pizdeze

    const setSelected = useCallback((item: Item) => {
        console.log('Selected item', item);
        if (item.type === 'category' || item.type === 'paginated-category') {
            setSelectedNode(item)
        } else {
            const value = itemsMap.get(item.hash)
            if (value) {
                onSelect(value)
                closePopover()
                setSearchValue('')
            }
        }
    }, [itemsMap])


    const activator = useMemo(() => {
        return <Labelled {...labeledProps}>
            <Button
                fullWidth
                onClick={togglePopover}
                textAlign={'left'}
                icon={<AutoContentView value={value} />} />
        </Labelled>
    }, [value])

    return (
        <View>
            <Popover
                active={popoverActive}
                activator={activator}
                onClose={closePopover}
                ariaHaspopup={false}
                fullWidth
                sectioned
            >
                <Popover.Pane fixed>
                    <Popover.Section>
                        <Stack>
                            <Stack.Item fill>
                                <SearchField
                                    onChange={setSearchValue}
                                    value={searchValue}
                                    placeholder="Search"
                                    showFocusBorder
                                />
                            </Stack.Item>
                            {selectedNode?.hash === "pages_category" && <Stack.Item><Button primary onClick={() => {
                                setAddPageOpened(true)
                            }}>Add page</Button></Stack.Item>}
                        </Stack>


                    </Popover.Section>
                    {!isRoot && <BackFragment onPress={onBackCb} count={itemsToRender.length} />}
                </Popover.Pane>
                <Popover.Pane>
                    <View>
                        <CategoryView items={itemsToRender} onClick={setSelected} showItemsCount={true} />
                    </View>
                </Popover.Pane>
            </Popover>
            <PagesNewModal open={addPageOpened} close={closeAddPageModal} />
        </View>
    )
})

const BackFragment = React.memo<{ count: number, onPress: () => void }>(({ count, onPress }) => (
    <Popover.Section>
        <TouchableOpacity onPress={onPress}>
            <Stack>
                <Stack.Item>
                    <Icon
                        source={ChevronLeftMinor} />
                </Stack.Item>
                <Stack.Item fill>
                    <TextStyle variation="strong">Back</TextStyle>
                </Stack.Item>
                <Stack.Item>
                    {count} result(s)
                </Stack.Item>
            </Stack>
        </TouchableOpacity>
    </Popover.Section>
))