import { Card, DisplayText, Popover, ProgressBar, Stack } from "@shopify/polaris";
import React from "react";

export const StatCard = React.memo((
    {
        popoverActive,
        setPopoverActive,
        title,
        value,
        popoverText
    }: {
        popoverActive: boolean,
        setPopoverActive: (newState: boolean) => void,
        title?: string,
        value?: string,
        popoverText?: string
    }
) => {

    return (
        <div
            className={"stat-card"}
            style={{
                background: '#fff'
            }}
        >
            <Stack vertical>
                <Stack.Item>
                    <Popover
                        active={popoverActive}
                        activator={
                            <button className={"stat-title"}
                                onMouseEnter={() => { setPopoverActive(true); }}
                                onMouseLeave={() => { setPopoverActive(false); }}
                            ><span className={"stat-header"}>{title}</span></button>
                        }
                        onClose={() => { setPopoverActive(false); }}
                    >
                        <Card sectioned>
                            {popoverText}
                        </Card>
                    </Popover>
                </Stack.Item>
                <Stack.Item>
                    <DisplayText size="large" element="p">
                        {value}
                    </DisplayText>
                </Stack.Item>
                <Stack.Item>
                    <ProgressBar progress={100} size="small" color='success' />
                </Stack.Item>

            </Stack>
        </div>
    );
});