import { Icon, Stack } from "@shopify/polaris";
import { PauseMajor } from "@shopify/polaris-icons";
import React from "react";
import { IconWrapper } from "../../components/Icon/IconView/Icons";
import {
    ContentView,
} from '../../content/components';
import { TemplateWithVariables } from "../knobs/TemplateWithVariables";
import { FontWeightType, TextAlignType } from "../knobs/types";
import { ConfigBlock } from "./BlockTypes";

const BannerVariables = {
    color: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: 'Caption sample text',
            textAlign: TextAlignType.Start,
            textColor: '#000',
            textShadow: false,
            showCaption: true,
            fontWeight: FontWeightType.Normal
        },
        description: 'Divider color',
        disableAlpha: true,
        noValue: true,
        noTextAlign: true,
        noFontWeight: true,
        switchsble: false
    },
    height: {
        type: 'range' as const,
        defaultValue: 2,
        description: 'Height',
        min: 2,
        max: 10,
        step: 1,
        disableAlpha: true,
    },
    borderRadius: {
        type: 'range' as const,
        defaultValue: 0,
        description: 'Border radius',
        min: 0,
        max: 5,
        step: 1,
        disableAlpha: true,
    },
    width: {
        type: 'range' as const,
        defaultValue: 100,
        description: 'Divider width',
        min: 2,
        max: 100,
        step: 1,
        disableAlpha: true,
        isAdvanced: true
    },
}

const Divider = TemplateWithVariables(BannerVariables, ({
    height, color, borderRadius, width
}) => {
    return (
        <ContentView style={{
            marginLeft: 10,
            marginRight: 10,
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <ContentView
                style={{
                    height: height,
                    backgroundColor: color.textColor,
                    marginBottom: 20,
                    marginTop: 20,
                    borderRadius: borderRadius,
                    width: `${width}%`
                }}
            >
            </ContentView>
        </ContentView>
    )
});

export default {
    Preview: () => <Stack alignment="center" distribution="leading" spacing={'loose'}>
        <Stack.Item fill={false}>
            <IconWrapper>
                <Icon source={PauseMajor} />
            </IconWrapper>
        </Stack.Item>
        <Stack.Item fill>
            Divider
        </Stack.Item>
    </Stack>,
    RenderBlock: Divider,
    Icon: PauseMajor,
    Name: 'Divider'
} as ConfigBlock