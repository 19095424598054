import { Tooltip } from "@shopify/polaris";
import React, { useEffect } from "react";
import { View, Text } from "react-native";
import { useParams } from "react-router";
import { useClient } from "../../../api/useClient";
import { emptyCartmateIcon } from "../../../assets/helpers";
import { useEmulatorConfig } from "../../../content/emulator/components/ScalableEmulatorComponent";
import { AppEmulatorConfig } from "../../../content/emulator/types";
import { Screen } from "../../../api/spacex.types";
import { ProductsPreview } from "./ProductsPreview";

export const ProductsByCollectionsPreview = React.memo(() => {
    const { id: appId } = useParams<{ id: string }>();
    const client = useClient();
    const appConfig = client.useAppEmulatorConfig({ id: appId }).app.draftConfig as AppEmulatorConfig;
    const emulator = useEmulatorConfig();
    const colors = appConfig.design.colors[emulator.theme];
    const screenSettings = client.useScreenSettings({ screen: Screen.PRODUCTS_BY_COLLECTIONS }).screenSettings;
    const collectionsIds = JSON.parse(screenSettings.settings).collections as string[];
    let collectionsRes = client.useCollections();
    let collections = collectionsRes.collections;
    if (collectionsIds && collectionsIds.length > 0) {
        console.log('[ProductsByCollectionsPreview] collectionsIds', collectionsIds);
        collections = [];
        collectionsIds.forEach((id) => {
            const item = collectionsRes.collections.find((c) => c?.id === id);
            if (item) {
                collections.push(item);
            }
        });
        console.log('[ProductsByCollectionsPreview] collections', collections.map((c) => c?.id));
    }

    return (
        <div className="ios__content-items" style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px 0 20px 0',
            flexWrap: 'wrap',
            width: '100%',
            position: 'relative'
        }}>
            <div
                className={"ios__categories-list"}
                style={{
                    position: "sticky",
                    top: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.75)',
                    backdropFilter: 'blur(10px)',
                    marginBottom: `${emulator.isSmall ? '0px' : '10px'}`,
                    overflow: 'hidden',
                    paddingBottom: '70px',
                    height: '4px',
                    zIndex: 10
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: '40px',
                        overflowY: "hidden",
                        overflowX: "auto",
                        paddingBottom: "100%",
                        marginBottom: "-20px",
                    }}
                >
                    {collections.length > 0 && (
                        <div
                            className="ios__content-tags-list"
                            style={{
                                display: 'flex',
                                padding: '14px 0 30px 6px',
                                alignItems: "center"
                            }}
                        >
                            {
                                collections.map((collection, index) => {
                                    return (
                                        <div
                                            key={`${collection?.id}-${index}`}
                                            className="ios__content-tag"
                                            style={{
                                                marginLeft: '14px',
                                                padding: '6px 20px',
                                                backgroundColor: index === 0
                                                    ? colors.accent
                                                    : colors.toolBarBackground,
                                                borderRadius: '30px',
                                                fontSize: `${emulator.isSmall ? '25px' : '28px'}`,
                                                lineHeight: '30px',
                                                fontWeight: 600,
                                                color: index === 0
                                                    ? colors.accentText
                                                    : colors.toolBarText,
                                                textTransform: 'uppercase',
                                                height: '42px',
                                                overflowY: 'hidden',
                                                whiteSpace: "nowrap",
                                                flexGrow: 0,
                                                flexShrink: 0
                                            }}
                                        >
                                            <span>{collection?.title}</span>
                                        </div>
                                    );
                                })
                            }
                        </div>)}
                </div>
            </div>
            {collections.map((collection, index) => {
                if (collection) {
                    return <ProductsPreview key={`products-${index}`} collection={collection} appId={appId} />
                }
            })}
        </div>
    );
});