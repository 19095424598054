import { Button, Checkbox, Form, FormLayout, Stack, TextField, Toast } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { AccountContactType, Contacts_contacts, Contacts_contacts_data_socials } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { CleanSpaceX } from "../../../components/types";
import { SocialsInput } from "./SocialsInput";

export const TrafficExchangeContactForm = React.memo((
    {
        contact
    }: {
        contact?: Contacts_contacts
    }) => {
    const client = useClient();
    console.log('[TrafficExchangeContactForm', contact);

    const initialSocials = contact?.data.socials?.map((s) => { return { socialName: s.socialName, socialId: s.socialId }; });

    const [name, setName] = useState(contact?.data.name);
    const [email, setEmail] = useState(contact?.data.email);
    const [phone, setPhone] = useState(contact?.data.phoneNumber);
    const [socials, setSocials] = useState<CleanSpaceX<Contacts_contacts_data_socials>[] | null | undefined>(initialSocials);
    const [visible, setVisible] = useState(contact?.data.visible);
    const [isDirty, setIsDirty] = useState(false);
    const [showSaveToast, setShowSaveToast] = useState(false);

    useEffect(() => {
        if (
            name !== contact?.data.name
            || email !== contact?.data.email
            || phone !== contact?.data.phoneNumber
            || socials != initialSocials
            || visible != contact?.data.visible
        ) {
            setIsDirty(true);
        }
    }, [name, email, phone, socials, visible, contact]);

    const saveToast = showSaveToast ? (
        <Toast content="Saved!" onDismiss={() => setShowSaveToast(false)} />
    ) : null;

    const submit = useCallback(
        () => {
            const input = {
                name: name,
                email: email,
                phoneNumber: phone,
                socials: socials,
                visible: visible
            };
            console.log('[TrafficExchangeContactForm] input', input);
            client.mutateCreateOrUpdateContact({
                type: AccountContactType.TrafficExchange,
                input: input,
                id: contact ? contact?.id : undefined
            }).then(() => {
                setShowSaveToast(true);
                setIsDirty(false);
                client.refetchContacts();
            })
        },
        [name, email, phone, client, socials, visible, contact, setIsDirty, setShowSaveToast],
    );

    return (
        <Form onSubmit={submit}>
            <FormLayout>
                <Stack vertical>
                    <Stack.Item>
                        <TextField
                            id={'name-input'}
                            placeholder={''}
                            label={'Contact name'}
                            error={''}
                            onChange={setName}
                            type="text"
                            value={name ? name : ''}
                        />
                    </Stack.Item>
                    <Stack distribution={'fill'}>
                        <Stack.Item>
                            <TextField
                                id={'email-input'}
                                placeholder={''}
                                label={'Email'}
                                error={''}
                                onChange={setEmail}
                                type={'email'}
                                inputMode={'email'}
                                value={email ? email : ''}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField
                                id={'phone-input'}
                                placeholder={''}
                                label={'Phone'}
                                error={''}
                                onChange={setPhone}
                                type={'tel'}
                                inputMode={'tel'}
                                value={phone ? phone : ''}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack.Item>
                        <SocialsInput value={socials} onChange={setSocials} />
                    </Stack.Item>
                    <Stack.Item>
                        <Checkbox
                            label="Show this contact in community"
                            checked={visible ? visible : false}
                            onChange={setVisible}
                        />
                    </Stack.Item>
                </Stack>
                <Stack distribution={"trailing"}>
                    <Button primary={isDirty} disabled={!isDirty} submit>
                        {'Save'}
                    </Button>
                </Stack>
            </FormLayout>
            {saveToast}
        </Form>
    );
});