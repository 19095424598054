import { LinearGradientProps } from 'expo-linear-gradient';
import { ScrollViewProps, StyleProp, ViewStyle } from 'react-native';
import { TextProps, ViewProps, ImageProps, Insets, PressableProps } from 'react-native';
import { createOutputRenderer } from './elements/RenderOutput';
import { YouTubeProps } from 'react-native-youtube';
import { BlurhashProps } from 'react-native-blurhash';
import { BlurProps } from 'expo-blur';
import { FontWeightType, ResizeMode, TextAlignType, ViewType } from '../configEditor/knobs/types';
import { Knob } from '../configEditor/knobs/Knob';

export type ProductProps = {
    id: string,
    viewType?: ViewType | string,
    caption?: string,
    textColor?: string,
    textAlign?: TextAlignType,
    fontWeight?: FontWeightType,
    showCaption?: boolean,
    showTextBackground?: boolean,
    placeholderImage?: Knob.BlurhashImage,
    forcePlaceholder?: boolean,
    imageResizeMode: ResizeMode,
    blurIntencity: number,
    showPrice?: boolean,
    style?: StyleProp<ViewStyle>
}

export type TimerProps = {
    size: number,
    labelColor: string,
    digitColor: string,
    digitFontWeight: FontWeightType,
    until: Date,
    url?: string,
    timeToShow: string,
    titleValue: string,
    showBorder: boolean,
    borderColor: string,
    titleStyle: { color: string, textAlign: TextAlignType, fontWeight: FontWeightType },
    showCaption: boolean,
    style?: StyleProp<ViewStyle>
} & ViewProps

export type HtmlMarkupProps = {
    text: string
} & ViewProps;

export type SliderProps = {
    listOfSlides: Knob.Slide[] | null,
    autoplay: {
        enabled: boolean,
        interval: number
    }
    borderRadius: number,
    height: number,
    current?: number | null
} & ViewProps;

export type CollectionProps = {} & ProductProps

export const ContentSlider = createOutputRenderer<SliderProps>('content-slider');
export const ContentTimer = createOutputRenderer<TimerProps>('content-timer');
export const ContentText = createOutputRenderer<TextProps & { smartColor?: boolean }>('text');
export const ContentView = createOutputRenderer<ViewProps>('view');
export const ContentBlurView = createOutputRenderer<BlurProps>('blur-view');
export const ContentImage = createOutputRenderer<ImageProps>('image');
export const ContentProduct = createOutputRenderer<ProductProps>('content-product');
export const ContentCollection = createOutputRenderer<CollectionProps>('content-collection');
export const ContentBlurhash = createOutputRenderer<BlurhashProps>('blurhash');
export const ContentScrollView = createOutputRenderer<ScrollViewProps>('scroll-view');
export const ContentYouTubeView = createOutputRenderer<YouTubeProps & { apiKey: string }>('youtube-view');
export const ContentPressableNavigator = createOutputRenderer<{ route: string, borderRadius?: number }>('pressable-navigator');
export const ContentMenuItem = createOutputRenderer<{ url: string, text: string, textColor: string, icon?: string, iconCollection?: string }>('menu-item');
export const ContentTouchableOpacity = createOutputRenderer<{ url: string, activeOpacity?: number, hitSlop?: Insets }>('touchable-opacity');
export const ContentTouchableNoFeedback = createOutputRenderer<{ url: string, hitSlop?: Insets }>('touchable-no-feedback');
export const ContentLinearGradient = createOutputRenderer<LinearGradientProps>('gradient-linear');
export const ContentIcon = createOutputRenderer<{ collection: 'material' | 'ionicons' | 'feather', name: string, size?: number, color?: string }>('icon');
export const ContentProductsByCollection = createOutputRenderer<ViewProps>('products-by-collections');
export const ContentHtmlMarkup = createOutputRenderer<HtmlMarkupProps>('html-markup');