import React, { CSSProperties, useState } from "react";
import { Text, View } from "react-native";
import Color from "color";
import { AppEmulatorConfig } from "../types";
import { useEmulatorConfig } from "./ScalableEmulatorComponent";
import { useClient } from "../../../api/useClient";
import { useParams } from "react-router";
import { useAppCard } from "../../../configure/utils/useAppCard";
import { AppCard } from "../../../components/types";
import { ThemeType } from "../States";
import { Link, Tooltip } from "@shopify/polaris";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { useAppBridge } from "@shopify/app-bridge-react";

const defaultLogo = require('../../../assets/images/defaultBannerLogo.png');

export const IOSTitlebar = React.memo((
    {
        config,
    }: {
        config: AppEmulatorConfig,
    }
) => {




    const emulator = useEmulatorConfig();
    const appBridge = useAppBridge();

    const params = useParams<{ id: string, appId: string }>();
    const client = useClient();
    const { id: appId } = useParams<{ id: string }>();

    const { id } = useParams<{ id: string }>();

    const appConfig = client.useAppConfig({ id: params.id }).app;
    const colors = config.design.colors[emulator.theme];

    // const name = client.useApp({ id }).app;
    const name = client.useApp({ id }).app;

    const [initialAppCard, initialPublishedCard] = useAppCard(appId);
    const [appCard, setAppCard] = useState<AppCard>(initialAppCard);
    const [title, setTitle] = React.useState<string>(appCard.title || '');



    const toolbarSideStyle: CSSProperties = {
        width: '25%',
        backgroundColor: colors.toolBarBackground,
        paddingTop: '20px',
        paddingBottom: '20px',
        paddingLeft: '24px',
        paddingRight: '36px',
    };

    return (
        <div
            className={"ios__titlebar"}
            style={{
                width: "100%",
                paddingTop: '20px',
                display: "flex",
                alignItems: 'center',
                marginBottom: '-1px',
                marginTop: '-1px',
                paddingBottom: '1px',
                backgroundColor: colors.toolBarBackground,
            }}
        >
            <Tooltip content='You can set your own in-app shop logo via "Shop logo" field on "Branding" tab. This logo will be used as default in App Promotion Banner and in Traffic Exchange Program'>
                <div className="ios-shop-logo" style={{
                    marginLeft: '20px',
                    // width: '65px',
                    width: `${emulator.isSmall ? '60px' : '65px'}`,
                    height: `${emulator.isSmall ? '60px' : '65px'}`,
                    borderRadius: '50%',
                    // backgroundImage: `url(${appConfig.settings?.logo})`,
                    backgroundColor: '#fff',
                    // backgroundImage: `url(${appConfig.settings?.logo})`,
                    backgroundImage: `url(${appConfig.settings?.logo == null || appConfig.settings?.logo.length == 0 ? defaultLogo : appConfig.settings?.logo})`,
                    backgroundPositionX: '50%',
                    backgroundPositionY: '50%',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    marginRight: '20px',
                    marginBottom: '1px',
                    border: `2px solid ${emulator.theme === ThemeType.Light ? '#000' : 'transparent'}`
                }}>
                </div>
            </Tooltip>
            <Tooltip content='The default title of your in-app shop is your Shopify store name. You can change this and customize it by visiting the “Traffic Exchange” page. '>
                <div className="ios-shop-name" style={{
                    // color: '#fff',
                    color: Color(colors.toolBarBackground).isDark() ? "#fff" : "#000",
                    fontSize: '32px',
                    fontWeight: 600,
                    marginBottom: '1px',
                    // width: '70%'
                    width: '100%'
                }}>
                    <Text
                        ellipsizeMode={'tail'}
                        numberOfLines={1}
                        style={{
                            fontSize: emulator.isSmall ? 28 : 32,
                            fontWeight: '600',
                            color: Color(colors.toolBarBackground).isDark() ? "#fff" : "#000",
                        }}
                    >
                        {`${title === null ? `${name.name}` : title}`}
                    </Text>
                    {/* {`${name.name}`} */}
                </div>
            </Tooltip>
        </div>
    );
});