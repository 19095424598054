import React from "react"
import { ContentView, CollectionProps, ContentCollection } from "../../../../content/components"
import { ResizeMode } from "../../../knobs/types";

export const CollectionCircleListItem = React.memo((
    props: {
        viewsHeight: number,
        showShadow?: boolean,
        showTextBackground: boolean,
        viewsMargins: number,
    } & CollectionProps
) => {

    return (
        <ContentView
            style={{
                height: props.viewsHeight,
                width: props.viewsHeight,
                borderRadius: props.viewsHeight / 2,
                marginRight: props.viewsMargins ? props.viewsMargins : 4,
                shadowOpacity: props.showShadow ? 0.5 : undefined,
                shadowRadius: props.showShadow ? 6 : undefined,
                overflow: 'hidden'
            }}
        >
            <ContentCollection
                id={props.id}
                caption={props.caption}
                textColor={props.textColor}
                textAlign={props.textAlign}
                showCaption={props.showCaption}
                placeholderImage={props.placeholderImage}
                showTextBackground={props.showTextBackground}
                fontWeight={props.fontWeight}
                viewType={props.viewType}
                imageResizeMode={props.imageResizeMode as ResizeMode}
                blurIntencity={props.blurIntencity}
            />
        </ContentView>
    )
})