export enum Capability {
    Theme = 'theme',
    Branding= 'branding',
    MainMenu = 'mainMenu',
    MoreMenu = 'moreMenu',
    PromotionBanner = 'promotionBanner',
    PushNotifications = 'pushNotifications',
    PushNotificationsImage = 'pushNotificationsImage',
    PushNotificationsScheduled = 'pushNotificationsScheduled',
    ProBadge = 'proBadge',
}