import React, { useCallback, useEffect, useState } from 'react'
import { ViewConfig } from '../ConfigEditor';
import { rerenderBlock } from '../subRender/subRenderUtils';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Knob } from './Knob';
import { KnobString } from './components/KnobString';
import { KnobNumber } from './components/KnobNumber';
import { Button, Collapsible, Heading, Stack, TextField } from '@shopify/polaris';
import { KnobColor } from './components/KnobColor';
import { KnobFile } from './components/KnobFile';
import { ContentViewer } from '../../content/ContentViewer';
import { KnobCollection } from './components/KnobCollection';
import { KnobProduct } from './components/KnobProduct';
import { KnobCollections } from './components/KnobCollections';
import { KnobViewType } from './components/KnobViewType';
import { KnobBoolean } from './components/KnobBoolean';
import { KnobNavigation } from './components/KnobNavigation';
import { KnobTab } from './components/KnobTab';
import { KnobShop, KnobWeb } from './components/KnobWeb';
import { KnobRange } from './components/KnobRange';
import { KnobProducts } from './components/KnobProducts';
import { KnobTextPosition } from './components/KnobTextPosition';
import { useClient } from '../../api/useClient';
import { useParams } from 'react-router';
import { KnobBlurhashImage } from './components/KnobBlurhashImage';
import { KnobAdvancedText } from './components/KnobAdvancedText';
import { KnobCoordinates } from './components/KnobСoordinates';
import { KnobTitleProps } from './components/KnobTitleProps';
import { KnobProductPicker } from './components/KnobProductPicker';
import { KnobCollectionPicker } from './components/KnobCollectionPicker';
import { KnobNavigationPicker } from './components/KnobNavigationPicker';
import { KnobBorder } from './components/KnobBorder';
import { KnobResizeMode } from './components/KnobResizeMode';
import { KnobPagePicker } from './components/KnobPagePicker';
import { KnobDateTimePicker } from './components/KnobDateTimePicker';
import { KnobMultiProductPicker } from './components/KnobMultiProductPicker';
import { KnobMultiCollectionPicker } from './components/KnobMultiCollectionPicker';
import { KnobSelect } from './components/KnobSelect';
import { KnobHtmlMarkup } from './components/KnobHtmlMarkup';
import { allBlocks } from '../blocks/allBloks';
import { KnobSlider } from './components/KnobSlider';
import { KnobSwitchableRange } from './components/KnobSwitchableRange';

const style = StyleSheet.create({
    container: {
        padding: 20,
        flexDirection: 'column',
        height: '100vh',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingBottom: 20,
    },
})

type Props = {
    node: ViewConfig.NodeBlockExtended
    onUpdate(newValue: ViewConfig.NodeBlockExtended): void
    onCancel(): void
}

const Components: Record<Knob.Types, Knob.KnobComponent> = {
    string: KnobString,
    advanced_text: KnobAdvancedText,
    number: KnobNumber,
    color: KnobColor,
    file: KnobFile,
    collection: KnobCollection,
    product: KnobProduct,
    collections: KnobCollections,
    view_type: KnobViewType,
    boolean: KnobBoolean,
    navigation: KnobNavigation,
    tab: KnobTab,
    web: KnobWeb,
    range: KnobRange,
    products: KnobProducts,
    text_position: KnobTextPosition,
    blurhash_file: KnobBlurhashImage,
    shop: KnobShop,
    coordinates: KnobCoordinates,
    title_props: KnobTitleProps,
    product_picker: KnobProductPicker,
    collection_picker: KnobCollectionPicker,
    page_picker: KnobPagePicker,
    navigation_picker: KnobNavigationPicker,
    border: KnobBorder,
    resize_mode: KnobResizeMode,
    date_time: KnobDateTimePicker,
    multi_product_picker: KnobMultiProductPicker,
    multi_collection_picker: KnobMultiCollectionPicker,
    select: KnobSelect,
    html_markup: KnobHtmlMarkup,
    slider: KnobSlider,
    switchable_range: KnobSwitchableRange
}

export const KnobsView = React.memo<Props>(({ node, onUpdate, onCancel }) => {
    const client = useClient();
    const { id: appId } = useParams<{ id: string }>();
    const background = client.useApp({ id: appId }).app.draftConfig.design.colors.light.background;
    const component = allBlocks.get(node.originComponentKey);

    const [title, setTitle] = useState(
        (!node?.title || node.title.length === 0)
            ? component?.Name || ''
            : node.title
    );

    console.log('[KnobsView] onKnobChange node.knobs', node.knobs);
    const onKnobChange = useCallback((knobValue: any, key: string) => {
        console.log('[KnobsView] onKnobChange', knobValue, key);
        const newNode = rerenderBlock({
            ...node,
            knobs: {
                ...node.knobs,
                [key]: knobValue,
            },
        });
        console.log('[KnobsView] onKnobChange newNode', node.knobs, newNode.knobs);
        onUpdate(newNode)
    }, [node, onUpdate])

    const onTitleChange = useCallback((value) => {
        setTitle(value)
        const newNode = rerenderBlock({
            ...node,
            ...{ title: value },
        });
        onUpdate(newNode)
    }, [setTitle, title, onUpdate,])

    useEffect(() => {
        console.log('NEW TITLE ', node.title, title);
    }, [title, onTitleChange]);

    const { descr } = node
    if (!descr) {
        return null
    }

    const [showAdv, setShowAdv] = useState(false);

    const toggleAdvanced = useCallback(
        () => setShowAdv((showAdv) => !showAdv),
        [],
    );

    const activator = (
        <Button plain fullWidth onClick={toggleAdvanced} disclosure={(!showAdv) ? 'down' : 'up'}>
            {showAdv ? 'Hide Advanced' : 'Advanced'}
        </Button>
    );

    const advancedKnobs: (JSX.Element | null)[] = [];

    const knobs = Object.keys(descr).map(key => {
        const knob = descr[key];
        const Component = Components[knob.type]

        if (knob.isAdvanced) {
            advancedKnobs.push(
                <Stack.Item key={key}>
                    <Component
                        value={node.knobs[key]}
                        innerKey={key}
                        knob={knob}
                        onChange={onKnobChange}
                    />
                    <hr style={{
                        borderTop: '1px solid #ddd',
                        borderRadius: 4,
                        marginTop: 8,
                    }} />
                </Stack.Item>
            );
            return null;
        }

        if (!Component) {
            return null
        } else {
            return (
                <Stack.Item key={key}>
                    <Component
                        value={node.knobs[key]}
                        innerKey={key}
                        knob={knob}
                        onChange={onKnobChange}
                    />
                    <hr style={{
                        borderTop: '1px solid #ddd',
                        borderRadius: 4,
                        marginTop: 8,
                    }} />
                </Stack.Item>
            );
        }
    });

    const [width, setWidth] = useState<number>(window.innerWidth);
    const handleWindowSizeChange = useCallback(
        () => {
            setWidth(window.innerWidth);
        },
        [],
    );

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);



    return <>
        <View style={{ paddingLeft: 16, paddingTop: 16 }}>
            <Stack>
                <TextField label="Block label"
                    value={title}
                    onChange={onTitleChange}
                    autoComplete="off" />
            </Stack>
        </View>
        <hr style={{ margin: '10px 16px 0' }} />
        <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1, paddingRight: 16, paddingLeft: 16, paddingBottom: 16, paddingTop: 8 }}>
                <Stack vertical>
                    {knobs}
                    {advancedKnobs.length > 0 && activator}
                    <Collapsible
                        id={'advanced-knobs'}
                        open={showAdv}
                        transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                    >
                        <Stack vertical spacing={'loose'}>
                            {advancedKnobs}
                        </Stack>
                    </Collapsible>
                </Stack>
            </View>
            {(width > 768) && (
                <div style={{ flex: 1, padding: 16, height: '100%', position: 'sticky', top: 0 }}>
                    <Heading>
                        {'Preview'}
                    </Heading>
                    <View style={{
                        // alignSelf: 'center',
                        alignSelf: 'flex-start',
                        elevation: 1,
                        backgroundColor: background,
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 16,
                        borderRadius: 16,
                        borderWidth: 1,
                        borderColor: '#DDD',
                    }}>
                        <ScrollView style={{ width: 400 }}>
                            <ContentViewer src={node.modernRender} width={400} />
                        </ScrollView>
                    </View>
                </div>
            )}
        </View>
    </>
})