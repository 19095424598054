import { Caption, FormLayout, Labelled, Stack, Subheading, Tag, TextStyle } from "@shopify/polaris";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useClient } from "../../../api/useClient";
import { diff } from "../../../utils/intersection";
import { Knob } from "../Knob";
import { KnobCollectionPicker } from "./KnobCollectionPicker";

type ItemTag = { label: string, id: string };
type InnerKnobValue = Knob.PickedCollection;


export const KnobMultiCollectionPicker = React.memo<Knob.KnobComponentProps<Knob.KnobMultiCollectionPickerDescriptor>>((
    { knob, innerKey, value: initialValue, onChange, disabled, dropZoneProps }
) => {
    const client = useClient();

    const [value, setValue] = useState<Knob.PickedMultiCollection>(knob.defaultValue || []);

    const [tags, setTags] = useState<ItemTag[]>([]);
    const [knobValue, setKnobValue] = useState<InnerKnobValue>('');
    const [loading, setLoading] = useState<boolean>(false);

    const handleUpdateTags = useCallback((value: Knob.PickedMultiCollection) => {
        setLoading(true);
        Promise.all(
            value.map((item) => client.queryCollection({ id: item }))
        )
            .then((result) => {
                setLoading(false);
                setTags(
                    result.map((item) => {
                        return {
                            label: item.collection.title,
                            id: item.collection.id
                        };
                    })
                )
            })
            .catch((e) => {
                setLoading(false);
                console.warn(e);
            });
    }, [innerKey, client, setTags]);

    const uniqueItems = useMemo<boolean>(() => {
        return typeof knob.unique === 'undefined' ? true : knob.unique;
    }, [innerKey, knob]);

    const handleKnobChange = useCallback((newKnobValue: InnerKnobValue, knobKey: string) => {
        if (knob.maxItems && value.length >= knob.maxItems) {
            return;
        }
        if (uniqueItems && value.indexOf(newKnobValue) !== -1) {
            return;
        }
        setValue((prevValue) => {
            const newValue = [...prevValue, newKnobValue];
            onChange(newValue, innerKey);
            return newValue;
        });

    }, [innerKey, value, setValue, knob, uniqueItems]);

    useEffect(() => {
        if (value.length) {
            setKnobValue(value[value.length - 1]);
        }
        handleUpdateTags(value);
    }, [value, handleUpdateTags, setKnobValue]);

    useEffect(() => {
        setValue((prevValue) => {
            if (
                (initialValue.length !== prevValue.length || diff(initialValue, prevValue).length !== 0) &&
                (!knob.maxItems || initialValue.length <= knob.maxItems) // Check max length for val
            ) {
                return initialValue;
            }
            // console.log('Iinital value checks failed', initialValue, knob);            
            return prevValue;
        });
    }, [initialValue, innerKey, setValue, knob]);

    const handleClear = useCallback(() => {
        setValue((prevValue) => {
            onChange([], innerKey);
            return [];
        });
    }, [innerKey, onChange]);

    const handleRemoveTag = useCallback((tag: ItemTag, index: number) => {
        return () => {
            setValue((prevValue) => {
                const newValue = [...prevValue];
                newValue.splice(index, 1);
                onChange(newValue, innerKey);
                return newValue;
            });
        }
    }, [setValue, onChange, innerKey]);

    return (
        <FormLayout>
            <Labelled
                id={innerKey}
                label={(
                    <Stack distribution={"leading"} alignment={"center"} spacing={"extraTight"} >
                        <Stack.Item>{knob.description}</Stack.Item>
                        {
                            typeof knob.maxItems !== 'undefined' &&
                            <Stack.Item>
                                <Caption>
                                    <TextStyle variation={"subdued"}>{` (${value.length}/${knob.maxItems})`}</TextStyle>
                                </Caption>
                            </Stack.Item>
                        }
                    </Stack>
                )}
            >
                <KnobCollectionPicker
                    knob={{
                        type: 'collection_picker',
                        pageSize: knob.pageSize,
                        clerable: false,
                        description: '',
                        isAdvanced: knob.isAdvanced,
                    }}
                    innerKey={`${innerKey}-collection-picker`}
                    value={knobValue}
                    onChange={handleKnobChange}
                    disabled={knob.maxItems ? knob.maxItems <= value.length : disabled}
                    dropZoneProps={dropZoneProps}
                />
            </Labelled>
            <Stack distribution={'leading'} alignment={'center'} spacing={'loose'} wrap>
                {tags.map((tag, index) => {
                    return (
                        <Tag
                            key={`${innerKey}-tag-${tag.id}`}
                            onRemove={handleRemoveTag(tag, index)}
                        >
                            {tag.label}
                        </Tag>
                    );
                })}
            </Stack>

        </FormLayout>
    );
});