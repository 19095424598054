import Constants from 'expo-constants';

export function getEndpoint() {
    if (__DEV__) {
        if (process.env.EXPO_DEV_LOCAL) {
            return 'http://localhost:8080'
        } else {
            return 'https://api.cartmate.com'
        }
    } else {
        return Constants.manifest.extra.apiEndpoint as string;
    }
}