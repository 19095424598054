import { Icon, InlineError, Stack, TextField, TextStyle, Tooltip } from "@shopify/polaris";
import { CircleInformationMajor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useState } from "react";
import { useClient } from "../../../api/useClient";

const description = '';
const balanceError = 'Max Spendings exceeds your Cartmate Points availible';
const balanceEmpty = 'You dont have enough Cartmate Points';
const invalidMaxSpedings = 'Invalid Max spendings value';
const invalidBid = 'Invalid Bid';
const minBid = 1;

export type AdListingPointsSettings = {
    maxSpendings: number,
    bidPerClick: number,
}

export const AdListingPoints = React.memo((
    {
        value,
        onChange,
        error
    }: {
        value?: AdListingPointsSettings,
        onChange: (data?: AdListingPointsSettings) => void,
        error?: string
    }) => {
    const client = useClient();
    const walletBalance = client.useCoinStatus({ fetchPolicy: 'network-only' }).account.cartCoins;
    const [maxSpendings, setMaxSpendings] = useState<number>(minBid);
    const [clickBid, setClickBid] = useState<number>(minBid);
    const [innerError, setInnerError] = useState<string>();

    const onMaxSpedingsChange = useCallback(
        (value: number) => {
            setInnerError(undefined);
            setMaxSpendings(value);
        },
        [setMaxSpendings, setInnerError],
    );

    const onChangeBid = useCallback(
        (value: number) => {
            setInnerError(undefined);
            setClickBid(value);
        },
        [setClickBid, setInnerError],
    );

    useEffect(() => {
        if (!walletBalance) {
            setInnerError(balanceEmpty);
            return;
        }

        if (!maxSpendings) {
            setInnerError(invalidMaxSpedings);
        } else if (walletBalance < maxSpendings) {
            setInnerError(balanceError);
        }

        if (!clickBid || (maxSpendings && clickBid > maxSpendings) || (clickBid && clickBid < minBid)) {
            setInnerError(invalidBid);
        }

        console.log('[AdListingPoints] maxSpendings, clickBid, walletBalance', maxSpendings, clickBid, walletBalance);

        if (
            maxSpendings && clickBid
            && walletBalance > maxSpendings
            && clickBid >= minBid
            && clickBid < maxSpendings
        ) {
            console.log('[AdListingPoints] onChange', maxSpendings, clickBid);
            onChange({
                maxSpendings: maxSpendings,
                bidPerClick: clickBid
            });
        }

    }, [maxSpendings, clickBid, minBid, walletBalance, setInnerError, onChange]);

    return (
        <Stack vertical>
            <Stack.Item>
                <Stack>
                    <Stack.Item>
                        <label>Bid settings</label>
                    </Stack.Item>
                    <Stack.Item>
                        <Tooltip content={description}>
                            <Icon
                                source={CircleInformationMajor}
                                color='interactive'
                            />
                        </Tooltip>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <label>
                    <TextStyle variation={'subdued'}>
                        {`Your Cartmate Points balance: `}
                        <TextStyle variation={walletBalance || 0 > 0 ? 'positive' : 'subdued'}>
                            {`${walletBalance}`}
                        </TextStyle>
                    </TextStyle>
                </label>
            </Stack.Item>
            <Stack distribution={'fill'}>
                <Stack.Item>
                    <TextField
                        label={'Max spendings'}
                        type="number"
                        value={(maxSpendings || '0').toString()}
                        min={minBid}
                        max={walletBalance ? walletBalance : undefined}
                        step={1}
                        onChange={(value: string) => onMaxSpedingsChange(parseFloat(value))}
                    />
                </Stack.Item>
                <Stack.Item>
                    <TextField
                        label={'Bid per click'}
                        type="number"
                        value={(clickBid || '0').toString()}
                        min={minBid}
                        max={maxSpendings}
                        step={0.1}
                        onChange={(value: string) => onChangeBid(parseFloat(value))}
                    />
                </Stack.Item>
            </Stack>
            {error && <InlineError message={error} fieldID="points-settings" />}
            {innerError && <InlineError message={innerError} fieldID="points-settings" />}
        </Stack>
    );
});