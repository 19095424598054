import { Tooltip } from "@shopify/polaris";
import React, { PropsWithChildren, useCallback, useState } from "react";
import { useEmulatorConfig } from "./ScalableEmulatorComponent";

export interface IOSScreenContentProps {
    toggleNavigation?: boolean
}

export const IOSScreenContent = React.memo<PropsWithChildren<IOSScreenContentProps>>((
    { toggleNavigation, children }
) => {
    const emulator = useEmulatorConfig();
    const [scrollTop, setScrollTop] = useState<number>(0);
    // const handleScroll = useCallback((_event) => {
    //     if (_event.target) {
    //         const target = _event.target as HTMLDivElement;
    //         if (typeof target.scrollTop !== "number") return;
    //         console.log('dif', scrollTop, target.scrollTop, scrollTop - target.scrollTop);
    //         if (scrollTop - target.scrollTop < 0) {
    //             emulator.setNavigationVisible(target.scrollTop < 10);
    //         } else {
    //             emulator.setNavigationVisible(true);
    //         }
    //         setScrollTop(target.scrollTop);
    //     }
    // }, [emulator, setScrollTop, scrollTop]);
    return (
        <div
            className={"ios__content-page-wrapper"}
            style={{
                flexGrow: 1,
                flexShrink: 1,
                display:"flex",
                flexDirection:'column',
                overflow: "hidden",
                height: '100%',
                width: '100%'
            }}
        >
            <div
            id={'ios-scroll'}
                className={"ios__content-page"}
                style={{
                    flexGrow: 1,
                    flexShrink: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "hidden",
                    overflowY: "auto",
                    position: "relative",
                    paddingRight: '0px',
                    width: '100%',
                    marginRight: '100px',
                    scrollbarWidth:'none',
                    marginTop:'-2px'
                }}
            >
                <div
                    className={"ios__content-page-inner"}
                    style={{
                        flexGrow: 1,
                        flexShrink: 1,
                        display: "flex",
                        flexDirection: "column",
                        height: '100%',
                        width: '100%'
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
});