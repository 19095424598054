import { Stack } from "@shopify/polaris";
import React, { useState, useCallback, useEffect } from "react";
import { DatePicker } from "./DatePicker";
import { TimePicker, TimePickerValue } from "./TimePicker";

export const DateTimePicker = React.memo(({
    selected,
    onChange,
    disabled
}: {
    selected?: Date,
    onChange: (value: Date) => void,
    disabled?: boolean
}) => {
    const now = new Date();
    const [value, setValue] = useState<Date>(selected || now);

    const handleDateChange = useCallback((newValue: Date) => {
        setValue((prevValue) => {
            let newState = newValue;
            const now = new Date();
            newState.setHours(prevValue.getHours());
            newState.setMinutes(prevValue.getMinutes());
            onChange(newState);
            return newState;
        });
    }, [onChange]);

    const handleTimeChange = useCallback((newValue: TimePickerValue) => {
        setValue((prevValue) => {
            let newState = new Date(prevValue);
            newState.setHours(newValue.hours);
            newState.setMinutes(newValue.min);
            onChange(newState);
            return newState;
        });
    }, [onChange]);

    console.log('[KnobDateTimePicker] selected', selected);

    return (
        <Stack>
            <Stack.Item>
                <DatePicker
                    selected={value}
                    onChange={(value) => { handleDateChange(value) }}
                    disabled={disabled}
                />
            </Stack.Item>
            <Stack.Item>
                <TimePicker
                    selected={{ min: value.getMinutes(), hours: value.getHours() }}
                    onChange={handleTimeChange}
                    disabled={disabled}
                />
            </Stack.Item>
        </Stack>
    );
});