import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

//todo fix negative svg
function SvgComponent(props: SvgProps) {
    return (
        <Svg
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill="none"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 0zm13.701 5.713A1 1 0 1014.3 4.287l-6.417 6.31-3.18-3.128a1 1 0 00-1.403 1.426l3.882 3.818.701.69.702-.69 7.117-7z"
                fill="#FFFFFF"
            />
        </Svg>
    )
}

const MemoSvgComponent = React.memo(SvgComponent)
export default MemoSvgComponent