import { Provider, useAppBridge } from '@shopify/app-bridge-react';
import * as React from 'react';
import { View } from 'react-native';
import App from './App';
import Constants from 'expo-constants';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { backoff } from '@openland/patterns';
import { authenticateByOAuth, authenticateBySessionToken, authenticationUrl } from './auth/api';
import { AppProviders } from './AppProviders';
import Bugsnag from '@bugsnag/js';
import { isInIframe } from './utils/isInIFrame';
import qs from 'query-string';
import { useState } from 'react';
import { CreateAccountLoader } from './routes/CreateAccount/fragments/CreateAccountLoader';
import { DisplayText, Spinner } from '@shopify/polaris';
import { CartmateLoader } from './components/CartmateLoader';
import { trackEvent } from './utils/mixpanel';

// Resolve shop origin
function resolveShop(): string | null {
    // Try to load dev shop
    const devShop = Constants.manifest.extra.shopifyDevShop as { token: string, shop: string } | null;
    if (devShop) {
        console.warn('[boot]: Booting Development shop');
        return devShop.shop;
    }

    // Loading shop
    const urlSearchParams = new URLSearchParams(window.location.search);
    const shopOrigin = urlSearchParams.get('shop');
    if (!shopOrigin) {
        console.warn('[boot]: Unable to find shop');
        return null;
    }
    console.info('[boot]: Booting shop ' + shopOrigin);
    return shopOrigin;
}

function resolveToken(): string | null {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const shopToken = urlSearchParams.get('token');
    if (!shopToken) {
        console.warn('[boot]: Unable to find shop\'s token');
        return null;
    }
    return shopToken;
}

const scopes = [
    'write_orders',
    'read_products',
    'read_product_listings',
    'write_draft_orders',
    'read_script_tags',
    'read_fulfillments',
    'write_checkouts',
    'read_locales',
    'unauthenticated_write_checkouts',
    'unauthenticated_write_customers',
    'unauthenticated_read_customer_tags',
    'unauthenticated_read_content',
    'unauthenticated_read_product_tags',
    'unauthenticated_read_product_listings',
    'read_themes',
    'write_themes',
    'read_script_tags',
    'write_script_tags',
    'write_resource_feedbacks',
];

// Resolve redirect url
function resolveRedirectUrl(shop: string) {
    let online = false; // !!req.query['session'];
    const parameters = qs.stringify({
        'client_id': Constants.manifest.extra.shopifyKey,
        'scope': scopes.join(','),
        'redirect_uri': 'https://' + window.location.hostname + '/',
        'grant_options[]': online ? 'per-user' : '',
        'state': 'nonce'
    });
    const authUrl = `https://${shop}/admin/oauth/authorize?${parameters}`;
    return authUrl;
}

const TokenBoot = React.memo((props: {
    shop: string,
    setForceRedirect: (value: boolean) => void,
    token: null | string,
    setToken: (token: null | string) => void
}) => {
    const [settingUp, setSettingUp] = useState(false);
    // Load token
    const appBridge = useAppBridge();
    // const [token, setToken] = React.useState<null | string>(null);
    React.useEffect(() => {
        (async () => {

            // Try to load dev shop
            const devShop = Constants.manifest.extra.shopifyDevShop as { token: string, shop: string } | null;
            if (devShop) {
                console.warn('[boot]: Booting Development Token');
                props.setToken(devShop.token);
                return;
            }

            // Boot from embedded app
            if (!isInIframe() && !props.token) {
                const params = qs.parse(window.location.search);
                // If Oauth
                if (params.code) {
                    setSettingUp(true);
                    console.info('[boot]: Booting production token [external]');
                    const cartmateToken = await backoff(async () => {
                        try {
                            return await authenticateByOAuth(params);
                        } catch (e) {
                            Bugsnag.notify(e);
                            throw e;
                        }
                    });
                    console.info('[boot]: Cartmate token ready');
                    props.setToken(cartmateToken);
                    props.setForceRedirect(true);
                    setSettingUp(false);
                    return;
                } else {
                    console.info('[boot]: Booting production redirect [external]');
                    trackEvent('gen_auth_url', { refferr: document.referrer });
                    const redirectUrl = resolveRedirectUrl(params.shop as string);
                    console.info('[boot]: Redirect to ' + redirectUrl);
                    window.location.href = redirectUrl;
                    return;
                }
            }

            // Loading production shop
            console.info('[boot]: Booting production token [embedded]');
            const sessionToken = await backoff(async () => {
                try {
                    return await getSessionToken(appBridge);
                } catch (e) {
                    Bugsnag.notify(e);
                    throw e;
                }
            });
            console.info('[boot]: Session token loaded...');
            const cartmateToken = await backoff(async () => {
                try {
                    return await authenticateBySessionToken(sessionToken);
                } catch (e) {
                    Bugsnag.notify(e);
                    throw e;
                }
            });
            console.info('[boot]: Cartmate token ready');
            props.setToken(cartmateToken);
        })();
    }, []);

    if (!props.token) {
        return (<View style={{
            height: '100%', width: '100%',
            justifyContent: 'center', alignItems: 'center',
        }}>
            <CartmateLoader />
        </View>);
    }

    return (
        <AppProviders token={props.token}>
            <App />
        </AppProviders>
    );
});

export const Boot = React.memo(() => {

    // Resolve boot shop
    const bootShop = React.useMemo(() => resolveShop(), []);
    const bootToken = React.useMemo(() => resolveToken(), []);
    React.useEffect(() => {
        if (!bootShop) {
            window.location.href = 'https://cartmate.com';
        }
    }, []);
    const [forceRedirect, setForceRedirect] = React.useState(false);
    const [token, setToken] = React.useState<null | string>(bootToken);
    // Loading
    if (!bootShop) {
        return (
            <View style={{ flexGrow: 1, height: '100vh', width: '100vw', }}>
                <CartmateLoader />
            </View>
        );
    }

    console.log('[Boot]: forceRedirect:', forceRedirect);

    // Boot completed
    return (
        <Provider
            key={`app-bridge-provider-${forceRedirect}`}
            config={{
                apiKey: Constants.manifest.extra.shopifyKey,
                shopOrigin: bootShop,
                forceRedirect
            }}
        >
            <TokenBoot
                shop={bootShop}
                setForceRedirect={setForceRedirect}
                token={token}
                setToken={setToken}
            />
        </Provider>
    );
});