import { Badge, Card } from "@shopify/polaris";
import React from "react";
import { View } from "react-native";
import { useHistory, useRouteMatch } from "react-router";
import { useClient } from "../../../api/useClient";

export const RequestsCard = React.memo(() => {
    const history = useHistory();
    const match = useRouteMatch();
    const client = useClient();
    const pendingRequests = client.useIncomingRequestsCount().incomingRequestsCount;

    return (
        <Card
            title={'Incoming and community requests'}
            primaryFooterAction={{
                content: 'Review',
                onAction: () => { history.push(`${match.url}/incoming-listings`) }
            }}
        >
            <Card.Section>
                <label>{'New requests: '}</label>
                <Badge status={'critical'}>{`${pendingRequests}`}</Badge>
            </Card.Section>
        </Card>
    )
});