import * as React from 'react';
import { SandboxComponent } from './dev/SandboxComponent';
import { Home } from './routes/Home';
import { Route } from './routing/routing';
import { AccountRequireRoute } from './routing/AccountRequireRoute';
import { Redirect, Switch, useHistory } from 'react-router';
import { CreateAccount } from './routes/CreateAccount/CreateAccount';
import { Apps } from './routes/Apps';
import { wrapPage, wrapPageWithSkeleton } from './components/wrapPage';
import { Card, Frame, Navigation } from '@shopify/polaris';
import { AppsMajor, TroubleshootMajor, PageMajor, SettingsMajor, ProfileMajor, CircleInformationMajor, ArrowUpMinor, DataVisualizationMajor, NotificationMajor } from '@shopify/polaris-icons';
import { Settings } from './routes/Settings/Settings';
import { AppNew } from './routes/AppNew';
import { EditorComponent } from './dev/EditorComponent';
import { PhonePreviewState } from './configure/theme/preview/PhonePreviewContext';
import { AppTab } from './routes/AppTab';
import './assets/styles/style.css';
import { ShopApp } from './routes/ShopApp';
import { AppPushNotifications } from "./push/AppPushNotifications";
import { IntercomComponent } from './intercom/IntercomComponent';
import { MyShop_shop_properties } from './api/spacex.types';
import { useEffect } from 'react';
import { BillingApply } from "./routes/BillingApply";
import { AppDashboard } from "./routes/AppDashboard";
import { PluginDashboard } from "./routes/PluginDashboard";
import { AppPromote } from "./routes/Promote/AppPromote";
import { PluginPromote } from "./routes/PluginPromote";
import { isInIframe } from './utils/isInIFrame';
import { useClientRouting } from '@shopify/app-bridge-react';
import { identifyUser, mixpanelInit, trackEvent } from './utils/mixpanel';
import { useClient } from './api/useClient';
import { BillingCreate } from './routes/BillingCreate';
import { Help } from './routes/help/Help';
import { CartmatePointsDashboard } from './routes/Promote/components/CartmatePointsDashboard';
import { BillingCoinApply } from './routes/BillingCoinApply';
import { useThemeBanner } from './components/banners/useThemeBanner';
import { animated, config, useTransition } from '@react-spring/native';
import { CreatePushMailing } from './push/CreatePushMailing';
import { ListPushMailing } from './push/ListPushMailing';
import { EditPushMailing } from './push/EditPushMailing';
import { SubscriptionPage } from './routes/subscription/Subscription';
import { Pages } from './routes/Pages/Pages';
import { PagesEdit } from './routes/Pages/PagesEdit';
import { PagesNew } from './routes/Pages/PagesNew';
import { ShopifyPlugin } from './routes/ShopifyPlugin';
import { useNumberLocalStorage } from './components/banners/useNumberLocalStorage';
import { Wizard } from './routes/Pages/Wizard';

mixpanelInit();

export const Intercom = {
    init: () => {
        try {
            var w = window as any;
            var ic = w.Intercom;
            if (typeof ic === "function") {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
                var d = document;
                var i: any = function () {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function (args: any) {
                    i.q.push(args);
                };
                w.Intercom = i;
                var l = function () {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/e4ngjsre';
                    var x = d.getElementsByTagName('script')[0];
                    (x as any).parentNode.insertBefore(s, x);
                };
                if (w.attachEvent) {
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                }
            }
            console.log('intercom inited');
        } catch (error) {
            console.log('intercom error', error);
        }
    },

    boot: (properties: MyShop_shop_properties) => {
        let ts = Math.floor(new Date().getTime() / 1000).toString();
        console.log('bootign... intercom', properties);
        try {
            (window as any).Intercom("boot", {
                app_id: "e4ngjsre",
                custom_launcher_selector: '#contact-us-intercom',
                name: properties.name || 'Cartmate client', // properties.name, // Full name
                email: properties.email || 'Please leave a comment with your contact info', //properties.email, // Email address
                created_at: ts, // Signup date as a Unix timestamp
                phone: properties.phone ? properties.phone : undefined, // Phone
                // user_id: properties.
            });
            console.log('intercom booted');
        } catch (error) {
            console.log(error)
        }
    }
}

export default React.memo(() => {
    //todo https://codesandbox.io/embed/jp1wr1867w
    // const location = useLocation()
    // const transitions = useTransition(location, location => location.pathname, {
    //     from: {opacity: 0, transform: 'translate3d(100%,0,0)'},
    //     enter: {opacity: 1, transform: 'translate3d(0%,0,0)'},
    //     leave: {opacity: 0, transform: 'translate3d(-50%,0,0)'},
    // })

    // Intercom.init();
    const history = useHistory();
    const client = useClient();
    const shop = client.useMyShop();
    const [views, setViews] = useNumberLocalStorage('CustomizationViews');

    useEffect(() => {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/e4ngjsre';
        var x = document.getElementsByTagName('script')[0];
        (x as any).parentNode.insertBefore(s, x);
        console.log('Scrip loaded');
        const unsubscribeHistoryChange = history.listen((location, action) => {
            trackEvent('Web route', { route: location.pathname });
        });

        setViews(views + 1)

        // Add user related info to mixpanel user
        identifyUser(shop.shop.id, shop.shop.name);
        return () => {
            unsubscribeHistoryChange();
        }
    }, []);
    useClientRouting(history);

    return (
        <Frame
            navigation={
                !isInIframe() && (<Navigation location="/">
                    <Navigation.Section
                        items={[{
                            label: 'Apps',
                            url: '/apps',
                            icon: AppsMajor,
                        }, {
                            label: 'Dashboard',
                            url: '/dashboard',
                            icon: DataVisualizationMajor,
                        }, {
                            label: 'Content pages',
                            url: '/pages',
                            icon: PageMajor,
                        }, {
                            label: 'Settings',
                            url: '/settings',
                            icon: SettingsMajor,
                        }, {
                            label: 'Plans',
                            url: '/subscription',
                            icon: ProfileMajor,
                        }, {
                            label: 'Notifications',
                            url: '/push/mailing',
                            icon: NotificationMajor
                        }, {
                            label: 'Promote',
                            url: '/promote',
                            icon: ArrowUpMinor,
                        }, {
                            label: 'About',
                            url: '/about',
                            icon: CircleInformationMajor,
                        }, {
                            label: 'Development',
                            url: '/dev',
                            icon: TroubleshootMajor,
                        }]}
                    />
                </Navigation>)
            }
        >
            <Switch>
                <Route path="/create-account" component={wrapPage(CreateAccount)} />
                <Route path="/billing/create" component={wrapPage(BillingCreate)} />
                <Route path="/wizard" component={wrapPage(Wizard)} />
                {__DEV__ && <Route path="/dev" component={wrapPage(SandboxComponent)} />}
                {/* <Route path="/editor" component={wrapPage(EditorComponent)} /> */}
                <Route path="/test" component={wrapPage(Home)} />
                <Route path="/subscription" component={wrapPage(SubscriptionPage)} />
                <Route path="/about" component={wrapPage(Help)} />
                <Route path={"/plugin/:ref"} component={ShopifyPlugin}/>
                <PhonePreviewState>
                    <IntercomComponent />
                    <Switch>
                        <AccountRequireRoute path={'/billing/apply'} component={wrapPage(BillingApply)} />
                        <AccountRequireRoute path={'/billing/coin/apply'} component={wrapPage(BillingCoinApply)} />
                        <AccountRequireRoute path={'/promote'} component={wrapPage(PluginPromote)} />
                        <AccountRequireRoute path={'/apps/:id/promote'} component={wrapPageWithSkeleton(AppPromote)} />
                        <AccountRequireRoute path={`/apps/:id/dashboard`} component={wrapPage(AppDashboard)} />
                        <AccountRequireRoute path={`/dashboard`} component={wrapPage(PluginDashboard)} />
                        <AccountRequireRoute path={'/apps/new'} component={wrapPage(AppNew)} />
                        <AccountRequireRoute path={'/apps/:id'} component={wrapPage(AppTab)} />
                        <AccountRequireRoute path={'/apps'} component={wrapPage(Apps)} />
                        <AccountRequireRoute path="/pages/new" component={wrapPage(PagesNew)} />
                        <AccountRequireRoute path="/pages/:id" component={wrapPage(PagesEdit)} />
                        <AccountRequireRoute path="/pages" component={wrapPage(Pages)} />
                        <AccountRequireRoute path="/settings" component={wrapPage(Settings)} />
                        <AccountRequireRoute path="/shop-app" component={wrapPage(ShopApp)} />
                        <AccountRequireRoute path="/push/mailing/:id/edit" component={wrapPage(EditPushMailing)} />
                        <AccountRequireRoute path="/push/mailing/create" component={wrapPage(CreatePushMailing)} />
                        <AccountRequireRoute path="/push/mailing" component={wrapPage(ListPushMailing)} />
                        <AccountRequireRoute path="/notifications" component={wrapPage(AppPushNotifications)} />
                        <Redirect from={'/'} to={'/dashboard'} />
                    </Switch>
                </PhonePreviewState>
            </Switch>
        </Frame>
    )
});