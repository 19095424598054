import React, { useCallback, useState } from "react";
import { useClient } from "../api/useClient";
import { PushMailingPlatform, PushMailingStatus } from "../api/spacex.types";
import { useAccessControl } from "../configure/utils/useAccessControl";
import { Badge, Card, FooterHelp, Form, Heading, Layout, Link, Page, Stack, Toast } from "@shopify/polaris";
import { TrialModal } from "../configure/modals/TrialModal";
import { Push } from "./Push";
import { EditPushMailingForm, PushMailing } from "./EditPsuhMailingForm";
import { useHistory, useParams } from "react-router";
import { PushLimitComponent } from "./PushLimitComponent";
import { PushOnboarding } from "../components/onboarding/PushOnboarding";

export const EditPushMailing = React.memo(() => {
    const { id } = useParams<{ id: string }>();
    const client = useClient();
    const ac = useAccessControl();
    const entity = client.usePushMailing({ id }).pushMailing;
    const history = useHistory();
    const [pushMailing, setPushMailing] = useState<PushMailing>({
        title: entity.content.title,
        message: entity.content.message,
        platform: entity.target.platform,
        image: entity.content.image,
        navigation: entity.content.navigation || undefined,
        scheduledAt: entity.scheduledAt ? new Date(parseInt(entity.scheduledAt)) : undefined
    });
    const [savedToastActive, setSavedToastActive] = useState(false);
    const toggleSavedToastActive = useCallback(() => setSavedToastActive((active) => !active), []);

    const savedToastMarkup = savedToastActive ? (
        <Toast content="Mailing saved!" onDismiss={toggleSavedToastActive} />
    ) : null;

    let badge: JSX.Element;
    switch (entity.status) {
        case PushMailingStatus.DRAFT:
            badge = (<Badge status={"info"}>Draft</Badge>);        
            break;
        case PushMailingStatus.COMPLETE:
            badge = (<Badge status={"success"}>Complete</Badge>);            
            break;
        case PushMailingStatus.SCHEDULED:
            badge = (<Badge status={"warning"}>Scheduled</Badge>);        
            break;
        case PushMailingStatus.FAIL:
            badge = (<Badge status={'critical'}>Fail</Badge>);          
            break;
        case PushMailingStatus.CANCELED:
            badge = (<Badge status={'critical'}>Canceled</Badge>);          
            break;
    }
    return (<>
        <Page
            title={"Edit push mailing"}
            breadcrumbs={[
                { url: '/push/mailing' }
            ]}
            titleMetadata={badge}
        >
            <Layout>
                <Layout.Section>
                    <Form onSubmit={() => {}}>
                        <Card
                            title={(
                                <Stack distribution={'leading'} alignment={'center'}>
                                    <Stack.Item fill>
                                        <Heading>Edit push mailing</Heading>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <PushLimitComponent />
                                    </Stack.Item>
                                </Stack>
                            )}
                        >
                            <EditPushMailingForm
                                initialValue={pushMailing}
                                onChange={(value) => { console.log('Push mailing changed', value); setPushMailing(value) }}
                                disabled={{
                                    target: true,
                                    title: true,
                                    message: true,
                                    image: true,
                                    navigation: true,
                                    scheduling: true
                                }}
                            />
                        </Card>
                    </Form>
                </Layout.Section>
                <Layout.Section secondary>
                    <Push
                        title={pushMailing?.title || ''}
                        message={pushMailing?.message || ''}
                        selectedPlatform={pushMailing?.platform || PushMailingPlatform.ALL}
                        image={pushMailing?.image || ''}
                    />
                </Layout.Section>
                <TrialModal />
                {savedToastMarkup}
            </Layout>
            <FooterHelp>
                Learn more about{' '}
                <Link onClick={() => {
                    history.push({ pathname: '/about/push' });
                }}>
                    Cartmate push notifications
                </Link>
            </FooterHelp>
        </Page>
        </>
    );
});