import { Caption, Popover, Stack, TextStyle, Tooltip } from "@shopify/polaris";
import moment from "moment";
import React from "react";
import { useClient } from "../api/useClient";

export const PushLimitComponent = React.memo(() => {
    const client = useClient();
    const limit = client.useAccounPricingLimit({ key: 'pushPerWeek' }).accountPricingLimit;
    if (!limit) {
        return (
            <Tooltip
                content={
                    <Stack vertical distribution={"leading"} alignment={"center"} spacing={"extraTight"}>
                        <Stack.Item>
                            <Caption>
                                <TextStyle variation={"subdued"}>Upgrade plan for access to push notification.</TextStyle>
                            </Caption>
                        </Stack.Item>
                    </Stack>
                }
            >
                <Caption>
                    <TextStyle variation={"subdued"}>
                        {"No push available"}
                    </TextStyle>
                </Caption>
            </Tooltip>
        );
    }
    const nextSyncDate = moment(new Date(
        parseInt(String(limit?.lastSyncDate || Date.now())) +
        (parseInt(String(limit.period)) * 1000)
    ));
    const lastSyncDate = moment(new Date(parseInt(String(limit?.lastSyncDate || Date.now()))));
    return (
        <Caption>
            <TextStyle variation={"subdued"}>
                {"Push per week: "}
                <Tooltip
                    content={(

                        <Stack vertical distribution={"leading"} alignment={"center"} spacing={"extraTight"}>
                            <Stack.Item>
                                <Caption>
                                    <TextStyle variation={"subdued"}>
                                        {`Period start: `}
                                        <TextStyle variation={"strong"}>
                                            {lastSyncDate.format('MM.DD.YYYY')}
                                        </TextStyle>
                                    </TextStyle>
                                </Caption>
                            </Stack.Item>
                            <Stack.Item>
                                <Caption>
                                    <TextStyle variation={"subdued"}>
                                        {`Next period: `}
                                        <TextStyle variation={"strong"}>
                                            {nextSyncDate.format('MM.DD.YYYY')}
                                        </TextStyle>
                                    </TextStyle>
                                </Caption>
                            </Stack.Item>
                        </Stack>

                    )}
                    preferredPosition={"mostSpace"}
                >
                    <TextStyle variation={"strong"}>{`${limit.count}/${limit.limit}`}</TextStyle>
                </Tooltip>
            </TextStyle>
        </Caption>
    );
});