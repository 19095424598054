import React, { useEffect, useMemo } from 'react';
import { useState } from "react";
import '@shopify/polaris/dist/styles.css';
import Clock from "./Clock";
import { Animated, View, Text } from 'react-native';
import { PushMailingPlatform } from '../api/spacex.types';
import { useAccount } from '../configure/utils/useAccount';
import { useClient } from '../api/useClient';
import { useAppCard } from '../configure/utils/useAppCard';
import { useActiveApp } from '../configure/utils/useActiveApp';

const iosBg = require('../assets/images/Group1.png');
const iosStatusBar = require('../assets/images/status-bar.svg');
const pushLogo = require('../assets/images/push-logo.svg');
const iosFlashlight = require('../assets/images/ios-flashlight-3.svg');
const iosCamera = require('../assets/images/camera.svg');
const androidLogo = require('../assets/images/GooglePixel4a.png');
const androidStatusBar = require('../assets/images/Group2.svg');
const androidCall = require('../assets/images/call.svg');
const androidCamera = require('../assets/images/camera_enhance.svg');

export const Push = React.memo((props: { title: string, message: string, selectedPlatform: string, image: string }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [isRotated, setIsRotated] = useState<boolean>(false);
    const [textExpanded, setTextExpanded] = useState<boolean>(false);
    const app = useActiveApp();
    const [appCard, publishedCard] = useAppCard(app.id);
    const storeName = (appCard || publishedCard).title;


    let styledLargeImg
    let pushBody
    let content
    let smallImg
    let styledSmallImg
    let message = ''
    let messageBlock

    // title = 'Enter your text as title'
    // message = 'See how your push message will look'
    message = `${props.title.length > 0
        ? props.title
        : 'Enter your text as title'
        }\n${props.message.length > 0
            ? props.message
            : 'See how your push message will look'
        }`;

    const rotation = React.useMemo(() => new Animated.Value(0), []);
    const heightGrow = React.useMemo(() => new Animated.Value(0), []);

    const rotateOff = () => Animated.timing(rotation, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true
    }).start();

    const rotateOn = () => Animated.timing(rotation, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true
    }).start();

    const deg = rotation.interpolate({
        inputRange: [0, 1],
        outputRange: ['45deg', '-135deg']
    })

    useEffect(() => {
        if (isRotated && props.image.length > 0) {
            rotateOn()
        } else if (textExpanded && message.length < 45) {
            rotateOff()
        } else if (textExpanded && message.length > 44) {
            rotateOn()
        } else if (!textExpanded || !isRotated || !expanded) {
            rotateOff()
        }

    })
    const heightGrowOff = () => Animated.timing(heightGrow, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false
    }).start();
    const heightGrowOn = () => Animated.timing(heightGrow, {
        toValue: 1,
        duration: 200,
        useNativeDriver: false
    }).start();

    const setHeight = heightGrow.interpolate({
        inputRange: [0, 1],
        outputRange: ['0px', '150px']
    })

    if (props.selectedPlatform === PushMailingPlatform.ANDROID) {
        styledLargeImg = {
            height: '160px',
            overflow: 'hidden',
            width: '113%',
            margin: '10px 0 0 -16px',
            backgroundImage: `url(${props.image.replace(/'/g, '')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }
        styledSmallImg = {
            display: 'inline-block',
            width: '45px',
            height: '45px',
            backgroundImage: `url(${props.image.replace(/'/g, '')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }
    } else {
        styledLargeImg = {
            margin: '20px 0 6px -52px',
            width: '131%',
            height: '160px',
            overflow: 'hidden',
            backgroundImage: `url(${props.image.replace(/'/g, '')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }
        styledSmallImg = {
            display: 'inline-block',
            width: '28px',
            height: '28px',
            backgroundImage: `url(${props.image.replace(/'/g, '')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            borderRadius: '7px'
        }
    }
    useEffect(() => {
        if (!expanded || props.image === '') {
            heightGrowOff()
        } else {
            heightGrowOn()
        }
    })
    if (!textExpanded || props.message.length < 45) {
        messageBlock = 2
    } else {
        messageBlock = 4
    }
    if (!expanded || props.image === '') {
        smallImg = <div className="push__text-img-ios" style={styledSmallImg} />
        pushBody = <Animated.View style={{ height: setHeight }}></Animated.View>
        if (messageBlock == 4) {
            messageBlock = 4
        } else {
            messageBlock = 2
        }
    } else {
        pushBody = <Animated.View style={{ height: setHeight }}>
            <div className="push__large-img" style={styledLargeImg}></div>
        </Animated.View>
        messageBlock = 4
    };


    const tab1 = <div className='wrapper-ios' style={{
        textAlign: 'center',
        color: '#fff',
        width: '340px',
        height: '670px',
        backgroundImage: `url(${iosBg})`,
        backgroundSize: 'cover',
        position: 'relative',
        zIndex: 5,
        overflow: 'hidden'
    }}>
        <span style={{
            position: 'absolute',
            width: '60px',
            height: '10px',
            backgroundImage: `url(${iosStatusBar})`,
            backgroundSize: 'cover',
            right: '30px',
            top: '34px'
        }}></span>
        <div className="main-ios" style={{ height: '84%' }}>
            <Clock device='ios' />
            <div className='push-ios' onMouseEnter={() => {
                setExpanded(true);
                setTextExpanded(true)
            }} onMouseLeave={() => {
                setExpanded(false);
                setTextExpanded(false)
            }} style={{
                color: '#000',
                padding: '10px',
                backgroundColor: 'rgba(245, 245, 245, 0.5)',
                margin: '0 40px',
                borderRadius: '10px',
                textAlign: 'left',
                cursor: 'pointer'
            }}>
                <div className='push__info-ios'
                    style={{ fontSize: '15px', position: 'relative', lineHeight: '18px', paddingLeft: '42px' }}>
                    <div style={{
                        width: '32px',
                        height: '32px',
                        position: 'absolute',
                        backgroundImage: `url(${pushLogo})`,
                        backgroundSize: 'cover',
                        left: 0,
                        top: '3px'
                    }}></div>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flex: 1 }}>
                            <Text style={{ fontWeight: '600', fontSize: 15, lineHeight: 18, width: 169, marginBottom: 5 }}
                                numberOfLines={1} ellipsizeMode='tail'>
                                {storeName}
                            </Text>
                        </View>
                        <span className='push__top--time-ios'
                            style={{ display: 'inline-block', color: 'rgba(60, 60, 67, 0.6)' }}>now</span>
                    </View>
                    {pushBody}
                    <div className="push__text-ios">
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ flex: 1 }}>
                                <Text style={{ width: '160px', margin: '0 10px 0 0' }} numberOfLines={messageBlock}
                                    ellipsizeMode='tail'>
                                    {message}
                                </Text>
                            </View>
                            {smallImg}
                        </View>
                    </div>
                </div>
            </div>
        </div>
        <div className='push__bottom-ios' style={{ textAlign: 'left', position: 'relative' }}>
            <div style={{
                color: '#fff',
                left: '50%',
                transform: 'translateX(-50%)',
                position: 'absolute',
                marginTop: '20px'
            }}>swipe up to open
            </div>
            <div className="push__bottom--left-ios" style={{
                display: 'inline-block',
                width: '40px',
                height: '40px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                position: 'relative',
                zIndex: 5,
                borderRadius: '50%',
                marginLeft: '60px'
            }}>
                <div style={{
                    position: 'absolute',
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    backgroundImage: `url(${iosFlashlight})`,
                    bottom: '4px',
                    right: '5px'
                }}></div>
            </div>
            <div className="push__bottom--right-ios" style={{
                display: 'inline-block',
                width: '40px',
                height: '40px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                position: 'relative',
                zIndex: 5,
                borderRadius: '50%',
                marginLeft: '140px'
            }}>
                <div style={{
                    position: 'absolute',
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    backgroundImage: `url(${iosCamera})`,
                    bottom: '6px',
                    right: '5px'
                }}></div>
            </div>
            <div style={{
                width: '130px',
                height: '4px',
                backgroundColor: '#fff',
                borderRadius: '2px',
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                bottom: '-30px'
            }}></div>
        </div>
    </div>

    const tab2 = <div className='wrapper-android' style={{
        fontFamily: '"Roboto", sans-serif',
        textAlign: 'center',
        color: '#fff',
        width: '340px',
        height: '703px',
        backgroundImage: `url(${androidLogo})`,
        backgroundSize: 'cover',
        position: 'relative',
        zIndex: 5,
        overflow: 'hidden'
    }}>
        <span style={{
            position: 'absolute',
            width: '53px',
            height: '16px',
            backgroundImage: `url(${androidStatusBar})`,
            backgroundSize: 'cover',
            right: '35px',
            top: '34px'
        }}></span>
        <div className="main-android" style={{ height: '88%' }}>
            <Clock device='android' />
            <div className='push-android' onMouseEnter={() => {
                setExpanded(true);
                setIsRotated(true);
                setTextExpanded(true);
            }} onMouseLeave={() => {
                setExpanded(false);
                setIsRotated(false);
                setTextExpanded(false);
            }} style={{
                color: '#000',
                padding: '16px',
                backgroundColor: '#fff',
                margin: '0 32px',
                textAlign: 'left',
                borderRadius: '8px',
                cursor: 'pointer',
                overflow: 'hidden'
            }}>
                <div className='push__info-android'>
                    <div className="push__headline-android" style={{
                        fontSize: '12px',
                        position: 'relative',
                        lineHeight: '16px',
                        paddingLeft: '25px',
                        marginBottom: '12px'
                    }}>
                        <div style={{
                            width: '18px',
                            height: '18px',
                            position: 'absolute',
                            backgroundImage: `url(${pushLogo})`,
                            backgroundSize: 'cover',
                            left: 0,
                            top: '-3px'
                        }}></div>
                        <span className="push__headline-name-android">
                            Cartmate
                        </span>
                        <span className="push__headline-time-android"
                            style={{ marginLeft: '12px', position: 'relative' }}>
                            <span style={{ position: 'absolute', fontSize: '20px', top: '-5px', left: '-8px' }}>.</span>
                            now
                        </span>
                        <Animated.View style={{
                            position: 'absolute',
                            borderBottomColor: '#000',
                            borderBottomWidth: 2,
                            borderRightColor: '#000',
                            borderRightWidth: 2,
                            width: '10px',
                            height: '10px',
                            top: '0',
                            right: '2px',
                            transform: [{ rotate: deg }]
                        }}></Animated.View>
                    </div>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flex: 1, width: 184, marginRight: 15 }}>
                            <Text style={{ fontWeight: '500', fontSize: 14, lineHeight: 20, marginBottom: 2 }}
                                numberOfLines={1} ellipsizeMode='tail'>
                                {storeName}
                            </Text>
                            <Text style={{ fontSize: 14, lineHeight: 18, color: 'rgba(0, 0, 0, 0.6)', margin: 0 }}
                                numberOfLines={messageBlock} ellipsizeMode='tail'>
                                {message}
                            </Text>
                        </View>
                        {smallImg}
                    </View>
                    {pushBody}
                </div>
            </div>
        </div>
        <div className='push__bottom-android' style={{ textAlign: 'left', position: 'relative' }}>
            <div className="push__bottom--left-android" style={{
                display: 'inline-block',
                width: '40px',
                height: '40px',
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                position: 'relative',
                zIndex: 5,
                borderRadius: '40%',
                marginLeft: '50px'
            }}>
                <div style={{
                    position: 'absolute',
                    width: '30px',
                    height: '30px',
                    backgroundImage: `url(${androidCall})`,
                    backgroundRepeat: 'no-repeat',
                    bottom: '2px',
                    right: '2px'
                }}></div>
            </div>
            <div className="push__bottom--right-android" style={{
                display: 'inline-block',
                width: '40px',
                height: '40px',
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                position: 'relative',
                zIndex: 5,
                borderRadius: '40%',
                marginLeft: '160px'
            }}>
                <div style={{
                    position: 'absolute',
                    width: '30px',
                    height: '30px',
                    backgroundImage: `url(${androidCamera})`,
                    backgroundRepeat: 'no-repeat',
                    bottom: '2px',
                    right: '2px'
                }}></div>
            </div>
        </div>
    </div>

    if (props.selectedPlatform === PushMailingPlatform.IOS || props.selectedPlatform === PushMailingPlatform.ALL) {
        content = tab1
    } else {
        content = tab2
    }

    return <>
        {content}
    </>
})