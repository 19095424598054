import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { VisuallyHidden, Stack, Thumbnail, Button, DropZone, FormLayout, Select } from '@shopify/polaris'
import { Knob } from '../Knob';
import Constants from 'expo-constants';
import { FileInfo, Widget, WidgetAPI } from '@uploadcare/react-widget';
import { DeleteMajor } from '@shopify/polaris-icons';
import { useClient } from '../../../api/useClient';
import { DafaultBlockValues } from '../../blocks/defaults';

const crops = 'free, 16:9, 4:3, 5:4, 1:1'

export const KnobBlurhashImage = React.memo<Knob.KnobComponentProps<Knob.KnobBlurhashImageDescriptor>>(({ innerKey, value, onChange, knob, disabled }) => {
    const { description, crop: cropInput, clearable, defaultValue } = knob;
    const nullObj = { src: null, blurhash: null, resizeMode: null };
    const widgetApi = useRef<WidgetAPI | null>(null);
    const client = useClient();
    const [cdnUrl, setCdnUrl] = useState(value.src);
    const [resizeMode, setresizeMode] = useState<'contain' | 'cover' | 'stretch' | 'center'>('cover');

    const onCleanCb = useCallback(() => onChange(nullObj, innerKey), [knob, onChange]);

    const onFileChange = useCallback((fileInfo: FileInfo) => {
        const url = fileInfo.cdnUrl;
        console.log('onFileChange', fileInfo.cdnUrl);
        if (url !== cdnUrl) {
            setCdnUrl(url);
        }
    }, [cdnUrl, onChange]);

    useEffect(() => {
        if (cdnUrl) {
            client.queryBlurHash({ url: cdnUrl })
                .then((result) => {
                    const newValue = result
                        ? { src: result.getBlurHash.src, blurhash: result.getBlurHash.blurhash, resizeMode: resizeMode }
                        : nullObj;
                    console.log('newValue', newValue);
                    if (newValue !== value) {
                        onChange(newValue, innerKey);
                    }
                })
                .catch((error) => console.log(error));
        }
    }, [cdnUrl, resizeMode]);

    const openDialog = useCallback(() => {
        widgetApi.current?.openDialog('')
    }, [])

    if (value && value !== defaultValue && value.src) {
        return <FormLayout>
            <label>{description}</label>
            <div style={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <Thumbnail
                    size="large"
                    alt="img"
                    source={value.src}
                />
                <div style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column-reverse',
                    marginLeft: '10px'
                }}>
                    {!disabled && (value.src !== DafaultBlockValues.backgroundUrl) && (
                        <Widget
                            ref={widgetApi}
                            imagesOnly={true}
                            crop={cropInput || crops}
                            value={value.src}
                            onChange={onFileChange}
                            previewStep={true}
                            pathValue={true}
                            publicKey={Constants.manifest.extra.uploadcarePublicKey} multiple={false} />
                    )}
                    <div
                        style={{
                            display: 'flex',
                            marginRight: '10px',
                            marginTop: '10px',
                            alignItems: 'center',
                        }}
                    >
                        <Select
                            label="Resize mode"
                            labelInline
                            options={[
                                {
                                    label: 'contain',
                                    value: 'contain',
                                },
                                {
                                    label: 'cover',
                                    value: 'cover',
                                },
                                {
                                    label: 'stretch',
                                    value: 'stretch',
                                },
                                {
                                    label: 'center',
                                    value: 'center',
                                },
                            ]}
                            onChange={(value) => setresizeMode(value as 'contain' | 'cover' | 'stretch' | 'center')}
                            value={resizeMode}
                        />
                    </div>
                    {clearable &&
                        <Button icon={DeleteMajor} onClick={onCleanCb}>
                            Change
                        </Button>
                    }


                </div>
            </div>
        </FormLayout>
    } else {
        return (
            <FormLayout>
                <Stack vertical>
                    <label>{description}</label>
                    <DropZone
                        disabled={!!disabled}
                        type="image"
                        onClick={openDialog}
                        onDragEnter={openDialog}
                    >
                        <DropZone.FileUpload />
                        <VisuallyHidden>
                            <Widget
                                ref={widgetApi}
                                imagesOnly={true}
                                crop={cropInput || crops}
                                onChange={onFileChange}
                                previewStep={true}
                                pathValue={true}
                                publicKey={Constants.manifest.extra.uploadcarePublicKey}
                                multiple={false}
                            />
                        </VisuallyHidden>
                    </DropZone>
                </Stack>
            </FormLayout>
        );
    }
})