import { config, useTransition } from "@react-spring/core";
import { animated } from "@react-spring/native";
import { Card, FormLayout, Layout, Link, Stack, TextField, TextStyle } from "@shopify/polaris";
import React from "react";
import { useClient } from "../api/useClient";
import { useInfoBanner } from "./banners/useInfoBanner";
import { TipKey } from "./banners/useTip";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";

export const ProductPublishingComponent = React.memo(({ notAnnotated: annotated }: { notAnnotated?: boolean }) => {
    const client = useClient();
    const productStatus = client.useProductStatus();
    const appBridge = useAppBridge();

    const [banner] = useInfoBanner(
        TipKey.ProductPublishingInfo,
        '',
        'Products publishing to Cartmate mobile in-app storefront can take 30-60 minutes to update. Once your products are successfully published your products will be visible on Cartmate mobile app.'
    );

    const closeBanner = useTransition(banner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    })

    const infoBanner = closeBanner(
        (style, banner) => banner
            && <animated.View style={[{ marginBottom: '2rem' }, style]}>
                <Card>{banner}</Card>
            </animated.View>
    );

    if (annotated) {
        return (
            <Card
                title={'Product status'}
                sectioned
                actions={[{
                    content: 'Manage availability',
                    onAction: () => {
                        const redirect = ShopifyRedirect.create(appBridge);
                        redirect.dispatch(
                            ShopifyRedirect.Action.ADMIN_PATH,
                            {
                                path: '/products?published_status=cartmate%3Avisible&selectedView=all',
                                newContext: true
                            }
                        );
                    }
                }]}
            >
                {infoBanner}
                <Stack alignment={"center"}>
                    <Stack.Item fill>
                        <div>
                            <p><TextStyle variation={'strong'}>{productStatus.productStatus}</TextStyle>{' products are available to Cartmate mobile in-app storefront'}</p>
                        </div>
                    </Stack.Item>
                    <Stack.Item>
                        <p><TextStyle>{'More info about product status and synchronization in the '}
                            <Link onClick={() => {
                                const redirect = ShopifyRedirect.create(appBridge);
                                redirect.dispatch(ShopifyRedirect.Action.APP, '/settings/general');
                            }}>
                                General settings seciton
                            </Link>
                        </TextStyle></p>
                    </Stack.Item>
                    <Stack.Item>
                        <p><TextStyle variation={'subdued'}>{'If you are having troubles with product avilabilty, please, contact us via '}
                            <a href="mailto:hello@cartmate.com">Email</a> or
                        </TextStyle></p>
                        <div id={'contact-us-intercom'}>
                            <a href={'#'}>Message us in online support chat</a>
                        </div>
                    </Stack.Item>
                </Stack>
            </Card>
        )
    }

    return (
        <Layout.AnnotatedSection
            title="Publishing"
            description="Products that are being synced to your Cartmate in-app
            catalog, or have errors preventing their
            sync, are shown here."
        >
            <Card
                title={'Product status'}
                sectioned
                actions={[{
                    content: 'Manage availability',
                    onAction: () => {
                        const redirect = ShopifyRedirect.create(appBridge);
                        redirect.dispatch(
                            ShopifyRedirect.Action.ADMIN_PATH,
                            {
                                path: '/products?published_status=cartmate%3Avisible&selectedView=all',
                                newContext: true
                            }
                        );
                    }
                }]}
            >
                {infoBanner}
                <Stack alignment={"center"}>
                    <Stack.Item fill>
                        <div>
                            <p><TextStyle variation={'strong'}>{productStatus.productStatus}</TextStyle>{' products are available to Cartmate mobile in-app storefront'}</p>
                        </div>
                    </Stack.Item>
                    <Stack.Item>
                        <p><TextStyle variation={'subdued'}>{'If you are having troubles with product avilabilty, please, contact us via '}
                            <a href="mailto:hello@cartmate.com">Email</a> or
                        </TextStyle></p>
                        <div id={'contact-us-intercom'}>
                            <a href={'#'}>Message us in online support chat</a>
                        </div>
                    </Stack.Item>
                    {/* <Stack.Item>
                        <p><TextStyle variation={'subdued'}>{'Also you can resolve some issues by starting force synchronization in '}
                            <Link onClick={() => {
                                const redirect = ShopifyRedirect.create(appBridge);
                                redirect.dispatch(ShopifyRedirect.Action.APP, '/settings/synchronize');
                            }}>
                                Synchronization settings
                            </Link>
                        </TextStyle></p>
                    </Stack.Item> */}
                </Stack>
            </Card>
        </Layout.AnnotatedSection>
    )
});