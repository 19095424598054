import { Modal, Heading } from "@shopify/polaris";
import React from "react";
import { View, Text } from "react-native";
import { OnboardingModal, OnboardingType } from "../modals/OnboardingModal";

const pushOnboarding = require('../../assets/gifs/onboarding-push.mp4');

export const PushOnboarding = React.memo(() => {
    return (
        <OnboardingModal
            title={'Send push notifications'}
            _key={OnboardingType.Push}
        >
            <Modal.Section>
                <View style={{
                    flexDirection: 'row',
                    flex: 1, flexWrap: 'wrap',
                    justifyContent: 'center'
                }}>
                    <video
                        style={{
                            display: 'flex',
                            flex: 1,
                            maxHeight: '400px',
                            maxWidth: '400px'
                        }}
                        src={pushOnboarding}
                        autoPlay
                        muted
                        controls={false}
                        loop
                    />
                    <View style={{
                        flex: 1,
                        maxHeight: '400px',
                        maxWidth: '400px',
                        margin: '16px'
                    }}>
                        <View style={{ marginBottom: '10px' }}>
                            <Heading>
                                {'Send PUSH notifications'}
                            </Heading>
                            <Text>
                                {'PUSH notifications are a good way to boost your sales. Let customers know about all promotions and activities in your store. Think of an attractive text and use emoji, it will help to attract attention and motivate the client to come to your store more often. You can customize title and text on all plans, but image adding and notification scheduling if available only at PRO plan. Standard plan allows you to send 1 push notification per week, but PRO plan grants 7 notifications per week.'}
                            </Text>
                        </View>
                    </View>
                </View>
            </Modal.Section>
        </OnboardingModal>
    );
});