import { Button } from "@shopify/polaris";
import { PauseCircleMajor, PlayCircleMajor } from "@shopify/polaris-icons";
import React, { useEffect, useState } from "react"
import { StyleProp, View, ViewStyle } from "react-native";

export type CarouselItem = {
    id: string,
    content: any
}

export const Carousel = React.memo((
    {
        style,
        items,
        interval,
        onFinish,
        loop,
        canBePaused
    }: {
        style?: StyleProp<ViewStyle>
        items: CarouselItem[]
        interval?: number,
        onFinish?: () => void,
        loop?: boolean,
        canBePaused?: boolean
    }
) => {
    const [current, setCurrent] = useState<CarouselItem | undefined>(items.length > 0 ? items[0] : undefined);
    const [paused, setPaused] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (paused) {
                return;
            }
            if (current && items.length > 0) {
                const index = items.findIndex((i) => i.id === current.id);
                console.log('[Carousel]', index);
                if (index !== -1) {
                    if (index <= items.length - 2) {
                        setCurrent(items[index + 1]);
                        if ((index + 1 === items.length - 1) && onFinish && !loop) {
                            console.log('[Carousel]', 'onFinish');
                            onFinish();
                        }
                    } else if (loop && index === items.length - 1) {
                        setCurrent(items[0]);
                    }
                }
            }
        }, interval || 9000);

        return () => {
            clearInterval(intervalId);
        }
    }, [interval, items, onFinish, setCurrent, current, paused]);

    return (
        <View style={[{
            flex: 1
        }, style]}>
            {current?.content}
            {canBePaused && (
                <View style={{
                    width: '100%',
                    justifyContent: 'center',
                    padding: '10px'
                }}>
                    <Button
                        icon={paused ? PlayCircleMajor : PauseCircleMajor}
                        onClick={() => setPaused(!paused)}
                        plain
                        size={'large'}
                        monochrome
                    >
                        {paused ? 'Play' : 'Pause'}
                    </Button>
                </View>
            )}
            <View style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
            }}>
                {items.map((i, index) => {
                    return (
                        <div
                            key={`${i.id}-${index}`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setCurrent(i);
                            }}
                        >
                            <View style={{
                                height: 10, width: 10, borderRadius: 5,
                                backgroundColor: current?.id === i.id ? '#008060' : 'grey',
                                margin: 8,
                            }} />
                        </div>
                    )
                })}
            </View>
        </View>
    );
});