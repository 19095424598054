
import { useCallback, useEffect, useMemo, useState } from "react";

export function useNumberLocalStorage(_key: string) {
    const key: string = useMemo(() => 'Number' + _key, [_key]);

    const [value, setValue] = useState<(number)>(parseInt(localStorage.getItem(key) || '0'));
    console.log('COUNTER', value);

    useEffect(() => {
        let localValue = 0;
        try {
            localValue = parseInt(localStorage.getItem(key) || '0');
        } catch (e) {
            localValue = 0;
        }

        // if (__DEV__) {
        //     localValue = 0;
        // }
        setValue(localValue);
    }, [key]);

    const setNewValue = useCallback((newValue: number) => {
        localStorage.setItem(key, (newValue).toString())
        setValue(newValue);
    }, [value, key]);

    return [value, setNewValue] as const;
}
