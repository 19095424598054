import Bugsnag from "@bugsnag/browser";
import { Card, EmptyState, Heading, Pagination, ResourceList, Stack, TextStyle } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { ListCommunityListings_listCommunityListings, ListCommunityListings_listCommunityListings_edges_node } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { ResourceListSkeleton } from "../../../components/Skeleton/ResourceListSkeleton";
import { CommunityAdLisingItem } from "./CommunityAdLisingItem";
import { ReviewListingModal } from "./ReviewListingModal";

const communityIcon = require('../../../assets/images/logo-chats.svg');

export const CommunityListings = React.memo(() => {
    const client = useClient();
    const [results, setResults] = useState<ListCommunityListings_listCommunityListings>();
    const [loading, setLoading] = useState<boolean>(false);
    const [reviewModalItem, setReviewModalItem] = useState<ListCommunityListings_listCommunityListings_edges_node>();
    const pageSize = 6;

    const loadPage = useCallback((pageSize: number, after?: string | null) => {
        client.queryListCommunityListings({
            first: pageSize,
            after: after,
        })
            .then((result) => {
                console.log('Nex page loaded', result);
                setResults(result.listCommunityListings);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    }, [client]);

    const loadNextPage = useCallback(() => {
        console.log('loading next page')
        setLoading(true);
        loadPage(pageSize, results?.pageInfo.endCursor);
    }, [results, loadPage]);

    const loadPrevPage = useCallback(() => {
        if (!results?.pageInfo.hasPreviousPage) {
            throw new Error("No previous page");
        }
        setLoading(true);
        loadPage(pageSize, results.pageInfo.startCursor);
    }, [pageSize, results, loadPage]);

    useEffect(() => {
        loadNextPage();
    }, []);

    const handleModalCancel = useCallback(
        () => {
            setReviewModalItem(undefined);
        },
        [setReviewModalItem],
    );

    const handleAcceptListing = useCallback(
        (listing?: ListCommunityListings_listCommunityListings_edges_node) => {
            if (listing) {
                client.mutateAcceptCommunityListing({ id: listing?.adListing.id }).then((res) => {
                    console.log('mutateAcceptCommunityListing', res);
                }).catch((e) => {
                    console.log('mutateAcceptCommunityListing error', e);
                    Bugsnag.notify(e);
                }).finally(() => {
                    handleModalCancel();
                });
            }
        },
        [client, handleModalCancel],
    );

    if (loading) {
        return (<ResourceListSkeleton />);
    }

    return (
        <Card>
            <Card.Section>
                <View style={{flexDirection: 'row'}}>
                    <Stack.Item fill>
                        <Stack vertical>
                            <Stack.Item>
                                <Heading>
                                    <TextStyle variation={'strong'}>
                                    {'Community requests'}
                                    </TextStyle>
                                </Heading>
                            </Stack.Item>
                            <Stack.Item>
                                <TextStyle variation={'subdued'}>
                                    {
                                        'Community positions from stores which would like to exchange and communicate. \n'
                                        + 'You can accept requests and get more Cartmate Points when your in-app customer clicks on this position.'
                                    }
                                </TextStyle>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <div style={{
                            textAlign: 'center',
                            width: '100px',
                            height: '100px',
                            backgroundImage: `url(${communityIcon})`,
                            backgroundSize: 'cover',
                        }} />
                    </Stack.Item>
                        </View>
            </Card.Section>
            <Card.Section>
                {(results?.edges.length === 0) && <EmptyState
                    heading="No requests yet"
                    image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png" />}
                {(results?.edges && results?.edges.length > 0) && (
                    <ResourceList
                        items={results?.edges.map((e) => e.node)}
                        renderItem={(l) => {
                            return (
                                <ResourceList.Item
                                    onClick={() => { }}
                                    id={l.adListing.id}
                                    shortcutActions={[
                                        {
                                            content: 'Review',
                                            onAction: () => {
                                                setReviewModalItem(l);
                                            }
                                        }
                                    ]}
                                >
                                    <CommunityAdLisingItem item={l} />
                                </ResourceList.Item>
                            );
                        }}
                    />
                )}
            </Card.Section>
            {(results?.pageInfo.hasNextPage || results?.pageInfo.hasPreviousPage) && <Card.Section>
                <Stack distribution={"center"}>
                    <Pagination
                        hasPrevious={results?.pageInfo.hasPreviousPage}
                        onPrevious={() => {
                            loadPrevPage()
                        }}
                        hasNext={results?.pageInfo.hasNextPage}
                        onNext={() => {
                            loadNextPage()
                        }}
                    />
                </Stack>
            </Card.Section>}
            <ReviewListingModal
                listing={reviewModalItem}
                handleAccept={handleAcceptListing}
                handleCancel={handleModalCancel}
            />
        </Card>
    );
});