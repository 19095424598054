import React, { useCallback, useEffect, useState } from "react";
import { Banner, Card, FooterHelp, Heading, Icon, Layout, Link, Page, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import { ChatMajor, CustomersMajor, FollowUpEmailMajor, MobileAcceptMajor, OnlineStoreMajor, ProfileMajor, ReplaceMajor, SendMajor } from '@shopify/polaris-icons';
import moment from 'moment';
import { useAccount } from "../../configure/utils/useAccount";
import { AccountPricingStatus, ListPricing_listPricing, PricingStatus } from "../../api/spacex.types";
import { IntercomComponent } from "../../intercom/IntercomComponent";
import { useClient } from "../../api/useClient";
import { CleanSpaceX } from "../../components/types";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { useAppBridge } from "@shopify/app-bridge-react";
import { View } from "react-native";
import { Plan } from "./Plan";

type ListPricing = CleanSpaceX<ListPricing_listPricing>;

export const SubscriptionPage = React.memo(() => {
    const client = useClient();
    const account = useAccount();
    const appBridge = useAppBridge();

    // const [pageSize, setPageSize] = useState<number>(10);
    // const [cursor, setCursor] = useState<string | null>(null);

    const results = client.useListPricing({ first: 10 }).listPricing as ListPricing;
    // const [results, setResults] = useState<ListPricing>();

    // const loadNextPage = useCallback(() => {
    //     if (results && !results.pageInfo.hasNextPage) {
    //         // console.log('No next page', cursor, results);
    //         throw new Error("No next page");
    //     }
    //     const queryData = {
    //         first: 10 // pageSize,
    //         // after: results?.pageInfo.endCursor
    //     };
    //     console.log('Load next page', queryData)
    //     client.queryListPricing(queryData).then((result) => {
    //         console.log('Nex page loaded', result);
    //         setResults(result.listPricing);
    //     });
    // }, [results]);

    // const loadPrevPage = useCallback(() => {
    //     if (!results?.pageInfo.hasPreviousPage) {
    //         console.log('No previous page', cursor, results);
    //         throw new Error("No previous page");
    //     }
    //     const queryData = { last: pageSize, before: results.pageInfo.startCursor };
    //     console.log('Load next page', queryData)
    //     client.queryListPricing(queryData).then((result) => {
    //         console.log('Nex page loaded', result);
    //         setResults(result.listPricing);
    //     });
    // }, [pageSize, results]);

    // useEffect(() => {
    //     loadNextPage();
    // }, []);

    const trialEndAt = parseInt(account.pricing.trialEndAt || '0');
    const memberSince = moment(parseInt(account.pricing.createdAt)).utc().format('ll');
    const currentPlan = account.pricing.pricing?.title || 'Trial';
    let planStatus: string;
    switch (account.pricing.status) {
        case AccountPricingStatus.ACTIVE:
            planStatus = 'Active';
            break;
        case AccountPricingStatus.BLOCKED:
            planStatus = 'Blocked';
            break;
        case AccountPricingStatus.CREATED:
        case AccountPricingStatus.PENDING:
            planStatus = 'Waiting for payment';
            break;
        case AccountPricingStatus.INACTIVE:
            planStatus = 'Inactive';
            break;
        default:
            planStatus = 'Unknown';
            break;
    }


    const onSubscribe = useCallback(
        (confirmUrl?: string | null) => {
            console.log('confirmUrl', confirmUrl);
            if (confirmUrl) {
                const redirect = ShopifyRedirect.create(appBridge);
                redirect.dispatch(ShopifyRedirect.Action.REMOTE, confirmUrl);
            }
            client.refetchListPricing({ first: 10 });
            client.refetchAccountWrapper();
        },
        [],
    );

    return (
        <Page title={"Subscription"}>
            <IntercomComponent />
            <Layout>
                <Layout.AnnotatedSection
                    title={"Plan"}
                    description={
                        <Stack vertical>
                            <TextContainer>
                                {/* <p>Looking for new options?</p> */}
                                <p>Having trouble with the app, want to report a bug, have an idea for new features, want to say 'Hi'?  We’d love to hear from you! Please, contact us:</p>
                                <Stack vertical alignment={"leading"} distribution={"center"} spacing={"tight"} wrap={false}>
                                    <Stack alignment={"center"} distribution={"leading"} spacing={"tight"} wrap={false}>
                                        <Stack.Item>
                                            <Icon
                                                color={'subdued'}
                                                source={ChatMajor}
                                                backdrop
                                            />
                                        </Stack.Item>
                                        <Stack.Item fill>
                                            <div id={'contact-us-intercom'}>
                                                <a href={'#'}>Message us in online support chat</a>
                                            </div>
                                        </Stack.Item>
                                    </Stack>
                                    <Stack alignment={"center"} distribution={"leading"} spacing={"tight"} wrap={false}>
                                        <Stack.Item>
                                            <Icon
                                                color={'subdued'}
                                                source={FollowUpEmailMajor}
                                                backdrop
                                            />
                                        </Stack.Item>
                                        <Stack.Item fill>
                                            <a href="mailto:hello@cartmate.com">Send us an Email</a>
                                        </Stack.Item>
                                    </Stack>
                                </Stack>
                            </TextContainer>
                        </Stack>
                    }
                >
                    <View style={{
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        width: '100%'
                    }}>
                        {results
                            ?.edges
                            .filter((edge) => !edge.node.hidden)
                            .filter((edge) => edge.node.status === PricingStatus.ACTIVE)
                            .map((edge) => {
                                return (
                                    <Plan
                                        key={`plan-${edge.node.id}`}
                                        onSubscribe={onSubscribe}
                                        plan={edge.node}
                                    />
                                );
                            })
                        }
                    </View>
                </Layout.AnnotatedSection>
            </Layout>
            <FooterHelp>
                Learn more about{' '}
                <Link onClick={() => {
                    const redirect = ShopifyRedirect.create(appBridge);
                    redirect.dispatch(ShopifyRedirect.Action.APP, '/about/plan');
                }}>
                    Cartmate subscription plan
                </Link>
            </FooterHelp>
        </Page >
    );
});