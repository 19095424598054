import { animated, config, useSpring, a } from "@react-spring/native";
import { Heading, Stack, TextContainer } from "@shopify/polaris";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { View, StyleSheet, Text } from "react-native";
import { FixedSizeList, ListChildComponentProps, ListOnItemsRenderedProps } from "react-window";
import { debounce } from "ts-debounce";
import { IconCollection, IconElement } from "../../../content/elements/IconElement";
import { IconView } from "../../types";
import { IconPickerListStyle } from "./commonViewStyle";

export type FavoriteIcon = { collection: IconCollection, name: string };
export type FavoriteCollection = {
    title: string,
    iconPreview: FavoriteIcon,
    icons: FavoriteIcon[]
};

const favoritesCollections: FavoriteCollection[] = [
    {
        title: 'Home',
        iconPreview: { collection: 'material', name: 'home' },
        icons: [
            { collection: 'feather', name: 'home' },
            { collection: 'ionicons', name: 'home' },
            { collection: 'ionicons', name: 'home-outline' },
            { collection: 'ionicons', name: 'home-sharp' },
            { collection: 'material', name: 'home' },
            { collection: 'material', name: 'home-filled' },
            { collection: 'material-community', name: 'folder-home' },
            { collection: 'material-community', name: 'folder-home-outline' },
            { collection: 'material-community', name: 'home-account' },
            { collection: 'material-community', name: 'home-circle' },
            { collection: 'material-community', name: 'home-circle-outline' },
            { collection: 'material-community', name: 'home-heart' },
            { collection: 'material-community', name: 'home-roof' },
            { collection: 'material-community', name: 'home-variant-outline' },
            { collection: 'material', name: 'house' },
            { collection: 'material', name: 'storefront' },
            { collection: 'material', name: 'store' },
            { collection: 'material-community', name: 'storefront' },
        ]
    },
    {
        title: 'Cart',
        iconPreview: { collection: 'feather', name: 'shopping-cart' },
        icons: [
            { collection: 'feather', name: 'shopping-cart' },
            { collection: 'ionicons', name: 'cart' },
            { collection: 'ionicons', name: 'cart-outline' },
            { collection: 'material', name: 'add-shopping-cart' },
            { collection: 'material', name: 'shopping-cart' },
            { collection: 'material-community', name: 'cart-arrow-down' },
            { collection: 'material-community', name: 'cart-outline' },
            { collection: 'feather', name: 'shopping-bag' },
            { collection: 'material', name: 'shopping-bag' },
            { collection: 'material-community', name: 'shopping-outline' },
            { collection: 'material-community', name: 'shopping' },
            { collection: 'material', name: 'shopping-basket' },
            { collection: 'ionicons', name: 'ios-basket-outline' },
            { collection: 'ionicons', name: 'lock-closed-outline' },
            { collection: 'ionicons', name: 'lock-closed' },
        ]
    },
    {
        title: 'Collections',
        iconPreview: { collection: 'material', name: 'collections' },
        icons: [
            { collection: 'material', name: 'collections' },
            { collection: 'material', name: 'collections-bookmark' },
            { collection: 'feather', name: 'menu' },
            { collection: 'ionicons', name: 'menu' },
            { collection: 'material', name: 'menu-open' },
            { collection: 'ionicons', name: 'list' },
            { collection: 'ionicons', name: 'list-outline' },
            { collection: 'ionicons', name: 'newspaper-outline' },
            { collection: 'ionicons', name: 'newspaper' },
            { collection: 'ionicons', name: 'pricetag' },
            { collection: 'ionicons', name: 'pricetag-outline' },
            { collection: 'ionicons', name: 'reorder-four' },
            {collection: 'ionicons', name: 'reorder-four-outline'},
            {collection: 'ionicons', name: 'ios-albums-outline'},
            {collection: 'ionicons', name: 'ios-albums-sharp'},
            {collection: 'ionicons', name: 'ios-apps-outline'},
            {collection: 'ionicons', name: 'ios-apps-sharp'},
            {collection: 'ionicons', name: 'ios-apps'},
            {collection: 'ionicons', name: 'ios-bookmark-outline'},
            {collection: 'ionicons', name: 'ios-bookmark'},
            {collection: 'ionicons', name: 'ios-folder'},
            {collection: 'ionicons', name: 'ios-folder-open'},
            {collection: 'ionicons', name: 'ios-folder-open-outline'},
            {collection: 'ionicons', name: 'ios-folder-open-sharp'},
            {collection: 'ionicons', name: 'ios-folder-outline'},
            {collection: 'ionicons', name: 'ios-folder-outline'},
            {collection: 'material', name: 'view-list'},
        ]
    },
    {
        title: 'Favorites',
        iconPreview: {collection: 'material', name: 'favorite'},
        icons: [
            {collection: 'material', name: 'favorite'},
            {collection: 'material', name: 'favorite-border'},
            {collection: 'feather', name: 'star'},
            {collection: 'ionicons', name: 'star'},
            {collection: 'ionicons', name: 'star-half'},
            {collection: 'ionicons', name: 'star-half-sharp'},
            {collection: 'ionicons', name: 'star-outline'},   
            {collection: 'material', name: 'stars'},
            {collection: 'feather', name: 'heart'},
            {collection: 'ionicons', name: 'heart'},
            {collection: 'ionicons', name: 'heart-half-sharp'},
            {collection: 'material-community', name: 'heart-multiple-outline'},
            {collection: 'material-community', name: 'tag-heart'},
            {collection: 'material-community', name: 'tag-heart-outline'},    
        ]
    },
    {
        title: 'Orders history',
        iconPreview: {collection: 'material', name: 'history'},
        icons: [
            {collection: 'material', name: 'history'}
        ]
    },


];

type Props = {
    onSelect: (val: IconView) => void,
    value: IconView | null,
    onItemsRendered?: (props: ListOnItemsRenderedProps) => any,
    height?: number,
    flatIcons: FavoriteIconWrapper[]
};

const PADDING_SIZE = 10;
const ITEM_SIZE = 36;

export const FavoritesIcons = React.forwardRef<FixedSizeList, Props>((props, forwardRef) => {
    const items = buildFlatList(favoritesCollections, 5);

    console.log('FavoriteIcon', props);
    return (
        <FixedSizeList
            ref={forwardRef}
            height={props.height || IconPickerListStyle.height}
            width={"100%"}
            itemData={props}
            onItemsRendered={props.onItemsRendered}
            itemCount={props.flatIcons.length}
            itemSize={ITEM_SIZE}
        >
            {Row}
        </FixedSizeList>
    );
});

const Row = React.memo<ListChildComponentProps & { data: Props }>(({
    index,
    style: innerStyle,
    data: { flatIcons, value, onSelect },
}: ListChildComponentProps) => {
    const item: FavoriteIconWrapper = flatIcons[index];
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: config.stiff,
    })
    // console.log('Row item', item);
    return <div style={innerStyle}>
        <animated.View style={style}>
            {item.type === FavoriteListItemType.IconsGroup ?
                <IconsRow item={item} value={value} onClick={onSelect} /> :
                <TitleRow title={item.iconPackData.title} />}
        </animated.View>
    </div>
})

const TitleRow = React.memo<{ title: string }>(({ title }) => (
    <TextContainer>
        <Heading element={"h5"}>{title}</Heading>
    </TextContainer>
))

const IconsRow = React.memo<{
    item: FavoriteIconRowWrapper,
    value: IconView | null
    onClick: (val: IconView) => void
}>(({ item, onClick, value }) => {
    const handleSelectIcon = useCallback((icon: FavoriteIcon) => {
        onClick({collection: icon.collection, name: icon.name});
    }, [onClick]);
    return <View style={{ paddingHorizontal: PADDING_SIZE }}>
        <Stack distribution="fill">
            {item.icons.map(icon => {
                const isSelected = value?.collection === icon.collection && value?.name === icon.name;
                return <Stack.Item key={icon.collection + icon.name}>
                    <div className={"icon-picker-list-item " + (isSelected ? "selected" : "")} onClickCapture={() => handleSelectIcon(icon)}>
                        <IconElement collection={icon.collection} name={icon.name} size={20} />
                    </div>
                </Stack.Item>
            })}
        </Stack>
    </View>
})


export enum FavoriteListItemType {
    IconTitle,
    IconsGroup
}

export type FavoriteIconWrapper = FavoriteIconRowWrapper | FavoriteIconCollectionTitleWrapper

export type FavoriteIconRowWrapper = {
    type: FavoriteListItemType.IconsGroup
    iconPackData: FavoriteCollection
    icons: FavoriteIcon[]
}

export type FavoriteIconCollectionTitleWrapper = {
    type: FavoriteListItemType.IconTitle
    iconPackData: FavoriteCollection
    flatIndexStart: number
    flatIndexEnd: number
}

export function buildFlatList(icons: FavoriteCollection[], itemsPerGroup: number, search: string | null = null) {
    const collections: FavoriteIconCollectionTitleWrapper[] = [];
    const trimmedSearch = search === null ? null : search.trim().toLowerCase();
    console.log('Build fav', trimmedSearch);
    const flatItems = icons.reduce<FavoriteIconWrapper[]>((flat, iconPackData) => {
        let iconsKeys: FavoriteIcon[] = iconPackData.icons;
        if (trimmedSearch !== null) {
            iconsKeys = iconPackData.icons.filter((key) => key.name.toLowerCase().includes(trimmedSearch));
        }
        if (iconsKeys.length) {
            const groupCount = Math.ceil(iconsKeys.length / itemsPerGroup);
            const collection: FavoriteIconCollectionTitleWrapper = {
                type: FavoriteListItemType.IconTitle,
                iconPackData,
                flatIndexStart: flat.length,
                flatIndexEnd: flat.length + groupCount + 1,
            }
            flat.push(collection);
            collections.push(collection);
            let group = 0;
            while (group < groupCount) {
                const startIndex = group * itemsPerGroup
                const endIndex = Math.min((group + 1) * itemsPerGroup, iconsKeys.length)
                flat.push({
                    type: FavoriteListItemType.IconsGroup,
                    iconPackData,
                    icons: iconsKeys.slice(startIndex, endIndex),
                })

                group++
            }
        }
        return flat;
    }, []);
    return { flatItems, collections }
}

export function useFavoriteIconsFlat(itemsPerGroup: number) {
    const [isLoading, setIsLoading] = useState(true)
    const [items, setItems] = useState<FavoriteIconWrapper[]>([]);
    const [collections, setCollections] = useState<FavoriteIconCollectionTitleWrapper[]>([]);
    const [rawIcons, setRawIcons] = useState<FavoriteCollection[]>([]);

    useEffect(() => {
        const flatList = buildFlatList(favoritesCollections, itemsPerGroup);
        setRawIcons(favoritesCollections);
        setItems(flatList.flatItems);
        setCollections(flatList.collections);
        setIsLoading(false)
    }, [itemsPerGroup])
    return [items, collections, rawIcons, isLoading] as const;
}


type FilteredViewProps = {
    icons: FavoriteCollection[]
    onSelect: (val: IconView) => void
    value: IconView | null
    search: string | null,
    height?: number
}
const style = StyleSheet.create({
    wrapper: {
        position: 'relative', 
        overflow: 'hidden', 
        // maxHeight: '300px', 
        width: 250, 
        // height: 250,
        minHeight: 100,
    },
    searchBody: {
        position: 'absolute',
        backgroundColor: '#fff',
        overflow: 'hidden',
        bottom: 0,
        borderRadius: 4,
        width: '100%'
    },
    noItems: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    }
})
export const FavoriteFilteredIconsView = React.memo<React.PropsWithChildren<FilteredViewProps>>(
    ({icons, onSelect, value, search, children, height}) => {
        const [items, setItems] = useState<FavoriteIconWrapper[] | null>(null)

        const styleEnter = useSpring({
            from: {opacity: 0},
            to: items ? {opacity: 1} : {opacity: 0},
        })

        const debFilter = useCallback(debounce((
            icons: FavoriteCollection[],
            search: string | null,
            setItems: (flatIcons: FavoriteIconWrapper[] | null) => void) => {

            if (search === null || !search.trim()) {
                console.log('FAV set null');
                setItems(null)
            } else {
                setItems(buildFlatList(icons, 5, search).flatItems)
            }
        }, 300), [])

        useEffect(() => {
            debFilter(icons, search, setItems)
        }, [icons, search])

        const renderItems = useMemo(() => items || [], [items])

        const hasItems = !!renderItems.length

        return (
            <View style={[style.wrapper]}>
                {children}
                {search &&<a.View style={[style.searchBody, styleEnter]}>
                    {hasItems && <FavoritesIcons height={height} flatIcons={renderItems} onSelect={onSelect} value={value}/>}
                    {!hasItems && <View style={[style.noItems, {height}]}><Text>No items</Text></View>}
                </a.View>}
            </View>
        )
    })