import Color from 'color';
import React, { } from 'react';
import { useState } from "react";
import { Text } from "react-native";
import { AppEmulatorConfig } from '../types';
import { useEmulatorConfig } from './ScalableEmulatorComponent';

const arrow = require('../../../assets/images/ios-arrow.svg');
export const ExploreEmulatorCardScreen = React.memo((props: {
    config: AppEmulatorConfig,
    bgl: string,
    bgd: string,
    selectedPlatform: string,
    title: string, image: string, description: string,
    isAppCard?: boolean
}) => {
    const [hoveredOn, setHoveredOn] = useState(false);
    const emulator = useEmulatorConfig();

    let primaryText;
    let secondaryText;

    if (props.bgl === '#fff') {
        primaryText = props.config.design.colors.light.primaryText;
        secondaryText = props.config.design.colors.light.secondaryText;
    } else {
        primaryText = props.config.design.colors.dark.primaryText;
        secondaryText = props.config.design.colors.dark.secondaryText;
    }

    const [opened, setOpened] = useState<boolean>(false)


    let image
    let imageBlock
    if (props.image.length == 0) {
        imageBlock = <div className="ios__content-item-img" style={{
            width: `${emulator.isSmall ? '100px' : '120px'}`,
            height: `${emulator.isSmall ? '100px' : '120px'}`,
            color: '#000',
            flexShrink: 0
        }}>
            <div className="default-img" style={{
                width: `${emulator.isSmall ? '100px' : '120px'}`,
                height: `${emulator.isSmall ? '100px' : '120px'}`,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: '#b7b7b7',
                borderRadius: '30px',
                fontSize: `${emulator.fontSize}px`,
                fontWeight: 500,
                lineHeight: '34px',
                flexShrink: 0
            }}>
                YOUR<br />
                SHOP<br />
                LOGO<br />
            </div>
        </div>
    } else {
        image = props.image
        imageBlock = <div className="ios__content-item-img" style={{
            background: `#fff url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            borderRadius: '30px',
            width: `${emulator.isSmall ? '100px' : '120px'}`,
            height: `${emulator.isSmall ? '100px' : '120px'}`,
            flexShrink: 0
        }}></div>
    }

    let title
    if (props.title.length == 0) {
        title = 'Enter your text as title'
    } else {
        title = props.title
    }

    let description
    if (props.description.length == 0) {
        description = 'See how your shop description will look'
    } else {
        description = props.description
    }

    return <>
        <div
            className="ios__content-item"
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                // backgroundColor: props.backGround,
                backgroundColor: props.bgd,
                padding: `${emulator.explorePadding}px`,
                borderRadius: '30px',
                cursor: 'pointer',
                boxShadow: props.isAppCard && hoveredOn
                    ? `0px 0px 20px ${emulator.explorePadding / 2}px #00A5FE`
                    : undefined,
                // borderWidth: props.isAppCard && !hoveredOn ? 2 : 0,
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: props.isAppCard && !hoveredOn ? '#00A5FE' : 'transparent',
                width: `${emulator.isSmall ? '480px' : '632px'}`

            }}
            onClick={() => setOpened(true)}
            onMouseOver={() => setHoveredOn(true)}
            onMouseLeave={() => setHoveredOn(false)}
        >
            {imageBlock}
            <div className="ios__content-item-info" style={{
                width: `${emulator.infoWidth}px`,
                margin: `0 ${emulator.explorePadding}px`
            }}>
                <div className="ios__content-item-title" style={{
                    fontSize: `${emulator.isSmall ? '25px' : '32px'}`,
                    // color: '#000',
                    color: primaryText,
                    fontWeight: 700,
                    marginBottom: '5px',
                    width: `${emulator.infoWidth}px`
                }}>
                    <Text
                        numberOfLines={2}
                        ellipsizeMode={"tail"}
                        style={{
                            fontSize: emulator.isSmall ? 25 : 30,
                            // color: '#000',
                            color: primaryText,
                            fontWeight: '600',
                            lineHeight: 30
                        }}
                    >
                        {title}
                    </Text>
                </div>
                <div className="ios__content-item-text">
                    <Text
                        numberOfLines={2}
                        ellipsizeMode={"tail"}
                        style={{
                            fontSize: emulator.isSmall ? 22 : 24,
                            color: secondaryText,
                            lineHeight: 30,
                            fontWeight: '500'
                        }}
                    >
                        {description}
                    </Text>
                </div>
            </div>
            <div className="ios__content-item-button" style={{
                backgroundColor: '#2e96f7',
                padding: '1px 30px',
                borderRadius: '30px',
            }}>
                <Text
                    numberOfLines={2}
                    ellipsizeMode={"tail"}
                    style={{
                        fontSize: emulator.isSmall ? 22 : 24,
                        color: secondaryText,
                        lineHeight: 30,
                        fontWeight: '700'
                    }}
                >
                    ADD
                </Text>
            </div>
        </div>
    </>
})