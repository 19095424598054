import { TextStyle } from "@shopify/polaris";
import React from "react";
import { View } from "react-native";
import { useClient } from "../../../api/useClient";
import { IconElement } from "../../../content/elements/IconElement";
import { Screen } from "../../../api/spacex.types";

export const CollectionsPreview = React.memo(() => {
    const client = useClient();
    const screenSettings = client.useScreenSettings({ screen: Screen.COLLECTIONS }).screenSettings;
    const collectionsIds = JSON.parse(screenSettings.settings).collections as string[];
    let collectionsRes = client.useCollections();
    let collections = collectionsRes.collections;
    if (collectionsIds && collectionsIds.length > 0) {
        console.log('[CollectionsPreview] collectionsIds', collectionsIds);
        collections = [];
        collectionsIds.forEach((id) => {
            const item = collectionsRes.collections.find((c) => c?.id === id);
            if (item) {
                collections.push(item);
            }
        });
        console.log('[CollectionsPreview] collections', collections.map((c) => c?.id));
    }
    console.log('[CollectionsPreview]', collections);

    return (
        <View style={{ flex: 1 }}>
            {collections.map((collection) => {
                return (
                    <View
                        key={`collection-${collection?.id}`}
                        style={{
                            flexDirection: 'row', justifyContent: 'space-between',
                            paddingHorizontal: 16, marginVertical: 16
                        }}
                    >
                        <TextStyle>{collection?.title}</TextStyle>
                        <IconElement
                            name={'chevron-forward'}
                            collection={'ionicons'}
                            color={'#000'}
                            size={24}
                        />
                    </View>
                );
            })}
        </View>
    );
});