import React, { useEffect, useState } from 'react'
import { Knob } from '../Knob';
import { TextField } from '@shopify/polaris'

export const KnobNumber = React.memo<Knob.KnobComponentProps<Knob.KnobNumberDescriptor>>(
    ({knob, value: initValue, onChange, innerKey}) => {
        const {description, min, max, step} = knob
        const [value, setValue] = useState((initValue || 0).toString())

        useEffect(() => {
            const newValue = parseInt(value)
            if (newValue !== initValue) {
                onChange(newValue, innerKey)
            }

        }, [value, knob, innerKey])

        return <TextField
            label={description}
            type="number"
            value={value}
            min={min}
            max={max}
            step={step}
            onChange={setValue}
        />
    })