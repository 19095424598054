import { RangeSlider, Stack, TextField } from "@shopify/polaris"
import React, { useEffect, useState } from "react"
import { Knob } from "../Knob"

export const KnobRange = React.memo<Knob.KnobComponentProps<Knob.KnobSingleRangeDescriptor>>(
    ({ knob, value: initValue, onChange, innerKey }) => {
        const { description, min, max, step } = knob
        const [value, setValue] = useState((initValue || 0));

        useEffect(() => {
            if (value !== initValue) {
                onChange(value, innerKey)
            }
        }, [value, knob, innerKey])

        return <div>
            <TextField
                type={'number'}
                min={min}
                max={max}
                step={step}
                label={description}
                value={value.toString()}
                onChange={(value) => setValue(Number(value))}
            />
            <div style={{ marginTop: '10px' }}>
                <RangeSlider
                    label={''}
                    value={value}
                    min={min}
                    max={max}
                    step={step}
                    onChange={(value) => setValue((typeof value === 'number') ? value : 0)}
                />
            </div>
        </div>
    })