import React, { useCallback, useEffect, useState } from 'react'
import { Page, Layout, Card, TextField, FormLayout } from '@shopify/polaris';
import { useClient } from '../api/useClient';
import { useValidatePageName } from '../content/contentPage/editPageUtils';
import { useHistory } from 'react-router';

export const AppNew = React.memo(() => {
    const client = useClient()
    const [name, setName] = useState('')
    const [errorMsg, clean, verifyName] = useValidatePageName(name)
    const history = useHistory()

    useEffect(clean, [name])

    const onSubmit = useCallback(() => {
        if (verifyName()) {
            client.mutateCreateApp({
                name,
                repeatKey: Date.now().toString()
            }).then(app => {
                history.replace(`/apps/${app.appCreate.id}`)
            })
        }

    }, [name, verifyName])

    return (
        <Page title='Create new application' breadcrumbs={[{content: 'Apps', url: '/apps'}]}
              primaryAction={{content: 'Create', onAction: onSubmit}}>
            <Layout>
                <Layout.AnnotatedSection title={'Application params'}>
                    <Card sectioned>
                        <FormLayout>
                            <TextField label='Name' type={'text'} value={name} onChange={setName} error={errorMsg || false}/>
                        </FormLayout>
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>
        </Page>
    )
})