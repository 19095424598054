import { Layout, Page } from "@shopify/polaris"
import React from "react"
import { useHistory } from "react-router";

export const Plan = React.memo(() => {
    const history = useHistory();

    return (
        <Page
            title={'Cartmate plans'}
            breadcrumbs={[{
                content: 'About',
                onAction: () => { history.length > 2 ? history.goBack() : history.push({ pathname: '/about' }) }
            }]
            }>
            <Layout>
                <Layout.Section>
                    
                </Layout.Section>
            </Layout>
        </Page>
    )
});