import React, {PropsWithChildren} from "react";

export interface IOSScreenProps {}

export const IOSScreen = React.memo<PropsWithChildren<IOSScreenProps>>(({children})=>{
    return (
        <div
            className={"ios__screen"}
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                overflow: "hidden",
            }}
        >
            {children}
        </div>
    );
});