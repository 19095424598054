import { Layout, Banner, Card, FormLayout, TextField, Button } from "@shopify/polaris";
import React, { useState } from "react";
import { useClient } from "../../../api/useClient";

export const GooglePlayForm = React.memo(() => {
    const client = useClient();
    const settings = client.useSettings();

    const [id, setId] = React.useState<string>(settings.storeConnections.googlePlay ? settings.storeConnections.googlePlay.id : '');
    const [credentials, setCredentials] = React.useState<string>(settings.storeConnections.googlePlay ? settings.storeConnections.googlePlay.credentials : '');
    const [googlePlayConnected, setGooglePlayConnected] = React.useState<boolean>(settings.checkGooglePlayConnection);
    const [dirty, setdirty] = useState(false);

    return (
        <Layout.AnnotatedSection
            title="GooglePlay Store"
            // description="Appstore connection parameters"
            description={
                <Banner
                    title={"Connected status"}
                    status={googlePlayConnected ? "success" : "critical"}
                >
                    <p>{googlePlayConnected ? "Connected 🥳" : "Not connected ☠️"}</p>
                </Banner>
            }
        >

            <Card sectioned>
                <FormLayout>
                    <TextField label="ID" onChange={(value) => {
                        setId(value);
                        setdirty(true);
                    }} disabled={false} value={id} />
                    <TextField label="Credentials" onChange={(value) => {
                        setCredentials(value);
                        setdirty(true);
                    }} disabled={false} value={credentials} />
                    <Button disabled={!dirty} primary>Update GooglePlay connection</Button>
                </FormLayout>
            </Card>
        </Layout.AnnotatedSection>
    )
});