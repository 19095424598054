import { useAppBridge } from "@shopify/app-bridge-react";
import React, { useCallback, useEffect, useState } from "react";
import { Redirect, useRouteMatch } from "react-router";
import { AccountStatus } from "../api/spacex.types";
import { useClient } from "../api/useClient";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { Loader } from "../components/Loader";
import { useAccount } from "../configure/utils/useAccount";
import { isInIframe } from "../utils/isInIFrame";

export const BillingCreate = React.memo(() => {

    const client = useClient();

    const account = useAccount();

    const pricing = client.useDefaultPricing().defaultPricing;

    const appBridge = useAppBridge();

    const match = useRouteMatch();

    useEffect(() => {
        if (!isInIframe()) { // TODO: Fix me! Dirty hack!
            console.log('Not in IFrame');
            return;
        }
        if (!account.pricing.chargeId) {
            client.mutateSubscribeToPricing({ id: account.id, pricingId: pricing.id, returnUrl: match.url }).then((result) => {
                console.log('Subscriiibe result', result);
                const redirect = ShopifyRedirect.create(appBridge);
                if (result.subscribeToPricing) {
                    redirect.dispatch(ShopifyRedirect.Action.REMOTE, result.subscribeToPricing);
                }
                console.log('[BillingCreate] Redirect to billing: ', account, result.subscribeToPricing);
            }).catch((e) => {
                console.warn(e);
            });
            console.log("Subscribe link", pricing.id, match);
        } else {
            console.log('Charge exists', account.pricing.chargeId);
        }
    }, [account]);

    console.log('[BillingCreate]', account.pricing, account.pricing.chargeId);

    if (account.pricing.chargeId) {
        console.log('[BillingCreate] Redirect to create account: ', account);
        return <Redirect to={{ pathname: '/create-account' }} />
    }
    return (<Loader />);
});