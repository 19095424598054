import { Badge, Card, Layout, Page, Stack, TextStyle } from "@shopify/polaris";
import React, { } from "react";
import { Switch, useHistory, useRouteMatch } from "react-router";
import { useClient } from "../../../api/useClient";
import { SkeletonSuspense, SkeletonType } from "../../../components/Skeleton/SkeletonSuspense";
import { AccountRequireRoute } from "../../../routing/AccountRequireRoute";
import { ActiveAdListingItems } from "../components/ActiveAdListingItems";
import { PositionsCard } from "../components/PositionsCard";
import { RequestsCard } from "../components/RequestsCard";
import { IncomingAdListings } from "./IncomingAdListings";
import { MyAdListings } from "./MyAdListings";

export const TrafficExchangeListing = React.memo(() => {
    const match = useRouteMatch();
    return (
        <Switch>
            <AccountRequireRoute path={`${match.path}/positions`} component={MyAdListings} />
            <AccountRequireRoute path={`${match.path}/incoming-listings`} component={IncomingAdListings} />
            <AccountRequireRoute path={match.path}>
                <Page
                    title={'Exchange Traffic positions'}
                    breadcrumbs={[{ content: 'Promote', url: '/promote' }]}
                >
                    <Layout>
                        <Layout.Section>
                            <SkeletonSuspense type={SkeletonType.Card}>
                                <ActiveAdListingItems />
                            </SkeletonSuspense>
                        </Layout.Section>
                        <Layout.Section oneHalf>
                            <SkeletonSuspense type={SkeletonType.Card}>
                                <PositionsCard />
                            </SkeletonSuspense>
                        </Layout.Section>
                        <Layout.Section oneHalf>
                            <SkeletonSuspense type={SkeletonType.Card}>
                                <RequestsCard />
                            </SkeletonSuspense>
                        </Layout.Section>
                    </Layout>
                </Page>
            </AccountRequireRoute>
        </Switch>
    );
});