import React from 'react';
import {Redirect, RouteProps} from 'react-router';
import {useClient} from '../api/useClient';
import {AccountPricingStatus, AccountStatus} from '../api/spacex.types';
import {Route} from './routing';

export const AccountRequireRoute = React.memo<RouteProps>((props) => {
    const client = useClient()
    const account = client.useAccountWrapper().account;    
    if (account.status === AccountStatus.ACTIVE) {
        console.log('REDIRECT TO SUBSCRIPTION', account, props.path);
        if (props.path === '/billing/apply') {
            return (<Route {...props}/>);
        }
        // if (!account.pricing.chargeId || account.pricing.status === AccountPricingStatus.TRIAL) {
        //     console.log('Reditect to billing STATUS');
        //     return (<Redirect to={{pathname: '/billing/create',state: {from: props.location}} } />);
        // }
        if (!account.survey) {        
            console.log('Reditect to create SURVEY');
            return (<Redirect to={{pathname: '/create-account', state: {from: props.location}}}/>);
        }                
        if (account.pricing.hasAccess) {        // TODO: fix
            console.log('Reditect to ROUTE');
            return (<Route {...props}/>);
        }
        
        return (<Redirect to={{pathname: '/subscription', state: {from: props.location}}}/>);
    } else {
        if (!account.pricing.chargeId) {
            console.log('Reditect to billing ACCOUNT STATUS');
            return (<Redirect to={{pathname: '/billing/create',state: {from: props.location}} } />);
        }
        console.log('Reditect to create ACCOUNT STATUS');
        return (<Redirect to={{pathname: '/create-account', state: {from: props.location}}}/>);
    }
})