import { Icon, Stack } from "@shopify/polaris";
import { BuyButtonMajor, OrderStatusMinor } from "@shopify/polaris-icons";
import React from "react";
import { ImageResizeMode } from "react-native";
import { IconWrapper } from "../../components/Icon/IconView/Icons";
import {
    ContentBlurhash,
    ContentBlurView,
    ContentImage,
    ContentPressableNavigator,
    ContentText,
    ContentView,
} from '../../content/components';
import { mapTextAlign } from "../../utils/textUtils";
import { defaultValueNav } from "../knobs/defults";
import { Knob } from "../knobs/Knob";
import { TemplateWithVariables } from "../knobs/TemplateWithVariables";
import { FontWeightType, TextAlignType } from "../knobs/types";
import { ConfigBlock } from "./BlockTypes";
import { DafaultBlockValues } from "./defaults";

const BannerVariables = {
    bannerTitle: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: 'Caption sample text',
            textAlign: TextAlignType.Start,
            textColor: '#000',
            textShadow: false,
            showCaption: true,
            fontWeight: FontWeightType.Normal
        },
        description: 'Enter caption',
        disableAlpha: true,
    },
    border: {
        type: 'border' as const,
        defaultValue: {
            border: 1,
            color: '#676767',
            showBorder: false,
        },
        description: 'Border settings',
        min: 0,
        max: 5,
        step: 1,
        disableAlpha: true,
        isAdvanced: true
    },
    bannerBackground: {
        type: 'blurhash_file' as const,
        defaultValue: { src: DafaultBlockValues.backgroundUrl, blurhash: null, resizeMode: null },
        description: 'Background image',
        clearable: true,
    },
    bannerHeight: {
        type: 'range' as const,
        defaultValue: DafaultBlockValues.height,
        description: 'Height',
        min: 100,
        max: 1000,
    },
    borderRadius: {
        type: 'range' as const,
        defaultValue: DafaultBlockValues.borderRadius,
        description: 'Border radius',
        min: 0,
        max: 100,
        isAdvanced: true
    },
    url: {
        type: 'navigation_picker' as const,
        description: 'Navigation Link',
        defaultValue: '',
    },
    // showShadow: {
    //     type: 'boolean' as const,
    //     defaultValue: false,
    //     description: 'Enable banner shadow',
    //     isAdvanced: true,
    // },
    blurIntencity: {
        type: 'range' as const,
        defaultValue: 50,
        description: 'Captions background intencity',
        min: 0,
        max: 100
    },
    backgroundColor: {
        type: 'color' as const,
        defaultValue: DafaultBlockValues.color,
        description: 'Banner background color',
        disableAlpha: false,
        isAdvanced: true,
    },
    marginCoordinates: {
        type: 'coordinates' as const,
        defaultValue: { left: 10, right: 10, top: 8, bottom: 0 },
        description: 'margin',
        min: 0,
        isAdvanced: true,
    },
    paddingCoordinates: {
        type: 'coordinates' as const,
        defaultValue: { left: 0, right: 0, top: 0, bottom: 0 },
        description: 'padding',
        min: 0,
        isAdvanced: true,
    },
}

const Banner = TemplateWithVariables(BannerVariables, ({
    bannerHeight,
    borderRadius,
    url, bannerTitle, blurIntencity,
    bannerBackground, backgroundColor,
    // showShadow, 
    marginCoordinates, paddingCoordinates, border
}) => {
    return (
        <ContentView
            style={{
                height: bannerHeight,
                flexDirection: 'row',
                borderRadius: borderRadius,
                paddingLeft: paddingCoordinates.left,
                paddingRight: paddingCoordinates.right,
                paddingTop: paddingCoordinates.top,
                paddingBottom: paddingCoordinates.bottom,
                marginBottom: marginCoordinates.bottom,
                marginTop: marginCoordinates.top,
                marginRight: marginCoordinates.right,
                marginLeft: marginCoordinates.left,
                // shadowOpacity: showShadow ? 0.5 : undefined,
                // shadowRadius: showShadow ? 6 : undefined,
                backgroundColor: backgroundColor,
                borderWidth: border.showBorder ? border.border : 0,
                borderColor: border.color,
                overflow: 'hidden'
            }}
        >
            <ContentView style={{ flexGrow: 1 }}>
                {bannerBackground && bannerBackground.blurhash && (
                    <ContentBlurhash
                        blurhash={bannerBackground.blurhash!}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            alignSelf: 'flex-start',
                        }}
                        decodeHeight={16}
                        decodeWidth={16}
                        decodeAsync={true}
                    />
                )}
                <ContentImage
                    resizeMode={(
                        bannerBackground.resizeMode
                        && bannerBackground.resizeMode !== null
                    )
                        ? bannerBackground.resizeMode || 'cover'
                        : 'cover'
                    }
                    source={{ uri: bannerBackground.src as string }}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        alignSelf: 'flex-start',
                    }}
                />
                {bannerTitle.showCaption && (bannerTitle.value.length > 0) && (
                    <ContentBlurView
                        style={{
                            flexGrow: 1,
                            height: 68,
                            alignItems: bannerTitle.textAlign,
                            justifyContent: 'center',
                            paddingTop: 8,
                            paddingBottom: 8,
                            paddingLeft: 16,
                            paddingRight: 16,
                            position: 'absolute', left: 0, right: 0, bottom: 0,
                            overflow: 'hidden'
                        }}
                        tint={'dark'}
                        intensity={blurIntencity}
                    >
                        <ContentText
                            style={{
                                color: bannerTitle.textColor,
                                fontSize: 16,
                                fontWeight: bannerTitle.fontWeight,
                                textAlign: mapTextAlign(bannerTitle.textAlign)
                            }}
                            numberOfLines={2}
                            ellipsizeMode={'tail'}
                        >
                            {bannerTitle.value}
                        </ContentText>
                    </ContentBlurView>
                )}
                <ContentPressableNavigator route={url || ''} borderRadius={borderRadius} />
            </ContentView>
        </ContentView>
    )
});

export default {
    Preview: () => <Stack alignment="center" distribution="leading" spacing={'loose'}>
        <Stack.Item fill={false}>
            <IconWrapper>
                <Icon source={BuyButtonMajor} />
            </IconWrapper>
        </Stack.Item>
        <Stack.Item fill>
            Banner
        </Stack.Item>
    </Stack>,
    RenderBlock: Banner,
    Icon: BuyButtonMajor,
    Name: 'Banner'
} as ConfigBlock