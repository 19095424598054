import React from 'react'
import { Knob } from './Knob';

export class KnobContextMediator<T extends Knob.Map = Knob.Map> {
    static makeDefaultValue<T extends Knob.Map = Knob.Map>(descr: T): Knob.Value<T> {
        return Object.keys(descr).reduce<Knob.Value<T>>((sum: any, key) => {
            sum[key] = descr[key].defaultValue
            return sum
        }, {} as any)
    }

    private descr: T | null = null

    constructor(private sideEffect: Knob.SideEffect<T> = {}) {
    }

    setup(descr: T): Knob.Value<T> {
        this.descr = descr

        this.sideEffect = Object.keys(descr).reduce<Knob.Value<T>>((sum: any, key) => {
            const descrElement = descr[key];
            if (sum[key] === undefined && descrElement) {
                sum[key] = descrElement.defaultValue
            }
            return sum
        }, this.sideEffect as any)


        return this.sideEffect as Knob.Value<T>
    }

    getKnobsState(): Knob.SideEffect<T> {
        return this.sideEffect
    }

    getDescriptor(): T | null {
        return this.descr
    }
}

export const KnobContext = React.createContext<KnobContextMediator>(new KnobContextMediator())

type Props = {
    collector: KnobContextMediator
}

export const KnobCollection = React.memo<React.PropsWithChildren<Props>>(({collector, children}) => (
    <KnobContext.Provider value={collector}>
        {children}
    </KnobContext.Provider>
))