import { SkeletonBodyText, Stack, TextStyle, Thumbnail } from "@shopify/polaris";
import React from "react";
import { AdListingType } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";

export const ListingItemContentPreview = React.memo(({ id, type }: { id?: string, type?: AdListingType }) => {
    
    if (!type || !id) {
        return <SkeletonBodyText />
    }

    const client = useClient();
    const content = client.usePreviewListingContent({ id: id }).previewListingContent;

    return (
        <Stack vertical>
            <p>
                Type:
                <TextStyle variation={'strong'}>
                    {` ${type}`}
                </TextStyle>
            </p>
            <Stack>
                {content && content.image?.src && (
                    <Stack.Item>
                        <Thumbnail
                            alt={'content-thumbnail'}
                            size={'medium'}
                            source={content.image?.src}
                        />
                    </Stack.Item>
                )}
                <Stack.Item>
                    <Stack vertical>
                        <p>
                            <TextStyle variation={'strong'}>
                                {content.title}
                            </TextStyle>
                        </p>
                        <p>
                            <TextStyle variation={'subdued'}>
                                {content.description}
                            </TextStyle>
                        </p>
                    </Stack>
                </Stack.Item>
            </Stack>
        </Stack>
    );
});