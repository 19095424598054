import { AccountPricingStatus } from "../../api/spacex.types";
import { PublicationStatus } from "./pages/TrafficExchangeProfile";

export enum PromoteStatusType {
    Active = 'Active',
    Limited = 'Limited',
    Inactive = 'Inactive'
}

export enum PromoteFormError {
    Title = 'Title is required',
    Description = 'Description is required',
    Icon = 'Icon is required',
    Industires = 'Please, select at least one industry you operate in'
};

export const PromoteStatuses = {
    Active: {
        type: PromoteStatusType.Active,
        message: 'You’re all set! Your customers can see the Cartmate Exchange list and you ARE getting traffic from other stores.'
    },
    Limited: {
        type: PromoteStatusType.Limited,
        message: 'Your customers can see the Cartmate Exchange list, but you are NOT getting traffic from other stores. Submit form and pass moderation to get traffic to your store.'
    },
    Inactive: {
        type: PromoteStatusType.Inactive,
        message: 'Your customers can’t see the Cartmate Exchange list and you are NOT getting traffic from other stores. Enable \'Find more stores\' button, submit form and pass moderation to get traffic to your store.'
    },
    Default: {
        type: PromoteStatusType.Limited,
        message: 'Your customers can see the Cartmate Exchange list, but you are NOT getting traffic from other stores. Submit form and pass moderation to get traffic to your store.'
    },
}

export function mapStatusDescription(pricingStatus: AccountPricingStatus, active: boolean, publicationStatus?: PublicationStatus) {
    let freeTrafficStatus = PromoteStatuses.Default.type;
    let freeTrafficStatusDescr = PromoteStatuses.Default.message;
    if (pricingStatus === AccountPricingStatus.ACTIVE) {
        if (active && publicationStatus === PublicationStatus.Published) {
            freeTrafficStatusDescr = PromoteStatuses.Active.message;
            freeTrafficStatus = PromoteStatuses.Active.type;
        } else if (active && publicationStatus !== PublicationStatus.Published) {
            freeTrafficStatusDescr = PromoteStatuses.Limited.message;
            freeTrafficStatus = PromoteStatuses.Limited.type;
        } else if (!active && publicationStatus !== PublicationStatus.Published) {
            freeTrafficStatusDescr = PromoteStatuses.Inactive.message;
            freeTrafficStatus = PromoteStatuses.Inactive.type;
        } else if (!active && publicationStatus === PublicationStatus.Published) {
            freeTrafficStatusDescr = PromoteStatuses.Inactive.message;
            freeTrafficStatus = PromoteStatuses.Inactive.type;
        }
    } else {
        if (!active) {
            freeTrafficStatusDescr = PromoteStatuses.Inactive.message;
            freeTrafficStatus = PromoteStatuses.Inactive.type;
        } else {
            freeTrafficStatusDescr = PromoteStatuses.Limited.message;
            freeTrafficStatus = PromoteStatuses.Limited.type;
        }
    }

    return [freeTrafficStatus, freeTrafficStatusDescr];
}