import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { TypographyStyle, TypographyWeightsStyle } from '../../../components/Typography/commonTypography';
import { FormSelect } from '../../../components/Form/Components/FormSelect';
import { SelectOption } from '../../../components/StyledSelect/StyledSelect';
import { Heading, TextContainer } from '@shopify/polaris';
import { useFormContext } from 'react-hook-form';

const options: SelectOption<string>[] = [
    { value: '1', label: 'Team: 1-5 people' },
    { value: '2', label: 'Organization: 5-10 people' },
    { value: '3', label: 'Enterprise: over 10 people' },
    { value: '4', label: 'Skip' },
]

const style = StyleSheet.create({
    title: {
        marginBottom: 8
    },
    caption: {
        marginBottom: 8
    },
    captionError: {
        color: 'red'
    },
});

const validate = (data?: string) => !!data?.length;

export const WhatIsYouTeamSize = React.memo(() => {
    const context = useFormContext<{ teamSize: string }>();
    const error = context.errors['teamSize'];
    return (
        <View>
            <View style={{ marginBottom: 8 }}>
                <TextContainer>
                    <Heading>
                        🏠 &nbsp; What is you current team size?
                    </Heading>
                </TextContainer>
            </View>
            <FormSelect
                name={'teamSize'}
                rules={{ ...{ validate }, ...{ required: true } }}
                isSearchable={true}
                placeholder={'Team size'}
                options={options}
            />
            <Text style={[TypographyStyle.caption, style.caption, error && style.captionError]}>
                {'This field is required'}
            </Text>
        </View>
    );
});