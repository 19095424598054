import { Icon, Stack } from "@shopify/polaris";
import { BuyButtonMajor } from "@shopify/polaris-icons";
import React from "react";
import { View } from "react-native";
import { IconWrapper } from "../../components/Icon/IconView/Icons";
import {
    ContentBlurhash,
    ContentBlurView,
    ContentImage,
    ContentPressableNavigator,
    ContentText,
    ContentTouchableNoFeedback,
    ContentView,
} from '../../content/components';
import { mapTextAlign } from "../../utils/textUtils";
import { defaultValueNav } from "../knobs/defults";
import { TemplateWithVariables } from "../knobs/TemplateWithVariables";
import { FontWeightType, TextAlignType } from "../knobs/types";
import { ConfigBlock } from "./BlockTypes";
import { DafaultBlockValues } from "./defaults";

const Variables = {
    bannerTitle1: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: 'Caption sample text',
            textAlign: TextAlignType.Start,
            textColor: '#000',
            textShadow: false,
            showCaption: true,
            fontWeight: FontWeightType.Normal
        },
        description: 'Caption',
        disableAlpha: true,
    },
    bannerTitle2: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: 'Caption sample text',
            textAlign: TextAlignType.Start,
            textColor: '#000',
            textShadow: false,
            showCaption: true,
            fontWeight: FontWeightType.Normal
        },
        description: 'Caption',
        disableAlpha: true,
    },
    height1: {
        type: 'range' as const,
        defaultValue: 100,
        description: 'Blocks height',
        min: 100,
        max: 1000
    },
    url1: {
        type: 'navigation_picker' as const,
        description: 'Button #1 Navigation Link',
        defaultValue: '',
    },
    url2: {
        type: 'navigation_picker' as const,
        description: 'Button #2 Navigation Link',
        defaultValue: '',
    },
    file1: {
        type: 'blurhash_file' as const,
        defaultValue: { src: '', blurhash: null, resizeMode: null },
        description: 'Button #1 image',
        clearable: true,
    },
    file2: {
        type: 'blurhash_file' as const,
        defaultValue: { src: '', blurhash: null, resizeMode: null },
        description: 'Button #2 image',
        clearable: true,
    },
    borderRadius: {
        type: 'range' as const,
        defaultValue: 16,
        description: 'Border radius',
        min: 0,
        max: 100,
        isAdvanced: true
    },
    showShadow: {
        type: 'boolean' as const,
        defaultValue: false,
        description: 'Show shadow',
        isAdvanced: true
    },
    viewsMargins: {
        type: 'range' as const,
        defaultValue: 16,
        description: 'Margins between banners',
        min: 0,
        max: 100,
        isAdvanced: true,
    },
    marginCoordinates: {
        type: 'coordinates' as const,
        defaultValue: { left: 10, right: 10, top: 8, bottom: 0 },
        description: 'margin',
        min: 0,
        isAdvanced: true,
    },
    paddingCoordinates: {
        type: 'coordinates' as const,
        defaultValue: { left: 0, right: 0, top: 0, bottom: 0 },
        description: 'padding',
        min: 0,
        isAdvanced: true,
    },
    blurIntencity: {
        type: 'range' as const,
        defaultValue: 50,
        description: 'Captions background intencity',
        min: 0,
        max: 100
    },
}

const TwoButtons = TemplateWithVariables(Variables, ({
    height1,
    borderRadius,
    url1, url2,
    bannerTitle1, bannerTitle2,
    file1, file2,
    showShadow,
    marginCoordinates,
    paddingCoordinates,
    viewsMargins,
    blurIntencity
}) => {
    return (
        <ContentView
            style={{
                height: height1,
                flexDirection: 'row',
                borderRadius: borderRadius,
                paddingLeft: paddingCoordinates.left,
                paddingRight: paddingCoordinates.right,
                paddingTop: paddingCoordinates.top,
                paddingBottom: paddingCoordinates.bottom,
                marginBottom: marginCoordinates.bottom,
                marginTop: marginCoordinates.top,
                marginLeft: marginCoordinates.left,
                marginRight: marginCoordinates.right
            }}
        >
            {/* Button #1 */}
            <ContentView style={{
                flexGrow: 1,
                height: '100%',
                marginRight: viewsMargins,
                shadowOpacity: showShadow ? 0.5 : undefined,
                shadowRadius: showShadow ? 6 : undefined,
                borderRadius: borderRadius,
                overflow: 'hidden'
            }}>
                {file1 && file1.blurhash && (
                    <ContentBlurhash
                        blurhash={file1.blurhash!}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            alignSelf: 'flex-start',
                            borderRadius: borderRadius,
                        }}
                        decodeHeight={16}
                        decodeWidth={16}
                        decodeAsync={true}
                    />
                )}
                <ContentImage
                    resizeMode={(file1.resizeMode && file1.resizeMode !== null) ? file1.resizeMode || 'cover' : 'cover'}
                    source={{ uri: file1.src as string }}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        alignSelf: 'flex-start',
                        borderRadius: borderRadius,
                    }}
                />
                {(bannerTitle1.value.length > 0 && bannerTitle1.showCaption) && (
                    <ContentBlurView
                        style={{
                            flexGrow: 1,
                            height: 62,
                            alignItems: bannerTitle1.textAlign,
                            justifyContent: 'center',
                            padding: 16,
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            overflow: 'hidden'
                        }}
                        tint={'dark'}
                        intensity={blurIntencity}
                    >
                        <ContentText style={{
                            color: bannerTitle1.textColor,
                            fontSize: 16,
                            fontWeight: '600',
                            maxWidth: 300,
                            textAlign: mapTextAlign(bannerTitle1.textAlign)
                        }}>
                            {bannerTitle1.value}
                        </ContentText>
                    </ContentBlurView>
                )}
                <ContentPressableNavigator route={url1 || ''} borderRadius={borderRadius} />
            </ContentView>

            {/* Button #2 */}
            <ContentView style={{
                flexGrow: 1,
                height: '100%',
                shadowOpacity: showShadow ? 0.5 : undefined,
                shadowRadius: showShadow ? 6 : undefined,
                borderRadius: borderRadius,
                overflow: 'hidden'
            }}>
                {file2 && file2.blurhash && (
                    <ContentBlurhash
                        blurhash={file2.blurhash!}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            alignSelf: 'flex-start',
                            borderRadius: borderRadius,
                        }}
                        decodeHeight={16}
                        decodeWidth={16}
                        decodeAsync={true}
                    />
                )}
                <ContentImage
                    resizeMode={(file2.resizeMode && file2.resizeMode !== null) ? file2.resizeMode || 'cover' : 'cover'}
                    source={{ uri: file2.src as string }}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        alignSelf: 'flex-start',
                        borderRadius: borderRadius,
                    }}
                />
                {(bannerTitle2.value.length > 0 && bannerTitle2.showCaption) && (
                    <ContentBlurView
                        style={{
                            flexGrow: 1,
                            height: 62,
                            alignItems: bannerTitle2.textAlign,
                            justifyContent: 'center',
                            padding: 16,
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }}
                        tint={'dark'}
                        intensity={blurIntencity}
                    >
                        <ContentText style={{
                            color: bannerTitle2.textColor,
                            fontSize: 16,
                            fontWeight: '600',
                            maxWidth: 300,
                            textAlign: mapTextAlign(bannerTitle2.textAlign)
                        }}>
                            {bannerTitle2.value}
                        </ContentText>
                    </ContentBlurView>
                )}
                <ContentPressableNavigator route={url2 || ''} borderRadius={borderRadius} />
            </ContentView>
        </ContentView>
    )
});

export default {
    Preview: () => <Stack alignment="center" distribution="center">
        <IconWrapper>
            <View style={{ flexDirection: 'row' }}>
                <View>
                    <Icon source={BuyButtonMajor} />
                </View>
                <View style={{ marginLeft: 2 }}>
                    <Icon source={BuyButtonMajor} />
                </View>
            </View>
        </IconWrapper>
        <Stack.Item fill>
            Two banners
        </Stack.Item>
    </Stack>,
    RenderBlock: TwoButtons,
    Icon: BuyButtonMajor,
    Name:'Two banners'
} as ConfigBlock