import { Collapsible, RangeSlider, TextField } from "@shopify/polaris"
import React, { useEffect, useState } from "react"
import { Knob } from "../Knob"
import { KnobBoolean } from "./KnobBoolean"

export const KnobSwitchableRange = React.memo<Knob.KnobComponentProps<Knob.KnobSwitchableRangeDescriptor>>(
    ({ knob, value: initValue, onChange, innerKey }) => {
        const { description, min, max, step } = knob
        const [value, setValue] = useState({
            enabled: initValue.enabled,
            interval: initValue.interval
        });

        useEffect(() => {
            setValue(initValue)
        }, [initValue]);


        return (<>
            <KnobBoolean
                knob={{
                    type: 'boolean',
                    defaultValue: false,
                    description: 'Enable autoplay to slider'
                }}
                value={value.enabled || false}
                innerKey={'SmartBannerStickTobottom'}
                onChange={(v) => {
                    console.log('SmartBannerStickTobottom', v);
                    setValue((prevValue) => {
                        const newState = { ...prevValue, ...{ enabled: v } };
                        onChange(newState, innerKey)
                        return newState
                    });
                }}
            />
            <Collapsible
                open={value.enabled}
                id="range-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint>
                <TextField
                    type={'number'}
                    min={min}
                    max={max}
                    step={step}
                    label={description}
                    value={value.interval.toString()}
                    onChange={(v) => {
                        setValue((prevValue) => {
                            const newState = { ...prevValue, ...{ interval: +v } };
                            onChange(newState, innerKey)
                            return newState;
                        })
                    }}
                />
                <div style={{ marginTop: '10px' }}>
                    <RangeSlider
                        label={''}
                        value={value.interval}
                        min={min}
                        max={max}
                        step={step}
                        onChange={(v) => {
                            setValue((prevValue) => {
                                const newState = { ...prevValue, ...{ interval: typeof v === 'number' ? v : min ?? 0 } };
                                onChange(newState, innerKey)
                                return newState;
                            })
                        }
                        }
                    />
                </div>
            </Collapsible>
        </>
        )
    })