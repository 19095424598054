import React, { useEffect } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Modal, TextField } from '@shopify/polaris'
import { useClient } from '../../api/useClient';
import { PromptLoading } from '../../routing/PromptLoading';
import { useValidatePageName } from './editPageUtils';

export function useRenamePageModal(pageId: string) {
    const [active, setActive] = useState(false);
    const client = useClient();
    const {name: oldPageName} = client.usePage({id: pageId}).page
    const [name, setName] = useState(oldPageName)
    const [errorMsg, clean, verifyName] = useValidatePageName(name)
    const [loading, setLoading] = useState(false)
    useEffect(clean, [name, active])

    const setClose = useCallback(() => setActive(false), []);
    const setOpen = useCallback(() => setActive(true), []);

    const onSave = useCallback(() => {
        if (verifyName()) {
            setLoading(true)
            client.mutateUpdatePage({
                id: pageId, input: {
                    name,
                },
            })
                .then(setClose)
                .finally(() => setLoading(false))
        }
    }, [name])

    const modalContent = useMemo(() => (
        <Modal
            open={active}
            onClose={setClose}
            title={`Rename page ${oldPageName}`}
            primaryAction={{
                content: 'Save',
                onAction: onSave,
                disabled: loading,
            }}
        >
            <Modal.Section>
                <TextField label={'New page name'} placeholder={'Enter new page name'} inputMode={'text'} value={name}
                           onChange={setName} error={errorMsg || false}/>
                <PromptLoading message={'Wait when page will be updated'} when={loading}/>
            </Modal.Section>
        </Modal>
    ), [active, name, loading, errorMsg])

    return [modalContent, setOpen] as const
}