import { Icon } from '@expo/vector-icons/build/createIconSet';
import { useEffect, useState } from 'react';
import {IconsData, SyncIconFontDes} from "./types";

export function setup(item: Pick<IconsData, 'iconSet' | 'searchMeta' | 'title' | 'iconPreview'>): Promise<Omit<IconsData, 'key'>> {
    const allKeys = Object.keys(item.iconSet.glyphMap || item.iconSet.getRawGlyphMap())
    const loadFontPromise = item.iconSet.loadFont && item.iconSet.loadFont() || Promise.resolve()
    return loadFontPromise.then(() => ({
        ...item,
        allKeys,
        totalElements: allKeys.length,
    }))
}

export function makeSyncKey(key: string, val: () => Promise<Omit<IconsData, 'key'>>): SyncIconFontDes {
    const wrapper = (() => val().then(data => ({...data, key}))) as SyncIconFontDes
    wrapper.key = key
    return wrapper
}