import { EmptyState, ResourceList, TextStyle } from "@shopify/polaris";
import moment from "moment";
import React from "react";
import { View } from "react-native";
import { CoinTransactionType } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { dateFormat } from "../../../utils/textUtils";
import { PointsTransactionItem } from "./PointsTransactionItem";

export const PointsTransactionsPreview = React.memo(() => {
    const client = useClient();
    const transactions = client
        .useListPointsTransactions({ first: 4 })
        .listPointsTransactions.edges.map((e) => e.node);

    return (
        <View style={{ flexGrow: 1 }}>
            <TextStyle>
                {`Latest transactions`}
            </TextStyle>
            {(!transactions || transactions.length === 0) && (
                <EmptyState
                    heading="No transactions yet"
                    image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png" />
            )}
            {(transactions && transactions.length > 0) && (
                <ResourceList 
                items={transactions} 
                renderItem={(item) => {
                    return (
                        <ResourceList.Item
                            onClick={() => { }}
                            id={item.id}
                        >
                            <PointsTransactionItem item={item} />
                        </ResourceList.Item>
                    );
                }} />
            )}
        </View>
    );
});