import { useCallback, useEffect, useMemo, useState } from 'react';

export enum TipKey {
    Max5Items = 'Max5Items',
    ThemeInfo = 'ThemeInfoBanner',
    ShopLogo = 'ShopLogoBanner',
    FreeTrafficInfo = 'FreeTrafficInfo',
    SmartBannerInfo = 'SmartBannerInfo',
    PushNotificationsInfo = "PushNotificationsInfo",
    ProductPublishingInfo = "ProductPublishingInfo",
    TrafficExchangeProfileInfo = "TrafficExchangeProfileInfo",
    TrafficExchangeLisingInfo = "TrafficExchangeLisingInfo",
    ThemeBanner = "ThemeBanner",
    TrafficExchangeCommunityInfo = "TrafficExchangeCommunityInfo",
}

const TIPS_PREFIX = 'Tips_'

// TODO: add Shop Id to the key, to percist banners shopwing up across the apps
export function useTip(_key: TipKey, finishCount: number = 1) {
    const key: string = useMemo(() => TIPS_PREFIX + key, [_key])

    const [value, setValue] = useState<number>(finishCount)

    useEffect(() => {
        let localValue = 0
        try {
            localValue = parseInt(localStorage.getItem(key) || '0');
        } catch (e) {
            localValue = 0
        }

        if (__DEV__) {
            localValue = 0
        }
        setValue(localValue)
    }, [key])

    const onFinishTip = useCallback(() => {
        localStorage.setItem(key, (value + 1).toString())
        setValue(finishCount)
    }, [value, key])

    const isFinished = value >= finishCount

    return [isFinished, onFinishTip] as const
}