import { Card, FormLayout, OptionList, TextField, Button, Popover, Icon, Heading, Labelled } from "@shopify/polaris"
import { CategoriesMajor, ListMajor, ColumnWithTextMajor, CircleChevronRightMinor } from "@shopify/polaris-icons"
import React, { useCallback, useEffect, useState } from "react"
import { View } from "react-native"
import { Knob } from "../Knob"

export const KnobViewType = React.memo<Knob.KnobComponentProps<Knob.KnobViewTypeDescriptor>>(({ knob, value: initValue, onChange, innerKey }) => {
    const { description } = knob
    const [selected, setSelected] = useState<string[]>([initValue])
    const [popoverActive, setPopoverActive] = useState(false);

    useEffect(() => {
        onChange(selected[0], innerKey)
    }, [selected, knob, innerKey])

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );

    const options = [
        {
            value: 'list', label: 'Vertical List', media: (
                <Icon
                    source={ListMajor}
                    color="base" />
            )
        },
        {
            value: 'blocks', label: 'Grid Blocks', media: (
                <Icon
                    source={ColumnWithTextMajor}
                    color="base" />
            )
        },
        {
            value: 'circles', label: 'Horizontal Circles', media: (
                <Icon
                    source={CircleChevronRightMinor}
                    color="base" />
            )
        },
        {
            value: 'banners', label: 'Vertical Blocks', media: (
                <Icon
                    source={CategoriesMajor}
                    color="base" />
            )
        },
        {
            value: 'horizontal_list', label: 'Horizontal blocks list', media: (
                <View style={{ transform: [{ rotate: '90deg' }] }}>
                    <Icon
                        source={CategoriesMajor}
                        color="base" />
                </View>
            )
        },
    ];

    const selectedLabelIndex = options.findIndex((option) => {
        return option.value === selected[0];
    });

    const activator = (
        <Button
            fullWidth
            onClick={togglePopoverActive}
            icon={(selectedLabelIndex !== -1) ? options[selectedLabelIndex].media : undefined}
        >
            {(selectedLabelIndex !== -1) ? options[selectedLabelIndex].label : 'View type'}
        </Button>
    );

    return (
        <FormLayout>
            <Labelled id={innerKey} label={description}>
                <Popover
                    active={popoverActive}
                    activator={activator}
                    onClose={togglePopoverActive}
                >
                    <OptionList
                        title={description}
                        onChange={setSelected}
                        options={options}
                        selected={selected}
                    />
                </Popover>
            </Labelled>
        </FormLayout>
    );
});