import { useCallback, useEffect, useMemo, useState } from "react";

const BOOL_PREFIX = 'Boolean';

export function useBoolLocalStorage(_key: string) {
    const key: string = useMemo(() => BOOL_PREFIX + _key, [_key]);

    const [value, setValue] = useState<(0 | 1)>(1);

    useEffect(() => {
        let localValue = 0;
        try {
            localValue = parseInt(localStorage.getItem(key) || '0');
        } catch (e) {
            localValue = 0;
        }

        if (__DEV__) {
            localValue = 0;
        }
        setValue(localValue === 1 ? 1 : 0);
    }, [key]);

    const setNewValue = useCallback((newValue: boolean) => {
        console.log('setNewValue', newValue);
        localStorage.setItem(key, (newValue ? 1 : 0).toString())
        setValue(newValue ? 1 : 0);
    }, [value, key]);

    console.log('useBoolLocalStorage', _key, value);

    return [value === 1 ? true : false, setNewValue] as const;
}