import React, { useCallback, useMemo } from 'react'
import { useClient } from '../../api/useClient';
import { ViewConfig } from '../../configEditor/ConfigEditor';
import { ContentPageType } from '../../api/spacex.types';
import { useSaver } from '../utils/useSaver';
import { PageBlocks } from '../../content/contentPage/PageBlocks';

type Props = {
    pageId: string
}

export const EditPageById = React.memo<Props>(({ pageId }) => {
    const client = useClient()
    const page = client.usePage({ id: pageId }).page

    const initBlocks: ViewConfig.NodeBlockExtended[] = useMemo(() => ViewConfig.getBlocks(page), [page.id])

    const onChange = useCallback(async (newState: ViewConfig.NodeBlockExtended[]) => {
        const data = ViewConfig.PageToInputPage.encode({
            name: '',
            id: '',
            type: ContentPageType.BLOCK,
            blocks: newState,
            contentPageId: '',
        });

        console.log('[EditPageById] mutateUpdatePage data', data);

        const res = await client.mutateUpdatePage({
            id: page.id,
            input: {
                body: data.body,
            },
        });

        console.log('[EditPageById] mutateUpdatePage res', res);
    }, [page?.id]);

    const [pageData, loading, setPageData] = useSaver<ViewConfig.NodeBlockExtended[]>(initBlocks, onChange)

    return <PageBlocks blocks={pageData} onChange={setPageData} />
})