import React from 'react';
import { Chip } from './Chip';
import { PrimaryChip } from './PrimaryChip';
import { View, StyleSheet } from 'react-native';
import { SelectOption } from '../StyledSelect/StyledSelect';

type ValueMap = {
    [value: string]: boolean
}

export type SelectChipsProps = {
    options: SelectOption[]
    valueMap: ValueMap
    onSelect: (item: SelectOption) => void
}

const chipMargin = 4
const style = StyleSheet.create({
    container: {
        flexDirection: 'row',
        margin: -chipMargin,
        flexWrap: 'wrap'
    },
    chip: {
        margin: chipMargin
    }
})

export const SelectChips = React.memo(React.forwardRef<View, SelectChipsProps>(({options, valueMap, onSelect}, ref) => (
    <View style={style.container} ref={ref}>
        {options.map(opt => {
            const {value, label} = opt
            if (valueMap[value]) {
                return <PrimaryChip key={value} style={style.chip} onPress={() => onSelect(opt)}>{label}</PrimaryChip>
            } else {
                return <Chip key={value} style={style.chip} onPress={() => onSelect(opt)}>{label}</Chip>
            }
        })}
    </View>
)))