import { ViewConfig } from '../ConfigEditor';
import renderer from 'react-test-renderer';
import React from 'react';
import { allBlocks } from '../blocks/allBloks';
import { KnobCollection, KnobContextMediator } from '../knobs/KnobContext';
import { Knob } from '../knobs/Knob';

export function renderBlock(originComponentKey: string, sideEffect: Knob.SideEffect = {}, title: string): Pick<ViewConfig.NodeBlockExtended, 'descr' | 'src' | 'knobs' | 'originComponentKey' | 'title'> {
    const block = allBlocks.get(originComponentKey)
    
    if (!block) {
        return {
            src: '',
            knobs: {},
            originComponentKey,
            descr: null,
            title: ''
        }
    }

    const collector = new KnobContextMediator(sideEffect)
    const subRender = renderer.create(<KnobCollection collector={collector}>
        <block.RenderBlock />
    </KnobCollection>)
    
    const modernRender = JSON.stringify(subRender.toJSON())
    return {
        src: modernRender,
        knobs: collector.getKnobsState(),
        originComponentKey,
        descr: collector.getDescriptor(),
        title: title
    }
}

export function rerenderBlock(block: ViewConfig.NodeBlock): ViewConfig.NodeBlockExtended {
    const newValue = renderBlock(block.originComponentKey, block.knobs, block.title)
    return {
        ...block,
        ...{title: newValue.title},
        ...newValue,
        modernRender: newValue.src,
    }
}

export function initRenderBlock(block: ViewConfig.NodeBlock): ViewConfig.NodeBlockExtended {
    const newValue = renderBlock(block.originComponentKey, block.knobs, block.title)
    console.log('[initRenderBlock]', newValue);
    
    return {
        ...newValue,
        ...block,
        ...{title: newValue.title},
        modernRender: newValue.src,
    }
}

export function createBlock(originComponentKey: string): ViewConfig.NodeBlockExtended {
    const newValue = renderBlock(originComponentKey, undefined, originComponentKey)
    console.log('[createBlock]', newValue);
    return {
        id: Date.now().toString(),
        modernRender: newValue.src,
        ...newValue,
        ...{title:''},
    }
}