import { Modal, Heading } from "@shopify/polaris";
import React from "react";
import { View, Text } from "react-native";
import { OnboardingModal, OnboardingType } from "../modals/OnboardingModal";

const pagesOnboarding = require('../../assets/gifs/onboarding-pages.mp4');

export const PagesOnboarding = React.memo(() => {
    return (
        <OnboardingModal
            title={'Pages customization'}
            _key={OnboardingType.Pages}
        >
            <Modal.Section>
                <View style={{
                    flexDirection: 'row',
                    flex: 1, flexWrap: 'wrap',
                    justifyContent: 'center'
                }}>
                    <video
                        style={{
                            display: 'flex',
                            flex: 1,
                            maxHeight: '400px',
                            maxWidth: '400px'
                        }}
                        src={pagesOnboarding}
                        muted
                        autoPlay
                        controls={false}
                        loop
                    />
                    <View style={{
                        flex: 1,
                        maxHeight: '400px',
                        maxWidth: '400px',
                        margin: '16px'
                    }}>
                        <View style={{ marginBottom: '10px' }}>
                            <Heading>
                                {'Make your own custom pages of in-app store'}
                            </Heading>
                            <Text>
                                {"You can deeply customize the pages of your store to make it unique in the flow of Shopify stores and meet your specific needs. Our 'Page builder' allows you to easily select, customize, and change elements on your in-app pages. Has a new promotion or activity started in your store? Grab customers' attention with a striking banner and move them directly to the product they want. Don't leave the customer a single chance to leave you without a purchase."}
                            </Text>
                            <Heading>
                                {"Pages customization available only on PRO plan"}
                            </Heading>
                        </View>
                    </View>
                </View>
            </Modal.Section>
        </OnboardingModal>
    );
});