import React, { useCallback, useEffect, useState } from "react";
import { AppEmulatorConfig, AppEmulatorPlatform } from "../types";
import { useClient } from "../../../api/useClient";
import { AppBannerInputParams } from "../../../api/spacex.types";
import { useAccount } from "../../../configure/utils/useAccount";
import { useEmulatorConfig } from "../components/ScalableEmulatorComponent";
import { useParams } from "react-router";
import { DisplayText, TextStyle, Tooltip } from "@shopify/polaris";
import { FontWeightType } from "../../../configEditor/knobs/types";

const defaultLogo = require('../../../assets/images/defaultBannerLogo.png');

export const SmartBannerScreen = React.memo((
    {
        appId,
        config,
        smartBanner,
        selectedPlatform
    }: {
        appId: string,
        config: AppEmulatorConfig,
        smartBanner: AppBannerInputParams,
        setSmartBanner?: (value: AppBannerInputParams) => void,
        selectedPlatform: AppEmulatorPlatform
    }
) => {
    const emulator = useEmulatorConfig();
    const client = useClient();
    const account = useAccount();
    const { id } = useParams<{ id: string }>();
    const app = client.useGetAppSettings({ id }).app;
    const screenshot = client.useGetSiteScreenshot({ appId }).siteScreenshot;

    const [siteScreenshot, setSiteScreenshot] = useState<string>(screenshot || account.screenshot || '');
    const [bannerVisible, setBannerVisible] = useState(true);

    const handleBannerToggle = useCallback(() => { setBannerVisible(!bannerVisible) }, []);

    console.log("EMULATOR preview screenshot", siteScreenshot);
    console.log('icon', app.settings?.banner?.params?.icon);

    console.log('titleColor', app.settings?.banner?.params?.titleColor);

    const banner = !smartBanner.fullScreen
        ?
        <div id={"installapp"} style={{
            height: `${emulator.isSmall ? '120px' : '170px'}`,
            color: '#fff',
            position: smartBanner.stickToBottom ? 'absolute' : 'relative',
            top: smartBanner.stickToBottom ? 'auto' : '0px',
            // right: smartBanner.stickToBottom ? '29px' : '0px',
            // right: !smartBanner.stickToBottom ? '0px' : emulator.isSmall ? '24px' : '29px',
            right: '0px',
            bottom: smartBanner.stickToBottom ? '90px' : 'auto',
            // width: smartBanner.stickToBottom ? '675px' : '100%',
            width: !smartBanner.stickToBottom ? '100%' : emulator.isSmall ? '509px' : '675px',
            animationName: smartBanner.stickToBottom ? 'heightGrow' : 'heightGrow',
        }}>
            <div
                id={"iapp-" + selectedPlatform + "_inner"}
                style={{
                    height: `${(emulator.isSmall
                        && smartBanner.icon !== null
                        && smartBanner.icon.length > 0)
                        || smartBanner.text.length > 0
                        ? `${emulator.bannerHeight}px`
                        : !smartBanner.icon ? '50px' : `${emulator.bannerHeight}px`}`,
                    // padding: '0 16px'
                    padding: `${emulator.isSmall ? '0 12px' : '0 22px'}`,
                    color: '#fff',
                    backgroundColor: smartBanner.backgroundColor ? smartBanner.backgroundColor : '#555',
                    animationName: smartBanner.stickToBottom ? 'appearanceBottom' : 'appearance',
                    margin: emulator.isSmall ? '8px' : '16px',
                    // borderRadius: '15px',
                    borderRadius: '26px',
                    // justifyContent: `${smartBanner.buttonText.length > 0 ? 'space-around' : 'space-around'}`
                    justifyContent: `${(
                        smartBanner.icon !== null
                        && smartBanner.icon.length > 0)
                        && smartBanner.text.length > 0
                        && smartBanner.buttonText.length > 0 ? 'space-around' : 'flex-start'}`
                }}
            >
                <div
                    className={"iapp-icon_close"}
                    onClick={handleBannerToggle}
                    style={{
                        transform: `${emulator.isSmall ? 'scale(1.5)' : 'scale(2)'}`,
                        position: 'absolute',
                        top: '17px',
                        right: '17px',
                        backgroundColor: '#fff',
                        borderRadius: '50%'
                    }}
                />
                <div className={"iapp-icon_wrapper"}>
                    {(smartBanner && smartBanner.icon !== null && smartBanner.icon.length > 0) && (
                        <div
                            className={"iapp-app_icon " + selectedPlatform}
                            style={{
                                backgroundImage: `url(${smartBanner.icon})`,
                                width: `${emulator.isSmall ? '80px' : '105px'}`,
                                height: `${emulator.isSmall ? '80px' : '105px'}`,
                            }}
                        />
                    )}
                    {(!smartBanner || smartBanner.icon === null || smartBanner.icon.length === 0) && (
                        <div
                            className={"iapp-app_icon " + selectedPlatform}
                            style={{
                                display: 'none',
                                width: '70px',
                                height: '70px'
                            }}
                        />
                    )}
                </div>
                <Tooltip content='Smart banner shown to all website visitors.'>
                    <div
                        className={"iapp-info"}
                        style={{
                            marginTop: '-4px'
                        }}
                    >
                        <div
                            className={"iapp-title_wrapper"}
                            style={{
                                height: '35px',
                                margin: ` 0 10px ${emulator.isSmall ? '-3px' : '0px'} ${emulator.smartBannerMargin}px`,
                                width: emulator.isSmall ? '245px' : '330px'
                            }}
                        >
                            <div
                                id={"iapp-title"}
                                style={{
                                    fontSize: `${emulator.isSmall ? '20px' : '24px'}`,
                                    lineHeight: '36px',
                                    color: smartBanner.titleColor ? smartBanner.titleColor : '#fff',
                                    fontWeight: smartBanner.titleFontWeight ? +smartBanner.titleFontWeight : +FontWeightType.Normal
                                }}
                            >
                                {smartBanner.title}
                            </div>
                        </div>
                        {smartBanner.text.length > 0 && <div
                            className={"iapp-text_wrapper"}
                            style={{
                                height: '43px',
                                margin: ` 0 10px 0 ${emulator.smartBannerMargin}px`,
                                width: emulator.isSmall ? '250px' : '330px'
                            }}
                        >
                            <div
                                id={"iapp-text"}
                                style={{
                                    fontSize: `${emulator.isSmall ? '16px' : '18px'}`,
                                    lineHeight: `${emulator.isSmall ? '20px' : '21px'}`,
                                    color: smartBanner.textColor ? smartBanner.textColor : '#fff',
                                    fontWeight: smartBanner.textFontWeight ? +smartBanner.textFontWeight : +FontWeightType.Normal
                                }}
                            >
                                {smartBanner.text}
                            </div>
                        </div>}
                    </div>
                </Tooltip>
                <div
                    className={"iapp-button iapp-" + selectedPlatform + "_button"}
                    style={{
                        display: smartBanner.buttonText.length > 0 ? 'inline-block' : 'none',
                        fontSize: `${emulator.isSmall ? '17px' : '20px'}`,
                        width: `${emulator.isSmall ? '115px' : '135px'}`,
                        color: smartBanner.buttonTextColor ? smartBanner.buttonTextColor : '#fff',
                        backgroundColor: smartBanner.buttonColor ? smartBanner.buttonColor : '#0f80ff',
                        fontWeight: smartBanner.buttonTextFontWeight ? +smartBanner.buttonTextFontWeight : +FontWeightType.Normal,
                        margin: `${smartBanner.text.length > 0 ? '27px 0 0 0' : '0px'}`
                    }}
                >
                    {smartBanner.buttonText}
                </div>
            </div>
        </div>
        :
        <div style={{
            color: '#fff',
            position: 'absolute',
            // top: '20px', right: '50px', left: '20px', bottom: '50px',
            inset: `0px`,
            // width: !smartBanner.stickToBottom ? '100%' : emulator.isSmall ? '509px' : '675px',
            // animationName: smartBanner.stickToBottom ? 'heightGrow' : 'heightGrow',
            // backgroundImage: `url(${defaultLogo})`,
            opacity: 0,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundColor: 'rgb(0 0 0 / 30%)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            animationName: 'toggleOpacity',
            animationDelay: '0.5s',
            animationDuration: '0.2s',
            animationFillMode: 'forwards'
        }}>
            <div style={{
                color: '#fff',
                position: 'absolute',
                inset: `${emulator.isSmall ? '20px 20px 90px' : '30px 30px 110px'}`,
                opacity: 0,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundColor: smartBanner.backgroundColor ? smartBanner.backgroundColor : '#555',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                animationName: 'toggleOpacity',
                animationDelay: '0.5s',
                animationDuration: '0.2s',
                animationFillMode: 'forwards',
                borderRadius: `${emulator.isSmall ? '30px' : '40px'}`
            }}>
                <div
                    className={"largeBanner-icon_close"}
                    onClick={handleBannerToggle}
                    style={{
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                        transform: 'scale(1)',
                        width: '44px',
                        height: '44px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        zIndex: 99999
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            width: '28px',
                            height: '3px',
                            transform: 'rotate(45deg)',
                            left: '8px',
                            bottom: '21px',
                            backgroundColor: smartBanner.backgroundColor ? smartBanner.backgroundColor : '#555'
                        }} />
                    <div
                        style={{
                            position: 'absolute',
                            width: '28px',
                            height: '3px',
                            transform: 'rotate(-45deg)',
                            left: '8px',
                            bottom: '21px',
                            backgroundColor: smartBanner.backgroundColor ? smartBanner.backgroundColor : '#555'
                        }} />
                </div>
                <div style={{
                    fontSize: emulator.isSmall ? '60px' : '80px',
                    lineHeight: emulator.isSmall ? '60px' : '80px',
                    textAlign: 'center',
                    marginTop: '-170px',
                    fontWeight: smartBanner.titleFontWeight ? +smartBanner.titleFontWeight : +FontWeightType.Bold,
                    color: smartBanner.titleColor ? smartBanner.titleColor : '#fff'
                }}>
                    {smartBanner.title}
                </div>
                <div style={{
                    fontSize: emulator.isSmall ? '35px' : '50px',
                    lineHeight: emulator.isSmall ? '35px' : '50px',
                    textAlign: 'center',
                    marginTop: '30px',
                    padding:'0 15px',
                    fontWeight: smartBanner.textFontWeight ? +smartBanner.textFontWeight : +FontWeightType.Normal,
                    color: smartBanner.textColor ? smartBanner.textColor : '#fff'
                }}>
                    {smartBanner.text}
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: '40px',
                    left: '0px',
                    right: '0px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '0px 20px'
                }}>
                    <div
                        style={{
                            backgroundImage: `url(${smartBanner.icon})`,
                            width: `${emulator.isSmall ? '90px' : '120px'}`,
                            height: `${emulator.isSmall ? '90px' : '120px'}`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            borderRadius: '8px'
                        }}
                    />
                    <div
                        style={{
                            fontSize: emulator.isSmall ? '30px' : '40px',
                            lineHeight: emulator.isSmall ? '30px' : '40px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: '0px 25px',
                            color: smartBanner.buttonTextColor ? smartBanner.buttonTextColor : '#fff',
                            backgroundColor: smartBanner.buttonColor ? smartBanner.buttonColor : '#0f80ff',
                            fontWeight: smartBanner.buttonTextFontWeight ? +smartBanner.buttonTextFontWeight : +FontWeightType.Normal,
                            maxWidth: emulator.isSmall ? '300px' : '430px',
                            minWidth: emulator.isSmall ? '100px' : '230px',
                            borderRadius: '8px',
                            textAlign: 'center'
                        }}
                    >
                        {smartBanner.buttonText}
                    </div>
                </div>
            </div>
        </div>
    return (
        <>
            <div
                className={`${selectedPlatform}__smart-banner-content`}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    position: 'relative'
                }}
            >
                {bannerVisible && banner}
                <span style={{
                    backgroundImage: `url(${siteScreenshot})`,
                    backgroundSize: 'cover',
                    height: `${emulator.smartBannerScreenshot}px`,
                    width: "100%",
                    flexGrow: 1
                }} />
            </div>
        </>
    );
});