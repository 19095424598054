import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { TypographyStyle, TypographyWeightsStyle } from '../../../components/Typography/commonTypography';
import { FormSelect } from '../../../components/Form/Components/FormSelect';
import { SelectOption } from '../../../components/StyledSelect/StyledSelect';
import { Heading, TextContainer } from '@shopify/polaris';
import { ErrorMessage } from '@hookform/error-message';
import { useForm, useFormContext } from 'react-hook-form';

const options: SelectOption<string>[] = [
    { value: '1', label: 'Online only' },
    { value: '2', label: 'Offline & Online' },
    { value: '3', label: 'Skip' },
];

const style = StyleSheet.create({
    title: {
        marginBottom: 8
    },
    caption: {
        marginBottom: 8
    },
    captionError: {
        color: 'red'
    },
});

const validate = (data?: string) => !!data?.length;

export const HowAreYouSelling = React.memo(() => {
    const context = useFormContext<{ sellingStatus: string }>();
    const error = context.errors['sellingStatus'];

    return (
        <View>
            <View style={{ marginBottom: 8 }}>
                <TextContainer>
                    <Heading>
                        📦 &nbsp; How are you selling your products?
                    </Heading>
                </TextContainer>
            </View>
            <FormSelect
                name={'sellingStatus'}
                rules={{ ...{ validate }, ...{ required: true } }}
                isSearchable={true}
                placeholder={'Select value'}
                options={options}
            />
            <Text style={[TypographyStyle.caption, style.caption, error && style.captionError]}>
                {'This field is required'}
            </Text>
        </View>
    );
});