import React from "react";
import { ContentView, ProductProps, ContentProduct } from "../../../../content/components";
import { ResizeMode, ViewType } from "../../../knobs/types";

export const ProductHorizontalListItem = React.memo((
    props: {
        viewsHeight: number,
        viewsMargins: number,
        borderRadius: number,
        showShadow?: boolean,
    } & ProductProps
) => {

    return (
        <ContentView style={{
            height: props.viewsHeight,
            marginRight: props.viewsMargins,
            marginBottom: props.viewsMargins,
            borderRadius: props.borderRadius,
            shadowOpacity: props.showShadow ? 0.5 : undefined,
            shadowRadius: props.showShadow ? 6 : undefined,
            overflow: 'hidden',
            width: 182
        }}>
            <ContentProduct
                id={props.id}
                viewType={ViewType.Blocks}
                textColor={props.textColor}
                textAlign={props.textAlign}
                showCaption={props.showCaption}
                placeholderImage={props.placeholderImage}
                showTextBackground={props.showTextBackground}
                fontWeight={props.fontWeight}
                imageResizeMode={props.imageResizeMode as ResizeMode}
                blurIntencity={props.blurIntencity}
                showPrice={props.showPrice}
            />
        </ContentView>
    );
})