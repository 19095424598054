import { Modal, Stack, TextStyle, Image, Button, Heading } from "@shopify/polaris";
import React from "react";
import { View, Text } from "react-native";
import { useBoolLocalStorage } from "../banners/useBoolLocalStorage";
import { OnboardingModal, OnboardingType } from "../modals/OnboardingModal";

const themeOnboarding = require('../../assets/gifs/onboarding-theme.gif');

export const ThemeOnboarding = React.memo(() => {
    return (
        <OnboardingModal
            title={'Branding'}
            _key={OnboardingType.Branding}
        >
            <Modal.Section>
                <View style={{
                    flexDirection: 'row',
                    flex: 1, flexWrap: 'wrap',
                    justifyContent: 'center'
                }}>
                    <img
                        style={{
                            display: 'flex',
                            flex: 1,
                            maxHeight: '400px',
                            maxWidth: '400px'
                        }}
                        src={themeOnboarding}
                        alt="Branding"
                    />
                    <View style={{
                        flex: 1,
                        maxHeight: '400px',
                        maxWidth: '400px',
                        margin: '16px'
                    }}>
                        <View style={{ marginBottom: '10px' }}>
                            <Heading>
                                {"Improve your customers' experience"}
                            </Heading>
                            <Text>
                                {'Start quality increasing by choosing two themes for your app based on the primary color.'}
                            </Text>
                        </View>
                        <View style={{ marginBottom: '10px' }}>
                            <Heading>
                                {'Choose the main color of your app'}
                            </Heading>
                            <Text>
                                {'Choose the color is the most suitable for your store. You can choose from 19 base colors or use the palette to create a new one.'}
                            </Text>
                        </View>
                        <View style={{ marginBottom: '10px' }}>
                            <Heading>
                                {'Customize your branding'}
                            </Heading>
                            <Text>
                                {'Upload your logo, which will be used as your default store icon throughout the app.'}
                            </Text>
                        </View>
                        <View style={{ marginBottom: '10px' }}>
                            <Heading>
                                {'Use the smartphone emulator'}
                            </Heading>
                            <Text>
                                {"The smartphone emulator on the right side of the screen helps you understand how your Cartmate store will look on your customers' smartphones. Experiment with the settings to get the perfect result."}
                            </Text>
                        </View>
                    </View>
                </View>
            </Modal.Section>
        </OnboardingModal>
    );
});