import React, { useCallback } from 'react'
import { ResourceList, Stack, Thumbnail, TextStyle } from '@shopify/polaris';
import { DynamicIcon } from '../../Icon/IconView/Icons';
import { ItemTreeRoot, Item } from './categoryDataTree';
import { View } from 'react-native';


type Props = {
    items: ItemTreeRoot
    showItemsCount:boolean
    onClick(item: Item): void
}

export const CategoryView = React.memo<Props>(({items,showItemsCount, onClick}) => {
    const renderItemCb = useCallback((item: Item) => renderItem(item, () => onClick(item), showItemsCount), [onClick])
    return (
        
        <ResourceList emptyState={NoItems} items={items} renderItem={renderItemCb}/>
    )
})

const NoItems = <View style={{padding: 10}}>
    <Stack alignment={'center'} distribution={'center'}>
        <Stack.Item>
            <TextStyle variation="strong">
                No items
            </TextStyle>
        </Stack.Item>
    </Stack>
</View>

function renderItem(item: Item, onClick: () => void, showItemsCount:boolean) {

    const itemInfo = item.type === 'category' && <Stack.Item>
        {item.options.length} results
    </Stack.Item>

    const titleContent = item.type === 'category' && <TextStyle variation="strong">{item.title}</TextStyle> || <TextStyle>{item.title}</TextStyle>

    if (item.viewType === 'icon') {
        return <ResourceList.Item id={item.hash} onClick={onClick}>
            <Stack>
                <Stack.Item>
                    <DynamicIcon size={20} value={item.icon}/>
                </Stack.Item>
                <Stack.Item fill>
                    {titleContent}
                </Stack.Item>
                {itemInfo}
            </Stack>
        </ResourceList.Item>
    } else {
        return (
            <ResourceList.Item
                id={item.hash}
                media={<Thumbnail size={'small'} alt={''} source={item.imgSrc}/>}
                onClick={onClick}>
                <Stack>
                    <Stack.Item fill>
                        {titleContent}
                    </Stack.Item>
                    {itemInfo}
                </Stack>
            </ResourceList.Item>
        )
    }
}