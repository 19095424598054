import React from "react";
import { AppEmulatorConfig } from "../types";
import { useClient } from "../../../api/useClient";
import { IOSTitlebar } from "../components/IOSTitlebar";
import { IOSSearchBar } from "../components/IOSSearchBar";
import { IOSScreenContent } from "../components/IOSScreenContent";
import { IOSScreen } from "../components/IOSScreen";
import { SyncStatus } from "../../../api/spacex.types";
import { CatalogSync } from "../components/CatalogSync";
import { ProductsByCollectionsPreview } from "../../../configure/theme/preview/ProductsByCollectionsPreview";

export const ThemeScreen = React.memo((
    {
        appId,
        config
    }: {
        appId: string,
        config: AppEmulatorConfig
    }
) => {
    const client = useClient();
    const syncStatus = client.useMyShop().syncState;

    if (syncStatus.status === SyncStatus.IN_PROGRES) {
        return (<CatalogSync config={config} />);
    }

    return (
        <IOSScreen>
            <IOSTitlebar config={config} />
            <IOSSearchBar config={config} />
            <IOSScreenContent toggleNavigation>
                <ProductsByCollectionsPreview />
            </IOSScreenContent>
        </IOSScreen>
    );
});