import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { TypographyStyle, TypographyWeightsStyle } from '../../../components/Typography/commonTypography';
import { FormSelect } from '../../../components/Form/Components/FormSelect';
import { SelectOption } from '../../../components/StyledSelect/StyledSelect';
import { Heading, TextContainer } from '@shopify/polaris';
import { useFormContext } from 'react-hook-form';

const options: SelectOption<string>[] = [
    { value: '1', label: '🚀 US $0 (I\'m just getting started)' },
    { value: '2', label: 'Up to US $1000' },
    { value: '3', label: 'US $1,000 to US $10,000' },
    { value: '4', label: 'US $10,000 to US $100,000' },
    { value: '5', label: 'US $1,000,000+' },
    { value: '6', label: 'Skip' },
];

const style = StyleSheet.create({
    title: {
        marginBottom: 8
    },
    caption: {
        marginBottom: 8
    },
    captionError: {
        color: 'red'
    },
});

const validate = (data?: string) => !!data?.length;

export const WhatIsYouRevenue = React.memo(() => {
    const context = useFormContext<{ currentMonthlySales: string }>();
    const error = context.errors['currentMonthlySales'];
    return (
        <View>
            <View style={{ marginBottom: 8 }}>
                <TextContainer>
                    <Heading>
                        💵 &nbsp; What is you current revenue?
                    </Heading>
                </TextContainer>
            </View>
            <FormSelect
                name={'currentMonthlySales'}
                rules={{ ...{ validate }, ...{ required: true } }}
                isSearchable={true}
                placeholder={'Revenue'}
                options={options}
            />
            <Text style={[TypographyStyle.caption, style.caption, error && style.captionError]}>
                {'This field is required'}
            </Text>
        </View>
    )
})