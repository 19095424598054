import { FormLayout, Icon, Labelled, Popover, ResourceItem, ResourceList, TextField, TextStyle, Thumbnail } from "@shopify/polaris";
import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { debounce } from "ts-debounce";
import { Collection_collection, ListCollectionsVariables, ListCollections_listCollections, ListCollections_listCollections_edges_node } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { CleanSpaceX } from "../../../components/types";
import { Knob } from "../Knob";

type CollectionsFilter = {
    pageSize: number,
    query?: string
}

type Collection = CleanSpaceX<Collection_collection>;

export const KnobCollectionPicker = React.memo<Knob.KnobComponentProps<Knob.KnobCollectionPickerDescriptor>>((
    { knob, innerKey, value: initialValue, onChange, disabled }
) => {
    const client = useClient();
    const [popoverActive, setPopoverActive] = useState(false);
    const [value, setValue] = useState<Knob.PickedCollection>();
    const [filters, setFilters] = useState<CollectionsFilter>({ pageSize: knob.pageSize || 10 });
    const [results, setResults] = useState<ListCollections_listCollections>();
    const [list, setList] = useState<ListCollections_listCollections_edges_node[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const loadPage = useCallback((params: ListCollectionsVariables, replace: boolean = false) => {
        setLoading(true);
        client.queryListCollections(params)
            .then((result) => {
                setResults(result.listCollections);
                const items = result.listCollections.edges.map(e => e.node);
                setList(replace ? items : list.concat(items));
            })
            .catch(console.warn)
            .finally(() => { setLoading(false); });
    }, [client, loading, list]);

    const nodeToValue = useCallback((node: Collection | ListCollections_listCollections_edges_node) => {
        return node.id;
    }, []);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((prevValue) => {
            return !prevValue
        }),
        [knob, innerKey],
    );
    const handleNodeSelect = useCallback((node: Collection) => {
        const newValue = nodeToValue(node);
        setValue(newValue);
        handleFiltersChange("query", node.title);
        onChange(newValue, innerKey);
        togglePopoverActive();
    }, [innerKey, onChange]);

    const debFilter = useCallback(
        debounce(
            (filters: CollectionsFilter, lp: (params: ListCollectionsVariables, replace?: boolean) => Promise<any> | any) => {
                lp({ first: filters.pageSize, after: undefined }, true)
            },
            300
        ), []);


    const handleFiltersChange = useCallback((field: keyof CollectionsFilter, newValue) => {
        setFilters((prevState) => {
            let newState = { ...prevState, [field]: newValue };
            return newState;
        });
    }, []);

    const handleScrolledToBottom = useCallback(() => {
        if (loading) {
            return;
        }
        if (results?.pageInfo.hasNextPage) {
            loadPage({
                first: filters.pageSize,
                after: results?.pageInfo.endCursor,
            }, false);
        }
    }, [results, filters, loading]);

    const handleClear = useCallback(() => {
        handleFiltersChange("query", '');
        setValue('');
        onChange('', innerKey)
    }, [innerKey, onChange]);

    useEffect(() => {
        if (initialValue && initialValue !== value) {
            client.queryCollection({ id: initialValue.trim() })
                .then((result) => {
                    setValue(nodeToValue(result.collection));
                    handleFiltersChange("query", result.collection.title);
                })
                .catch(console.warn);
        }
    }, [initialValue]);

    useEffect(() => {
        debFilter(filters, loadPage);
    }, [filters]);

    const activator = (
        <div className={'knob_picker_activator'}>
            <TextField
                label={knob.description}
                labelHidden
                value={filters.query}
                onChange={(query) => {
                    handleFiltersChange("query", query);
                }}
                onFocus={() => { !disabled ? setPopoverActive(true) : undefined; }}
                autoComplete={'off'}
                disabled={disabled}
                readOnly
                clearButton={knob.clerable || false}
                onClearButtonClick={handleClear}
                placeholder={"Collection"}
                suffix={(
                    <div style={{ cursor: 'pointer' }}>
                        <Icon
                            source={popoverActive ? ChevronUpMinor : ChevronDownMinor}
                            color="base"
                        />
                    </div>
                )}
            />
        </div>
    );
    return (
        <FormLayout>
            <Labelled id={innerKey} label={knob.description}>
                <Popover
                    activator={activator}
                    active={popoverActive}
                    onClose={togglePopoverActive}
                    fullWidth
                    preferredAlignment={'left'}
                    sectioned
                    ariaHaspopup={false}
                >
                    <Popover.Pane
                        onScrolledToBottom={handleScrolledToBottom}
                    >
                        <View style={{ maxHeight: filters.pageSize * 50 }}>
                            <ResourceList
                                items={list}
                                renderItem={renderProductItem}
                            />
                        </View>
                    </Popover.Pane>
                </Popover>
            </Labelled>
        </FormLayout>
    );

    function renderProductItem(node: ListCollections_listCollections_edges_node) {
        return (
            <ResourceItem
                id={node.id}
                onClick={() => { handleNodeSelect(node) }}
                media={<Thumbnail size={"medium"} source={node.image?.src || ''} alt={node.title} />}
                verticalAlignment={"center"}
            >
                <TextStyle>{node.title}</TextStyle>
            </ResourceItem>
        );
    }
});