import Bugsnag from "@bugsnag/js";
import { Card, ComplexAction, EmptyState, Pagination, ResourceList, Stack } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { AdListingStatus, AdListings_adListings, ListAdListings_listAdListings } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { ResourceListSkeleton } from "../../../components/Skeleton/ResourceListSkeleton";
import { AddLisingModal } from "./AddLisingModal";
import { AdLisingItem } from "./AdLisingItem";
import { DeactivateModal } from "./DeactivateModal";

export const ActiveAdLisings = React.memo(() => {
    const client = useClient();
    const [results, setResults] = useState<ListAdListings_listAdListings>();
    const [loading, setLoading] = useState<boolean>(false);
    const [addModalActive, setAddModalActive] = useState(false);
    const [deactivateItem, setDeactivateItem] = useState<AdListings_adListings>();
    const pageSize = 6;

    const loadPage = useCallback((pageSize: number, after?: string | null) => {
        client.queryListAdListings({
            first: pageSize,
            after: results?.pageInfo.endCursor,
            status: AdListingStatus.ACTIVE
        })
            .then((result) => {
                console.log('Nex page loaded', result);
                setResults(result.listAdListings);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [client]);

    const loadNextPage = useCallback(() => {
        setLoading(true);
        loadPage(pageSize, results?.pageInfo.endCursor);
    }, [results, loadPage]);

    const loadPrevPage = useCallback(() => {
        if (!results?.pageInfo.hasPreviousPage) {
            throw new Error("No previous page");
        }
        setLoading(true);
        loadPage(pageSize, results.pageInfo.startCursor);
    }, [pageSize, results, loadPage]);

    useEffect(() => {
        loadNextPage();
    }, []);

    const addNewAction: ComplexAction = {
        content: 'Add',
        onAction: () => {
            setAddModalActive(true);
        }
    };

    const handleDeactivate = useCallback(
        (item: AdListings_adListings) => {
            console.log('handleDeactivate', item);
            client.mutateDeactivateAdLising({ id: item.id }).then(() => {
                loadNextPage();
            }).catch((e) => {
                console.log('handleDeactivate error', e);
                Bugsnag.notify(e);
            }).finally(() => {
                setDeactivateItem(undefined);
            });
        },
        [client, setDeactivateItem, loadNextPage],
    );

    const handleCancel = useCallback(
        () => {
            setAddModalActive(false)
        },
        [setAddModalActive],
    );

    if (loading) {
        return (<ResourceListSkeleton/>);
    }

    return (
        <Card
            primaryFooterAction={results && results?.edges.length > 0 ? addNewAction : undefined}
            title={'Active positions'}
        >
            <Card.Section>
                {(results?.edges.length === 0) && <EmptyState
                    heading="Manage your positions"
                    action={addNewAction}
                    image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png" />}
                {(results?.edges && results?.edges.length > 0) && (
                    <ResourceList
                        items={results?.edges.map((e) => e.node)}
                        renderItem={(l) => {
                            return (
                                <ResourceList.Item
                                    onClick={() => { }}
                                    id={l.id}
                                    shortcutActions={[
                                        {
                                            content: 'Deactivate',
                                            onAction: () => setDeactivateItem(l)
                                        }
                                    ]}
                                >
                                    <AdLisingItem item={l} />
                                </ResourceList.Item>
                            );
                        }}
                    />
                )}
            </Card.Section>
            {(results?.pageInfo.hasNextPage || results?.pageInfo.hasPreviousPage) && <Card.Section>
                <Stack distribution={"center"}>
                    <Pagination
                        hasPrevious={results?.pageInfo.hasPreviousPage}
                        onPrevious={() => {
                            loadPrevPage()
                        }}
                        hasNext={results?.pageInfo.hasNextPage}
                        onNext={() => {
                            loadNextPage()
                        }}
                    />
                </Stack>
            </Card.Section>}
            <AddLisingModal
                onSuccess={() => {
                    loadPage(pageSize);
                    handleCancel();
                }}
                active={addModalActive}
                handleCancel={handleCancel}
            />
            {deactivateItem && <DeactivateModal
                item={deactivateItem}
                handleCancel={() => {
                    setDeactivateItem(undefined);
                }}
                handleDeactivate={handleDeactivate}
            />}
        </Card>
    );
});