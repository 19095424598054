import { Card, EmptyState, ResourceList } from "@shopify/polaris";
import React from "react";
import { useClient } from "../../../api/useClient";
import { FriendlyStoreItem } from "./FriendlyStoreItem";

export const FriendlyStores = React.memo(() => {
    const client = useClient();
    const friends = client.useFriendlyStores().friendlyStores;
    return (
        <Card sectioned title={'Your friendly stores'}>
            {(friends?.length === 0) && <EmptyState
                heading="No friendly stores added yet"
                image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png" />}
            {(friends && friends.length > 0) && (
                <ResourceList
                    items={friends}
                    renderItem={(store) => {
                        return (
                            <ResourceList.Item
                                onClick={() => { }}
                                id={store.contact.id}
                                shortcutActions={[
                                    {
                                        content: 'View',
                                        onAction: () => {

                                        }
                                    }
                                ]}
                            >
                                <FriendlyStoreItem item={store} />
                            </ResourceList.Item>
                        );
                    }}
                />
            )}
        </Card>
    );
});