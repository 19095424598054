import React, { CSSProperties } from "react";
import Color from "color";
import Clock from "../../Clock";
import { AppEmulatorConfig } from "../types";
import { useEmulatorConfig } from "./ScalableEmulatorComponent";
import { ThemeType } from "../States";

const iosStatusBar = require('../../../assets/images/status-bar.svg');
const iosStatusBarInverted = require('../../../assets/images/status-bar-inverted.svg');
const borderradius = require('../../../assets/images/border-radius.svg');

export const IOSToolbar = React.memo((
    {
        config,
        transparent,
        colored,
        smartBanner
    }: {
        config: AppEmulatorConfig,
        transparent: boolean,
        colored: boolean,
        smartBanner: boolean
    }
) => {
    const emulator = useEmulatorConfig();
    const colors = config.design.colors[emulator.theme];

    let bgl
    let bgd
    if (emulator.theme === ThemeType.Light) {
        bgl = '#fff'
        bgd = '#ececec'
    } else {
        // bgl = '#000'
        bgl = '#1b1b1b'
        bgd = '#353535'
    }

    let background
    let textColor = '#000'
    let indicator = iosStatusBar
    if (transparent) {
        background = 'transparent'
        indicator = iosStatusBarInverted
    } else if (!colored) {
        background = bgl
        textColor = '#fff'
        indicator = iosStatusBar
    } else {
        background = colors.toolBarBackground
        textColor = '#fff'
    }

    if (smartBanner) {
        bgl = '#555555'
    }

    const toolbarSideStyle: CSSProperties = {
        width: '25%',
        backgroundColor: smartBanner ? '#555555' : background,
        // backgroundColor: colors.toolBarBackground,
        paddingTop: '20px',
        paddingBottom: '20px',
        paddingLeft: '24px',
        paddingRight: '36px',
    };

    return (
        <div
            className={"ios__toolbar"}
            style={{
                width: "100%",
                // height: '50px',
                height: `${emulator.isSmall ? '40px' : '50px'}`,
                display: "flex",
                justifyContent: "stretch",
                marginBottom: '-1px',
                backgroundColor: smartBanner ? '#555555' : 'none'
                // backgroundColor:'#000'
            }}
        >
            <div
                className={"ios__toolbar-clock"}
                style={{
                    ...toolbarSideStyle, ...{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        // color: Color(colors.toolBarBackground).isDark() ? "#fff" : "#000",
                        color: smartBanner ? '#fff' : Color(colors.toolBarBackground).isDark() ? textColor : "#000",
                        borderRadius: '0px 15px 0 0'
                    }
                }}
            >
                <Clock device='ios' />
            </div>
            <div
                className={"ios__front-camera"}
                style={{
                    width: "50%",
                    flexGrow: 1,
                    // backgroundColor: colors.toolBarBackground,
                    // backgroundColor: background,
                    backgroundColor: smartBanner ? '#555555' : background,
                    position: 'relative'
                }}
            >
                <div style={{
                    position: 'absolute',
                    // width: '75px',
                    width: `${emulator.isSmall ? '60px' : '75px'}`,
                    height: `${emulator.isSmall ? '7px' : '10px'}`,
                    borderRadius: '20px',
                    backgroundColor: '#262C2D',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    top: `${emulator.isSmall ? '14px' : '15px'}`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}></div>
                <div style={{
                    position: 'absolute',
                    width: `${emulator.isSmall ? '16px' : '21px'}`,
                    height: `${emulator.isSmall ? '16px' : '21px'}`,
                    borderRadius: '50%',
                    backgroundColor: 'transparent',
                    border: `${emulator.isSmall ? '4px' : '5px'} solid #262C2D`,
                    left: '65%',
                    transform: 'translateX(50)',
                    top: '9px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}></div>
                <div style={{
                    position: 'absolute',
                    width: '15px',
                    height: '15px',
                    backgroundImage: `url(${borderradius})`,
                    left: '-14px',
                    top: '-1px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    transform: 'scaleX(-1)'
                }}></div>
                <div style={{
                    position: 'absolute',
                    width: '15px',
                    height: '15px',
                    backgroundImage: `url(${borderradius})`,
                    right: '-14px',
                    top: '-1px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}></div>
                <div
                    className={"ios__front-camera-inner"}
                    style={{
                        backgroundColor: "#000",
                        borderBottomLeftRadius: "30px",
                        borderBottomRightRadius: "30px",
                        width: "100%",
                        height: "100%",
                        marginTop: '-1px'
                    }}
                />
            </div>
            <div
                className={"ios__toolbar-indicator"}
                style={{
                    ...toolbarSideStyle, ...{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        // marginRight:'3px'
                        // marginRight: `${emulator.indicatorMargin}px`,
                        marginRight: '0px',
                        borderRadius: '15px 0 0 0'
                    }
                }}
            >
                <img
                    // src={iosStatusBar}
                    src={ smartBanner ? indicator : Color(colors.toolBarBackground).isDark() ? indicator : iosStatusBarInverted}
                    alt={"Status bar"}
                    style={{
                        // width: "100%"
                        width: `${emulator.toolbarIndicator}%`,
                        marginTop: `${emulator.toolbarIndicator === 125 ? '5px' : '0px'}`,
                        transform: `${emulator.toolbarIndicator === 125 ? 'translateX(8px)' : 'none'}`
                    }}
                />
            </div>
        </div>
    );
});