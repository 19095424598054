import { Icon } from '@expo/vector-icons/build/createIconSet';
import { useEffect, useState } from 'react';
import * as Icons from '@expo/vector-icons';

export type IconsData = Readonly<{
    iconSet: Icon<any, string>,
    allKeys: string[]
    searchMeta: {
        tags: string,
        collections: Partial<Record<'solid' | 'regular' | 'light' | 'brands', string[]>>
    },
    iconPreview: string
    title: string,
    key: string,
    totalElements: number
}>

function setup(item: Pick<IconsData, 'iconSet' | 'searchMeta' | 'title' | 'iconPreview'>): Promise<Omit<IconsData, 'key'>> {
    const allKeys = Object.keys(item.iconSet.glyphMap || item.iconSet.getRawGlyphMap())
    const loadFontPromise = item.iconSet.loadFont && item.iconSet.loadFont() || Promise.resolve()
    return loadFontPromise.then(() => ({
        ...item,
        allKeys,
        totalElements: allKeys.length,
    }))
}

interface SyncIconFontDes {
    (): Promise<IconsData>;

    key: string
}

function makeSyncKey(key: string, val: () => Promise<Omit<IconsData, 'key'>>): SyncIconFontDes {
    const wrapper = (() => val().then(data => ({ ...data, key }))) as SyncIconFontDes
    wrapper.key = key
    return wrapper
}

// const AntDesign = makeSyncKey('ant-design', () => Promise.resolve(Icons)
//     .then(icons => icons.AntDesign)
//     .then(iconSet => setup({
//         iconSet,
//         title: 'Ant design',
//         iconPreview: 'shoppingcart',
//         searchMeta: {
//             tags: 'ant/design/light',
//             collections: {},
//         },
//     })))

// const Entypo = makeSyncKey('entypo', () => Promise.resolve(Icons)
//     .then(icons => icons.Entypo)
//     .then(iconSet => setup({
//         iconSet,
//         title: 'Entypo icons',
//         iconPreview: 'cart',
//         searchMeta: {
//             tags: 'entypo/icons',
//             collections: {},
//         },
//     })))

// const EvilIcons = makeSyncKey('evil-icons', () => Promise.resolve(Icons)
//     .then(icons => icons.EvilIcons)
//     .then(iconSet => setup({
//         iconSet,
//         title: 'Evil icons',
//         iconPreview: 'cart',
//         searchMeta: {
//             tags: 'evil/icons',
//             collections: {},
//         },
//     })))

const Feather = makeSyncKey('feather', () => Promise.resolve(Icons)
    .then(icons => icons.Feather)
    .then(iconSet => setup({
        iconSet,
        title: 'Feather icons',
        iconPreview: 'feather',
        searchMeta: {
            tags: 'feather/icons',
            collections: {},
        },
    })))

// const FontAwesome = makeSyncKey('font-awesome', () => Promise.resolve(Icons)
//     .then(icons => icons.FontAwesome)
//     .then(iconSet => setup({
//         iconSet,
//         title: 'Awesome icons',
//         iconPreview: 'cart-arrow-down',
//         searchMeta: {
//             tags: 'awesome/icons',
//             collections: {},
//         },
//     })))

// const FontAwesome5 = makeSyncKey('font-awesome', () => Promise.all([
//     Promise.resolve(Icons).then(icons => icons.FontAwesome5),
//     import('@expo/vector-icons/build/vendor/react-native-vector-icons/glyphmaps/FontAwesome5Free_meta.json'),
// ])
//     .then(([iconSet, collections]) => {
//         return setup({
//             iconSet,
//             title: 'Awesome icons',
//             iconPreview: 'opencart',
//             searchMeta: {
//                 tags: 'awesome5/icons',
//                 collections,
//             },
//         })
//     }))

// const Fontisto = makeSyncKey('fontisto', () => import('@expo/vector-icons')
//     .then(icons => icons.Fontisto)
//     .then(iconSet => setup({
//         iconSet,
//         title: 'Fontisto',
//         iconPreview: 'opencart',
//         searchMeta: {
//             tags: 'fontisto/icons',
//             collections: {},
//         },
//     })))

// const Foundation = makeSyncKey('foundation', () => import('@expo/vector-icons')
//     .then(icons => icons.Foundation)
//     .then(iconSet => setup({
//         iconSet,
//         title: 'Foundation',
//         iconPreview: 'shopping-cart',
//         searchMeta: {
//             tags: 'foundation/icons',
//             collections: {},
//         },
//     })))

const Ionicons = makeSyncKey('ionicons', () => Promise.resolve(Icons)
    .then(icons => icons.Ionicons)
    .then(iconSet => setup({
        iconSet,
        title: 'Ionicons',
        iconPreview: 'logo-ionic',
        searchMeta: {
            tags: 'ionicons/icons',
            collections: {},
        },
    })))

export const MaterialCommunityIcons = makeSyncKey('material-community', () => Promise.resolve(Icons)
    .then(icons => icons.MaterialCommunityIcons)
    .then(iconSet => setup({
        iconSet,
        title: 'Material Community Icons',
        iconPreview: 'google',
        searchMeta: {
            tags: 'material/community/icons',
            collections: {},
        },
    })))

const MaterialIcons = makeSyncKey('material', () => Promise.resolve(Icons)
    .then(icons => icons.MaterialIcons)
    .then(iconSet => {    
        return setup({
        iconSet,
        title: 'Material Icons',
        iconPreview: 'android',
        searchMeta: {
            tags: 'material/icons',
            collections: {},
        },
    });}
    ))

// const Octicons = makeSyncKey('octicons', () => Promise.resolve(Icons)
//     .then(icons => icons.Octicons)
//     .then(iconSet => setup({
//         iconSet,
//         title: 'Octicons',
//         iconPreview: 'home',
//         searchMeta: {
//             tags: 'octicons/icons',
//             collections: {},
//         },
//     })))

// const SimpleLineIcons = makeSyncKey('simple-line-icons', () => import('@expo/vector-icons')
//     .then(icons => icons.SimpleLineIcons)
//     .then(iconSet => setup({
//         iconSet,
//         title: 'Simple Line Icons',
//         iconPreview: 'home',
//         searchMeta: {
//             tags: 'simple/line/icons',
//             collections: {},
//         },
//     })))

// const Zocial = makeSyncKey('zocial', () => import('@expo/vector-icons')
//     .then(icons => icons.Zocial)
//     .then(iconSet => setup({
//         iconSet,
//         title: 'Zocial',
//         iconPreview: 'cart',
//         searchMeta: {
//             tags: 'zocial/line/icons',
//             collections: {},
//         },
//     })))

const AllEnabledIcons: SyncIconFontDes[] = [
    MaterialIcons,
    MaterialCommunityIcons,
    Feather,
    Ionicons,
]

const PopularItems: Promise<IconsData> = MaterialCommunityIcons().then(iconSet => ({
    ...iconSet,
    title: 'Favorite icons',
    iconPreview: 'bookmark-check-outline',
    allKeys: ['home', 'format-list-bulleted', 'cart', 'cart-outline', 'shopping-outline', 'account', 'auto-fix', 'bank', 'book',
        'calendar', 'diamond-stone', 'email', 'flag', 'gamepad-left'],
}))

const AllCategories: Promise<IconsData[]> = Promise.all([
    // PopularItems,
     ...AllEnabledIcons.map(o => o())
] as const)


export enum FlatIconType {
    IconTitle,
    IconsGroup
}

export type FlatIconWrapper = FlatIconRowWrapper | FlatIconCollectionTitleWrapper

type FlatIconRowWrapper = {
    type: FlatIconType.IconsGroup
    iconPackData: IconsData
    icons: string[]
}

export type FlatIconCollectionTitleWrapper = {
    type: FlatIconType.IconTitle
    iconPackData: IconsData
    flatIndexStart: number
    flatIndexEnd: number
}

export function useAllIconsFlat(itemsPerGroup: number) {

    const [isLoading, setIsLoading] = useState(true)
    const [items, setItems] = useState<FlatIconWrapper[]>([])
    const [collections, setCollections] = useState<FlatIconCollectionTitleWrapper[]>([])
    const [rawIcons, setRawIcons] = useState<IconsData[]>([])

    useEffect(() => {
        let invalidate = false
        AllCategories
            .then(icons => {
                setRawIcons(icons)
                return buildFlatList(icons, itemsPerGroup)
            }).then(input => {
                if (!invalidate) {
                    setItems(input.flatItems)
                    setCollections(input.collections)
                    setIsLoading(false)
                }
            })

        return () => {
            invalidate = true
        }
    }, [itemsPerGroup])

    return [items, collections, rawIcons, isLoading] as const
}

export function buildFlatList(icons: IconsData[], itemsPerGroup: number, search: string | null = null) {
    const collections: FlatIconCollectionTitleWrapper[] = []
    const trimmedSearch = search === null ? null : search.trim().toLowerCase()
    const flatItems = icons.reduce<FlatIconWrapper[]>((flat, iconPackData) => {
        let iconsKeys: string[] = iconPackData.allKeys;

        if (trimmedSearch !== null) {
            if (iconPackData.searchMeta.tags.includes(trimmedSearch)) {
                iconsKeys = iconPackData.allKeys
            } else if (iconPackData.title.toLowerCase().includes(trimmedSearch)) {
                iconsKeys = iconPackData.allKeys
            } else {
                iconsKeys = iconPackData.allKeys.filter(key => key.includes(trimmedSearch))
            }
        }

        if (iconsKeys.length) {
            const groupCount = Math.ceil(iconsKeys.length / itemsPerGroup)
            const collection: FlatIconCollectionTitleWrapper = {
                type: FlatIconType.IconTitle,
                iconPackData,
                flatIndexStart: flat.length,
                flatIndexEnd: flat.length + groupCount + 1,
            }

            flat.push(collection)
            collections.push(collection)

            let group = 0
            while (group < groupCount) {
                const startIndex = group * itemsPerGroup
                const endIndex = Math.min((group + 1) * itemsPerGroup, iconsKeys.length)

                flat.push({
                    type: FlatIconType.IconsGroup,
                    iconPackData,
                    icons: iconsKeys.slice(startIndex, endIndex),
                })

                group++
            }
        }
        return flat
    }, [])

    return {
        flatItems,
        collections,
    }
}

const fontsByKey = AllEnabledIcons.reduce((map, fontDes) => {
    if (map.has(fontDes.key)) {
        throw new Error('Not unique font key')
    }
    map.set(fontDes.key, fontDes)
    return map
}, new Map<string, SyncIconFontDes>())

const DEFAULT_FONT = AllEnabledIcons[0]

export function useFont(key: string = DEFAULT_FONT.key) {
    const [font, setFont] = useState<IconsData>()

    useEffect(() => {
        const font = fontsByKey.get(key) || DEFAULT_FONT
        let actual = true
        font().then(fontData => {
            if (actual) {
                setFont(fontData)
            }
        })

        return () => {
            actual = false
        }
    }, [key])

    const loading = !font
    return [font, loading] as const
}

const EmptyIcon = (() => null) as any as Icon<any, string>

export function useFontComponent(key: string = DEFAULT_FONT.key): Icon<any, string> {
    const [font] = useFont(key)

    return font?.iconSet || EmptyIcon
}