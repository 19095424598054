import { StyleSheet } from 'react-native'

export const IconPickerListStyle = {
    minHeight: 200,
    maxHeight: 400,
    width: 250,
    height: 250
}

export const IconPickerStyle = StyleSheet.create({
    iconListSize: IconPickerListStyle
})