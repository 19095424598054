import { WebEngine, createCommonTransport } from '@openland/spacex-web';
import { getEndpoint } from './getEndpoint';
import { ShopClient } from './spacex';

function createClientEndpoint(): string {
    let endpoint = getEndpoint();
    if (endpoint.startsWith('https:')) {
        endpoint = 'wss' + endpoint.substring('https'.length);
    } else if (endpoint.startsWith('http:')) {
        endpoint = 'ws' + endpoint.substring('http'.length);
    } else {
        throw Error('Unknown endpoint')
    }
    return endpoint + '/graphql';
}

export function createClient(token: string) {

    let endpoint = createClientEndpoint();

    const transport = createCommonTransport({
        url: endpoint,
        mode: 'transport-ws',
        connectionParams: { token },
    })

    return new ShopClient({
        engine: new WebEngine({
            definitions: require('./spacex.descriptor.json'),
            transport,
            logging: false
        })
    })
}

export function createUnAuthClient() {
    let endpoint = createClientEndpoint();

    const transport = createCommonTransport({
        url: endpoint,
        mode: 'transport-ws',
    })

    return new ShopClient({
        engine: new WebEngine({
            definitions: require('./spacex.descriptor.json'),
            transport,
            logging: false
        })
    })
}