import { Tag } from "@shopify/polaris";
import React from "react";
import { View } from "react-native";
import { Knob } from "../Knob";

export function renderProductTags(products: Knob.Product[], removeProduct: (product: Knob.Product) => void) {
    return products.map((product) => {
        return (
            <View style={{
                marginRight: 4, marginBottom: 4,
                maxWidth: '220px'
            }}>
                <Tag
                    key={product.id}
                    onRemove={() => { removeProduct(product); }}
                >
                    {product.title}
                </Tag>
            </View>
        );
    });
}