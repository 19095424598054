import React, { useCallback, useMemo } from 'react';
import {
    Controller,
    ControllerRenderProps, useFormContext, Validate,
    ValidationRule,
    ValidationValueMessage
} from 'react-hook-form';
import { SelectOption } from '../../StyledSelect/StyledSelect';
import { SelectChips, SelectChipsProps } from '../../Chips/SelectChips';
import { View } from 'react-native';

type PickedSelectChipsProps = Pick<SelectChipsProps, 'options'>

type Props = {
    name: string,
    defaultValue?: any
    rules?: Partial<{
        required: string | boolean | ValidationValueMessage<boolean>;
        min: ValidationRule<React.ReactText>;
        max: ValidationRule<React.ReactText>;
        maxLength: ValidationRule<React.ReactText>;
        minLength: ValidationRule<React.ReactText>;
        pattern: ValidationRule<RegExp>;
        validate: Validate | Record<string, Validate>;
        valueAsNumber: boolean;
        valueAsDate: boolean;
        setValueAs: (value: any) => any;
    }> | undefined
} & PickedSelectChipsProps

const RenderSelect = React.forwardRef<View, ControllerRenderProps & PickedSelectChipsProps>(({name, options, value, onChange, ...props}, ref) => {
    const selectedValueMap = useMemo(() => (value as string[] || []).reduce<Record<any, boolean>>((map, value) => {
        map[value] = true
        return map
    }, {}), [value])

    const onSelectChange = useCallback((opt: SelectOption) => {
        let newValue:string[]
        if (selectedValueMap[opt.value]) {
            newValue = Object.keys(selectedValueMap).filter(key => key !== opt.value)
        } else {
            newValue = Object.keys(selectedValueMap)
            newValue.push(opt.value)
        }
        onChange(newValue.length ? newValue : undefined)
    }, [onChange, selectedValueMap])

    return <SelectChips {...props}
                        ref={ref}
                        options={options}
                        onSelect={onSelectChange}
                        valueMap={selectedValueMap}/>
})

export const FormSelectChips = React.memo<Props>(React.forwardRef(({name, defaultValue, rules, options}, ref) => (
    <Controller
        ref={ref}
        render={({ref, ...valueProps}) => <RenderSelect ref={ref} {...valueProps} options={options}/>}
        defaultValue={defaultValue}
        name={name}
        rules={rules}
    />
)))