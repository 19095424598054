import { Badge } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
    View,
    Text,
    TouchableOpacity,
    AppState,
    ViewStyle,
    StyleProp,
    TextStyle
} from 'react-native';

const DEFAULT_DIGIT_STYLE = { backgroundColor: '#FAB913' };
const DEFAULT_DIGIT_TXT_STYLE = { color: '#000' };
const DEFAULT_TIME_LABEL_STYLE = { color: '#000' };
const DEFAULT_SEPARATOR_STYLE = { color: '#000' };
const DEFAULT_TIME_TO_SHOW = ['D', 'H', 'M', 'S'];
const DEFAULT_TIME_LABELS = {
    d: 'Days',
    h: 'Hours',
    m: 'Minutes',
    s: 'Seconds',
};

export const Timer = React.memo((props: {
    until: number;
    running?: boolean;
    digitStyle?: StyleProp<ViewStyle>;
    digitTxtStyle?: StyleProp<TextStyle>;
    size?: number;
    onFinish?: () => void,
    onChange?: (until: number) => void,
    timeLabelStyle?: StyleProp<TextStyle>,
    separatorStyle?: StyleProp<TextStyle>,
    timeToShow?: string[],
    timeLabels?: { d: string, h: string, m: string, s: string },
    showSeparator?: boolean,
    onPress?: () => void
}) => {
    // console.log('[Timer] props', props);
    // props = {
    //     ...{
    //         digitStyle: DEFAULT_DIGIT_STYLE,
    //         digitTxtStyle: DEFAULT_DIGIT_TXT_STYLE,
    //         timeLabelStyle: DEFAULT_TIME_LABEL_STYLE,
    //         timeLabels: DEFAULT_TIME_LABELS,
    //         separatorStyle: DEFAULT_SEPARATOR_STYLE,
    //         timeToShow: DEFAULT_TIME_TO_SHOW,
    //         showSeparator: false,
    //         secondsLength: 0,
    //         size: 15,
    //         running: true,
    //     }, ...props
    // }

    const [time, setTime] = useState<{
        secondsLeft: number,
        lastSecondsLeft?: number,
        wentBackgroundAt?: number
    }>
        ({
            lastSecondsLeft: undefined,
            secondsLeft: Math.max((props.until - Date.now()) / 1000, 0),
            wentBackgroundAt: 0,
        });

    useEffect(() => {
        setTime(
            {
                lastSecondsLeft: undefined,
                secondsLeft: Math.max((props.until - Date.now()) / 1000, 0),
                wentBackgroundAt: 0,
            }
        );
    }, [props.until]);

    const updateTimer = useCallback(() => {
        // console.log('[Timer] updateTimer', time, props.running);

        if (time.lastSecondsLeft === time.secondsLeft || !props.running) {
            // console.log('[Timer] updateTimer not running');
            return;
        }

        if (time.secondsLeft === 1 || (time.secondsLeft === 0 && time.lastSecondsLeft !== 1)) {
            if (props.onFinish) {
                props.onFinish();
            }
            if (props.onChange) {
                props.onChange(time.secondsLeft);
            }
            // console.log('[Timer] finished');
        }

        if (time.secondsLeft === 0) {
            // console.log('[Timer] finished');
            setTime({
                lastSecondsLeft: 0,
                secondsLeft: 0,
                wentBackgroundAt: time.wentBackgroundAt
            });
        } else {
            if (props.onChange) {
                props.onChange(time.secondsLeft - 1);
            }
            const newVal = {
                lastSecondsLeft: time.secondsLeft,
                secondsLeft: Math.max(0, (props.until - Date.now()) / 1000),
                wentBackgroundAt: time.wentBackgroundAt
            };
            // console.log('[Timer] NEW VAL', newVal);
            setTime(newVal);
        }
    }, [time, props]);

    useEffect(() => {
        const timerId = setInterval(updateTimer, 1000);
        AppState.addEventListener('change', _handleAppStateChange);
        return () => {
            clearInterval(timerId);
            AppState.removeEventListener('change', _handleAppStateChange);
        }
    }, [updateTimer]);

    const _handleAppStateChange = useCallback((currentAppState) => {
        console.log('_handleAppStateChange', currentAppState);
        const { secondsLeft: until, wentBackgroundAt } = time;
        if (currentAppState === 'active' && wentBackgroundAt && props.running) {
            const diff = (Date.now() - wentBackgroundAt) / 1000.0;
            setTime({
                ...time, ...{
                    lastSecondsLeft: until,
                    secondsLeft: Math.max(0, (until || 0) - diff)
                }
            });
        }
        if (currentAppState === 'background') {
            setTime({ ...time, ...{ wentBackgroundAt: Date.now() } });
        }
    }, [time, props]);

    const getTimeLeft = useCallback(() => {
        const until = (time.secondsLeft || 0);
        return {
            seconds: Math.floor(until % 60).toString().padStart(2, '0'),
            minutes: Math.floor(until / 60 % 60).toString().padStart(2, '0'),
            hours: Math.floor(until / (60 * 60) % 24).toString().padStart(2, '0'),
            days: Math.floor(until / (60 * 60 * 24)).toString().padStart(2, '0'),
        };
    }, [time]);

    const renderDigit = useCallback((d) => {
        const { digitStyle, digitTxtStyle, size } = props;
        return (
            <View style={[
                {
                    width: size! * 2.3,
                    height: size! * 2.6,
                    borderRadius: 5,
                    marginHorizontal: 2,
                    alignItems: 'center', justifyContent: 'center'
                },
                digitStyle || DEFAULT_DIGIT_STYLE,
            ]}>
                <Text style={[
                    { fontSize: size, color: 'white', fontWeight: 'bold', fontVariant: ['tabular-nums'] },
                    digitTxtStyle || DEFAULT_DIGIT_TXT_STYLE,
                ]}>
                    {d}
                </Text>
            </View>
        );
    }, [props]);

    const renderLabel = useCallback((label) => {
        const { timeLabelStyle, size } = props;
        if (label) {
            return (
                <Text style={[
                    { fontSize: size! / 2, color: 'white', marginVertical: 2, backgroundColor: 'transparent', },
                    timeLabelStyle || DEFAULT_TIME_LABEL_STYLE,
                ]}>
                    {label}
                </Text>
            );
        }
    }, [props]);

    const renderDoubleDigits = useCallback((label, digits) => {
        return (
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {renderDigit(digits)}
                </View>
                {renderLabel(label)}
            </View>
        );
    }, [renderDigit]);

    const renderSeparator = useCallback(() => {
        return (
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Text style={[
                    { fontSize: (props.size || 15) * 1.2, backgroundColor: 'transparent', fontWeight: 'bold', },
                    props.separatorStyle || DEFAULT_SEPARATOR_STYLE,
                ]}>
                    {':'}
                </Text>
            </View>
        );
    }, [props]);

    const timeLabels = props.timeLabels || DEFAULT_TIME_LABELS;
    const timeToShow = props.timeToShow || DEFAULT_TIME_TO_SHOW;
    const { days, hours, minutes, seconds } = useMemo(() => getTimeLeft(), [time]);
    console.log('TIMELAbELS ', days, hours, minutes, seconds);

    if (((timeToShow.join('') === 'DHMS' || timeToShow.join('') === 'MS') && days === '00' && hours === '00' && minutes === '00' && seconds === '00') || ((timeToShow.join('') === 'DHM' || timeToShow.join('') === 'HM' || timeToShow.join('') === 'M') && days === '00' && hours === '00' && minutes === '00') || ((timeToShow.join('') === 'DH' || timeToShow.join('') === 'H') && days === '00' && hours === '00') || (timeToShow.join('') === 'D' && days === '00') || (time.secondsLeft === 0)) {
        return (
            <View style={{
                flex: 1,
                justifyContent: 'center', alignItems: 'center'
            }}>
                <Badge status={'info'}>
                    {'Finished'}
                </Badge>
            </View>
        );
    }

    return (
        <View
            style={{
                flexDirection: 'row',
                justifyContent: 'center', flex: 1, alignItems: 'center'
            }}
        >
            {
                timeToShow?.includes('D')
                    ? renderDoubleDigits(timeLabels.d, days)
                    : null
            }
            {
                props.showSeparator
                    && timeToShow.includes('D')
                    && timeToShow.includes('H')
                    ? renderSeparator()
                    : null
            }
            {
                timeToShow?.includes('H')
                    ? renderDoubleDigits(timeLabels.h, hours)
                    : null
            }
            {
                props.showSeparator
                    && timeToShow.includes('H')
                    && timeToShow.includes('M')
                    ? renderSeparator()
                    : null
            }
            {
                timeToShow?.includes('M')
                    ? renderDoubleDigits(timeLabels.m, minutes)
                    : null
            }
            {
                props.showSeparator
                    && timeToShow.includes('M')
                    && timeToShow.includes('S')
                    ? renderSeparator()
                    : null
            }
            {
                timeToShow?.includes('S')
                    ? renderDoubleDigits(timeLabels.s, seconds)
                    : null
            }
        </View>
    );
});

