import React from "react";
import { useHistory } from "react-router";

export const SettingsItem = React.memo(({
    url,
    backgroundImage,
    title,
    description
}: {
    url: string,
    backgroundImage: any,
    title: string,
    description: string
}) => {
    const history = useHistory();

    return (
        <li className="settings-item" style={{
            display: 'flex',
            padding: '16px',
            cursor: 'pointer'

        }} onClick={() => {
            history.push(url);
        }}>
            <div className="settings-item-icon" style={{
                backgroundColor: '#f4f6f8',
                width: "40px",
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: "center",
                borderRadius: "3px",
                marginRight: '16px'
            }}>
                <div style={{
                    width: '20px',
                    height: '20px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundImage: `url(${backgroundImage})`,
                }}>

                </div>
            </div>
            <div className="settings-info">
                <div className="settings-title" style={{
                    color: '#006fbb',
                    fontWeight: 600
                }}>
                    {title}
                </div>
                <div className="settings-text" style={{
                    color: '#6d7175',
                }}>
                    {description}
                </div>
            </div>
        </li>
    );
});
