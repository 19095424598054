import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useClient } from "../api/useClient";
import { PushMailingInput, PushMailingPlatform } from "../api/spacex.types";
import { useAccessControl } from "../configure/utils/useAccessControl";
import { Card, ComplexAction, FooterHelp, Form, Heading, Layout, Link, Page, Stack, Toast } from "@shopify/polaris";
import { useInfoBanner } from "../components/banners/useInfoBanner";
import { TipKey } from "../components/banners/useTip";
import { animated, config, useTransition } from "@react-spring/native";
import { TrialModal } from "../configure/modals/TrialModal";
import { Push } from "./Push";
import { Capability } from "../utils/enums";
import { EditPushMailingForm, PushMailing } from "./EditPsuhMailingForm";
import { useHistory } from "react-router";
import moment from "moment";
import { PushLimitComponent } from "./PushLimitComponent";
import { MixpanelEvent, trackEvent } from "../utils/mixpanel";

export const CreatePushMailing = React.memo(() => {
    const client = useClient();
    const ac = useAccessControl();

    const history = useHistory();
    const limit = client.useAccounPricingLimit({ key: 'pushPerWeek' }).accountPricingLimit;
    const [pushMailing, setPushMailing] = useState<PushMailing>();

    const [loading, setLoading] = useState<boolean>(false);
    const [savedToastActive, setSavedToastActive] = useState(false);
    const toggleSavedToastActive = useCallback(() => setSavedToastActive((active) => !active), []);

    const handleSubminAction = useCallback(() => {
        if (!pushMailing) {
            return;
        }
        setLoading(true);
        let inputData: PushMailingInput = { ...pushMailing, scheduledAt: undefined, image: undefined };
        if (!pushMailing.title.trim()) {
            throw new Error("Invalid title");
        }
        if (ac.can('user').createOwn(Capability.PushNotificationsImage).granted) {
            inputData.image = pushMailing.image;
        }
        if (ac.can('user').createOwn(Capability.PushNotificationsScheduled).granted) {
            inputData.scheduledAt = pushMailing.scheduledAt?.getTime().toString() || undefined;
        }
        client.mutateCreatePushMailing({
            input: inputData
        }).then((res) => {
            toggleSavedToastActive();
            setLoading(false);
            client.refetchAccounPricingLimit({ key: 'pushPerWeek' });
            trackEvent(MixpanelEvent.CreatePush, { scheduled: inputData.scheduledAt || false });
            return client.refetchListPushMailing({ first: 8, after: undefined })
                .then(() => {
                    history.push({ pathname: '/push/mailing' });
                })
                .catch(console.warn);
        }).catch(() => { setLoading(false); });
        // TODO send by platform 
    }, [pushMailing]);

    const saveButtonActive = useMemo(() => {
        return (!!pushMailing?.title.trim()) && (limit ? (limit.count < limit.limit) : false);
    }, [pushMailing, limit]);

    const [banner] = useInfoBanner(
        TipKey.PushNotificationsInfo,
        'Send Push Notifications!',
        'Push Notifications allow you to engage customers, retarget lost customers, build relationships with personalized ads and banners, increase customer retention and conversion rates (higher than email marketing) while generating more leads'
    );

    const closeBanner = useTransition(banner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    });

    const infoBanner = closeBanner(
        (style, banner) => banner
            && <animated.View style={[{ marginBottom: '2rem' }, style]}>
                <Card>{banner}</Card>
            </animated.View>
    );

    const [formBanner] = useInfoBanner(
        TipKey.PushNotificationsInfo,
        'Customize your Push Notification',
        'Cartmate allows you to target customers by platform including, All/Android/iOS. Fields like: title, message, content image, and navigation route (Product or Collection) can be edited here'
    );

    const closeFormBanner = useTransition(formBanner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    });

    const formInfoBanner = closeFormBanner(
        (style, banner) => banner
            && <animated.View style={[{}, style]}>
                <Card>{formBanner}</Card>
            </animated.View>
    );

    const savedToastMarkup = savedToastActive ? (
        <Toast content="Mailing saved!" onDismiss={toggleSavedToastActive} />
    ) : null;

    const [primaryAction, setPrimaryAction] = useState<ComplexAction>({
        content: 'Save and send',
        onAction: handleSubminAction,
        disabled: !saveButtonActive
    });

    useEffect(() => {
        setPrimaryAction((prevValue) => {
            return { ...prevValue, content: pushMailing?.scheduledAt ? 'Save and schedule' : 'Save and send', onAction: handleSubminAction, disabled: !saveButtonActive }
        })
    }, [pushMailing, handleSubminAction, saveButtonActive]);

    const nextSyncDate = moment(new Date(
        parseInt(String(limit?.lastSyncDate || Date.now())) +
        (parseInt(String(limit?.period || 0)) * 1000)
    ));
    return (
        <Page
            title={"Create push mailing"}
            breadcrumbs={[
                { url: '/push/mailing' }
            ]}
        >
            {infoBanner}
            <Layout>
                <Layout.Section>
                    <Form onSubmit={handleSubminAction}>
                        <Card
                            title={(
                                <Stack distribution={'leading'} alignment={'center'}>
                                    <Stack.Item fill>
                                        <Heading>Push mailing</Heading>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <PushLimitComponent />
                                    </Stack.Item>
                                </Stack>
                            )}
                            primaryFooterAction={primaryAction}
                        >
                            {formBanner && <Card.Section>
                                {formInfoBanner}
                            </Card.Section>}
                            <EditPushMailingForm
                                onChange={(value) => {
                                    setPushMailing(value);
                                }}
                            />
                        </Card>
                    </Form>
                </Layout.Section>
                <Layout.Section secondary>
                    <Push
                        title={pushMailing?.title || ''}
                        message={pushMailing?.message || ''}
                        selectedPlatform={pushMailing?.platform || PushMailingPlatform.ALL}
                        image={pushMailing?.image || ''}
                    />
                </Layout.Section>
                <TrialModal />
                {savedToastMarkup}
            </Layout>
            <FooterHelp>
                Learn more about{' '}
                <Link onClick={() => {
                    history.push({ pathname: '/about/push' });
                }}>
                    Cartmate push notifications
                </Link>
            </FooterHelp>
        </Page>
    );
});