import React from 'react';
import { StyleSheet, View } from 'react-native';
import { FormSelect } from '../../../components/Form/Components/FormSelect';
import { SelectOption } from '../../../components/StyledSelect/StyledSelect';
import { Heading, TextContainer } from '@shopify/polaris';
import { useFormContext } from 'react-hook-form';

const options: SelectOption<string>[] = [
    { value: 'fb', label: 'Facebook' },
    { value: 'shp', label: 'Shopify' },
    { value: 'insta', label: 'Instagram' },
    { value: 'tw', label: 'Twitter' },
    { value: 'rd', label: 'Reddit' },
    { value: 'ggl', label: 'Google' },
    { value: 'friends', label: 'Friends' },
    { value: 'colleagues', label: 'Colleagues' },
    { value: 'other', label: 'Other' },
];

const style = StyleSheet.create({
    title: {
        marginBottom: 8
    },
    caption: {
        marginBottom: 8
    },
    captionError: {
        color: 'red'
    },
});

const validate = (data?: string) => !!data?.length;

export const WhatIsYourReferrer = React.memo(() => {
    const context = useFormContext<{ referrer: string }>();
    const error = context.errors['referrer'];
    return (
        <View>
            <View style={{ marginBottom: 8 }}>
                <TextContainer>
                    <Heading>
                        📢 &nbsp; How did you hear about us?
                    </Heading>
                </TextContainer>
            </View>
            <FormSelect
                name={'referrer'}
                rules={{ ...{ validate }, ...{ required: false } }}
                isSearchable={true}
                placeholder={'How did you hear about us'}
                options={options}
            />
        </View>
    )
})