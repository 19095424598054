import { Button, Card, Layout, Page, TextStyle } from "@shopify/polaris";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { Suspense } from "../../../components/Suspense";
import { HelpItem } from "../../help/components/HelpItem";
import { AddCartmatePoints } from "./AddCartmatePoints";
import { BuyPointsModal } from "./BuyPointsModal";
import { PointsBalance } from "./PointsBalance";
import { PointsTransactions } from "./PointsTransactions";

export const CartmatePointsDashboard = React.memo(() => {    
    const [active, setActive] = useState<boolean>(false);
    const handleToggleModal = useCallback(() => { setActive(!active) }, [active]);    
    return (
        <Page
            title={'Cartmate Points'}
            breadcrumbs={[{ content: 'Promote', url: '/promote' }]}
            titleMetadata={
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                    <View style={{ marginRight: 4 }}>
                        <Suspense>
                            <PointsBalance />
                        </Suspense>
                    </View>
                    <BuyPointsModal 
                        activator={(<Button primary plain onClick={handleToggleModal}>{'Buy more'}</Button>)} 
                        open={active}
                        onClose={handleToggleModal}
                        setOpen={setActive}
                    />
                    
                </View>
            }
        >
            <Layout>
                <Layout.Section>
                    <PointsTransactions />
                </Layout.Section>
            </Layout>
        </Page>
    );
});