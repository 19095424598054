import * as React from 'react';
import { SyncValue } from '@openland/patterns';
import { debounce } from 'ts-debounce'

export function useSaver<T>(initial: T, handler: (value: T) => Promise<void>) {
    const [value, loading, setValue, doSync] = useManualSaver(initial, handler)

    const debDoSync = React.useCallback(debounce(doSync, 500), [doSync])
    const callback = React.useCallback((value: T) => {
        setValue(value);
        debDoSync()
    }, [debDoSync]);
    return [value, loading, callback] as const
}

export function useManualSaver<T>(initial: T, handler: (value: T) => Promise<void>) {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [value, setValue] = React.useState(initial);
    const sync = React.useMemo(() => new SyncValue<T>(initial, async (src: T) => {
        setLoading(true);
        console.log('useManualSaver', src);
        await handler(src);
        setLoading(false);
    }), [handler]);

    const doSync = React.useCallback(() => setValue(val => {
        sync.value = val
        return val
    }), [sync])

    return [value, loading, setValue, doSync] as const
}