import { Stack, TextStyle } from "@shopify/polaris";
import React from "react";
import { View } from "react-native";
import { FriendlyStores_friendlyStores } from "../../../api/spacex.types";
import { SocialType } from "../../Settings/components/SocialsInput";

const friendlyIcon = require('../../../assets/images/friendly-store-icon.svg');
const communityIcon = require('../../../assets/images/community-store-icon.svg');

export const CommunityStoreItem = React.memo((
    {
        item,
        friendly
    }: {
        item: FriendlyStores_friendlyStores,
        friendly: boolean
    }) => {

    return (
        <Stack vertical>
            <Stack.Item>
                <View style={{ flexDirection: 'row', alignItems: 'center', flexGrow: 1, justifyContent: 'space-between' }}>
                    <p><TextStyle variation={'strong'}>{item.contact.data.name}</TextStyle></p>
                    <div style={{
                        textAlign: 'center',
                        width: '32px',
                        height: '32px',
                        backgroundImage: `url(${friendly ? friendlyIcon : communityIcon})`,
                        backgroundSize: 'cover',
                    }} />
                </View>
            </Stack.Item>
            <Stack.Item>
                <Stack>
                    {(item.contact.data.email && item.contact.data.email.length > 0) && (
                        <Stack.Item>
                            <p>Email: <TextStyle variation={'subdued'}>{item.contact.data.email}</TextStyle></p>
                        </Stack.Item>
                    )}
                    {(item.contact.data.phoneNumber && item.contact.data.phoneNumber.length > 0) && (
                        <Stack.Item>
                            <p>Phone: <TextStyle variation={'subdued'}>{item.contact.data.phoneNumber}</TextStyle></p>
                        </Stack.Item>
                    )}
                    {(item.contact.data.socials && item.contact.data.socials.length > 0) && (
                        <Stack.Item>
                            <Stack distribution={'fill'}>
                                <Stack.Item>
                                    <p>Facebook:
                                        <TextStyle variation={'subdued'}>
                                            {
                                                ` @${item
                                                    .contact
                                                    .data
                                                    .socials
                                                    ?.find((s) => s.socialName === SocialType.Facebook)?.socialId}`
                                            }
                                        </TextStyle>
                                    </p>
                                </Stack.Item>
                                <Stack.Item>
                                    <p>Instagram:
                                        <TextStyle variation={'subdued'}>
                                            {
                                                ` @${item
                                                    .contact
                                                    .data
                                                    .socials
                                                    ?.find((s) => s.socialName === SocialType.Instagram)?.socialId}`
                                            }
                                        </TextStyle>
                                    </p>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    )}
                </Stack>
            </Stack.Item>
        </Stack>
    );
});