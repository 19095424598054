import React, { useCallback } from 'react'
import { allThemes, darkThemes, lightThemes, Theme, ThemeSet } from './themes/themes';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { ThemeDescriptor } from './model/theme';
import { useInfoBanner } from '../../components/banners/useInfoBanner';
import { TipKey } from '../../components/banners/useTip';
import { animated, config, useTransition } from '@react-spring/native';
import { Card, Stack } from '@shopify/polaris';
import { useSetRecoilState } from 'recoil';
import { themeStateAtom, ThemeType } from '../../content/emulator/States';
import { MixpanelEvent, trackEvent } from '../../utils/mixpanel';
import { borderRadius } from 'react-select/src/theme';

const iosDarkTheme = require('../../assets/images/ios-dark-theme.png');
const iosLightTheme = require('../../assets/images/ios-light-theme.png');

type Props = { theme: Theme, onChange: (theme: Theme) => void }
type ThemeSetProps = { themeSet: ThemeSet, onChange: (themeSet: ThemeSet) => void }

type ThemeViewProps = Props & {
    desc: ThemeDescriptor<any>
}

const style = StyleSheet.create({
    box: {
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    selected: {
        borderColor: '#4487fb',
        color: '#4487fb',
        fontWeight: '600',
    },
    notSelected: {
        // borderColor: '#DFE3E8',
        borderColor: 'transparent',
        //color: '#9c9c9c',
        opacity: 0.9
    },
    preview: {
        width: 88,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 3,
        overflow: 'hidden',
    },
    title: {
        alignSelf: 'center',
        marginTop: 2,
    },
    wrapper: {
        flexDirection: 'column',
    },
    header: {
        height: 16,
    },
    contentAccent: {
        width: 33,
        height: 16,
        marginVertical: 8,
        marginLeft: 10,
        borderRadius: 2,
    },
    footer: {
        height: 16,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    dote: {
        height: 8,
        width: 8,
        borderRadius: 4,
    },
})

const ThemeView = React.memo<ThemeViewProps>(({ theme, desc, onChange }) => {
    const isSelected = theme.type === desc.key
    const onPress = useCallback(() => {
        if (!isSelected) {
            onChange(desc.default)
        }
    }, [isSelected, desc, onChange])

    const resolvedThem = desc.resolve(desc.default)

    return (
        <TouchableOpacity onPress={onPress} style={style.wrapper}>
            <View style={[
                !isSelected && style.notSelected,
                isSelected && style.selected,
                { borderWidth: 2, borderRadius: 5, }
           ]}>
                <View
                    style={[
                        style.preview,
                        // !isSelected && style.notSelected,
                        // isSelected && style.selected,
                        { backgroundColor: resolvedThem.background, borderWidth: isSelected ? 1 : 1 }
                    ]}>
                    <View style={[style.header, { backgroundColor: resolvedThem.toolBarBackground }]} />
                    <View style={[style.contentAccent, { backgroundColor: resolvedThem.accent }]} />
                    <View style={[style.footer, { backgroundColor: resolvedThem.bottomNavigationBackground }]}>
                        <View style={[style.dote, { backgroundColor: resolvedThem.accent }]} />
                        <View style={[style.dote, { backgroundColor: '#BDBDBD' }]} />
                        <View style={[style.dote, { backgroundColor: '#BDBDBD' }]} />
                        <View style={[style.dote, { backgroundColor: '#BDBDBD' }]} />
                    </View>
                </View>
            </View>
            <View style={[style.title]}>
                <Text style={[
                    isSelected && style.selected,
                    !isSelected && style.notSelected
                ]}>{desc.name}</Text>
            </View>
        </TouchableOpacity>
    )
});

export const ThemeChooser = React.memo<Props>((props) => (
    <View style={style.box}>
        {allThemes.map(desc => <ThemeView {...props} desc={desc} key={desc.key} />)}
    </View>
));

export const ThemeSetChooser = React.memo<ThemeSetProps>((props) => {
    const setThemeType = useSetRecoilState(themeStateAtom);
    const onThemeSelected = useCallback(
        (theme: Theme, isDark: boolean) => {
            trackEvent(MixpanelEvent.ThemeChooser, { theme: theme });
            console.log('theme.onThemeSelected', props.themeSet);
            console.log('theme.accent', theme.accent);
            if (isDark) {
                setThemeType(ThemeType.Dark);
                props.onChange({
                    light: props.themeSet.light,
                    dark: {
                        ...theme,
                        accent: props.themeSet.dark.accent
                    }
                });
                return;
            }
            setThemeType(ThemeType.Light);
            props.onChange({
                light: {
                    ...theme,
                    accent: props.themeSet.dark.accent
                },
                dark: props.themeSet.dark
            });
        },
        [props],
    );

    const [banner] = useInfoBanner(
        TipKey.ThemeInfo,
        'Improve your customers experience',
        'In iOS and Android there are options for your screens theme. Here you can choose two themes for your app based on accent color.'
    );

    const closeBanner = useTransition(banner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    })

    const infoBanner = closeBanner(
        (style, banner) => banner
            && <animated.View style={[{ marginBottom: '2rem' }, style]}>
                <Card>{banner}</Card>
            </animated.View>
    );

    return (
        <View>
            {infoBanner}
            <Stack distribution={"equalSpacing"} spacing={"loose"}>
                <Stack alignment={"trailing"} spacing={"extraTight"}>
                    <Stack.Item>
                        <View style={{ marginRight: 4 }}>
                            <div className="ios__light_theme" style={{
                                textAlign: 'center',
                                width: '60px',
                                height: '120px',
                                backgroundImage: `url(${iosLightTheme})`,
                                backgroundSize: 'cover',
                            }}>
                            </div>
                            <Text style={{ textAlign: 'center' }}>{'Light'}</Text>
                        </View>
                    </Stack.Item>
                    {lightThemes.map((desc, index) => <Stack.Item key={`lightThemes-${desc.key}-${index}`}>
                        <ThemeView
                            theme={props.themeSet.light}
                            onChange={(theme) => onThemeSelected(theme, false)}
                            desc={desc}
                            key={`${desc.key}`}
                        />
                    </Stack.Item>)}
                </Stack>
                <Stack alignment={"trailing"} spacing={"extraTight"}>
                    <Stack.Item>
                        <View style={{ marginRight: 4 }}>
                            <div className="ios__dark_theme" style={{
                                textAlign: 'center',
                                width: '60px',
                                height: '120px',
                                backgroundImage: `url(${iosDarkTheme})`,
                                backgroundSize: 'cover',
                            }}>
                            </div>
                            <Text style={{ textAlign: 'center' }}>{'Dark'}</Text>
                        </View>
                    </Stack.Item>
                    {darkThemes.map((desc, index) => <Stack.Item key={`darkThemes-${desc.key}-${index}`}>
                        <ThemeView
                            theme={props.themeSet.dark}
                            onChange={(theme) => onThemeSelected(theme, true)}
                            desc={desc}
                            key={desc.key}
                        />
                    </Stack.Item>)}

                </Stack>
            </Stack>
        </View>
    );
});