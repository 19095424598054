import { Card, SkeletonBodyText, SkeletonDisplayText, TextContainer } from "@shopify/polaris";
import React from "react";

export const ResourceListSkeleton = React.memo(() => {
    return (
        <Card>
            <Card.Section>
                <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={2} />
                </TextContainer>
            </Card.Section>
            <Card.Section>
                <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={2} />
                </TextContainer>
            </Card.Section>
            <Card.Section>
                <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={2} />
                </TextContainer>
            </Card.Section>
            <Card.Section>
                <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={2} />
                </TextContainer>
            </Card.Section>
        </Card>
    );
});