import React from 'react';
import { useEmulatorConfig } from './ScalableEmulatorComponent';

const arrow = require('../../../assets/images/ios-arrow.svg');
const arrowInvert = require('../../../assets/images/arrow-invert.svg');

export const ExploreEmulatorTop = React.memo((props: {
    // background: string,
    // backgroundDarkened: string,
    // backgroundLightened: string,
    bgl: string,
    bgd: string
    selectedPlatform: string,
    title: string,
    image: string,
    description: string
}) => {
    const emulator = useEmulatorConfig();

    let textColor
    let topArrow

    if (props.bgl === '#fff') {
        textColor = '#000'
        topArrow = `url(${arrow})`
    } else {
        textColor = '#fff'
        topArrow = `url(${arrowInvert})`
    }

    return (
        <div className="ios__content-top" style={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'sticky',
            top: '0',
            // backgroundColor: props.background,
            backgroundColor: props.bgl,
            width: '100%',
            padding: '25px 0 20px 0',
            alignItems: 'center',
            height: '95px'
        }}>
            <div className="ios__content-top-arrow" style={{
                // backgroundImage: `url(${arrow})`,
                backgroundImage: topArrow,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                width: '24px',
                height: '44px',
                marginLeft: '26px'
            }}></div>
            <div className="ios__content-top-title" style={{
                // color: '#000',
                color: textColor,
                fontSize: `${emulator.fontSize}px`,
                fontWeight: 600,
                marginRight: '50%',
                transform: 'translateX(50%)'
            }}>
                Explore Stores
            </div>
        </div>
    )
})