import React, {useMemo, useState} from "react";

export const ProgressBar = React.memo(({progress}:{progress:number}) => {
    const [percents, setPercents] = useState(progress);

    const [progressColor, progressEmoji] = useMemo(()=>{
        let progressColor = 'red', progressEmoji = '🔥';
        if (percents > 60) {
            progressColor = 'yellow';
            progressEmoji = '⚠️'
        }
        if (percents > 90) {
            progressColor = 'green';
            progressEmoji = '🦄';
        }
        return [progressColor, progressEmoji];
    }, [percents]);


    let progressWidth = percents > 100 ? 100 : percents < 0 ? 0 : percents;
    return (
        <div className={"progressBar-wrapper"}>
            <div className={"progressBar-container"}>
                <div className={"progressBar-progress "+progressColor} style={{width: `${progressWidth}%`}}>
                    <span>{percents+"% "+progressEmoji}</span>
                </div>
            </div>
        </div>
    );
});