import React from "react";
import { AppEmulatorConfig, AppEmulatorPlatform, AppMenuTab } from "../types";
import { IconElement } from "../../elements/IconElement";
import { useEmulatorConfig } from "./ScalableEmulatorComponent";
import { Tooltip } from "@shopify/polaris";

export const AppEmulatorMenu = React.memo((
    { config, selectedPlatform }: { config: AppEmulatorConfig, selectedPlatform: AppEmulatorPlatform }
) => {
    const emulator = useEmulatorConfig();
    const colors = config.design.colors[emulator.theme];
    return (
        <Tooltip content='You can customize Bottom navigation panel via Menu tab. Here you can change items, icons and captions'>
        <div className={`${selectedPlatform}__tabs-inner`} style={{
            display: 'flex',
            flexDirection: 'row',
            // justifyContent: 'space-between',
            justifyContent: 'space-around',
            // margin: '0 30px',
            margin: '0px',
            fontSize: '20px'
        }}>
            {
                config.tabs.map((tab, index, array) => {
                    return (
                        <div key={`tab-${index}`} className={selectedPlatform + "__tabs-tab"}
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div className={selectedPlatform + "__tabs-icon"}
                                style={{
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '5px'
                                }}>
                                {tab.icon && <IconElement
                                    collection={tab.icon.collection as 'material' | 'ionicons' | 'feather' | 'material-community'}
                                    name={tab.icon.name}
                                    color={index === 0 ?
                                        colors.bottomNavigationAccent :
                                        colors.bottomNavigationInactiveIcon
                                    }
                                    size={40}
                                />}
                            </div>
                            <div
                                className={selectedPlatform + "__tabs-text"}
                                style={{
                                    color: index === 0 ?
                                        colors.bottomNavigationAccent :
                                        colors.bottomNavigationInactiveIcon,
                                    // fontSize: '25px'
                                    fontSize: '20px',
                                    letterSpacing:'0.8px',
                                    textAlign: 'center'
                                }}
                            >
                                {tab.title}
                            </div>
                        </div>
                    );
                })
            }
            <div className={selectedPlatform + "__tabs-tab"}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className={selectedPlatform + "__tabs-icon"}
                    style={{
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '5px'
                    }}>
                    {<IconElement
                        collection={'ionicons'}
                        name={'ellipsis-horizontal-outline'}
                        color={'rgb(155, 155, 155)'
                        }
                        size={40}
                    />}
                </div>
                <div
                    className={selectedPlatform + "__tabs-text"}
                    style={{
                        color: 'rgb(155, 155, 155)',
                        // fontSize: '25px'
                        fontSize: '20px',
                        letterSpacing:'0.8px'
                    }}
                >
                    More
                </div>
            </div>
        </div>
        </Tooltip>
    );
});