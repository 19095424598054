import { Button, Card, Collapsible, Heading, Icon, Stack, TextStyle } from "@shopify/polaris";
import { ThumbsUpMajor } from "@shopify/polaris-icons";
import React, { useCallback, useState } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { MixpanelEvent, trackEvent } from "../utils/mixpanel";
import { capitalize } from "../utils/textUtils";

export const ComingSoonCard = React.memo((
    {
        featureTitle,
        style,
        event,
        children
    }: {
        featureTitle?: string,
        style?: StyleProp<ViewStyle>
        event: MixpanelEvent,
        children: any
    }
) => {
    const localValue = parseInt(localStorage.getItem(event) || '0');
    const [alreadyAwesome, setAlreadyAwesome] = useState(localValue === 1);
    const [collapsed, setCollapsed] = useState(true);

    const sendAwesome = useCallback(
        () => {
            if (!alreadyAwesome) {
                setAlreadyAwesome(true);
                trackEvent(event);
                localStorage.setItem(event, '1');
            }
        },
        [alreadyAwesome],
    );

    return (
        <Card title={capitalize(`${featureTitle} feature`)}>
            <Card.Section>
                <Button
                    fullWidth
                    plain
                    onClick={() => setCollapsed(!collapsed)}
                >
                    {collapsed ? 'Show more' : 'Show less'}
                </Button>
            </Card.Section>
            <Card.Section>
                <Collapsible open={!collapsed} id={`coming-soon-${event}`}>
                    <View>
                        <View style={[
                            {
                                position: 'absolute', top: 0, bottom: 0, left: 0, right: 0,
                                backgroundColor: 'rgba(255, 255, 255, 0.79)',
                                zIndex: 100,
                                borderRadius: 8,
                                justifyContent: 'center',
                                alignItems: 'center'
                            },
                            style
                        ]}>
                            <Stack vertical alignment={'center'}>
                                <Heading>
                                    {capitalize(`${featureTitle ? featureTitle + ' ' : ''}coming soon`)}
                                </Heading>
                                <Button
                                    primary={alreadyAwesome}
                                    onClick={sendAwesome}
                                    icon={<Icon
                                        source={ThumbsUpMajor}
                                        color="base"
                                    />}
                                >
                                    {'Awesome!'}
                                </Button>
                            </Stack>
                        </View>
                        {children}
                    </View>
                </Collapsible>
            </Card.Section>
        </Card>
    );
});