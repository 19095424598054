// Load Bugsnag
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
Bugsnag.start({
    apiKey: 'e33e6ce7dc810c76dfec8ebbebb92c78',
    plugins: [new BugsnagPluginReact()]
});

// Load styles
import './assets/polaris.css';
import '@shopify/polaris/dist/styles.css';
import './assets/app.css';

// Track endpoint
import { getEndpoint } from './api/getEndpoint';
const endpoint = getEndpoint();
console.log('Endpoint: ' + endpoint);