import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { buildFlatList, FlatIconWrapper, IconsData } from './iconsData';
import { IconView } from '../../types';
import { StyleSheet, View, Text } from 'react-native';
import { a, useSpring } from '@react-spring/native';
import { AllIconsView } from './AllIconsView';
import { debounce } from 'ts-debounce';

type Props = {
    icons: IconsData[]
    onSelect: (val: IconView) => void
    value: IconView | null
    search: string | null,
    height?: number
}

const style = StyleSheet.create({
    wrapper: {
        position: 'relative', 
        overflow: 'hidden', 
        // maxHeight: '300px', 
        width: 250, 
        // height: 250,
        minHeight: 100
    },
    searchBody: {
        position: 'absolute',
        backgroundColor: '#fff',
        overflow: 'hidden',
        bottom: 0,
        borderRadius: 4,
        width: '100%'
    },
    noItems: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    }
})

export const FilteredIconsView = React.memo<React.PropsWithChildren<Props>>(
    ({icons, onSelect, value, search, children, height}) => {
        const [items, setItems] = useState<FlatIconWrapper[] | null>(null)

        const styleEnter = useSpring({
            from: {opacity: 0},
            to: items ? {opacity: 1} : {opacity: 0},
        })

        const debFilter = useCallback(debounce((
            icons: IconsData[],
            search: string | null,
            setItems: (flatIcons: FlatIconWrapper[] | null) => void) => {

            if (search === null || !search.trim()) {
                setItems(null)
            } else {
                setItems(buildFlatList(icons, 5, search).flatItems)
            }
        }, 300), [])

        useEffect(() => {
            debFilter(icons, search, setItems)
        }, [icons, search])

        const renderItems = useMemo(() => items || [], [items])

        const hasItems = !!renderItems.length

        return (
            <View style={[style.wrapper]}>
                {children}
                {search && <a.View style={[style.searchBody, styleEnter]}>
                    {hasItems && <AllIconsView height={height} flatIcons={renderItems} onSelect={onSelect} value={value}/>}
                    {!hasItems && <View style={[style.noItems, {height}]}><Text>No items</Text></View>}
                </a.View>}
            </View>
        )
    })