import React, { useContext } from "react";
import { PhonePreviewContext } from "./PhonePreviewContext";

export const TabContent = React.memo(() => {
    const renderPreviewContext = useContext(PhonePreviewContext);

    return (
        <>
            {renderPreviewContext.first.render()}
        </>
    );
});