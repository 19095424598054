import React, { useCallback, useEffect, useState } from 'react'
import { ViewConfig } from '../../configEditor/ConfigEditor';
import { ConfigBlock } from '../../configEditor/blocks/BlockTypes';
import { createBlock } from '../../configEditor/subRender/subRenderUtils';
import { setNewValueArray } from '../../configEditor/elementsPickerComponents/ElementsListUtils';
import { RenderDNDElementProps, SortableDndList } from '../../components/sortableDNDList/SortableDndList';
import { KnobsView } from '../../configEditor/knobs/KnobsView';
import { Sheet, EmptyState, Stack, Button, Icon, Card, Modal, UnstyledLink, TextStyle, Layout } from '@shopify/polaris'
import { BlocksChooser } from '../../configEditor/blocksChooser/BlocksChooser';
import { DeleteMinor, DragHandleMinor, EditMinor, HideMinor, ViewMinor, DuplicateMinor, BuyButtonMajor } from '@shopify/polaris-icons';
import { allBlocks } from '../../configEditor/blocks/allBloks';
import { usePageEditorPreview } from '../../configure/theme/preview/usePageEditorPreview';
import { useAccessControl } from '../../configure/utils/useAccessControl';
import { Capability } from '../../utils/enums';
import { Text, View } from 'react-native';
import { IconWrapper } from '../../components/Icon/IconView/Icons';

type Props = {
    blocks: ViewConfig.NodeBlockExtended[]
    onChange(newVal: ViewConfig.NodeBlockExtended[]): void
}

function getItemId(tab: ViewConfig.NodeBlockExtended) {
    return tab.id
}

export const PageBlocks = React.memo<Props>(({ blocks, onChange }) => {
    usePageEditorPreview(blocks)

    const [selectedIndex, setSelected] = useState<number | null>(null)

    const closeKnobs = useCallback(() => setSelected(null), [setSelected])

    const [chooserOpened, setChooserOpen] = useState(false)

    const closeChooser = useCallback(() => setChooserOpen(false), [setChooserOpen])
    const openChooser = useCallback(() => setChooserOpen(true), [setChooserOpen])

    const onChoose = useCallback((block: ConfigBlock, originComponentKey: string) => {
        const nodeBlock = createBlock(originComponentKey);
        onChange([nodeBlock, ...blocks])
        closeChooser()
        setSelected(0)
    }, [blocks, onChange, closeChooser, setSelected, createBlock])

    const onRemove = useCallback((node: ViewConfig.NodeBlockExtended) => {
        onChange(blocks.filter(b => b.id !== node.id))
    }, [blocks, onChange])

    const onDuplicate = useCallback((node: ViewConfig.NodeBlockExtended) => {
        let nodeBlock = createBlock(node.originComponentKey,)
        const component = allBlocks.get(node.originComponentKey);
        nodeBlock = { ...nodeBlock, ...{ src: node.src, modernRender: node.modernRender, knobs: node.knobs, title: !node?.title || node.title.length === 0 ? component?.Name + ' (copy)' : node.title + ' (copy)' } }
        onChange([nodeBlock].concat(blocks))
    }, [blocks, onChange])


    const onSelectBlock = useCallback((node: ViewConfig.NodeBlockExtended) => {
        const index = blocks.findIndex(n => node.id === n.id);
        setSelected(index === -1 ? null : index)
    }, [blocks, setSelected])

    const onNodeChange = useCallback((node: ViewConfig.NodeBlockExtended) => {
        console.log('[PageBlocks] onNodeChange', node)
        const index = blocks.findIndex(n => node.id === n.id)
        if (index !== -1) {
            const newValueArray = setNewValueArray(blocks, index, node);
            onChange(newValueArray)
        }
    }, [blocks, onChange, setNewValueArray])

    const selected = selectedIndex !== null && blocks[selectedIndex];
    console.log('SELECTED ', selected);


    const renCb = useCallback((props: RenderDNDElementProps<ViewConfig.NodeBlockExtended>) => renderItem(props, onSelectBlock, onRemove, onDuplicate), [onSelectBlock, onRemove, onDuplicate])

    return (
        <>
            <SortableDndList items={blocks} getKey={getItemId} onChangeList={onChange} renderItem={renCb} />
            {blocks.length ? <div style={{ padding: '0 20px 20px 20px' }}>
                <Button onClick={openChooser}>Add item</Button>
            </div> : <EmptyState
                heading="Manage your page content"
                action={{ content: 'Add content', onAction: openChooser }}
                image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png" />}
            <Modal
                large={true}
                open={!!selected && !!selected.knobs}
                onClose={closeKnobs}
                title={'Customize block'}
                primaryAction={{
                    content: "Save",
                    onAction: closeKnobs
                }}
            >
                {selected && <KnobsView node={selected} onUpdate={onNodeChange} onCancel={closeKnobs} />}
            </Modal>
            <Modal large={true} open={chooserOpened} onClose={closeChooser} title={'Choose content block to add'}>
                <BlocksChooser onChoose={onChoose} onCancel={closeChooser} />
            </Modal>
        </>
    )
},
)

function renderItem({
    entity,
    provided,
}: RenderDNDElementProps<ViewConfig.NodeBlockExtended>, onSelectBlock: (node: ViewConfig.NodeBlockExtended) => void, onRemove: (node: ViewConfig.NodeBlockExtended) => void, onDuplicate: (node: ViewConfig.NodeBlockExtended) => void) {
    const { originComponentKey } = entity;
    const component = allBlocks.get(originComponentKey);

    const onClick = useCallback(() => onSelectBlock(entity), [entity, onSelectBlock]);
    const onRemoveCb = useCallback(() => onRemove(entity), [entity, onRemove]);
    const onDuplicateCb = useCallback(() => onDuplicate(entity), [entity, onDuplicate]);

    const ac = useAccessControl();

    console.log('ENTITY ', entity);


    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div onClick={onClick} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '20px', width: '70%' }}>
                <div>
                    <IconWrapper>
                        <Icon source={component?.Icon!} />
                    </IconWrapper>
                </div>
                <div>
                    <Text>
                        {
                            !entity?.title || entity.title.length === 0
                                ? component?.Name
                                : entity.title
                        }
                    </Text>
                </div>
            </div>
            <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'flex-end', marginRight: '10px' }}>
                {(
                    ac.can('user').updateOwn(Capability.MainMenu).granted
                    && ac.can('user').updateOwn(Capability.MoreMenu).granted
                )
                    && (
                        <Stack.Item>
                            <Stack.Item>
                                <Button
                                    plain
                                    onClick={onClick}
                                    icon={<Icon source={EditMinor} />}
                                />
                            </Stack.Item>
                        </Stack.Item>
                    )}
                <Stack.Item>
                    <Button
                        plain
                        onClick={onDuplicateCb}
                        icon={<Icon source={DuplicateMinor} />}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Button
                        plain
                        onClick={onRemoveCb}
                        icon={<Icon source={DeleteMinor} />}
                    />
                </Stack.Item>
                <Stack.Item>
                    <div {...provided.dragHandleProps}>
                        <Button
                            plain
                            icon={<Icon source={DragHandleMinor} />}
                        />
                    </div>
                </Stack.Item>
            </div>
        </div>
    )
}


