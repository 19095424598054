import React, { Component, CSSProperties } from 'react';
import { stringify } from 'querystringify';

type Props = {
    ifrRef: React.RefObject<HTMLIFrameElement>,
    public: string
    endpoint?: string,
    device?: 'iphone4s' | 'iphone5s' | 'iphone6' | 'iphone6plus' |
     'ipadair' | 'iphone6s' | 'iphone6splus' | 'ipadair2' | 'nexus5' |
    'nexus7' | 'nexus9' | 'pixel4' | 'iphone11pro'
    version?: string
    scale?: number,
    orientation?: 'portrait' | 'landscape'
    color?: 'white' | 'black'
    screen?: boolean,
    xdoc?: boolean,
    debug?: boolean,
    language?: string
    locale?: string
    location?: string
    link?: string
    proxy?: string
    adb?: boolean,
    apk?: boolean,
    autoplay?: boolean,
    params?: Object,
    deviceColor: 'black' | 'white'
    style?: CSSProperties,
}

export class Appetizer extends Component<Props> {
    /**
     * Generate the URL on which the application is hosted.
     *
     * @returns {String} The API endpoint.
     * @private
     */
    url() {
        return (this.props.endpoint || 'https://appetize.io/embed/') + this.props.public;
    }

    /**
     * Generate the query string with the correct options.
     *
     * @param {Object} props Properties we need to use.
     * @returns {String} The query string for the url.
     * @private
     */
    query(props:Props) {
        const data: any = {};

        data.device = props.device;
        data.scale = props.scale;
        data.orientation = props.orientation;
        data.autoplay = props.autoplay ? 'true' : 'false';
        data.deviceColor = props.color;

        if (props.language) data.language = props.language;
        if (props.location) data.location = props.location;
        if (props.version) data.osVersion = props.version;
        if (props.locale) data.locale = props.locale;
        if (props.screen) data.screenOnly = 'true';
        if (props.xdoc) data.xdocMsg = 'true';
        if (props.debug) data.debug = 'true';
        if (props.adb) data.enableAdb = 'false';
        if (props.apk) data.androidPackageManager = 'false';
        if (props.link) data.launchUrl = props.link;

        if (typeof props.params === 'object') {
            data.params = JSON.stringify(props.params);
        }

        return stringify(data, true);
    }

    /**
     * Renders the <iframe> element that embeds the device.
     *
     * @returns {Component} React Component to render.
     * @public
     */
    render() {
        const props = this.props;
        const url = this.url() + this.query({
            endpoint: 'https://appetize.io/embed/',
            device: 'iphone5s',
            scale: 100,
            orientation: 'portrait',
            autoplay: false,
            color: 'black',
            screen: false,
            xdoc: false,
            ...props
        });

        const ifrProps = {
            frameBorder: '0',
            scrolling: 'no',
            style: props.style,
            ref: props.ifrRef
        }

        return (
            <iframe {...ifrProps} src={url}/>
        );
    }
}