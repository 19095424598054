import Bugsnag from "@bugsnag/js";
import { animated, config, useTransition } from "@react-spring/native";
import { Badge, Card, Layout, Page, SettingToggle, TextStyle } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router";
import { AccountContactType, AccountPricingStatus, AppCardPublishedStatus, AppCardStatus } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { useInfoBanner } from "../../../components/banners/useInfoBanner";
import { TipKey } from "../../../components/banners/useTip";
import { EmulatorSuspense } from "../../../components/Suspense";
import { AppCard, AppCardPublished } from "../../../components/types";
import { AppCardComponent } from "../../../configure/listing/AppCardComponent";
import { useAccount } from "../../../configure/utils/useAccount";
import { useAppCard } from "../../../configure/utils/useAppCard";
import { ExploreScreen } from "../../../content/emulator/components/ExploreScreen";
import { IPhoneEmulatorContent } from "../../../content/emulator/components/IPhoneEmulatorContent";
import { ScalableEmulatorComponent } from "../../../content/emulator/components/ScalableEmulatorComponent";
import { AppEmulatorConfig } from "../../../content/emulator/types";
import { MixpanelEvent, trackEvent } from "../../../utils/mixpanel";
import { TrafficExchangeContactForm } from "../../Settings/components/TrafficExchangeContactForm";
import { mapStatusDescription, PromoteStatuses, PromoteStatusType } from "../utils";

export enum PublicationStatus {
    Published = 'Shop Published',
    Unpublished = 'Shop Unpublished',
    OnReview = 'Waiting for Review',
}

export const TrafficExchangeProfile = React.memo(() => {
    const client = useClient();
    const account = useAccount();
    const { id } = useParams<{ id: string }>();
    const contacts = client.useContacts().contacts;

    const [initialAppCard, initialPublishedCard] = useAppCard(id);
    const [publishedAppCard, setPublishedAppCard] = useState<AppCardPublished | null>(initialPublishedCard);
    const [appCard, setAppCard] = useState<AppCard>(initialAppCard);

    const app = client.useApp({ id }).app;
    const appConfig = client.useAppEmulatorConfig({ id }).app.draftConfig as AppEmulatorConfig;

    let publicationBadge;
    let publicationStatus;
    if (
        appCard.status !== AppCardStatus.ON_REVIEW
        && publishedAppCard?.status === AppCardPublishedStatus.ACTIVE
    ) {
        publicationBadge = (<Badge status={"success"}>{PublicationStatus.Published}</Badge>);
        publicationStatus = PublicationStatus.Published;
    } else if (
        appCard.status !== AppCardStatus.ON_REVIEW
        && publishedAppCard?.status === AppCardPublishedStatus.INACTIVE
    ) {
        publicationBadge = (<Badge status={"critical"}>{PublicationStatus.Unpublished}</Badge>);
        publicationStatus = PublicationStatus.Unpublished;
    } else if (appCard.status === AppCardStatus.ON_REVIEW) {
        publicationBadge = (<Badge status={"warning"}>{PublicationStatus.OnReview}</Badge>);
        publicationStatus = PublicationStatus.OnReview;
    }

    let [freeTrafficStatus, freeTrafficStatusDescr] = mapStatusDescription(
        account.pricing.status,
        app.freeTrafficActive,
        publicationStatus
    );

    const handleFreeTrafficEnabledChange = useCallback(
        (active: boolean) => {
            client.mutateSetFreeTraffic({ id: id, active: active })
                .then(() => {
                    client.refetchApp({ id: id })
                }).catch((e) => {
                    Bugsnag.notify(e);
                });
        },
        [id, client],
    );

    const [banner] = useInfoBanner(
        TipKey.FreeTrafficInfo,
        'Request Cartmate Traffic Exchange program publication',
        'Fill out and submit the form, we will review it shortly and your shop will be availible in the \'Explore Cartmate\''
    );

    const closeBanner = useTransition(banner, {
        from: { opacity: 0, maxHeight: 1000 },
        enter: { opacity: 1, maxHeight: 1000 },
        leave: { opacity: 0, maxHeight: 0 },
        config: config.default,
    });

    const infoBanner = closeBanner(
        (style, banner) => banner
            && <animated.View style={[{ marginBottom: '2rem' }, style]}>
                <Card>{banner}</Card>
            </animated.View>
    );

    return (
        <Page title={'Traffic Exchnage Profile'} breadcrumbs={[{ content: 'Promote', url: '/promote' }]}>
            <Layout>
                <Layout.Section>
                    {!publishedAppCard && infoBanner}
                    <Card>
                        {/* <Card.Section> */}
                        <SettingToggle
                            action={{
                                destructive: app.freeTrafficActive,
                                content: app.freeTrafficActive ? "Disable" : "Enable",
                                onAction: () => {
                                    // TODO: wrap this with modal
                                    trackEvent(
                                        MixpanelEvent.FreeTrafficToggle,
                                        { enabled: !app.freeTrafficActive }
                                    );
                                    handleFreeTrafficEnabledChange(!app.freeTrafficActive);
                                }
                            }}
                        >
                            Traffic Exchange Status: <Badge
                                status={freeTrafficStatus === PromoteStatusType.Active ? 'success' : 'warning'}
                            >
                                {freeTrafficStatus}
                            </Badge><br />
                            {freeTrafficStatusDescr}
                        </SettingToggle>
                        {/* </Card.Section> */}
                    </Card>
                    {__DEV__ && (
                        <Card sectioned title={'Constact information'}>
                            <label>{'Your contact info that will be visible only for Traffic Exchange Program participants'}</label>
                            <TrafficExchangeContactForm
                                contact={contacts?.find((c) => c.type === AccountContactType.TrafficExchange)}
                            />
                        </Card>
                    )}
                    <AppCardComponent onCardChange={(card) => {
                        console.log('[AppPromote] onCardChange', card);
                        setAppCard(card);
                    }} />
                </Layout.Section>
                <Layout.Section secondary>
                    {/* <Suspense> */}
                    <ScalableEmulatorComponent>
                        <IPhoneEmulatorContent
                            appId={id}
                            config={appConfig}
                            showNavigation={false}
                            colored={false}
                            bannerScreen={false}
                        >
                            <EmulatorSuspense>
                                <ExploreScreen
                                    card={appCard}
                                    config={appConfig}
                                />
                            </EmulatorSuspense>
                        </IPhoneEmulatorContent>
                    </ScalableEmulatorComponent>
                </Layout.Section>
            </Layout>
        </Page>
    );
})