import { FormLayout } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { useClient } from "../../../api/useClient";
import { StyledSelect, StyledSelectRef } from "../../../components/StyledSelect/StyledSelect";
import { Knob } from "../Knob";

export const KnobTab = React.memo<Knob.KnobComponentProps<Knob.KnobTabDescriptor>>(({ knob, value: initValue, onChange, innerKey }) => {
    const { description } = knob
    const [value, setValue] = useState(initValue);
    const client = useClient();
    const ref = React.createRef<StyledSelectRef>();
    const apps = client.useAllApps();
    const tabs = client.useAppTabsConfig({ id: apps.apps[0].id }).app.draftConfig.tabsDesigner;
    const options = tabs
        .map((tab) => {
            return {
                value: { id: tab.key, title: tab.title, contentUrl: tab.contentUrl },
                label: tab.title
            }
        });

    // useEffect(() => {
    //     if (value !== initValue) {
    //         onChange(value, innerKey)
    //     }
    // }, [value])

    return (
        <FormLayout>
            <label>{description}</label>
            <StyledSelect
                ref={ref}
                options={options}
                onChange={(item) => {
                    onChange({ contentUrl: item?.value.contentUrl }, innerKey);
                }}
            />
        </FormLayout>
    );
});