import { FormLayout, Icon, Labelled, Popover, ResourceItem, ResourceList, TextField, TextStyle, Thumbnail } from "@shopify/polaris";
import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { debounce } from "ts-debounce";
import { ListProductsVariables, ListProducts_listProducts, ListProducts_listProducts_edges_node, ProductsSorts, Product_product_Product } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { CleanSpaceX } from "../../../components/types";
import { Knob } from "../Knob";

type ProductFilters = {
    pageSize: number,
    query?: string
}

type Product = CleanSpaceX<Product_product_Product>;

export const KnobProductPicker = React.memo<Knob.KnobComponentProps<Knob.KnobProductPickerDescriptor>>((
    { knob, innerKey, value: initialValue, onChange, disabled }
) => {
    const client = useClient();
    const [popoverActive, setPopoverActive] = useState(false);
    const [value, setValue] = useState<Knob.PickedProduct>();
    const [filters, setFilters] = useState<ProductFilters>({ pageSize: knob.pageSize || 10 });
    const [results, setResults] = useState<ListProducts_listProducts>();
    const [list, setList] = useState<ListProducts_listProducts_edges_node[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const loadPage = useCallback((params: ListProductsVariables, replace: boolean = false) => {
        setLoading(true);
        client.queryListProducts(params)
            .then((result) => {
                setResults(result.listProducts);
                const products = result.listProducts.edges.map(e => e.node);
                setList(replace ? products : list.concat(products));
            })
            .catch(console.warn)
            .finally(() => { setLoading(false); });
    }, [client, loading, list]);

    const productToValue = useCallback((product: Product | ListProducts_listProducts_edges_node) => {
        return product.id;
    }, []);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((prevValue) => {
            return !prevValue
        }),
        [knob, innerKey],
    );
    const handleProductSelect = useCallback((product: Product) => {
        const newValue = productToValue(product);
        setValue(newValue);
        handleFiltersChange("query", product.title);
        onChange(newValue, innerKey);
        togglePopoverActive();
    }, [innerKey, onChange]);

    const debFilter = useCallback(
        debounce(
            (filters: ProductFilters, lp: (params: ListProductsVariables, replace: boolean) => Promise<any> | any) => {
                lp({ first: filters.pageSize, query: filters.query, after: undefined, sort: ProductsSorts.DEFAULT }, true)
            },
            300
        ), []);


    const handleFiltersChange = useCallback((field: keyof ProductFilters, newValue) => {
        setFilters((prevState) => {
            let newState = { ...prevState, [field]: newValue };
            return newState;
        });
    }, []);

    const handleScrolledToBottom = useCallback(() => {
        if (loading) {
            return;
        }
        if (results?.pageInfo.hasNextPage) {
            loadPage({
                first: filters.pageSize,
                after: results?.pageInfo.endCursor,
                query: filters.query,
                sort: ProductsSorts.DEFAULT
            }, false);
        }
    }, [results, filters, loading]);

    useEffect(() => {
        if (initialValue && initialValue !== value) {
            client.queryProduct({ id: initialValue.trim() })
                .then((result) => {
                    if (result.product.__typename === "Product") {
                        setValue(productToValue(result.product));
                        handleFiltersChange("query", result.product.title);
                    }
                })
                .catch(console.warn);
        }
    }, [initialValue]);

    useEffect(() => {
        debFilter(filters, loadPage);
    }, [filters]);

    const handleClear = useCallback(() => {
        handleFiltersChange("query", '');
        setValue('');
        onChange('', innerKey)
    }, [innerKey, onChange]);

    const activator = (
        <div className={'knob_picker_activator'}>
            <TextField
                label={knob.description}
                labelHidden
                value={filters.query}
                onChange={(query) => {
                    handleFiltersChange("query", query);
                }}
                onFocus={() => { !disabled ? setPopoverActive(true) : undefined; }}
                autoComplete={'off'}
                disabled={disabled}
                clearButton={knob.clerable || false}
                onClearButtonClick={handleClear}
                placeholder={"Pick a product"}
                suffix={(
                    <div style={{ cursor: 'pointer' }}>
                        <Icon
                            source={popoverActive ? ChevronUpMinor : ChevronDownMinor}
                            color="base"
                        />
                    </div>
                )}
            />
        </div>
    );
    return (
        <FormLayout>
            <Labelled id={innerKey} label={knob.description}>
                <Popover
                    activator={activator}
                    active={popoverActive}
                    onClose={togglePopoverActive}
                    fullWidth
                    preferredAlignment={'left'}
                    sectioned
                    ariaHaspopup={false}
                >

                    <Popover.Pane
                        onScrolledToBottom={handleScrolledToBottom}
                    >
                        <View style={{ maxHeight: filters.pageSize * 50 }}>
                            <ResourceList
                                items={list}
                                renderItem={renderProductItem}
                            />
                        </View>

                    </Popover.Pane>

                </Popover>
            </Labelled>
        </FormLayout>
    );

    function renderProductItem(node: ListProducts_listProducts_edges_node) {
        return (
            <ResourceItem
                id={node.id}
                onClick={() => { handleProductSelect(node) }}
                media={<Thumbnail size={"medium"} source={node.images ? (node.images[0]?.src || '') : ''} alt={node.title} />}
                verticalAlignment={"center"}
            >
                <h3><TextStyle>{node.title}</TextStyle></h3>
            </ResourceItem>
        );
    }
});