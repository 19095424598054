import React, { useCallback } from 'react'
import { View, TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import { allBlocks } from '../blocks/allBloks';
import { ConfigBlock } from '../blocks/BlockTypes';
import { Heading, Button, Layout, TextField } from '@shopify/polaris'
import { MobileCancelMajor } from '@shopify/polaris-icons';

const allBlocksArray: [string, ConfigBlock][] = []

allBlocks.forEach((block, originComponentKey) => {
    allBlocksArray.push([originComponentKey, block])
})


const style = StyleSheet.create({
    container: {
        padding: 20,
        flexDirection: 'column',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingBottom: 20,
    },
    element: {
        marginBottom: 15
    }
})

type Props = {
    onChoose: (block: ConfigBlock, originComponentKey: string) => void
    onCancel: () => void
}

const BlockElement = React.memo<{ block: ConfigBlock, onChoose: Props['onChoose'], originComponentKey: string }>(
    ({ onChoose, block, originComponentKey }) => {
        const onPress = useCallback(() => onChoose(block, originComponentKey), [block, originComponentKey])
        return <TouchableOpacity onPress={onPress} style={style.element}>
            <block.Preview />
        </TouchableOpacity>
    })

export const BlocksChooser = React.memo<Props>(({ onChoose, onCancel }) => (
    <ScrollView style={style.container}>
        {allBlocksArray.map(([originComponentKey, block], index) => (
            <BlockElement key={index} block={block} originComponentKey={originComponentKey} onChoose={onChoose} />
        ))}
    </ScrollView>
))