import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';

export const TextEditor = React.memo((props: { id?: string }) => {
    return (
        <>
            <Editor
                id={props.id}
                init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                        'link image',
                        'table paste'
                    ],
                    toolbar: 'formatselect |' + 'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'image | removeformat',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
        </>
    );
});