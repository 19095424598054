import React, { useContext, useEffect, useMemo } from 'react';
import { PhonePreviewContext, PhonePreviews, PhonePreviewsAccent } from './PhonePreviewContext';
import { ViewConfig } from '../../../configEditor/ConfigEditor';
import { ContentViewer } from '../../../content/ContentViewer';
import { View } from 'react-native';
import { useClient } from '../../../api/useClient';

export function usePageEditorPreview(blocks: ViewConfig.NodeBlockExtended[]) {
    const renderPreviewContext = useContext(PhonePreviewContext)

    useEffect(() => {
        const mediator = renderPreviewContext.pushNewPreview<ViewConfig.NodeBlockExtended[]>((blocks) => {
            return <ShowBlocks blocks={blocks}/>
        }, blocks, PhonePreviewsAccent.DEFAULT, PhonePreviews.PAGE_EDITOR)
        return () => {
            mediator.destroy()
        }
    }, [blocks])
}

export const ShowPageContent = React.memo<{ pageId: string }>(({pageId}) => {
    const client = useClient()
    const page = client.usePage({id: pageId}).page
    const blocks = useMemo(() => ViewConfig.getBlocks(page), [page]);

    return <ShowBlocks blocks={blocks}/>
})

export const ShowBlocks = React.memo<{ blocks: ViewConfig.NodeBlockExtended[] }>(({blocks}) => (
    <View>
        {blocks.map(node => <ContentViewer key={node.id} src={node.modernRender || node.src} width={'100%'}/>)}
    </View>
))