import { Badge, Button, Icon, Stack, TextStyle, UnstyledLink } from "@shopify/polaris";
import { DeleteMinor, DuplicateMinor, EditMinor, NoteMinor } from "@shopify/polaris-icons";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { useHistory, useRouteMatch } from "react-router";
import { ContentPageType, Pages_pages } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { IconWrapper } from "../../../components/Icon/IconView/Icons";
import { ViewConfig } from "../../../configEditor/ConfigEditor";
import { useAccessControl } from "../../../configure/utils/useAccessControl";
import { Capability } from "../../../utils/enums";
import { DeletePageModal } from "./DeletePageModal";

export const PageItem = React.memo(({ page }: { page: Pages_pages }) => {
    const client = useClient();
    const match = useRouteMatch();
    const history = useHistory();
    const ac = useAccessControl();
    const permited = ac.can('user').updateOwn(Capability.MainMenu).granted;
    const [delModalOpened, setDelModalOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    const onDuplicate = useCallback(
        (p: Pages_pages) => {
            setLoading(true)
            client.mutateCreatePage({
                input: ViewConfig.PageToInputPage.encode({
                    id: 'new',
                    name : `${p.name} (copy)`,
                    type: ContentPageType.BLOCK,
                    blocks: JSON.parse(p.draftData),
                    contentPageId: 'new'
                }),
            }).then(() => {
                client.refetchPages();
            }).finally(()=>{
                setLoading(false)
            })
        },
        [client, page],
    );

    return (
        <View style={{
            height: "40px",
            justifyContent: 'center',
            paddingHorizontal: '20px',
            marginVertical: '6px'
        }}>
            <Stack alignment="center" distribution="center">
                <Stack.Item fill>
                    <UnstyledLink url={`${match.url}/${page.id}`}>
                        {permited && (
                            <Stack alignment='center'>
                                <Stack.Item>
                                    <IconWrapper>
                                        <Icon
                                            source={NoteMinor}
                                            color="base" />
                                    </IconWrapper>
                                </Stack.Item>
                                <Stack.Item fill>
                                    <TextStyle variation="strong">{page.name}</TextStyle>
                                    {(!page.publishedData || page.draftData !== page.publishedData) &&
                                        <Badge status='attention' progress="partiallyComplete">unpublished</Badge>}
                                </Stack.Item>
                            </Stack>
                        )}
                        {!permited && (
                            <Stack alignment="center">
                                <Stack.Item fill>
                                    <TextStyle variation='subdued'>{page.name}</TextStyle>
                                    {(!page.publishedData || page.draftData !== page.publishedData) &&
                                        <Badge status='attention' progress="partiallyComplete">unpublished</Badge>}
                                </Stack.Item>
                            </Stack>
                        )}
                    </UnstyledLink>
                </Stack.Item>
                <Stack.Item>
                    <Button
                        plain
                        onClick={() => history.push(`${match.url}/${page.id}`)}
                        icon={<Icon source={EditMinor} />}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Button
                        plain
                        loading={loading}
                        onClick={() => onDuplicate(page)}
                        icon={<Icon source={DuplicateMinor} />}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Button
                        plain
                        onClick={() => setDelModalOpened(true)}
                        icon={<Icon source={DeleteMinor} />}
                    />
                </Stack.Item>
            </Stack>
            <DeletePageModal
                open={delModalOpened}
                onClose={() => setDelModalOpened(false)}
                pageName={page.name}
                pageId={page.id}
            />
        </View>
    );
});