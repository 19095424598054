import { Modal, Heading } from "@shopify/polaris";
import React from "react";
import { View, Text } from "react-native";
import { OnboardingModal, OnboardingType } from "../modals/OnboardingModal";

const bannerOnboarding = require('../../assets/gifs/onboarding-banner.gif');

export const BannerOnboarding = React.memo(() => {
    return (
        <OnboardingModal
            title={'Banner customization'}
            _key={OnboardingType.Banner}
        >
            <Modal.Section>
                <View style={{
                    flexDirection: 'row',
                    flex: 1, flexWrap: 'wrap',
                    justifyContent: 'center'
                }}>
                    <img
                        style={{
                            display: 'flex',
                            flex: 1,
                            maxHeight: '400px',
                            maxWidth: '400px'
                        }}
                        src={bannerOnboarding}
                        alt=''
                    />
                    <View style={{
                        flex: 1,
                        maxHeight: '400px',
                        maxWidth: '400px',
                        margin: '16px'
                    }}>
                        <View style={{ marginBottom: '10px' }}>
                            <Heading>
                                {'Create a Smart banner to engage with your customers and convert them from your website'}
                            </Heading>
                            <Text>
                                {"Smart banner appears automatically when a customer enters your store from a smartphone and prompts them to install a mobile app and go to your in-app store. With a mobile app, you'll have a much easier time reminding the customer about your store with push notifications and motivating them to come back to shop. You can make a Smart banner with your own design to bring it closer to the design of your site. If you don't fill in some fields, their blocks will be gracefully removed from a real banner scheme. You can always enable or disable a Smart banner in the corresponding menu item."}
                            </Text>
                        </View>
                    </View>
                </View>
            </Modal.Section>
        </OnboardingModal>
    );
});