import { Card, Select, Stack, TextStyle } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { Suspense } from "../../../components/Suspense";
import { Chart as PerformanceChart } from "./Chart";
import { PointsSummury } from "./PointsSummury";

export const TrafficExchangeDashboard = React.memo(() => {
    const [selectedType, setSelected] = useState('views');
    const [selectedPeriod, setSelectedPeriod] = useState('30');
    const handleSelectTypeChange = useCallback((value) => setSelected(value), []);
    const handleSelectPeriodChange = useCallback((value) => setSelectedPeriod(value), []);

    return (
        <Card sectioned>
            <Stack>
                <Stack.Item>
                    <Stack vertical>
                        <Stack.Item>
                            <p>
                                <TextStyle variation={'strong'}>
                                    {'Your Exchanges'}
                                </TextStyle>
                            </p>
                        </Stack.Item>
                        <Stack.Item>
                            <label>
                                {'Summary of your exchanges with other stores'}
                            </label>
                        </Stack.Item>
                        <Stack.Item>
                            <Stack>
                                <Stack.Item>
                                    <Select
                                        label=""
                                        options={[
                                            { label: 'Clicks', value: 'clicks' },
                                            { label: 'Views', value: 'views' },
                                        ]}
                                        onChange={handleSelectTypeChange}
                                        value={selectedType}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Select
                                        label=""
                                        options={[
                                            { label: 'Last 30 days', value: '30' },
                                            { label: 'Last 7 days', value: '7' },
                                            { label: 'Last day', value: '1' },
                                        ]}
                                        onChange={handleSelectPeriodChange}
                                        value={selectedPeriod}
                                    />
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <View style={{
                                width: 500,
                                height: 250
                            }}>
                                <Suspense>
                                    <PerformanceChart
                                        selectedPeriod={selectedPeriod}
                                        selectedType={selectedType}
                                    />
                                </Suspense>
                            </View>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item fill>
                    <PointsSummury />
                </Stack.Item>
            </Stack >
        </Card >
    );
});