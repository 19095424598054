import { Button, Form, FormLayout, Modal, TextContainer, TextField, TextStyle } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { useClient } from "../../../api/useClient";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";;

export const BuyPointsModal = React.memo((props: {
    activator?: React.RefObject<HTMLElement> | React.ReactElement,
    open?: boolean,
    onClose?: () => void
    points?: number,
    setOpen?: (value: boolean) => void
}) => {
    const client = useClient();        
    const appBridge = useAppBridge();
    const [active, setActive] = props.setOpen ? [props.open  || false, props.setOpen] : useState<boolean>(props.open || false);
    const [pointsAmount, setPointsAmount] = useState<number>(props.points || 5);
    const [chargeLoading, setChargeLoading] = useState<boolean>(false);

    const [pointsPrice, setPointsPrice] = useState<number>();
    const [pointsPriceLoading, setPointsPriceLoading] = useState<boolean>(false);
    const handleRecountPrice = useCallback((points) => {
        setPointsPriceLoading(true);        
        client.queryGetCoinsPrice({ input: { amount: points } })
            .then((result) => {                
                setPointsPrice(result.getCoinsPrice);                
            })
            .catch((e) => console.warn)
            .finally(() => {
                setPointsPriceLoading(false);
            });
    }, [pointsAmount, client]);
    const handlePointsAmountChange = useCallback((value) => {
        setPointsAmount(parseInt(String(value)));
        handleRecountPrice(parseInt(String(value)));
    }, []);
    const handleToggleModal = props.onClose || useCallback(() => { setActive(!active) }, [active]);
    const handleBuy = useCallback((_event?) => {
        console.log('Buy ', pointsAmount);
        setChargeLoading(true);
        client.mutateCreateCoinCharge({input: {amount: pointsAmount}})
            .then((result) => {
                console.log('Charge created', result, active);
                const coinCharge = result.createCoinChargeRequest;
                if (coinCharge.confirmUrl) {
                    window.open(coinCharge.confirmUrl, '_blank');
                    const redirect = ShopifyRedirect.create(appBridge);
                    redirect.dispatch(ShopifyRedirect.Action.REMOTE, coinCharge.confirmUrl);
                }
                setActive(false);
            })
            .catch((e) => console.warn)
            .finally(() => {
                setChargeLoading(false);
            });
    }, [pointsAmount]);
    const activator = props.activator || <Button onClick={handleToggleModal} primary>Buy Cartmate Points</Button>
    useEffect(() => {
        handleRecountPrice(pointsAmount);
    }, []);
    return (
        <Modal        
            activator={activator}
            open={active}
            onClose={handleToggleModal}
            title={"Buy Cartnate Points"}
            primaryAction={{
                content: 'Buy',
                onAction: handleBuy,
                loading: chargeLoading

            }}
            secondaryActions={[
                { content: 'Close', onAction: handleToggleModal }
            ]}
        >
            <Modal.Section>
                <TextContainer>
                    <p>To buy the points read this text again</p>
                </TextContainer>
            </Modal.Section>
            <Modal.Section>
                <Form onSubmit={handleBuy}>
                    <FormLayout>
                        <TextField
                            label={"Amount of Cartmate Points"}
                            value={String(pointsAmount)}
                            onChange={handlePointsAmountChange}
                            type={'number'}
                            inputMode={'numeric'}
                            step={1}
                            min={1}
                            connectedRight={
                                <TextField 
                                    prefix={"$"}
                                    value={String(pointsPrice)}
                                    type={'number'}
                                    label={"Points price"}
                                    onChange={()=>{}}
                                    disabled
                                    labelHidden                                
                                />                                                   
                            }
                        />
                    </FormLayout>
                </Form>
            </Modal.Section>
        </Modal>
    );
});