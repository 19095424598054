import React, { } from "react"
import { View, Text } from "react-native";
import { Timer } from "../../components/Timer";
import { TimerProps } from "../components";

export const TimerView = React.memo((props: TimerProps) => {

  // console.log('[TimerView] props', props.timeToShow);

  return (
    <View>
      {props.showCaption && <View
        style={{
          flexDirection: 'column',
          alignItems: props.titleStyle.textAlign
        }}>
        <Text
          style={{
            color: props.titleStyle.color,
            fontSize: 16,
            fontWeight: props.titleStyle.fontWeight,
            marginBottom: 10
          }}
          numberOfLines={2}
          ellipsizeMode={'tail'}
        >
          {props.titleValue}
        </Text>
      </View>}
      <Timer
        until={new Date(props.until).getTime()}
        size={props.size}
        digitStyle={{ backgroundColor: props.labelColor, borderColor: props.borderColor, borderWidth: props.showBorder ? 2 : 0 }}
        digitTxtStyle={{ color: props.digitColor, fontWeight: props.digitFontWeight }}
        timeToShow={props.timeToShow.split('')}
        running
      />
    </View>
  )
});