import React from "react"
import { ContentView, ContentMenuItem } from "../../../../content/components";

export const CollectionListItem = React.memo(({ collection, textColor }: { collection: any, textColor: string }) => {
    return (
        <ContentView
            style={{
                height: 44,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <ContentMenuItem url={`/products/${collection.id}`} text={collection.title} textColor={textColor} />
        </ContentView>
    );
})