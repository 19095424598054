import { IconsData } from "../Icon/IconPicker/iconsData";
import { makeSyncKey, setup } from "./utils";
import { SyncIconFontDes } from "./types";
import * as Icons from '@expo/vector-icons';

export const AntDesign = makeSyncKey('ant-design', () => Promise.resolve(Icons)
    .then(icons => icons.AntDesign)
    .then(iconSet => setup({
        iconSet,
        title: 'Ant design',
        iconPreview: 'shoppingcart',
        searchMeta: {
            tags: 'ant/design/light',
            collections: {},
        },
    })))

export const Entypo = makeSyncKey('entypo', () => Promise.resolve(Icons)
    .then(icons => icons.Entypo)
    .then(iconSet => setup({
        iconSet,
        title: 'Entypo icons',
        iconPreview: 'cart',
        searchMeta: {
            tags: 'entypo/icons',
            collections: {},
        },
    })))

export const EvilIcons = makeSyncKey('evil-icons', () => Promise.resolve(Icons)
    .then(icons => icons.EvilIcons)
    .then(iconSet => setup({
        iconSet,
        title: 'Evil icons',
        iconPreview: 'cart',
        searchMeta: {
            tags: 'evil/icons',
            collections: {},
        },
    })))

export const Feather = makeSyncKey('feather', () => Promise.resolve(Icons)
    .then(icons => icons.Feather)
    .then(iconSet => setup({
        iconSet,
        title: 'Feather icons',
        iconPreview: 'shopping-cart',
        searchMeta: {
            tags: 'feather/icons',
            collections: {},
        },
    })))

export const FontAwesome = makeSyncKey('font-awesome', () => Promise.resolve(Icons)
    .then(icons => icons.FontAwesome)
    .then(iconSet => setup({
        iconSet,
        title: 'Awesome icons',
        iconPreview: 'cart-arrow-down',
        searchMeta: {
            tags: 'awesome/icons',
            collections: {},
        },
    })))

export const Fontisto = makeSyncKey('fontisto', () => Promise.resolve(Icons)
    .then(icons => icons.Fontisto)
    .then(iconSet => setup({
        iconSet,
        title: 'Fontisto',
        iconPreview: 'opencart',
        searchMeta: {
            tags: 'fontisto/icons',
            collections: {},
        },
    })))

export const Foundation = makeSyncKey('foundation', () => Promise.resolve(Icons)
    .then(icons => icons.Foundation)
    .then(iconSet => setup({
        iconSet,
        title: 'Foundation',
        iconPreview: 'shopping-cart',
        searchMeta: {
            tags: 'foundation/icons',
            collections: {},
        },
    })))

export const Ionicons = makeSyncKey('ionicons', () => Promise.resolve(Icons)
    .then(icons => icons.Ionicons)
    .then(iconSet => setup({
        iconSet,
        title: 'Ionicons',
        iconPreview: 'cart',
        searchMeta: {
            tags: 'ionicons/icons',
            collections: {},
        },
    })))

export const MaterialCommunityIcons = makeSyncKey('material-community', () => Promise.resolve(Icons)
    .then(icons => icons.MaterialCommunityIcons)
    .then(iconSet => setup({
        iconSet,
        title: 'Material Community Icons',
        iconPreview: 'cart',
        searchMeta: {
            tags: 'material/community/icons',
            collections: {},
        },
    })))

export const MaterialIcons = makeSyncKey('material', () => Promise.resolve(Icons)
    .then(icons => icons.MaterialIcons)
    .then(iconSet => setup({
        iconSet,
        title: 'Material Icons',
        iconPreview: 'add-shopping-cart',
        searchMeta: {
            tags: 'material/icons',
            collections: {},
        },
    })))

export const Octicons = makeSyncKey('octicons', () => Promise.resolve(Icons)
    .then(icons => icons.Octicons)
    .then(iconSet => setup({
        iconSet,
        title: 'Octicons',
        iconPreview: 'home',
        searchMeta: {
            tags: 'octicons/icons',
            collections: {},
        },
    })))

export const SimpleLineIcons = makeSyncKey('simple-line-icons', () => Promise.resolve(Icons)
    .then(icons => icons.SimpleLineIcons)
    .then(iconSet => setup({
        iconSet,
        title: 'Simple Line Icons',
        iconPreview: 'home',
        searchMeta: {
            tags: 'simple/line/icons',
            collections: {},
        },
    })))

export const Zocial = makeSyncKey('zocial', () => Promise.resolve(Icons)
    .then(icons => icons.Zocial)
    .then(iconSet => setup({
        iconSet,
        title: 'Zocial',
        iconPreview: 'cart',
        searchMeta: {
            tags: 'zocial/line/icons',
            collections: {},
        },
    })))

const AllEnabledIcons: SyncIconFontDes[] = [
    MaterialIcons,
    MaterialCommunityIcons,
    Feather,
    Ionicons,
]

const PopularItems: Promise<IconsData> = MaterialCommunityIcons().then(iconSet => ({
    ...iconSet,
    title: 'Favorite icons',
    iconPreview: 'star-outline',
    allKeys: ['home', 'format-list-bulleted', 'cart'],
}))