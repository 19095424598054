import React, { useCallback, useMemo, useRef, useState } from 'react'
import { VisuallyHidden, Stack, Thumbnail, Button, DropZone, FormLayout, Icon } from '@shopify/polaris'
import { Knob } from '../Knob';
import Constants from 'expo-constants';
import { FileInfo, Widget, WidgetAPI } from '@uploadcare/react-widget';
import { DeleteMajor } from '@shopify/polaris-icons';
import { View } from "react-native";

const crops = '1:1, 16:9, 4:3, 5:4, free'

export const KnobFile = React.memo<Knob.KnobComponentProps<Knob.KnobFileDescriptor>>(
    ({ innerKey, value, onChange, knob, disabled, dropZoneProps }) => {
        const { description, crop: cropInput, clearable, defaultValue } = knob
        const widgetApi = useRef<WidgetAPI | null>(null)

        const onCleanCb = useCallback(() => onChange(defaultValue !== undefined ? defaultValue : null, innerKey), [knob, onChange])

        const onFileChange = useCallback((fileInfo: FileInfo) => {
            const newValue = fileInfo.cdnUrl
            if (newValue !== value) {
                setControlsShown(false);
                onChange(newValue, innerKey)
            }
        }, [value, onChange]);

        const [controlsShown, setControlsShown] = useState<boolean>(false);

        const handleToggleControls = useCallback(() => {
            setControlsShown(!controlsShown);
        }, [controlsShown]);

        const openDialog = useCallback(() => {
            widgetApi.current?.openDialog('')
        }, [])

        if (value && value !== defaultValue) {
            return <FormLayout>
                <label>{description}</label>
                <Stack vertical>
                    <div
                        onMouseEnter={() => { setControlsShown(true) }}
                        onMouseLeave={() => { setControlsShown(false) }}
                        style={{
                            position: "relative",
                            width: "8rem"
                        }}
                    >
                        <Thumbnail
                            size="large"
                            alt="img"
                            source={value}
                        />
                        {!disabled && clearable && controlsShown && <div
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                top: 0,
                                left: 0,
                                backgroundColor: "rgba(0, 0, 0, 0.35)",
                                borderRadius: "4px",
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: "5px",
                                    width: "20px",
                                    height: "20px",
                                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                                    borderRadius: "4px",
                                    padding: "5px",
                                    cursor: "pointer"
                                }}
                                onClick={onCleanCb}
                            >
                                <Icon source={DeleteMajor} color={"warning"} accessibilityLabel={"Remove"} />
                            </div>
                        </div>}
                    </div>
                    {!disabled && (
                        <View>
                            <Stack vertical>
                                <VisuallyHidden>
                                    <Widget ref={widgetApi} imagesOnly={true} crop={cropInput || crops}
                                        value={value}
                                        onChange={onFileChange}
                                        previewStep={true}
                                        pathValue={true}
                                        publicKey={Constants.manifest.extra.uploadcarePublicKey} multiple={false} />
                                </VisuallyHidden>
                                {/*{clearable && <Button fullWidth icon={DeleteMajor} onClick={onCleanCb}>Remove</Button>}*/}
                            </Stack>
                        </View>
                    )}
                </Stack>
            </FormLayout>
        } else {
            return (
                <FormLayout>
                    <Stack vertical>
                        <label>{description}</label>
                        <DropZone disabled={!!disabled} type="image" onClick={openDialog} onDragEnter={openDialog} {...dropZoneProps}>
                            <DropZone.FileUpload />
                            <VisuallyHidden>
                                <Widget ref={widgetApi} imagesOnly={true} crop={cropInput || crops}
                                    onChange={onFileChange}
                                    previewStep={true}
                                    pathValue={true}
                                    publicKey={Constants.manifest.extra.uploadcarePublicKey} multiple={false} />
                            </VisuallyHidden>
                        </DropZone>
                    </Stack>
                </FormLayout>
            );
        }
    })