import Bugsnag from "@bugsnag/js";
import { Card, EmptyState, Heading, Pagination, ResourceList, Stack, TextStyle } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { ListCommunityStores_listCommunityStores, ListCommunityStores_listCommunityStores_edges_node } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { ResourceListSkeleton } from "../../../components/Skeleton/ResourceListSkeleton";
import { CommunityStoreItem } from "./CommunityStoreItem";
import { ReviewCommunityStoreModal } from "./ReviewCommunityStoreModal";

const emptyCartmateIcon = require('../../../assets/images/empty-cartmate-icon.svg');

export const CommunityStores = React.memo(({onNewFriend}: {onNewFriend: () => void}) => {
    const client = useClient();
    const friends = client.useFriendlyStores().friendlyStores;
    const [results, setResults] = useState<ListCommunityStores_listCommunityStores>();
    const [loading, setLoading] = useState<boolean>(false);
    const [reviewModalItem, setReviewModalItem] = useState<ListCommunityStores_listCommunityStores_edges_node>();
    const pageSize = 6;

    const loadPage = useCallback((pageSize: number, after?: string | null) => {
        client.queryListCommunityStores({
            first: pageSize,
            after: after,
        })
            .then((result) => {
                console.log('Nex page loaded', result);
                setResults(result.listCommunityStores);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    }, [client]);

    const loadNextPage = useCallback(() => {
        console.log('loading next page')
        setLoading(true);
        loadPage(pageSize, results?.pageInfo.endCursor);
    }, [results, loadPage]);

    const loadPrevPage = useCallback(() => {
        if (!results?.pageInfo.hasPreviousPage) {
            throw new Error("No previous page");
        }
        setLoading(true);
        loadPage(pageSize, results.pageInfo.startCursor);
    }, [pageSize, results, loadPage]);

    useEffect(() => {
        loadNextPage();
    }, []);

    const handleModalCancel = useCallback(
        () => {
            setReviewModalItem(undefined);
        },
        [setReviewModalItem],
    );

    const handleAddTofriendly = useCallback(
        (input?: { id: string, acceptAllRequests: boolean }) => {
            if (input) {
                client.mutateAddOrUpdateFriendlyStore({
                    input: input
                }).then((res) => {
                    setResults(undefined);
                    onNewFriend();
                    loadNextPage();
                }).catch((e) => {
                    console.log('mutateAcceptCommunityListing error', e);
                    Bugsnag.notify(e);
                }).finally(() => {
                    handleModalCancel();
                });
            }
        },
        [client, handleModalCancel, setResults, loadNextPage, onNewFriend],
    );

    if (loading) {
        return (<ResourceListSkeleton />);
    }

    return (
        <Card>
            <Card.Section>
                <View style={{ flexDirection: 'row' }}>
                    <Stack.Item fill>
                        <Stack vertical>
                            <Stack.Item>
                                <Heading>
                                    <TextStyle variation={'strong'}>
                                        {'Explore community'}
                                    </TextStyle>
                                </Heading>
                            </Stack.Item>
                            <Stack.Item>
                                <TextStyle variation={'subdued'}>
                                    {'Community store contacts who would like to communicate and exchange traffic.\n'}
                                </TextStyle>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        {/* <div style={{
                            textAlign: 'center',
                            width: '100px',
                            height: '100px',
                            backgroundImage: `url(${communityIcon})`,
                            backgroundSize: 'cover',
                        }} /> */}
                    </Stack.Item>
                </View>
            </Card.Section>
            <Card.Section>
                {(results?.edges.length === 0) && <EmptyState
                    heading="No community stores found"
                    image={emptyCartmateIcon} />}
                {(results?.edges && results?.edges.length > 0) && (
                    <ResourceList
                        items={results?.edges.map((e) => e.node)}
                        renderItem={(l) => {
                            const friendly = friends.find((f) => f.contact.id === l.contact.id) ? true : false;
                            return (
                                <ResourceList.Item
                                    onClick={() => { }}
                                    id={l.contact.id}
                                    shortcutActions={
                                        !friendly ? [{
                                            content: 'Review',
                                            onAction: () => {
                                                setReviewModalItem(l);
                                            }
                                        }] : []}
                                >
                                    <CommunityStoreItem friendly={friendly} item={l} />
                                </ResourceList.Item>
                            );
                        }}
                    />
                )}
            </Card.Section>
            {(results?.pageInfo.hasNextPage || results?.pageInfo.hasPreviousPage) && <Card.Section>
                <Stack distribution={"center"}>
                    <Pagination
                        hasPrevious={results?.pageInfo.hasPreviousPage}
                        onPrevious={() => {
                            loadPrevPage()
                        }}
                        hasNext={results?.pageInfo.hasNextPage}
                        onNext={() => {
                            loadNextPage()
                        }}
                    />
                </Stack>
            </Card.Section>}
            <ReviewCommunityStoreModal
                item={reviewModalItem}
                handleAccept={handleAddTofriendly}
                handleCancel={handleModalCancel}
            />
        </Card>
    );
});