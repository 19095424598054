import React from 'react';
import { Card, Layout, Page, TextStyle, FooterHelp, Link } from '@shopify/polaris';
import { useClient } from '../../api/useClient';
import { View } from "react-native";
import { useState } from 'react';
import { TabDescriptor } from '@shopify/polaris/dist/types/latest/src/components/Tabs/types';
import { Switch, useHistory, useRouteMatch } from 'react-router';
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { AccountRequireRoute } from '../../routing/AccountRequireRoute';
import Constants from 'expo-constants';
import { Generals } from './pages/Generals';
import { Synchronize } from './contents/Synchronize';
import { SettingsItem } from './components/SettingsItem';
import { Legal } from './pages/Legal';
import { StoresForm } from './pages/StoresForm';
import { Contacts } from './pages/Contacts';

const logo = require('../../assets/images/settings-cartmate-logo.svg');
const generals = require('../../assets/images/generals.svg');
const survey = require('../../assets/images/settings-survey-icon.svg');
const catalog = require('../../assets/images/Catalog.svg');
const legal = require('../../assets/images/legal.svg');
const standalone = require('../../assets/images/standalone.svg');
const contacts = require('../../assets/images/settings-contact-info-icon.svg');

export const Settings = React.memo(() => {
    const client = useClient();
    client.useSettings({ fetchPolicy: 'cache-and-network' });
    const [selected, setSelected] = useState<number>(0);
    const tabs: TabDescriptor[] = [
        {
            id: 'catalog',
            content: (
                <TextStyle variation="strong">{'Catalog settings'} </TextStyle>
            ),
            accessibilityLabel: 'Catalog',
            panelID: 'catalog-tab',
        },
        {
            id: 'standalone',
            content: (
                <TextStyle variation="strong">{'Standalone app'} </TextStyle>
            ),
            accessibilityLabel: 'Standalone',
            panelID: 'standalone-tab'
        },
        {
            id: 'legal',
            content: (
                <TextStyle variation="strong">{'Legal'} </TextStyle>
            ),
            accessibilityLabel: 'legal',
            panelID: 'legal-tab'
        }
    ];

    const currentVersion = Constants.manifest.version as string
    const match = useRouteMatch();
    const history = useHistory();
    const appBridge = useAppBridge();
    return (
        <>
            <Switch>
                <AccountRequireRoute path={`${match.path}/general`} component={Generals} />
                <AccountRequireRoute path={`${match.path}/contacts`} component={Contacts} />
                {/* <AccountRequireRoute path={`${match.path}/survey`} component={Survey} /> */}
                <AccountRequireRoute path={`${match.path}/synchronize`} component={Synchronize} />
                <AccountRequireRoute path={`${match.path}/legal`} component={Legal} />
                <AccountRequireRoute path={`${match.path}/storesform`} component={StoresForm} />
                <AccountRequireRoute path={match.path}>
                    <Page title="Settings">
                        <Layout>
                            <Layout.Section>
                                <Card>
                                    <ul className="settings-list" style={{
                                        listStyleType: 'none',
                                        margin: '0px',
                                        display: 'grid',
                                        gridTemplateRows: 'repeat( 8, auto)',
                                        gridTemplateColumns: 'repeat( 3, auto)',
                                        gridColumnGap: '1.6rem',
                                        padding: '1.6rem'
                                    }}>
                                        <SettingsItem
                                            title={'General'}
                                            url={`${match.url}/general`}
                                            backgroundImage={generals}
                                            description={'General app settings, like products and collections sync'}
                                        />
                                        <SettingsItem
                                            title={'Contact information'}
                                            url={`${match.url}/contacts`}
                                            backgroundImage={contacts}
                                            description={'General, Legal and Traffic Exchange Program contact information settings'}
                                        />
                                    </ul>
                                </Card>
                            </Layout.Section>
                        </Layout>
                        <Layout>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '4rem'
                            }}>
                                <div className="version" style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: "center",
                                }}>
                                    <View style={{
                                        flexDirection: 'row',
                                        justifyContent: 'center', alignItems: 'center'
                                    }}>
                                        <div className="version-logo" style={{
                                            width: '48px',
                                            height: '48px',
                                            backgroundImage: `url(${logo})`,
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            marginRight: '8px'
                                        }}></div>
                                        <View>
                                            <div className="version-title" style={{
                                                color: '#000',
                                                fontSize: '16px',
                                                lineHeight: '16px',
                                                fontWeight: 600,
                                                marginBottom: '2px'
                                            }}>
                                                Cartmate
                                            </div>
                                            <div className="version-text" style={{
                                                color: '#000',
                                                fontSize: '12px',
                                                lineHeight: '12px',
                                                fontWeight: 500
                                            }}>
                                                Version {currentVersion}
                                            </div>
                                        </View>
                                    </View>
                                </div>
                            </div>
                        </Layout>
                        <FooterHelp>
                            Learn more about{' '}
                            <Link external onClick={() => {
                                const redirect = ShopifyRedirect.create(appBridge);
                                redirect.dispatch(ShopifyRedirect.Action.REMOTE, {
                                    url: 'https://cartmate.com',
                                    newContext: true
                                });
                            }}>
                                Cartmate
                            </Link>
                        </FooterHelp>
                    </Page>
                </AccountRequireRoute>
            </Switch>
        </>
    )
})