import { TextAlignType } from "../configEditor/knobs/types";

export function shortenString(value: string, charCount: number) {
    return value.substring(0, charCount).length === charCount
        ? `: ${value.substring(0, charCount)}...`
        : `: ${value.substring(0, charCount)}`
}

export function capitalize(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export const dateFormat = 'yyyy-MM-DD';

export function mapTextAlign(type?: TextAlignType) {
    switch (type) {
        case TextAlignType.Start:
            return 'left';
        case TextAlignType.End:
            return 'right';
        case TextAlignType.Center:
            return 'center';
        default:
            return undefined
    }
}