import { Tag } from "@shopify/polaris";
import React from "react";
import { View } from "react-native";
import { Knob } from "../Knob";

export function renderCollectionsTags(collections: Knob.Collection[], removeProduct: (collection: Knob.Collection) => void) {
    return collections.map((collections) => {
        return (
            <View key={collections.id} style={{ marginRight: 4, marginBottom: 4 }}>
                <Tag onRemove={() => { removeProduct(collections); }}>
                    {collections.title}
                </Tag>
            </View>
        );
    });
}