export enum TextAlignType {
    // Left = 'left',
    // Right = 'right',
    // Center = 'center'
    Start = 'flex-start',
    End = 'flex-end',
    Center = 'center'
}

export enum ResizeMode {
    Contain = 'contain',
    Cover = 'cover',
    Stretch = 'stretch',
    Center = 'center'
}

export enum ViewType {
    List = 'list',
    Blocks = 'blocks',
    Banners = 'banner',
    Circles = 'circles',
    Grid = 'grid'
}

export enum NavigationType {
    Shop = 'Shop',
    Collection = 'Collection',
    Product = 'Product',
    Web = 'Web',
    Tab = 'Tab'
}
export enum FontWeightType {
    Light = '300',
    Normal = '400',
    Bold = '700'
}