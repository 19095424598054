import { Icon, Stack } from "@shopify/polaris";
import { ProductsMajor } from "@shopify/polaris-icons";
import React from "react";
import { IconWrapper } from "../../components/Icon/IconView/Icons";
import {
    ContentProduct,
    ContentView,
} from '../../content/components';
import { TemplateWithVariables } from "../knobs/TemplateWithVariables";
import { FontWeightType, ResizeMode, TextAlignType } from "../knobs/types";
import { ConfigBlock } from "./BlockTypes";
import { DafaultBlockValues } from "./defaults";

const Variables = {
    product: {
        type: 'product_picker' as const,
        defaultValue: '',
        description: 'Product',
    },
    blockTitle: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: '',
            textAlign: TextAlignType.Start,
            textColor: '#FFF',
            textShadow: false,
            showCaption: true,
            fontWeight: FontWeightType.Normal
        },
        description: 'Product\'s caption settings',
        placeholder: 'Caption placeholder',
        disableAlpha: true,
    },
    blurIntencity: {
        type: 'range' as const,
        defaultValue: 50,
        description: 'Captions background intencity',
        min: 0,
        max: 100
    },
    height: {
        type: 'range' as const,
        defaultValue: DafaultBlockValues.height,
        description: 'Height',
        min: 100,
        max: 1000,
    },
    showPrice: {
        type: 'boolean' as const,
        defaultValue: false,
        description: 'Show product\'s price',
    },
    imageResizeMode: {
        type: 'resize_mode' as const,
        defaulValue: ResizeMode.Cover,
        description: 'Image resize mode'
    },
    borderRadius: {
        type: 'range' as const,
        defaultValue: DafaultBlockValues.borderRadius,
        description: 'Border radius',
        min: 0,
        max: 100,
        isAdvanced: true,
    },
    border: {
        type: 'border' as const,
        defaultValue: {
            border: 1,
            color: '#676767',
            showBorder: false,
        },
        description: 'Border settings',
        min: 0,
        max: 5,
        step: 1,
        disableAlpha: true,
        isAdvanced: true
    },
    showShadow: {
        type: 'boolean' as const,
        defaultValue: false,
        description: 'Enable block\'s shadow',
        isAdvanced: true,
    },
    prductImagePlaceholder: {
        type: 'blurhash_file' as const,
        defaultValue: { src: '', blurhash: null, resizeMode: null },
        description: 'Product\'s image placeholder',
        clearable: true,
        isAdvanced: true,
    },
    marginCoordinates: {
        type: 'coordinates' as const,
        defaultValue: { left: 10, right: 10, top: 8, bottom: 0 },
        description: 'margin',
        min: 0,
        isAdvanced: true,
    },
    paddingCoordinates: {
        type: 'coordinates' as const,
        defaultValue: { left: 0, right: 0, top: 0, bottom: 0 },
        description: 'padding',
        min: 0,
        isAdvanced: true,
    },
}

const Product = TemplateWithVariables(Variables, ({
    height,
    borderRadius,
    product,
    blockTitle,
    prductImagePlaceholder,
    showShadow,
    paddingCoordinates,
    marginCoordinates,
    imageResizeMode,
    blurIntencity,
    showPrice
}) => {
    return (
        <ContentView
            style={{
                // height: height,
                // flexDirection: 'row',
                borderRadius: borderRadius,
                paddingLeft: paddingCoordinates.left,
                paddingRight: paddingCoordinates.right,
                paddingTop: paddingCoordinates.top,
                paddingBottom: paddingCoordinates.bottom,
                marginBottom: marginCoordinates.bottom,
                marginTop: marginCoordinates.top,
                marginLeft: marginCoordinates.left,
                marginRight: marginCoordinates.right,
                // shadowOpacity: showShadow ? 0.5 : undefined,
                // shadowRadius: showShadow ? 6 : undefined,
                overflow: 'hidden'
            }}
        >
            <ContentProduct
                id={product}
                caption={blockTitle.value}
                textColor={blockTitle.textColor}
                textAlign={blockTitle.textAlign}
                showCaption={blockTitle.showCaption}
                placeholderImage={prductImagePlaceholder}
                fontWeight={blockTitle.fontWeight}
                imageResizeMode={imageResizeMode as ResizeMode}
                forcePlaceholder
                blurIntencity={blurIntencity}
                showPrice={showPrice}
                style={{
                    height: height
                }}
            />
        </ContentView>
    )
});

export default {
    Preview: () => <Stack alignment="center" distribution="center">
        <Stack.Item>
            <IconWrapper>
                <Icon source={ProductsMajor} />
            </IconWrapper>
        </Stack.Item>
        <Stack.Item fill>
            Product
        </Stack.Item>
    </Stack>,
    RenderBlock: Product,
    Icon: ProductsMajor,
    Name: 'Product'
} as ConfigBlock