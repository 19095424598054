import { Card, EmptyState, Pagination, ResourceList, Stack } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { AdListingStatus, ListAdListings_listAdListings } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { ResourceListSkeleton } from "../../../components/Skeleton/ResourceListSkeleton";
import { AdLisingItem } from "./AdLisingItem";

export const InactiveAdLisings = React.memo(() => {
    const client = useClient();
    const [results, setResults] = useState<ListAdListings_listAdListings>();
    const [loading, setLoading] = useState<boolean>(false);
    const pageSize = 5;

    const loadNextPage = useCallback(() => {
        setLoading(true);
        client.queryListAdListings({
            first: pageSize,
            after: results?.pageInfo.endCursor,
            status: AdListingStatus.INACTIVE
        })
            .then((result) => {
                console.log('Nex page loaded', result);
                setResults(result.listAdListings);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [client, results]);

    const loadPrevPage = useCallback(() => {
        if (!results?.pageInfo.hasPreviousPage) {
            throw new Error("No previous page");
        }
        setLoading(true);

        client.queryListAdListings({
            last: pageSize,
            before: results.pageInfo.startCursor,
            status: AdListingStatus.INACTIVE
        })
            .then((result) => {
                console.log('Next page loaded', result);
                setResults(result.listAdListings);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [pageSize, results]);

    useEffect(() => {
        loadNextPage();
    }, []);

    if (loading) {
        return (<ResourceListSkeleton />);
    }

    return (
        <Card
            title={'Inactive positions'}
        >
            <Card.Section>
                {(results?.edges.length === 0) && <EmptyState
                    heading="No listings available"
                    image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png" />}
                {(results?.edges && results?.edges.length > 0) && (
                    <ResourceList
                        items={results?.edges.map((e) => e.node)}
                        renderItem={(l) => {
                            return (
                                <ResourceList.Item
                                    onClick={() => { }}
                                    id={l.id}
                                >
                                    <AdLisingItem item={l} />
                                </ResourceList.Item>
                            );
                        }}
                    />
                )}
            </Card.Section>
            {(results?.pageInfo.hasNextPage || results?.pageInfo.hasPreviousPage) && <Card.Section>
                <Stack distribution={"center"}>
                    <Pagination
                        hasPrevious={results?.pageInfo.hasPreviousPage}
                        onPrevious={() => {
                            loadPrevPage()
                        }}
                        hasNext={results?.pageInfo.hasNextPage}
                        onNext={() => {
                            loadNextPage()
                        }}
                    />
                </Stack>
            </Card.Section>}
        </Card>
    );
});