
import React from 'react';
import { Chip, ChipProps } from './Chip';
import { StyleSheet } from 'react-native';
import { TypographyStyle } from '../Typography/commonTypography';

const style = StyleSheet.create({
    primaryChip: {
        ...TypographyStyle.body1WhiteObject,
        borderColor: '#008060',
        backgroundColor: '#008060'
    }
})

export const PrimaryChip = React.memo<ChipProps>(props => <Chip {...props} style={[style.primaryChip, props.style]}/>)