import React, { useCallback, useEffect, useState } from "react";
import moment from 'moment';
import { InlineError, Stack, TextField, TextStyle } from "@shopify/polaris";
import { dateFormat } from "../../../utils/textUtils";

const description = 'Choose how long this item will be visible';

export const ExpireAtSelector = React.memo((
    {
        initialValue,
        onChange,
        error
    }: {
        initialValue?: number,
        onChange: (value?: number) => void,
        error?: string
    }
) => {

    const [selected, setSelected] = useState(initialValue);
    const [dateError, setError] = useState<string>();

    useEffect(() => {
        if (selected !== initialValue) {
            onChange(selected);
        }
    }, [selected]);

    const handleDateSelect = useCallback(
        (value: string) => {
            setError(undefined);
            let nowDate = moment();
            console.log('nowDate', nowDate);
            const date = moment(value, dateFormat);
            const inBounds = moment(date, dateFormat)
                .isBetween(
                    moment(nowDate, dateFormat),
                    moment(nowDate.add(30, 'days'), dateFormat)
                );
            if (inBounds) {
                setSelected(parseInt(date.format('x')));
            } else {
                setError('Select a date within 30 days');
            }
        },
        [],
    );

    return (
        <Stack vertical>
            <Stack.Item>
                <p><TextStyle variation={'strong'}>{'Visibility time span'}</TextStyle></p>
            </Stack.Item>
            <Stack.Item>
                <p><TextStyle variation={'subdued'}>{description}</TextStyle></p>
            </Stack.Item>
            <Stack.Item>
                <TextField
                    id={'expire-at'}
                    placeholder={'Select a date withing next 30 days range'}
                    label={''}
                    error={dateError}
                    onChange={handleDateSelect}
                    type="date"
                    value={selected ? moment(selected).format(dateFormat) : undefined}
                />
            </Stack.Item>
            {error && <InlineError message={error} fieldID="expire-at" />}
        </Stack>
    );
});