import * as React from 'react';
import { ContentRendererConfig } from './config';

type ReactPersistedNode = string | { type: string, props: any, children: any[] | null };

function RenderNode(props: { node: ReactPersistedNode, config: ContentRendererConfig, textContext: boolean }) {
    if (typeof props.node === 'string') {
        if (props.textContext) {
            return <>{props.node}</>;
        } else {
            return null;
        }
    }

    // Handle text content
    let textContext = props.textContext || props.node.type === 'text';
    let Component = props.config.renderers[props.node.type];
    if (Component) {
        return <Component {...props.node.props}>{props.node.children && props.node.children.map((ch, i) => <RenderNode key={'child-' + i} config={props.config} node={ch} textContext={textContext} />)}</Component>;
    }
    return null;
}

export function ContentRenderer(props: { source: string, config: ContentRendererConfig }) {
    let document = React.useMemo(() => JSON.parse(props.source) as ReactPersistedNode, [props.source]);
    return (<RenderNode node={document} config={props.config} textContext={false} />);
}