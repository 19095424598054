import Bugsnag from "@bugsnag/js";
import Color from "color";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import { useParams } from "react-router";
import { useClient } from "../../../api/useClient";
import { emptyCartmateIcon } from "../../../assets/helpers";
import { IconElement } from "../../../content/elements/IconElement";
import { useEmulatorConfig } from "../../../content/emulator/components/ScalableEmulatorComponent";
import { AppEmulatorConfig } from "../../../content/emulator/types";
import { formatToUSD } from "../../../utils/helpers";
import { dateFormat } from "../../../utils/textUtils";

export const ShopOrdersPreview = React.memo(() => {
    const { id } = useParams<{ id: string }>();
    const client = useClient();
    const appConfig = client.useAppEmulatorConfig({ id: id }).app.draftConfig as AppEmulatorConfig;
    const emulator = useEmulatorConfig();
    const colors = appConfig.design.colors[emulator.theme];
    const [products, setProducts] = useState<any[]>([]);
    useEffect(() => {
        client
            .queryListProducts({ first: 4 })
            .then((result) => {
                setProducts(
                    result.listProducts.edges
                        .map((edge) => {
                            if (edge.node.variants) return {
                                ...edge.node.variants[0],
                                images: edge.node.images,
                                productTitle: edge.node.title
                            }
                        })
                );
            })
            .catch((e) => { Bugsnag.notify(e); });
    }, [client, setProducts]);

    return (
        <View style={{
            height: emulator.openedHeight,
            padding: 8
        }}>
            {(!products || products.length === 0) && (
                <View style={{
                    position: 'absolute', top: 0, bottom: 0, left: 0, right: 0,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Text style={{
                        fontSize: emulator.fontSize, color: colors.primaryText
                    }}>
                        {'No favorite products yet'}
                    </Text>
                </View>
            )}
            {(products && products.length > 0) && products.map((product, index) => {
                return (
                    <View
                        key={`order-item-${product.id}`}
                        style={{
                            flexDirection: 'row',
                            alignItems: 'stretch',
                            height: emulator.maxHeight / 2,
                            borderRadius: 8,
                            padding: 4,
                            backgroundColor: colors.toolBarBackground,
                            marginBottom: 8
                        }}
                    >
                        <View style={{
                            width: emulator.maxImgHeight * 0.7,
                            height: emulator.maxImgHeight * 0.7,
                            backgroundColor: '#bdbdbd',
                            borderRadius: 8,
                        }}>
                            <div className="ios__content-item-img" style={{
                                backgroundImage: `url(${product.images && product.images.length > 0
                                    ? product.images[0]
                                        ? product.images[0].src
                                        : emptyCartmateIcon
                                    : emptyCartmateIcon
                                    })`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                width: '100%',
                                height: `100%`,
                                borderRadius: '10px',
                                marginBottom: '13px',
                                flexShrink: 0,
                                flexGrow: 0,
                            }} />
                        </View>
                        <View style={{ flex: 1, marginLeft: 8 }}>
                            <Text
                                style={{
                                    fontWeight: '700', fontSize: emulator.fontSize,
                                    color: colors.primaryText
                                }}
                                numberOfLines={1}
                                ellipsizeMode={'tail'}
                            >
                                {`Order #${Math.floor(Math.random() * 10000)}`}
                            </Text>
                            <Text
                                style={{
                                    fontSize: emulator.fontSize - 6,
                                    color: colors.primaryText
                                }}
                                numberOfLines={2}
                                ellipsizeMode={'tail'}
                            >
                                {`${moment().format(dateFormat)}`}
                            </Text>
                            <Text
                                style={{
                                    fontSize: emulator.fontSize - 4,
                                    color: colors.primaryText
                                }}
                                numberOfLines={2}
                                ellipsizeMode={'tail'}
                            >
                                {`${product.productTitle}`}
                            </Text>
                            <Text
                                style={{
                                    fontSize: emulator.fontSize - 6,
                                    color: colors.primaryText,
                                    marginTop: 8
                                }}
                            >
                                {`${product.formatted_price}`}
                            </Text>
                        </View>
                    </View>
                );
            })}
        </View>
    );
});