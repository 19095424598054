import React, { useState } from "react";

export const PlanFrame = React.memo((
    {
        selected,
        children,
        onClick
    }: {
        selected?: boolean,
        children: any,
        onClick: () => void
    }
) => {
    const [hoveredOn, setHoveredOn] = useState(false);

    return (
        <div
            style={{
                borderRadius: 8,
                borderColor: '#bbb',
                backgroundColor: selected
                    ? '#fff'
                    : undefined,
                borderWidth: hoveredOn ? 2 : 1,
                borderStyle: 'solid',
                padding: 20,
                margin: 8,
                flexGrow: 1,
                cursor: 'pointer',
            }}
            onMouseOver={() => setHoveredOn(true)}
            onMouseLeave={() => setHoveredOn(false)}
            onClick={onClick}
        >
            {children}
        </div>
    );
});