import { Card, Link, Stack } from "@shopify/polaris";
import React from "react";
import { useAppBridge } from "@shopify/app-bridge-react";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";

export const TermsAndConditions = React.memo(() => {
    const appBridge = useAppBridge();
    return (
        <Card
            title={'Terms of Service and Privacy policy'}
            sectioned
        >
            <Stack alignment={'center'}>
                <Stack.Item fill>
                    <Link external onClick={() => {
                        const redirect = ShopifyRedirect.create(appBridge);
                        redirect.dispatch(ShopifyRedirect.Action.REMOTE, {
                            url: 'https://cartmate.com/TermsOfService',
                            newContext: true
                        });
                    }}>
                        Terms of Service
                    </Link>
                </Stack.Item>
                <Stack.Item fill>
                    <Link external onClick={() => {
                        const redirect = ShopifyRedirect.create(appBridge);
                        redirect.dispatch(ShopifyRedirect.Action.REMOTE, {
                            url: 'https://cartmate.com/PrivacyPolicy',
                            newContext: true
                        });
                    }}>
                        Privacy policy
                    </Link>
                </Stack.Item>
            </Stack>
        </Card>
    )
})