import React, { } from "react"
import { View, Text } from "react-native";
import { HtmlMarkupProps } from "../components";
import parse from 'html-react-parser'

export const HtmlMarkupView = React.memo((props: HtmlMarkupProps) => {

  console.log('[HtmlMarkupView] props', props);

  return (
    <View>
      {parse(props.text)}
    </View>
  )
});