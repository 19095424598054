import { Stack, TextStyle, Icon, Modal, Spinner } from "@shopify/polaris";
import { MobileAcceptMajor } from "@shopify/polaris-icons";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { AccountPricingStatus, ListPricing_listPricing_edges_node } from "../../api/spacex.types";
import { useClient } from "../../api/useClient";
import { useAccount } from "../../configure/utils/useAccount";
import { PlanFrame } from "./PlanFrame";

function getHTMLContent(str: string) {
    console.log('str', str);
    var doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1)
        ? <div dangerouslySetInnerHTML={{ __html: `<div>${str}<div/>` }} />
        : <p>{str}</p>;
}

export const Plan = React.memo((
    {
        plan,
        onSubscribe
    }: {
        plan: ListPricing_listPricing_edges_node,
        onSubscribe: (confirmationUrl?: string | null) => void
    }
) => {
    const client = useClient();
    const account = useAccount();
    const selected = plan.id === account.pricing.pricing?.id;
    const status = account.pricing.status;
    // const [hoveredOn, setHoveredOn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);

    const subscribe = useCallback(
        () => {
            if (!selected) {
                setLoading(true);
                client.mutateSubscribeToPricing({
                    id: account.id,
                    pricingId: plan.id
                }).then((result) => {
                    client.refetchAccountWrapper();
                    onSubscribe(result.subscribeToPricing);
                }).finally(() => setLoading(false));
            }
        },
        [plan, account, client, setLoading],
    );

    const toggle = useCallback(
        () => {
            console.log('close');
            setModalOpened(!modalOpened);
        },
        [modalOpened],
    );

    return (
        <PlanFrame
            selected={selected}
            onClick={() => {
                if (!selected || account.pricing.status !== AccountPricingStatus.ACTIVE) {
                    toggle();
                }
            }}
        >
            <Stack>
                <Stack.Item fill>
                    <TextStyle variation={'strong'}>
                        {`${plan.title}`}
                    </TextStyle>
                </Stack.Item>
                {!loading && selected && (
                    <Stack>
                        <TextStyle
                            variation={
                                status === AccountPricingStatus.ACTIVE
                                    ? 'positive'
                                    : 'negative'
                            }
                        >
                            {status.toLocaleLowerCase()}
                        </TextStyle>
                        <Icon
                            source={MobileAcceptMajor}
                            color='success'
                        />
                    </Stack>
                )}
                {!loading && !selected && (
                    <TextStyle variation={'subdued'}>
                        {'Change plan'}
                    </TextStyle>
                )}
                {(!loading && selected && account.pricing.status !== AccountPricingStatus.ACTIVE) && (
                    <TextStyle variation={'subdued'}>
                        {'Retry Subscription'}
                    </TextStyle>
                )}
                {loading && <Spinner accessibilityLabel="Loading Subscription" size="small" />}
            </Stack>
            <Stack vertical>
                {getHTMLContent(plan.description)}
                <TextStyle variation={'strong'}>
                    {`$${plan.price}`}
                </TextStyle>
            </Stack>
            <Modal
                title={`Switch to ${plan.title} plan?`}
                open={modalOpened}
                onClose={toggle}
                primaryAction={{
                    content: 'Switch',
                    onAction: subscribe,
                    loading: loading
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: toggle
                    }
                ]}
            />
        </PlanFrame>
    );
});