import { Avatar, Button, Icon, Stack, TextStyle } from "@shopify/polaris";
import { CircleCancelMinor } from "@shopify/polaris-icons";
import React from "react";
import { AdListingStatus, AdListings_adListings } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { capitalize } from "../../../utils/textUtils";

export const AdLisingItem = React.memo(({ item }: { item: AdListings_adListings }) => {
    const active = item.status === AdListingStatus.ACTIVE;
    const variation = active ? 'strong' : 'subdued';
    const client = useClient();
    const content = client.useShowAdListingContent({ id: item.id }).showAdListingContent;    
    return (
        <Stack alignment={"center"} distribution={"leading"}>
            <Stack.Item>
                <Avatar source={content.image?.src} customer name={content.title} />
            </Stack.Item>
            <Stack.Item>
                <Stack vertical>
                    <Stack.Item>
                        <p><TextStyle variation={variation}>{item.caption}</TextStyle></p>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack>
                            <Stack.Item>
                                <p>Views: <TextStyle variation={variation}>{item.viewsCount}</TextStyle></p>
                            </Stack.Item>
                            <Stack.Item>
                                <p>Clicks: <TextStyle variation={variation}>{item.clicksCount}</TextStyle></p>
                            </Stack.Item>
                            <Stack.Item>
                                <p>Content: <TextStyle variation={variation}>{capitalize(item.type.toString().toLocaleLowerCase())}</TextStyle></p>
                            </Stack.Item>
                            <Stack.Item>
                                <p>Max spendings: <TextStyle variation={variation}>{item.maxSpendings}</TextStyle></p>
                            </Stack.Item>
                            <Stack.Item>
                                <p>Bid: <TextStyle variation={variation}>{item.bidPerClick}</TextStyle></p>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
});