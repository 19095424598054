import * as React from 'react';
import { View } from 'react-native';
import { getEndpoint } from '../api/getEndpoint';
import { useApiToken } from '../api/useApiToken';
import GraphiQL from 'graphiql';
import 'graphiql/graphiql.css';
import { FetcherParams } from 'graphiql/dist/components/GraphiQL';

export const SandboxComponent = React.memo(() => {

    const token = useApiToken();

    async function graphQLFetcher(graphQLParams: FetcherParams) {
        const response = await fetch(getEndpoint() + '/graphql', {
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'x-shopbutton-token': token },
            body: JSON.stringify(graphQLParams),
        });
        return await response.json();
    }

    return (
        <View style={{ alignSelf: 'stretch', height: '100vh', flexShrink: 1, flexGrow: 1 }}>
            <GraphiQL
                fetcher={graphQLFetcher}
            />
        </View>
    )
});