import React, { useMemo } from 'react'
import { Banner } from '@shopify/polaris';
import { TipKey, useTip } from './useTip';

export function useInfoBanner(key: TipKey, title: string, body: string) {
    const [isFinished, onFinishTip] = useTip(key)

    const render = useMemo(() => {
        if (isFinished) {
            return null
        } else {
            return <Banner
                title={title}
                status="info"
                onDismiss={onFinishTip}
            >
                <p>{body}</p>
            </Banner>
        }
    }, [isFinished, onFinishTip])

    return [render, onFinishTip] as const
}