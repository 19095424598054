import React from "react";
import { ContentView, ContentProduct, ProductProps } from "../../../../content/components";
import { ResizeMode, ViewType } from "../../../knobs/types";

export const ProductBannerItem = React.memo((
    props: {
        viewsHeight: number,
        viewsMargins: number,
        borderRadius: number,
        showShadow?: boolean,
    } & ProductProps
) => {
    return (
        <ContentView
            style={{
                height: props.viewsHeight,
                borderRadius: props.borderRadius,
                marginBottom: props.viewsMargins,
                shadowOpacity: props.showShadow ? 0.5 : undefined,
                shadowRadius: props.showShadow ? 6 : undefined,
                overflow: 'hidden'
            }}
        >
            <ContentProduct
                id={props.id}
                viewType={ViewType.Banners}
                textColor={props.textColor}
                textAlign={props.textAlign}
                showCaption={props.showCaption}
                placeholderImage={props.placeholderImage}
                showTextBackground={props.showTextBackground}
                fontWeight={props.fontWeight}
                imageResizeMode={props.imageResizeMode as ResizeMode}
                blurIntencity={props.blurIntencity}
                showPrice={props.showPrice}
            />
        </ContentView>
    );
})