import React from "react"
import { TemplateWithVariables } from "../knobs/TemplateWithVariables"
import {
    ContentMenuItem,
    ContentView,
} from '../../content/components';
import { Icon, Stack } from "@shopify/polaris";
import { ConfigBlock } from "./BlockTypes";
import { ChevronRightMinor } from "@shopify/polaris-icons";
import { IconWrapper } from "../../components/Icon/IconView/Icons";
import { FontWeightType } from "../knobs/types";

const Variables = {
    blockTitle: {
        type: 'advanced_text' as const,
        defaultValue: {
            value: 'Caption placeholder',
            textColor: '#000',
            textShadow: false,
            fontWeight: FontWeightType.Normal
        },
        description: 'Caption',
        placeholder: 'Enter caption...',
        disableAlpha: true,
    },
    height: {
        type: 'range' as const,
        defaultValue: 100,
        description: 'Height',
        min: 100,
        max: 500,
    },
    url: {
        type: 'navigation_picker' as const,
        description: 'Navigation Link',
        defaultValue: '',
    },
}

const MenuItem = TemplateWithVariables(Variables, ({
    height,
    url,
    blockTitle
}) => {

    return (
        <ContentView
            style={{
                height: height,
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
            }}
        >
            <ContentMenuItem
                url={url || ''}
                text={blockTitle.value}
                textColor={blockTitle.textColor}
            />
        </ContentView>
    )
});

export default {
    Preview: () => <Stack alignment="center" distribution="center">
        <Stack.Item>
            <IconWrapper>
                <Icon source={ChevronRightMinor} />
            </IconWrapper>
        </Stack.Item>
        <Stack.Item fill>
            Menu item
        </Stack.Item>
    </Stack>,
    RenderBlock: MenuItem,
    Icon: ChevronRightMinor,
    Name: 'Menu item'
} as ConfigBlock