import React, { useCallback, useEffect, useState } from 'react'
import { Knob } from '../Knob';
import { Labelled } from '@shopify/polaris'
import { View } from 'react-native';
import { DateTimePicker } from '../../../components/DatePicker/DateTimePicker';


export const KnobDateTimePicker = React.memo<Knob.KnobComponentProps<Knob.KnobDateTimePickerDescriptor>>((
    { knob, value: initValue, onChange, innerKey }
) => {
    const { description, placeholder } = knob;

    console.log('[KnobAdvancedText] init', initValue);
    const [value, setValue] = useState<Knob.DateTimePicker>({
        selected: initValue.selected,
        disabled: initValue.disabled
    });
    const handleKnobChange = useCallback((key: keyof Knob.DateTimePicker, value: any) => {
        setValue((prevValue) => {
            const newValue = { ...prevValue, ...{ [key]: value } };
            onChange(newValue, innerKey)
            return newValue;
        });
        // onChange({ ...initValue, ...{ [key]: value } }, innerKey);
    }, [innerKey, onChange, setValue]);

    useEffect(() => {
        setValue(initValue)
    }, [initValue]);

    let d = new Date(value.selected);

    return (
        <View>
            <Labelled
                id={`knob-date-time-picker-${innerKey}`}
                label={description}
            >
                <DateTimePicker
                    // selected={new Date(initValue.selected)}
                    // onChange={(v) => handleKnobChange('selected', v)}
                    // disabled={initValue.disabled}
                    selected={d}
                    onChange={(v) => handleKnobChange('selected', v)}
                    disabled={value.disabled}
                />
            </Labelled>
        </View>
    );
});