import { ResolvedTheme, ResolvedThemeSet } from './../model/theme';
import { isRight } from 'fp-ts/lib/These';
import * as t from 'io-ts';
import { ThemeDarkCodec, ThemeDarkDescriptor } from './dark.theme';
import { ThemeLightCodec, ThemeLightDescriptor } from './light.theme';
import { ThemeCNightCodec, ThemeCNightDescriptor } from './c-night.theme';
import { ThemeClassicCodec, ThemeClassicDescriptor } from './classic.theme';

const ThemeCodec = t.union([ThemeClassicCodec, ThemeLightCodec, ThemeDarkCodec, ThemeCNightCodec]);
export const ThemeSetCodec = t.type({
    light: ThemeCodec,
    dark: ThemeCodec
});

export type ThemeSet = t.TypeOf<typeof ThemeSetCodec>;
export type Theme = t.TypeOf<typeof ThemeCodec>;
export const allThemes = [ThemeClassicDescriptor, ThemeLightDescriptor, ThemeDarkDescriptor, ThemeCNightDescriptor];
export const lightThemes = [ThemeClassicDescriptor, ThemeLightDescriptor];
export const darkThemes = [ThemeDarkDescriptor, ThemeCNightDescriptor];
export const defaultTheme = ThemeLightDescriptor;
export const defaultThemeSet = {
    light: ThemeLightDescriptor.default,
    dark: ThemeDarkDescriptor.default,
};

export function loadTheme(src: string): Theme {
    try {
        let parsed = JSON.parse(src);
        let decoded = ThemeCodec.decode(parsed);
        if (isRight(decoded)) {
            return decoded.right;
        } else {
            return defaultTheme.default;
        }
    } catch (e) {
        console.warn(e);
        return defaultTheme.default;
    }
}
export function loadThemeSet(src: string): ThemeSet {
    try {
        let parsed = JSON.parse(src);
        let decoded = ThemeSetCodec.decode(parsed);
        if (isRight(decoded)) {
            return decoded.right;
        } else {
            return defaultThemeSet;
        }
    } catch (e) {
        console.warn(e);
        return defaultThemeSet;
    }
}

export function resolveTheme(src: Theme): ResolvedTheme {
    return allThemes.find((v) => v.key === src.type)!.resolve(src as any);
}

export function resolveThemeSet(src: ThemeSet): ResolvedThemeSet {
    return {
        light: allThemes.find((v) => v.key === src.light.type)!.resolve(src.light as any),
        dark: allThemes.find((v) => v.key === src.dark.type)!.resolve(src.dark as any)
    };
}