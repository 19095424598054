import { atom } from "recoil";

export enum ThemeType {
    Light = 'light',
    Dark = 'dark'
}

export const themeStateAtom = atom({
    key: 'themeState',
    default: ThemeType.Light
});