import { ThemeDescriptor } from '../model/theme';
import * as t from 'io-ts';
import Color from 'color';

export const ThemeDarkCodec = t.type({
    type: t.literal('dark'),
    accent: t.string,
});
export type ThemeDark = t.TypeOf<typeof ThemeDarkCodec>;
export const ThemeDarkDescriptor: ThemeDescriptor<ThemeDark> = {
    key: 'dark',
    name: 'Night',
    default: {
        type: 'dark',
        accent: '#2F80ED',
    },
    resolve: (src) => {
        return {
            accent: src.accent,
            accentText: Color(src.accent).isDark()? '#f5f5f5' : '#212121',
            accentIconTint: src.accent,

            primary: '#f5f5f5',
            primaryText: '#f5f5f5',

            background: '#000',
            backgroundText: '#616161',

            secondary: '#212121',
            secondaryText: '#212121',
            separator: '#616161',

            iconTint: '#000',

            bottomNavigationIcon: '#616161',
            bottomNavigationText: '#616161',
            bottomNavigationAccent: '#f5f5f5',
            bottomNavigationBackground: '#212121',
            bottomNavigationInactiveIcon: '#9b9b9b',
            bottomNavigationInactiveText: '#9b9b9b',

            toolBarText: '#f5f5f5',
            toolBarIcon: '#f5f5f5',
            toolBarAccent: '#f5f5f5',
            toolBarBackground: '#212121',

            priceBackground: '#db7a7a',
            priceOldText: '#757575',
            priceText: '#f5f5f5',

            toCartBackground: src.accent,
            toCartIconTint: Color(src.accent).isDark()? '#f5f5f5' : '#212121',
            toCartText: Color(src.accent).isDark()? '#f5f5f5' : '#212121'
        };
    },
}