import React, { useCallback, useMemo } from 'react'
import { Button, Card, Page, Layout } from '@shopify/polaris';
import { useRouteMatch } from 'react-router';
import { getOrElse } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { Page_page, ContentPageType } from '../../api/spacex.types';
import { useClient } from '../../api/useClient';
import { ViewConfig } from '../../configEditor/ConfigEditor';
import { initRenderBlock } from '../../configEditor/subRender/subRenderUtils';
import { EditPageById } from '../../configure/pages/EditPageById';
import { PhonePreviewLayout } from '../../configure/theme/preview/PhonePreview';
import { useSaver } from '../../configure/utils/useSaver';
import { usePublishAction } from '../../content/contentPage/usePublishAction';
import { useRenamePageModal } from '../../content/contentPage/useRenamePageModal';
import { useRevertAction } from '../../content/contentPage/useRevertAction';
import { PromptLoading } from '../../routing/PromptLoading';

function getBlocks(page: Page_page): ViewConfig.NodeBlockExtended[] {
    const nodeBlocks = pipe(ViewConfig.PageToSeverPage.decode(page), getOrElse(() => (null as ViewConfig.Page | null)))?.blocks || [];

    return nodeBlocks.map(node => initRenderBlock(node))
}

export const PagesEdit = React.memo(() => {

    const client = useClient()
    const params = useRouteMatch<Record<'id', string>>().params
    const page = client.usePage(params).page

    const initBlocks: ViewConfig.NodeBlockExtended[] = useMemo(() => getBlocks(page), [page.id])

    const onChange = useCallback(async (newState: ViewConfig.NodeBlockExtended[]) => {
        const data = ViewConfig.PageToInputPage.encode({
            name: '',
            id: '',
            type: ContentPageType.BLOCK,
            blocks: newState,
            contentPageId: ''
        })
        await client.mutateUpdatePage({
            id: page.id,
            input: {
                body: data.body,
            },
        })
    }, [page?.id])

    const [pageData, loading, setPageData] = useSaver<ViewConfig.NodeBlockExtended[]>(initBlocks, onChange)

    const needPublish = !page.publishedData || page.draftData !== page.publishedData

    const [modalRename, openModal] = useRenamePageModal(page.id)

    const [toastPublish, onPublish] = usePublishAction(page.id)
    const [toastRevert, onRevert] = useRevertAction(page, setPageData)

    return (
        <Page title={`Edit page ${page.name}`} breadcrumbs={[{content: 'Pages', url: '/pages'}]}
              primaryAction={
                  <Button
                      disabled={!needPublish}
                      primary
                      onClick={onPublish}
                      connectedDisclosure={{
                          accessibilityLabel: 'Other page actions',
                          actions: [
                              {content: 'Rename', onAction: openModal},
                              {
                                  content: 'Revert changes',
                                  disabled: !needPublish,
                                  accessibilityLabel: 'Revert changes to published version',
                                  onAction: onRevert,
                              },
                          ],
                      }}>
                      Publish
                  </Button>
              }>
            {modalRename}
            {toastPublish}
            {toastRevert}
            <PromptLoading message={'Wait when page will be updated'} when={loading}/>
            <PhonePreviewLayout applicationId={'unknown'}>
                <Layout.Section>
                    <Card title={'Page content'}>
                        <EditPageById pageId={page.id}/>
                    </Card>
                </Layout.Section>
            </PhonePreviewLayout>
        </Page>
    )
})