import React, { useCallback, useMemo, useState } from 'react'
import { Modal, TextStyle } from '@shopify/polaris';
import { PromptLoading } from '../../routing/PromptLoading';
import { useBooleanState } from '../hooks/useBooleanState';

export function useConform(title: string, onSubmit: () => Promise<void>, onClose?: () => void) {
    const [active, setOpen, close] = useBooleanState(false)
    const [loading, setLoading] = useState(false)
    const setClose = useCallback(() => {
        close()
        onClose && onClose()
    }, [onClose])

    const onAction = useCallback(() => {
        setLoading(true)
        onSubmit().finally(() => {
            setLoading(false)
            setClose()
        })
    }, [onSubmit])

    const modal = useMemo(() => (
        <Modal
            title="Confirm"
            open={active}
            onClose={setClose}
            primaryAction={{
                content: 'Ok',
                onAction: onAction,
                loading: loading
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: setClose
                }
            ]}
        >
            <Modal.Section>
                <h3>
                    <TextStyle variation="strong">
                        {title}
                    </TextStyle>
                </h3>
            </Modal.Section>
            <PromptLoading message={'Wait when operation will be finished'} when={loading} />
        </Modal>
    ), [active, title, onAction, loading])

    return [modal, setOpen] as const
}

type Context = {
    onConform?: () => Promise<void>
    onClose?: () => {}
}

const EmptyConform = () => () => Promise.resolve()
const EmptyOnClose = () => () => { }

export function useConformContext(title: string) {
    const [onConform, setOnConform] = useState(EmptyConform)
    const [onClose, setOnClose] = useState(EmptyOnClose)

    const [modal, setOpen] = useConform(title, onConform, onClose)

    const setOpenContext = useCallback((newCbs: Context) => {
        const conform = newCbs.onConform;
        if (conform) {
            setOnConform(() => conform)
        } else {
            setOnConform(EmptyConform)
        }

        if (newCbs.onClose) {
            setOnClose(() => newCbs.onClose)
        } else {
            setOnClose(EmptyOnClose)
        }

        setOpen()
    }, [setOpen])

    return [modal, setOpenContext] as const
}