import { useClient } from '../../api/useClient';
import React, { useCallback, useState } from 'react';
import { Toast } from '@shopify/polaris'


export function usePublishAction(pageId: string) {
    const client = useClient();

    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleClose = useCallback(() => setActive((active) => false), []);

    const toastMarkup = active ? (
        <Toast content="Published" onDismiss={toggleClose}/>
    ) : null;

    const onPublish = useCallback(() => {
        setLoading(true);
        client.mutatePublishPage({id: pageId})
            .then(() => {
                setActive(true);
            }).finally(() => {
                setLoading(false);
            })
    }, [pageId, setLoading, client, setActive]);

    return [toastMarkup, onPublish, toggleClose, loading] as const;
}