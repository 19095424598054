import * as React from 'react';

export function RenderOutput(props: { type: string, props: any, children?: any }) {
    const Component = props.type as any;
    return (<Component {...props.props}>{props.children}</Component>)
}

export function createOutputRenderer<T>(type: string) {
    return function (props: T & { children?: any }) {
        const { children, ...other } = props;
        return (
            <RenderOutput type={type} props={other}>{children}</RenderOutput>
        );
    }
}