import { CalloutCard } from "@shopify/polaris";
import React from "react";
import { Redirect as ShopifyRedirect } from "@shopify/app-bridge/actions";
import { useAppBridge } from "@shopify/app-bridge-react";

export const CustomizeThemeCalloutCard = React.memo(() => {
    const appBridge = useAppBridge();

    return (
        <CalloutCard
            title="Customize your Cartmate storefronts theme"
            illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
            primaryAction={{
                content: 'Customize',
                onAction: () => {
                    const redirect = ShopifyRedirect.create(appBridge);
                    redirect.dispatch(ShopifyRedirect.Action.APP, '/apps');
                }
            }}
        >
            <p>You can change your shop's colors and theme sets in one click</p>
        </CalloutCard>
    );
});