import React from "react";
import { View, Text } from "react-native";
import { IconElement } from "./IconElement";

export function MenuItem (
    {
        url,
        text,
        textColor,
    }: {
        url: string,
        text: string,
        textColor: string,
    }) {

    return (
        <View style={{ width: '100%', height: '100%' }}>
            <View
                style={{
                    height: '100%', width: '100%',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingHorizontal: 16
                }}
            >
                <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center', }}>
                    <Text
                        style={{
                            color: textColor,
                            fontWeight: '600',
                            fontSize: 16,
                        }}
                    >
                        {text}
                    </Text>
                </View>
                <View>
                    <IconElement
                        name={'chevron-right'}
                        collection={'feather'}
                        color={textColor}
                        size={24}
                    />
                </View>
            </View>
        </View>
    );
}