import React from 'react'
import { Card, TextField } from '@shopify/polaris';

type Props = {
    name: string
    onChange: (val:string) => void
    errorMsg?: string|false
}

export const PageName = React.memo<Props>(({name, onChange, errorMsg}) => (
    <Card.Section>
        <TextField error={errorMsg} label={'Page name'} placeholder={'Enter page name'} inputMode={'text'} value={name} onChange={onChange}/>
    </Card.Section>
))