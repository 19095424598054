import * as React from 'react';
import { Image, Text, TouchableWithoutFeedback, View, ScrollView } from 'react-native';
import { ContentRenderer } from './renderer/ContentRenderer';
import { persistContent } from './renderer/persistContent';
import { LinearGradient } from 'expo-linear-gradient';
import { IconElement } from './elements/IconElement';
import { MenuItem } from './elements/MenuItem';
import { ContentRendererConfig } from './renderer/config';
import { BlurView } from 'expo-blur';
import { ProductView } from './elements/ProductView';
import { CollectionView } from './elements/CollectionView';
import { TimerView } from './elements/TImerView';
import { HtmlMarkupView } from './elements/HtmlMarkupView';
import { SliderView } from './elements/SliderView';

const renderers: ContentRendererConfig['renderers'] = {
    view: View,
    text: Text,
    image: Image,
    icon: IconElement,
    ['gradient-linear']: LinearGradient,
    ['touchable-opacity']: View,
    ['touchable-no-feedback']: TouchableWithoutFeedback,
    ['scroll-view']: ScrollView,
    ['menu-item']: MenuItem,
    ['blur-view']: BlurView,
    ['content-product']: ProductView,
    ['content-collection']: CollectionView,
    ['content-timer']: TimerView,
    ['html-markup']: HtmlMarkupView,
    ['content-slider']: SliderView
}

export const ContentViewer = React.memo((props: { width?: number | string, src?: string, children?: React.ReactElement }) => {
    return (
        <View style={{ width: props.width }}>
            <ContentRenderer
                source={props.src || persistContent(props.children!)}
                config={{ renderers }}
            />
        </View>
    )
});