import { Avatar, Badge, Stack, TextStyle } from "@shopify/polaris";
import React from "react";
import { AdListingType, ListAdListingsItems_listAdListingItems_edges_node } from "../../../api/spacex.types";
import { useClient } from "../../../api/useClient";
import { useFirstActiveApp } from "../../../utils/helpers";

export const TargetedAdLisingItem = React.memo(({ item }: { item: ListAdListingsItems_listAdListingItems_edges_node }) => {
    const client = useClient();
    const app = client.useGetAppCard({ appId: useFirstActiveApp().id }).app;
    if (!app.publishedCard) {
        return (
            <Stack vertical>
                <Stack.Item>
                    <TextStyle variation={'strong'}>Invalid shop</TextStyle>
                </Stack.Item>
            </Stack>
        );
    }
    app.publishedCard?.icon?.src;
    app.publishedCard?.title

    return (
        <Stack alignment={"center"} distribution={"leading"}>
            <Stack.Item>
                <Avatar source={app.publishedCard.icon?.src} customer name={app.publishedCard.title} />
            </Stack.Item>
            <Stack.Item>
                <Stack vertical>
                    <Stack.Item>
                        <Stack>
                            <Stack.Item>
                                <p>Bid: <TextStyle variation={'strong'}>{item.adListing.bidPerClick}</TextStyle></p>
                            </Stack.Item>
                            <Stack.Item>
                                <Badge status={item.adListing.type === AdListingType.PRODUCT ? "success" : "info"}>{item.adListing.type}</Badge>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <p>Store: <TextStyle variation={'strong'}>{app.publishedCard.title}</TextStyle>{"\u00A0"}</p>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
});