import React, { useCallback, useMemo } from 'react'
import { FixedSizeList, ListChildComponentProps, ListOnItemsRenderedProps } from 'react-window';
import { FlatIconType, FlatIconWrapper, IconsData } from './iconsData';
import { View, Text } from 'react-native';
import { Button, Stack, TextContainer, Heading } from '@shopify/polaris';
import { IconView } from '../../types';
import { useSpring, animated, config } from '@react-spring/native'
import { IconPickerListStyle } from './commonViewStyle';

type Props = {
    flatIcons: FlatIconWrapper[]
    onSelect: (val: IconView) => void
    value: IconView | null
    onItemsRendered?: (props: ListOnItemsRenderedProps) => any,
    height?: number
}

const PADDING_SIZE = 10;
const ITEM_SIZE = 36;

export const AllIconsView = React.forwardRef<FixedSizeList, Props>((props, forwardRef) => {
    return (
        <FixedSizeList
            ref={forwardRef}
            height={props.height || IconPickerListStyle.height}
            width={"100%"}
            itemData={props}
            onItemsRendered={props.onItemsRendered}
            itemCount={props.flatIcons.length}
            itemSize={ITEM_SIZE}>
            {Row}
        </FixedSizeList>
    )
})

const Row = React.memo<ListChildComponentProps & { data: Props }>(({
                                                                       index,
                                                                       style: innerStyle,
                                                                       data: {flatIcons, value, onSelect},
                                                                   }: ListChildComponentProps) => {
    const item = flatIcons[index];
    const style = useSpring({
        from: {opacity: 0},
        to: {opacity: 1},
        config: config.stiff,
    })

    return <div style={innerStyle}>
        <animated.View style={style}>
            {item.type === FlatIconType.IconsGroup ?
                <IconsRow icons={item.icons} value={value} onClick={onSelect} iconPackData={item.iconPackData}/> :
                <TitleRow title={item.iconPackData.title}/>}
        </animated.View>
    </div>
})

const TitleRow = React.memo<{ title: string }>(({title}) => (
    <TextContainer>
        <Heading element={"h5"}>{title}</Heading>
    </TextContainer>
))

const IconsRow = React.memo<{
    iconPackData: IconsData
    icons: string[]
    value: IconView | null
    onClick: (val: IconView) => void
}>(({icons, iconPackData, onClick, value}) => {

    const isPackEql = value?.collection === iconPackData.key

    const onClickCb = useCallback((name: string) => {
        onClick({
            collection: iconPackData.key,
            name,
        })
    }, [onClick])

    const IconComponent = iconPackData.iconSet

    return <View style={{paddingHorizontal: PADDING_SIZE}}>
        <Stack distribution="fill">
            {icons.map(icon => {
                const isSelected = isPackEql && icon === value?.name
                return <Stack.Item key={icon}>
                    <div onClickCapture={()=>onClickCb(icon)} className={"icon-picker-list-item "+(isSelected ? "selected":"")}>
                        <IconComponent name={icon} size={20}/>
                    </div>
                    {/*<Button monochrome={!isSelected} plain*/}
                    {/*        onClick={() => onClickCb(icon)}*/}
                    {/*        icon={<IconComponent name={icon} size={20} color={isSelected ? 'rgb(0, 123, 92)' : 'rgb(109, 113, 117)'}/>}/>*/}
                </Stack.Item>
            })}
        </Stack>
    </View>
})