import { FormLayout, Labelled } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { Knob } from "../Knob";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor, EditorEvent } from 'tinymce';
 
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';

export const KnobHtmlMarkup = React.memo<Knob.KnobComponentProps<Knob.KnobHtmlMarkupDescriptor>>((
    { knob, innerKey, value: initialValue, onChange, disabled }
) => {    
    const [value, setValue] = useState<Knob.HtmlMarkup>(knob.defaultValue || '');    

    useEffect(() => {        
        if (initialValue !== value) {            
            setValue(initialValue);
        }        
    }, [initialValue, innerKey]);

    const handleEditorChange = useCallback((event: EditorEvent<any>, editor: TinyMCEEditor) => {        
        const newValue = editor.getContent();
        setValue(newValue);
        onChange(newValue, innerKey);
    }, [innerKey, setValue, onChange]);

    return (
        <FormLayout>
            <Labelled id={`${innerKey}-editor`} label={knob.description}>
                <Editor
                    id={`${innerKey}-editor`}
                    value={value}    
                    onEditorChange={handleEditorChange}
                    disabled={disabled}
                    init={{
                        height: 200,
                        menubar: false,
                        plugins: [
                            'link image',
                            'table paste'
                        ],
                        toolbar: 'formatselect |' + 'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'image | removeformat',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                />
            </Labelled>   
        </FormLayout>
    );
});