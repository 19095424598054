import React, { createRef, LegacyRef, useMemo, useRef, useState } from 'react'
import { matchPath, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import { AccountRequireRoute } from '../routing/AccountRequireRoute';
import { AppPageDetail } from '../configure/menu/AppPageDetail';
import { AppMenuNew } from '../configure/menu/AppMenuNew';
import { App } from './App';
import { UseAppPhonePreview } from '../configure/theme/preview/useAppPhonePreview';
import { PhonePreviewsAccent } from '../configure/theme/preview/PhonePreviewContext';
import { AppetizerFrame } from '../configure/theme/preview/AppetizerFrame';
import { useClient } from '../api/useClient';
import IdleTimer from 'react-idle-timer';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { AppPageNew } from "../configure/page/AppPageNew";
import { AppPageEdit } from "../configure/page/AppPageEdit";
import { AppBannerInputParams } from "../api/spacex.types";
import { AppMoreMenuNew } from "../configure/more/AppMoreMenuNew";
import { AppMorePageDetail } from "../configure/more/AppMorePageDetail";
import { SmartBannerContextLoader } from '../SmartBannerContext';
import { AppComponent } from '../configure/AppComponent';

export const AppTab = React.memo(() => {
    const client = useClient();
    const match = useRouteMatch<{ id: string }>();
    const location = useLocation();
    const history = useHistory();
    const { id } = match.params;
    const isMenu = useMemo(() => matchPath(location.pathname, `${match.path}/menu`), [location.pathname]);
    UseAppPhonePreview(id, isMenu ? PhonePreviewsAccent.NAVIGATION_BOTTOM_BAR : PhonePreviewsAccent.CONTENT);

    const frm = useRef<HTMLIFrameElement>(null);

    const [sessionId, setsessionId] = useState<string>();
    useEffect(() => {
        client
            .mutateCreatePreviewSession({ appId: id })
            .then((value) => setsessionId(value.createPreviewSession.id));
    }, [id]);

    const closeSession = useCallback(
        () => {
            if (sessionId) {
                client
                    .mutateClosePreviewSession({ sessionId })
                    .then();
            }
        },
        [sessionId, id],
    );

    const idleRef = createRef<IdleTimer>();
    const [idle, setIdle] = useState(false);

    const handleOnIdle = useCallback(
        () => {
            closeSession();
            setIdle(true);
        },
        [sessionId, id],
    );

    const handleOnAction = useCallback(
        () => {
            if (idle) {
                client.mutateCreatePreviewSession({ appId: id })
                    .then((value) => {
                        setsessionId(value.createPreviewSession.id);
                    });
            }
            setIdle(false);
            window.dispatchEvent(new Event('resize'));
            // Preventing timeout on Appetize if user is active on the page.
            if (frm.current && frm.current.contentWindow) {
                frm.current.contentWindow.postMessage('heartbeat', '*');
            }
        },
        [sessionId, id],
    );

    const serverTabDesigner = client.useAppTabsConfig({ id }).app.draftConfig.tabsDesigner.map(i => {
        return {
            url: i.contentUrl?.replace('app:///', 'cartmate://'),
            key: i.key
        }
    });

    useEffect(() => {
        if (sessionId) {

            const selectedTab = matchPath<{ key: string, id: string }>(location.pathname, `/apps/:id/menu/item/:key`);

            if (serverTabDesigner && selectedTab) {
                const tab = serverTabDesigner.find((t) => {
                    return t.key === (selectedTab.params.key);
                });

                if (tab && tab.url) {
                    client.mutateUpdatePreviewSessionUrl({ appId: id, sessionId: sessionId, url: tab.url })
                        .then((result) => console.log(result));
                }
            }


        }
    }, [sessionId, location]);
    const [showPreview, setShowPreview] = useState(history.location.pathname.indexOf('promo') === -1);

    useEffect(() => {
        const unsubscribeHistoryChange = history.listen((location, action) => {
            console.log('HISTORY CHANGE', location, action);
            // TODO: Very dirty hack. Fix me!
            setShowPreview(location.pathname.indexOf('promo') === -1);
        });
        return () => {
            unsubscribeHistoryChange();
        }
    }, []);
    // const [smartBanner, setSmartBanner] = useState<AppBannerInputParams | undefined>(undefined);

    // console.log('[AppTab] smartBanner', smartBanner);

    return (
        <SmartBannerContextLoader>
            <AppetizerFrame
                sessionId={sessionId}
                appId={id}
                frm={frm}
            >
                {/* NOTE THAT THIS WILL BREAK DND LIB WORIKING FIRING UP CANCEL DRAG EVENT */}
                {/* <IdleTimer
                ref={idleRef}
                timeout={1000 * 60 * 10}
                onIdle={handleOnIdle}
                onAction={handleOnAction}
                debounce={250}
            /> */}
                <Switch>
                    <AccountRequireRoute path={`${match.path}/menu/item/:key`} component={AppPageDetail} />
                    <AccountRequireRoute path={`${match.path}/menu/new`} component={AppMenuNew} />
                    <AccountRequireRoute path={`${match.path}/menu/more-item/:key`} component={AppMorePageDetail} />
                    <AccountRequireRoute path={`${match.path}/menu/more-new`} component={AppMoreMenuNew} />
                    <AccountRequireRoute path={`${match.path}/pages/new`} component={AppPageNew} />
                    <AccountRequireRoute path={`${match.path}/pages/:pageId`} component={AppPageEdit} />
                    <AccountRequireRoute path={match.path}>
                        {/* <App /> */}
                        <AppComponent />
                    </AccountRequireRoute>
                </Switch>
            </AppetizerFrame>
        </SmartBannerContextLoader>
    );
})