import React, { CSSProperties, useCallback, useMemo, useState } from 'react'
import { reorderArray } from '../../configEditor/elementsPickerComponents/ElementsListUtils';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableRubric,
    DraggableStateSnapshot,
    Droppable,
} from 'react-beautiful-dnd';
import { useConform, useConformContext } from '../modals/useConform';

export type RenderDNDElementProps<TItem> = {
    entity: TItem,
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric
    onRemove: () => void
}

type Props<TItem> = {
    items: TItem[]
    getKey: (item: TItem) => string
    onChangeList: (item: TItem[]) => void
    renderItem: (props: RenderDNDElementProps<TItem>) => React.ReactElement<HTMLElement>
    conformOnRemove?: boolean,
    droppableKey?: string
}

const style: Record<any, CSSProperties> = {
    dragBody: {
        display: 'flex',
        flexDirection: 'row',
    },
    dropList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        paddingBottom: 20,
        paddingTop: 20,
    },
    draggableStyle: {
        paddingBottom: 0,
        flex: '1 1 100%',
        background: 'white',
    },
}

type ElementProps<T> = {
    entity: T
    index: number
    innerKey: string
    renderItem: (props: RenderDNDElementProps<T>) => React.ReactElement<HTMLElement>
    itemsCount: number
    onRemove: (entity: T) => void
}


function Element<T>({ entity, index, innerKey, renderItem: Item, itemsCount, onRemove }: ElementProps<T>) {
    const renderCb = useCallback((provided: DraggableProvided,
        snapshot: DraggableStateSnapshot,
        rubric: DraggableRubric) => {

        const draggingStyle = snapshot.isDragging && {
            opacity: 1,
            boxShadow: '-1px -1px 15px -1px rgba(0,0,0,0.1)',
        } as CSSProperties


        const dropAnimation = snapshot.dropAnimation;

        const lineStyle = {
            backgroundColor: '#f3f4f5',
            height: 1,
            position: 'relative',
            transition: `opacity ${dropAnimation?.curve} ${dropAnimation?.duration || 0.2}s`,
            opacity: snapshot.isDragging ? 0 : 1,
        } as CSSProperties

        const onRemoveCb = useCallback(() => onRemove(entity), [entity])

        return (
            <div ref={provided.innerRef} {...provided.draggableProps}
                style={{
                    ...provided.draggableProps.style,
                    ...style.draggableStyle,
                    ...draggingStyle,
                }}>
                <div style={{ ...lineStyle, top: -1 }} />
                <div style={{ padding: '1.2rem 20px' }}>
                    <Item onRemove={onRemoveCb} entity={entity} provided={provided} snapshot={snapshot} rubric={rubric} />
                </div>
                <div style={lineStyle} />
            </div>
        )
    }, [entity, Item, index, innerKey, onRemove])
    return (
        <Draggable draggableId={innerKey} disableInteractiveElementBlocking index={index} children={renderCb} />
    )
}

export function SortableDndList<TItem>({ items, onChangeList, getKey, renderItem, conformOnRemove, droppableKey }: Props<TItem>) {

    const onDragEnd = useCallback(result => {
        console.log('[SortableDndList]: onDragEnd', result);
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        onChangeList(reorderArray(
            items,
            result.source.index,
            result.destination.index,
        ));
    }, [items, onChangeList])

    const [modal, setConformOpen] = useConformContext('Are you sure you want to delete the item?')

    const onRemove = useCallback((entity: TItem) => {
        if (conformOnRemove) {
            setConformOpen({
                onConform: () => {
                    onChangeList(items.filter(i => getKey(i) !== getKey(entity)))
                    return Promise.resolve()
                }
            })
        } else {
            onChangeList(items.filter(i => getKey(i) !== getKey(entity)))
        }
    }, [items, getKey, setConformOpen, conformOnRemove])

    const renderCb = useCallback((entity: TItem, index: number) => {
        const key = getKey(entity);
        return (
            <Element
                key={key} 
                index={index}
                entity={entity}
                innerKey={key}
                renderItem={renderItem}
                onRemove={onRemove}
                itemsCount={items.length}
            />
        );
    }, [getKey, renderItem, items]);

    return useMemo(() => (
        <>
            {modal}
            <DragDropContext
                onDragEnd={onDragEnd}
            >
                <Droppable
                    key={droppableKey}
                    droppableId={`list-${droppableKey}`}
                >
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} style={style.dropList}>
                            {items.map(renderCb)}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    ), [items, renderCb, modal])
}