import React from 'react';
import { Controller, ControllerRenderProps, Validate, ValidationRule, ValidationValueMessage } from 'react-hook-form';
import { StyledCheckbox, StyledCheckboxProps } from '../../StyledCheckbox/StyledCheckbox';
import { TouchableOpacity } from 'react-native';

type PartialSelectProps = Pick<StyledCheckboxProps, 'value'|'text'>

type Props = {
    name: string,
    defaultValue?: boolean
    rules?: Partial<{
        required: string | boolean | ValidationValueMessage<boolean>;
        min: ValidationRule<React.ReactText>;
        max: ValidationRule<React.ReactText>;
        maxLength: ValidationRule<React.ReactText>;
        minLength: ValidationRule<React.ReactText>;
        pattern: ValidationRule<RegExp>;
        validate: Validate | Record<string, Validate>;
        valueAsNumber: boolean;
        valueAsDate: boolean;
        setValueAs: (value: any) => any;
    }> | undefined
} & PartialSelectProps

const RenderComponent = React.forwardRef<TouchableOpacity, ControllerRenderProps & PartialSelectProps>(({text, value, onChange, ...props}, ref) => {
    return <StyledCheckbox {...props}
                           ref={ref}
                           text={text}
                           onChange={onChange}
                           value={value}/>
})

export const FormCheckbox = React.memo<Props>(React.forwardRef(({text, name, defaultValue, rules}, ref) => (
    <Controller
        ref={ref}
        render={({ref, ...valueProps}) => <RenderComponent text={text} ref={ref} {...valueProps}/>}
        defaultValue={defaultValue}
        name={name}
        rules={rules}
    />
)))